define("@outdoorsyco/ember-shared-data/serializers/line-item", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class LineItemSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        taxRates: {
          serialize: 'ids',
          deserialize: 'ids'
        }
      });
    }

    // Model -> what the API expects
    serialize(record, options) {
      let data = super.serialize(record, options);
      data.tax_rate = data.tax_percentage;

      if (data.charge_type !== 'percent') {
        data.percent = 0;
        data.percent_type = null;
      }

      let tax_rate_ids = data.tax_rates || [];
      data.tax_rate_ids = tax_rate_ids.map(tr => parseInt(tr));

      if (data.booking_tax_rates) {
        data.tax_rates = data.booking_tax_rates;
        delete data.tax_rate_ids;
      } else {
        delete data.tax_rates;
      } // delete data.tax_rate_id;


      data.location_ids = (data.locations || []).map(locId => parseInt(locId));
      delete data.locations;
      data.container_id = data.question_id;
      delete data.question_id;
      delete data.localized_content;
      return data;
    } // API -> what the model expects


    normalize(typeClass, hash) {
      let tax_rate_ids = hash.tax_rate_ids || [];

      if (hash.tax_rates) {
        hash.booking_tax_rates = hash.tax_rates;
      }

      hash.tax_rates = tax_rate_ids;
      hash.locations = hash.location_ids || [];
      hash.tax_percentage = hash.tax_rate;
      delete hash.tax_rate;

      if (hash.location_id === 0) {
        delete hash.location_id;
      }

      if (hash.tax_rate_id === 0) {
        delete hash.tax_rate_id;
      }

      if (hash.parent_item_id === 0) {
        delete hash.parent_item_id;
      }

      if (hash.container_id === 0) {
        delete hash.container_id;
      }

      if (hash.percent) {
        hash.charge_type = 'percent';
      } else {
        hash.charge_type = 'normal';
      }

      if (hash.position === 0) {
        delete hash.position;
      }

      hash.question_id = hash.container_id;
      hash.links = hash.links || {};
      hash.links.localizedContent = `/v0/localized-content?object_type=rental_items&object_id=${hash.id}`;
      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = LineItemSerializer;
});