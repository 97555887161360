define("@outdoorsyco/ember-shared-data/models/line-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _dec53, _dec54, _dec55, _dec56, _dec57, _dec58, _dec59, _dec60, _dec61, _dec62, _dec63, _dec64, _dec65, _dec66, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52, _descriptor53, _descriptor54, _descriptor55, _descriptor56, _descriptor57;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  let LineItem = (_dec = Ember.inject.service, _dec2 = belongsTo('user'), _dec3 = belongsTo('tax'), _dec4 = hasMany('location'), _dec5 = hasMany('tax'), _dec6 = attr(), _dec7 = belongsTo('rental'), _dec8 = belongsTo('booking'), _dec9 = belongsTo('payout'), _dec10 = belongsTo('lineItem', {
    async: true
  }), _dec11 = belongsTo('location', {
    async: true,
    inverse: null
  }), _dec12 = hasMany('localized-content', {
    async: true
  }), _dec13 = hasMany('lineItem', {
    inverse: 'question'
  }), _dec14 = attr('string'), _dec15 = attr('string'), _dec16 = attr('centsToDollars'), _dec17 = attr('centsToDollars'), _dec18 = attr('centsToDollars'), _dec19 = attr('number'), _dec20 = attr('string'), _dec21 = attr('string'), _dec22 = attr('number'), _dec23 = attr('number'), _dec24 = attr('boolean'), _dec25 = attr('boolean'), _dec26 = attr('boolean'), _dec27 = attr('centsToDollars'), _dec28 = attr('boolean'), _dec29 = attr('number'), _dec30 = attr('boolean'), _dec31 = attr('boolean'), _dec32 = attr('boolean'), _dec33 = attr('boolean'), _dec34 = attr('boolean'), _dec35 = attr('boolean'), _dec36 = attr('boolean'), _dec37 = attr('boolean'), _dec38 = attr('boolean'), _dec39 = attr('array'), _dec40 = attr('number'), _dec41 = attr('string'), _dec42 = attr('string'), _dec43 = attr('centsToDollars'), _dec44 = attr('number'), _dec45 = attr('centsToDollars'), _dec46 = attr('number'), _dec47 = attr('string'), _dec48 = attr('number'), _dec49 = attr('number'), _dec50 = attr('number'), _dec51 = attr('string'), _dec52 = attr('string'), _dec53 = attr('centsToDollars'), _dec54 = attr('centsToDollars'), _dec55 = attr('number'), _dec56 = Ember.computed.bool('available'), _dec57 = Ember.computed.readOnly('deferred'), _dec58 = Ember.computed('name', 'mileage'), _dec59 = Ember.computed('name', 'generator'), _dec60 = Ember.computed('premiumAmount', 'price'), _dec61 = Ember.computed('available'), _dec62 = Ember.computed('availableOptions', 'count'), _dec63 = Ember.computed('description', 'price', 'name'), _dec64 = Ember.computed('name'), _dec65 = Ember.computed('name'), _dec66 = Ember.computed('minimumPrice', 'maximumPrice'), (_class = class LineItem extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);

      _initializerDefineProperty(this, "taxRate", _descriptor3, this);

      _initializerDefineProperty(this, "locations", _descriptor4, this);

      _initializerDefineProperty(this, "taxRates", _descriptor5, this);

      _initializerDefineProperty(this, "bookingTaxRates", _descriptor6, this);

      _initializerDefineProperty(this, "rental", _descriptor7, this);

      _initializerDefineProperty(this, "booking", _descriptor8, this);

      _initializerDefineProperty(this, "consignedPayout", _descriptor9, this);

      _initializerDefineProperty(this, "question", _descriptor10, this);

      _initializerDefineProperty(this, "location", _descriptor11, this);

      _initializerDefineProperty(this, "localizedContent", _descriptor12, this);

      _initializerDefineProperty(this, "answers", _descriptor13, this);

      _initializerDefineProperty(this, "imageUrl", _descriptor14, this);

      _initializerDefineProperty(this, "description", _descriptor15, this);

      _initializerDefineProperty(this, "price", _descriptor16, this);

      _initializerDefineProperty(this, "minimumPrice", _descriptor17, this);

      _initializerDefineProperty(this, "maximumPrice", _descriptor18, this);

      _initializerDefineProperty(this, "position", _descriptor19, this);

      _initializerDefineProperty(this, "name", _descriptor20, this);

      _initializerDefineProperty(this, "category", _descriptor21, this);

      _initializerDefineProperty(this, "categoryId", _descriptor22, this);

      _initializerDefineProperty(this, "count", _descriptor23, this);

      _initializerDefineProperty(this, "variable", _descriptor24, this);

      _initializerDefineProperty(this, "required", _descriptor25, this);

      _initializerDefineProperty(this, "daily", _descriptor26, this);

      _initializerDefineProperty(this, "total", _descriptor27, this);

      _initializerDefineProperty(this, "deferred", _descriptor28, this);

      _initializerDefineProperty(this, "available", _descriptor29, this);

      _initializerDefineProperty(this, "taxable", _descriptor30, this);

      _initializerDefineProperty(this, "rentalAmount", _descriptor31, this);

      _initializerDefineProperty(this, "coachnetItem", _descriptor32, this);

      _initializerDefineProperty(this, "outdoorsyItem", _descriptor33, this);

      _initializerDefineProperty(this, "mileage", _descriptor34, this);

      _initializerDefineProperty(this, "generator", _descriptor35, this);

      _initializerDefineProperty(this, "delivery", _descriptor36, this);

      _initializerDefineProperty(this, "otherClaim", _descriptor37, this);

      _initializerDefineProperty(this, "hidden", _descriptor38, this);

      _initializerDefineProperty(this, "appliesToType", _descriptor39, this);

      _initializerDefineProperty(this, "taxPercentage", _descriptor40, this);

      _initializerDefineProperty(this, "taxName", _descriptor41, this);

      _initializerDefineProperty(this, "taxDescription", _descriptor42, this);

      _initializerDefineProperty(this, "taxAmount", _descriptor43, this);

      _initializerDefineProperty(this, "consignedPayPct", _descriptor44, this);

      _initializerDefineProperty(this, "consignedPayAmount", _descriptor45, this);

      _initializerDefineProperty(this, "parentItemId", _descriptor46, this);

      _initializerDefineProperty(this, "parentItemType", _descriptor47, this);

      _initializerDefineProperty(this, "originalTaxRateId", _descriptor48, this);

      _initializerDefineProperty(this, "rentalId", _descriptor49, this);

      _initializerDefineProperty(this, "percent", _descriptor50, this);

      _initializerDefineProperty(this, "percentType", _descriptor51, this);

      _initializerDefineProperty(this, "chargeType", _descriptor52, this);

      _initializerDefineProperty(this, "premiumAmount", _descriptor53, this);

      _initializerDefineProperty(this, "premiumTotal", _descriptor54, this);

      _initializerDefineProperty(this, "travelers", _descriptor55, this);

      _initializerDefineProperty(this, "isQuantifiable", _descriptor56, this);

      _initializerDefineProperty(this, "isVariableOrNote", _descriptor57, this);
    }

    get isMileageItem() {
      return this.get('mileage') || this.get('name') === 'mileage';
    }

    get isGeneratorItem() {
      return this.get('generator') || this.get('name') === 'generator';
    }

    get priceWithPremium() {
      return this.get('premiumAmount') + this.get('price');
    }

    get availableOptions() {
      let available = this.get('available');

      if (available) {
        let result = [{
          display: '0',
          value: 0
        }];
        let maxAvailable = available > 100 ? 100 : available;

        for (let i = 1; i <= maxAvailable; i++) {
          result.push({
            display: i,
            value: i
          });
        }

        return result;
      } // yay/nay


      return [{
        value: undefined,
        display: '0'
      }, {
        value: 1,
        display: '1'
      }];
    }

    get selectedOptionByValue() {
      return this.availableOptions.findBy('value', this.count);
    }

    get friendlyDescription() {
      if (['Days', 'Weeks', 'Months'].indexOf(this.get('name')) !== -1) {
        return `$${this.get('price')} x ${this.get('count')} ${this.friendlyName}`;
      }

      return this.friendlyName;
    }

    get friendlyName() {
      const intl = this.intl;
      const item = this.name;
      let translationKey = 'term'.concat('-', item.replace(/\s+/g, '-').toLowerCase());

      if (translatedItems.includes(translationKey)) {
        return intl.t(translationKey);
      }

      return item;
    } //TODO: kill this when not needed anymore, should be soon when mileage is deployed


    get protected() {
      return ['generator', 'mileage'].indexOf(this.get('name')) !== -1;
    }

    get priceRangeType() {
      if (this.minimumPrice && this.maximumPrice) {
        return 'min-and-max';
      }

      if (this.minimumPrice) {
        return 'min-only';
      }

      return 'max-only';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "taxRate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "locations", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "taxRates", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "bookingTaxRates", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "rental", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "booking", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "consignedPayout", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "question", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "localizedContent", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "answers", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "imageUrl", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "price", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "minimumPrice", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "maximumPrice", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "position", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "categoryId", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "count", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "variable", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "required", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "daily", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "total", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "deferred", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "available", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "taxable", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "rentalAmount", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "coachnetItem", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "outdoorsyItem", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "mileage", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "generator", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "delivery", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "otherClaim", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "hidden", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "appliesToType", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "taxPercentage", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "taxName", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "taxDescription", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "taxAmount", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "consignedPayPct", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "consignedPayAmount", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "parentItemId", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "parentItemType", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = _applyDecoratedDescriptor(_class.prototype, "originalTaxRateId", [_dec48], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = _applyDecoratedDescriptor(_class.prototype, "rentalId", [_dec49], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = _applyDecoratedDescriptor(_class.prototype, "percent", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = _applyDecoratedDescriptor(_class.prototype, "percentType", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = _applyDecoratedDescriptor(_class.prototype, "chargeType", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor53 = _applyDecoratedDescriptor(_class.prototype, "premiumAmount", [_dec53], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor54 = _applyDecoratedDescriptor(_class.prototype, "premiumTotal", [_dec54], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor55 = _applyDecoratedDescriptor(_class.prototype, "travelers", [_dec55], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor56 = _applyDecoratedDescriptor(_class.prototype, "isQuantifiable", [_dec56], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor57 = _applyDecoratedDescriptor(_class.prototype, "isVariableOrNote", [_dec57], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isMileageItem", [_dec58], Object.getOwnPropertyDescriptor(_class.prototype, "isMileageItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isGeneratorItem", [_dec59], Object.getOwnPropertyDescriptor(_class.prototype, "isGeneratorItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "priceWithPremium", [_dec60], Object.getOwnPropertyDescriptor(_class.prototype, "priceWithPremium"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "availableOptions", [_dec61], Object.getOwnPropertyDescriptor(_class.prototype, "availableOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedOptionByValue", [_dec62], Object.getOwnPropertyDescriptor(_class.prototype, "selectedOptionByValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "friendlyDescription", [_dec63], Object.getOwnPropertyDescriptor(_class.prototype, "friendlyDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "friendlyName", [_dec64], Object.getOwnPropertyDescriptor(_class.prototype, "friendlyName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "protected", [_dec65], Object.getOwnPropertyDescriptor(_class.prototype, "protected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "priceRangeType", [_dec66], Object.getOwnPropertyDescriptor(_class.prototype, "priceRangeType"), _class.prototype)), _class));
  _exports.default = LineItem;
  const translatedItems = ['term-rental-amount', 'term-mileage', 'term-generator'];
});