define("@outdoorsyco/ember-switchback/components/hr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N/8Vzv0I",
    "block": "{\"symbols\":[\"@variant\",\"@maxWidth\",\"&attrs\"],\"statements\":[[7,\"hr\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Hr\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/hr/styles\"]]]]]],[12,\"data-variant\",[28,\"or\",[[23,1,[]],\"default\"],null]],[12,\"data-max-width\",[23,2,[]]],[13,3],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/hr/template.hbs"
    }
  });

  _exports.default = _default;
});