define("@outdoorsyco/ember-shared-data/components/birthday-selector/component", ["exports", "ember-cp-validations", "@outdoorsyco/ember-shared-data/components/birthday-selector/template", "@outdoorsyco/ember-shared-data/utils/generate-options"], function (_exports, _emberCpValidations, _template, _generateOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const currentYear = new Date().getFullYear();
  const minAge = 18;
  const maxAge = 110;
  const Validations = (0, _emberCpValidations.buildValidations)({
    birthdayDay: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: 0,
      lte: 31
    })],
    birthdayMonth: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: 0,
      lte: 12
    })],
    birthdayYear: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      positive: true,
      gte: currentYear - maxAge,
      lte: currentYear - minAge
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    layout: _template.default,
    intl: Ember.inject.service(),

    didUpdateAttrs() {
      // setup validations to give caller access to form validations
      this.validate().then(({
        validations
      }) => {
        if (this.get('validationsChange')) {
          this.get('validationsChange')(validations);
        }
      });
    },

    monthOptions: Ember.computed('intl.locale', function () {
      let intl = this.get('intl');
      let months = [];

      for (let i = 1; i <= 12; i++) {
        months.push(intl.t(`common-month-${i}`));
      }

      return months.map((m, i) => {
        return {
          display: m,
          value: i + 1
        };
      });
    }),
    dayOptions: Ember.computed(function () {
      return (0, _generateOptions.generateOptions)(31);
    }),
    yearOptions: Ember.computed(function () {
      return (0, _generateOptions.generateOptions)(maxAge - minAge + 1, currentYear - maxAge, 'desc');
    }),
    birthdayMonthValue: Ember.computed('birthdayMonth', 'monthOptions.@each.value', function () {
      return this.monthOptions.findBy('value', this.birthdayMonth);
    }),
    actions: {
      onBirthdayMonthChange({
        value
      }) {
        this.onChange('month', value);
      },

      onBirthdayDayChange(value) {
        this.onChange('day', value);
      },

      onBirthdayYearChange(value) {
        this.onChange('year', value);
      }

    }
  });

  _exports.default = _default;
});