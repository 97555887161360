define("@outdoorsyco/ember-shared-data/components/driving-check-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CYHcK15C",
    "block": "{\"symbols\":[\"l\",\"@modal\"],\"statements\":[[5,\"layout/modal/default\",[],[[\"@modal\"],[[22,\"modal\"]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"driving-check-modal\"],[8],[0,\"\\n    \"],[6,[23,1,[\"Heading\"]],[[12,\"class\",\"  margin-bottom-0.5 margin-right-2 \"],[12,\"id\",[23,2,[\"labelledbyId\"]]]],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"template-modals-driving_check-title\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[6,[23,1,[\"P\"]],[[12,\"class\",\"  margin-bottom-1.5 \"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"template-modals-driving_check-instructions\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[1,[28,\"driving-check\",null,[[\"firstName\",\"lastName\",\"email\",\"booking\",\"user\",\"driverCheckOnly\",\"cancelIdentityCheck\",\"identityCheckComplete\"],[[24,[\"firstName\"]],[24,[\"lastName\"]],[24,[\"email\"]],[24,[\"booking\"]],[24,[\"user\"]],[24,[\"driverCheckOnly\"]],\"cancelIdentityCheck\",\"identityCheckComplete\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,[23,1,[\"Close\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/driving-check-modal/template.hbs"
    }
  });

  _exports.default = _default;
});