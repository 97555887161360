define("@outdoorsyco/outdoorsy-addon/components/calendar-picker/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray600": "hsl(210, 10%, 58%)",
    "sm": "576px",
    "md": "768px",
    "CalendarPicker": "_CalendarPicker_1woilg",
    "form-control": "_form-control_1woilg",
    "ember-power-select-trigger": "_ember-power-select-trigger_1woilg",
    "od-ddpicker-content": "_od-ddpicker-content_1woilg",
    "ember-basic-dropdown-content--right": "_ember-basic-dropdown-content--right_1woilg",
    "od-ddpicker": "_od-ddpicker_1woilg",
    "ember-basic-dropdown-trigger": "_ember-basic-dropdown-trigger_1woilg",
    "input-label": "_input-label_1woilg"
  };
  _exports.default = _default;
});