define("@outdoorsyco/ember-shared-data/components/state-by-country-select/component", ["exports", "@outdoorsyco/ember-shared-data/components/state-by-country-select/template", "@outdoorsyco/ember-shared-data/utils/states-provinces"], function (_exports, _template, _statesProvinces) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    label: false,
    id: 'state-by-country-select',
    country: '',
    value: '',
    showErrors: false,
    allowCreate: true,
    error: '',
    placeholder: Ember.computed('intl.locale', function () {
      return this.get('intl').t('form-state');
    }),
    groupedStates: Ember.computed('country', function () {
      return (0, _statesProvinces.groupedStatesForSelect)(this.get('country'), true);
    }),
    states: Ember.computed('groupedStates.[]', function () {
      return this.get('groupedStates').reduce((arr, obj) => {
        arr = arr.concat(obj.options);
        return arr;
      }, []);
    }),
    actions: {
      setState(state) {
        let val = state;

        if (typeof state === 'object') {
          val = state.short;
        }

        this.sendAction('onChange', val); //eslint-disable-line ember/closure-actions
      }

    }
  });

  _exports.default = _default;
});