define("@outdoorsyco/ember-shared-data/helpers/format-from-now", ["exports", "dayjs"], function (_exports, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const PERIODS = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];
  let FormatFromNow = (_dec = Ember.inject.service, (_class = class FormatFromNow extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    compute(params, {
      style = 'long'
    } = {}) {
      let locale = this.intl.locale || 'en';
      let now = (0, _dayjs.default)();
      let then = (0, _dayjs.default)(params[0]);
      (false && !(then.isValid()) && Ember.assert(`You must pass a valid date as the first param to format-from-now, you passed ${params[0]}`, then.isValid()));
      /**
       * Native support not found so we fall back to just showing the date
       */

      if (!Intl.RelativeTimeFormat) {
        return this.intl.formatDate(then, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        });
      }

      let formatter = new Intl.RelativeTimeFormat(locale, {
        style: style
      });
      let differencePeriod = PERIODS.find(period => {
        return then.diff(now, period) !== 0;
      });
      let differenceAmount = then.diff(now, differencePeriod);
      return formatter.format(differenceAmount, differencePeriod);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FormatFromNow;
});