define("@outdoorsyco/ember-shared-data/components/form/reset-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tb+bl5va",
    "block": "{\"symbols\":[\"f\",\"@onPasswordResetExit\",\"&attrs\"],\"statements\":[[5,\"form\",[[13,3]],[[\"@onSubmit\"],[[28,\"perform\",[[23,0,[\"onSubmit\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"didReset\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[10,\"class\",\"  margin-top-1.5 margin-bottom-3 \"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"form-password-reset-success\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"      \"],[5,\"button\",[[12,\"onclick\",[23,2,[]]]],[[\"@variant\",\"@width\"],[\"brand-primary\",\"full\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"form-password-reset-close\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"  margin-bottom-0.5 \"],[8],[0,\"\\n      \"],[5,\"text-input\",[[12,\"placeholder\",[28,\"t\",[\"form-signup-email\"],null]],[12,\"aria-label\",[28,\"t\",[\"form-signup-email\"],null]],[12,\"autocomplete\",\"email\"],[12,\"type\",\"email\"]],[[\"@form\",\"@value\",\"@onChange\",\"@requirements\"],[[23,1,[]],[23,0,[\"email\"]],[28,\"fn\",[[28,\"mut\",[[23,0,[\"email\"]]],null]],null],[28,\"hash\",null,[[\"required\",\"email\"],[true,true]]]]]],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[5,\"button\",[],[[\"@variant\",\"@width\",\"@loading\",\"@type\"],[\"brand-primary\",\"full\",[23,0,[\"onSubmit\",\"isRunning\"]],\"submit\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"form-password-reset-button\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/form/reset-password/template.hbs"
    }
  });

  _exports.default = _default;
});