define("@outdoorsyco/ember-switchback/modifiers/swipe-down-to-dismiss", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.REM_MULTIPLIER = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const REM_MULTIPLIER = parseInt(getComputedStyle(document.body).fontSize);
  _exports.REM_MULTIPLIER = REM_MULTIPLIER;
  let hasDOM = typeof document !== 'undefined';
  /**
   * Calls an action if the user swipes to dismiss the element.
   * It assigns css variables to the element to allow for style
   * to match the movement of the user's swipe.
   *
   * TODO: Support velocity swipes
   */

  let SwipeDownToDismissModifier = (_dec = Ember._action, _dec2 = Ember._action, (_class = class SwipeDownToDismissModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "yStart", null);

      _defineProperty(this, "offset", void 0);

      _defineProperty(this, "isScrollingContainer", false);
    }

    get targetElement() {
      return document.getElementById(this.args.named.targetElementId);
    }

    get targetElementHeight() {
      return this.targetElement.getBoundingClientRect().height;
    }

    get percentageSwiped() {
      return this.offset / this.targetElementHeight * 100;
    }

    didReceiveArguments() {
      if (!hasDOM) {
        return;
      }

      this.removeEventListener();
      this.addEventListener();
    }

    willRemove() {
      if (!hasDOM) {
        return;
      }

      this.removeEventListener();
    }

    addEventListener() {
      this.element.addEventListener('touchmove', this.onTouchMove);
      this.element.addEventListener('touchend', this.onTouchEnd);
    }

    removeEventListener() {
      this.element.removeEventListener('touchmove', this.onTouchMove);
      this.element.addEventListener('touchend', this.onTouchEnd);
    }
    /**
     * Called when the users performs a swipe movement. Keeps track of
     * how far the user has swiped and stores it in a css variable for
     * use by child elements.
     *
     * @param {object} event - Touchmove event
     */


    onTouchMove(event) {
      this.element.dataset.swiping = true;

      if (this.isScrollingContainer) {
        return;
      }

      let yPosition = event.touches[0].clientY;
      /**
       * First touch remember Y position for where the user put
       * their finder down.
       */

      if (!this.yStart) {
        this.yStart = yPosition;
        return;
      }
      /**
       * If the user is trying to scroll the scroll area we abort
       */


      let scrollArea = event.target.closest('[data-slideup-scroll-area]');
      let canScrollUp = scrollArea && scrollArea.scrollTop > 0;
      let isScrollingDown = yPosition <= this.yStart;

      if (canScrollUp || isScrollingDown) {
        this.isScrollingContainer = true;
        return;
      }
      /**
       * Second touch, ie. on drag
       */


      event.preventDefault();
      this.updateOffset(this.yStart, yPosition);
      this.updateCss();
    }
    /**
     * Called when the swipe ends and is responsible for deciding if this
     * swipe should be considered a dismissal.
     */


    onTouchEnd() {
      if (this.percentageSwiped > 30) {
        this.args.named.onDismiss();
      }

      let element = this.element;
      /**
       * We need to schedule the clearing of the css properties so
       * ember-animated has a chance to start animating out else
       * there will be a flash as the element moves back to its original
       * position before animating down.
       */

      Ember.run.next(this, () => {
        element.style.setProperty('--swipe-distance-completed', null);
        element.style.setProperty('--swipe-distance-remaining', null);
      });
      this.yStart = null;
      this.offset = null;
      this.isScrollingContainer = false;
      this.element.dataset.swiping = false;
    }
    /**
     * Sets a css variable so child elements can use this to position
     * elements as the swipe moves.
     *
     * @param {boolean} options.clear - If the css variable should be removed
     */


    updateCss({
      clear
    } = {}) {
      if (clear) {
        this.element.style.setProperty('--swipe-distance-completed', null);
        this.element.style.setProperty('--swipe-distance-remaining', null);
        return;
      }

      this.element.style.setProperty('--swipe-distance-completed', `${this.percentageSwiped}`);
      this.element.style.setProperty('--swipe-distance-remaining', `${100 - this.percentageSwiped}`);
    }
    /**
     * Calculates what the offset should be given the swipe distance
     * that will dismiss the element.
     *
     * @param {number} start - Start Y position
     * @param {number} stop - Stop Y position
     */


    updateOffset(start, stop) {
      let offset = (start - stop) * -1;
      this.offset = Math.max(offset, 0);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onTouchMove", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onTouchMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTouchEnd", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onTouchEnd"), _class.prototype)), _class));
  _exports.default = SwipeDownToDismissModifier;
});