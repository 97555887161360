define("@outdoorsyco/ember-shared-data/serializers/bundle-feature", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BundleFeature extends _application.default {
    serialize(record, options) {
      options = options ? options : {}; // handle the case where options is undefined

      options.includeId = true;
      let hash = super.serialize(record, options);

      if (hash.regions.length) {
        if (hash.regions[0].indexOf(',') > 0) {
          hash.regions = hash.regions[0].split(',');
        }
      }

      return hash;
    }

    normalize(typeClass, hash) {
      hash.links = hash.links || {};
      hash.links.localizedContent = `/v0/localized-content?object_type=bundle_features&object_id=${hash.id}`;
      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = BundleFeature;
});