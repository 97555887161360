define("ember-mobile-core/easings", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EaseIn = function EaseIn(power) {
    return function (t) {
      return Math.pow(t, power);
    };
  };
  var EaseOut = function EaseOut(power) {
    return function (t) {
      return 1 - Math.abs(Math.pow(t - 1, power));
    };
  };
  var EaseInOut = function EaseInOut(power) {
    return function (t) {
      return t < .5 ? EaseIn(power)(t * 2) / 2 : EaseOut(power)(t * 2 - 1) / 2 + 0.5;
    };
  };

  exports.default = {
    linear: EaseInOut(1),
    easeInQuad: EaseIn(2),
    easeOutQuad: EaseOut(2),
    easeInOutQuad: EaseInOut(2),
    easeInCubic: EaseIn(3),
    easeOutCubic: EaseOut(3),
    easeInOutCubic: EaseInOut(3),
    easeInQuart: EaseIn(4),
    easeOutQuart: EaseOut(4),
    easeInOutQuart: EaseInOut(4),
    easeInQuint: EaseIn(5),
    easeOutQuint: EaseOut(5),
    easeInOutQuint: EaseInOut(5)
  };
});