define("@outdoorsyco/ember-shared-data/components/ui/aspect-ratio/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "md": "768px",
    "AspectRatio": "_AspectRatio_13kfmv",
    "wrapper": "_wrapper_13kfmv",
    "image-hover": "_image-hover_13kfmv",
    "sections": "_sections_13kfmv"
  };
  _exports.default = _default;
});