define("@outdoorsyco/ember-shared-data/utils/array/median", ["exports", "@outdoorsyco/ember-shared-data/utils/array/quantile"], function (_exports, _quantile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = median;

  function median(values, valueof) {
    return (0, _quantile.default)(values, 0.5, valueof);
  }
});