define("@outdoorsyco/ember-switchback/components/menu-button/option/component", ["exports", "@glimmer/component", "@outdoorsyco/ember-switchback/utils/key-codes"], function (_exports, _component, _keyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let MenuButtonOption = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class MenuButtonOption extends _component.default {
    /**
     * Focuses on the option when the user mouse in so we can
     * exclusively use the focus selector to style the option and
     * not risk having two options showing as selected when
     * the user combines mouse and keyboard navigation.
     */
    focus(event) {
      event.target.focus();
    }

    blur(event) {
      event.target.blur();
    }

    onKeyDown(event) {
      if (event.which === _keyCodes.SPACE || event.which === _keyCodes.ENTER) {
        event.preventDefault();
        event.target.click();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "focus", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "focus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "blur", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "blur"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype)), _class));
  _exports.default = MenuButtonOption;
});