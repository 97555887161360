define("@outdoorsyco/ember-shared-data/utils/remove-auth-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = removeAuthParams;

  function removeAuthParams(url) {
    if (Ember.isBlank(url)) {
      return null;
    }

    let queryParams = url.slice(1).split('&');

    if (queryParams.length === 0) {
      return;
    }

    return queryParams.filter(param => {
      if (param.indexOf('token=') === -1 && param.indexOf('user_id=') === -1) {
        return param;
      }
    }).join('&');
  }
});