define("@outdoorsyco/ember-switchback/components/rater/star/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray200": "hsl(204, 14%, 89%)",
    "gray400": "hsl(212, 14%, 80%)",
    "orange300": "hsl(31, 94%, 74%)",
    "orange500": "hsl(33, 99%, 57%)",
    "orange600": "hsl(33, 90%, 52%)",
    "red400": "hsl(358, 86%, 64%)",
    "red500": "hsl(358, 80%, 58%)",
    "erroredShadow100": "0px 0px 0px 2px color-mod(hsl(358, 80%, 58%) a(20%))",
    "brand800": "hsl(172, 50%, 28%)",
    "Star": "_Star_16s8qn",
    "state-shadow": "_state-shadow_16s8qn",
    "state-border": "_state-border_16s8qn",
    "fill": "_fill_16s8qn"
  };
  _exports.default = _default;
});