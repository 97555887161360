define("@outdoorsyco/ember-switchback/components/close/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qQjCAppc",
    "block": "{\"symbols\":[\"@class\",\"@position\",\"@onclick\",\"&attrs\"],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[[23,1,[]],\" \",[28,\"local-class\",[\"Close\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/close/styles\"]]]]]],[12,\"data-position\",[23,2,[]]],[12,\"aria-label\",\"Close\"],[12,\"onclick\",[23,3,[]]],[12,\"data-test-close\",\"\"],[13,4],[12,\"type\",\"button\"],[8],[0,\"\\n  \"],[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"viewBox\",\"0 0 16 16\"],[8],[0,\"\\n    \"],[7,\"path\",true],[10,\"d\",\"M15.7.3c-.4-.4-1-.4-1.4 0L8 6.6 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4L6.6 8 .3 14.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3L8 9.4l6.3 6.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L9.4 8l6.3-6.3c.4-.4.4-1 0-1.4z\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/close/template.hbs"
    }
  });

  _exports.default = _default;
});