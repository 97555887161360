define("calendar-picker/templates/components/od-day-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gneGi2WD",
    "block": "{\"symbols\":[\"@day\",\"&default\",\"&attrs\"],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"\\n    ember-power-calendar-day\\n    \",[23,1,[\"class\"]],\"\\n    \",[28,\"if\",[[24,[\"onSelect\"]],\"ember-power-calendar-day--interactive\"],null],\"\\n    \",[28,\"if\",[[23,1,[\"isCurrentMonth\"]],\"ember-power-calendar-day--current-month\",\"ember-power-calendar-day--other-month\"],null],\"\\n    \",[28,\"if\",[[23,1,[\"isSelected\"]],\"ember-power-calendar-day--selected\"],null],\"\\n    \",[28,\"if\",[[23,1,[\"isToday\"]],\"ember-power-calendar-day--today\"],null],\"\\n    \",[28,\"if\",[[23,1,[\"isFocused\"]],\"ember-power-calendar-day--focused\"],null],\"\\n    \",[28,\"if\",[[23,1,[\"isRangeStart\"]],\"ember-power-calendar-day--range-start\"],null],\"\\n    \",[28,\"if\",[[23,1,[\"isRangeEnd\"]],\"ember-power-calendar-day--range-end\"],null],\"\\n  \"]]],[12,\"disabled\",[23,1,[\"isDisabled\"]]],[12,\"data-date\",[23,1,[\"id\"]]],[12,\"label\",[23,1,[\"name\"]]],[13,3],[12,\"type\",\"button\"],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]],[24,[\"publicAPI\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"isSameDay\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[8],[1,[23,1,[\"number\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,1,[\"number\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "calendar-picker/templates/components/od-day-button.hbs"
    }
  });

  _exports.default = _default;
});