define("ember-svg-jar/inlined/map-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><circle id=\"a\" cx=\"15\" cy=\"15\" r=\"7\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"15\" cy=\"15\" r=\"15\" fill=\"#FE9D27\"/><use fill=\"#FE9D27\" xlink:href=\"#a\"/><circle cx=\"15\" cy=\"15\" r=\"10\" stroke=\"#fff\" stroke-opacity=\".679\" stroke-width=\"6\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 30 30"
    }
  };
  _exports.default = _default;
});