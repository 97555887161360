define("@outdoorsyco/ember-shared-data/components/modals/gps-deprecation-warning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JB6IA4B0",
    "block": "{\"symbols\":[\"m\",\"l\"],\"statements\":[[4,\"if\",[[23,0,[\"shouldDisplay\"]]],null,{\"statements\":[[0,\"  \"],[5,\"modal\",[],[[\"@onClose\",\"@maxWidth\"],[[23,0,[\"onClose\"]],\"36\"]],{\"statements\":[[0,\"\\n    \"],[5,\"layout/modal/default\",[],[[\"@modal\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n      \"],[5,\"gps-deprecation-warning\",[],[[],[]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,2,[\"Close\"]],[],[[],[]]],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/modals/gps-deprecation-warning/template.hbs"
    }
  });

  _exports.default = _default;
});