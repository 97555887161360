define("@outdoorsyco/ember-shared-data/adapters/booking-bundle", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BookingBundle extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", 'v0');
    }

    pathForType() {
      return 'bookings/:bookingId/bundles';
    }

    buildURL(type, id, snapshot, requestType, query) {
      let url = super.buildURL(type, id, snapshot, requestType);

      if (query && query.bookingId) {
        url = url.replace(':bookingId', query.bookingId);
        delete query.bookingId;
      }

      if (snapshot) {
        url = url.replace(':bookingId', snapshot.belongsTo('booking', {
          id: true
        }));
      }

      return url;
    }

  }

  _exports.default = BookingBundle;
});