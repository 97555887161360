define("@outdoorsyco/ember-shared-data/serializers/card", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      let {
        modelName
      } = primaryModelClass;
      id = this.extractId(primaryModelClass, payload);

      if (store.hasRecordForId(modelName, id)) {
        let record = store.peekRecord(modelName, id);
        store.unloadRecord(record);
        return this._super(store, primaryModelClass, payload, id, requestType);
      } else {
        return this._super(store, primaryModelClass, payload, id, requestType);
      }
    }

  });

  _exports.default = _default;
});