define("@outdoorsyco/ember-shared-data/serializers/driver-application", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DriverApplicationSerializer extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        requirements: {
          serialize: false
        }
      });
    }

  }

  _exports.default = DriverApplicationSerializer;
});