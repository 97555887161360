define("@outdoorsyco/ember-switchback/components/layout/modal/default/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5zIh+Apk",
    "block": "{\"symbols\":[\"&attrs\",\"@modal\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"  padding-all-1.5 \"],[13,1],[8],[0,\"\\n  \"],[14,3,[[28,\"hash\",null,[[\"Close\",\"Heading\",\"P\",\"ActionBar\"],[[28,\"component\",[\"close\"],[[\"position\",\"onclick\"],[\"top-right\",[28,\"action\",[[23,0,[]],[23,2,[\"close\"]],[28,\"hash\",null,[[\"cancelled\"],[true]]]],null]]]],[28,\"component\",[\"heading\"],[[\"id\"],[[23,2,[\"labelledbyId\"]]]]],[28,\"component\",[\"p\"],null],[28,\"component\",[\"layout/modal/default/action-bar\"],null]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/layout/modal/default/template.hbs"
    }
  });

  _exports.default = _default;
});