define("@outdoorsyco/ember-switchback/components/counter/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Counter": "_Counter_mamm7j",
    "value": "_value_mamm7j"
  };
  _exports.default = _default;
});