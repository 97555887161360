define("@outdoorsyco/ember-shared-data/helpers/format-symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatSymbol = formatSymbol;
  _exports.default = void 0;
  const formatSymbolHelper = Ember.Helper.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),

    compute(params, hash) {
      // set default options, cannot modify incoming read-only hash
      let options = Object.assign({}, hash || {});

      if (Ember.isBlank(options['format'])) {
        let currency = this.get('session.currentAccount.localeBaseCurrency') || this.get('session.currentUser.localeBaseCurrency') || 'USD';
        options['format'] = currency;
      }

      options['style'] = 'currency';
      options['currency'] = options['format'];
      options['fallback'] = 0;
      options['minimumFractionDigits'] = 0;
      options['minimumSignificantDigits'] = 1;
      let result = this.get('intl').formatNumber(0, options);

      if (result) {
        // replaces digits and any whitespace and leaves just the symbol
        result = result.replace(/(?:\d)+(?:\s)*/, '');
      }

      return result;
    }

  });

  function formatSymbol(context, hash) {
    let f = formatSymbolHelper.create();
    Ember.setOwner(f, Ember.getOwner(context));
    return f.compute(null, hash);
  }

  var _default = formatSymbolHelper;
  _exports.default = _default;
});