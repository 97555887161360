define("@outdoorsyco/ember-shared-data/services/card-setup", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CardSetupService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class CardSetupService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "apiRequest", _descriptor, this);

      _initializerDefineProperty(this, "stripev3", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "createStripeSetupIntent", _descriptor5, this);

      _initializerDefineProperty(this, "createStripePaymentIntent", _descriptor6, this);

      _initializerDefineProperty(this, "setupCard", _descriptor7, this);

      _initializerDefineProperty(this, "confirmCard", _descriptor8, this);

      _initializerDefineProperty(this, "confirmCardPayment", _descriptor9, this);
    }

    async tryToSetupCardForBooking(booking, cardOwner, card) {
      if (booking.hasSetupIntentEnabled) {
        return await this.setupCard.perform({
          cardOwner,
          booking,
          card
        });
      }

      return;
    }

    async shouldMakeRequest(booking) {
      if (booking === undefined) {
        //always make the request when not paying/setting up card for a booking
        return true;
      } //fetch the latest data - this booking might have expired


      booking = await this.get('store').findRecord('booking', booking.id, {
        reload: true
      });
      return booking.status !== 'expired';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stripev3", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createStripeSetupIntent", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* ({
        bookingId,
        cardOwnerId,
        cardId,
        cardToken,
        chargeType
      }) {
        return yield this.apiRequest.post('/stripe/intents/setup', {
          data: {
            booking_id: bookingId,
            card_owner_id: cardOwnerId,
            card_id: cardId,
            card_token: cardToken,
            type: chargeType
          }
        });
      };
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "createStripePaymentIntent", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* ({
        bookingId,
        cardOwnerId,
        cardId,
        cardToken,
        chargeType,
        amount,
        authorize
      }) {
        return yield this.apiRequest.post('/stripe/intents/payment', {
          data: {
            authorize: authorize,
            booking_id: bookingId,
            card_owner_id: cardOwnerId,
            card_id: cardId,
            card_token: cardToken,
            type: chargeType,
            amount: amount
          }
        });
      };
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "setupCard", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* ({
        booking,
        cardOwner,
        card,
        chargeType
      }) {
        let cardId, cardToken;

        if (card.key !== undefined) {
          //needed for dealer checkout
          cardId = card.key === 'card_id' ? card.id : 0;
          cardToken = card.key === 'card_token' ? card.id : '';
        } else {
          cardId = card.id;
          cardToken = '';
        }

        const setupIntentResponse = yield this.createStripeSetupIntent.perform({
          bookingId: booking !== undefined ? booking.id : 0,
          cardOwnerId: cardOwner !== undefined ? cardOwner.id : 0,
          cardId: cardId,
          cardToken: cardToken,
          chargeType: chargeType
        });

        if (!setupIntentResponse.needs_confirmed) {
          return setupIntentResponse;
        }

        const setupResponse = yield this.stripev3.handleCardSetup(setupIntentResponse.client_secret, {
          payment_method: setupIntentResponse.stripe_card_id
        }).catch(error => {
          throw error;
        });

        if (setupResponse && setupResponse.error) {
          throw setupResponse.error;
        }

        return setupResponse;
      };
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "confirmCard", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* ({
        booking,
        cardOwner,
        card,
        chargeType,
        amount,
        authorize
      }) {
        let cardId, cardToken;

        if (card.key !== undefined) {
          //needed for dealer checkout
          cardId = card.key === 'card_id' ? card.id : 0;
          cardToken = card.key === 'card_token' ? card.id : '';
        } else {
          cardId = card.id;
          cardToken = '';
        }

        let intentResponse;
        const shouldMakeRequest = yield this.shouldMakeRequest(booking);

        if (shouldMakeRequest) {
          intentResponse = yield this.createStripePaymentIntent.perform({
            authorize: authorize === true,
            bookingId: booking !== undefined ? booking.id : 0,
            cardOwnerId: cardOwner !== undefined ? cardOwner.id : 0,
            cardId: cardId,
            cardToken: cardToken,
            chargeType: chargeType,
            amount: amount
          });

          if (intentResponse.amount === 0 && intentResponse.paid_with_credits) {
            return {
              id: 0
            };
          }
        } else {
          throw new Error(this.intl.t('booking-expired-error'));
        }

        const confirmResponse = yield this.confirmCardPayment.perform({
          secret: intentResponse.client_secret,
          cardId: intentResponse.stripe_card_id
        });

        if (confirmResponse && confirmResponse.error) {
          throw confirmResponse.error;
        }

        return intentResponse;
      };
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "confirmCardPayment", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* ({
        cardId,
        secret
      }) {
        return yield this.stripev3.handleCardPayment(secret, {
          payment_method: cardId,
          setup_future_usage: 'off_session'
        }).catch(error => {
          throw error;
        });
      };
    }
  })), _class));
  _exports.default = CardSetupService;
});