define("@outdoorsyco/ember-nydus/adapters/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTAdapter = _emberData.default.RESTAdapter;

  var _default = RESTAdapter.extend({
    session: Ember.inject.service(),
    namespace: null,
    host: Ember.computed(function () {
      return this.appConfig().APP.nydusHost;
    }),

    // just for setting method as PATCH
    // this won't be necessary in future ember versions
    updateRecord(store, type, record) {
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, record);
      let {
        id
      } = record;
      return this.ajax(this.buildURL(type.modelName, id, record, "updateRecord"), "PATCH", {
        data
      });
    },

    headers: Ember.computed(function () {
      let h = {};
      let accessToken = this.get('session.data.authenticated.token');

      if (Ember.isPresent(accessToken)) {
        h['Authorization'] = `Token=${accessToken.replace('guest:', '')}`;
      }

      return h;
    }).volatile(),

    appConfig() {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }

  });

  _exports.default = _default;
});