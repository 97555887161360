define("@outdoorsyco/ember-shared-data/components/survey-show-short-answer", ["exports", "@outdoorsyco/form-components/components/input-field/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    question: null,
    value: Ember.computed.alias('question.value'),
    error: Ember.computed('errorMsg', function () {
      return {
        message: this.get('errorMsg')
      };
    })
  });

  _exports.default = _default;
});