define("@outdoorsyco/ember-shared-data/validator/password-strength", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.password = password;
  _exports.default = void 0;

  function password(value, passwordStrength) {
    const noValueToValidate = Ember.isEmpty(value);
    const validValue = passwordStrength >= 3;
    return noValueToValidate || validValue;
  }

  var _default = {
    param: 'passwordStrength',
    error: {
      key: 'error.validator.passwordStrength',
      message: 'Your password is not strong enough.'
    },
    validate: password
  };
  _exports.default = _default;
});