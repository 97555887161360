define("@outdoorsyco/form-components/components/textarea-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yn9XpQ+v",
    "block": "{\"symbols\":[\"@label\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"form-group textarea-field \",[28,\"if\",[[24,[\"tip\"]],\"textarea-with-tip\"],null],\" \",[28,\"if\",[[24,[\"hasError\"]],\"has-error\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[11,\"for\",[22,\"formElementId\"]],[10,\"class\",\"form-label\"],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"required\"],[8],[0,\"*\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"form-control-input\"],[8],[0,\"\\n    \"],[1,[28,\"textarea\",null,[[\"autoresize\",\"id\",\"type\",\"value\",\"placeholder\",\"name\",\"class\"],[[24,[\"autoresize\"]],[24,[\"formElementId\"]],[24,[\"type\"]],[24,[\"value\"]],[24,[\"placeholder\"]],[24,[\"name\"]],\"form-control\"]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"tip\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"input-tip alert alert-success\"],[8],[0,\"\\n      \"],[1,[22,\"tip\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"hasError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"help-block \",[28,\"if\",[[24,[\"hasError\"]],\"has-error\"],null]]]],[8],[1,[24,[\"error\",\"message\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/form-components/components/textarea-field/template.hbs"
    }
  });

  _exports.default = _default;
});