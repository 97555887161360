define("@outdoorsyco/outdoorsy-addon/components/instamatch-form/component", ["exports", "ember-cp-validations", "@outdoorsyco/outdoorsy-addon/components/instamatch-form/template", "moment"], function (_exports, _emberCpValidations, _template, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    pickupEntered: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please provide a pickup location'
    }),
    datesEntered: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please provide dates for your trip'
    }),
    destinationEntered: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please provide a destination'
    }),
    travelers: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'How many people are traveling with you?'
    })
  });

  var _default = Ember.Component.extend(Validations, {
    layout: _template.default,
    classNames: ['instamatch-form'],
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    showErrors: false,
    pickupLat: null,
    pickupLng: null,
    pickupEntered: Ember.computed.and('pickupLat', 'pickupLng'),
    pickupLocation: null,
    travelers: null,
    destinationLat: null,
    destinationLng: null,
    destinationEntered: Ember.computed.and('destinationLat', 'destinationLng'),
    destinationLocation: null,
    type: 'all',
    petFriendly: null,
    priceType: 'all',
    from: null,
    to: null,
    datesEntered: Ember.computed.and('from', 'to'),
    comment: null,
    destinationTypes: ['geocode', 'establishment'],
    // select options
    travelerOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    shortForm: false,
    isFullForm: Ember.computed.not('shortForm'),
    selectedDates: Ember.computed('from', 'to', function () {
      let fromDate = this.get('from');
      let toDate = this.get('to');
      let intl = this.get('intl');
      fromDate = fromDate && (0, _moment.default)(fromDate).isValid() ? (0, _moment.default)(fromDate).format('MMM D, YYYY') : null;
      toDate = toDate && (0, _moment.default)(toDate).isValid() ? (0, _moment.default)(toDate).format('MMM D, YYYY') : null;
      return fromDate || toDate ? `${fromDate || intl.t('term-departure')}  →  ${toDate || intl.t('term-return')}` : null;
    }),
    actions: {
      pickupLocationFound(lat, lng, basic, detailed) {
        this.setProperties({
          pickupLat: lat,
          pickupLng: lng,
          pickupLocation: detailed.formatted_address
        });
      },

      destinationLocationFound(lat, lng, basic, detailed) {
        this.setProperties({
          destinationLat: lat,
          destinationLng: lng,
          destinationLocation: detailed.formatted_address
        });
      },

      setDate(from, to) {
        this.setProperties({
          from,
          to
        });
      },

      createInstamatch() {
        this.validate().then(({
          validations
        }) => {
          this.set('showErrors', true);

          if (!validations.get('isValid')) {
            this.get('flashMessages').danger('Oops, some fields need your attention!');
            return;
          }

          this.set('savingInstamatch', true);

          let type = this._getType();

          let {
            minPrice,
            maxPrice
          } = this._getPrice();

          let instamatch = this.get('store').createRecord('quoteRequest', {
            minDayPrice: minPrice,
            maxDayPrice: maxPrice,
            type,
            comments: this.get('comment'),
            pickupLat: this.get('pickupLat'),
            pickupLng: this.get('pickupLng'),
            destination: this.get('destinationLocation'),
            destLat: this.get('destinationLat'),
            destLng: this.get('destinationLng'),
            petFriendly: this.get('petFriendly'),
            from: this.get('from'),
            to: this.get('to'),
            travelers: this.get('travelers')
          });
          instamatch.save().then(() => {
            if (this.get('onComplete')) {
              this.get('onComplete')(instamatch);
              return;
            }
          }).catch(() => {
            this.get('flashMessages').danger('There was an issue creating your request');
          }).finally(() => {
            this.set('savingInstamatch', false);
          });
        });
      },

      cancelInstamatch() {
        if (this.get('onCancel')) {
          this.get('onCancel')();
        }
      }

    },

    _getType() {
      let type;

      switch (this.get('type')) {
        case 'towable':
          type = 'trailer,folding-trailer,fifth-wheel,toy-hauler';
          break;

        case 'drivable':
          type = 'a,b,c,camper-van,truck-camper';
          break;

        default:
          type = 'a,b,c,camper-van,truck_camper,trailer,folding-trailer,fifth-wheel,toy-hauler';
      }

      return type;
    },

    _getPrice() {
      let minPrice, maxPrice;

      switch (this.get('priceType')) {
        case 'budget':
          minPrice = null;
          maxPrice = 150;
          break;

        case 'luxury':
          minPrice = 150;
          maxPrice = null;
          break;
      }

      return {
        minPrice,
        maxPrice
      };
    }

  });

  _exports.default = _default;
});