define("@outdoorsyco/outdoorsy-addon/components/user-response-rate/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/user-response-rate/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    responsePercentage: Ember.computed('user.responsePercentage', function () {
      return parseInt(this.get('user.responsePercentage'));
    })
  });

  _exports.default = _default;
});