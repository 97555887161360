define("@outdoorsyco/ember-shared-data/components/input/phone-number/four-digits/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PhoneNumberFourDigitsComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class PhoneNumberFourDigitsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "digitInputs", _descriptor, this);

      _initializerDefineProperty(this, "currentValue", _descriptor2, this);

      _initializerDefineProperty(this, "didResend", _descriptor3, this);

      _initializerDefineProperty(this, "errorHighlight", _descriptor4, this);
    }

    /**
     * Stores the 4 input elements representing the four digits.
     * We access them all the time to get values and check integrity.
     */
    registerInput(element) {
      if (this.digitInputs.length === 0) {
        element.focus();
      }

      this.digitInputs.push(element);
    }
    /**
     * On keydown event, we ignore value changes for remove type keys such as
     * backspace(8), space(32) and delete(46). If the pressed key is a number,
     * and the total value of digits isn't yet 4, we update the value, otherwise
     * we trigger a reset to start over.
     */


    onKeyDown(digit, event) {
      const digitObject = this.digitInputs[digit];
      const eventInput = event.key !== 'Unidentified' ? event.key : event.target.value;
      const digitValue = parseInt(eventInput);
      const isBackSpace = Boolean(event.keyCode === 8);
      const isRemoveKey = [8, 32, 46].includes(event.keyCode);

      if (isNaN(digitValue) && !isBackSpace) {
        digitObject.value = null;
        event.preventDefault();
        return;
      }

      if (isRemoveKey) {
        if (digit > 0 && isBackSpace) {
          const prevDigit = this.digitInputs[digit - 1];
          prevDigit.focus();
        }

        digitObject.value = null;
        this.updateValue(null, true);
        event.preventDefault();
        return;
      }

      if (digit <= 3) {
        const nextDigit = this.digitInputs[digit + 1];
        digitObject.value = digitValue;
        this.updateValue(digitValue);

        if (digit === 3) {
          digitObject.blur();

          this._onReady();
        } else {
          nextDigit.focus();
          event.preventDefault();
        }
      }

      if (this.currentValue.length > 4) {
        this._reset();

        this.digitInputs[0].value = digitValue;
        this.updateValue(digitValue);
        this.digitInputs[1].focus();
        event.preventDefault();
        return;
      }
    }
    /**
     * Handles keyup event, necessary as a hack for tests (qunit typeIn helper)
     * but also prevents possible unwanted behaviour.
     */


    onKeyUp(event) {
      const value = event.target.value;

      if (value.length > 1) {
        event.target.value = value.substring(0, value.length - 1);
      }

      return event.preventDefault();
    }
    /**
     * Adds a number character at the end of 'currentValue',
     * invokes data owner's 'onChange' function, if provided.
     */


    updateValue(value, isRemove) {
      this.currentValue = isRemove ? this.currentValue.substring(0, this.currentValue.length - 1) : this.currentValue + value;
      this.errorHighlight = false;
      Ember.tryInvoke(this.args, 'onChange', [this.currentValue]);
    }
    /**
     * Calls component's invoker 'onReady' function,
     * handle errors if the invocation fails;
     */


    async _onReady() {
      this.didResend = false;

      try {
        await Ember.tryInvoke(this.args, 'onReady', [this.currentValue]);
        this.errorHighlight = false;
      } catch (error) {
        this.errorHighlight = true;
        this.digitInputs[0].focus();
      }
    }
    /**
     * Resets most of the component flags and states
     */


    _reset() {
      this.digitInputs.forEach(d => {
        d.value = '';
        d.blur();
        this.updateValue('', true);
      });
      this.currentValue = '';
      this.digitInputs[0].focus();
      this.errorHighlight = false;
    }
    /**
     * Resets the component's state and invokes a
     * data owner provided 'onResend', is provided
     */


    resend() {
      this._reset();

      this.didResend = true;
      Ember.tryInvoke(this.args, 'onResend', [this.currentValue]);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "digitInputs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentValue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "didResend", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorHighlight", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "registerInput", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "registerInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyUp", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateValue", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resend", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "resend"), _class.prototype)), _class));
  _exports.default = PhoneNumberFourDigitsComponent;
});