define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/collapsible-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MrXUFWtp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"collapsible-footer \",[28,\"if\",[[24,[\"isActive\"]],\"active\",\"\"],null]]]],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"collapsible-footer-toggle\"],[12,\"data-test-collapsible-footer-toggle\",\"\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isActive\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"collapsible-footer-toggle-icon\"],[10,\"data-test-collapsible-footer-toggle-icon\",\"\"],[8],[0,\"\\n          \"],[1,[28,\"svg-jar\",[\"close\"],[[\"height\",\"width\"],[\"16\",\"16\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"data-test-collapsible-footer-toggle-label\",\"\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"comp-footer-toggle-close\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"collapsible-footer-toggle-icon\"],[10,\"data-test-collapsible-footer-toggle-icon\",\"\"],[8],[0,\"\\n          \"],[1,[28,\"svg-jar\",[\"arrow-up\"],[[\"height\",\"width\"],[\"16\",\"16\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"data-test-collapsible-footer-toggle-label\",\"\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"comp-footer-toggle-open\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"collapsible-footer-content\"],[8],[0,\"\\n    \"],[1,[22,\"outdoorsy/footer-component\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/outdoorsy/collapsible-footer/template.hbs"
    }
  });

  _exports.default = _default;
});