define("@outdoorsyco/ember-shared-data/utils/load-script", ["exports", "ember-metrics/utils/can-use-dom"], function (_exports, _canUseDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadScript;

  /*
   * loadScript will load a JavaScript asset. Subsequent load
   * calls with a already requested src or text will just chain off the
   * already existing promise.
   */
  let loadedScripts = {};

  function loadScript({
    src,
    text,
    type = 'text/javascript',
    cache = true,
    async = false
  } = {}) {
    const scriptId = src || text;
    let promise = cache ? loadedScripts[scriptId] : null;

    if (!promise && _canUseDom.default) {
      promise = new Ember.RSVP.Promise((resolve, reject) => {
        let element = document.createElement('script');
        element.type = type;
        element.async = async;
        element.addEventListener('load', () => {
          Ember.run(() => {
            resolve();
          });
        }, false);
        element.addEventListener('error', () => {
          let error = new Error(`Could not load script ${scriptId}`);
          Ember.run(() => {
            reject(error);
          });
        }, false);

        if (src) {
          element.src = src;
        }

        if (text) {
          element.text = text;
        }

        let firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(element, firstScript);
      });
      loadedScripts[scriptId] = promise;
    }

    return promise;
  }
});