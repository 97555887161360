define("@outdoorsyco/ember-switchback/components/button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @documenter esdoc */

  /**
   * A basic button.
   *
   * If you provide it with a `href` it will use an `<a>` tag while retaining
   * the visual aesthetic of a button. You may also override the tag and use
   * any tag you want.
   *
   * ```handlebars
   * <Button @onClick={{action "continue"}}>
   *  Continue
   * </Button>
   *
   * <Button @href="https://www.outdoorsy.com">
   *   Visit Outdoorsy
   * </Button>
   *
   * <Button @tag="span">
   *   Select
   * </Button>
   * ```
   * @public
   */
  class Button extends _component.default {
    /**
     * Calculates the element tag the element should used based on whether
     * it is acting like a button or a link. This does not recompute because
     * the element should not change after the first render.
     */
    get computedElement() {
      return this.args.tagName || this.args.href ? 'a' : 'button';
    }

  }

  _exports.default = Button;
});