define("@outdoorsyco/ember-shared-data/components/map/static/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jKePrwtG",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[4,\"if\",[[23,0,[\"src\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",false],[12,\"class\",[29,[[28,\"local-class\",[\"image\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/map/static/styles\"]]]]]],[12,\"src\",[23,0,[\"src\"]]],[12,\"width\",[23,0,[\"width\"]]],[12,\"height\",[23,0,[\"height\"]]],[12,\"loading\",\"lazy\"],[13,1],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/map/static/template.hbs"
    }
  });

  _exports.default = _default;
});