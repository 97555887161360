define("@outdoorsyco/ember-shared-data/components/input/phone-number/four-digits/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray500": "hsl(208, 12%, 72%)",
    "green400": "hsl(149, 50%, 64%)",
    "red500": "hsl(358, 80%, 58%)",
    "gray900": "hsl(207, 6%, 23%)",
    "PhoneNumberFourDigits": "_PhoneNumberFourDigits_190ik0",
    "digit": "_digit_190ik0",
    "digits": "_digits_190ik0",
    "error": "_error_190ik0"
  };
  _exports.default = _default;
});