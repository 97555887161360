define("@outdoorsyco/ember-shared-data/serializers/review", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class ReviewSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        rentalTags: {
          deserialize: 'records',
          serialize: 'records'
        },
        reviewer: {
          deserialize: 'records',
          serialize: 'id'
        },
        owner: {
          deserialize: 'records',
          serialize: false
        },
        reviewImages: {
          deserialize: 'records',
          serialize: 'records'
        }
      });
    }

    keyForRelationship(key, type, method) {
      /* I don't quite understand why method is undefined.
         I assumed it would be serialize or deserialize */
      if ((key === 'reviewer' || key === 'owner') && method === undefined) {
        return key;
      }

      return super.keyForRelationship(key, type, method);
    }

    serialize(record, options) {
      let data = super.serialize(record, options);

      if (data.rental_tags) {
        data.tags = data.rental_tags;
        delete data.rental_tags;
      }

      return data;
    }

  }

  _exports.default = ReviewSerializer;
});