define("@outdoorsyco/ember-shared-data/instance-initializers/load-odc-metrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    // trigger the init() of metrics service, which activates the adapters
    appInstance.lookup('service:metrics');
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});