define("@outdoorsyco/ember-shared-data/helpers/typeof", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function ([obj]) {
    return typeof obj;
  });

  _exports.default = _default;
});