define("@outdoorsyco/ember-shared-data/components/provider/passengers/passenger/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @argument {passenger} passenger - The passenger object
   * @argument {booking} booking - The booking that the passenger is on
   */
  let PassengersPassengerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.computed.alias('args.booking'), _dec9 = Ember.computed.alias('args.passenger'), _dec10 = Ember.computed.alias('args.passenger.personaCheckVerified'), _dec11 = Ember.computed.alias('args.booking.passengers'), _dec12 = Ember.computed.alias('args.passenger.checks'), _dec13 = Ember.computed.alias('args.booking.renter.id'), _dec14 = Ember.computed.alias('session.currentUser.id'), _dec15 = Ember.computed.alias('session.currentAccount'), _dec16 = Ember.computed.alias('session.currentUser'), (_class = class PassengersPassengerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "environment", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "prompt", _descriptor4, this);

      _initializerDefineProperty(this, "persona", _descriptor5, this);

      _initializerDefineProperty(this, "apiRequest", _descriptor6, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor7, this);

      _initializerDefineProperty(this, "booking", _descriptor8, this);

      _initializerDefineProperty(this, "passenger", _descriptor9, this);

      _initializerDefineProperty(this, "personaCheckVerified", _descriptor10, this);

      _initializerDefineProperty(this, "passengers", _descriptor11, this);

      _initializerDefineProperty(this, "checks", _descriptor12, this);

      _initializerDefineProperty(this, "renterId", _descriptor13, this);

      _initializerDefineProperty(this, "currentUserId", _descriptor14, this);

      _initializerDefineProperty(this, "currentAccount", _descriptor15, this);

      _initializerDefineProperty(this, "currentUser", _descriptor16, this);

      let options = {
        coverageAlert: true
      };

      if (this.forceUpdateDriverValidation) {
        this.addPassenger(options);
      }
    }

    get complete() {
      return this.passenger.verified || this.passenger.inviteStatus === 'complete';
    }

    get statusLevelColor() {
      switch (this.passenger.statusKey) {
        case 'invite-sent':
          return 'gray700';

        case 'driver':
          return 'green800';

        case 'driver-skipped':
          return 'orange500';

        case 'driver-pending':
          return 'gray900';

        case 'driver-failed':
          return 'red500';

        case 'driver-not-verified':
          return 'orange500';

        default:
          return 'green800';
      }
    }

    get passengerIsCurrentUser() {
      return this.passenger.id === this.currentUserId;
    }

    get renterIsCurrentUser() {
      return this.renterId === this.currentUserId;
    }

    get shouldUpdateBirthdate() {
      return this.passenger.role === 'driver' && this.passenger.hasRiskCheck && !this.passenger.ageVerified && !this.passenger.birthdate;
    }

    get canUpdateBirthdate() {
      return this.passengerIsCurrentUser || this.currentAccount.get('dealer') && this.passenger.ownedById;
    }

    get shouldAddDriverLicenseCheck() {
      return this.passenger.role === 'driver' && !this.passenger.hasDrivingRecordCheck && parseInt(this.currentUser.get('id')) === parseInt(this.passenger.id) && this.booking.requireDrivers;
    }

    get needsDriverVerification() {
      let isDriver = Ember.get(this.passenger, 'role') === 'driver'; // Mirage is causing one ID to be a string and one ID to be a number

      let currentUserIsPassenger = Number(Ember.get(this.currentUser, 'id')) === Number(Ember.get(this.passenger, 'id'));
      let hasRiskCheck = Ember.get(this.passenger, 'hasRiskCheck');
      return isDriver && currentUserIsPassenger && !hasRiskCheck;
    }

    get driverVerificationUrl() {
      return `${this.environment.originForSearch}/driver-application/${this.booking.id}`;
    }

    get needsPersonaVerification() {
      let isDriver = Ember.get(this.passenger, 'role') === 'driver';
      let requirePersona = Ember.get(this.passenger, 'requirePersona');
      let currentUserIsPassenger = Ember.get(this.currentUser, 'id') === Ember.get(this.passenger, 'id');
      let personaCheckNotPassed = !Ember.get(this.passenger, 'personaCheckVerified');
      return isDriver && requirePersona && currentUserIsPassenger && personaCheckNotPassed;
    }

    get needsEvidentVerification() {
      return this.passenger.role === 'driver' && this.passenger.requireEvident && parseInt(this.currentUser.get('id')) === parseInt(this.passenger.id) && !this.passenger.evidentCheckVerified;
    }

    get tintCheck() {
      return (this.checks || []).findBy('method', 'tint');
    }

    get verificationUploadLink() {
      return (this.checks || []).find(check => {
        return check.verification_upload_link;
      });
    }
    /**
     * hasDriverWithPendingOrSuccessfulValidationStatus - checks whether the booking has any drivers
     * with a passed, pending or processing validation status
     */


    get hasDriverWithPendingOrSuccessfulValidationStatus() {
      let drivers = (this.passengers || []).filterBy('role', 'driver');
      return drivers.any(driver => {
        return ['passed', 'pending', 'processing', ''].includes(driver.validationStatus);
      });
    }

    get shouldUploadDriversLicense() {
      return this.passenger.role === 'driver' && !(this.renterIsCurrentUser && this.tintCheck) && this.tintCheck && this.tintCheck.status !== 'upload_received' && this.verificationUploadLink;
    }
    /**
     * forceUpdateDriverValidation - check for a failed validation status on a driver and force a display
     * of the add driver insurance protection modal if validation is failed
     */


    get forceUpdateDriverValidation() {
      return this.passenger.role === 'driver' && this.renterIsCurrentUser && !this.hasDriverWithPendingOrSuccessfulValidationStatus && ['failed', 'error'].includes(this.passenger.validationStatus);
    }
    /**
     * addPassenger - reveals the add-passenger modal with coverage alert styling
     * @param {object} options - a hash of additional options to be merged into data passed to the
     * modal
     */


    async addPassenger(options = {}) {
      await this.prompt.show('prompt/add-passenger', Object.assign({
        booking: this.args.booking,
        location: 'booking/show'
      }, options), {
        minWidth: 22,
        maxWidth: 26
      });
      await this.args.booking.hasMany('passengers').reload();
    }

    updateBirthdate(options = {}) {
      this.prompt.show('driver-missing-fields', Object.assign({
        driver: this.args.passenger,
        booking: this.args.booking
      }, options), {
        minWidth: 100
      });
    }

    async addDriverLicenseCheck() {
      await this.prompt.show('driving-check-modal', {
        driverCheckOnly: true,
        user: this.session.currentUser
      }, {
        minWidth: 36
      });
      this.booking.reload();
    }

    launchPersona() {
      this.persona.open({
        user: this.currentUser,
        onComplete: () =>
        /*meta*/
        {
          // mark true to hide button
          // this is not causing needsPersonaVerification to recompute as promised.
          Ember.set(this, 'passenger.personaCheckVerified', true);
        }
      });
    }

    async evidentVerificationLink() {
      try {
        let response = await this.apiRequest.request(`drivers/${this.currentUserId}/id-verification`);

        if (response.link) {
          window.open(response.link, '_blank');
        }
      } catch (error) {
        this.flashMessages.danger(this.intl.t('comp-id-verification-error'));
      }

      return null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "prompt", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "persona", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "booking", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "passenger", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "personaCheckVerified", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "passengers", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "checks", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "renterId", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "currentUserId", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "currentAccount", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PassengersPassengerComponent;
});