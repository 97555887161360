define("@outdoorsyco/outdoorsy-addon/utils/currencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currenciesArray = void 0;
  const currenciesArray = [{
    currency: 'AUD',
    translatedName: 'currency-aud',
    // translation
    name: '$AUD'
  }, {
    currency: 'CAD',
    translatedName: 'currency-cad',
    // translation
    name: '$CAD'
  }, {
    currency: 'EUR',
    translatedName: 'currency-eur',
    // translation
    name: '€EUR'
  }, {
    currency: 'GBP',
    translatedName: 'currency-gbp',
    // translation
    name: '£GBP'
  }, {
    currency: 'NZD',
    translatedName: 'currency-nzd',
    // translation
    name: '$NZD'
  }, {
    currency: 'USD',
    translatedName: 'currency-usd',
    // translation
    name: '$USD'
  }];
  _exports.currenciesArray = currenciesArray;
});