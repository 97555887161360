define("@outdoorsyco/outdoorsy-addon/components/ui-button/pill/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/ui-button/pill/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    //passed in variables
    name: null,
    isHidden: false,
    isSelected: false,
    addableObject: null,
    actions: {
      add() {
        (false && !(typeof this.get('onAdd') !== 'undefined') && Ember.assert(`ui-button/pill needs an 'onAdd' action`, typeof this.get('onAdd') !== 'undefined'));
        this.get('onAdd')(this.get('addableObject'));
      }

    }
  });

  _exports.default = _default;
});