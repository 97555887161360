define("@outdoorsyco/ember-shared-data/helpers/date-formatter", ["exports", "moment", "@outdoorsyco/ember-shared-data/formats", "@outdoorsyco/ember-shared-data/utils/can-use-dom"], function (_exports, _moment, _formats, _canUseDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Format/localize dates with either an explicit custom format or fallback to a default
   * we will maintain a limited set of "localizable" custom formats found in addon/formats.js
   * we will use a subset of the moment.js token set:
   * https://docs.google.com/spreadsheets/d/1lPzBlmJVAkN6HUw28wBJmY1SdbSInRIBY0JCmBUDUmk/edit?amp;hl=en_US#gid=0
   * NOTE: we will not provide for ordinals... I.E. "Do", as this complicates the formatting for other languages too much :)
   *
   * @param {string[]} positionalParams
   * @param {!string} positionalParams[0] - Date to be formatted
   * @param {string} positionalParams[1] - Deprecated. Use options.inputFormat instead.
   * @param {string} positionalParams[2] - Deprecated. Use options.outputFormat instead.
   * @param {?object} options - Formatting options
   * @param {?string} options.inputFormat - An explicit format passed to moment to define a known date structure.
   * @param {?string} [options.outputFormat='MMM D']
   * @param {?string} [options.locale=currentUser.localeLanguage]
   * @param {?string} [options.timeZome=Intl.DateTimeFormat().resolvedOptions().timeZone]
   */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    // eslint-disable-next-line ember/no-observers
    onLocaleChange: Ember.observer('session.user.localeLanguage', function () {
      this.recompute();
    }),

    compute([value, format, opFormat], options) {
      let intl = this.get('intl');

      if (Ember.isEmpty(value)) {
        return intl.t('term-invalid-data');
      } // current time


      if (value === 'now') {
        value = (0, _moment.default)();
      }

      const formatOptions = Object.assign({}, options); // copy to unbind compute hash?

      const inputFormat = formatOptions.inputFormat || format;
      const defaultUserLocale = _canUseDom.default ? navigator.language : null;
      const userDefinedLocale = this.get('session.user.localeLanguage');
      const locale = formatOptions.locale || userDefinedLocale || defaultUserLocale || 'en-us';
      const outputFormat = formatOptions.outputFormat ? _formats.default.date[formatOptions.outputFormat] : _formats.default.date['MMM D'];
      (false && !(outputFormat) && Ember.assert(`An invalid "localized date format" of ${formatOptions.outputFormat} has been passed to the date-formatter helper, falling back to default`, outputFormat)); // if an invalid format was passed fallback to 'MMM D' default

      let resolvedFormat = outputFormat || _formats.default.date['MMM D'];
      let date;

      _moment.default.locale(locale);

      if (formatOptions.timeZone) {
        resolvedFormat = Object.assign({}, resolvedFormat, {
          timeZone: formatOptions.timeZone
        });
      }

      if (_moment.default.isMoment(value)) {
        date = value.clone();
      } else {
        /* Use "utc" as input format parameter if you'll use a server's RFC3339 format */
        if (inputFormat === 'utc') {
          date = _moment.default.utc(value); // if given an RFC3339 formatted string, parse it
        } else if (value.length > 10 && value[10] === 'T') {
          date = (0, _moment.default)(value);
        } else {
          let _format = inputFormat ? inputFormat : ['YYYY-MM-DD', 'MM/DD/YYYY'];

          date = (0, _moment.default)(value, _format);
        }
      }

      if (!date.isValid()) {
        return intl.t('term-invalid-data');
      } // show deprecation warning if the instance is called with old style params


      if (format || opFormat || formatOptions.outputFormat && /o/.test(formatOptions.outputFormat)) {
        return this._handleDeprecatedFormatting(date, opFormat);
      }

      let formattedDateObj;

      try {
        formattedDateObj = new Intl.DateTimeFormat(locale, resolvedFormat);
      } catch (error) {
        if (/Invalid time zone specified/.test(error.message) && resolvedFormat.timeZone) {
          delete resolvedFormat.timeZone;
          console.warn('date-formatter was passed a timezone it could not handle and fell back to local timezone: ', error.message);
          formattedDateObj = new Intl.DateTimeFormat(locale, resolvedFormat);
        }
      }

      return formattedDateObj.format(date.toDate());
    },

    _handleDeprecatedFormatting(date, outputFormat) {
      const deprecationWarning = 'Passing moment.js style format options as positional params is being deprecated. See link for usage';
      (false && !(false) && Ember.deprecate(deprecationWarning, false, {
        id: 'ember-data-services.date-formatter',
        until: 'we all stop using it ;)',
        url: 'https://github.com/outdoorsy/ember-data-services/blob/master/addon/helpers/date-formatter.js'
      }));

      let _outputFormat = outputFormat ? outputFormat : 'MMM Do';

      return date.format(_outputFormat);
    }

  });

  _exports.default = _default;
});