define("@outdoorsyco/ember-switchback/validators/max", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.max = max;
  _exports.default = void 0;

  function max(value, max) {
    const validatorIsDisabled = !max;
    const noValueToValidate = Ember.isEmpty(value);
    const validValue = parseFloat(value) <= parseFloat(max);
    return validatorIsDisabled || noValueToValidate || validValue;
  }

  var _default = {
    param: 'max',
    error: {
      key: 'error.validator.max',
      message: 'This must be %@ or less.'
    },
    validate: max
  };
  _exports.default = _default;
});