define("@outdoorsyco/ember-shared-data/components/vehicle-location-map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RkTxRA9h",
    "block": "{\"symbols\":[\"g\"],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"pickupLat\"]],[24,[\"pickupLng\"]],[24,[\"googleLoaded\"]]],null]],null,{\"statements\":[[4,\"g-map\",null,[[\"lat\",\"lng\",\"zoom\",\"options\",\"onComponentsLoad\"],[[24,[\"pickupLat\"]],[24,[\"pickupLng\"]],[24,[\"zoom\"]],[24,[\"mapOptions\"]],[28,\"action\",[[23,0,[]],\"onMapLoad\"],null]]],{\"statements\":[[1,[23,1,[\"canvas\"]],false],[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"marker\"]],\"expected `g.marker` to be a contextual component but found a string. Did you mean `(component g.marker)`? ('@outdoorsyco/ember-shared-data/components/vehicle-location-map/template.hbs' @ L9:C6) \"],null]],[[\"lat\",\"lng\",\"icon\",\"draggable\",\"onDragend\"],[[24,[\"pickupLat\"]],[24,[\"pickupLng\"]],[28,\"map-icon-options\",null,[[\"url\"],[\"https://d1o5877uy6tsnd.cloudfront.net/static-assets/images/map-pin.svg\"]]],true,[28,\"action\",[[23,0,[]],\"handleDrag\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/vehicle-location-map/template.hbs"
    }
  });

  _exports.default = _default;
});