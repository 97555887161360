define("@outdoorsyco/ember-switchback/styles/text/preset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "font100": "normal normal 400 normal 14px/1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;",
    "gray700": "hsl(210, 10%, 42%)",
    "brand100": "hsl(154, 70%, 94%)",
    "brand800": "hsl(172, 50%, 28%)",
    "blue100": "hsl(210, 88%, 97%)",
    "blue600": "hsl(210, 72%, 48%)",
    "font200": "normal normal 400 normal 16px/1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;",
    "gray900": "hsl(207, 6%, 23%)",
    "font300": "normal normal 400 normal 18px/1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;",
    "font400": "normal normal 400 normal 20px/1.4 -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;",
    "font500": "normal normal 400 normal 22px/1.4 -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;",
    "font700": "normal normal 400 normal 28px/1.3 -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;",
    "gray800": "hsl(208, 8%, 36%)"
  };
  _exports.default = _default;
});