define("@outdoorsyco/outdoorsy-addon/components/calendar-picker/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CalendarPickerComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class = class CalendarPickerComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "inputId", `${Ember.guidFor(this)}--input`);

      _defineProperty(this, "labelId", `${Ember.guidFor(this)}--label`);

      _defineProperty(this, "isReadOnly", false);

      _defineProperty(this, "displayIcon", false);

      _defineProperty(this, "displayShortValue", false);

      _initializerDefineProperty(this, "fromFormat", _descriptor2, this);

      _initializerDefineProperty(this, "toFormat", _descriptor3, this);

      _defineProperty(this, "toSymbol", '→');

      _defineProperty(this, "isSmall", false);
    }

    get fromDate() {
      const fromDate = this.args.start;
      const fromFormat = this.args.fromFormat || this.fromFormat;
      return this._formatDate(fromDate, fromFormat);
    }

    get toDate() {
      const toDate = this.args.end;
      const toFormat = this.args.toFormat || this.toFormat;
      return this._formatDate(toDate, toFormat);
    }

    get selectedDates() {
      const toSymbol = this.toSymbol;
      const intl = this.intl;
      const displayShortValue = this.args.displayShortValue || this.displayShortValue;
      let fromDate = this.args.start;
      let fromFormat = this.args.fromFormat || this.fromFormat;
      let toDate = this.args.end;
      let toFormat = this.args.toFormat || this.toFormat;

      if (displayShortValue) {
        fromFormat = 'MMM D';
        toFormat = 'MMM D';
        let fromMonth, toMonth;

        if (fromDate && (0, _moment.default)(fromDate).isValid()) {
          let date = (0, _moment.default)(fromDate);
          fromMonth = date.month();
        }

        if (toDate && (0, _moment.default)(toDate).isValid()) {
          let date = (0, _moment.default)(toDate);
          toMonth = date.month();
        }

        if (fromMonth === toMonth) {
          toFormat = 'D';
        }
      }

      fromDate = this._formatDate(fromDate, fromFormat);
      toDate = this._formatDate(toDate, toFormat);

      if (displayShortValue && fromDate && toDate) {
        return `${fromDate}-${toDate}`;
      }

      return fromDate || toDate ? `${fromDate || intl.t('term-departure')}  ${toSymbol}  ${toDate || intl.t('term-return')}` : null;
    }

    _formatDate(date, format) {
      return date && (0, _moment.default)(date).isValid() ? (0, _moment.default)(date).format(format) : null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fromFormat", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'MMM D, YYYY';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toFormat", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'MMM D, YYYY';
    }
  })), _class));
  _exports.default = CalendarPickerComponent;
});