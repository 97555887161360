define("@outdoorsyco/ember-switchback/components/switchback/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * A component that implements the dom part of the framework.
   *
   * ```handlebars
   *  <Switchback />
   * ```
   * @public
   */
  let Switchback = (_dec = Ember._action, (_class = class Switchback extends _component.default {
    /**
     * Allows switchback to know if the consuming app is
     * using ember-fastboot
     */
    get isFastBoot() {
      let fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot && fastboot.isFastBoot;
    }

    onDidInsert() {
      this.onResize();
      window.addEventListener('resize', this.onResize);
      window.addEventListener('orientationchange', this.onResize);
    }

    onResize() {
      let vh = window.innerHeight * 0.01;
      let vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--vw', `${vw}px`);
    }
    /**
     * Remove event listeners. Under normal circumstances this wouldn't be
     * necessary since this component should ever only get rendered once
     * and never un-rendered. However, in tests it can get added and torn
     * down repeatedly so we need to do cleanup so it doesn't leak memory.
     */


    willDestroy() {
      if (this.isFastBoot) {
        return;
      }

      window.removeEventListener('resize', this.onResize);
      window.removeEventListener('orientationchange', this.onResize);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onResize", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onResize"), _class.prototype)), _class));
  _exports.default = Switchback;
});