define("@outdoorsyco/outdoorsy-addon/components/insurance-dialog/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/insurance-dialog/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    isCustomAndHasCustomMsg: Ember.computed.and('isCustom', 'booking.customInsuranceText'),
    isCustom: Ember.computed.equal('booking.insuranceCoverage', 'custom'),
    isWaived: Ember.computed('booking.{insurance,insuranceCoverage}', function () {
      return this.get('booking.insurance') === 'waived' || this.get('booking.insuranceCoverage') === 'waived';
    }),
    alertType: Ember.computed('isCustomAndHasCustomMsg', function () {
      if (this.get('isCustomAndHasCustomMsg')) {
        return 'info';
      }

      return 'warning';
    }),
    icon: Ember.computed('isCustomAndHasCustomMsg', function () {
      if (this.get('isCustomAndHasCustomMsg')) {
        return 'question-circle';
      }

      return 'notice';
    })
  });

  _exports.default = _default;
});