define("@outdoorsyco/ember-shared-data/components/select-tax/component", ["exports", "@outdoorsyco/ember-shared-data/components/select-tax/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    store: Ember.inject.service(),
    value: null,
    allowClear: true,
    searchField: 'name',
    placeholder: '-- No tax --',
    options: null,
    queryParams: null,

    init() {
      this._super(...arguments);

      if (this.get('options')) {
        this.set('taxes', this.get('options'));
      } else {
        let params = {
          include_all: true
        };
        let queryParams = this.get('queryParams');

        if (queryParams && typeof queryParams === 'object') {
          Object.entries(this.get('queryParams')).forEach(([key, value]) => {
            params[key] = value;
          });
        }

        this.store.query('tax', params).then(data => {
          this.set('taxes', data);
        });
      }
    },

    // we need to set the observer after the model
    modelObserver: Ember.observer('options', 'options.[]', function () {
      let options = this.get('options');
      let currentValue = this.get('value');
      const filteredArray = currentValue.filter(value => options.includes(value));
      this.set('value', filteredArray);

      if (this.get('onChange')) {
        this.get('onChange')(filteredArray);
      }
    }),
    actions: {
      changeTax(value) {
        if (value) {
          this.set('value', value);
        } else {
          this.set('value', null);
        }

        if (this.get('onChange')) {
          this.get('onChange')(value);
        }
      }

    }
  });

  _exports.default = _default;
});