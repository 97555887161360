define("@outdoorsyco/ember-switchback/components/hr/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @documenter esdoc */

  /**
   * Draws a thin rule to separate content.
   *
   * ```handlebars
   * <Hr />
   * ```
   *
   * @public
   */
  class Hr extends _component.default {}

  _exports.default = Hr;
});