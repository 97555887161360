define("@outdoorsyco/ember-switchback/modifiers/on-click-outside", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let hasDOM = typeof document !== 'undefined';
  /**
   * This modifiers captures all clicks on the body and if the click is not inside
   * the element it triggers the passed in action
   *
   * @param {HTMLElement} element - The element that must contain the click to not trigger the action
   */

  var _default = (0, _emberModifier.modifier)((element, [action]) => {
    if (!hasDOM) {
      return;
    }

    (false && !(typeof action === 'function') && Ember.assert('You must pass an action to `on-click-outside`', typeof action === 'function'));

    let onClick = event => {
      !element.contains(event.target) ? action(event) : '';
    };

    if ('ontouchstart' in window) {
      document.body.addEventListener('touchstart', onClick, true);
    } else {
      document.body.addEventListener('click', onClick, true);
    }

    return () => {
      if ('ontouchstart' in window) {
        document.body.removeEventListener('touchstart', onClick, true);
      } else {
        document.body.removeEventListener('click', onClick, true);
      }
    };
  });

  _exports.default = _default;
});