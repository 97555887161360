define("@outdoorsyco/ember-shared-data/components/reports/deposits/batch/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DepositsBatchComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class DepositsBatchComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "apiError", _descriptor, this);

      _initializerDefineProperty(this, "apiRequest", _descriptor2, this);

      _initializerDefineProperty(this, "batchEvents", _descriptor3, this);

      _initializerDefineProperty(this, "loadBatch", _descriptor4, this);

      this.loadBatch.perform();
    }

    filtersChanged() {
      if (this.args.filtersChanged) {
        this.args.filtersChanged();
      }

      this.loadBatch.perform();
    }

    toggleEvent(batchId) {
      this.loadBatchDetails.perform(batchId);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiError", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "batchEvents", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "filtersChanged", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "filtersChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleEvent", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEvent"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loadBatch", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        let offset = 0;

        if (this.args.page > 0) {
          offset = this.args.page - 1;
        }

        let params = {
          limit: 100,
          offset: offset
        };

        if (this.args.dateGT || this.args.dateLT) {
          params.date_lower_bound = this.args.dateGT;
          params.date_upper_bound = this.args.dateLT;
        }

        try {
          let response = [];

          if (this.args.userId && this.args.adminRequest) {
            let url = `admin/users/${this.args.userId}/transactions/batch-events`;
            response = yield this.args.adminRequest.request(url, {
              method: 'GET',
              data: params
            });
          } else {
            response = yield this.apiRequest.request('transactions/batch-events', {
              method: 'GET',
              data: params
            });
          }

          this.batchEvents = response;
        } catch (e) {
          this.apiError.danger(e, 'generic-error');
        }
      };
    }
  })), _class));
  _exports.default = DepositsBatchComponent;
});