define("@outdoorsyco/ember-shared-data/services/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apiRequest: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),

    socialLink(method, returnPath = '') {
      let state = this._convertedState(returnPath);

      let applicationConfig = Ember.getOwner(this).resolveRegistration('config:environment');
      return `${applicationConfig.APP.apiHost}/v0/auth/${method}/login?state=${state}`;
    },

    registerUser(user) {
      let url = 'auth/creds/create';
      let data = Ember.assign({}, this._state({
        skipGuest: true
      }), user);
      return this.apiRequest.post(url, {
        data: JSON.stringify(data)
      }).then(response => {
        return this.get('session').userLoggedIn(response.user_id, response.token);
      }).catch(error => {
        return new Ember.RSVP.Promise((resolve, reject) => {
          reject(error);
        });
      });
    },

    _convertedState(returnPath = '') {
      let state = this._state();

      if (typeof returnPath === 'string') {
        state.return_path = returnPath;
      } else if (typeof returnPath === 'object') {
        state = Ember.assign(returnPath, state);
      }

      return btoa(JSON.stringify(state));
    },

    _state(options = {}) {
      let state = {
        locale: this.intl.locale[0]
      };

      if (this.get('session.refCode')) {
        state.referral_code = this.get('session.refCode');
      }

      if (!options.skipGuest && this.get('session.isGuest')) {
        state['guest_token'] = this.get('session.authKey');
      }

      let userSource = this.get('session.userSource');

      if (userSource && Ember.typeOf(userSource) === 'object') {
        state['signup_source'] = userSource.signup_source;
        state['campaign'] = userSource.campaign;
        state['medium'] = userSource.medium;
        state['content'] = userSource.content;
      }

      if (options.return_path) {
        state.return_path = options.return_path;
      }

      return state;
    }

  });

  _exports.default = _default;
});