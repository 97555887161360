define("@outdoorsyco/ember-shared-data/models/quote", ["exports", "ember-data", "dayjs"], function (_exports, _emberData, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _dec53, _dec54, _dec55, _dec56, _dec57, _dec58, _dec59, _dec60, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52, _descriptor53, _descriptor54, _descriptor55, _descriptor56, _descriptor57, _descriptor58, _descriptor59, _descriptor60;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  let Quote = (_dec = belongsTo('rental'), _dec2 = belongsTo('bundle'), _dec3 = hasMany('line-item', {
    async: false
  }), _dec4 = hasMany('premium-service'), _dec5 = attr(), _dec6 = attr(), _dec7 = belongsTo('booking-bundle'), _dec8 = attr(), _dec9 = attr('string'), _dec10 = attr('number'), _dec11 = attr('string'), _dec12 = attr('number'), _dec13 = attr('number'), _dec14 = attr('number'), _dec15 = attr('string'), _dec16 = attr('centsToDollars'), _dec17 = attr('string'), _dec18 = attr('string'), _dec19 = attr('centsToDollars'), _dec20 = attr('centsToDollars'), _dec21 = attr('string'), _dec22 = attr('boolean'), _dec23 = attr('boolean'), _dec24 = attr('string'), _dec25 = attr('centsToDollars'), _dec26 = attr('centsToDollars'), _dec27 = attr('centsToDollars'), _dec28 = attr('centsToDollars'), _dec29 = attr('centsToDollars'), _dec30 = attr('centsToDollars'), _dec31 = attr('centsToDollars'), _dec32 = attr('centsToDollars'), _dec33 = attr('centsToDollars'), _dec34 = attr('centsToDollars'), _dec35 = attr('centsToDollars'), _dec36 = attr('centsToDollars'), _dec37 = attr('centsToDollars'), _dec38 = attr('centsToDollars'), _dec39 = attr('centsToDollars'), _dec40 = attr('centsToDollars'), _dec41 = attr('centsToDollars', {
    defaultValue: 0
  }), _dec42 = attr('centsToDollars'), _dec43 = attr(), _dec44 = attr('centsToDollars'), _dec45 = attr(), _dec46 = attr('centsToDollars'), _dec47 = attr('string'), _dec48 = attr('string', {
    defaultValue: 'USD'
  }), _dec49 = attr('string'), _dec50 = attr('boolean'), _dec51 = attr('boolean'), _dec52 = attr('boolean'), _dec53 = attr('boolean'), _dec54 = attr('boolean'), _dec55 = attr('boolean'), _dec56 = attr('boolean'), _dec57 = attr('boolean'), _dec58 = attr('boolean'), _dec59 = attr('boolean'), _dec60 = attr('boolean'), (_class = class Quote extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "rental", _descriptor, this);

      _initializerDefineProperty(this, "bundle", _descriptor2, this);

      _initializerDefineProperty(this, "lineItems", _descriptor3, this);

      _initializerDefineProperty(this, "premiumServices", _descriptor4, this);

      _initializerDefineProperty(this, "rentalSummary", _descriptor5, this);

      _initializerDefineProperty(this, "insurancePlan", _descriptor6, this);

      _initializerDefineProperty(this, "insuranceBundle", _descriptor7, this);

      _initializerDefineProperty(this, "taxRates", _descriptor8, this);

      _initializerDefineProperty(this, "from", _descriptor9, this);

      _initializerDefineProperty(this, "fromTime", _descriptor10, this);

      _initializerDefineProperty(this, "to", _descriptor11, this);

      _initializerDefineProperty(this, "toTime", _descriptor12, this);

      _initializerDefineProperty(this, "duration", _descriptor13, this);

      _initializerDefineProperty(this, "requestedDuration", _descriptor14, this);

      _initializerDefineProperty(this, "discountCode", _descriptor15, this);

      _initializerDefineProperty(this, "discountCodeAmount", _descriptor16, this);

      _initializerDefineProperty(this, "discountCodeAppliedTo", _descriptor17, this);

      _initializerDefineProperty(this, "discountCodeMessage", _descriptor18, this);

      _initializerDefineProperty(this, "estimatedGeneratorCost", _descriptor19, this);

      _initializerDefineProperty(this, "estimatedMileageCost", _descriptor20, this);

      _initializerDefineProperty(this, "message", _descriptor21, this);

      _initializerDefineProperty(this, "renterAccepted", _descriptor22, this);

      _initializerDefineProperty(this, "ownerAccepted", _descriptor23, this);

      _initializerDefineProperty(this, "stripeToken", _descriptor24, this);

      _initializerDefineProperty(this, "outdoorsyFee", _descriptor25, this);

      _initializerDefineProperty(this, "ownerFee", _descriptor26, this);

      _initializerDefineProperty(this, "renterCreditsApplied", _descriptor27, this);

      _initializerDefineProperty(this, "originalDayPrice", _descriptor28, this);

      _initializerDefineProperty(this, "originalWeekPrice", _descriptor29, this);

      _initializerDefineProperty(this, "originalMonthPrice", _descriptor30, this);

      _initializerDefineProperty(this, "calculatedDayPrice", _descriptor31, this);

      _initializerDefineProperty(this, "originalServiceFee", _descriptor32, this);

      _initializerDefineProperty(this, "rentalAmount", _descriptor33, this);

      _initializerDefineProperty(this, "renterFee", _descriptor34, this);

      _initializerDefineProperty(this, "reserveAmount", _descriptor35, this);

      _initializerDefineProperty(this, "renterReserveAmount", _descriptor36, this);

      _initializerDefineProperty(this, "securityDeposit", _descriptor37, this);

      _initializerDefineProperty(this, "tax", _descriptor38, this);

      _initializerDefineProperty(this, "subtotal", _descriptor39, this);

      _initializerDefineProperty(this, "total", _descriptor40, this);

      _initializerDefineProperty(this, "totalAfterDeductions", _descriptor41, this);

      _initializerDefineProperty(this, "remainderAmount", _descriptor42, this);

      _initializerDefineProperty(this, "remainderDueDate", _descriptor43, this);

      _initializerDefineProperty(this, "finalPaymentAmount", _descriptor44, this);

      _initializerDefineProperty(this, "finalPaymentDueDate", _descriptor45, this);

      _initializerDefineProperty(this, "ownerIntegrationsFee", _descriptor46, this);

      _initializerDefineProperty(this, "localeBaseCurrency", _descriptor47, this);

      _initializerDefineProperty(this, "presentmentCurrency", _descriptor48, this);

      _initializerDefineProperty(this, "settlementCurrency", _descriptor49, this);

      _initializerDefineProperty(this, "recalculate", _descriptor50, this);

      _initializerDefineProperty(this, "offerAffirm", _descriptor51, this);

      _initializerDefineProperty(this, "isFestivalTravel", _descriptor52, this);

      _initializerDefineProperty(this, "isInstamatch", _descriptor53, this);

      _initializerDefineProperty(this, "hasPets", _descriptor54, this);

      _initializerDefineProperty(this, "useDayPrice", _descriptor55, this);

      _initializerDefineProperty(this, "useDayPricing", _descriptor56, this);

      _initializerDefineProperty(this, "useTaxInclusivePricing", _descriptor57, this);

      _initializerDefineProperty(this, "useSystemTaxRates", _descriptor58, this);

      _initializerDefineProperty(this, "useCustomTaxRates", _descriptor59, this);

      _initializerDefineProperty(this, "reserve", _descriptor60, this);
    }

    get fromDate() {
      return this.from ? (0, _dayjs.default)(this.from) : null;
    }

    get toDate() {
      return this.to ? (0, _dayjs.default)(this.to) : null;
    }
    /**
     * The duration of the trip as selected by the user without
     * taking into account minimum booking duration.
     */


    get selectedDuration() {
      return (0, _dayjs.default)(this.to).diff(this.from, 'day');
    }
    /**
     * Simulates what the the renter will have to pay to reserve
     * the rental if they applied their credit to this booking.
     */


    get reserveAmountAfterCredits() {
      return Math.max(0, this.reserveAmount - (this.renterCredits || 0));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "rental", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bundle", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lineItems", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "premiumServices", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rentalSummary", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "insurancePlan", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "insuranceBundle", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "taxRates", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "from", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "fromTime", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "to", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "toTime", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "duration", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "requestedDuration", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "discountCode", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "discountCodeAmount", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "discountCodeAppliedTo", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "discountCodeMessage", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "estimatedGeneratorCost", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "estimatedMileageCost", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "message", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "renterAccepted", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "ownerAccepted", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "stripeToken", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "outdoorsyFee", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "ownerFee", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "renterCreditsApplied", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "originalDayPrice", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "originalWeekPrice", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "originalMonthPrice", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "calculatedDayPrice", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "originalServiceFee", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "rentalAmount", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "renterFee", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "reserveAmount", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "renterReserveAmount", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "securityDeposit", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "tax", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "subtotal", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "total", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "totalAfterDeductions", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "remainderAmount", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "remainderDueDate", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "finalPaymentAmount", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "finalPaymentDueDate", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "ownerIntegrationsFee", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "localeBaseCurrency", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = _applyDecoratedDescriptor(_class.prototype, "presentmentCurrency", [_dec48], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = _applyDecoratedDescriptor(_class.prototype, "settlementCurrency", [_dec49], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = _applyDecoratedDescriptor(_class.prototype, "recalculate", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = _applyDecoratedDescriptor(_class.prototype, "offerAffirm", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = _applyDecoratedDescriptor(_class.prototype, "isFestivalTravel", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor53 = _applyDecoratedDescriptor(_class.prototype, "isInstamatch", [_dec53], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor54 = _applyDecoratedDescriptor(_class.prototype, "hasPets", [_dec54], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor55 = _applyDecoratedDescriptor(_class.prototype, "useDayPrice", [_dec55], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor56 = _applyDecoratedDescriptor(_class.prototype, "useDayPricing", [_dec56], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor57 = _applyDecoratedDescriptor(_class.prototype, "useTaxInclusivePricing", [_dec57], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor58 = _applyDecoratedDescriptor(_class.prototype, "useSystemTaxRates", [_dec58], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor59 = _applyDecoratedDescriptor(_class.prototype, "useCustomTaxRates", [_dec59], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor60 = _applyDecoratedDescriptor(_class.prototype, "reserve", [_dec60], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Quote;
});