define("ember-mobile-pane/templates/components/mobile-pane/simple-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VH5bWPXn",
    "block": "{\"symbols\":[\"navItem\"],\"statements\":[[4,\"each\",[[24,[\"navItems\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",false],[3,\"action\",[[23,0,[]],\"onClick\",[23,1,[]]]],[8],[7,\"i\",true],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"simple-indicator__indicator\"],[11,\"style\",[22,\"indicatorStyle\"]],[8],[7,\"i\",true],[8],[9],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mobile-pane/templates/components/mobile-pane/simple-indicator.hbs"
    }
  });

  _exports.default = _default;
});