define("@outdoorsyco/ember-switchback/components/slideup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ywNrhR/P",
    "block": "{\"symbols\":[\"@id\",\"@onDismiss\",\"&attrs\",\"&default\",\"@show\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"switchback-slideups\"]],{\"statements\":[[0,\"  \"],[7,\"div\",false],[3,\"swipe-down-to-dismiss\",null,[[\"targetElementId\",\"onDismiss\"],[[28,\"or\",[[23,1,[]],\"slideup\"],null],[23,2,[]]]]],[8],[0,\"\\n\\n\"],[4,\"animated-if\",[[23,5,[]]],[[\"use\",\"duration\"],[[23,0,[\"slideupTransition\"]],200]],{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Slideup\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/slideup/styles\"]]]]]],[12,\"id\",[28,\"or\",[[23,1,[]],\"slideup\"],null]],[3,\"trap-focus\"],[3,\"on-click-outside\",[[23,2,[]]]],[8],[0,\"\\n        \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"content\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/slideup/styles\"]]]]]],[12,\"data-slideup-scroll-area\",\"\"],[13,3],[8],[0,\"\\n\\n          \"],[14,4,[[28,\"hash\",null,[[\"close\"],[[23,2,[]]]]]]],[0,\"\\n\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"animated-if\",[[23,5,[]]],[[\"use\",\"duration\"],[[23,0,[\"overlayTransition\"]],200]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"overlay\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/slideup/styles\"]]]]]],[10,\"data-test-overlay\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/slideup/template.hbs"
    }
  });

  _exports.default = _default;
});