define("@outdoorsyco/ember-switchback/components/badge/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "brand100": "hsl(154, 70%, 94%)",
    "brand800": "hsl(172, 50%, 28%)",
    "gray200": "hsl(204, 14%, 89%)",
    "gray900": "hsl(207, 6%, 23%)",
    "Badge": "_Badge_1ayp6b"
  };
  _exports.default = _default;
});