define("@outdoorsyco/ember-shared-data/serializers/payment-processor", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PaymentProcessorSerializer extends _application.default {
    normalize(typeClass, payload) {
      payload.name = payload.bank_name;
      payload.last_four = payload.bank_last_four;
      payload.processor_country = payload.payment_processor.country;
      payload.processor_name = payload.payment_processor.name;
      payload.external_processor = payload.payment_processor.external_processor;
      payload.external_processor_id = payload.payment_processor.external_processor_id;
      payload.city = payload.address.city;
      payload.country = payload.address.country;
      payload.state = payload.address.state;
      payload.street = payload.address.street;
      payload.street_etc = payload.address.street_etc;
      payload.zip = payload.address.zip;
      delete payload.bank_name;
      delete payload.bank_last_four;
      delete payload.payment_processor;
      delete payload.address;
      return super.normalize(typeClass, payload);
    }

  }

  _exports.default = PaymentProcessorSerializer;
});