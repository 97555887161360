define("@outdoorsyco/ember-switchback/components/rater/component", ["exports", "@outdoorsyco/ember-switchback/components/form-input/component", "@outdoorsyco/ember-switchback/utils/key-codes"], function (_exports, _component, _keyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A star rating component.
   *
   * ```
   * <Rater
   *   @value={{this.rating}}
   *   @onChange={{action updateRating}}
   * />
   * ```
   * @public
   */
  class Rater extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (false && !(args.onChange) && Ember.assert('You must provide an @onChange action to Rater', args.onChange));
    }
    /**
     * Captures key events on the input and updates the value appropriately.
     *
     * @param {Event} event - keydown DOM event
     */


    onKeyDown(event) {
      let newRating;
      let currentRating = this.args.value || 0;

      if (typeof currentRating !== 'number') {
        currentRating = 0;
      }

      switch (event.which) {
        case _keyCodes.UP_ARROW:
          event.preventDefault();
          newRating = currentRating + 1;
          break;

        case _keyCodes.DOWN_ARROW:
          event.preventDefault();
          newRating = currentRating - 1;
          break;

        case _keyCodes.RIGHT_ARROW:
          event.preventDefault();
          newRating = currentRating + 1;
          break;

        case _keyCodes.LEFT_ARROW:
          event.preventDefault();
          newRating = currentRating - 1;
          break;

        case _keyCodes.HOME:
          event.preventDefault();
          newRating = 1;
          break;

        case _keyCodes.END:
          event.preventDefault();
          newRating = 5;
          break;

        default:
          return;
      }

      newRating = newRating > 5 ? 5 : newRating;
      newRating = newRating < 0 ? 0 : newRating;
      this.args.onChange(newRating);
    }

  }

  _exports.default = Rater;
});