define("@outdoorsyco/ember-shared-data/adapters/search/user", ["exports", "@outdoorsyco/ember-shared-data/adapters/search/-search-api"], function (_exports, _searchApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SearchUserAdapter extends _searchApi.default {
    pathForType() {
      return 'users';
    }

  }

  _exports.default = SearchUserAdapter;
});