define("@outdoorsyco/ember-shared-data/serializers/integration", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class IntegrationSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        taxRates: {
          serialize: 'ids',
          deserialize: 'ids'
        }
      });
    }

    // Model -> what the API expects
    serialize(record, options) {
      const data = super.serialize(record, options);

      if (data.dealer_charge_type === 'percent') {
        data.dealer_price = 0;
        data.dealer_price_type = '';
      } else {
        data.dealer_percent = 0;
        data.dealer_percent_type = null;
      }

      if (data.owner_charge_type === 'percent') {
        data.owner_price = 0;
        data.owner_price_type = '';
      } else {
        data.owner_percent = 0;
        data.owner_percent_type = null;
      }

      let tax_rate_ids = data.tax_rates || [];
      data.tax_rate_ids = tax_rate_ids.map(tr => parseInt(tr));
      delete data.tax_rates;
      return data;
    } // API -> what the model expects


    normalize(typeClass, hash) {
      if (hash.dealer_percent) {
        hash.dealer_charge_type = 'percent';
      } else {
        hash.dealer_charge_type = 'normal';
      }

      if (hash.owner_percent) {
        hash.owner_charge_type = 'percent';
      } else {
        hash.owner_charge_type = 'normal';
      }

      let tax_rate_ids = hash.tax_rate_ids || [];
      hash.tax_rates = tax_rate_ids;
      hash.links = hash.links || {};
      hash.links.localizedContent = `/v0/localized-content?object_type=service_integrations&object_id=${hash.id}`;
      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = IntegrationSerializer;
});