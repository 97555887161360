define("@outdoorsyco/ember-shared-data/helpers/map-icon-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mapIconOptions = mapIconOptions;
  _exports.default = void 0;

  function mapIconOptions(_, hash) {
    let defaults;

    if (hash.path) {
      defaults = {
        scale: 10,
        fillOpacity: 0.8,
        fillColor: 'red',
        strokeColor: '',
        strokeWeight: 0,
        strokeOpacity: 1,
        rotation: 0
      };
    } else {
      defaults = {
        url: '',
        width: 40,
        height: 40,
        x: undefined,
        y: undefined
      };
    }

    let options = Ember.assign(defaults, hash);

    if (options.path) {
      switch (options.path) {
        case 'circle':
          options.path = google.maps.SymbolPath.CIRCLE;
          break;

        case 'closed-arrow':
          options.path = google.maps.SymbolPath.FORWARD_CLOSED_ARROW; // also BACKWARD_CLOSED_ARROW

          break;

        case 'open-arrow':
          options.path = google.maps.SymbolPath.FORWARD_OPEN_ARROW; // also BACKWARD_OPEN_ARROW

          break;

        case 'star':
          options.path = 'M 125,5 155,90 245,90 175,145 200,230 125,180 50,230 75,145 5,90 95,90 z';
          break;

        default:
          break;
      }
    }

    if (options.width && options.height) {
      let width = parseInt(options.width);
      let height = parseInt(options.height);
      options.size = new google.maps.Size(width, height);
      options.scaledSize = new google.maps.Size(width, height);
      options.origin = new google.maps.Point(0, 0);
      options.anchor = new google.maps.Point(width / 2, height / 2);
    }

    if (options.x !== undefined && options.y !== undefined) {
      let x = parseInt(options.x);
      let y = parseInt(options.y);
      options.anchor = new google.maps.Point(x, y);
    }

    return options;
  }

  var _default = Ember.Helper.helper(mapIconOptions);

  _exports.default = _default;
});