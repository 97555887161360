define("@outdoorsyco/ember-shared-data/utils/object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.objectHasKey = objectHasKey;

  function objectHasKey(obj, key) {
    if (!obj || typeof obj !== 'object' || typeof key !== 'string') {
      return false;
    }

    return Object.keys(obj).includes(key);
  }
});