define("@outdoorsyco/ember-switchback/components/button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sm": "576px",
    "md": "768px",
    "gray100": "hsl(220, 20%, 97%)",
    "gray200": "hsl(204, 14%, 89%)",
    "gray300": "hsl(210, 14%, 84%)",
    "gray400": "hsl(212, 14%, 80%)",
    "gray500": "hsl(208, 12%, 72%)",
    "gray600": "hsl(210, 10%, 58%)",
    "gray700": "hsl(210, 10%, 42%)",
    "red400": "hsl(358, 86%, 64%)",
    "red200": "hsl(358, 93%, 86%)",
    "contentBackground": "hsl(0, 0%, 100%)",
    "brand100": "hsl(154, 70%, 94%)",
    "brand800": "hsl(172, 50%, 28%)",
    "insetShadow100": "inset 0px 1px 2px hsla(0, 0, 0, 0.1)",
    "raisedGray100": "0 1px 1px 0 hsla(207, 6%, 23%, 0.1)",
    "erroredShadow100": "0px 0px 0px 2px color-mod(hsl(358, 80%, 58%) a(20%))",
    "focusedShadow100": "0px 0px 0px 2px color-mod(hsl(172, 50%, 28%) a(20%))",
    "green100": "hsl(154, 70%, 94%)",
    "green800": "hsl(172, 50%, 28%)",
    "red100": "hsl(356, 100%, 97%)",
    "red500": "hsl(358, 80%, 58%)",
    "red600": "hsl(358, 71%, 48%)",
    "red700": "hsl(358, 78%, 42%)",
    "brand300": "hsl(143, 56%, 79%)",
    "Button": "_Button_1hot7x",
    "content": "_content_1hot7x",
    "label": "_label_1hot7x",
    "loading": "_loading_1hot7x"
  };
  _exports.default = _default;
});