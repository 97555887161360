define("@outdoorsyco/ember-switchback/validators/email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.email = email;
  _exports.default = void 0;

  function email(value, email) {
    const emailPattern = /^\S+@\S+\.\S+$/;
    const validatorIsDisabled = !email;
    const noValueToValidate = Ember.isEmpty(value);
    const validValue = emailPattern.exec(value) && value.indexOf(',') === -1;
    return validatorIsDisabled || noValueToValidate || validValue;
  }

  var _default = {
    param: 'email',
    error: {
      key: 'error.validator.email',
      message: 'Invalid email.'
    },
    validate: email
  };
  _exports.default = _default;
});