define("@outdoorsyco/ember-shared-data/components/input/region/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bJf+0J1N",
    "block": "{\"symbols\":[\"option\",\"&default\",\"@id\",\"@value\",\"@searchEnabled\",\"@form\",\"@group\",\"@requirements\",\"&attrs\",\"@countryCode\"],\"statements\":[[5,\"select\",[[12,\"name\",\"region\"],[12,\"autocomplete\",\"address-level1\"],[13,9],[3,\"did-insert\",[[28,\"perform\",[[23,0,[\"countryCodeDidChange\"]]],null]]],[3,\"did-update\",[[28,\"perform\",[[23,0,[\"countryCodeDidChange\"]]],null],[23,10,[]]]]],[[\"@id\",\"@value\",\"@onChange\",\"@searchField\",\"@searchEnabled\",\"@form\",\"@group\",\"@requirements\",\"@options\",\"@placeholder\",\"@loading\"],[[23,3,[]],[28,\"find-by\",[\"code\",[23,4,[]],[23,0,[\"administrativeAreaOptions\"]]],null],[23,0,[\"onChange\"]],\"codeAndName\",[28,\"or\",[[23,5,[]],false],null],[23,6,[]],[23,7,[]],[23,8,[]],[23,0,[\"sortedAdministrativeAreaOptions\"]],[28,\"t\",[[28,\"concat\",[\"address-\",[28,\"or\",[[23,0,[\"addressFormat\",\"administrativeAreaType\"]],\"state\"],null]],null]],null],[23,0,[\"countryCodeDidChange\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/input/region/template.hbs"
    }
  });

  _exports.default = _default;
});