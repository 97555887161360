define("@outdoorsyco/ember-shared-data/components/input-auto-width/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    attributeBindings: 'style',
    fontStyle: '21px Gotham Narrow A',
    textMeasurer: Ember.inject.service(),
    style: Ember.computed('value', 'placeholder', 'fontStyle', function () {
      let value = this.get('value') || this.get('placeholder');
      let width = this.get('textMeasurer').width(value, this.get('fontStyle'));
      return Ember.String.htmlSafe(`width: ${width + 4}px;`);
    })
  });

  _exports.default = _default;
});