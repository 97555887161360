define("@outdoorsyco/ember-shared-data/serializers/role", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class RoleSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        permissions: {
          embedded: 'always'
        }
      });
    }

    keyForRelationship(key, type, method) {
      /* I don't quite understand why method is undefined.
         I assumed it would be serialize or deserialize
         EDIT: 2.14.20 maybe cause of embedded records mixin? */
      if (key === 'permissions') {
        // permissions are embedded and therefor are NOT sent with an ID
        return key;
      }

      return super.keyForRelationship(key, type, method);
    }

  }

  _exports.default = RoleSerializer;
});