define("@outdoorsyco/ember-shared-data/components/form/insurance-claim/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "animated": "_animated_d42seq",
    "map": "_map_d42seq",
    "icon": "_icon_d42seq"
  };
  _exports.default = _default;
});