define("@outdoorsyco/ember-switchback/components/radioset/option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ahovibjf",
    "block": "{\"symbols\":[\"@radiosetDisabled\",\"@disabled\",\"@errored\",\"@maxWidth\",\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Option\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/radioset/option/styles\"]]]]]],[12,\"id\",[23,0,[\"id\"]]],[12,\"role\",\"radio\"],[12,\"tabindex\",[23,0,[\"tabindex\"]]],[12,\"aria-checked\",[28,\"to-string\",[[23,0,[\"isSelected\"]]],null]],[12,\"aria-disabled\",[28,\"to-string\",[[28,\"or\",[[23,2,[]],[23,1,[]]],null]],null]],[12,\"data-errored\",[28,\"to-string\",[[23,3,[]]],null]],[12,\"data-max-width\",[23,4,[]]],[13,5],[3,\"on\",[\"click\",[23,0,[\"select\"]]]],[3,\"on\",[\"keypress\",[23,0,[\"onKeyPress\"]]]],[3,\"did-insert\",[[23,0,[\"onDidInsert\"]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"radio\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/radioset/option/styles\"]]]]]],[10,\"role\",\"presentation\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"label\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/radioset/option/styles\"]]]]]],[8],[0,\"\\n    \"],[14,6],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/radioset/option/template.hbs"
    }
  });

  _exports.default = _default;
});