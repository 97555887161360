define("@outdoorsyco/ember-shared-data/helpers/is-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let isObject = function isObject([value]) {
    return typeof value === 'object';
  };

  var _default = Ember.Helper.helper(isObject);

  _exports.default = _default;
});