define("ember-mapbox-gl/templates/components/mapbox-gl-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D+UO4VuW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[28,\"-clear-element\",[[24,[\"domContent\"]]],null]],[[\"guid\",\"guid\",\"nextSibling\"],[\"%cursor:0%\",\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"on\"],[[28,\"component\",[\"mapbox-gl-on\"],[[\"eventSource\"],[[23,0,[\"popup\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl-popup.hbs"
    }
  });

  _exports.default = _default;
});