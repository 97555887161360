define("@outdoorsyco/ember-switchback/utils/key-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DOWN_ARROW = _exports.RIGHT_ARROW = _exports.UP_ARROW = _exports.LEFT_ARROW = _exports.HOME = _exports.END = _exports.SPACE = _exports.ESC = _exports.ENTER = _exports.TAB = void 0;
  const TAB = 9;
  _exports.TAB = TAB;
  const ENTER = 13;
  _exports.ENTER = ENTER;
  const ESC = 27;
  _exports.ESC = ESC;
  const SPACE = 32;
  _exports.SPACE = SPACE;
  const END = 35;
  _exports.END = END;
  const HOME = 36;
  _exports.HOME = HOME;
  const LEFT_ARROW = 37;
  _exports.LEFT_ARROW = LEFT_ARROW;
  const UP_ARROW = 38;
  _exports.UP_ARROW = UP_ARROW;
  const RIGHT_ARROW = 39;
  _exports.RIGHT_ARROW = RIGHT_ARROW;
  const DOWN_ARROW = 40;
  _exports.DOWN_ARROW = DOWN_ARROW;
});