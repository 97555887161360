define("@outdoorsyco/ember-shared-data/components/survey-show-long-answer", ["exports", "@outdoorsyco/form-components/components/textarea-field/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    row: 6,
    question: null,
    value: Ember.computed.alias('question.value'),
    placeholder: Ember.computed.alias('question.help'),
    autoresize: true
  });

  _exports.default = _default;
});