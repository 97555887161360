define("@outdoorsyco/ember-shared-data/utils/array/quantile", ["exports", "@outdoorsyco/ember-shared-data/utils/array/min", "@outdoorsyco/ember-shared-data/utils/array/max"], function (_exports, _min, _max) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = quantile;

  function quantile(values, p, valueof) {
    values = Float64Array.from(numbers(values, valueof));
    if (!(n = values.length)) return;
    if ((p = Number(p)) <= 0 || n < 2) return (0, _min.default)(values);
    if (p >= 1) return (0, _max.default)(values);
    var n,
        i = (n - 1) * p,
        i0 = Math.floor(i),
        value0 = (0, _max.default)(quickselect(values, i0).subarray(0, i0 + 1)),
        value1 = (0, _min.default)(values.subarray(i0 + 1));
    return value0 + (value1 - value0) * (i - i0);
  }

  function* numbers(values, valueof) {
    if (valueof === undefined) {
      for (let value of values) {
        if (value !== null && (value = Number(value)) >= value) {
          yield value;
        }
      }
    } else {
      let index = -1;

      for (let value of values) {
        if ((value = valueof(value, ++index, values)) !== null && (value = Number(value)) >= value) {
          yield value;
        }
      }
    }
  }

  function quickselect(array, k, left = 0, right = array.length - 1, compare = ascending) {
    while (right > left) {
      if (right - left > 600) {
        const n = right - left + 1;
        const m = k - left + 1;
        const z = Math.log(n);
        const s = 0.5 * Math.exp(2 * z / 3);
        const sd = 0.5 * Math.sqrt(z * s * (n - s) / n) * (m - n / 2 < 0 ? -1 : 1);
        const newLeft = Math.max(left, Math.floor(k - m * s / n + sd));
        const newRight = Math.min(right, Math.floor(k + (n - m) * s / n + sd));
        quickselect(array, k, newLeft, newRight, compare);
      }

      const t = array[k];
      let i = left;
      let j = right;
      swap(array, left, k);
      if (compare(array[right], t) > 0) swap(array, left, right);

      while (i < j) {
        swap(array, i, j), ++i, --j;

        while (compare(array[i], t) < 0) ++i;

        while (compare(array[j], t) > 0) --j;
      }

      if (compare(array[left], t) === 0) swap(array, left, j);else ++j, swap(array, j, right);
      if (j <= k) left = j + 1;
      if (k <= j) right = j - 1;
    }

    return array;
  }

  function swap(array, i, j) {
    const t = array[i];
    array[i] = array[j];
    array[j] = t;
  }

  function ascending(a, b) {
    return a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN;
  }
});