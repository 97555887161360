define("ember-svg-jar/inlined/current-color-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.7 2.3l5 5c.4.4.4 1 0 1.4l-13 13c-.2.2-.4.3-.7.3H3c-.6 0-1-.4-1-1v-5c0-.3.1-.5.3-.7l13-13c.4-.4 1-.4 1.4 0zM4 20h3.6l12-12L16 4.4l-12 12V20z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});