define("@outdoorsyco/outdoorsy-addon/components/ui-button/pill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wR8AEQkx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",true],[11,\"class\",[29,[\"ui-button-pill \",[28,\"if\",[[24,[\"isSelected\"]],\"selected\"],null],\" \",[28,\"if\",[[24,[\"isHidden\"]],\"hide\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"add\"],null]],[10,\"data-test-ui-button-pill\",\"\"],[8],[0,\"\\n  \"],[1,[28,\"svg-jar\",[\"plus-thin\"],[[\"class\"],[\"ui-button-pill__plus-icon primary-color\"]]],false],[0,\"\\n  \"],[1,[22,\"name\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/ui-button/pill/template.hbs"
    }
  });

  _exports.default = _default;
});