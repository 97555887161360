define("@outdoorsyco/ember-switchback/components/popup/component", ["exports", "@glimmer/component", "@popperjs/core/lib/modifiers/flip", "@popperjs/core/lib/modifiers/offset", "@popperjs/core/lib/modifiers/arrow", "@popperjs/core/lib/modifiers/preventOverflow", "@outdoorsyco/ember-switchback/utils/key-codes", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _component, _flip, _offset, _arrow, _preventOverflow, _keyCodes, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Positions and shows a popup window relative to an element.
   *
   * ```handlebars
   * <Popup
   *   @show={{this.show}}
   *   @relativeTo="#target"
   *   @placement="bottom"
   *   @onDismiss={{fn (mut this.show) false}}>
   *   Content
   * </Popup>
   * ```
   * @public
   */
  let Popup = (_dec = Ember._action, _dec2 = Ember._action, (_class = class Popup extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "arrowId", `${Ember.guidFor(this)}--arrow`);

      _initializerDefineProperty(this, "dismissTask", _descriptor, this);
    }

    get modifiers() {
      let offsetWithOptions = Object.assign({}, _offset.default, {
        options: {
          offset: [0, this.spacing]
        }
      });
      let modifiers = [_flip.default, _preventOverflow.default, offsetWithOptions];

      if (this.args.enableArrow) {
        let arrowWithOptions = Object.assign({}, _arrow.default, {
          options: {
            element: `#${this.arrowId}`,
            padding: 12
          }
        });
        modifiers.push(arrowWithOptions);
      }

      return modifiers;
    }

    get spacing() {
      (false && !(!this.args.spacing || typeof this.args.spacing === 'number') && Ember.assert('<Popup /> requires @spacing to be a number', !this.args.spacing || typeof this.args.spacing === 'number'));
      return this.args.spacing || this.args.enableArrow ? 16 : 8;
    }

    get popupElement() {
      return document.getElementById('switchback-popups');
    }

    dismiss(event) {
      this.dismissTask.perform(event);
    }

    onKeyDown(event) {
      if (event.which === _keyCodes.ESC) {
        this.dismissTask.perform();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dismissTask", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (event) {
        yield (0, _emberConcurrency.timeout)(200);
        Ember.tryInvoke(this.args, 'onDismiss', [event]);
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "dismiss", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "dismiss"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype)), _class));
  _exports.default = Popup;
});