define("@outdoorsyco/ember-switchback/components/rater/star/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Adds a star to Rater.
   *
   * @public
   */
  class Star extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "id", `${Ember.guidFor(this)}--star`);
    }

    /**
     * Compares the currently set rating against this star's value and returns full or empty
     * which controls which star svg is rendered in the template
     */
    get fill() {
      return this.args.value <= this.args.currentValue ? 'full' : 'empty';
    }

  }

  _exports.default = Star;
});