define("@outdoorsyco/outdoorsy-addon/components/rental/cancellation-policy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Jzo0lb9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"font-sub-section-title\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"comp-cancellation-policy-heading\"],null],false],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"hasCustomPolicy\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"dealerOwnedUser\"]]],null,{\"statements\":[[0,\"      - \"],[1,[22,\"policyText\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      - \"],[7,\"a\",true],[10,\"href\",\"https://www.outdoorsy.com/help/what-are-the-cancellation-fees-and-policies\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[1,[22,\"policyText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasCustomPolicy\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"customText\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"policyDescription\"],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/rental/cancellation-policy/template.hbs"
    }
  });

  _exports.default = _default;
});