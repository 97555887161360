define("@outdoorsyco/ember-switchback/components/menu-button/option/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sm": "576px",
    "gray100": "hsl(220, 20%, 97%)",
    "gray600": "hsl(210, 10%, 58%)",
    "gray900": "hsl(207, 6%, 23%)",
    "white": "hsl(255, 100%, 100%)",
    "brand100": "hsl(154, 70%, 94%)",
    "brand800": "hsl(172, 50%, 28%)",
    "option": "_option_ssjy9r"
  };
  _exports.default = _default;
});