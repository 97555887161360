define("@outdoorsyco/ember-shared-data/adapters/bundle-feature", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BundleFeature extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", 'v0');
    }

    pathForType() {
      return 'bundles/:bundleId/features';
    }

    buildURL(type, id, snapshot, requestType, query) {
      let url = super.buildURL(type, id, snapshot, requestType);

      if (snapshot) {
        url = url.replace(':bundleId', snapshot.belongsTo('bundle', {
          id: true
        }));
      } else if (query && query.bundle_id) {
        url = url.replace(':bundleId', query.bundle_id);
        delete query.bundle_id;
      }

      return url;
    }

  }

  _exports.default = BundleFeature;
});