define("@outdoorsyco/ember-shared-data/components/location-search", ["exports", "@outdoorsyco/form-components/components/input-field/component", "fetch", "ember-concurrency"], function (_exports, _component, _fetch, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   ******************************************
   * DEPRECATED: Use <Input:Location>
   ******************************************
   */
  var _default = _component.default.extend({
    geocoder: Ember.inject.service(),
    googleMapsApi: Ember.inject.service(),
    google: Ember.computed.reads('googleMapsApi.google'),
    attributeBindings: 'style',
    disabled: false,

    init() {
      this._super(...arguments);

      this.prevCountries = [];
    },

    submit(e) {
      e.preventDefault();
    },

    ref: null,
    // used exclusively in marketplace dashboard for places
    countries: null,
    types: '(regions)',

    didInsertElement() {
      this._super(...arguments);

      this._setupPlacesApiAndAutocomplete();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this._setupPlacesApiAndAutocomplete();
    },

    willDestroyElement() {
      if (Ember.isPresent(this.get('autocomplete'))) {
        let evt = this.get('google.maps.event');

        if (evt) {
          evt.clearInstanceListeners(this.get('autocomplete'));

          this._clearAutoCompleteElements();
        }
      }

      this._super(...arguments);
    },

    keyUp(e) {
      if (e.which === 13) {
        // user hit enter, if we haven't initialized google, bail with a locationSearch.
        let value = this.get('value');

        if (value && value.length > 2 && !this.get('mapautocomplete') && this.get('locationSearch')) {
          this.get('locationSearch')(value);
        }
      }
    },

    focusOut() {
      // all opt in focus out trigger keypress on google drop menu to select first option
      // in drop menu. used for like copy and paste but no select or type and click away
      if (this.get('google') && this.get('allowFocusOutSearch')) {
        let el = this.$('input');
        let evt = this.get('google.maps.event');

        if (evt && el) {
          evt.trigger(el, 'keydown', {
            keyCode: 40
          });
          evt.trigger(el, 'keydown', {
            keyCode: 13
          });
        }
      }
    },

    _setupPlacesApiAndAutocomplete() {
      let shouldUpdate = false;

      if (!Ember.isEmpty(this.countries)) {
        shouldUpdate = JSON.stringify(this.countries) !== JSON.stringify(this.prevCountries);
        this.set('prevCountries', this.countries);
      }

      if (this.get('mapautocomplete') && !shouldUpdate) {
        return;
      }

      this.get('google').then(() => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        this._setupAutocomplete();
      });
    },

    _setupAutocomplete() {
      let el = this.$('input')[0];
      let ref = this.get('ref');
      let types = this.get('types') || '';
      let countries = this.get('countries') || null;
      let places = this.get('google.maps.places');

      if (!el) {
        return;
      }

      if (this.get('disabled')) {
        el.disabled = true;
        return;
      }

      let autocomplete = new places.Autocomplete(el, {
        types: Ember.makeArray(types),
        // limit the fields, so we don't query the Google places API
        // (instead use our own - see this._getPlaceDetails)
        // https://developers.google.com/maps/documentation/javascript/reference/places-widget#AutocompleteOptions.placeIdOnly
        fields: ['place_id', 'name', 'types']
      });

      if (countries && countries.get('length')) {
        autocomplete.setComponentRestrictions({
          country: countries
        });
      }

      this.set('mapautocomplete', autocomplete);
      this.set('autocomplete', 'on'); // place_changed fires both when a user clicks on a location
      // AND if they enter in some text and hit enter

      autocomplete.addListener('place_changed', async () => {
        let place = autocomplete.getPlace();

        this._onPlaceChanged.perform(place, ref);
      });
    },

    _onPlaceChanged: (0, _emberConcurrency.task)(function* (place, ref) {
      try {
        const placeDetails = yield this._getPlaceDetails.perform(place);

        if (!placeDetails.address_components || !placeDetails.geometry) {
          let placeName = placeDetails.formatted_address ? placeDetails.formatted_address : placeDetails.name;

          this._getBounds(placeName);

          return;
        }

        let bounds = this.get('geocoder').getBounds(placeDetails);
        let location = this.get('geocoder').geocodeParsePlace(placeDetails);
        let {
          lat,
          lng
        } = location;
        this.get('locationFound')(lat, lng, location, placeDetails, ref, bounds);
      } catch (error) {
        this.logger.error(error);
        this.get('locationSearch')(place.name);
      }
    }),
    _getPlaceDetails: (0, _emberConcurrency.task)(function* (place) {
      // if no place id, just return back what we have (probably just { name: ... })
      if (!place.place_id) {
        return place;
      }

      const response = yield (0, _fetch.default)(`https://geoip.outdoorsy.co/places/${place.place_id}`);
      const {
        result
      } = yield response.json();
      return result;
    }),

    _getBounds(placeName) {
      this.get('geocoder').boundingBox(placeName).then(locationData => {
        let bounds = {
          neLat: locationData.northeast.lat,
          neLng: locationData.northeast.long,
          swLat: locationData.southwest.lat,
          swLng: locationData.southwest.long
        };
        let locationObj = locationData.location || {};
        let lng = locationObj.long;
        let {
          location,
          place,
          lat
        } = locationObj;
        let ref = this.get('ref');
        this.get('locationFound')(lat, lng, location, place, ref, bounds);
      }).catch(() => {
        if (this.get('locationSearch')) {
          this.get('locationSearch')(placeName);
        }
      });
    },

    _clearAutoCompleteElements() {
      // clean up after google :(
      let pacContainers = document.querySelectorAll('.pac-container');

      for (let i = 0; pacContainers.length > i; i++) {
        pacContainers[i].parentNode.removeChild(pacContainers[i]);
      }
    }

  });

  _exports.default = _default;
});