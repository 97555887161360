define("@outdoorsyco/ember-shared-data/components/provider/booking/service/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4+o2SS7y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"hasOutdoorsySupport\",\"supportsRoadsideAssistance\",\"supportsTripInsurance\",\"supportsDamageProtection\"],[[23,0,[\"hasOutdoorsySupport\"]],[23,0,[\"supportsRoadsideAssistance\"]],[23,0,[\"supportsTripInsurance\"]],[23,0,[\"supportsDamageProtection\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/provider/booking/service/template.hbs"
    }
  });

  _exports.default = _default;
});