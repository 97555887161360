define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/mobile-menu/link/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/mobile-menu/link/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    session: Ember.inject.service(),
    userCredits: Ember.computed.readOnly('session.currentUser.credits'),
    // userCredits is NaN for a brief moment, so use 0 when necessary
    credits: Ember.computed('userCredits', function () {
      return this.userCredits > 0 ? this.userCredits : 0;
    })
  }).reopenClass({
    positionalParams: ['link', 'domain', 'environment']
  });

  _exports.default = _default;
});