define("@outdoorsyco/form-components/components/time-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1SJkrMGA",
    "block": "{\"symbols\":[\"time\"],\"statements\":[[4,\"select-field\",null,[[\"options\",\"value\",\"error\",\"label\",\"disabled\",\"showErrors\",\"allowCreate\",\"searchEnabled\",\"searchField\",\"renderInPlace\",\"placeholder\",\"allowClear\",\"onChange\"],[[24,[\"timeOptions\"]],[28,\"find-by\",[\"value\",[24,[\"value\"]],[24,[\"timeOptions\"]]],null],[24,[\"error\"]],[24,[\"label\"]],[24,[\"disabled\"]],[24,[\"showErrors\"]],false,[24,[\"searchEnabled\"]],\"display\",[24,[\"renderInPlace\"]],[24,[\"placeholder\"]],[24,[\"allowClear\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"display\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/form-components/components/time-select/template.hbs"
    }
  });

  _exports.default = _default;
});