define("@outdoorsyco/ember-nydus/transforms/cents-to-dollars", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: function deserialize(serialized) {
      if (!Ember.isBlank(serialized)) {
        return Number(serialized) / 100;
      }
    },
    serialize: function serialize(deserialized) {
      if (!Ember.isBlank(deserialized)) {
        return Math.round(Number(deserialized) * 100);
      } else if (deserialized === '') {
        return null;
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});