define("@outdoorsyco/ember-shared-data/components/payouts-processor/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C9w7eZUi",
    "block": "{\"symbols\":[\"@isLoading\",\"@isDisabled\",\"@onSubmit\",\"@submitButtonLabel\"],\"statements\":[[5,\"layout/footer\",[],[[\"@isLoading\",\"@isDisabled\",\"@hidePreviousButton\",\"@onSubmit\",\"@submitButtonLabel\"],[[23,1,[]],[23,2,[]],true,[23,3,[]],[23,4,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/payouts-processor/footer/template.hbs"
    }
  });

  _exports.default = _default;
});