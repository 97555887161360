define("@outdoorsyco/ember-switchback/components/slideup/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "ember-animated/motions/move"], function (_exports, _component, _opacity, _move) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Positions and shows a popup window at the bottom of the
   * screen. The popup can be dismissed by swiping it down.
   *
   * ```handlebars
   * <Slideup
   *   @show={{this.show}}
   *   @onDismiss={{fn (mut this.show) false}}>
   *    Content
   * </Slideup>
   * ```
   * @public
   */
  class Slideup extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "slideupTransition", function* ({
        removedSprites,
        insertedSprites,
        duration
      }) {
        insertedSprites.forEach(sprite => {
          sprite.applyStyles({
            transition: 'none'
          });
          sprite.startAtPixel({
            y: window.innerHeight
          });
          (0, _move.default)(sprite, {
            duration
          });
        });
        removedSprites.forEach(sprite => {
          sprite.applyStyles({
            transition: 'none'
          });
          sprite.endAtPixel({
            y: window.innerHeight
          });
          (0, _move.default)(sprite, {
            duration
          });
        });
      });

      _defineProperty(this, "overlayTransition", function* ({
        removedSprites,
        insertedSprites,
        duration
      }) {
        insertedSprites.forEach(sprite => {
          sprite.applyStyles({
            opacity: '0'
          });
          (0, _opacity.default)(sprite, {
            to: 1,
            duration
          });
        });
        removedSprites.forEach(sprite => {
          (0, _opacity.default)(sprite, {
            to: 0,
            duration
          });
        });
      });
    }

  }

  _exports.default = Slideup;
});