define("ember-svg-jar/inlined/google-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"a\" d=\"M21.6 10.2h-9.3V14h5.3c-.5 2.4-2.6 3.8-5.3 3.8-3.2 0-5.9-2.7-5.9-5.9S9 6 12.3 6c1.4 0 2.7.5 3.7 1.3l2.9-2.8C17.1 2.9 14.8 2 12.3 2c-5.6 0-10 4.4-10 10s4.4 10 10 10c5 0 9.6-3.7 9.6-10-.2-.6-.2-1.2-.3-1.8z\"/></defs><clipPath id=\"b\"><use xlink:href=\"#a\" overflow=\"visible\"/></clipPath><path d=\"M1.4 17.9V6.1L9.1 12l-7.7 5.9z\" clip-path=\"url(#b)\" fill=\"#fbbc05\"/><defs><path id=\"c\" d=\"M21.6 10.2h-9.3V14h5.3c-.5 2.4-2.6 3.8-5.3 3.8-3.2 0-5.9-2.7-5.9-5.9S9 6 12.3 6c1.4 0 2.7.5 3.7 1.3l2.9-2.8C17.1 2.9 14.8 2 12.3 2c-5.6 0-10 4.4-10 10s4.4 10 10 10c5 0 9.6-3.7 9.6-10-.2-.6-.2-1.2-.3-1.8z\"/></defs><clipPath id=\"d\"><use xlink:href=\"#c\" overflow=\"visible\"/></clipPath><path d=\"M1.4 6.1L9.1 12l3.2-2.7 10.9-1.7V1.2H1.4v4.9z\" clip-path=\"url(#d)\" fill=\"#ea4335\"/><g><defs><path id=\"e\" d=\"M21.6 10.2h-9.3V14h5.3c-.5 2.4-2.6 3.8-5.3 3.8-3.2 0-5.9-2.7-5.9-5.9S9 6 12.3 6c1.4 0 2.7.5 3.7 1.3l2.9-2.8C17.1 2.9 14.8 2 12.3 2c-5.6 0-10 4.4-10 10s4.4 10 10 10c5 0 9.6-3.7 9.6-10-.2-.6-.2-1.2-.3-1.8z\"/></defs><clipPath id=\"f\"><use xlink:href=\"#e\" overflow=\"visible\"/></clipPath><path d=\"M1.4 17.9L14.9 7.4l3.6.4L23.1 1v21.8H1.4v-4.9z\" clip-path=\"url(#f)\" fill=\"#34a853\"/></g><g><defs><path id=\"g\" d=\"M21.6 10.2h-9.3V14h5.3c-.5 2.4-2.6 3.8-5.3 3.8-3.2 0-5.9-2.7-5.9-5.9S9 6 12.3 6c1.4 0 2.7.5 3.7 1.3l2.9-2.8C17.1 2.9 14.8 2 12.3 2c-5.6 0-10 4.4-10 10s4.4 10 10 10c5 0 9.6-3.7 9.6-10-.2-.6-.2-1.2-.3-1.8z\"/></defs><clipPath id=\"h\"><use xlink:href=\"#g\" overflow=\"visible\"/></clipPath><path d=\"M23.2 22.9L9.1 12l-1.8-1.3 15.9-4.6v16.8z\" clip-path=\"url(#h)\" fill=\"#4285f4\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});