define("@outdoorsyco/ember-shared-data/validator/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addressField = addressField;
  _exports.default = void 0;

  function addressField(_value) {
    if (Ember.isEmpty(_value)) {
      return true;
    }

    return /^ *[a-zA-ZÀ-ÖØ-öø-ɏ0-9]+((\.|,|-|'| )[a-zA-ZÀ-ÖØ-öø-ɏ0-9]*)* *$/.test(_value);
  }

  var _default = {
    param: 'addressField',
    error: {
      key: 'form-address-field-validation',
      message: 'Address field contains illegal characters'
    },
    validate: addressField
  };
  _exports.default = _default;
});