define("@outdoorsyco/ember-shared-data/services/localized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    async saveContent(object, type) {
      if (!object) {
        return;
      }

      if (!type) {
        return;
      }

      if (!object.localizedContent) {
        return;
      }

      for (var i = 0; i < object.localizedContent.length; i++) {
        let lc = object.localizedContent.objectAt(i);

        if (lc.hasDirtyAttributes) {
          lc.objectType = type;
          lc.objectId = object.id;
          await lc.save();
        }
      }

      return;
    }

  });

  _exports.default = _default;
});