define("@outdoorsyco/ember-shared-data/components/input/stripe-card/input/component", ["exports", "@outdoorsyco/ember-switchback/components/form-input/component", "@outdoorsyco/ember-switchback/components/text-input/styles", "@outdoorsyco/ember-shared-data/components/input/stripe-card/input/styles"], function (_exports, _component, _styles, _styles2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StripeCardFormInputComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class StripeCardFormInputComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "textInputClass", _styles.default['TextInput']);

      _defineProperty(this, "stripeOptions", {
        hidePostalCode: false,
        classes: {
          base: _styles2.default['StripeElement']
        },
        style: {
          base: {
            fontSize: '16px'
          },
          // when invalid, keep the text color the same instead of changing to red
          invalid: {
            color: 'inherit'
          }
        }
      });

      _initializerDefineProperty(this, "isFocused", _descriptor, this);

      _initializerDefineProperty(this, "isErrored", _descriptor2, this);

      _initializerDefineProperty(this, "validationErrors", _descriptor3, this);
    }

    get getStripeOptions() {
      if (typeof this.args.hidePostalCode === 'boolean') {
        this.stripeOptions.hidePostalCode = this.args.hidePostalCode;
      }

      return this.stripeOptions;
    }

    onFocus() {
      this.isFocused = true;
    }

    onBlur() {
      this.isFocused = false;
    }

    onChange(stripeElement, event) {
      // manually set error state to utilize switchback fieldset's error display logic
      const error = event && event.error;
      this.isErrored = Boolean(error);

      if (error) {
        this.validationErrors = [error];
      }

      Ember.tryInvoke(this.args, 'onChange', [stripeElement, event]);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isFocused", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isErrored", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "validationErrors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onFocus", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBlur", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onBlur"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype)), _class));
  _exports.default = StripeCardFormInputComponent;
});