define("@outdoorsyco/ember-shared-data/components/prompt/authenticate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dHma/PXF",
    "block": "{\"symbols\":[\"l\",\"@modal\",\"@data\",\"@cancel\",\"@accept\"],\"statements\":[[5,\"layout/modal/default\",[],[[\"@modal\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n  \"],[5,\"form/authenticate\",[],[[\"@stage\",\"@state\",\"@signupMethod\",\"@signupPreset\",\"@signupTitleKey\",\"@signupDescriptionKey\",\"@loginTitleKey\",\"@loginDescriptionKey\",\"@loginPreset\",\"@onPasswordResetExit\",\"@onAuthenticate\",\"@setStage\"],[[23,0,[\"stage\"]],[23,3,[\"state\"]],[23,3,[\"signupMethod\"]],[23,3,[\"signupPreset\"]],[23,3,[\"signup\",\"titleKey\"]],[23,3,[\"signup\",\"descriptionKey\"]],[23,3,[\"login\",\"titleKey\"]],[23,3,[\"login\",\"descriptionKey\"]],[23,3,[\"loginPreset\"]],[23,4,[]],[23,5,[]],[28,\"fn\",[[28,\"mut\",[[23,0,[\"stage\"]]],null]],null]]]],[0,\"\\n  \"],[6,[23,1,[\"Close\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/prompt/authenticate/template.hbs"
    }
  });

  _exports.default = _default;
});