define("@outdoorsyco/ember-switchback/components/textarea-input/footer/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray100": "hsl(220, 20%, 97%)",
    "gray200": "hsl(204, 14%, 89%)",
    "Footer": "_Footer_vosr28"
  };
  _exports.default = _default;
});