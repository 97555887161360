define("@outdoorsyco/ember-switchback/components/menu-button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Shows a list of options relative to an element.
   *
   * ```handlebars
   * TODO
   * ```
   * @public
   */
  class MenuButton extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "menuId", `${Ember.guidFor(this)}--menu`);
    }

  }

  _exports.default = MenuButton;
});