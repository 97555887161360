define("@outdoorsyco/outdoorsy-addon/components/count-down/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/pX9kclD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"countdown\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"display\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"t\",[\"comp-countdown-label\"],[[\"countdown\",\"htmlSafe\"],[[24,[\"display\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/count-down/template.hbs"
    }
  });

  _exports.default = _default;
});