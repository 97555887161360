define("@outdoorsyco/ember-shared-data/components/login-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VlXZYqmV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[12,\"name\",\"login-user\"],[3,\"action\",[[23,0,[]],\"authenticate\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[1,[28,\"input-field\",null,[[\"new\",\"value\",\"label\",\"autocomplete\"],[[24,[\"new\"]],[24,[\"email\"]],[28,\"t\",[\"form-email-address\"],null],\"email\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[1,[28,\"input-field\",null,[[\"new\",\"value\",\"type\",\"label\",\"click\",\"autocomplete\"],[[24,[\"new\"]],[24,[\"password\"]],\"password\",[28,\"t\",[\"form-password\"],null],[28,\"action\",[[23,0,[]],\"passwordClicked\"],null],\"current-password\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[0,\"\\n      \"],[1,[22,\"errorMessage\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\"],[4,\"action-button\",null,[[\"disabled\",\"class\",\"eventLabel\"],[[24,[\"loading\"]],\"btn btn-primary btn-block btn-lg\",\"login-form-button\"]],{\"statements\":[[4,\"if\",[[24,[\"dealer\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"if\",[[24,[\"loading\"]],[28,\"t\",[\"comp-login-form-logging-in\"],null],[28,\"t\",[\"comp-login-form-login-to-wheelbase\"],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"if\",[[24,[\"loading\"]],[28,\"t\",[\"comp-login-form-logging-in\"],null],[28,\"t\",[\"comp-login-form-login-to-outdoorsy\"],null]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/login-form/template.hbs"
    }
  });

  _exports.default = _default;
});