define("@outdoorsyco/ember-shared-data/controllers/account/discount-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function compareCodeCreatedDate(a, b) {
    return b.get('created').unix() - a.get('created').unix();
  }

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: ['page'],
    page: 1,
    perPage: 50,
    shouldShowStateAndCountry: function shouldShowStateAndCountry() {
      return this.get('session.currentUser.admin') === true || ['US', 'CA'].includes(this.get('session.currentUser.country'));
    },
    discountCodes: Ember.computed.alias('model'),
    savedCodes: Ember.computed.filterBy('discountCodes', 'isNew', false),
    activeCodes: Ember.computed.filterBy('savedCodes', 'isActive', true),
    sortedActiveCodes: Ember.computed.sort('activeCodes', compareCodeCreatedDate),
    inactiveCodes: Ember.computed.filterBy('savedCodes', 'isActive', false),
    sortedInactiveCodes: Ember.computed.sort('inactiveCodes', compareCodeCreatedDate),
    isAdminUser: Ember.computed('', function () {
      return this.get('session.currentUser.admin') === true;
    }),
    hideRadioButtonsGroup: Ember.computed('', function () {
      return Ember.getWithDefault(this, 'hideRadioButtons', false);
    }),
    currentActiveCodes: Ember.computed('page', 'perPage', 'sortedActiveCodes', function () {
      const startingIndex = ((this.page || 1) - 1) * this.perPage;
      const endingIndex = this.page * this.perPage;
      return (this.sortedActiveCodes || []).toArray().slice(startingIndex, endingIndex);
    }),
    currentInactiveCodes: Ember.computed('page', 'perPage', 'sortedInactiveCodes', function () {
      const startingIndex = ((this.page || 1) - 1) * this.perPage;
      const endingIndex = this.page * this.perPage;
      return (this.sortedInactiveCodes || []).toArray().slice(startingIndex, endingIndex);
    }),
    actions: {
      setPage(page) {
        Ember.set(this, 'page', page);
      },

      newDiscountCode() {
        let code = this.store.createRecord('discountCode');
        return this.send('showModal', 'editDiscountCode', code);
      },

      editDiscountCode(code) {
        return this.send('showModal', 'editDiscountCode', code);
      },

      deleteDiscountCode(code) {
        let message = this.get('intl').t('template-discount-code-delete-discount-code-msg');
        let confirmed = confirm(message);

        if (confirmed === true) {
          return code.destroyRecord();
        }
      },

      setApplyToField(field) {
        this.set('model', this.store.query('discountCode', {
          apply_to_field: field
        }));
      }

    }
  });

  _exports.default = _default;
});