define("@outdoorsyco/ember-shared-data/utils/money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.centsToDollars = centsToDollars;
  _exports.dollarsToCents = dollarsToCents;

  function centsToDollars(cents) {
    if (!Ember.isBlank(cents)) {
      return Number(cents) / 100;
    }
  }

  function dollarsToCents(dollars) {
    if (!Ember.isBlank(dollars)) {
      return Math.round(Number(dollars) * 100);
    } else if (dollars === '') {
      return null;
    }

    return dollars;
  }
});