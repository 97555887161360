define("@outdoorsyco/ember-shared-data/services/stripev3", ["exports", "ember-stripe-elements/services/stripev3"], function (_exports, _stripev) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _stripev.default.extend({
    configure() {
      if (!this.mock) {
        return this._super(...arguments);
      } // HACK: for tests that mock stripe, override existing method,
      // using window.StripeMock instead of window.Stripe.
      // avoids conflicts with other tests using stripe v2.
      // should be able to remove this hack when we fully migrate to v3


      let didConfigure = this.get('didConfigure');

      if (!didConfigure) {
        let publishableKey = this.get('publishableKey');
        let {
          elements,
          createToken,
          createSource,
          retrieveSource,
          paymentRequest,
          handleCardPayment,
          createPaymentMethod,
          handleCardSetup
        } = new window.StripeMock(publishableKey);
        Ember.setProperties(this, {
          elements,
          createToken,
          createSource,
          retrieveSource,
          paymentRequest,
          handleCardPayment,
          createPaymentMethod,
          handleCardSetup
        });
        this.set('didConfigure', true);
      }
    }

  });

  _exports.default = _default;
});