define("@outdoorsyco/outdoorsy-addon/components/currency-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pSbsgEiR",
    "block": "{\"symbols\":[\"currency\"],\"statements\":[[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"currency-selection\"],[3,\"action\",[[23,0,[]],\"openDropdown\"]],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"text\"],[10,\"data-test-currency-select-currency\",\"\"],[8],[1,[24,[\"selectedCurrencyObject\",\"name\"]],false],[9],[0,\"\\n  \"],[1,[28,\"svg-jar\",[\"arrow-down\"],[[\"class\"],[\"white size-20\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"currency-dropdown-options\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"currenciesArray\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[]],[24,[\"selectedCurrencyObject\"]]],null],\"selected\",\"not-selected\"],null]],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"href\",\"\"],[3,\"action\",[[23,0,[]],\"updateCurrency\",[23,1,[\"currency\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[]],[24,[\"selection\"]]],null]],null,{\"statements\":[[0,\"            \"],[1,[28,\"svg-jar\",[\"check\"],[[\"class\"],[\"white\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/currency-select/template.hbs"
    }
  });

  _exports.default = _default;
});