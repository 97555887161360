define("ember-google-maps/helpers/g-map/compute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gMapCompute = gMapCompute;
  _exports.default = void 0;

  // Same as ember-composable-helpers 'compute'.
  function gMapCompute([action, ...params]) {
    return action(...params);
  }

  var _default = Ember.Helper.helper(gMapCompute);

  _exports.default = _default;
});