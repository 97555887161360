define("@outdoorsyco/ember-shared-data/components/reports/deposits/batch/event/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DepositsBatchEventComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.alias('args.event'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('event.batch_id'), _dec9 = Ember._action, (_class = class DepositsBatchEventComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "apiError", _descriptor, this);

      _initializerDefineProperty(this, "apiRequest", _descriptor2, this);

      _initializerDefineProperty(this, "adminRequest", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "event", _descriptor5, this);

      _initializerDefineProperty(this, "batchDetails", _descriptor6, this);

      _initializerDefineProperty(this, "expanded", _descriptor7, this);

      _initializerDefineProperty(this, "loadBatchDetails", _descriptor8, this);
    }

    get showEventDetails() {
      return Ember.get(this, 'session.user.admin') && this.event.batch_id;
    }

    toggleEvent() {
      if (this.expanded) {
        this.expanded = false;
      } else {
        this.loadBatchDetails.perform();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiError", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "adminRequest", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "event", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "batchDetails", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "expanded", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showEventDetails", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showEventDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleEvent", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEvent"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "loadBatchDetails", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        try {
          if (!this.event.batch_id) {
            throw 'No batch id provided';
          }

          let url = `transactions/batches/${this.event.batch_id}/details`;
          let response = yield this.adminRequest.request(url, {
            method: 'GET'
          });
          this.batchDetails = response;
          this.expanded = true;
        } catch (e) {
          this.apiError.danger(e, 'generic-error');
        }
      };
    }
  })), _class));
  _exports.default = DepositsBatchEventComponent;
});