define("@outdoorsyco/ember-shared-data/components/reset-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "33yVneGi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[12,\"name\",\"password-reset\"],[3,\"action\",[[23,0,[]],\"passwordReset\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[1,[28,\"input-field\",null,[[\"new\",\"value\",\"label\",\"required\",\"autocomplete\"],[[24,[\"new\"]],[24,[\"email\"]],[28,\"t\",[\"form-email\"],null],\"required\",\"email\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[0,\"\\n      \"],[1,[22,\"error\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\"],[4,\"action-button\",null,[[\"disabled\",\"class\"],[[24,[\"loading\"]],\"btn btn-primary btn-block btn-lg\"]],{\"statements\":[[0,\"      \"],[1,[28,\"if\",[[24,[\"loading\"]],[28,\"t\",[\"comp-reset-password-sending-reset-instructions\"],null],[28,\"t\",[\"comp-reset-password-reset-password\"],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/reset-password/template.hbs"
    }
  });

  _exports.default = _default;
});