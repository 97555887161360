define("@outdoorsyco/ember-shared-data/components/avatar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ECe1aro2",
    "block": "{\"symbols\":[\"@user\",\"@size\",\"@shape\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"avatar\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/avatar/styles\"]]],\"  display-flex \"]]],[12,\"data-size\",[28,\"or\",[[23,2,[]],\"medium\"],null]],[12,\"data-shape\",[28,\"or\",[[23,3,[]],\"round\"],null]],[13,4],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"avatarImage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[11,\"src\",[28,\"image-formatter\",[[23,1,[\"avatarImage\"]]],[[\"size\"],[\"square250\"]]]],[10,\"aria-hidden\",\"true\"],[10,\"onerror\",\"this.style.display='none'\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"avatar_url\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[11,\"src\",[28,\"image-formatter\",[[23,1,[\"avatar_url\"]]],[[\"size\"],[\"square250\"]]]],[10,\"aria-hidden\",\"true\"],[10,\"onerror\",\"this.style.display='none'\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"  margin-all-auto text-white text-capitalize text-semi-bold \"],[8],[0,\"\\n      \"],[1,[23,0,[\"initials\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/avatar/template.hbs"
    }
  });

  _exports.default = _default;
});