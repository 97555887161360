define("@outdoorsyco/outdoorsy-addon/utils/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.triggerChat = void 0;

  const triggerChat = () => {
    if (typeof window.openChat === 'function') {
      window.openChat();
      return true;
    }

    return false;
  };

  _exports.triggerChat = triggerChat;
});