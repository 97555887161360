define("@outdoorsyco/ember-shared-data/components/upload-file/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray200": "hsl(204, 14%, 89%)",
    "photo-drop-zone": "_photo-drop-zone_hjqw2m",
    "photo-drop-zone-inner": "_photo-drop-zone-inner_hjqw2m"
  };
  _exports.default = _default;
});