define("@outdoorsyco/ember-shared-data/serializers/account", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class AccountSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        apiKeys: {
          deserialize: 'records',
          serialize: 'records'
        }
      });
    }

    serialize(record, options) {
      let data = super.serialize(record, options);
      data.keys = data.api_keys;
      return data;
    }

    normalize(typeClass, hash) {
      hash.api_keys = hash.keys;
      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = AccountSerializer;
});