define("@outdoorsyco/ember-shared-data/components/add-bank/component", ["exports", "ember-concurrency", "@outdoorsyco/ember-shared-data/components/add-bank/template"], function (_exports, _emberConcurrency, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    bank: null,
    onSubmit: (0, _emberConcurrency.task)(function* (data) {
      try {
        if (this.bank && this.bank.id) {
          yield this.apiRequest.patch(`users/banks/${this.bank.id}`, {
            data
          });
        } else {
          yield this.apiRequest.post('users/banks', {
            data
          });
        }

        let user = this.get('user');

        if (!user.get('isFulfilled')) {
          user = Ember.RSVP.resolve(user);
        }

        const u = yield user;
        yield u.reload();
        this.sendAction('bankConnected'); //eslint-disable-line ember/closure-actions
      } catch (error) {
        this.logger.debug('Add stripe bank failed', {
          error
        });
        let message = this.get('intl').t('comp-add-bank-api-error-message');

        if (Ember.isPresent(error.payload) && Ember.isPresent(error.payload.error)) {
          message = error.payload.error;
        }

        this.flashMessages.danger(message);
      }
    })
  });

  _exports.default = _default;
});