define("@outdoorsyco/ember-switchback/components/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zkJbCcpI",
    "block": "{\"symbols\":[\"@maxWidth\",\"&attrs\",\"&default\"],\"statements\":[[7,\"label\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Label\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/label/styles\"]]]]]],[12,\"data-max-width\",[23,1,[]]],[13,2],[8],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/label/template.hbs"
    }
  });

  _exports.default = _default;
});