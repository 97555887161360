define("ember-data-storefront/-private/coordinator", ["exports", "ember-data-storefront/-private/cache", "ember-data-storefront/-private/record-query", "ember-data-storefront/-private/record-array-query"], function (_exports, _cache, _recordQuery, _recordArrayQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // cleans options so that the resulting object only contains
  // data we want to send to the server as query params.
  let _cleanParams = function _cleanParams(options) {
    let clean = _objectSpread(_objectSpread({}, {}), options);

    delete clean.reload;
    delete clean.backgroundReload;
    return clean;
  };
  /*
    I know how to retrieve queries from the cache, and also assemble queries that
    are not in the cache but can be derived from them.
  */


  class Coordinator {
    constructor(store) {
      this.store = store;
      this.recordCache = new _cache.default();
      this.arrayCache = new _cache.default(); // A materialized view of loaded includes from the cache's queries.

      this.loadedIncludes = {};
    }

    recordQueryFor(type, id, params) {
      let safeParams = _cleanParams(params);

      let query = this.recordCache.get(type, id, safeParams);

      if (!query) {
        query = this._assembleRecordQuery(type, id, safeParams);

        this._rememberRecordQuery(query);
      }

      return query;
    }

    recordArrayQueryFor(type, params) {
      let safeParams = _cleanParams(params);

      let query = this.arrayCache.get(type, safeParams);

      if (!query) {
        query = this._assembleRecordArrayQuery(type, safeParams);

        this._rememberRecordArrayQuery(query);
      }

      return query;
    }

    queryFor(...args) {
      return args.length === 3 ? this.recordQueryFor(...args) : this.recordArrayQueryFor(...args);
    }

    dump() {
      let records = this.recordCache.all();
      let arrays = this.arrayCache.all();
      return records.concat(arrays);
    }

    recordHasIncludes(type, id, includesString) {
      let query = this._assembleRecordQuery(type, id, {
        include: includesString
      });

      let nonLoadedIncludes = this._nonLoadedIncludesForQuery(query);

      return nonLoadedIncludes.length === 0;
    } // Private


    _assembleRecordQuery(type, id, params) {
      let query = new _recordQuery.default(this.store, type, id, params);

      if (this._queryValueCanBeDerived(query)) {
        query.value = this.store.peekRecord(type, id);
      }

      return query;
    }

    _assembleRecordArrayQuery(type, params) {
      let query = new _recordArrayQuery.default(this.store, type, params);
      return query;
    }

    _queryValueCanBeDerived(query) {
      let queryKeys = Object.keys(query.params);

      if (queryKeys.length === 1 && queryKeys[0] === 'include') {
        let nonLoadedIncludes = this._nonLoadedIncludesForQuery(query);

        return nonLoadedIncludes.length === 0;
      }
    }

    _nonLoadedIncludesForQuery(query) {
      let loadedIncludes = Ember.get(this, `loadedIncludes.${query.type}.${query.id}`) || [];
      let includesString = query.params.include || '';
      return includesString.split(',').filter(include => !!include).filter(include => {
        return !loadedIncludes.find(loadedInclude => {
          return loadedInclude.indexOf(include) === 0;
        });
      });
    }

    _rememberRecordQuery(query) {
      this.recordCache.put(query);

      this._updateLoadedIncludesWithQuery(query);
    }

    _rememberRecordArrayQuery(query) {
      this.arrayCache.put(query);
    }

    _updateLoadedIncludesWithQuery(query) {
      this.loadedIncludes[query.type] = this.loadedIncludes[query.type] || {};
      this.loadedIncludes[query.type][query.id] = this.loadedIncludes[query.type][query.id] || [];
      let currentIncludes = this.loadedIncludes[query.type][query.id];

      let nonLoadedIncludes = this._nonLoadedIncludesForQuery(query);

      let newLoadedIncludes = [...currentIncludes, ...nonLoadedIncludes];
      this.loadedIncludes[query.type][query.id] = newLoadedIncludes;
    }

  }

  _exports.default = Coordinator;
});