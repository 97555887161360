define("@outdoorsyco/ember-shared-data/components/modals/lippert-mobile-service/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "md": "768px",
    "lippert-modal": "_lippert-modal_171jqk",
    "rental-line": "_rental-line_171jqk",
    "rental-extended": "_rental-extended_171jqk",
    "inactive-rentals": "_inactive-rentals_171jqk",
    "rental-title-columns": "_rental-title-columns_171jqk",
    "name-title-column": "_name-title-column_171jqk",
    "grid-container": "_grid-container_171jqk",
    "item-a": "_item-a_171jqk",
    "item-b": "_item-b_171jqk",
    "outdoorsy-rental": "_outdoorsy-rental_171jqk",
    "item1": "_item1_171jqk",
    "m-rental-heading": "_m-rental-heading_171jqk",
    "m-rental-note": "_m-rental-note_171jqk"
  };
  _exports.default = _default;
});