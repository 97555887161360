define("@outdoorsyco/ember-shared-data/components/form/server-form/element/component", ["exports", "@glimmer/component", "@outdoorsyco/ember-shared-data/utils/date-of-birth", "moment"], function (_exports, _component, _dateOfBirth, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormElementComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('args.field'), _dec3 = Ember.computed.alias('args.field.type'), _dec4 = Ember.computed.alias('args.model'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class FormElementComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "field", _descriptor2, this);

      _initializerDefineProperty(this, "fieldType", _descriptor3, this);

      _initializerDefineProperty(this, "model", _descriptor4, this);

      _defineProperty(this, "countryCode", 'US');
    }

    get yesNoOptions() {
      return Ember.A([{
        name: this.intl.t('common-yes'),
        value: true
      }, {
        name: this.intl.t('common-no'),
        value: false
      }]);
    }

    get checkboxLabel() {
      const label = this.field.label;

      if (this.field.rich_text.includes('label')) {
        return Ember.String.htmlSafe(label);
      }

      return label;
    }

    get months() {
      return _dateOfBirth.months.map(month => {
        // we can't switch to dayjs here until we get intl working for dayjs
        // getting a mutation error when trying to set month.display... so make a copy
        let monthCopy = Object.assign({}, month);

        _moment.default.locale(this.intl.locale);

        let monthName = (0, _moment.default)().month(month.value - 1).format('MMMM');
        monthCopy.label = `${month.value} ${monthName}`;
        return monthCopy;
      });
    }

    get years() {
      return (0, _dateOfBirth.driverYears)().map(y => {
        return {
          label: y.toString(),
          value: y.toString()
        };
      });
    }

    get days() {
      return (0, _dateOfBirth.days)().map(day => {
        return {
          label: day.toString(),
          value: day.toString()
        };
      });
    }

    updateAddress(key, value) {
      Ember.set(this, key, value);
    }

    setOptionValue(attrName, option) {
      Ember.set(this, `field.value`, option.value);
    }

    setDate(type, value) {
      Ember.set(this, type, value.value);
      let m = String(this.month);

      if (m.length === 1) {
        m = '0' + m;
      }

      let d = String(this.day);

      if (d.length === 1) {
        d = '0' + d;
      }

      Ember.set(this, `field.value`, `${this.year}-${m}-${d}`);
    }
    /**
     * Used for fields with a list of objects, setting a field in the array
     * instead of the top level field.
     */


    assignValue(attrName, value) {
      // if a model is passed in, set the value on the model
      // an array of objects is one purpose for this
      if (this.model) {
        Ember.set(this, `model.${attrName}`, value);
      } else {
        Ember.set(this, `field.value`, value);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "field", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fieldType", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateAddress", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateAddress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setOptionValue", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setOptionValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "assignValue", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "assignValue"), _class.prototype)), _class));
  _exports.default = FormElementComponent;
});