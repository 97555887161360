define("ember-phone-input/services/phone-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intlTelInput: null,

    load() {
      if (this.intlTelInput) return;
      return Ember.RSVP.all([emberAutoImportDynamic("intl-tel-input/build/js/intlTelInput.js"), emberAutoImportDynamic("intl-tel-input/build/js/utils.js")]).then(([intlTelInput]) => {
        if (!this.isDestroying && !this.isDestroyed) {
          this.set('intlTelInput', intlTelInput.default);
        }
      });
    }

  });

  _exports.default = _default;
});