define("@outdoorsyco/ember-shared-data/formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * provides config for this:
   * https://github.com/ember-intl/ember-intl#named-formats
   *
   * as well as our date-formatter helper
   */
  var _default = {
    time: {
      hhmmss: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
    },
    date: {
      hhmmss: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      },
      'h mm': {
        hour: 'numeric',
        minute: '2-digit'
      },
      'H mm': {
        hour: 'numeric',
        minute: '2-digit',
        hour12: false
      },
      'h mm ss z': {
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      },
      'MM D': {
        month: '2-digit',
        day: 'numeric'
      },
      // used as default:
      'MMM D': {
        month: 'short',
        day: 'numeric'
      },
      'MMM DD': {
        month: 'short',
        day: '2-digit'
      },
      'MMMM D': {
        month: 'long',
        day: 'numeric'
      },
      'MMMM DD': {
        month: 'long',
        day: '2-digit'
      },
      'MMM DD YY': {
        year: '2-digit',
        month: 'short',
        day: '2-digit'
      },
      'MM DD YYYY': {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
      'MMM D YYYY': {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      'MMM DD YYYY': {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
      },
      'MMMM D YYYY': {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      },
      'ddd MMMM D YYYY': {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'short'
      }
    },
    number: {
      EUR: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      USD: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      CAD: {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      AUD: {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      NZD: {
        style: 'currency',
        currency: 'NZD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  };
  _exports.default = _default;
});