define("@outdoorsyco/ember-shared-data/helpers/is-blank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let isBlank = function isBlank([value]) {
    return Ember.isBlank(value);
  };

  var _default = Ember.Helper.helper(isBlank);

  _exports.default = _default;
});