define("@outdoorsyco/ember-switchback/components/toggle/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "brand800": "hsl(172, 50%, 28%)",
    "gray100": "hsl(220, 20%, 97%)",
    "gray200": "hsl(204, 14%, 89%)",
    "gray300": "hsl(210, 14%, 84%)",
    "white": "hsl(255, 100%, 100%)",
    "focusedShadow100": "0px 0px 0px 2px color-mod(hsl(172, 50%, 28%) a(20%))",
    "Toggle": "_Toggle_1fx8ra",
    "reverse-label": "_reverse-label_1fx8ra",
    "toggle-element": "_toggle-element_1fx8ra",
    "handle": "_handle_1fx8ra",
    "toggle-label": "_toggle-label_1fx8ra"
  };
  _exports.default = _default;
});