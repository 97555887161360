define("@outdoorsyco/ember-shared-data/components/ui/spaced-sections/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QeZ2yF9T",
    "block": "{\"symbols\":[\"@space\",\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"SpacedSections\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/ui/spaced-sections/styles\"]]]]]],[12,\"style\",[28,\"html-safe\",[[28,\"if\",[[23,1,[]],[28,\"concat\",[\"--spaced-sections-space:\",[23,1,[]]],null]],null]],null]],[13,2],[8],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/ui/spaced-sections/template.hbs"
    }
  });

  _exports.default = _default;
});