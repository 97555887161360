define("@outdoorsyco/ember-switchback/components/instructions/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "font200": "normal normal 400 normal 16px/1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;",
    "gray700": "hsl(210, 10%, 42%)",
    "Instructions": "_Instructions_61wbu8"
  };
  _exports.default = _default;
});