define("@outdoorsyco/ember-shared-data/utils/vehicle-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = vehicleTypes;

  function vehicleTypes() {
    return new Map([['rv', [{
      name: 'Class A',
      value: 'rv-a'
    }, {
      name: 'Class C',
      value: 'rv-c'
    }, {
      name: 'Class B',
      value: 'rv-b'
    }, {
      name: 'Camper Van',
      value: 'rv-camper-van'
    }, {
      name: 'Truck Camper',
      value: 'rv-truck-camper'
    }, {
      name: 'Travel Trailer',
      value: 'rv-trailer'
    }, {
      name: 'Folding Trailer',
      value: 'rv-folding-trailer'
    }, {
      name: 'Fifth Wheel',
      value: 'rv-fifth-wheel'
    }, {
      name: 'Toy Hauler',
      value: 'rv-toy-hauler'
    }, {
      name: 'Tow Vehicle',
      value: 'rv-tow-vehicle'
    }, {
      name: 'Park Model',
      value: 'rv-park-model'
    }, {
      name: 'Utility Trailer',
      value: 'rv-utility-trailer'
    }, {
      name: 'Other',
      value: 'rv-other'
    }]], ['stay', [{
      name: 'Cabin',
      value: 'stay-cabin'
    }, {
      name: 'CamperRV',
      value: 'stay-camper-rv'
    }, {
      name: 'CampingTent',
      value: 'stay-camping-tent'
    }, {
      name: 'CanvasTent',
      value: 'stay-canvas-tent'
    }, {
      name: 'TinyHome',
      value: 'stay-tiny-home'
    }, {
      name: 'Yurt',
      value: 'stay-yurt'
    }, {
      name: 'Hut',
      value: 'stay-hut'
    }, {
      name: 'Tipi',
      value: 'stay-tipi'
    }, {
      name: 'Barn',
      value: 'stay-barn'
    }, {
      name: 'Treehouse',
      value: 'stay-treehouse'
    }, {
      name: 'Dome',
      value: 'stay-dome'
    }, {
      name: 'AFrame',
      value: 'stay-a-frame'
    }, {
      name: 'Ranch',
      value: 'stay-ranch'
    }, {
      name: 'Other',
      value: 'stay-other'
    }, {
      name: 'House',
      value: 'stay-house'
    }, {
      name: 'Cottage',
      value: 'stay-cottage'
    }]], ['campground', [{
      name: 'RV Site',
      value: 'rv_site'
    }, {
      name: 'Tent Site',
      value: 'tent_site'
    }, {
      name: 'Lodging Site',
      value: 'lodging_site'
    }, {
      name: 'Stationary Camper',
      value: 'stationary_camper_site'
    }]]]);
  }
});