define("@outdoorsyco/ember-shared-data/components/ui/aspect-ratio/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UiAspectRatioComponent extends _component.default {
    /**
     * Create space for async content
     * <Ui::AspectRatio @ratio="16:9" >
     *   your content
     * </Ui::AspectRatio>
     */
    get style() {
      let paddingBottom = this.args.ratio.split(':').map(str => Number(str)).reduce((prev, curr) => curr / prev);
      return Ember.String.htmlSafe(`padding-bottom: ${paddingBottom * 100}%`);
    }

  }

  _exports.default = UiAspectRatioComponent;
});