define("calendar-picker/utils/cp-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeRange = normalizeRange;
  _exports.normalizeSRPSameDay = normalizeSRPSameDay;

  function normalizeRange(range) {
    range.start = range.start.utc().startOf('day');
    range.end = range.end.utc().startOf('day');
    return range;
  }

  function normalizeSRPSameDay(sro) {
    let range = normalizeRange(sro.range.clone());
    range.start = sro.canSameDayPickStart ? range.start.add(1, 'day') : range.start;
    range.end = sro.canSameDayPickEnd ? range.end.subtract(1, 'day') : range.end;
    return range;
  }
});