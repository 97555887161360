define("@outdoorsyco/ember-switchback/components/like/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray100": "hsl(220, 20%, 97%)",
    "red500": "hsl(358, 80%, 58%)",
    "Like": "_Like_7roi1c",
    "button": "_button_7roi1c",
    "heart": "_heart_7roi1c",
    "pulse": "_pulse_7roi1c",
    "outline": "_outline_7roi1c",
    "inside": "_inside_7roi1c",
    "color": "_color_7roi1c",
    "overlay": "_overlay_7roi1c",
    "count": "_count_7roi1c"
  };
  _exports.default = _default;
});