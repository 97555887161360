define("@outdoorsyco/ember-switchback/components/rating/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @documenter esdoc */

  /**
   * A component that displays a star rating.
   *
   * ```handlebars
   * <Rating />
   * ```
   * @public
   */
  class Rating extends _component.default {
    get percent() {
      (false && !(typeof this.args.rating === 'number') && Ember.assert('<Rating /> requires @rating to be a number.', typeof this.args.rating === 'number'));
      (false && !(this.args.rating <= 6 && this.args.rating >= 0) && Ember.assert('<Rating /> requires @rating to be between 0 and 5.', this.args.rating <= 6 && this.args.rating >= 0)); // Rounds number to nearest half

      const rounded = Math.round(this.args.rating * 2) / 2;
      return rounded / 5 * 100;
    }

  }

  _exports.default = Rating;
});