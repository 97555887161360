define("@outdoorsyco/outdoorsy-addon/components/alert-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jj9lIkfz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"dismissed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"alert-bar alert-bar--style-\",[22,\"style\"],\" \",[28,\"if\",[[24,[\"onClick\"]],\"alert-bar--clickable\"],null]]]],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"alert-bar__main\"],[3,\"action\",[[23,0,[]],\"handleClick\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"alert-bar__main__title\"],[8],[0,\"\\n          \"],[1,[22,\"title\"],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\",true],[10,\"class\",\"alert-bar__main__content\"],[8],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"dismissable\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"alert-bar__actions\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"alert-bar__actions__action alert-bar__actions__action--dismiss\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"dismiss\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n          \"],[1,[28,\"svg-jar\",[\"close\"],[[\"class\"],[\"size-small\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/alert-bar/template.hbs"
    }
  });

  _exports.default = _default;
});