define("@outdoorsyco/ember-switchback/styles/border/color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray100": "hsl(220, 20%, 97%)",
    "gray200": "hsl(204, 14%, 89%)",
    "gray300": "hsl(210, 14%, 84%)",
    "gray400": "hsl(212, 14%, 80%)",
    "gray500": "hsl(208, 12%, 72%)",
    "gray600": "hsl(210, 10%, 58%)",
    "gray700": "hsl(210, 10%, 42%)",
    "gray800": "hsl(208, 8%, 36%)",
    "gray900": "hsl(207, 6%, 23%)"
  };
  _exports.default = _default;
});