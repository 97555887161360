define("@outdoorsyco/form-components/components/checkbox-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0b3+tpZO",
    "block": "{\"symbols\":[\"&default\",\"@label\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"checkbox-field form-group \",[28,\"if\",[[24,[\"hasError\"]],\"has-error\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[11,\"for\",[22,\"formElementId\"]],[10,\"class\",\"form-label\"],[8],[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"required\"],[8],[0,\"*\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"form-control-checkbox\"],[8],[0,\"\\n    \"],[7,\"input\",true],[10,\"class\",\"checkbox-field-input\"],[11,\"id\",[22,\"uniqueId\"]],[11,\"value\",[22,\"value\"]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"toggleValue\"],null]],[11,\"checked\",[28,\"if\",[[24,[\"value\"]],\"checked\"],null]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n    \"],[7,\"label\",true],[11,\"class\",[29,[\"checkbox-field-label\",[28,\"if\",[[28,\"eq\",[[24,[\"size\"]],\"large\"],null],\" large\"],null]]]],[11,\"for\",[22,\"uniqueId\"]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,\"checkboxLabel\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"tip\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"field-help \"],[8],[7,\"span\",true],[8],[1,[22,\"tip\"],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"hasError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"help-block \",[28,\"if\",[[24,[\"hasError\"]],\"has-error\"],null]]]],[8],[1,[24,[\"error\",\"message\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/form-components/components/checkbox-field/template.hbs"
    }
  });

  _exports.default = _default;
});