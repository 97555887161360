define("@outdoorsyco/ember-shared-data/components/vehicle-location-map/component", ["exports", "@outdoorsyco/ember-shared-data/components/vehicle-location-map/template", "@outdoorsyco/ember-shared-data/utils/map-style"], function (_exports, _template, _mapStyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals google*/
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['vehicle-location-map'],
    googleMapsApi: Ember.inject.service(),
    googleLoaded: Ember.computed.reads('googleMapsApi.google.isFulfilled'),
    map: null,
    zoom: 17,

    init() {
      this._super(...arguments);

      this.mapOptions = {
        styles: _mapStyle.default.mapStyle,
        disableDefaultUI: true,
        zoomControl: true,
        maxZoom: 17,
        zoomControlOptions: {
          position: 3 // google.maps.ControlPosition.TOP_RIGHT

        }
      };
    },

    onCoordsChange: Ember.observer('map', 'pickupLat', 'pickupLng', function () {
      Ember.run.once(this, 'setCenter');
    }),

    setCenter() {
      const map = this.get('map');
      const lat = this.get('pickupLat');
      const lng = this.get('pickupLng');

      if (Ember.isPresent(map) && Ember.isPresent(lat) && Ember.isPresent(lng) && typeof FastBoot === 'undefined') {
        const center = new google.maps.LatLng(lat, lng);
        map.setCenter(center);
      }
    },

    actions: {
      onMapLoad({
        map
      }) {
        this.set('map', map);
      },

      handleDrag(e) {
        if (e.googleEvent && e.googleEvent.latLng) {
          let lat = e.googleEvent.latLng.lat();
          let lng = e.googleEvent.latLng.lng();

          if (this.get('changeLocation')) {
            this.get('changeLocation')(lat, lng);
          }
        }
      }

    }
  });

  _exports.default = _default;
});