define("@outdoorsyco/ember-shared-data/components/form/authenticate/component", ["exports", "@glimmer/component", "ember-window-mock"], function (_exports, _component, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Component that lets a user to authenticate by logging in or creating
   * a new Outdoorsy account
   *
   * @public
   */
  let AuthenticateComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class AuthenticateComponent extends _component.default {
    constructor(owner, values) {
      super(...arguments);

      _initializerDefineProperty(this, "apiRequest", _descriptor, this);

      _initializerDefineProperty(this, "login", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "conversations", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "environment", _descriptor6, this);

      _initializerDefineProperty(this, "signupMethod", _descriptor7, this);

      this.signupMethod = values.signupMethod;
    }

    get stage() {
      return this.args.stage || 'signup';
    }
    /**
     * Redirects user to a social service to create an account. It stores the current app
     * state in the return url so the user lands back where they where.
     *
     * @argument {string} method - Can be "google" or "facebook"
     */


    socialAuth(method) {
      const appConfig = Ember.getOwner(this).resolveRegistration('config:environment');
      let currentState = this.args.state || Ember.get(this, 'session.state') || {};
      let nextRedirect = this.getNextRedirectURL();

      if (nextRedirect) {
        currentState.return_path = nextRedirect;
      }

      let intendedURL = Ember.get(this, 'session.attemptedTransition.intent.url');

      if (currentState && !currentState.return_path && intendedURL) {
        currentState.return_path = intendedURL;
      }

      const state = this.login._convertedState(currentState);

      const url = `${appConfig.APP.apiHost}/v0/auth/${method}/login?state=${state}`;
      _emberWindowMock.default.location.href = url;
    }

    getNextRedirectURL() {
      let currentRoute = this.router && this.router.currentRoute;
      let redirectURL;

      if (currentRoute && currentRoute.queryParams) {
        let next = currentRoute.queryParams.next;

        if (next) {
          try {
            redirectURL = atob(decodeURIComponent(next));
            redirectURL = redirectURL.replace(/(https?:\/\/[-\w.]+)\//, '/');
          } catch (e) {
            Ember.getOwner(this).lookup('logger:outdoorsy').error(e);
          }
        }
      }

      return redirectURL;
    }
    /**
     * Handles global logic that needs to happen after the user authenticates.
     *
     * Not an ideal location for this logic, but it was being done by the
     * global application route action so we will maintain its existence
     * for now.
     */


    onAuthenticate() {
      if (this.session.state) {
        this.router.transitionTo(this.session.state);
      }

      if (this.conversations) {
        this.conversations.loadMessages.perform();
      }

      Ember.tryInvoke(this.args, 'onAuthenticate', [this.stage]);
    }

    onPasswordReset() {
      Ember.tryInvoke(this.args, 'onPasswordReset');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "login", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "conversations", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "signupMethod", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "socialAuth", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "socialAuth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAuthenticate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onAuthenticate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPasswordReset", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onPasswordReset"), _class.prototype)), _class));
  _exports.default = AuthenticateComponent;
});