define("@outdoorsyco/ember-shared-data/serializers/rule", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;

  class Rule extends JSONAPISerializer {
    keyForAttribute(key) {
      return Ember.String.underscore(key);
    }

    keyForRelationship(key) {
      return Ember.String.underscore(key);
    }

    normalize(typeClass, hash) {
      let attrs = hash.attributes;
      let rsd = attrs.rule_specific_data || {};
      let modType = rsd.price_modifier_type;

      if (modType) {
        let modValue = rsd.price_modifier_value;

        let {
          change,
          value,
          type
        } = this._normalizePriceModChange(modType, modValue);

        hash.attributes.price_modifier_type = type;
        hash.attributes.price_modifier_value = value;
        hash.attributes.price_modifier_change = change;
      }

      if (rsd.minimum_booking_days) {
        hash.attributes.minimum_booking_days = rsd.minimum_booking_days;
      }

      let modTiers = rsd.tiers || rsd.days_of_trip || rsd.length_of_booking || [];

      if (modTiers && modTiers.length) {
        let tiers = [];

        for (let i = 0; i < modTiers.length; i++) {
          let tier = modTiers[i] || {};
          let pmt = tier.price_modifier_type;

          if (pmt) {
            let modValue = tier.price_modifier_value;

            let {
              change,
              value,
              type
            } = this._normalizePriceModChange(pmt, modValue);

            let newTier = {
              price_modifier_type: type,
              price_modifier_value: value,
              price_modifier_change: change
            };
            newTier = this._normalizeTierSpecificKey(hash.attributes.rule_type, tier, newTier);
            tiers.push(newTier);
          }
        }

        hash.attributes.tiers = tiers;
      }

      return super.normalize(typeClass, hash);
    }

    _normalizePriceModChange(type, value) {
      let change = 'increase';
      value = parseInt(value) / 100;

      switch (type) {
        case 'PERCENTAGE':
          // increase
          // ex. for increase of 20%
          // value = 12000
          // value = (12000/100) = 120
          // value = (120-100) = 20
          if (value >= 100) {
            value -= 100;
            value = Math.round(value * 100) / 100;
          } // decrease
          // ex. for decrease of 20%
          // value = 8000
          // value = (8000/100) = 80
          // value = (100-80) = 20
          else {
              value = 100 - value;
              value = Math.round(value * 100) / 100;
              change = 'decrease';
            }

          break;

        case 'FLAT':
          // value < 0 is a decrease
          if (value < 0) {
            value *= -1;
            change = 'decrease';
          }

          break;

        default:
          break;
      }

      return {
        change,
        type,
        value
      };
    }

    serialize
    /* snapshot, options */
    () {
      let json = super.serialize(
      /* snapshot, options */
      ...arguments);
      let attrs = json.data.attributes;
      let days = attrs.applicable_days;

      if (!days || days && !days.length) {
        days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
        json.data.attributes.applicable_days = days;
      }

      let rsd = attrs.rule_specific_data;

      if (!rsd) {
        rsd = {};
      }

      let hasTiers = ['UTILIZATION', 'LENGTH_OF_BOOKING', 'DAY_OF_TRIP'].includes(attrs.rule_type);
      let priceModType = attrs.price_modifier_type;
      let priceModValue = attrs.price_modifier_value;
      let priceModChange = attrs.price_modifier_change;

      if (!hasTiers && attrs.rule_type !== 'MINIMUM_BOOKING_DAYS' && priceModType && priceModValue) {
        let {
          value
        } = this._serializePriceModChange(priceModChange, priceModType, priceModValue);

        rsd.price_modifier_type = priceModType;
        rsd.price_modifier_value = value;
      } else {
        delete rsd.price_modifier_type;
        delete rsd.price_modifier_value;
      }

      let tiers = attrs.tiers || [];

      if (hasTiers && tiers.length) {
        let items = this._serializeTiersPrices(tiers, attrs.rule_type);

        if (attrs.rule_type === 'DAY_OF_TRIP') {
          rsd.days_of_trip = items;
          delete rsd.tiers;
        } else {
          rsd.tiers = items;
        }
      } else {
        delete rsd.tiers;
        delete rsd.days_of_trip;
      } // only include min booking days days exists


      if (attrs.rule_type === 'MINIMUM_BOOKING_DAYS' && parseInt(attrs.minimum_booking_days || 0) > 0) {
        rsd.minimum_booking_days = attrs.minimum_booking_days;
      } else {
        delete rsd.minimum_booking_days;
      }

      json.data.attributes.rule_specific_data = rsd;
      let bookingStart = attrs.booking_dates_start;
      let bookingEnd = attrs.booking_dates_end;
      json.data.attributes.all_booking_dates = !(bookingStart && bookingEnd);
      let searchStart = attrs.search_dates_start;
      let searchEnd = attrs.search_dates_end;
      json.data.attributes.all_search_dates = !(searchStart && searchEnd);
      delete json.data.attributes.tiers;
      delete json.data.attributes.price_modifier_type;
      delete json.data.attributes.price_modifier_value;
      delete json.data.attributes.price_modifier_change;
      delete json.data.attributes.minimum_booking_days;
      return json;
    }

    _serializePriceModChange(change, type, value) {
      let isDecrease = change === 'decrease';

      switch (type) {
        case 'PERCENTAGE':
          if (isDecrease) {
            value = Math.round((100 - value) * 100) / 100;
          } else {
            value = 100 + value;
          }

          break;

        case 'FLAT':
          if (isDecrease) {
            value *= -1;
          }

          break;

        default:
          break;
      }

      value *= 100;
      return {
        change,
        type,
        value
      };
    }

    _serializeTiersPrices(tiers, ruleType) {
      let newTiers = [];

      for (let i = 0; i < tiers.length; i++) {
        let tier = tiers[i];
        let priceModChange = tier.price_modifier_change;
        let priceModType = tier.price_modifier_type;
        let priceModValue = parseFloat(tier.price_modifier_value);

        let {
          value
        } = this._serializePriceModChange(priceModChange, priceModType, priceModValue);

        value = Math.round(value);
        let newTier = {
          price_modifier_type: priceModType,
          price_modifier_value: parseInt(value)
        };
        newTier = this._serializeTierSpecificKey(ruleType, tier, newTier);
        newTiers.push(newTier);
      }

      return newTiers;
    }

    _serializeTierSpecificKey(ruleType, tierObj, newTierObj) {
      switch (ruleType) {
        case 'UTILIZATION':
          newTierObj.utilization = parseInt(tierObj.utilization) / 100;
          break;

        case 'DAY_OF_TRIP':
          newTierObj.day_of_trip = parseInt(tierObj.day_of_trip) - 1;
          break;

        case 'LENGTH_OF_BOOKING':
          newTierObj.length_of_booking = parseInt(tierObj.length_of_booking);
          break;
      }

      return newTierObj;
    }

    _normalizeTierSpecificKey(ruleType, tierObj, newTierObj) {
      switch (ruleType) {
        case 'UTILIZATION':
          newTierObj.utilization = parseFloat(tierObj.utilization) * 100;
          break;

        case 'DAY_OF_TRIP':
          newTierObj.day_of_trip = parseInt(tierObj.day_of_trip) + 1;
          break;

        case 'LENGTH_OF_BOOKING':
          newTierObj.length_of_booking = parseInt(tierObj.length_of_booking);
          break;
      }

      return newTierObj;
    }

  }

  _exports.default = Rule;
});