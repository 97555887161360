define("@outdoorsyco/ember-switchback/components/range/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray100": "hsl(220, 20%, 97%)",
    "gray200": "hsl(204, 14%, 89%)",
    "gray300": "hsl(210, 14%, 84%)",
    "gray400": "hsl(212, 14%, 80%)",
    "gray500": "hsl(208, 12%, 72%)",
    "brand100": "hsl(154, 70%, 94%)",
    "brand800": "hsl(172, 50%, 28%)",
    "raisedGray100": "0 1px 1px 0 hsla(207, 6%, 23%, 0.1)",
    "raisedGray200": "0 4px 9px -2px  hsla(207, 6%, 23%, 0.1), 0 1px 2px -1px hsla(207, 6%, 23%, 0.06)",
    "focusedShadow100": "0px 0px 0px 2px color-mod(hsl(172, 50%, 28%) a(20%))",
    "Range": "_Range_1mneas",
    "rail": "_rail_1mneas",
    "rail-active": "_rail-active_1mneas",
    "rail-click-target": "_rail-click-target_1mneas",
    "button": "_button_1mneas",
    "content": "_content_1mneas",
    "bars": "_bars_1mneas"
  };
  _exports.default = _default;
});