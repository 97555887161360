define("@outdoorsyco/ember-shared-data/validator/basic-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.basicName = basicName;
  _exports.default = void 0;

  function basicName(_value, required) {
    return required ? !new RegExp(/[#$%^@!?~<>]/).test(_value.trim()) : true;
  }

  var _default = {
    param: 'basicName',
    error: {
      key: 'form-basic-name-validation',
      message: 'Name contains illegal characters'
    },
    validate: basicName
  };
  _exports.default = _default;
});