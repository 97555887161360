define("@outdoorsyco/ember-shared-data/adapters/search/rental", ["exports", "@outdoorsyco/ember-shared-data/adapters/search/-search-api"], function (_exports, _searchApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SearchRentalAdapter = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class SearchRentalAdapter extends _searchApi.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);
    }

    pathForType() {
      return 'rentals';
    }

    buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType === 'queryRecord') {
        let url = this._buildURL(modelName, query.id);

        delete query.id;
        return url;
      }

      return super.buildURL(modelName, id, snapshot, requestType, query);
    }

    urlForFindRecord()
    /*id, modelName, snapshot*/
    {
      let url = super.urlForFindRecord(...arguments);
      let unit = this.get('session.currentDistanceUnit');
      let currency = this.get('session.currentCurrency');
      let locale = this.get('intl.locale')[0];
      let embedSeoContentInResponse = this.get('session.isAuthenticated') ? 'false' : 'true';
      url += `?currency=${currency}&locale=${locale}&distance_unit=${unit}&translate=true&seo_links=${embedSeoContentInResponse}`;
      return url;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SearchRentalAdapter;
});