define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/mobile-menu/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iXhdD66A",
    "block": "{\"symbols\":[\"@link\",\"@domain\"],\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"eq\",[[23,1,[\"app\"]],\"credit\"],null],[28,\"gt\",[[23,0,[\"credits\"]],0],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[29,[[23,2,[]],\"/\",[23,1,[\"href\"]]]]],[11,\"title\",[23,1,[\"title\"]]],[10,\"data-test-mobile-nav-credit\",\"\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"account-credit mobile-menu-item\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"nav-menu-credits\"],[[\"amount\"],[[28,\"format-number\",[[23,0,[\"credits\"]]],[[\"style\",\"currency\",\"minimumFractionDigits\",\"maximumFractionDigits\"],[\"currency\",[23,0,[\"session\",\"currentCurrency\"]],0,0]]]]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"credit-status\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[29,[[23,2,[]],\"/\",[23,1,[\"href\"]]]]],[11,\"title\",[23,1,[\"title\"]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"icon\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"svg-jar\",[[23,1,[\"icon\"]]],[[\"class\"],[\"mrx\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[23,1,[\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/mobile-menu/link/template.hbs"
    }
  });

  _exports.default = _default;
});