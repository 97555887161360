define("@outdoorsyco/ember-shared-data/adapters/image", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Image extends _application.default {
    pathForType(type) {
      let path = super.pathForType(type);
      return `rentals/:rental_id/${path}`;
    }

    buildURL(type, id, snapshot, requestType, query) {
      let url = super.buildURL(type, id, snapshot);

      if (query) {
        (false && !(Boolean(query.rentalId)) && Ember.assert('you need to pass rentalId to `store.query("images")`', Boolean(query.rentalId)));
        url = url.replace(':rental_id', query.rentalId);
      }

      if (snapshot) {
        url = url.replace(':rental_id', snapshot.belongsTo('rental', {
          id: true
        }));
      }

      return url;
    }

  }

  _exports.default = Image;
});