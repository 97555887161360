define("@outdoorsyco/ember-shared-data/utils/array/max", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = max;

  function max(values, valueof) {
    let max;

    if (valueof === undefined) {
      for (const value of values) {
        if (value !== null && (max < value || max === undefined && value >= value)) {
          max = value;
        }
      }
    } else {
      let index = -1;

      for (let value of values) {
        if ((value = valueof(value, ++index, values)) !== null && (max < value || max === undefined && value >= value)) {
          max = value;
        }
      }
    }

    return max;
  }
});