define("@outdoorsyco/ember-switchback/components/fieldset/row/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Adds a row of inputs to the fieldset. All inputs inside of a row
   * will get joined together horizontally. Multiple rows will cause
   * them to join together vertically as well.
   *
   * @public
   */
  class FieldsetRow extends _component.default {}

  _exports.default = FieldsetRow;
});