define("@outdoorsyco/ember-shared-data/components/select-tag/component", ["exports", "@outdoorsyco/ember-shared-data/components/select-tag/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    content: null,
    value: null,
    selectClass: 'form-control',
    disabled: null,
    oneWay: false,
    placeholder: null,
    options: Ember.computed('content.[]', function () {
      return Ember.makeArray(this.get('content'));
    }),
    actions: {
      change() {
        let selectedValue, value;
        let selectedEl = this.$('select')[0];
        let {
          selectedIndex
        } = selectedEl;
        let content = this.get('content');

        if (this.get('placeholder')) {
          if (selectedIndex > 0) {
            selectedValue = content[selectedIndex - 1];
          } else {
            selectedValue = null;
          }
        } else {
          selectedValue = content[selectedIndex];
        }

        if (selectedValue && selectedValue.display) {
          value = selectedValue.value;
        } else {
          value = selectedValue;
        }

        if (!this.get('oneWay')) {
          this.set('value', value);
        }

        this.sendAction('onChange', value); //eslint-disable-line ember/closure-actions
      }

    }
  });

  _exports.default = _default;
});