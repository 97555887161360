define("@outdoorsyco/outdoorsy-addon/components/od-communicate-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0gc8BIGG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"od-dialog\",null,[[\"type\",\"allowClose\",\"title\",\"svgIcon\"],[\"info\",true,[28,\"t\",[\"comp-comm-always-communicate\"],null],\"question-circle\"]],{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"comp-comm-transactions-outside\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"a\",true],[10,\"href\",\"https://www.outdoorsy.com/help/pay-communicate-outdoorsy-directly\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[1,[28,\"t\",[\"term-learn-more\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/od-communicate-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});