define("@outdoorsyco/ember-switchback/modifiers/bind-attribute-to-min-width", ["exports", "ember-modifier", "resize-observer-polyfill"], function (_exports, _emberModifier, _resizeObserverPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.REM_MULTIPLIER = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let hasDOM = typeof document !== 'undefined';
  const REM_MULTIPLIER = hasDOM ? parseInt(getComputedStyle(document.body).fontSize) : 16;
  /**
   * Creates an observer that watches the element for resizes and sets a data attribute
   * to true or false based on the bounds passed in.
   */

  _exports.REM_MULTIPLIER = REM_MULTIPLIER;

  class ScrollPositionModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "resizeObserver", void 0);
    }

    get attribute() {
      return this.args.named.attribute;
    }

    get lowerBound() {
      return this.args.named.minWidth;
    }
    /**
     * Because we remove the margin around the modal when it is fullscreen we
     * need to set a higher threshold for the upper bound or it will cause continuous
     * reflows if the width falls between (lower bound) and (upper bound - margin).
     */


    get upperBound() {
      if (!this.element) {
        return null;
      }

      let computedStyles = getComputedStyle(this.element);
      let xMargin = parseInt(computedStyles.marginLeft) + parseInt(computedStyles.marginRight);
      return this.lowerBound + Math.ceil(xMargin / REM_MULTIPLIER);
    }

    get outerWidth() {
      if (!this.element) {
        return null;
      }

      let computedStyles = getComputedStyle(this.element);
      let width = parseInt(computedStyles.width) + parseInt(computedStyles.marginLeft) + parseInt(computedStyles.marginRight) + parseInt(computedStyles.paddingLeft) + parseInt(computedStyles.paddingRight);
      return width;
    }

    didReceiveArguments() {
      if (!hasDOM) {
        return;
      }

      if (!this.resizeObserver) {
        this.resizeObserver = new _resizeObserverPolyfill.default(() => {
          requestAnimationFrame(() => {
            this.updateAttribute();
          });
        });
      }
      /**
       * We need to update the data attribute as soon as possible so it is updated
       * before any animations are done playing else there may be a pop. We also need
       * to remove the padding from the width because the contentRect from the
       * resizeObserver doesn't include it.
       */


      this.updateAttribute();
      this.resizeObserver.unobserve(this.element);
      this.resizeObserver.observe(this.element);
    }

    willRemove() {
      if (!hasDOM) {
        return;
      }

      this.resizeObserver.unobserve(this.element);
    }
    /**
     * Sets a data attribute to true or false based on the *inner* width of the element.
     * Two bounds are provided to ensure that if styling changes the width when the
     * data attribute is set to true it doesn't then re-trigger the observer causing
     * it to get reset to false.
     */


    updateAttribute() {
      if (!hasDOM || !this.element) {
        return;
      }

      let windowWidth = window.innerWidth;
      let width = this.outerWidth;
      let upperBound = this.upperBound;

      if (width >= windowWidth && width < this.lowerBound * REM_MULTIPLIER) {
        this.element.setAttribute(this.attribute, 'true');
      } else if (width > upperBound * REM_MULTIPLIER) {
        this.element.setAttribute(this.attribute, 'false');
      }
    }

  }

  _exports.default = ScrollPositionModifier;
});