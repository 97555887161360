define("@outdoorsyco/ember-switchback/components/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OsTJIsa0",
    "block": "{\"symbols\":[\"option\",\"@size\",\"@maxWidth\",\"@disabled\",\"&attrs\",\"@id\",\"@value\",\"@options\",\"@placeholder\",\"@loading\",\"@searchEnabled\",\"@searchField\",\"@search\",\"@onChange\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Select\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/select/styles\"]]]]]],[12,\"data-input\",\"true\"],[12,\"data-size\",[28,\"or\",[[23,2,[]],\"medium\"],null]],[12,\"data-max-width\",[23,3,[]]],[12,\"data-focused\",[28,\"to-string\",[[23,0,[\"inputHasFocus\"]]],null]],[12,\"data-errored\",[28,\"to-string\",[[23,0,[\"isErrored\"]]],null]],[12,\"data-disabled\",[23,4,[]]],[13,5],[3,\"did-insert\",[[23,0,[\"onDidInsert\"]]]],[8],[0,\"\\n\\n  \"],[5,\"power-select\",[],[[\"@triggerId\",\"@selected\",\"@options\",\"@placeholder\",\"@placeholderComponent\",\"@searchEnabled\",\"@searchField\",\"@search\",\"@disabled\",\"@noMatchesMessage\",\"@destination\",\"@onChange\",\"@onFocus\",\"@onBlur\",\"@onOpen\",\"@onClose\"],[[28,\"or\",[[23,6,[]],[23,0,[\"inputId\"]]],null],[23,7,[]],[23,8,[]],[23,9,[]],[28,\"if\",[[23,10,[]],[28,\"component\",[\"select/placeholder\"],null]],null],[23,11,[]],[23,12,[]],[23,13,[]],[23,4,[]],[23,0,[\"noMatchesMessage\"]],\"switchback-select\",[23,14,[]],[28,\"perform\",[[23,0,[\"focusIn\"]]],null],[28,\"perform\",[[23,0,[\"focusOut\"]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"selectorIsVisible\"]]],null],true],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"selectorIsVisible\"]]],null],false],null]]],{\"statements\":[[0,\"\\n    \"],[14,15,[[23,1,[]]]],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\\n  \"],[5,\"input-error\",[[12,\"id\",[23,0,[\"errorId\"]]]],[[\"@showWhen\"],[[23,0,[\"showValidationErrors\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[23,0,[\"validationErrors\",\"firstObject\",\"message\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/select/template.hbs"
    }
  });

  _exports.default = _default;
});