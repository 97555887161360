define("ember-stripe-elements/components/stripe-card-cvc", ["exports", "ember-stripe-elements/components/stripe-element", "ember-stripe-elements/templates/components/stripe-card-cvc"], function (_exports, _stripeElement, _stripeCardCvc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _stripeElement.default.extend({
    layout: _stripeCardCvc.default,
    classNames: ['ember-stripe-card-cvc'],
    type: 'cardCvc'
  });

  _exports.default = _default;
});