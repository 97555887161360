define("@outdoorsyco/outdoorsy-addon/components/svg-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1M34rkwc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"figure\",true],[10,\"class\",\"figure\"],[8],[0,\"\\n  \"],[15,[24,[\"path\"]],[]],[0,\"\\n\"],[9]],\"hasEval\":true}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/svg-icon/template.hbs"
    }
  });

  _exports.default = _default;
});