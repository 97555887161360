define("@outdoorsyco/ember-shared-data/components/ui/aspect-ratio/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dBLrI8wM",
    "block": "{\"symbols\":[\"@isMobileFullscreen\",\"&attrs\",\"@hasImageHover\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"AspectRatio\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/ui/aspect-ratio/styles\"]]]]]],[12,\"data-mobile-radius\",[23,1,[]]],[12,\"style\",[23,0,[\"style\"]]],[13,2],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"wrapper \",[28,\"if\",[[23,3,[]],\"image-hover\"],null]],null]],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/ui/aspect-ratio/styles\"]]]]]],[8],[0,\"\\n    \"],[14,4],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/ui/aspect-ratio/template.hbs"
    }
  });

  _exports.default = _default;
});