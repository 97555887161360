define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/footer-component/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/outdoorsy/footer-component/template", "ember-window-mock", "@outdoorsyco/outdoorsy-addon/utils/chat"], function (_exports, _template, _emberWindowMock, _chat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    environment: Ember.inject.service(),
    //public
    user: null,
    showSubFooter: false,
    //private
    currentCountry: '',
    dateYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),

    init() {
      this._super(...arguments);

      let host = this.environment.host;
      let protocol = this.environment.isDevelopment ? 'http:' : 'https:';
      this.domain = `${protocol}//${host}`;
      let locale = this.intl.locale.firstObject || 'en';

      if (locale && locale === 'en') {
        locale = 'en-us';
      }

      this.set('currentCountry', locale);
    },

    isEnLocale: Ember.computed('intl.locale', function () {
      return ['en', 'en-us'].includes(this.intl.locale.firstObject);
    }),
    homepageURL: Ember.computed(function () {
      if (this.environment.isFastBoot) {
        return '/';
      }

      return `${_emberWindowMock.default.location.protocol}//${_emberWindowMock.default.location.host}/`;
    }),
    actions: {
      updateCurrency(currency) {
        this.session.updateCurrency(currency);
      },

      updateCountry(object) {
        this.session.updateCountry(object);
      },

      showHelpChat() {
        if ((0, _chat.triggerChat)()) {
          return;
        }

        this.flashMessages.danger(this.intl.t('comp-dashboard-help-center-launch-chat-err-msg'));
      },

      doOwnerPurchaseProgram() {
        let token = this.session.authKey;

        if (this.session.loggedIn && token) {
          _emberWindowMock.default.location.replace(`https://ownerpurchaseprogram.com?token=${token}`);
        } else {
          _emberWindowMock.default.location.href = '/?ownerpurchaseprogram';
        }
      }

    }
  });

  _exports.default = _default;
});