define("@outdoorsyco/ember-shared-data/components/form/server-form/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hzlaurg3",
    "block": "{\"symbols\":[\"option\",\"index\",\"r\",\"@fieldName\",\"@form\"],\"statements\":[[4,\"each\",[[23,0,[\"list\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"field\",\"item_label\"]]],null,{\"statements\":[[0,\"    \"],[5,\"instructions\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[23,0,[\"field\",\"item_label\"]],false],[0,\" \"],[1,[28,\"item-index\",[[23,2,[]]],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,0,[\"field\",\"rows\"]]],null,{\"statements\":[[0,\"    \"],[5,\"form/server-form/section\",[],[[\"@form\",\"@fieldName\",\"@field\",\"@model\"],[[23,5,[]],[23,3,[\"form_key\"]],[23,3,[]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,0,[\"field\",\"remove_action\"]],[28,\"gt\",[[23,2,[]],0],null]],null]],null,{\"statements\":[[0,\"    \"],[5,\"button\",[[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"removeItem\"]],\"parentToggle\",[23,2,[]]],null]]]],[[\"@variant\",\"@type\"],[[23,0,[\"field\",\"remove_action\",\"variant\"]],\"button\"]],{\"statements\":[[0,\"\\n      \"],[1,[23,0,[\"field\",\"remove_action\",\"label\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[5,\"hr\",[[12,\"class\",\"  margin-bottom-1 \"]],[[],[]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"field\",\"add_action\"]]],null,{\"statements\":[[0,\"  \"],[5,\"button\",[[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"addItem\"]],[23,4,[]]],null]]]],[[\"@variant\",\"@type\"],[[23,0,[\"field\",\"add_action\",\"variant\"]],\"button\"]],{\"statements\":[[0,\"\\n    \"],[1,[23,0,[\"field\",\"add_action\",\"label\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/form/server-form/list/template.hbs"
    }
  });

  _exports.default = _default;
});