define("@outdoorsyco/ember-shared-data/models/payment-processor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  let PaymentProcessorModel = (_dec = belongsTo('user'), _dec2 = attr('string'), _dec3 = attr('string'), _dec4 = attr('string'), _dec5 = attr('string'), _dec6 = attr('string'), _dec7 = attr('string', {
    defaultValue: ''
  }), _dec8 = attr('string', {
    defaultValue: ''
  }), _dec9 = attr('string'), _dec10 = attr('string'), _dec11 = attr('string'), _dec12 = attr('string'), _dec13 = attr('string'), _dec14 = attr('string'), _dec15 = attr('boolean'), _dec16 = attr('boolean'), _dec17 = attr('boolean'), _dec18 = attr('boolean'), _dec19 = attr('boolean'), _dec20 = attr('boolean'), _dec21 = attr('string'), _dec22 = attr('string'), _dec23 = attr('date'), _dec24 = attr('date'), _dec25 = attr('array', {
    defaultValue: function defaultValue() {
      return [];
    }
  }), _dec26 = Ember.computed('nickname', 'name', 'lastFour', 'processorCountry'), _dec27 = Ember.computed('nickname', 'name', 'lastFour'), _dec28 = Ember.computed('missingFields.[]'), _dec29 = Ember.computed('processorName'), _dec30 = Ember.computed('processorName'), (_class = class PaymentProcessorModel extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "owner", _descriptor, this);

      _initializerDefineProperty(this, "name", _descriptor2, this);

      _initializerDefineProperty(this, "nickname", _descriptor3, this);

      _initializerDefineProperty(this, "lastFour", _descriptor4, this);

      _initializerDefineProperty(this, "processorName", _descriptor5, this);

      _initializerDefineProperty(this, "processorCountry", _descriptor6, this);

      _initializerDefineProperty(this, "externalProcessor", _descriptor7, this);

      _initializerDefineProperty(this, "externalProcessorId", _descriptor8, this);

      _initializerDefineProperty(this, "city", _descriptor9, this);

      _initializerDefineProperty(this, "country", _descriptor10, this);

      _initializerDefineProperty(this, "state", _descriptor11, this);

      _initializerDefineProperty(this, "street", _descriptor12, this);

      _initializerDefineProperty(this, "streetEtc", _descriptor13, this);

      _initializerDefineProperty(this, "zip", _descriptor14, this);

      _initializerDefineProperty(this, "canAcceptPayments", _descriptor15, this);

      _initializerDefineProperty(this, "canSendPayments", _descriptor16, this);

      _initializerDefineProperty(this, "canArchiveBank", _descriptor17, this);

      _initializerDefineProperty(this, "depositsDisabled", _descriptor18, this);

      _initializerDefineProperty(this, "needsMigration", _descriptor19, this);

      _initializerDefineProperty(this, "archived", _descriptor20, this);

      _initializerDefineProperty(this, "supportPhone", _descriptor21, this);

      _initializerDefineProperty(this, "baseCurrency", _descriptor22, this);

      _initializerDefineProperty(this, "created", _descriptor23, this);

      _initializerDefineProperty(this, "updated", _descriptor24, this);

      _initializerDefineProperty(this, "missingFields", _descriptor25, this);
    }

    get nicknameDisplay() {
      return !Ember.isEmpty(this.nickname) ? this.nickname : `${this.name} - **** ${this.lastFour} - ${this.processorCountry}`;
    }

    get nameDisplay() {
      return !Ember.isEmpty(this.nickname) ? this.nickname : `${this.name} - **** ${this.lastFour}`;
    }

    get requiresVerification() {
      return !Ember.isEmpty(this.missingFields);
    }

    get isFinance() {
      return this.processorName === 'finance';
    }

    get isStripe() {
      return this.processorName === 'stripe';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "nickname", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "lastFour", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "processorName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "processorCountry", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "externalProcessor", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "externalProcessorId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "city", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "street", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "streetEtc", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "zip", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "canAcceptPayments", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "canSendPayments", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "canArchiveBank", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "depositsDisabled", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "needsMigration", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "archived", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "supportPhone", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "baseCurrency", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "created", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "updated", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "missingFields", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "nicknameDisplay", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "nicknameDisplay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nameDisplay", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "nameDisplay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "requiresVerification", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "requiresVerification"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isFinance", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "isFinance"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isStripe", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "isStripe"), _class.prototype)), _class));
  _exports.default = PaymentProcessorModel;
});