define('ember-mobile-pane/components/mobile-pane', ['exports', 'ember-mobile-pane/templates/components/mobile-pane', 'ember-mobile-pane/components/mobile-pane/pane', 'ember-mobile-pane/mixins/component-parent'], function (exports, _mobilePane, _pane, _componentParent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentParent.default, {
    layout: _mobilePane.default,

    classNames: ['mobile-pane'],
    classNameBindings: ['isDragging:mobile-pane--dragging'],

    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },


    // public
    activeIndex: 0,
    triggerVelocity: 0.3,
    transitionDuration: 300,

    /**
     * Renders active pane and it's nearest neighbours
     */
    lazyRendering: true,

    /**
     * Renders panes only when they are in the current viewport
     */
    strictLazyRendering: false,

    //TODO: add "keepRendered" option to only lazyRender on initial render. Maybe do this on a per pane basis?

    /**
     * Deadzone for how far a pane must be in the viewport to be rendered
     */
    strictLazyRenderingDeadZone: 0.25,

    /**
     * Keep the pane content rendered after the initial render
     */
    keepRendered: false,

    // fired whenever the active pane changes
    onChange: function onChange() {},


    actions: {
      changePane: function changePane(element) {
        Ember.set(this, 'activeIndex', element.index);
      },
      onDragStart: function onDragStart() {
        Ember.set(this, 'isDragging', true);
      },
      onDragMove: function onDragMove(dx) {
        Ember.set(this, 'dx', dx);
      },
      onDragEnd: function onDragEnd(activeIndex) {
        Ember.set(this, 'isDragging', false);
        Ember.set(this, 'activeIndex', activeIndex);
        Ember.set(this, 'dx', 0);
      },
      onPrevious: function onPrevious(activeIndex) {

        // don't advance if at the beginning
        if (Ember.get(this, 'activeIndex') <= 0) {
          return;
        }
        Ember.set(this, 'activeIndex', activeIndex - 1);
      },
      onNext: function onNext(activeIndex) {

        // don't advance if at the end
        if (Ember.get(this, 'activeIndex') >= Math.floor(Ember.get(this, 'paneCount') - 1)) {
          return;
        }

        Ember.set(this, 'activeIndex', activeIndex + 1);
      }
    },

    // private
    isDragging: false,
    dx: 0,

    _lazyRendering: Ember.computed.or('lazyRendering', 'strictLazyRendering'),

    paneContainerElement: Ember.computed('element', function () {
      if (this.get('fastboot.isFastBoot')) {
        return;
      }

      return Ember.get(this, 'element');
    }),

    panes: Ember.computed.filter('children', function (view) {
      return view instanceof _pane.default;
    }),

    paneCount: Ember.computed('panes.length', function () {
      return Ember.get(this, 'panes.length');
    }),

    navItems: Ember.computed('panes.@each.{elementId,title}', function () {
      return Ember.get(this, 'panes').map(function (item, index) {
        var result = item.getProperties('elementId', 'title');
        result.index = index;
        return result;
      });
    }),

    activePane: Ember.computed('panes.@each.elementId', 'activeIndex', function () {
      return Ember.get(this, 'panes').objectAt(Ember.get(this, 'activeIndex'));
    }),

    /**
     * Returns the panes which should be rendered when lazy rendering is enabled.
     */
    visiblePanes: Ember.computed('panes.@each.elementId', 'activeIndex', 'navOffset', function () {
      var activeIndex = Ember.get(this, 'activeIndex');
      var visibleIndices = [activeIndex];

      if (Ember.get(this, 'strictLazyRendering')) {
        var navOffset = Ember.get(this, 'navOffset');
        var lazyOffset = navOffset - activeIndex;

        if (Math.abs(lazyOffset) > Ember.get(this, 'strictLazyRenderingDeadZone')) {
          var visibleNeighborIndex = lazyOffset > 0 ? Math.ceil(navOffset) : Math.floor(navOffset);

          visibleIndices.push(visibleNeighborIndex);
        }
      } else {
        visibleIndices.push(activeIndex - 1, activeIndex + 1);
      }

      return Ember.get(this, 'panes').filter(function (item, index) {
        return visibleIndices.includes(index);
      }).map(function (item) {
        return item.getProperties('elementId');
      });
    }),

    currentOffset: Ember.computed('activeIndex', 'dx', 'paneCount', function () {
      // don't divide by 0
      return Ember.get(this, 'paneCount') !== 0 ? Ember.get(this, 'activeIndex') * -100 / Ember.get(this, 'paneCount') + Ember.get(this, 'dx') : Ember.get(this, 'dx');
    }),

    //TODO: rename to something more akin of what the number represents (limitedOffset, boundedOffset)
    navOffset: Ember.computed('currentOffset', 'paneCount', function () {
      return Math.min(Math.max(Ember.get(this, 'currentOffset') * Ember.get(this, 'paneCount') / -100, 0), Ember.get(this, 'paneCount') - 1);
    })
  });
});