define("@outdoorsyco/ember-shared-data/helpers/truncate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.truncate = void 0;

  let truncate = function truncate(params) {
    let text = params[0] || '';
    let limit = params[1] || 46;
    let useWordBoundary = params[2] === false ? params[2] : true;
    let isTooLong = text.length > limit;
    let truncatedText = isTooLong ? text.substr(0, limit - 1) : text;
    truncatedText = useWordBoundary && isTooLong ? truncatedText.substr(0, truncatedText.lastIndexOf(' ')) : truncatedText;

    if (isTooLong) {
      return `${truncatedText}...`;
    }

    return truncatedText;
  };

  _exports.truncate = truncate;

  var _default = Ember.Helper.helper(truncate);

  _exports.default = _default;
});