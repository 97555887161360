define("@outdoorsyco/outdoorsy-addon/components/lazy-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8W+mz12q",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[4,\"if\",[[24,[\"errorThrown\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"lazy-image-error-message \",[28,\"if\",[[24,[\"loadImage\"]],\"loaded\"],null]]]],[8],[1,[22,\"defaultErrorText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"lazy-image-placeholder \",[28,\"if\",[[24,[\"loadImage\"]],\"loaded\"],null]]]],[8],[14,1],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"errorThrown\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"lazy-image-error-message \",[28,\"if\",[[24,[\"loadImage\"]],\"loaded\"],null]]]],[8],[1,[22,\"defaultErrorText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"lazy-image-placeholder \",[28,\"if\",[[24,[\"loadImage\"]],\"loaded\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"useDimensionsAttrs\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",true],[11,\"class\",[22,\"className\"]],[10,\"src\",\"\"],[11,\"alt\",[22,\"alt\"]],[11,\"title\",[22,\"title\"]],[11,\"height\",[22,\"height\"]],[11,\"width\",[22,\"width\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"img\",true],[11,\"class\",[22,\"className\"]],[10,\"src\",\"\"],[11,\"alt\",[22,\"alt\"]],[11,\"title\",[22,\"title\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/lazy-image/template.hbs"
    }
  });

  _exports.default = _default;
});