define("@outdoorsyco/ember-shared-data/components/upload-file-form/component", ["exports", "@outdoorsyco/ember-shared-data/components/upload-file-form/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: 'upload-file-form',
    method: 'post',
    filePath: '',
    required: null,
    isProcessing: false,

    didInsertElement() {
      let files = {};
      this.$('form').fileupload({
        autoUpload: false,
        singleFileUploads: false,
        add: (_, data) => {
          let headers;

          if (this.get('session.authKey')) {
            headers = {
              Authorization: `Token=${this.get('session.authKey')}`
            };
          }

          data.headers = headers;
          let label = document.querySelector(`label[for="${data.paramName}"]`);

          if (label && data.files && data.files.length) {
            label.innerHTML = data.files[0].name;
          }

          files[data.paramName] = data.files[0];
          this.$('button').off('click').on('click', () => {
            let uploads = [];
            let paramNames = [];
            Object.keys(files).forEach(k => {
              uploads.push(files[k]);
              paramNames.push(k);
            });
            let requiredFields = this.get('required') || [];
            let errors = [];
            requiredFields.forEach(k => {
              if (!files[k]) {
                errors.push(k);
              }
            });

            if (errors.length > 0) {
              let msg = this.get('intl').t('component-upload-file-fields-required');
              alert(`${msg}: ${errors.join(', ')}`);
              return;
            }

            data.paramName = paramNames;
            data.files = uploads;
            this.set('isProcessing', true);
            data.submit();
          });
        },
        done: () => {
          if (this.get('onComplete')) {
            this.get('onComplete')();
          }

          this.set('isProcessing', false);
        },
        error: response => {
          this.set('isProcessing', false);
          let errorMessage = this.get('intl').t('component-upload-file-fields-error');

          if (response && response.responseJSON) {
            if (response.responseJSON.original_error) {
              errorMessage = (JSON.parse(response.responseJSON.original_error) || {}).message;
            } else if (response.responseJSON.error) {
              errorMessage = response.responseJSON.error;
            }
          }

          if (errorMessage) {
            return alert(errorMessage);
          }
        }
      });
    }

  });

  _exports.default = _default;
});