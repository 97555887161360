define("@outdoorsyco/ember-switchback/components/layout/modal/inform/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+fK2MDQ8",
    "block": "{\"symbols\":[\"l\",\"@data\",\"@modal\",\"&default\",\"@accept\"],\"statements\":[[5,\"layout/modal/default\",[],[[\"@modal\"],[[23,3,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"icon\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/layout/modal/inform/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[28,\"or\",[[23,2,[\"iconUrl\"]],\"/@outdoorsyco/ember-switchback/images/mountain.svg\"],null]],[10,\"alt\",\"\"],[10,\"aria-hidden\",\"true\"],[10,\"width\",\"64px\"],[10,\"height\",\"64px\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,[23,1,[\"Heading\"]],[[12,\"class\",\"  text-pre-heading400 text-center margin-top-1 margin-bottom-0.5 \"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[23,2,[\"title\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[\"description\"]]],null,{\"statements\":[[0,\"    \"],[5,\"p\",[[12,\"class\",\"  text-center \"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[23,2,[\"description\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,4],[0,\"\\n\\n  \"],[6,[23,1,[\"ActionBar\"]],[[12,\"class\",\"  text-center margin-top-2 \"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"button\",[[12,\"data-test-accept\",\"\"],[12,\"onclick\",[23,5,[]]]],[[\"@variant\",\"@width\"],[[28,\"or\",[[23,2,[\"close\",\"variant\"]],\"gray-secondary\"],null],\"sm:full\"]],{\"statements\":[[0,\"\\n      \"],[1,[23,2,[\"close\",\"label\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/layout/modal/inform/template.hbs"
    }
  });

  _exports.default = _default;
});