define("@outdoorsyco/ember-shared-data/components/prompt/add-passenger/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Prompt that lets a user additional passengers and drivers onto their booking
   *
   * @argument {object} booking - The booking to add to
   * @argument {string} location - Where this prompt was called from for tracking purposes
   *
   * @public
   */
  let PromptAddPassenger = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class PromptAddPassenger extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "apiRequest", _descriptor, this);

      _initializerDefineProperty(this, "metrics", _descriptor2, this);

      _defineProperty(this, "name", '');

      _defineProperty(this, "email", '');

      _defineProperty(this, "driver", this.args.data.driver || this.args.data.coverageAlert ? true : false);
    }

    async onSubmit() {
      let url = `bookings/${this.args.data.booking.id}/invite`;
      let data = {
        name: this.name,
        email: this.email,
        driver: this.driver,
        source: this.args.data.location
      };

      try {
        await this.apiRequest.post(url, {
          data: JSON.stringify(data)
        });
      } catch (error) {
        let errorObject = new Error(Ember.get(error, 'payload.error') || error);
        errorObject.name = 'FormSubmit';
        throw errorObject;
      }

      this.metrics.trackEvent({
        event: 'add-passenger',
        location: this.args.data.location,
        rentalId: this.args.data.booking.belongsTo('rental').id()
      });
      this.args.accept();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PromptAddPassenger;
});