define("@outdoorsyco/ember-switchback/components/radioset/component", ["exports", "@outdoorsyco/ember-switchback/components/form-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Renders a list of options which the user can select
   * on from.
   *
   * ```handlebars
   * <Radioset
   *  @value={{this.value}}
   *  @onChange={{fn (mut this.value)}} as |r|>
   *   <r.Option @value="one">One</r.Option>
   *   <r.Option @value="two">Two</r.Option>
   *   <r.Option @value="three">Three</r.Option>
   * </Radioset>
   * ```
   * @public
   */
  let Radioset = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class Radioset extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "optionRegistry", _descriptor, this);
    }

    /**
     * Id assigned to the fieldset
     */
    get fieldsetId() {
      return this.args.id || `${Ember.guidFor(this)}--fieldset`;
    }
    /**
     * A registry of all option components for this Radioset.
     */


    onFocusin() {
      this.focusIn.perform();
    }

    onFocusout() {
      this.focusOut.perform();
    }
    /**
     * Adds a option component to the registry.
     *
     * @param {component} option - A option component to register
     */


    registerOption(option) {
      Ember.run.next(() => {
        if (!this.optionRegistry.length) {
          option.tabindex = '0';
        }

        this.optionRegistry.pushObject(option);
      });
    }
    /**
     * Removes a option component from the registry
     *
     * @param {component} option An option component to unregister
     */


    unregisterOption(option) {
      this.optionRegistry.removeObject(option);
    }
    /**
     * Manages the user navigation side the radiogroup. For a11y reason the
     * entire radioset can only have a single focusable element and you which
     * which element is focusable via arrow key navigation.
     */


    onKeyDown(event) {
      const currentOption = this.optionRegistry.findBy('tabindex', '0');
      const currentIndex = this.optionRegistry.indexOf(currentOption);
      let nextOption;

      if (event.target.nodeName === 'TEXTAREA' || event.target.nodeName === 'INPUT') {
        return;
      }
      /* Down arrow */


      if (event.keyCode === 40) {
        nextOption = this.optionRegistry[currentIndex + 1];

        if (!nextOption) {
          nextOption = this.optionRegistry[0];
        }
      }
      /* Up arrow */


      if (event.keyCode === 38) {
        nextOption = this.optionRegistry[currentIndex - 1];

        if (!nextOption) {
          nextOption = this.optionRegistry[this.optionRegistry.length - 1];
        }
      }

      if (nextOption) {
        event.preventDefault();
        currentOption.tabindex = '-1';
        nextOption.tabindex = '0';
        nextOption.focus();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "optionRegistry", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return Ember.A();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onFocusin", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFocusout", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype)), _class));
  _exports.default = Radioset;
});