define("@outdoorsyco/ember-nydus/serializers/nydus-booking", ["exports", "ember-data", "@outdoorsyco/ember-nydus/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      user: {
        embedded: 'always',
        serialize: false
      },
      vehicle: {
        embedded: 'always',
        serialize: false
      }
    }
  });

  _exports.default = _default;
});