define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/minimal-header/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "md": "768px",
    "exit-button": "_exit-button_1qujbt",
    "lyrv-phone": "_lyrv-phone_1qujbt",
    "extra-small": "_extra-small_1qujbt",
    "phone-number": "_phone-number_1qujbt"
  };
  _exports.default = _default;
});