define("@outdoorsyco/ember-shared-data/components/format-address/component", ["exports", "@outdoorsyco/ember-shared-data/components/format-address/template", "@outdoorsyco/ember-shared-data/utils/address-fields", "@outdoorsyco/ember-shared-data/utils/states-provinces"], function (_exports, _template, _addressFields, _statesProvinces) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This provides a mapping between what we call fields in the address-fields util
   * and the arguments for this component.
   */
  const keyMap = {
    thoroughfare: 'street1',
    primise: 'street2',
    localityName: 'city',
    dependentLocalityName: '',
    administrativeArea: 'state',
    postalCode: 'zip'
  };
  /**
   * Formats an address according to the standards of a specific country.
   *
   * <FormatAddress
   *    @street1={{street1}}
   *    @city={{city}}
   *    @state={{state}}
   *    @zip={{zip}}
   *    @countryCode="US">
   */

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,

    /**
     * Street address
     * @argument street1
     * @type {string}
     */
    street1: null,

    /**
     * Apartment, Suite, Box number, etc.
     * @argument street2
     * @type {string}
     */
    street2: null,

    /**
     * City.
     * @argument city
     * @type {string}
     */
    city: null,

    /**
     * State or province
     * @argument state
     * @type {string}
     */
    state: null,

    /**
     * Zip or postal code
     * @argument zip
     * @type {string}
     */
    zip: null,

    /**
     * International two character country code. ie CA, US, FR. The address
     * will be formatted according to this code. It will also bew used to
     * determine the country name to display if `showCountry` is set to true.
     *
     * @argument countryCode
     * @type {string}
     */
    countryCode: 'US',

    /**
     * Controls if the country will be shown at the end of the address. Default
     * to false, but if the address is not in the same country as the user viewing
     * the address then you should set this to true.
     *
     * @argument showCountry
     * @type {boolean}
     */
    showCountry: false,

    init() {
      this._super(...arguments);

      (false && !(this.get('countryCode')) && Ember.assert('You must provide a country code to <FormatAddress>.', this.get('countryCode')));
    },

    country: Ember.computed('countryCode', function () {
      return (0, _statesProvinces.countryNameFromAbbreviation)(this.get('countryCode'));
    }),
    formattedAddress: Ember.computed('countryCode', 'street', 'street2', 'city', 'state', 'zip', 'country', function () {
      let countryCode = this.get('countryCode');
      let order = (0, _addressFields.getSortedFieldsForCountry)(countryCode);
      let formattedAddress = '';
      (false && !(order) && Ember.assert(`Unsupported country code provided to <FormatAddress>: ${countryCode}`, order));
      order.forEach(key => {
        /**
         * We don't currently store the equivalent of dependentLocalityName
         * so we need to skip over it.
         */
        if (!keyMap[key]) {
          return;
        }

        let value = this.get(keyMap[key]) || '';
        formattedAddress += `${value} `;
        let hasAStreetOrUnit = this.get('street1') || this.get('street2');

        if (keyMap[key] === 'street2' && hasAStreetOrUnit) {
          formattedAddress += '<br>';
        }
      });
      return Ember.String.htmlSafe(formattedAddress);
    })
  });

  _exports.default = _default;
});