define("@outdoorsyco/ember-switchback/components/rating/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/a5aaLLB",
    "block": "{\"symbols\":[\"@count\",\"@rating\",\"&attrs\",\"@size\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Rating\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/rating/styles\"]]]]]],[12,\"aria-label\",[28,\"concat\",[[23,2,[]],\" stars.\"],null]],[13,3],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"stars\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/rating/styles\"]]]]]],[11,\"data-size\",[28,\"or\",[[23,4,[]],\"small\"],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"fill\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/rating/styles\"]]]]]],[11,\"style\",[28,\"html-safe\",[[28,\"concat\",[\"width:\",[23,0,[\"percent\"]],\"%\"],null]],null]],[10,\"data-test-fill\",\"\"],[8],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[5,\"text\",[[12,\"class\",\"  text-pre-subtle margin-left-0.25 \"],[12,\"aria-hidden\",\"true\"]],[[],[]],{\"statements\":[[0,\"\\n      (\"],[1,[23,1,[]],false],[0,\")\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/rating/template.hbs"
    }
  });

  _exports.default = _default;
});