define("@outdoorsyco/form-components/components/radio-field/component", ["exports", "@outdoorsyco/form-components/components/radio-field/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    label: '',
    value: false,
    uniqueId: Ember.computed(function () {
      return `${Ember.guidFor(this)}-radio`;
    }),
    actions: {
      changed(value) {
        if (this.get('onChange')) {
          this.get('onChange')(value);
        }
      }

    }
  });

  _exports.default = _default;
});