define("@outdoorsyco/outdoorsy-addon/components/lazy-image/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/lazy-image/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const raf = window.requestAnimationFrame;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['lazy-image-container'],
    //public
    class: '',
    isLoaded: false,
    loadImage: Ember.computed('isLoaded', function () {
      let isLoaded = this.get('isLoaded');

      if (isLoaded) {
        this.img = this.element.getElementsByTagName('img')[0];
        raf(this._loadImage.bind(this));
      }

      return isLoaded;
    }),

    _loadImage() {
      if (this.img && !(this.isDestroying || this.isDestroyed)) {
        this.img.src = this.url;
      }
    }

  });

  _exports.default = _default;
});