define("@outdoorsyco/ember-switchback/components/frame/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lFKIeoO+",
    "block": "{\"symbols\":[\"Tag\",\"@variant\",\"@highlight\",\"@selected\",\"@onClick\",\"@maxWidth\",\"@onKeyDown\",\"&attrs\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"-element\",[[23,0,[\"tagName\"]]],null]],[[\"tagName\"],[[23,0,[\"tagName\"]]]]]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[[12,\"class\",[29,[[28,\"local-class\",[\"Frame\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/frame/styles\"]]]]]],[12,\"data-variant\",[28,\"or\",[[23,2,[]],\"default\"],null]],[12,\"data-highlight\",[23,3,[]]],[12,\"data-selected\",[23,4,[]]],[12,\"data-clickable\",[28,\"if\",[[23,5,[]],\"true\"],null]],[12,\"data-max-width\",[23,6,[]]],[12,\"onclick\",[23,5,[]]],[12,\"onKeyDown\",[23,7,[]]],[12,\"tabindex\",[28,\"if\",[[23,7,[]],\"0\",\"-1\"],null]],[13,8]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/frame/template.hbs"
    }
  });

  _exports.default = _default;
});