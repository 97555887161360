define("@outdoorsyco/ember-shared-data/transforms/cents-to-dollars", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/utils/money"], function (_exports, _emberData, _money) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: _money.centsToDollars,
    serialize: _money.dollarsToCents
  });

  _exports.default = _default;
});