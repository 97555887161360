define("@outdoorsyco/ember-shared-data/services/metrics", ["exports", "ember-metrics/services/metrics", "@sentry/browser"], function (_exports, _metrics, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _metrics.default.extend({
    experiments: Ember.inject.service(),
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      this.appVersion = Ember.get(config, 'APP.version') || null;
    },

    trackEvent(...args) {
      let eventData = args[args.length - 1];
      eventData = Object.assign({}, eventData); // outdoorsy-renter includes `source: ribbit` on all events

      eventData.stack = 'ember';
      eventData.experiments = this.experiments.getExperiments(); // user id

      if (!eventData.uid && this.session.userId) {
        eventData.uid = this.session.userId;
      } // app version


      eventData.app_version = this.appVersion; // Movable ink requires certain fields to be strings, and segment can't convert data types

      const moveableInkEvents = ['Search Results Viewed', 'Listing Viewed', 'Booking Request Contact Started', 'Booking Request Payment Completed'];

      if (moveableInkEvents.includes(eventData.event)) {
        const stringFields = ['rentalID', 'listingID', 'rentalIDs', 'listingIDs', 'bookingID', 'bookingIDs'];
        stringFields.forEach(field => {
          if (field in eventData && !('string_' + field in eventData)) {
            if (Array.isArray(eventData[field])) {
              eventData['string_' + field] = eventData[field].map(f => `${f}`);
            } else {
              eventData['string_' + field] = `${eventData[field]}`;
            }
          }
        });
      } // add Sentry breadcrumb


      (0, _browser.addBreadcrumb)({
        category: 'odc-metrics',
        message: eventData.event
      });
      args[args.length - 1] = eventData;
      return this._super(...args);
    },

    getFBPixelProps() {
      let fbp = this.cookies.read('_fbp');
      let fbc = this.cookies.read('_fbc');
      let props = {
        actionSource: 'website'
      };

      if (fbp) {
        props.fbp = fbp;
      }

      if (fbc) {
        props.fbc = fbc;
      }

      return props;
    },

    // Try to get anonymous userId from Segment
    getAnonymousUserId() {
      if (window && window.analytics && window.analytics.user) {
        const user = window.analytics.user();
        return user === null || user === void 0 ? void 0 : user.anonymousId();
      }
    }

  });

  _exports.default = _default;
});