define('ember-mobile-core/mixins/run-on-raf', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    runOnRaf: function runOnRaf(fn) {
      var _this = this;

      //TODO: emcTicking is set on the ember component instance, prevents multiple schedules of different things (?)
      // should it be possible to schedule other work for this animation frame?
      if (!this.emcTicking) {
        this.emcTicking = true;

        window.requestAnimationFrame(function () {
          _this.emcTicking = false;

          if (_this.get('isDestroyed')) return;

          Ember.run(_this, fn);
        });
      }
    }
  });
});