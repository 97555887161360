define("@outdoorsyco/ember-shared-data/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var JSONSerializer = _emberData.default.JSONSerializer;

  class Application extends JSONSerializer {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isNewSerializerAPI", true);
    }

    // we underscore all the things
    keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    }

    keyForRelationship(key, relationship) {
      key = Ember.String.underscore(key);

      if (relationship === 'hasMany') {
        return key;
      }

      return `${key}_id`;
    } // we want to break this thing


    serializeBelongsTo(snapshot, json, relationship) {
      super.serializeBelongsTo(snapshot, json, relationship);
      const {
        key
      } = relationship;
      const jsonKey = Ember.isPresent(this.keyForRelationship) ? this.keyForRelationship(key, 'belongsTo', 'serialize') : key;

      if (Ember.isBlank(json[jsonKey])) {
        return json[jsonKey] = null;
      }

      return json[jsonKey] = Number(json[jsonKey]) || json[jsonKey];
    }

    serializeHasMany(snapshot, json, relationship) {
      super.serializeHasMany(snapshot, json, relationship);
      const {
        key
      } = relationship;
      const jsonKey = Ember.isPresent(this.keyForRelationship) ? this.keyForRelationship(key, 'hasMany', 'serialize') : key;

      if (Ember.isBlank(json[jsonKey])) {
        return json[jsonKey] = null;
      }

      return json[jsonKey] = json[jsonKey].map(i => Number(i));
    }

  }

  _exports.default = Application;
});