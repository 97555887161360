define("ember-google-maps/templates/components/g-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AXrayOkZ",
    "block": "{\"symbols\":[\"gMap\",\"addons\",\"&attrs\",\"&default\"],\"statements\":[[4,\"with\",[[28,\"hash\",null,[[\"canvas\",\"publicAPI\",\"map\",\"_internalAPI\"],[[28,\"component\",[\"g-map/canvas\"],[[\"id\",\"classNames\",\"_internalAPI\"],[[23,0,[\"mapId\"]],[23,0,[\"classNames\"]],[23,0,[\"_internalAPI\"]]]]],[23,0,[\"publicAPI\"]],[23,0,[\"map\"]],[23,0,[\"_internalAPI\"]]]]]],null,{\"statements\":[[0,\"\\n\"],[4,\"-private-api/addon-factory\",null,[[\"gMap\",\"map\",\"_internalAPI\"],[[23,0,[\"gMap\"]],[23,1,[\"map\"]],[23,1,[\"_internalAPI\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"g-map/compute\",[[28,\"action\",[[23,0,[]],[23,0,[\"_updateGMap\"]]],null],[23,1,[]],[23,2,[]]],null],false],[0,\"\\n\\n\"],[4,\"unless\",[[26,4]],null,{\"statements\":[[0,\"      \"],[6,[23,1,[\"canvas\"]],[[13,3]],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[14,4,[[23,0,[\"gMap\"]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map.hbs"
    }
  });

  _exports.default = _default;
});