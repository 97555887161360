define("calendar-picker/components/od-calendars", ["exports", "moment", "calendar-picker/mixins/od-rangepick", "calendar-picker/templates/components/od-calendars"], function (_exports, _moment, _odRangepick, _odCalendars) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_odRangepick.default, {
    tagName: '',
    layout: _odCalendars.default,
    format: 'MMMM YYYY',
    guid: null,
    actions: {
      addMoreMonths(monthsToAdd) {
        let calendars = this.get('calendars'); // find the last month to add to

        const lastCurrentMonth = calendars.lastObject.center.clone(); // start of addition needs to be month after last current

        const additionStartDate = lastCurrentMonth.add(1, 'month').startOf('month');
        const additionEndDate = additionStartDate.clone().add(monthsToAdd - 1, 'month'); // build the range of the addition

        const additionRange = (0, _moment.default)().range(additionStartDate, additionEndDate); // iterate over range and add months to existing calendar Ember array

        for (let month of additionRange.by('months')) {
          calendars.pushObject(Ember.Object.create({
            center: month,
            ref: null
          }));
        }
      }

    }
  });

  _exports.default = _default;
});