define("@outdoorsyco/outdoorsy-addon/utils/clean-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cleanParams;

  function cleanParams(param) {
    if (Ember.isEmpty(param)) {
      return;
    }

    return Ember.String.dasherize(param.replace(/[^0-9a-z\s-]/gi, '').replace(/^\s+|\s+$/g, '')).toLowerCase();
  }
});