define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/desktop-menu/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DesktopMenu = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember.computed.readOnly('environment.config'), _dec8 = Ember.computed.readOnly('environment.origin'), _dec9 = Ember.computed.readOnly('session.user.credits'), _dec10 = Ember.computed.readOnly('conversations.messagesCount'), _dec11 = Ember._action, (_class = class DesktopMenu extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "metrics", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "environment", _descriptor3, this);

      _initializerDefineProperty(this, "experiments", _descriptor4, this);

      _initializerDefineProperty(this, "conversations", _descriptor5, this);

      _initializerDefineProperty(this, "domain", _descriptor6, this);

      _initializerDefineProperty(this, "config", _descriptor7, this);

      _initializerDefineProperty(this, "domain", _descriptor8, this);

      _initializerDefineProperty(this, "userCredits", _descriptor9, this);

      _initializerDefineProperty(this, "messagesCount", _descriptor10, this);
    }

    // userCredits is NaN for a brief moment, so use 0 when necessary
    get credits() {
      return this.userCredits > 0 ? this.userCredits : 0;
    }

    trackEvent(value) {
      this.metrics.trackEvent({
        event: 'Growth : Referral : Clicked nav CTA',
        auth_status: value
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "experiments", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "conversations", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "domain", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "domain", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "userCredits", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "messagesCount", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "trackEvent", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "trackEvent"), _class.prototype)), _class));
  _exports.default = DesktopMenu;
});