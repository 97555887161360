define("@outdoorsyco/ember-shared-data/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var RESTAdapter = _emberData.default.RESTAdapter;
  let Application = (_dec = Ember.computed(), (_class = class Application extends RESTAdapter.extend(_dataAdapterMixin.default) {
    // ember-simple-auth
    authorize() {
      return;
    }

    urlForFindRecord(id, modelName, snapshot) {
      const defaultUrl = super.urlForFindRecord(...arguments); // Allow for forced cache busting for findRecord() or reload()
      // Fixes bug where IE11 tries to improperly cache requests (even if using {reload: true})

      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.forceCacheBust) {
        return `${defaultUrl}?requested_at=${Date.now()}`;
      }

      return defaultUrl;
    }

    get fastboot() {
      return Ember.getOwner(this).lookup('service:fastboot');
    }

    get host() {
      let config = this.appConfig();

      if (this.fastboot && this.get('fastboot.isFastBoot') && config.APP.internalCoreApi && !config.isDev && !config.isCI) {
        return config.APP.internalCoreApi;
      }

      return config.APP.apiHost;
    }

    get headers() {
      let h = {};

      if (this.get('fastboot.isFastBoot')) {
        h['X-App-Session'] = 'fastboot';
        h['X-Device-ID'] = 'fastboot';
      }

      if (window.Outdoorsy && window.Outdoorsy.metrics && window.Outdoorsy.metrics.getIdentifier) {
        let {
          sid,
          did
        } = window.Outdoorsy.metrics.getIdentifier();
        h['X-App-Session'] = sid;
        h['X-Device-ID'] = did;
      }

      let config = this.appConfig() || {};

      if (config && config.outdoorsyPartner && config.outdoorsyPartner.key) {
        h['Partner-ID'] = config.outdoorsyPartner.key;
      }

      let accessToken = this.get('session.data.authenticated.token');

      if (Ember.isPresent(accessToken)) {
        h['Authorization'] = `Token=${accessToken.replace('guest:', '')}`;
      }
      /**
       * Adds header from mobile bouncer. See <Bouncer::MobileDiscount /> from
       * outdoorsy-search.
       */


      try {
        if (window && window.localStorage) {
          let applyCredit = window.localStorage.getItem('mobile-apply-credit');

          if (applyCredit) {
            h['X-Apply-Credit'] = applyCredit;
          }
        }
      } catch (_unused) {// ignore localstorage permissions error
      }

      return h;
    }

    appConfig() {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }

    handleResponse(status, headers, payload, requestData) {
      let _headers = headers;

      if (typeof FastBoot !== 'undefined' && headers.server) {
        let merged = {};
        headers.server.split('\n').map(l => {
          let line = l.split(': ');
          let o = {};
          line[0] = line[0].split('-').map(k => k.charAt(0) + k.substr(1, k.length - 1)).join('-');
          o[line[0]] = line[1];
          return o;
        }).forEach(o => Object.assign(merged, o));
        _headers = merged;
      }

      _headers = this.lowerCaseHeaderKeys(_headers);

      if (_headers['x-ppp-token']) {
        let config = this.appConfig();
        config.stripe = config.stripe || {};
        config.stripe.serverProvidedStripeKey = _headers['x-ppp-token'];
      }

      if (payload && typeof payload === 'object') {
        if (!payload.meta) {
          payload.meta = {};
        }

        if (_headers['anon-token']) {
          payload.anon_token = _headers['anon-token'];
          payload.anon_user_id = _headers['anon-user-id'];
        }

        if (_headers['x-ppp-country']) {
          payload.payment_country = _headers['x-ppp-country'];
        }

        payload.meta.total = payload.meta.total || Number(_headers['total-results']) || null;
        payload.meta.totalAmount = Number(_headers['total-amount']) || null;
        payload.meta.priceMin = Number(_headers['price-min']) || null;
        payload.meta.priceMax = Number(_headers['price-max']) || null;
        payload.meta.unread = Number(_headers['total-unread-activity']) || null;
        payload.meta.city = _headers['geo-ip-city'];
        payload.meta.state = _headers['geo-ip-state'];
        payload.meta.county = _headers['geo-ip-county'];
        payload.meta.country = _headers['geo-ip-country'];
        payload.meta.lat = _headers['geo-ip-lat'];
        payload.meta.lng = _headers['geo-ip-lng'];
        payload.meta.autoRadius = _headers['auto-radius'];
      }

      return super.handleResponse(status, _headers, payload, requestData);
    }

    normalizeErrorResponse(status, headers, payload, requestData) {
      if (payload && typeof payload === 'string') {
        try {
          payload = JSON.parse(payload);
        } catch (error) {
          const logger = Ember.getOwner(this).lookup('logger:outdoorsy');

          if (logger) {
            logger.debug('Error parse issue', {
              error
            });
          }
        }
      }

      if (payload && typeof payload === 'object' && payload.error) {
        return [{
          status,
          title: payload.error,
          detail: payload.details || '',
          code: payload.code || '',
          original_error: payload.original_error || '',
          errors: payload.errors || []
        }];
      }

      return super.normalizeErrorResponse(status, headers, payload, requestData);
    } // just for setting method as PATCH
    // this won't be necessary in future ember versions


    updateRecord(store, type, record) {
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, record);
      let {
        id
      } = record;
      return this.ajax(this.buildURL(type.modelName, id, record, 'updateRecord'), 'PATCH', {
        data
      });
    } // we do our sideloading as ids=1,2,3.  Ember defaults to ids[]=1&ids[]=2


    findMany(store, type, ids, snapshots) {
      ids = Ember.makeArray(ids);
      let url = this.buildURL(type.modelName, ids, snapshots, 'findMany');
      return this.ajax(url, 'GET', {
        data: {
          ids: `${ids.join(',')}`
        }
      });
    } // this is part of the ember simple auth mixin - we want to handle this ourselves


    ensureResponseAuthorized() {
      return;
    }

    lowerCaseHeaderKeys(headers) {
      return Object.keys(headers).reduce(function (newObj, key) {
        let newKey = headers[key];
        newObj[key.toLowerCase()] = newKey;
        return newObj;
      }, {});
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "host", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "host"), _class.prototype)), _class));
  _exports.default = Application;
});