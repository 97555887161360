define("@outdoorsyco/ember-shared-data/services/affirm", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AffirmService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class AffirmService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "environment", _descriptor, this);

      _initializerDefineProperty(this, "fastboot", _descriptor2, this);
    }

    loadAffirm() {
      if (this.fastboot.isFastBoot) {
        return;
      }

      if (_emberWindowMock.default.affirm && _emberWindowMock.default.affirm.ui.ready()) {
        _emberWindowMock.default.affirm.ui.refresh();
      } else {
        if (document.readyState === 'complete') {
          this._addAffirmScriptToPage();
        } else {
          _emberWindowMock.default.addEventListener('load', () => {
            this._addAffirmScriptToPage();
          });
        }
      }
    }
    /**
     * This cannot use the load-script utility because Affirm needs the config set inside of the
     * script tag along with the custom minified function to load the script.
     * https://docs.affirm.com/affirm-developers/docs/afjs-reference#section-including-and-initializing-affirmjs
     */


    _addAffirmScriptToPage() {
      if (document.querySelector('#affirm-script')) {
        return;
      }

      let source = 'https://cdn1.affirm.com/js/v2/affirm.js';

      if (this.environment.config.affirm.sandbox === true) {
        source = 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js';
      }

      let element = document.createElement('script');
      element.id = 'affirm-script';
      element.type = 'text/javascript';
      element.async = true;
      element.innerHTML = `
      _affirm_config = {
       public_api_key:  '${this.environment.config.affirm.apiKey}',
       script:          '${source}',
       session_id:      ''
      };
      (function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,_affirm_config,"affirm","checkout","ui","script","ready");
    `;
      document.querySelector('.ember-application').appendChild(element);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadAffirm", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "loadAffirm"), _class.prototype)), _class));
  _exports.default = AffirmService;
});