define("@outdoorsyco/ember-shared-data/components/add-bank/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nP4qjfKI",
    "block": "{\"symbols\":[\"@user\",\"@bank\",\"@defaultCountry\"],\"statements\":[[5,\"bank-form\",[],[[\"@user\",\"@bank\",\"@defaultCountry\",\"@onSubmit\"],[[23,1,[]],[23,2,[]],[23,3,[]],[28,\"perform\",[[23,0,[\"onSubmit\"]]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/add-bank/template.hbs"
    }
  });

  _exports.default = _default;
});