define("@outdoorsyco/ember-shared-data/components/set-tax-form/component", ["exports", "@outdoorsyco/ember-shared-data/components/set-tax-form/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    store: Ember.inject.service(),
    activeTaxRate: Ember.computed('taxRate.tax_rate_id', function () {
      let id = (this.get('taxRate') || {}).tax_rate_id;

      if (!id) {
        return '';
      }

      return this.get('store').find('tax', id);
    }),
    actions: {
      updateTaxRate(newTaxRate) {
        if (newTaxRate) {
          this.set('taxRate.tax_name', newTaxRate.get('name'));
          this.set('taxRate.tax_description', newTaxRate.get('description'));
          this.set('taxRate.tax_rate', newTaxRate.get('rate'));
          this.set('taxRate.tax_rate_id', newTaxRate.get('id'));
        }
      },

      saveChanges() {
        this.get('item').save();
        this.send('close');
      },

      cancelChanges() {
        this.get('item').rollbackAttributes();
        this.send('close');
      },

      close() {
        if (this.get('close')) {
          this.get('close')();
        }
      }

    }
  });

  _exports.default = _default;
});