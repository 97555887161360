define("@outdoorsyco/ember-switchback/components/input-error/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "raisedShadow300": "0 4px 6px -1px hsla(0, 0%, 0%, 0.1), 0 2px 4px -1px hsla(0, 0%, 0%, 0.06)",
    "font100": "normal normal 400 normal 14px/1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;",
    "gray200": "hsl(204, 14%, 89%)",
    "InputError": "_InputError_1xh14w",
    "show": "_show_1xh14w",
    "icon": "_icon_1xh14w",
    "text": "_text_1xh14w"
  };
  _exports.default = _default;
});