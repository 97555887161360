define("@outdoorsyco/ember-shared-data/components/reports/deposits/batch/detail-table/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BatchDetailTableComponent extends _component.default {}

  _exports.default = BatchDetailTableComponent;
});