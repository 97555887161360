define("ember-mapbox-gl/templates/components/mapbox-gl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JFXkjvMK",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"_loader\",\"isLoaded\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"_glSupported\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"_map\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"_isMapLoaded\"]]],null,{\"statements\":[[0,\"        \"],[14,2,[[28,\"hash\",null,[[\"call\",\"control\",\"image\",\"layer\",\"marker\",\"on\",\"popup\",\"source\",\"instance\"],[[28,\"component\",[\"mapbox-gl-call\"],[[\"obj\"],[[24,[\"_map\"]]]]],[28,\"component\",[\"mapbox-gl-control\"],[[\"map\"],[[24,[\"_map\"]]]]],[28,\"component\",[\"mapbox-gl-image\"],[[\"map\"],[[24,[\"_map\"]]]]],[28,\"component\",[\"mapbox-gl-layer\"],[[\"map\"],[[24,[\"_map\"]]]]],[28,\"component\",[\"mapbox-gl-marker\"],[[\"map\"],[[24,[\"_map\"]]]]],[28,\"component\",[\"mapbox-gl-on\"],[[\"eventSource\"],[[24,[\"_map\"]]]]],[28,\"component\",[\"mapbox-gl-popup\"],[[\"map\"],[[24,[\"_map\"]]]]],[28,\"component\",[\"mapbox-gl-source\"],[[\"map\"],[[24,[\"_map\"]]]]],[24,[\"_map\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl.hbs"
    }
  });

  _exports.default = _default;
});