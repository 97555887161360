define("ember-app-scheduler/scheduler", ["exports", "ember-test-waiters"], function (_exports, _emberTestWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.beginTransition = beginTransition;
  _exports.endTransition = endTransition;
  _exports.setupRouter = setupRouter;
  _exports.reset = reset;
  _exports.didTransition = didTransition;
  _exports.whenRoutePainted = whenRoutePainted;
  _exports.whenRouteIdle = whenRouteIdle;
  _exports.routeSettled = routeSettled;
  _exports.SIMPLE_CALLBACK = void 0;
  const APP_SCHEDULER_LABEL = 'ember-app-scheduler';
  const APP_SCHEDULER_HAS_SETUP = '__APP_SCHEDULER_HAS_SETUP__';

  let _whenRouteDidChange;

  let _whenRouteIdle;

  const SIMPLE_CALLBACK = callback => callback();

  _exports.SIMPLE_CALLBACK = SIMPLE_CALLBACK;
  const IS_FASTBOOT = typeof window.FastBoot !== 'undefined';
  const waiter = (0, _emberTestWaiters.buildWaiter)('ember-app-scheduler-waiter');
  reset();
  /**
   * Initializes the top level promise that initiates the post-render work.
   */

  function beginTransition() {
    if (_whenRouteDidChange.isResolved) {
      _whenRouteDidChange = _defer(APP_SCHEDULER_LABEL);
      _whenRouteIdle = _whenRouteDidChange.promise.then(() => {
        let scheduledWorkToken = waiter.beginAsync();
        return new Ember.RSVP.Promise(resolve => {
          Ember.run.schedule('afterRender', null, () => {
            requestAnimationFrame(() => {
              requestAnimationFrame(resolve);
            });
          });
        }).finally(() => {
          waiter.endAsync(scheduledWorkToken);
          mark('appSchedulerEnd');
          measure('appScheduler', 'appSchedulerStart', 'appSchedulerEnd');
        });
      });
    }
  }
  /**
   * Initiates the post-render work.
   */


  function endTransition() {
    _whenRouteDidChange.resolve();

    mark('appSchedulerStart');
  }
  /**
   * Correct connects the router's transition events to
   * app scheduler's work.
   *
   * @param {Router} router An instance of an Ember router.
   */


  function setupRouter(router) {
    if (IS_FASTBOOT || router[APP_SCHEDULER_HAS_SETUP]) {
      return;
    }

    router[APP_SCHEDULER_HAS_SETUP] = true;

    if (true) {
      router.on('routeWillChange', beginTransition);
      router.on('routeDidChange', endTransition);
    } else {
      router.on('willTransition', beginTransition);
      router.on('didTransition', endTransition);
    }
  }
  /**
   * Resets the state of app scheduler's top-level scheduled work promise.
   */


  function reset() {
    _whenRouteDidChange = _defer(APP_SCHEDULER_LABEL);
    _whenRouteIdle = _whenRouteDidChange.promise.then();
    waiter.reset();

    if (!IS_FASTBOOT) {
      _whenRouteDidChange.resolve();
    }
  }
  /**
   * Top level promise that represents the entry point for deferred work.
   * Subsequent promises are chained off this promise, successively composing
   * them together to approximate when painting has occurred.
   *
   * @public
   * @return {Promise} The top-level scheduled work promise.
   */


  function didTransition() {
    (false && !(false) && Ember.deprecate('The `didTransition` function is deprecated. Please use `whenRouteIdle` instead.', false, {
      id: 'ember-app-scheduler.didTransition',
      until: '4.0.0'
    }));
    return _whenRouteDidChange.promise;
  }
  /**
   * This promise, when resolved, approximates after the route is first painted.
   * This can be used to schedule work to occur that is lower priority than initial
   * work (content outside of the viewport, rendering non-critical content).
   *
   * @public
   * @return {Promise} The scheduled work promise.
   */


  function whenRoutePainted() {
    (false && !(false) && Ember.deprecate('The `whenRoutePainted` function is deprecated. Please use `whenRouteIdle` instead.', false, {
      id: 'ember-app-scheduler.whenRoutePainted',
      until: '4.0.0'
    }));
    return _whenRouteIdle;
  }
  /**
   * This promise, when resolved, approximates after content is painted.
   *
   * @public
   * @return {Promise} The scheduled work promise.
   */


  function whenRouteIdle() {
    return _whenRouteIdle;
  }
  /**
   * Allows for tests to pause until the scheduled work
   * promise is completed.
   *
   * @return {Promise} The scheduled work promise.
   */


  function routeSettled() {
    return _whenRouteIdle;
  }

  function _defer(label) {
    let _isResolved = false;

    let _resolve;

    let _reject;

    const promise = new Ember.RSVP.Promise((resolve, reject) => {
      _resolve = () => {
        _isResolved = true;
        resolve();
      };

      _reject = reject;
    }, label);
    return {
      promise,
      resolve: _resolve,
      reject: _reject,

      get isResolved() {
        return _isResolved;
      }

    };
  }

  function mark(markName) {
    try {
      performance.mark(markName);
    } catch (ex) {
      console.warn(`performance.mark could not be executed because of ${ex.message}`);
    }
  }

  function measure(measureName, startMark, endMark) {
    try {
      performance.measure(measureName, startMark, endMark);
    } catch (ex) {
      console.warn(`performance.measure could not be executed because of ${ex.message}`);
    }
  }
});