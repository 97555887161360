define("@outdoorsyco/ember-shared-data/serializers/quote", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class QuoteSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        lineItems: {
          deserialize: 'records'
        },
        premiumServices: {
          deserialize: 'records'
        },
        insuranceBundle: {
          deserialize: 'records',
          serialize: false
        }
      });
    }

    serialize(record, options) {
      let data = super.serialize(record, options);
      /**
       * The server applies a default bundle to bookings, but if we
       * send a null bundle_id then it will not so we need to delete it.
       */

      if (data.bundle_id === null) {
        delete data.bundle_id;
      }

      return data;
    }

    normalize(typeClass, data) {
      /**
       * The server sends back "" for a bundle ID which is invalid
       * so we need to delete it.
       */
      if (!data.bundle_id) {
        delete data.bundle_id;
      }
      /**
       * We have renamed items to line-items on the booking for some
       * reason. We maintain that rename here for consistency.
       */


      if (data.items) {
        data.line_items = data.items;
        delete data.items;
        /**
         * The backend doesn't provide IDs for lines so we need to
         * create some.
         */

        data.line_items.forEach(line_item => {
          if (!line_item.id) {
            line_item.id = Ember.guidFor(line_item);
          }
        });
      }
      /**
       * We have renamed services to premium-services on the booking for
       * some reason. We maintain that rename here for consistency.
       */


      if (data.services) {
        data.premium_services = data.services;
        delete data.services;
        /**
         * The backend doesn't provide IDs for services so we need to
         * create some.
         */

        data.premium_services.forEach(service => {
          if (!service.id) {
            service.id = Ember.guidFor(service);
          }
        });
      }

      return super.normalize(...arguments);
    }

  }

  _exports.default = QuoteSerializer;
});