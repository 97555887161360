define("@outdoorsyco/ember-switchback/components/switchback/prompts/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * A private component that implements the dom part of the prompt service.
   *
   * ```handlebars
   *  <Prompt />
   * ```
   * @private
   */
  let Prompts = (_dec = Ember.inject.service('prompt'), _dec2 = Ember._action, (_class = class Prompts extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "promptService", _descriptor, this);

      _initializerDefineProperty(this, "accept", _descriptor2, this);

      _initializerDefineProperty(this, "cancel", _descriptor3, this);
    }

    /**
     * Cancels the prompt if the users hit esc or clicked on the overlay
     * @param {object} prompt The prompt instance that is being shown
     * @param {object} options Options from the onClose event
     */
    onClose(prompt, options) {
      /**
       * If the user accept or cancels the prompt the onClose method still gets
       * called so we want to ignore it in those circumstances.
       */
      if (this.accept.isRunning || this.cancel.isRunning) {
        return;
      }

      if (options.cancelled) {
        prompt.cancel();
      } else {
        prompt.accept();
      }
    }
    /**
     * Accepts the prompt and closes the modal.
     * @param {object} prompt The prompt instance that is being shown
     * @param {object} modal The modal instance that is being shown
     * @param {object} options Any data that the user may want to pass through
     */


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "promptService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "accept", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (prompt, modal, ...options) {
        yield modal.close();
        prompt.accept(...options);
      };
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "cancel", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (prompt, modal, ...options) {
        yield modal.close();
        prompt.cancel(...options);
      };
    }
  })), _class));
  _exports.default = Prompts;
});