define("@outdoorsyco/ember-shared-data/services/phone-input", ["exports", "ember-phone-input/services/phone-input"], function (_exports, _phoneInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _phoneInput.default.extend({
    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),

    load() {
      if (this.get('fastboot.isFastBoot')) {
        return;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});