define("@outdoorsyco/ember-shared-data/components/input/country/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JwWP+JnF",
    "block": "{\"symbols\":[\"option\",\"@id\",\"@value\",\"@form\",\"@requirements\",\"@maxWidth\",\"@disabled\",\"&attrs\"],\"statements\":[[5,\"select\",[[12,\"name\",\"country\"],[12,\"autocomplete\",\"country\"],[13,8],[3,\"did-insert\",[[28,\"perform\",[[23,0,[\"getCountryOptions\"]]],null]]]],[[\"@id\",\"@value\",\"@form\",\"@requirements\",\"@options\",\"@search\",\"@searchEnabled\",\"@onChange\",\"@loading\",\"@maxWidth\",\"@disabled\"],[[23,2,[]],[28,\"find-by\",[\"code\",[23,3,[]],[23,0,[\"expandedOptions\"]]],null],[23,4,[]],[23,5,[]],[23,0,[\"filteredExpandedOptions\"]],[23,0,[\"search\"]],true,[23,0,[\"onChange\"]],[23,0,[\"getCountryOptions\",\"isRunning\"]],[23,6,[]],[23,7,[]]]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/input/country/template.hbs"
    }
  });

  _exports.default = _default;
});