define("@outdoorsyco/ember-shared-data/utils/array/mean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mean;

  function mean(values, valueof) {
    let count = 0;
    let sum = 0;

    if (valueof === undefined) {
      for (let value of values) {
        if (value !== null && (value = Number(value)) >= value) {
          ++count, sum += value;
        }
      }
    } else {
      let index = -1;

      for (let value of values) {
        if ((value = valueof(value, ++index, values)) !== null && (value = Number(value)) >= value) {
          ++count, sum += value;
        }
      }
    }

    if (count) return sum / count;
  }
});