define("@outdoorsyco/ember-switchback/helpers/html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertToHtmlSafe = convertToHtmlSafe;
  _exports.default = void 0;

  /**
   * TODO I thought this helper was a stock ember helper, but the outdoorsy-search
   * app throws an error so adding it for now.
   */
  function convertToHtmlSafe(params) {
    return Ember.String.htmlSafe(params.join(''));
  }

  var _default = Ember.Helper.helper(convertToHtmlSafe);

  _exports.default = _default;
});