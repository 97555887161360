define("@outdoorsyco/ember-shared-data/serializers/role-permission", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class RolePermission extends _application.default.extend(EmbeddedRecordsMixin) {
    serialize(record, options) {
      let data = super.serialize(record, options); // since we always embed role-permissions we need to make sure
      // the id's are numbers otherwise the backend errors

      if (data.id && typeof data.id === 'string') {
        data.id = Number(data.id);
      }

      return data;
    }

  }

  _exports.default = RolePermission;
});