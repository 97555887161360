define("@outdoorsyco/ember-shared-data/serializers/location", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class LocationSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        taxRates: {
          serialize: 'ids',
          deserialize: 'ids'
        },
        specialHours: {
          deserialize: 'records',
          serialize: false
        }
      });
    }

    // API -> what the model expects
    normalize(typeClass, data) {
      let tax_rate_ids = data.tax_rate_ids || [];
      data.tax_rates = tax_rate_ids;

      if (data && data.availability && data.availability.days) {
        let availableDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        availableDays.forEach(day => {
          if (data.availability.days[day] && data.availability.days[day].pickup) {
            data[`${day}_pickup_unavailable`] = data.availability.days[day].pickup.unavailable;
            data[`${day}_pickup_start`] = data.availability.days[day].pickup.start_time;
            data[`${day}_pickup_end`] = data.availability.days[day].pickup.end_time;
          }

          if (data.availability.days[day] && data.availability.days[day].dropoff) {
            data[`${day}_dropoff_unavailable`] = data.availability.days[day].dropoff.unavailable;
            data[`${day}_dropoff_start`] = data.availability.days[day].dropoff.start_time;
            data[`${day}_dropoff_end`] = data.availability.days[day].dropoff.end_time;
          }
        });
      }

      return super.normalize(typeClass, data);
    } // Model -> what the API expects


    serialize(record, options) {
      let data = super.serialize(record, options);
      let tax_rate_ids = data.tax_rates || [];
      data.tax_rate_ids = tax_rate_ids.map(tr => parseInt(tr));
      delete data.tax_rates; // delete data.tax_rate_id;

      let availableDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      data.availability = {
        days: {}
      };
      availableDays.forEach(day => {
        data.availability.days[day] = {
          pickup: {
            unavailable: data[`${day}_pickup_unavailable`],
            start_time: data[`${day}_pickup_start`],
            end_time: data[`${day}_pickup_end`]
          },
          dropoff: {
            unavailable: data[`${day}_dropoff_unavailable`],
            start_time: data[`${day}_dropoff_start`],
            end_time: data[`${day}_dropoff_end`]
          }
        };
        delete data[`${day}_pickup_unavailable`];
        delete data[`${day}_pickup_start`];
        delete data[`${day}_pickup_end`];
        delete data[`${day}_dropoff_unavailable`];
        delete data[`${day}_dropoff_start`];
        delete data[`${day}_dropoff_end`];
      });
      return data;
    }

  }

  _exports.default = LocationSerializer;
});