define("@outdoorsyco/ember-shared-data/services/verify-id", ["exports", "ember-concurrency-decorators", "ember-concurrency", "@outdoorsyco/ember-shared-data/utils/load-script"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ReserveService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class ReserveService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "apiRequest", _descriptor, this);

      _initializerDefineProperty(this, "environment", _descriptor2, this);

      _defineProperty(this, "personaClient", void 0);

      _defineProperty(this, "userBeingVerifiedId", void 0);

      _initializerDefineProperty(this, "isVerificationLoading", _descriptor3, this);

      _initializerDefineProperty(this, "idVerification", _descriptor4, this);

      _initializerDefineProperty(this, "setupVerification", _descriptor5, this);

      _initializerDefineProperty(this, "pollForUpdates", _descriptor6, this);
    }

    /**
     * Opens persona and returns the response of the verification.
     *
     * @param {number} id - ID of user to verify
     * @param {function} callback - Method to call after verification completes
     */
    async openPersona(callback) {
      if (!window.Persona) {
        await this.loadPersona();
      }

      let clientOptions = {
        environment: this.environment.config.persona.sandbox ? 'sandbox' : 'production',
        blueprintId: this.environment.config.persona.templateId,
        referenceId: this.userBeingVerifiedId,
        onComplete: async meta => {
          callback(meta);
        }
      };

      if (this.personaClient) {
        this.personaClient.clientOptions = clientOptions;
        this.personaClient.open();
      } else {
        this.personaClient = new window.Persona.Client(_objectSpread(_objectSpread({}, clientOptions), {}, {
          onLoad: () => {
            this.personaClient.open();
          }
        }));
      }
    }
    /**
     * Loads the JS for persona
     */


    async loadPersona() {
      return (0, _loadScript.default)({
        src: 'https://cdn.withpersona.com/assets/v2/persona.js',
        async: false
      });
    }

    async reloadVerification() {
      try {
        let idVerification = await this.apiRequest.request(`drivers/${this.userBeingVerifiedId}/id-verification`);
        this.idVerification = idVerification;
      } catch (error) {
        /**
         * We can get a 404 if we request a id-verification link from evident
         * and it has already been completed. The page component will deal
         * with the lack of an idVerification object so we just ignore it.
         */
        this.idVerification = null;
        return false;
      }
    }
    /**
     * Refreshes the verification model to see if the verification has passed.
     */


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isVerificationLoading", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "idVerification", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "setupVerification", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (id) {
        this.userBeingVerifiedId = id;
        let idVerification = yield this.apiRequest.request(`drivers/${id}/id-verification`).then(verification => verification).catch(() => {
          /**
           * We can get a 404 if we request a id-verification link from evident
           * and it has already been completed. The page component will deal
           * with the lack of an idVerification object so we just ignore it.
           */
          this.idVerification = null;
        });
        this.idVerification = idVerification;
        this.isVerificationLoading = false;
      };
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pollForUpdates", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        yield this.reloadVerification();

        if (!this.idVerification) {
          return;
        }
        /**
         * We start polling as soon as the window opens so initially we
         * wait 3 minutes to give them time to do the validation, before
         * we starting polling using an additive back off starting with 5
         * seconds.
         */


        let performCount = this.pollForUpdates.performCount;
        let timeoutMs = performCount === 1 ? 180000 : Math.min(5000 * performCount - 1);
        yield (0, _emberConcurrency.timeout)(timeoutMs);

        if (performCount < 30) {
          this.pollForUpdates.perform();
        }
      };
    }
  })), _class));
  _exports.default = ReserveService;
});