define("@outdoorsyco/ember-switchback/validators/min", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.min = min;
  _exports.default = void 0;

  function min(value, min) {
    const validatorIsDisabled = typeof min === 'undefined';
    const isValidRequirementValue = !isNaN(parseFloat(min));
    const noValueToValidate = Ember.isEmpty(value);
    const validValue = parseFloat(value) >= parseFloat(min);
    (false && !(validatorIsDisabled || isValidRequirementValue) && Ember.assert('min value is valid', validatorIsDisabled || isValidRequirementValue));
    return validatorIsDisabled || noValueToValidate || validValue;
  }

  var _default = {
    param: 'min',
    error: {
      key: 'error.validator.min',
      message: 'This must be %@ or higher.'
    },
    validate: min
  };
  _exports.default = _default;
});