define("@outdoorsyco/ember-shared-data/components/input/location/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray100": "hsl(220, 20%, 97%)",
    "gray200": "hsl(204, 14%, 89%)",
    "gray500": "hsl(208, 12%, 72%)",
    "gray900": "hsl(207, 6%, 23%)",
    "gray700": "hsl(210, 10%, 42%)",
    "raisedShadow300": "0 4px 6px -1px hsla(0, 0%, 0%, 0.1), 0 2px 4px -1px hsla(0, 0%, 0%, 0.06)",
    "clearable__clear": "_clearable__clear_10f9ar",
    "clearable": "_clearable_10f9ar"
  };
  _exports.default = _default;
});