define("@outdoorsyco/ember-switchback/components/select-time/component", ["exports", "@outdoorsyco/ember-switchback/components/form-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SECONDS_IN_A_DAY = 86400;
  /**
   * A time selector that returns the seconds since midnight for a
   * time selected by the user.
   *
   * ```handlebars
   *  <SelectTime
   *    @value={{this.value}}
   *    @onChange={{action (mut this.value)}} />
   * ```
   * @public
   */

  class SelectTime extends _component.default {
    constructor() {
      super(...arguments);
      (false && !(this.args.min === undefined || this.args.min >= 0) && Ember.assert('SelectTime @min must be greater than 0', this.args.min === undefined || this.args.min >= 0));
      (false && !(this.args.max === undefined || this.args.max < SECONDS_IN_A_DAY) && Ember.assert(`SelectTime @max can't exceed ${SECONDS_IN_A_DAY}`, this.args.max === undefined || this.args.max < SECONDS_IN_A_DAY));
      (false && !(this.args.increment === undefined || typeof this.args.increment === 'number') && Ember.assert(`SelectTime @increment must be a number`, this.args.increment === undefined || typeof this.args.increment === 'number'));
    }

    get incrementInSeconds() {
      return this.args.increment || 15 * 60;
    }

    get options() {
      let min = this.args.min || 0;
      let max = this.args.max || SECONDS_IN_A_DAY;
      let increment = this.incrementInSeconds;
      let options = [];

      for (let i = min; i <= max; i += increment) {
        options.push(i);
      }

      return options;
    }

  }

  _exports.default = SelectTime;
});