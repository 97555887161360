define("@outdoorsyco/ember-switchback/components/like/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * A component that allows the user to like something.
   *
   * ```handlebars
   * <Like
   *  @value={{true}}
   *  @onLike={{action "onLike"}}
   *  @count="20">
   * ```
   * @public
   */
  let Like = (_dec = Ember._action, (_class = class Like extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (false && !(typeof args.value === 'boolean') && Ember.assert('<Like/> requires @value to be a boolean', typeof args.value === 'boolean'));
    }

    onLike(value, event) {
      this.args.onLike(value, event);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onLike", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onLike"), _class.prototype)), _class));
  _exports.default = Like;
});