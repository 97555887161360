define("@outdoorsyco/outdoorsy-addon/components/od-avatar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zpgUQD+q",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ember-initials\",null,[[\"image\",\"name\",\"defaultName\",\"textColor\",\"fontSize\",\"fontWeight\",\"fontFamily\",\"size\",\"class\",\"backgroundColor\"],[[28,\"readonly\",[[24,[\"image\"]]],null],[28,\"readonly\",[[24,[\"displayName\"]]],null],\"\",\"white\",40,100,\"Helvetica Neue Light, Arial, sans-serif\",[24,[\"_avatar\",\"size\"]],[28,\"concat\",[\"avatar \",[24,[\"_avatar\",\"klass\"]]],null],\"#284941\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/od-avatar/template.hbs"
    }
  });

  _exports.default = _default;
});