define("calendar-picker/templates/components/od-range-days", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "By64CKCX",
    "block": "{\"symbols\":[\"week\",\"day\",\"wdn\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ember-power-calendar-row ember-power-calendar-weekdays\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"weekdaysNames\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ember-power-calendar-weekday\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ember-power-calendar-day-grid\"],[10,\"role\",\"button\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"weeks\"]]],[[\"key\"],[\"id\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ember-power-calendar-row ember-power-calendar-week\"],[11,\"data-missing-days\",[23,1,[\"missingDays\"]]],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"days\"]]],[[\"key\"],[\"id\"]],{\"statements\":[[0,\"        \"],[5,\"od-day-button\",[[3,\"on\",[\"click\",[28,\"fn\",[[24,[\"calendar\",\"actions\",\"select\"]],[23,2,[]],[24,[\"calendar\"]]],null]]]],[[\"@day\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "calendar-picker/templates/components/od-range-days.hbs"
    }
  });

  _exports.default = _default;
});