define("@outdoorsyco/ember-switchback/modifiers/tether-to", ["exports", "ember-modifier", "@popperjs/core/lib/popper-lite"], function (_exports, _emberModifier, _popperLite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let hasDOM = typeof document !== 'undefined';
  /**
   * Positions an element relative to another element
   *
   * @param {DOMElement} relativeToElement - The element to position relative to
   * @param {string} [align=left] - On which side of the element to align
   */

  var _default = (0, _emberModifier.modifier)((element, [target], {
    placement = 'bottom',
    strategy = 'absolute',
    modifiers = []
  }) => {
    if (!hasDOM) {
      return;
    }

    let targetElement = typeof target === 'string' ? document.querySelector(target) : target;
    let popper = (0, _popperLite.createPopper)(targetElement, element, {
      placement,
      strategy,
      modifiers
    });
    return () => popper.destroy();
  });

  _exports.default = _default;
});