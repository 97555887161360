define("@outdoorsyco/ember-shared-data/components/recursive-object-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N7uOaLfY",
    "block": "{\"symbols\":[\"value\",\"key\",\"item\"],\"statements\":[[4,\"each\",[[28,\"-each-in\",[[24,[\"items\"]]],null]],null,{\"statements\":[[4,\"if\",[[28,\"is-array\",[[23,1,[]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"strong\",true],[8],[1,[23,2,[]],false],[9],[0,\":\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"recursive-object-list\",null,[[\"items\"],[[23,3,[]]]]],false],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"is-object\",[[23,1,[]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"strong\",true],[8],[1,[23,2,[]],false],[9],[0,\":\\n      \"],[1,[28,\"recursive-object-list\",null,[[\"items\"],[[23,1,[]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"strong\",true],[8],[1,[23,2,[]],false],[9],[0,\":\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/recursive-object-list/template.hbs"
    }
  });

  _exports.default = _default;
});