define("@outdoorsyco/ember-shared-data/components/prompt/verify-phone/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sizer": "_sizer_i92d97",
    "form": "_form_i92d97",
    "verified-message": "_verified-message_i92d97"
  };
  _exports.default = _default;
});