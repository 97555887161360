define("@outdoorsyco/ember-shared-data/serializers/tax", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Tax extends _application.default {
    normalize(typeClass, hash) {
      hash.links = hash.links || {};
      hash.links.localizedContent = `/v0/localized-content?object_type=tax_rates&object_id=${hash.id}`;
      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = Tax;
});