define('ember-experiments/utils/is-testing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isTesting;
  function isTesting(context) {
    var config = void 0;

    if (context && Ember.get(context, 'resolveRegistration')) {
      config = context.resolveRegistration('config:environment');
    } else {
      config = Ember.getOwner(context).resolveRegistration('config:environment');
    }

    return config.environment === 'test';
  }
});