define("@outdoorsyco/ember-switchback/components/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o6+4FZHh",
    "block": "{\"symbols\":[\"@variant\",\"@size\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Loading\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/loading/styles\"]]]]]],[12,\"data-variant\",[28,\"or\",[[23,1,[]],\"default\"],null]],[12,\"data-size\",[28,\"or\",[[23,2,[]],\"medium\"],null]],[13,3],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"line one\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/loading/styles\"]]]]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"line two\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/loading/styles\"]]]]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"line three\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/loading/styles\"]]]]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"line four\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/loading/styles\"]]]]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"line five\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/loading/styles\"]]]]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"line six\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/loading/styles\"]]]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/loading/template.hbs"
    }
  });

  _exports.default = _default;
});