define("@outdoorsyco/ember-switchback/components/form-input/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "@outdoorsyco/ember-switchback/validators/email", "@outdoorsyco/ember-switchback/validators/max", "@outdoorsyco/ember-switchback/validators/maxlength", "@outdoorsyco/ember-switchback/validators/min", "@outdoorsyco/ember-switchback/validators/minlength", "@outdoorsyco/ember-switchback/validators/required"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, _email, _max, _maxlength, _min, _minlength, _required) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * The base form input all inputs extends from
   *
   * ```handlebars
   * TODO
   * ```
   * @public
   */
  let FormInput = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class FormInput extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "switchback", _descriptor, this);

      _initializerDefineProperty(this, "isTouched", _descriptor2, this);

      _initializerDefineProperty(this, "inputHasFocus", _descriptor3, this);

      _defineProperty(this, "errorId", `${Ember.guidFor(this)}--error`);

      _defineProperty(this, "inputId", `${Ember.guidFor(this)}--input`);

      _defineProperty(this, "labelId", `${Ember.guidFor(this)}--label`);

      _defineProperty(this, "selectorIsVisible", false);

      _initializerDefineProperty(this, "focusIn", _descriptor4, this);

      _initializerDefineProperty(this, "focusOut", _descriptor5, this);
    }

    get isTesting() {
      try {
        return Ember.getOwner(this).resolveRegistration('config:environment').environment === 'test';
      } catch (error) {
        console.error(error);
        return false;
      }
    }
    /**
     * If the input should be shown as errored.
     */


    get isErrored() {
      return this.isTouched && this.isInvalid;
    }
    /**
     * Don't show validation error messages when the input is being used
     * inside a <Fieldset />
     */


    get showValidationErrors() {
      return this.isErrored && !this.args.group;
    }
    /**
     * Used by the <Form> to identify inputs that should stop the form
     * from submitting.
     */


    get isInvalid() {
      return this.validationErrors.length > 0;
    }
    /**
     * All validators to be used to validate the value.
     */


    get validators() {
      return [_email.default, _max.default, _maxlength.default, _min.default, _minlength.default, _required.default, ...(this.args.customValidators || [])];
    }
    /**
     * Returns an array of error messages generated by the validators.
     */


    get validationErrors() {
      const intl = this.switchback.intl;
      let messages = Ember.A();

      if (this.args.disabled || !this.args.requirements) {
        return messages;
      }

      this.validators.forEach(validator => {
        try {
          let param = validator.param;
          let paramValue = param ? this.args.requirements[param] : undefined;

          if (paramValue === undefined) {
            return;
          }

          if (!validator.validate(this.args.value, paramValue)) {
            let message;
            let messageOverride = this.args.errorMessages && this.args.errorMessages[param];

            if (messageOverride) {
              message = Ember.String.loc(messageOverride, paramValue, this.args.value);
            } else if (intl && intl.exists(validator.error.key)) {
              message = intl.t(validator.error.key, {
                paramValue: paramValue,
                value: this.args.value
              });
            } else {
              message = Ember.String.loc(validator.error.message, paramValue, this.args.value);
            }

            messages.pushObject({
              message
            });
          }
        } catch (error) {
          (false && Ember.warn(`Exception with validator: ${validator} ${error}`, false, {
            id: 2
          }));
        }
      });
      return messages;
    }

    onDidInsert() {
      if (this.args.form) {
        this.args.form.registerInput(this);
      }

      if (this.args.group) {
        this.args.group.registerInput(this);
      }
    }

    willDestroy() {
      super.willDestroy(...arguments);

      if (this.args.form) {
        this.args.form.unregisterInput(this);
      }

      if (this.args.group) {
        this.args.group.unregisterInput(this);
      }
    }
    /**
     * Keeps track of whether the user has touched the input for
     * validation purposes. Bind `focusIn` and `focusOut` to
     * the focus and blur events of your input to ensure validation
     * code is triggered correctly.
     *
     * ```handlebars
     * <div
     *   tabindex="-1"
     *   onFocus={{action this.focusIn}}
     *   onBlur={{action this.focusOut}}>
     * </div>
     * ```
     */


    /**
     * Sets focus to the first focusable element found within the template.
     */
    focus() {
      const scrollSupported = ('scrollBehavior' in document.documentElement.style);
      const element = document.getElementById(this.args.id || this.inputId);

      if (element) {
        if (scrollSupported) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
          });
        }

        element.focus();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "switchback", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "inputHasFocus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "focusIn", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        /**
         * We want to make sure we don't trigger a focus out if we are also focusing in.
         * This allows for tabbing through compound inputs such as Radioset without
         * showing the error message until you leave the Radioset as a whole.
         * Don't timeout at all in tests, otherwise slows things down
         */
        this.focusOut.cancelAll();

        if (!this.isTesting) {
          yield (0, _emberConcurrency.timeout)(8);
        }

        this.inputHasFocus = true;

        if (!this.isErrored) {
          this.isTouched = false;
        }
      };
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "focusOut", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        /**
         * Having a timeout ensures that compound fields such as Radioset can be tabbed
         * through without triggering the error message to show up until the Radioset as
         * a whole looses focus.
         * Don't timeout at all in tests, otherwise slows things down
         */
        if (!this.isTesting) {
          yield (0, _emberConcurrency.timeout)(8);
        } // If it is a compound component with a selector popover we should pretend
        // focus is maintained while the selector is open


        if (this.selectorIsVisible) {
          return;
        }

        this.isTouched = true;
        this.inputHasFocus = false;
      };
    }
  })), _class));
  _exports.default = FormInput;
});