define("@outdoorsyco/ember-shared-data/serializers/search/json-api", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _JSONAPISerializer = _emberData.default.JSONAPISerializer;

  class JSONAPISerializer extends _JSONAPISerializer {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "dataType", 'json-api');
    }

    keyForAttribute(key) {
      return Ember.String.underscore(key);
    }

    keyForRelationship(key) {
      return Ember.String.underscore(key);
    }

    _normalizeResourceHelper(resourceHash) {
      /*
       * We've done a bad bad thing.
       *
       * Our search API returns models as json-api records, but they aren't the same key/values as our normal API
       * records.  We also rely pretty heavily on our serializers for api responses. This converts the json-api responses
       * to normal json ones and uses the original serializers
       *
       * One issue with this is that cases where the keys change between responses are just broken - but I couldn't find
       * cases of us handling that int he old overwritten models
       *
       * Learn more: https://github.com/emberjs/data/blob/v3.10.0/addon/serializers/json-api.js#L187
       *
       * We've done a bad bad thing.
       */
      let modelName = this.modelNameFromPayloadKey(resourceHash.type);
      let serializer = this.store.serializerFor(modelName);
      let modelClass = this.store.modelFor(modelName);

      if (serializer.dataType === 'json-api') {
        return super._normalizeResourceHelper(...arguments);
      }

      let attributes = resourceHash.attributes;
      attributes.id = resourceHash.id;
      let {
        data
      } = serializer.normalize(modelClass, attributes);
      return data;
    }

  }

  _exports.default = JSONAPISerializer;
});