define("@outdoorsyco/ember-switchback/components/checkbox/component", ["exports", "@outdoorsyco/ember-switchback/components/form-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * An checkbox
   *
   * ```handlebars
   * TODO
   * ```
   * @public
   */
  class Checkbox extends _component.default {
    /**
     * Converts the provided value to an appropriate string to assign to the
     * aria-checked attribute.
     */
    get value() {
      let type = typeof this.args.value;

      if (type === 'boolean') {
        return this.args.value.toString();
      }

      if (type === 'undefined' || type === 'null') {
        return 'false';
      }

      return 'mixed';
    }
    /**
     * Updates the value of the checkbox
     */


    toggleSelect() {
      if (this.args.disabled) {
        return;
      } // This ensures an indeterminate value goes to true on click


      let value = typeof this.args.value === 'boolean' ? !this.args.value : true;

      if (this.args.onChange) {
        this.args.onChange(value);
      }
    }
    /**
     * Handles key presses so space selects this checkbox.
     *
     * @param {event} event - The key press event
     */


    onKeyDown(event) {
      /* Space */
      if (event.keyCode === 32) {
        event.preventDefault();
        this.toggleSelect();
      }
    }

  }

  _exports.default = Checkbox;
});