define("ember-mobile-pane/templates/components/mobile-pane/nav/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ctcl2L2U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",[29,[\"#\",[24,[\"navItem\",\"elementId\"]]]]],[12,\"role\",\"tab\"],[12,\"class\",[29,[\"item__link \",[28,\"if\",[[24,[\"isActive\"]],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"clickItem\"]],[8],[0,\"\\n  \"],[1,[24,[\"navItem\",\"title\"]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mobile-pane/templates/components/mobile-pane/nav/item.hbs"
    }
  });

  _exports.default = _default;
});