define("@outdoorsyco/ember-shared-data/services/api-request", ["exports", "fetch", "ember-fetch/utils/mung-options-for-fetch", "@outdoorsyco/ember-shared-data/utils/errors", "ember-concurrency-decorators"], function (_exports, _fetch, _mungOptionsForFetch, _errors, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApiRequestService = (_dec = Ember.inject.service, (_class = class ApiRequestService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", 'v0');

      _defineProperty(this, "authHeaderName", 'Authorization');

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "_fetch", _descriptor2, this);

      _initializerDefineProperty(this, "_notOkError", _descriptor3, this);
    }

    request(path, {
      method = 'GET',
      data,
      isBearer = false,
      headers
    } = {}) {
      return this._fetch.perform(path, {
        method,
        data,
        isBearer,
        headers
      });
    }

    post(path, {
      data,
      isBearer = false,
      headers
    } = {}) {
      return this._fetch.perform(path, {
        method: 'POST',
        data,
        isBearer,
        headers
      });
    }

    patch(path, {
      data,
      isBearer = false,
      headers
    } = {}) {
      return this._fetch.perform(path, {
        method: 'PATCH',
        data,
        isBearer,
        headers
      });
    }

    delete(path, {
      isBearer = false,
      headers
    } = {}) {
      return this._fetch.perform(path, {
        method: 'DELETE',
        isBearer,
        headers
      });
    }

    _getFullUrl(url) {
      const isAlreadyFullUrl = url.indexOf('http') === 0;

      if (isAlreadyFullUrl) {
        return url;
      }

      const pathWithoutLeadingSlash = url.replace(/^\//, '');
      return `${this._host}/${this.namespace}/${pathWithoutLeadingSlash}`;
    }

    get logger() {
      return Ember.getOwner(this).lookup('logger:outdoorsy');
    }

    get _appConfig() {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }

    get _host() {
      return this._appConfig.APP.apiHost;
    }

    get _fastboot() {
      return Ember.getOwner(this).lookup('service:fastboot');
    }

    _getHeaders(isBearer) {
      let headers = {
        'content-type': 'application/json; charset=utf-8',
        'Bui-Accept': 'text/html'
      };

      if (Ember.get(this, '_fastboot.isFastBoot')) {
        headers['X-App-Session'] = 'fastboot';
        headers['X-Device-ID'] = 'fastboot';
      }

      const authToken = Ember.get(this, 'session.data.authenticated.token');

      if (Ember.isPresent(authToken)) {
        const prefix = isBearer ? 'Bearer ' : 'Token=';
        headers[this.authHeaderName] = `${prefix}${authToken.replace('guest:', '')}`;
      }

      if (Ember.get(window, 'Outdoorsy.metrics.getIdentifier')) {
        let {
          sid,
          did
        } = window.Outdoorsy.metrics.getIdentifier();
        headers['X-App-Session'] = sid;
        headers['X-Device-ID'] = did;
      }

      if (Ember.get(window, 'sessionStorage')) {
        let rid = window.sessionStorage.getItem('riskified-session-id') || window.riskifiedSessionID;

        if (rid) {
          headers['riskified-session-id'] = rid;
        }
      }

      const partnerId = Ember.get(this, '_appConfig.outdoorsyPartner.key');

      if (partnerId) {
        headers['Partner-ID'] = partnerId;
      }
      /**
       * Adds header from mobile bouncer. See <Bouncer::MobileDiscount /> from
       * outdoorsy-search.
       */


      try {
        if (window && window.localStorage) {
          let applyCredit = window.localStorage.getItem('mobile-apply-credit');

          if (applyCredit) {
            headers['X-Apply-Credit'] = applyCredit;
          }
        }
      } catch (_unused) {// ignore localstorage permissions error
      }

      return headers;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_fetch", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (initialUrl, initialOptions = {}) {
        if (initialUrl) {
          initialOptions.url = initialUrl;
        }

        const isBearer = initialOptions.isBearer;
        delete initialOptions.isBearer;
        const {
          url,
          method,
          body
        } = (0, _mungOptionsForFetch.default)(initialOptions);
        const options = {
          method,
          body,
          headers: initialOptions.headers || this._getHeaders(isBearer)
        };
        const response = yield (0, _fetch.default)(this._getFullUrl(url), options);
        const isHead = method === 'HEAD';
        const contentType = response.headers.get('content-type');
        const hasJSON = !isHead && contentType && contentType.includes('application/json');

        if (!response.ok) {
          throw yield this._notOkError.perform(response, {
            isHead
          });
        } else if (response.status === 204) {
          return;
        }

        if (hasJSON) {
          const responseJSON = yield response.json();

          if (responseJSON && typeof responseJSON === 'object') {
            responseJSON.fetchResponseMeta = {
              headers: response.headers
            };
          }

          return responseJSON;
        }

        return yield response.text();
      };
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_notOkError", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (response, {
        hasJSON
      }) {
        return yield (0, _errors.notOkError)(response, {
          logger: this.logger,
          hasJSON
        });
      };
    }
  })), _class));
  _exports.default = ApiRequestService;
});