define("@outdoorsyco/ember-shared-data/components/survey-show-multiple-choice/component", ["exports", "@outdoorsyco/ember-shared-data/components/survey-show-multiple-choice/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,

    init() {
      this._super(...arguments);

      if (this.get('question') && this.get('question').style !== 'single-choice') {
        this.set('question.value', Ember.makeArray(this.get('question.value')));
      }
    },

    choices: Ember.computed.mapBy('question.choices', 'value')
  });

  _exports.default = _default;
});