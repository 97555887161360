define("@outdoorsyco/ember-shared-data/adapters/cancellation-details", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CancellationDetailsAdapter extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", 'v0');
    }

    pathForType() {
      return 'bookings/:booking_id/cancellation-details';
    }

    buildURL(type, id, snapshot, requestType, query) {
      let url = super.buildURL(type, id, snapshot, requestType);

      if (query !== null && query !== void 0 && query.booking_id) {
        url = url.replace(':booking_id', query.booking_id);
        delete query.booking_id;
      }

      return url;
    }

  }

  _exports.default = CancellationDetailsAdapter;
});