define("@outdoorsyco/ember-switchback/components/loading/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Loading": "_Loading_dswbrx",
    "line": "_line_dswbrx",
    "two": "_two_dswbrx",
    "three": "_three_dswbrx",
    "four": "_four_dswbrx",
    "five": "_five_dswbrx",
    "six": "_six_dswbrx",
    "LoadingSmall": "_LoadingSmall_dswbrx",
    "LoadingMedium": "_LoadingMedium_dswbrx"
  };
  _exports.default = _default;
});