define("@outdoorsyco/ember-shared-data/models/search/user", ["exports", "@outdoorsyco/ember-shared-data/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SearchUser extends _user.default {}

  _exports.default = SearchUser;
});