define("@outdoorsyco/ember-shared-data/components/form/server-form/list/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormListComponent = (_dec = Ember.computed.alias('args.field'), _dec2 = Ember.computed.alias('args.field.type'), _dec3 = Ember.computed.alias('args.field.list'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class FormListComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "field", _descriptor, this);

      _initializerDefineProperty(this, "fieldType", _descriptor2, this);

      _initializerDefineProperty(this, "list", _descriptor3, this);

      let arr = [];

      let obj = this._createObject();

      if (obj) {
        arr.push(obj);
      } // create list on the field passed in


      if (this.field) {
        Ember.set(this, 'field.list', Ember.A(arr));
      }
    }

    _createObject() {
      let ref = this.field ? this.field.object_ref : {};

      if (typeof ref === 'string') {
        return JSON.parse(ref);
      }

      return null;
    }

    addItem() {
      Ember.set(this, 'list', [...this.list, this._createObject()]);
    }

    removeItem(boolProp, index) {
      if (Ember.isArray(this.list)) {
        this.list.splice(index, 1);
        Ember.set(this, 'list', [...this.list]);

        if (boolProp !== '' && this.list.length === 0) {
          Ember.set(this, boolProp, false);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "field", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fieldType", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "list", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeItem", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "removeItem"), _class.prototype)), _class));
  _exports.default = FormListComponent;
});