define("@outdoorsyco/ember-shared-data/serializers/booking-bundle", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BookingBundle extends _application.default {
    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.bundles) {
        payload = payload.bundles;
      }

      return super.normalizeQueryResponse(store, primaryModelClass, payload, id, requestType);
    }

  }

  _exports.default = BookingBundle;
});