define("@outdoorsyco/outdoorsy-addon/components/od-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pncANY9M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[22,\"componentClass\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"style\"]],\"primary\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"od-spinner__road-edges\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"od-spinner__road-stripes\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"od-spinner__road-stripes__stripe od-spinner__road-stripes__stripe--1\"],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"od-spinner__road-stripes__stripe od-spinner__road-stripes__stripe--2\"],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"od-spinner__road-stripes__stripe od-spinner__road-stripes__stripe--3\"],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"od-spinner__road-stripes__stripe od-spinner__road-stripes__stripe--4\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"od-spinner__dots\"],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/od-spinner/template.hbs"
    }
  });

  _exports.default = _default;
});