define("@outdoorsyco/ember-shared-data/components/provider/passengers/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @argument {booking} booking - The booking containing the passengers
   */
  let ProviderPassengersComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('args.booking.passengers'), (_class = class ProviderPassengersComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "prompt", _descriptor, this);

      _initializerDefineProperty(this, "passengers", _descriptor2, this);
    }

    /**
     * We want the order to be accepted invites, then unaccepted. If accepted the
     * order should be drivers, passengers, pending drivers. Following that we sort
     * by name.
     */
    get sortedPassengers() {
      let passengerArray = this.passengers.toArray();
      let renter = passengerArray.filter(passenger => {
        return passenger.inviteStatus === 'renter';
      });
      let pending = passengerArray.filter(passenger => {
        return passenger.inviteStatus === 'pending';
      });
      let drivers = passengerArray.filter(passenger => {
        return passenger.role === 'driver' && passenger.inviteStatus === 'accepted';
      });
      let passengers = passengerArray.filter(passenger => {
        return passenger.role === 'passenger' && passenger.inviteStatus === 'accepted';
      }); // dealers can add passengers in which case the inviteStatus === 'dealer_added'
      // let's just include any we might have missed for safety sake :)

      let remainingPassengers = passengerArray.filter(passenger => ![...renter, ...drivers, ...passengers, ...pending].includes(passenger));
      pending = pending.sortBy('firstName');
      drivers = drivers.sortBy('firstName');
      passengers = passengers.sortBy('firstName');
      remainingPassengers = remainingPassengers.sortBy('firstName');
      return [...renter, ...drivers, ...passengers, ...pending, ...remainingPassengers];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "prompt", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "passengers", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ProviderPassengersComponent;
});