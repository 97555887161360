define("@outdoorsyco/ember-shared-data/utils/format-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.secondsToHour = secondsToHour;
  _exports.secondsToMinuteOfHour = secondsToMinuteOfHour;
  _exports.secondsFromHour = secondsFromHour;
  _exports.secondsFromClockTime = secondsFromClockTime;
  _exports.hoursMinutesStringFromSeconds = hoursMinutesStringFromSeconds;

  function secondsToHour(timeInSeconds, useMeridiem) {
    let hour = Math.floor(timeInSeconds / 60 / 60) || 0;

    if (useMeridiem) {
      if (hour > 12) {
        hour = hour - 12;
      }
    }

    return hour;
  }

  function secondsToMinuteOfHour(timeInSeconds) {
    return Math.floor(timeInSeconds / 60 % 60) || 0;
  }

  function secondsFromHour(hours, amPm) {
    let seconds = hours * 60 * 60;

    if (amPm) {
      if (hours === 12) {
        seconds = amPm === 'pm' ? seconds : seconds - 43200;
      } else {
        seconds = amPm === 'pm' ? seconds + 43200 : seconds;
      }
    }

    return seconds;
  }

  function secondsFromClockTime(hour, minute, amPm) {
    return secondsFromHour(hour, amPm) + minute * 60;
  }

  function hoursMinutesStringFromSeconds(seconds, locale) {
    let m = (0, _moment.default)().startOf('day').locale(locale || 'en-us');
    let result = m.seconds(seconds).format('LT');
    return result;
  }
});