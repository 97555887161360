define("@outdoorsyco/outdoorsy-addon/components/rental/cancellation-policy/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/rental/cancellation-policy/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    cancellationPolicy: Ember.inject.service(),
    'data-test-cancellation-policy': true,
    policy: null,
    customText: null,
    cancelPolicy: null,
    policyName: Ember.computed.or('policy', 'cancelPolicy'),
    //internal
    hasCustomPolicy: Ember.computed.equal('policyName', 'custom'),
    policyText: Ember.computed('policyName', function () {
      let policyName = this.get('policyName');

      if (policyName) {
        return Ember.String.capitalize(policyName);
      }
    }),
    policyDescription: Ember.computed('policy', function () {
      return this.get('cancellationPolicy').policyDescription(this.get('policy'));
    })
  });

  _exports.default = _default;
});