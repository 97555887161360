define("ember-mapbox-gl/components/mapbox-gl-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A component used to call a specific Mapbox GL method.
   *
   * @class MapboxGLCallComponent
   */
  const MapboxGlCallComponent = Ember.Component.extend({
    tagName: '',
    obj: null,
    func: null,
    args: null,

    onResp() {},

    params: null,
    _scheduledCall: null,

    didReceiveAttrs() {
      this._super(...arguments);

      let {
        obj,
        func,
        args,
        params
      } = Ember.getProperties(this, 'obj', 'func', 'args', 'params');

      if (args === null && params !== null) {
        if (func !== null) {
          args = params;
        } else {
          [func, ...args] = params;
        }
      }

      (false && !(typeof obj === 'object' && obj !== null) && Ember.assert('mapbox-gl-call obj is required', typeof obj === 'object' && obj !== null));
      (false && !(typeof func === 'string') && Ember.assert('mapbox-gl-call func is required and must be a string', typeof func === 'string'));
      (false && !(typeof obj[func] === 'function') && Ember.assert(`mapbox-gl-call ${func} must be a function on ${obj}`, typeof obj[func] === 'function'));
      this._scheduledCall = Ember.run.scheduleOnce('afterRender', this, this._call, obj, func, args);
    },

    willDestroy() {
      this._super(...arguments);

      if (this._scheduledCall !== null) {
        Ember.run.cancel(this._scheduledCall);
      }
    },

    _call(obj, func, args) {
      this._scheduledCall = null;
      this.onResp(obj[func].apply(obj, args));
    }

  });
  MapboxGlCallComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = MapboxGlCallComponent;
  _exports.default = _default;
});