define("@outdoorsyco/ember-switchback/components/select-time/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "llWJD6Bn",
    "block": "{\"symbols\":[\"option\",\"&default\",\"@id\",\"@value\",\"@onChange\",\"@requirements\",\"@errorMessages\",\"@placeholder\",\"@disabled\",\"@size\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[5,\"select\",[[13,11]],[[\"@id\",\"@value\",\"@options\",\"@onChange\",\"@requirements\",\"@errorMessages\",\"@placeholder\",\"@disabled\",\"@size\"],[[23,3,[]],[23,4,[]],[23,0,[\"options\"]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]]]],{\"statements\":[[0,\"\\n\\n\"],[4,\"if\",[[28,\"-has-block\",[[23,12,[]],\"default\",[25,2]],null]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"select-time/label\",[],[[\"@seconds\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/select-time/template.hbs"
    }
  });

  _exports.default = _default;
});