define("@outdoorsyco/outdoorsy-addon/components/gdpr-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GjepfBVG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isShowingGDPR\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"gdpr-alert\"],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"close\"],null]],[10,\"data-test-gdpr-alert\",\"\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"gdpr-alert__cookie-container\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"class\",\"gdpr-cookie\"],[10,\"src\",\"/@outdoorsyco/outdoorsy-addon/img/cookie.svg\"],[11,\"alt\",[28,\"t\",[\"ui-close\"],null]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"gdpr-alert__description\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"comp-gdpr-alert-message\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"close-icon\"],[10,\"data-test-gdpr-alert-close\",\"\"],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"close\"],[[\"class\"],[\"size-medium icon-light\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/gdpr-alert/template.hbs"
    }
  });

  _exports.default = _default;
});