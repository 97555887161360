define("@outdoorsyco/ember-shared-data/helpers/cloudinary-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cloudinaryUrl = cloudinaryUrl;
  _exports.default = void 0;

  function cloudinaryUrl([url], hash) {
    if (!url) {
      return '';
    }

    let existingArgs = url.match(/upload\/([\w\d,_]+)/, '');

    if (existingArgs && existingArgs.length > 1) {
      let match = existingArgs[1];
      let params = match.split(',').reduce((imageHash, item) => {
        let pieces = item.split('_');

        if (pieces && pieces.length > 1) {
          imageHash[pieces[0]] = pieces[1];
        }

        return imageHash;
      }, {});
      hash = Ember.assign({}, params, hash);
    }

    let replaceStr = '';
    Object.keys(hash).forEach(k => {
      replaceStr += `${k}_${hash[k]},`;
    });
    replaceStr = replaceStr.replace(/,$/, '');
    return url.replace(/upload\//, `upload/${replaceStr}/`).replace(/,+$/g, '');
  }

  var _default = Ember.Helper.helper(cloudinaryUrl);

  _exports.default = _default;
});