define("@outdoorsyco/ember-shared-data/adapters/bundle", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Bundle extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", 'v0');
    }

    pathForType() {
      return `bundles`;
    }

    buildURL(type, id, snapshot, requestType, query) {
      let url = super.buildURL(type, id, snapshot, requestType, query);

      if (requestType === 'query' && query.owner_id) {
        url = url.replace('/bundles', `/users/${query.owner_id}/bundles`);
        delete query.owner_id;
      } else if (requestType === 'query' && query.rental_id) {
        url = url.replace('/bundles', `/rentals/${query.rental_id}/bundles`);
        delete query.rental_id;
      } else if (requestType === 'query' && query.custom_bundle_rental_id) {
        query.rental_id = query.custom_bundle_rental_id;
        delete query.custom_bundle_rental_id;
      }

      return url;
    }

  }

  _exports.default = Bundle;
});