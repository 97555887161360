define("@outdoorsyco/outdoorsy-addon/transitions/auto-filter", ["exports", "ember-animated/motions/move", "ember-animated/motions/opacity", "@outdoorsyco/ember-switchback/motions/scale", "ember-animated", "ember-animated/easings/cosine"], function (_exports, _move, _opacity, _scale, _emberAnimated, _cosine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Transition used by Filter when showing and hiding the popup.
   */
  // eslint-disable-next-line require-yield
  function* _default({
    removedSprites,
    insertedSprites,
    keptSprites,
    duration
  }) {
    removedSprites.map(sprite => {
      sprite.endAtPixel({
        y: sprite.absoluteInitialBounds.top - 16
      });

      if (sprite.revealed) {
        (0, _emberAnimated.parallel)((0, _opacity.default)(sprite, {
          to: 0,
          duration
        }), (0, _move.default)(sprite, {
          easing: _cosine.easeOut
        }), (0, _scale.default)(sprite, {
          from: 1,
          to: 0.9,
          duration
        }));
      }
    });
    insertedSprites.concat(keptSprites).map(sprite => {
      sprite.startAtPixel({
        y: sprite.absoluteFinalBounds.top - 16
      });
      (0, _emberAnimated.parallel)((0, _opacity.default)(sprite, {
        to: 1,
        duration
      }), (0, _scale.default)(sprite, {
        from: 0.9,
        to: 1,
        duration
      }));
    });
  }
});