define("ember-animated/templates/components/animated-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XID9XMSU",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[4,\"animated-each\",[[23,0,[\"items\"]]],[[\"key\",\"rules\",\"use\",\"duration\",\"group\",\"watch\",\"initialInsertion\",\"finalRemoval\"],[[23,0,[\"key\"]],[23,0,[\"rules\"]],[23,0,[\"use\"]],[23,0,[\"duration\"]],[23,0,[\"group\"]],[23,0,[\"watch\"]],[23,0,[\"initialInsertion\"]],[23,0,[\"finalRemoval\"]]]],{\"statements\":[[0,\"  \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-value.hbs"
    }
  });

  _exports.default = _default;
});