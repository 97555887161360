define("@outdoorsyco/ember-shared-data/utils/sentry", ["exports", "@sentry/browser", "@sentry/tracing", "@sentry/integrations/esm/ember", "@outdoorsyco/ember-shared-data/utils/can-use-dom"], function (_exports, Sentry, _tracing, _ember, _canUseDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startSentry = startSentry;

  function startSentry(config) {
    const {
      dsn,
      environment,
      enabled
    } = config.sentry;
    const isDebug = environment !== 'production';
    const tracingOrigins = isDebug ? ['api-staging.outdoorsy.co', 'api.staging.outdoorsy.com', 'search.staging.outdoorsy.com'] : ['api.outdoorsy.co', 'api.outdoorsy.com', 'search.outdoorsy.co', 'search.outdoorsy.com'];
    const integrations = [new _ember.Ember(), new _tracing.Integrations.BrowserTracing({
      tracingOrigins
    })];

    if (isDebug) {
      // https://github.com/getsentry/sentry-javascript/issues/2100
      integrations.push(new Sentry.Integrations.Breadcrumbs({
        console: false
      }));
    }

    let revisionElementContent;

    if (_canUseDom.default) {
      const revisionElement = document.querySelector(`meta[name='sentry:revision']`);
      revisionElementContent = revisionElement && revisionElement.content;
    }

    if (enabled) {
      Sentry.init({
        dsn,
        environment,
        integrations,
        tracesSampleRate: isDebug ? 1 : 0,
        release: revisionElementContent || config.APP.version,

        beforeSend(event, hint) {
          let error = hint.originalException; // ignore aborted route transitions from the Ember.js router

          if (error && error.name === 'TransitionAborted') {
            return null;
          } // ignore aborted ember adapter operations


          if (error && error.message === 'The adapter operation was aborted') {
            return null;
          } // filter out all 400 level responses, it's just noise and an appropriate response


          let status = Ember.get(error, 'errors.firstObject.status') || 0;

          if (status >= 400 && status < 500) {
            return null;
          }

          if (isDebug) {
            console.error(error || hint.syntheticException, event);
            return null; // this drops the event and nothing will be sent to sentry
          } // we still want to see errors in the console in production


          console.error(error || hint.syntheticException);
          return event;
        },

        beforeBreadcrumb(breadcrumb, hint) {
          // attach extra response context to breadcrumb data for non-20x responses
          const statusCode = Ember.get(breadcrumb, 'data.status_code');

          if (breadcrumb.category === 'xhr' && statusCode >= 300) {
            breadcrumb.data.response = Ember.get(hint, 'xhr.response');
          }

          if (breadcrumb.category === 'fetch') {
            const notOk = !statusCode || statusCode >= 300;

            if (notOk) {
              breadcrumb.data.input = hint.input;
              breadcrumb.data.response = hint.response;
              breadcrumb.data.responseType = Ember.get(hint, 'response.type');
            }
          }

          return breadcrumb;
        }

      });
    }
  }
});