define("@outdoorsyco/ember-shared-data/components/help-box/component", ["exports", "@outdoorsyco/ember-shared-data/components/help-box/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let HelpBox = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class HelpBox extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "layout", _template.default);

      _defineProperty(this, "icon", null);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "iconSize", null);

      _defineProperty(this, "renderInPlace", false);

      _defineProperty(this, "verticalPosition", 'above');

      _defineProperty(this, "horizontalPosition", 'center');
    }

    // Actions
    prevent(e) {
      return e.stopImmediatePropagation();
    }

    open(dropdown) {
      if (this.closeTimer) {
        Ember.run.cancel(this.closeTimer);
        this.closeTimer = null;
      } else {
        dropdown.actions.open();
      }
    }

    closeLater(dropdown) {
      this.closeTimer = Ember.run.later(() => {
        this.closeTimer = null;
        dropdown.actions.close();
      }, 200);
    }

    onClick(e) {
      e.stopPropagation();
      return false;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "open", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeLater", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "closeLater"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = HelpBox;
});