define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/desktop-menu/mega-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b5tHTdOA",
    "block": "{\"symbols\":[\"@link\",\"@label\",\"&attrs\",\"@direction\",\"&default\"],\"statements\":[[4,\"if\",[[23,2,[\"country\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"btn btn-link\"],[10,\"rel\",\"noopener\"],[11,\"title\",[23,2,[\"name\"]]],[8],[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[29,[\"flag flag-\",[23,2,[\"country\"]]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\",false],[12,\"href\",[23,1,[]]],[12,\"class\",\"btn btn-link\"],[12,\"rel\",\"noopener\"],[12,\"aria-label\",[28,\"t\",[\"nav-menu-extra-menu\"],null]],[12,\"data-href-to-ignore\",\"\"],[3,\"action\",[[23,0,[]],\"preventDefault\"]],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[7,\"div\",false],[12,\"data-test-mega-menu\",\"\"],[13,3],[12,\"class\",[29,[\"explore-dropdown-menu \",[28,\"if\",[[23,0,[\"megaMenu\"]],\"mega-menu\",\"small-menu\"],null],\" \",[28,\"if\",[[23,4,[]],[28,\"concat\",[\"open-\",[23,4,[]]],null]],null],\" \",[28,\"if\",[[23,0,[\"open\"]],\"open\"],null]]]],[8],[0,\"\\n  \"],[14,5],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/desktop-menu/mega-menu/template.hbs"
    }
  });

  _exports.default = _default;
});