define("@outdoorsyco/ember-shared-data/components/modal/text-link-to-apps/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal": "_modal_5vqpgr",
    "icon": "_icon_5vqpgr",
    "content": "_content_5vqpgr"
  };
  _exports.default = _default;
});