define("@outdoorsyco/ember-shared-data/helpers/starts-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function startsWith([needle, haystack]) {
    return haystack.startsWith(needle);
  });

  _exports.default = _default;
});