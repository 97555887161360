define("@outdoorsyco/ember-shared-data/utils/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPageCategory = getPageCategory;

  function getPageCategory(routeName) {
    if (!routeName) {
      return null;
    }

    if (routeName === 'dashboard.index') {
      return 'dashboard_home';
    }

    if (routeName.includes('dashboard.bookings')) {
      return 'bookings';
    }

    if (routeName.includes('dashboard.rentals')) {
      return 'rentals';
    }

    if (routeName.includes('dashboard.calendar')) {
      return 'calendar';
    }

    if (routeName.includes('dashboard.instamatch')) {
      return 'instamatch';
    }

    if (routeName.includes('dashboard.stats')) {
      return 'performance';
    }

    if (routeName.includes('dashboard.documents')) {
      return 'documents';
    }

    if (routeName.includes('dashboard.messages')) {
      return 'inbox';
    }

    if (routeName.includes('dashboard.profile')) {
      return 'profile';
    }

    if (routeName.includes('dashboard.account')) {
      return 'settings';
    }

    if (routeName.includes('dashboard.insurance-claim')) {
      return 'claims';
    }

    if (routeName.includes('dashboard.review')) {
      return 'review';
    }

    if (routeName.includes('owner.list')) {
      return 'lyrv';
    }

    return 'other';
  }
});