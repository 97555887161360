define("@outdoorsyco/ember-shared-data/models/passenger", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  let Passenger = (_dec = belongsTo('booking'), _dec2 = attr('string'), _dec3 = attr('string'), _dec4 = attr('string'), _dec5 = attr('string'), _dec6 = attr('string'), _dec7 = attr('string'), _dec8 = attr('string'), _dec9 = attr('boolean'), _dec10 = attr('string'), _dec11 = attr('boolean'), _dec12 = attr('boolean'), _dec13 = attr('boolean'), _dec14 = attr('boolean'), _dec15 = attr('boolean'), _dec16 = attr('boolean'), _dec17 = attr('number'), _dec18 = attr(), (_class = class Passenger extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "booking", _descriptor, this);

      _initializerDefineProperty(this, "avatarUrl", _descriptor2, this);

      _initializerDefineProperty(this, "email", _descriptor3, this);

      _initializerDefineProperty(this, "firstName", _descriptor4, this);

      _initializerDefineProperty(this, "lastName", _descriptor5, this);

      _initializerDefineProperty(this, "role", _descriptor6, this);

      _initializerDefineProperty(this, "inviteStatus", _descriptor7, this);

      _initializerDefineProperty(this, "birthdate", _descriptor8, this);

      _initializerDefineProperty(this, "verified", _descriptor9, this);

      _initializerDefineProperty(this, "validationStatus", _descriptor10, this);

      _initializerDefineProperty(this, "tintCheckVerified", _descriptor11, this);

      _initializerDefineProperty(this, "requirePersona", _descriptor12, this);

      _initializerDefineProperty(this, "personaCheckVerified", _descriptor13, this);

      _initializerDefineProperty(this, "driverCheckVerified", _descriptor14, this);

      _initializerDefineProperty(this, "hasDrivingRecordCheck", _descriptor15, this);

      _initializerDefineProperty(this, "ageVerified", _descriptor16, this);

      _initializerDefineProperty(this, "verifiedChecksCount", _descriptor17, this);

      _initializerDefineProperty(this, "checks", _descriptor18, this);
    }

    get name() {
      return `${this.firstName} ${this.lastName || ''}`;
    }

    get hasChecks() {
      return Boolean((this.checks || []).length);
    }

    get hasRiskCheck() {
      return this.hasTintCheck() || this.hasRoamlyRiskCheck();
    }

    get hasTintCheck() {
      let tintCheck = (this.checks || []).filterBy('method', 'tint');
      return Boolean(tintCheck.length);
    }

    get hasRoamlyRiskCheck() {
      let riskCheck = (this.checks || []).filterBy('method', 'roamly_risk');
      return Boolean(riskCheck.length);
    }

    get isUnverifiedDriver() {
      return this.role === 'driver' && !this.verified;
    }

    get statusKey() {
      if (this.inviteStatus === 'pending') {
        return 'invite-sent';
      }

      if (this.role === 'driver') {
        if (this.verified) {
          return 'driver';
        }

        if (!this.hasChecks) {
          return 'driver-skipped';
        }

        switch (this.validationStatus) {
          case 'pending':
            return 'driver-pending';

          case 'failed':
            return 'driver-failed';

          default:
            return 'driver-not-verified';
        }
      }

      return 'passenger';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "booking", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "avatarUrl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "firstName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "role", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "inviteStatus", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "birthdate", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "verified", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "validationStatus", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "tintCheckVerified", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "requirePersona", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "personaCheckVerified", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "driverCheckVerified", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "hasDrivingRecordCheck", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "ageVerified", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "verifiedChecksCount", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "checks", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Passenger;
});