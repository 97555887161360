define("@outdoorsyco/ember-nydus/services/nydus-request", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    namespace: null,
    host: Ember.computed(function () {
      return this.appConfig().APP.nydusHost;
    }),

    appConfig() {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    },

    headers: Ember.computed({
      get() {
        let headers = {
          'content-type': 'application/json; charset=utf-8'
        };
        let accessToken = '';

        if (this.get('userAccessToken')) {
          accessToken = this.get('userAccessToken');
        } else {
          accessToken = this.get('session.data.authenticated.token');
        }

        if (Ember.isPresent(accessToken)) {
          headers['Authorization'] = `Token=${accessToken}`;
        }

        return headers;
      }

    }).volatile()
  });

  _exports.default = _default;
});