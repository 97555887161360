define("@outdoorsyco/ember-switchback/components/layout/modal/inform/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @documenter esdoc */

  /**
   * Provides a layout to create a prompt that asks a polar question. This
   * is meant to be used via the prompt service.
   *
   * @public
   */
  class LayoutModalInform extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (false && !(args.modal) && Ember.assert('You must pass in the modal object to <Layout::Modal::Inform>', args.modal));
    }

  }

  _exports.default = LayoutModalInform;
});