define("@outdoorsyco/ember-shared-data/transforms/moment-date", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize(serialized) {
      if (serialized) {
        let date = (0, _moment.default)(serialized, 'YYYY-MM-DDTHH:mm:ssZ');

        if (date && date.isValid()) {
          return date;
        }

        return null;
      }

      return null;
    },

    serialize(deserialized) {
      let date = (0, _moment.default)(deserialized);

      if (date && date.isValid()) {
        return date.format('YYYY-MM-DDTHH:mm:ssZ');
      }

      return null;
    }

  });

  _exports.default = _default;
});