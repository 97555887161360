define("@outdoorsyco/ember-shared-data/components/action-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn'],
    classNameBindings: ['class'],
    class: 'btn-primary',
    attributeBindings: ['disabled'],
    loading: false,
    disabled: false,
    eventLabel: null
  });

  _exports.default = _default;
});