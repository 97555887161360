define("ember-mapbox-gl/-private/mapbox-loader", ["exports", "mapbox-gl"], function (_exports, _mapboxGl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.create({
    isLoaded: false,
    Mapbox: null,

    load(config = {}) {
      if (!this.isLoaded) {
        if ('accessToken' in config) {
          _mapboxGl.default.accessToken = config.accessToken;
        }

        Ember.setProperties(this, {
          Mapbox: _mapboxGl.default,
          isLoaded: true
        });
      }

      return Ember.RSVP.resolve();
    }

  });

  _exports.default = _default;
});