define("@outdoorsyco/ember-switchback/transitions/scale-and-fade", ["exports", "@outdoorsyco/ember-switchback/motions/scale", "ember-animated/motions/opacity", "ember-animated"], function (_exports, _scale, _opacity, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // Copied from https://github.com/ember-animation/ember-animated/issues/38

  /**
   * Transition used by modal when showing and hiding.
   */
  // eslint-disable-next-line require-yield
  function* _default({
    removedSprites,
    insertedSprites,
    keptSprites,
    duration
  }) {
    removedSprites.forEach(sprite => {
      if (sprite.revealed) {
        (0, _emberAnimated.parallel)((0, _opacity.default)(sprite, {
          to: 0,
          duration
        }), (0, _scale.default)(sprite, {
          from: 1,
          to: 0.9,
          duration
        }));
      }
    });
    insertedSprites.concat(keptSprites).forEach(sprite => {
      (0, _emberAnimated.parallel)((0, _opacity.default)(sprite, {
        to: 1,
        duration
      }), (0, _scale.default)(sprite, {
        from: 0.9,
        to: 1,
        duration
      }));
    });
  }
});