define('@outdoorsyco/ember-initials/utils/generators/svg', ['exports', '@outdoorsyco/ember-initials/utils/generators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var _class = function (_Base) {
    _inherits(_class, _Base);

    function _class() {
      _classCallCheck(this, _class);

      return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
    }

    _createClass(_class, [{
      key: 'revoke',
      value: function revoke(url) {
        URL.revokeObjectURL(url);
      }
    }, {
      key: 'generate',
      value: function generate(properties) {
        var textElement = this._generateTextElement(properties.initials, properties.initialsColor, properties.textStyles);
        var svgElement = this._generateSvgElement(properties.width, properties.height, properties.backgroundStyles);

        svgElement.append(textElement);
        var finalElement = Ember.$('<div>').append(svgElement);
        var blob = new Blob([finalElement.html()], { type: "image/svg+xml" });
        return URL.createObjectURL(blob);
      }
    }, {
      key: '_generateTextElement',
      value: function _generateTextElement(text, color) {
        var styles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        return this._generateElement('<text></text>', styles, {
          y: '50%',
          x: '50%',
          dy: '0.35em',
          'text-anchor': 'middle',
          'pointer-events': 'auto',
          fill: color
        }).html(text);
      }
    }, {
      key: '_generateElement',
      value: function _generateElement(name) {
        var styles = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var attrs = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        return Ember.$(name).attr(attrs).css(styles);
      }
    }, {
      key: '_generateSvgElement',
      value: function _generateSvgElement(width, height) {
        var styles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        return this._generateElement('<svg></svg>', styles, {
          width: width,
          height: height,
          xmlns: 'http://www.w3.org/2000/svg',
          'pointer-events': 'none',
          'viewBox': '0 0 100 100'
        });
      }
    }]);

    return _class;
  }(_base.default);

  exports.default = _class;
});