define("@outdoorsyco/ember-shared-data/utils/unwrap-proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = unwrapProxy;
  _exports.isProxy = isProxy;

  function unwrapProxy(o) {
    return isProxy(o) ? unwrapProxy(Ember.get(o, 'content')) : o;
  }

  function isProxy(o) {
    return Boolean(o && (o instanceof Ember.ObjectProxy || o instanceof Ember.ArrayProxy));
  }
});