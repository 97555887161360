define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/collapsible-footer/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/outdoorsy/collapsible-footer/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    isActive: false,
    actions: {
      toggle() {
        this.toggleProperty('isActive');
      }

    }
  });

  _exports.default = _default;
});