define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/desktop-menu/mega-menu/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/desktop-menu/mega-menu/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'li',
    classNames: ['menu-item'],
    classNameBindings: ['megaMenu:mega-menu-container'],
    megaMenu: true,

    mouseEnter() {
      this.get('_hoverIntent').perform();
    },

    mouseLeave(el) {
      this.hoverIntent = false;

      if (this.open) {
        return;
      }

      let relatedTarget = el.relatedTarget; // manage using google places auto complete modal

      if (!relatedTarget) {
        this.$('.explore-dropdown-menu').removeClass('open');
        return;
      } //  and here manage using google places auto complete modal


      let relatedTargetClassList = relatedTarget.classList;

      if (relatedTargetClassList && (relatedTargetClassList.contains('pac-item') || relatedTargetClassList.contains('pac-container'))) {
        return;
      }

      this.$('.explore-dropdown-menu').removeClass('open');
    },

    actions: {
      preventDefault() {
        return;
      }

    },
    _hoverIntent: (0, _emberConcurrency.task)(function* () {
      this.hoverIntent = true;
      yield (0, _emberConcurrency.timeout)(150);

      if (this.hoverIntent) {
        this.$('.explore-dropdown-menu').addClass('open');
      }
    }).drop()
  });

  _exports.default = _default;
});