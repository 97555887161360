define("@outdoorsyco/ember-switchback/components/input-error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I6QztVyT",
    "block": "{\"symbols\":[\"@align\",\"&attrs\",\"&default\",\"@showWhen\"],\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"InputError\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/input-error/styles\"]]]]]],[12,\"aria-live\",\"assertive\"],[12,\"data-align\",[28,\"or\",[[23,1,[]],\"right\"],null]],[12,\"data-test-input-error\",\"\"],[13,2],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"icon\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/input-error/styles\"]]]]]],[8],[0,\"\\n      \"],[7,\"svg\",true],[10,\"class\",\"has-fill\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"viewBox\",\"0 0 128 128\"],[8],[0,\"\\n        \"],[7,\"circle\",true],[10,\"cx\",\"64\"],[10,\"cy\",\"64\"],[10,\"r\",\"64\"],[10,\"fill\",\"#fe9c25\"],[8],[9],[0,\"\\n        \"],[7,\"path\",true],[10,\"fill\",\"#fff\"],[10,\"d\",\"M56 28h16v40H56zM56 84h16v16H56z\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"text\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/input-error/styles\"]]]]]],[8],[0,\"\\n      \"],[14,3],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/input-error/template.hbs"
    }
  });

  _exports.default = _default;
});