define("@outdoorsyco/outdoorsy-addon/components/user-response-rate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cMIwlqdS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"user\",\"responseTimeWithin\"]],[24,[\"user\",\"responsePercentage\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"responsePercentage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"user-response-rate\"],null],false],[0,\": \"],[7,\"strong\",true],[8],[1,[22,\"responsePercentage\"],false],[0,\"%\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"user\",\"averageResponseTime\"]]],null,{\"statements\":[[4,\"if\",[[28,\"gt\",[[24,[\"user\",\"averageResponseTime\"]],300],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"user-response-time\"],null],false],[0,\": \"],[7,\"strong\",true],[8],[1,[28,\"titleize\",[[28,\"moment-duration\",[[24,[\"user\",\"averageResponseTime\"]],\"seconds\"],null]],null],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/user-response-rate/template.hbs"
    }
  });

  _exports.default = _default;
});