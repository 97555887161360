define("@outdoorsyco/outdoorsy-addon/utils/rental-to-slug", ["exports", "@outdoorsyco/outdoorsy-addon/utils/clean-params", "@outdoorsyco/outdoorsy-addon/utils/location-segment-path"], function (_exports, _cleanParams, _locationSegmentPath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = rentalToSlug;

  function rentalToSlug(rental) {
    if (!rental) {
      return;
    }

    let {
      state,
      city,
      country
    } = Ember.get(rental, 'location') || {};
    let location = (0, _locationSegmentPath.default)({
      state,
      city,
      country
    });

    if (Ember.isBlank(location)) {
      location = 'earth';
    }

    let pieces = [Ember.get(rental, 'vehicleYear'), (0, _cleanParams.default)(Ember.get(rental, 'vehicleMake')), (0, _cleanParams.default)(Ember.get(rental, 'vehicleModel')), `${Ember.get(rental, 'id')}-listing`];
    let rvSlug = pieces.filter(item => !Ember.isBlank(item)).join('_');
    return {
      location,
      rvSlug
    };
  }
});