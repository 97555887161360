define("@outdoorsyco/ember-shared-data/utils/normalize-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeDate;

  function normalizeDate(date) {
    if (!date) {
      return;
    }

    date = (0, _moment.default)(date, ['YYYY-MM-DD', 'MM/DD/YYYY', 'MM-DD-YYYY']);

    if (!date.isValid()) {
      return;
    }

    return date.format('YYYY-MM-DD');
  }
});