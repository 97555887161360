define("@outdoorsyco/ember-shared-data/components/input/region/component", ["exports", "@glimmer/component", "@outdoorsyco/ember-shared-data/utils/address-fields", "ember-concurrency-decorators"], function (_exports, _component, _addressFields, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InputRegionComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember.computed.sort('administrativeAreaOptions', 'administrativeAreaOptionsDefinition'), _dec5 = Ember._action, (_class = class InputRegionComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "apiRequest", _descriptor, this);

      _initializerDefineProperty(this, "addressFormat", _descriptor2, this);

      _initializerDefineProperty(this, "administrativeAreaOptions", _descriptor3, this);

      _initializerDefineProperty(this, "sortedAdministrativeAreaOptions", _descriptor4, this);

      _defineProperty(this, "administrativeAreaOptionsDefinition", ['code:asc', 'name:asc']);

      _defineProperty(this, "previousCountryCode", null);

      _initializerDefineProperty(this, "countryCodeDidChange", _descriptor5, this);

      (false && !(typeof this.args.countryCode === 'string') && Ember.assert('You must provide a country code to <Input::Region>', typeof this.args.countryCode === 'string'));
    }

    onChange(option) {
      this.args.onChange(option.code);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "addressFormat", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "administrativeAreaOptions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sortedAdministrativeAreaOptions", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "countryCodeDidChange", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        let countryCode = this.args.countryCode;

        if (countryCode === this.previousCountryCode) {
          return;
        }

        this.previousCountryCode = countryCode;
        this.addressFormat = (0, _addressFields.getAddressFormatForCountry)(countryCode || 'US');

        if (this.args.hideTerritories && countryCode === 'US') {
          this.administrativeAreaOptions = US_STATES.map(([code, name]) => ({
            codeAndName: `${code} ${name}`,
            code,
            name
          }));
        } else {
          let options = yield this.apiRequest.request(`static/regions?country_code=${countryCode}`);
          options.map(o => {
            o.codeAndName = `${o.code} ${o.name}`;
            return o;
          });
          this.administrativeAreaOptions = options;
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype)), _class));
  _exports.default = InputRegionComponent;
  // this is a hack for gigwage (vendor that accepts our w-9 submissions).
  // they only allow US states as part of the address, so unable to use the static regions endpoint
  // from the API
  const US_STATES = [['AL', 'Alabama'], ['AK', 'Alaska'], ['AZ', 'Arizona'], ['AR', 'Arkansas'], ['CA', 'California'], ['CO', 'Colorado'], ['CT', 'Connecticut'], ['DE', 'Delaware'], ['DC', 'District of Columbia'], ['FL', 'Florida'], ['GU', 'Guam'], ['GA', 'Georgia'], ['HI', 'Hawaii'], ['ID', 'Idaho'], ['IL', 'Illinois'], ['IN', 'Indiana'], ['IA', 'Iowa'], ['KS', 'Kansas'], ['KY', 'Kentucky'], ['LA', 'Louisiana'], ['ME', 'Maine'], ['MD', 'Maryland'], ['MA', 'Massachusetts'], ['MI', 'Michigan'], ['MN', 'Minnesota'], ['MS', 'Mississippi'], ['MO', 'Missouri'], ['MT', 'Montana'], ['NE', 'Nebraska'], ['NV', 'Nevada'], ['NH', 'New Hampshire'], ['NJ', 'New Jersey'], ['NM', 'New Mexico'], ['NY', 'New York'], ['NC', 'North Carolina'], ['ND', 'North Dakota'], ['MP', 'Northern Mariana Islands'], ['OH', 'Ohio'], ['OK', 'Oklahoma'], ['OR', 'Oregon'], ['PA', 'Pennsylvania'], ['PR', 'Puerto Rico'], ['RI', 'Rhode Island'], ['SC', 'South Carolina'], ['SD', 'South Dakota'], ['TN', 'Tennessee'], ['TX', 'Texas'], ['UT', 'Utah'], ['VT', 'Vermont'], ['VI', 'Virgin Islands'], ['VA', 'Virginia'], ['WA', 'Washington'], ['WV', 'West Virginia'], ['WI', 'Wisconsin'], ['WY', 'Wyoming']];
});