define('@outdoorsyco/ember-initials/utils/hash', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = hash;
  function hash(properties) {
    var hashIt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    if (Ember.isBlank(properties)) {
      return 'undefined';
    }
    if (Ember.typeOf(properties) !== 'object') {
      return properties.toString();
    }

    var pKeys = Object.keys(properties).sort();
    var result = [];
    pKeys.forEach(function (key) {
      var value = properties[key];
      if (Ember.typeOf(value) !== 'string') {
        value = hash(value, false);
      }
      result = result.concat([key, value]);
    });

    if (hashIt) {
      return result.toString();
    }

    return result.toString();
  }
});