define("@outdoorsyco/ember-shared-data/services/booking", ["exports", "dayjs"], function (_exports, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apiRequest: Ember.inject.service(),

    batchUpdateBookingItems(booking) {
      if (!booking) {
        return;
      }

      let items = this._serializeItems(booking);

      let bookingId = booking.get('id');
      let url = `/bookings/${bookingId}/items/batch-items`;
      let data = JSON.stringify({
        items
      });
      return this.apiRequest.post(url, {
        data: data
      });
    },

    tripInsuranceTotal(booking) {
      let tripService = booking.get('premiumServices').findBy('category', 'trip-insurance');

      if (Ember.isPresent(tripService)) {
        return booking.get('total') - tripService.get('total');
      }

      return booking.get('total');
    },

    tripInsuranceEstimatedPrice({
      bookingTotal,
      tripInsuranceIntegration
    }) {
      (false && !(tripInsuranceIntegration.get('category') === 'trip-insurance') && Ember.assert('Must pass trip insurance integration model', tripInsuranceIntegration.get('category') === 'trip-insurance'));
      return tripInsuranceIntegration.get('renterPercent') / 100 * bookingTotal;
    },

    calculatePenaltyPrice(booking, bookingRestrictions) {
      const today = (0, _dayjs.default)();
      const sevenDaysFromToday = today.add(7, 'days');
      const startDate = (0, _dayjs.default)(booking.from);
      let penalty = 0;

      if (bookingRestrictions) {
        const cancellationsCount = bookingRestrictions.all_booking_cancellations_six_months;
        const cancellationThreshold = bookingRestrictions.all_booking_cancellation_threshold;
        const cancellationPenaltyLessThan7Days = bookingRestrictions.cancellation_penalty_less_than_7_days / 100;
        const cancellationPenaltyMoreThan7Days = bookingRestrictions.cancellation_penalty_more_than_7_days / 100;

        if (cancellationsCount >= cancellationThreshold) {
          penalty = cancellationPenaltyMoreThan7Days;

          if (sevenDaysFromToday.isAfter(startDate)) {
            penalty = cancellationPenaltyLessThan7Days;
          }
        }
      }

      return penalty;
    },

    calculateRemainingInstabookCancellations(bookingRestrictions) {
      let remainingCancellations = 0;

      if (bookingRestrictions) {
        const instantBookCancellationsCount = bookingRestrictions.instant_book_cancellations_six_months;
        const instantBookCancellationThreshold = bookingRestrictions.instant_book_cancellation_threshold;
        remainingCancellations = Math.max(instantBookCancellationThreshold - instantBookCancellationsCount, 0);
      }

      return remainingCancellations;
    },

    _serializeItems(booking) {
      let items = [];
      let newItems = [];
      booking.get('lineItems').forEach(item => {
        if (!item) {
          return;
        }

        let obj = item.serialize({
          includeId: true
        });

        if (obj.id) {
          obj.id = parseInt(obj.id);
        } // batch needs this flag (_deleted) to delete


        if (item.get('isDeleted')) {
          obj._deleted = true;
        }

        items.push(obj); // we reload the booking so any new record can be removed
        // since it will be created in batch post to booking items

        if (item.get('isNew')) {
          newItems.push(item);
        }
      });
      newItems.forEach(l => {
        l.deleteRecord();
      });
      return items;
    }

  });

  _exports.default = _default;
});