define("@outdoorsyco/outdoorsy-addon/adapters/page", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;

  var _default = JSONAPIAdapter.extend({
    host: 'https://d1o5877uy6tsnd.cloudfront.net',

    urlForFindRecord() {
      let result = this._super(...arguments);

      return `${result}.json`;
    },

    pathForType() {
      let host = this.get('fastboot.request.host') || window.location.host || 'www-outdoorsy-com';
      host = host.replace(/\./gi, '-');
      host = this._setHost(host);
      return `pages/${host}`;
    },

    _setHost(host) {
      if (host.match(/localhost:4502|odc-lab-canada/)) {
        return 'ca-outdoorsy-com';
      }

      if (host.match(/localhost|odc-lab|fb1.outdoorsy.co|preview.outdoorsy.co|staging.outdoorsy.co/)) {
        return 'www-outdoorsy-com';
      }

      return host;
    }

  });

  _exports.default = _default;
});