define("@outdoorsyco/ember-shared-data/helpers/item-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function itemIndex(params
  /*, hash*/
  ) {
    if (!params || params.length === undefined) {
      return 1;
    }

    return params[0] + 1;
  });

  _exports.default = _default;
});