define("@outdoorsyco/ember-switchback/components/select/component", ["exports", "@outdoorsyco/ember-switchback/components/form-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * An select wrapped around ember-power-select
   *
   * ```handlebars
   * <Select
   *  @value={{this.value}}
   *  @options={{this.options}}
   *  @searchField="name"
   *  @placeholder="Select a country" as |option|>
   *  {{option.name}}
   * </Select>
   * ```
   * @public
   */
  let Select = (_dec = Ember.inject.service, (_class = class Select extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "switchback", _descriptor, this);

      (false && !(typeof args.onChange === 'function') && Ember.assert(`You must pass an @onChange action to <Select>, you passed ${args.onChange}`, typeof args.onChange === 'function'));
    }

    get noMatchesMessage() {
      let intl = this.switchback.intl;
      let key = 'form.select.no-results';

      if (intl && intl.exists(key)) {
        return intl.t(key);
      } else {
        return 'No results found.';
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "switchback", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Select;
});