define("@outdoorsyco/ember-shared-data/validator/max-deposit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.maxDeposit = maxDeposit;
  _exports.default = void 0;

  function maxDeposit(value, maxDeposit) {
    const noValueToValidate = Ember.isEmpty(value); // maxDeposit = $3,000 or 3,000£ or 3000€ ==> 3000

    maxDeposit = maxDeposit.replace(/[^\d]/g, '');
    const validValue = String(value).match(/[0-9]/) && value <= parseInt(maxDeposit);
    return noValueToValidate || validValue;
  }

  var _default = {
    param: 'maxDeposit',
    error: {
      key: 'error.validator.maxDeposit',
      message: 'The maximum deposit is %@'
    },
    validate: maxDeposit
  };
  _exports.default = _default;
});