define("@outdoorsyco/ember-switchback/components/range/component", ["exports", "@glimmer/component", "@outdoorsyco/ember-switchback/utils/key-codes"], function (_exports, _component, _keyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * Range slider
   *
   * ```handlebars
   * <Range
   *  @minValue={{this.min}}
   *  @maxValue={{this.max}}
   *  @min="0"
   *  @max="100"
   *  @onChange={{action this.updateValues}} />
   * ```
   * @public
   */
  let Range = (_dec = Ember._action, _dec2 = Ember._action, (_class = class Range extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "rangeSliderId", `${Ember.guidFor(this)}`);

      (false && !(args.min !== undefined) && Ember.assert('You must provide a @min to Range', args.min !== undefined));
      (false && !(args.max !== undefined) && Ember.assert('You must provide a @max to Range', args.max !== undefined));
    }

    get rangeSliderElement() {
      return document.getElementById(this.rangeSliderId);
    }

    get minValue() {
      return this.args.minValue || this.args.min;
    }

    get maxValue() {
      return this.args.maxValue || this.args.max;
    }
    /**
     * Determines how much the values change when the user manipulates the
     * values via the keyboard. It is also used as the default minRange
     * value if not provided.
     */


    get incrementBy() {
      let steps = this.args.steps || 30;
      return Math.round((this.args.max - this.args.min) / steps);
    }
    /**
     * Styles applied ot the min knob for positioning. This is also used
     * by the active rail element to position its left edge.
     */


    get minKnobStyle() {
      /**
       * If the minValue is less than the range's min we make sure it doesn't get
       * positioned outside the range's bounding box.
       */
      let minValue = Math.max(this.minValue, this.args.min); // Also do this in case the minValue is greater than the max

      minValue = Math.min(this.minValue, this.args.max);
      let percent = (minValue - this.args.min) / (this.args.max - this.args.min) * 100;
      return Ember.String.htmlSafe(`left: ${percent}%;`);
    }
    /**
     * Styles applied to the max knob for positioning. This is also used
     * by the active rail element to position its right edge.
     */


    get maxKnobStyle() {
      /**
       * If the maxValue is less than the range's min we make sure it doesn't get
       * positioned outside the range's bounding box.
       */
      let maxValue = Math.max(this.maxValue, this.args.min); // Also do this in case the maxValue is greater than the max

      maxValue = Math.min(this.maxValue, this.args.max);
      let percent = (maxValue - this.args.min) / (this.args.max - this.args.min) * 100; // Orient direction from right so we can reuse it for the active rail style

      return Ember.String.htmlSafe(`right: ${100 - percent}%;`);
    }
    /**
     * Styles applied ot the active rail
     */


    get activeRailStyle() {
      return Ember.String.htmlSafe(`${this.minKnobStyle} ${this.maxKnobStyle}`);
    }

    onRailPress(event) {
      if (this.args.disabled) {
        return;
      }

      let rect = this.rangeSliderElement.getBoundingClientRect();
      let offsetX = (event.clientX || event.targetTouches[0].clientX) - rect.left;
      let percent = offsetX / rect.width;
      let newValues = {
        min: this.minValue,
        max: this.maxValue
      };
      let clickValue = Math.round((this.args.max - this.args.min) * percent);
      let minKnobIsCloser = newValues.min - clickValue > clickValue - newValues.max;
      let key = minKnobIsCloser ? 'min' : 'max';
      newValues[key] = clickValue;
      newValues = this.constrainValues(newValues, key);
      /**
       * Pretend like the user also clicked on the knob so if they want they
       * can click on the rail and keep dragging to adjust the value
       */

      this.startDrag(key);

      if (this.args.onChange) {
        this.args.onChange(newValues);
      }
    }
    /**
     * Captures key events on the knobs and updates the values appropriately.
     *
     * @param {string} key - min or max depending on which knob the user is triggering the key input on
     * @param {Event} event - keydown DOM event
     */


    onKeyDown(key, event) {
      if (this.args.disabled) {
        return;
      }

      let newValues = {
        min: this.minValue,
        max: this.maxValue
      };

      switch (event.which) {
        case _keyCodes.UP_ARROW:
          event.preventDefault();
          newValues[key] = newValues[key] + this.incrementBy;
          break;

        case _keyCodes.DOWN_ARROW:
          event.preventDefault();
          newValues[key] = newValues[key] - this.incrementBy;
          break;

        case _keyCodes.RIGHT_ARROW:
          event.preventDefault();
          newValues[key] = newValues[key] + this.incrementBy;
          break;

        case _keyCodes.LEFT_ARROW:
          event.preventDefault();
          newValues[key] = newValues[key] - this.incrementBy;
          break;

        case _keyCodes.HOME:
          event.preventDefault();
          newValues[key] = this.args.min;
          break;

        case _keyCodes.END:
          event.preventDefault();
          newValues[key] = this.args.max;
          break;
      }

      newValues = this.constrainValues(newValues, key);

      if (this.args.onChange) {
        this.args.onChange(newValues);
      }
    }
    /**
     * Catches the user starting a drag
     *
     * @param {string} type - Which knob is triggering this drag event
     * @param {Event} event - mousedown DOM event
     */


    startDrag(type) {
      if (this.args.disabled) {
        return;
      }

      this.dragType = type;
      /**
       * We set these events on the body so you can move your cursor anywhere
       * while dragging and still see the values update. This reduces the finicky
       * nature of the slider.
       */

      document.body.addEventListener('mouseup', this.endDrag);
      document.body.addEventListener('mousemove', this.onDrag);
      document.body.addEventListener('touchend', this.endDrag);
      document.body.addEventListener('touchmove', this.onDrag);
    }
    /**
     * Stops the user drag event and cleans up event listeners
     */


    endDrag() {
      this.dragType = null;
      this.removeEventListeners();
    }
    /**
     * Updates the values as the user drags the knob around
     *
     * @param {Event} event - mousemove DOM event
     */


    onDrag(event) {
      if (!this.dragType) {
        return;
      }

      let rect = this.rangeSliderElement.getBoundingClientRect();
      let clientX = event.targetTouches ? event.targetTouches[0].clientX : event.clientX;
      let offsetX = clientX - rect.left;
      let percent = offsetX / rect.width;
      let newValues = {
        min: this.minValue,
        max: this.maxValue
      };
      newValues[this.dragType] = Math.round((this.args.max - this.args.min) * percent);
      newValues = this.constrainValues(newValues, this.dragType);

      if (this.args.onChange) {
        this.args.onChange(newValues);
      }
    }
    /**
     * This ensures that the min and max knobs don't
     * end up on the wrong side of each other or exceed the
     * range slider's range. The slider that is prioritized will
     * not get its value adjusted.
     *
     * @param {object} values - The values to constrain to the min/max
     * @param {string} [priority=min] - Which value to prioritize when ensuring that the knobs don't pass each other.
     */


    constrainValues(values, priority = 'min') {
      let newValues = Object.assign({}, values);
      /**
       * Don't let the min/max values be less/greater than Range's min/max
       * so we don't end up positioning them out of bounds
       */

      newValues.min = Math.max(newValues.min, this.args.min);
      newValues.max = Math.min(newValues.max, this.args.max);

      if (priority === 'max') {
        // Don't let min/max values exceed their counterpart's
        // Min knob must always be on the left of the max knob.
        newValues.min = Math.min(newValues.min, newValues.max); // Max knob must always be on the right of the min knob.

        newValues.max = Math.max(newValues.max, newValues.min);
      } else {
        /**
         * This is identical to the above adjustment, we just change the order
         * so the max value gets adjusted first.
         */
        newValues.max = Math.max(newValues.max, newValues.min);
        newValues.min = Math.min(newValues.min, newValues.max);
      }

      newValues = this.spaceValues(newValues, priority);
      return newValues;
    }
    /**
     * Spaces apart the min/max values so the knobs don't end up
     * on top of each other using the minRange argument. The prioritized
     * knob won't get its value adjusted.
     *
     * @param {object} values - The values to space apart
     * @param {string} priority - Which value to freeze when ensuring that the values aren't too close
     */


    spaceValues(values, priority) {
      let newValues = Object.assign({}, values);
      let minRange = parseInt(this.args.minRange) || this.incrementBy;
      /**
       * Values are already sufficiently far apart
       */

      if (newValues.max - newValues.min > minRange) {
        return newValues;
      } // Deals with dragging to far right


      if (newValues.min > this.args.max - minRange) {
        newValues.max = parseInt(this.args.max);
        newValues.min = newValues.max - minRange; // Deals with dragging to the far left
      } else if (newValues.max < this.args.min + minRange) {
        newValues.min = parseInt(this.args.min);
        newValues.max = newValues.min + minRange; // Deals with adjusting range in the middle
      } else {
        if (priority === 'max') {
          newValues.min = newValues.max - minRange;
        } else {
          newValues.max = newValues.min + minRange;
        }
      }

      return newValues;
    }
    /**
     * Removes event listeners. Because they are manually attached to the body element
     * they won't automatically get cleaned up when the Range component is removed
     * from the dom.
     */


    removeEventListeners() {
      document.body.removeEventListener('mouseup', this.endDrag);
      document.body.removeEventListener('mousemove', this.onDrag);
      document.body.removeEventListener('touchend', this.endDrag);
      document.body.removeEventListener('touchmove', this.onDrag);
    }

    willDestroy() {
      this.removeEventListeners();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "endDrag", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "endDrag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDrag", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onDrag"), _class.prototype)), _class));
  _exports.default = Range;
});