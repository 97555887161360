define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/minimal-header/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/outdoorsy/minimal-header/template", "@outdoorsyco/ember-shared-data/utils/domain-languages"], function (_exports, _template, _domainLanguages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    prompt: Ember.inject.service(),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    lyrvManager: Ember.inject.service(),
    layoutService: Ember.inject.service('layout'),
    homepageURL: Ember.computed(function () {
      if (this.get('fastboot.isFastBoot')) {
        return '/';
      }

      return `${window.location.protocol}//${window.location.host}/`;
    }),
    // TODO: Don't bake so much lyrv-specific logic in this file
    isLyrvRoute: Ember.computed('router.currentRouteName', function () {
      let routeName = Ember.get(this, 'router.currentRouteName');
      return routeName && routeName.includes('owner.list');
    }),
    isPayoutNewRoute: Ember.computed('router.currentRouteName', function () {
      let routeName = Ember.get(this, 'router.currentRouteName');
      return routeName.includes('payout.new');
    }),
    isLyrvStepOne: Ember.computed('router.currentRouteName', function () {
      let lastRoutePiece = Ember.get(this, 'router.currentRouteName').split('.').pop();
      return lastRoutePiece === 'make-model-year-length';
    }),
    showExitLink: Ember.computed('isPayoutNewRoute', 'isLyrvRoute', 'isLyrvStepOne', function () {
      return this.isPayoutNewRoute || this.isLyrvRoute && !this.isLyrvStepOne;
    }),

    init() {
      this._super(...arguments);

      this.set('fastboot', Ember.getOwner(this).lookup('service:fastboot'));
      this.host = this.get('fastboot.request.host') || window.location.host;
      this.phoneNumber = (0, _domainLanguages.getCustomerServiceNumberFromHost)(this.host);
    },

    actions: {
      async openPayoutExitPrompt() {
        const {
          status
        } = await this.prompt.question({
          title: this.intl.t('comp-payouts-exit-confirmation'),
          description: this.intl.t('comp-payouts-exit-confirmation-description'),
          accept: {
            label: this.intl.t('comp-payouts-exit-confirm'),
            variant: 'red-secondary'
          },
          cancel: {
            label: this.intl.t('comp-payouts-exit-deny')
          }
        }, {
          minWidth: 26,
          maxWidth: 30
        });

        if (status === 'resolved') {
          this.metrics.trackEvent({
            event: 'Owner : Payouts : Exited'
          });
          this.router.transitionTo('dashboard.account.payout');
        }
      },

      async back() {
        let lastRoutePiece = Ember.get(this, 'router.currentRouteName').split('.').pop();

        if (lastRoutePiece === 'index') {
          if (Ember.get(this, 'session.isGuest')) {
            let ownerEmail = this.store.peekRecord('user', this.session.userId).email;
            Ember.set(this, 'session.userReAuth', true);
            let {
              status
            } = await this.prompt.show('prompt/authenticate', {
              stage: 'signup',
              signupMethod: 'email',
              signupPreset: {
                email: ownerEmail,
                disable: true
              }
            }, {
              minWidth: 27
            });

            if (status === 'resolved') {
              this.lyrvManager.trackEvent('Lyrv : Saved and exited', 'index-new-owner');
              this.router.transitionTo(this.layoutService.minimalHeaderBackButtonDestination);
            }

            if (status === 'rejected') return;
          } else {
            this.lyrvManager.trackEvent('Lyrv : Saved and exited', 'index-owner');
            this.router.transitionTo(this.layoutService.minimalHeaderBackButtonDestination);
          }
        } else {
          if (this.lyrvManager.unsavedChanges) {
            let {
              status
            } = await this.prompt.question({
              title: this.intl.t('comp-lyrv-header-save-exit-warning'),
              iconUrl: '/img/icons/warning-triangle.svg',
              description: this.intl.t('comp-lyrv-header-lose-changes'),
              accept: {
                label: this.intl.t('comp-lyrv-header-exit-anyway'),
                variant: 'red-secondary'
              },
              cancel: {
                label: this.intl.t('term-cancel')
              }
            });

            if (status === 'resolved') {
              this.lyrvManager.undoChanges();
              this.lyrvManager.trackEvent('Lyrv : Exited to index', this.lyrvManager.currentStep.name);
              this.router.transitionTo(this.layoutService.minimalHeaderBackButtonDestination);
            }

            if (status === 'rejected') return;
          } else {
            this.lyrvManager.trackEvent('Lyrv : Exited to index', this.lyrvManager.currentStep.name);
            this.router.transitionTo(this.layoutService.minimalHeaderBackButtonDestination);
          }
        }
      }

    }
  });

  _exports.default = _default;
});