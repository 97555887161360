define('ember-mobile-pane/components/mobile-pane/pervious', ['exports', 'ember-mobile-pane/templates/components/mobile-pane/pervious'], function (exports, _pervious) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _pervious.default,

    tagName: '',

    // public

    // protected
    paneCount: 0,
    activeIndex: 0,

    onClick: function onClick() {},


    isDisabled: Ember.computed('activeIndex', 'paneCount', function () {
      return Ember.get(this, 'activeIndex') <= 0;
    }),

    actions: {
      onClick: function onClick(activeIndex, evt) {
        evt.stopPropagation();
        if (Ember.get(this, 'isDisabled')) {
          return;
        }

        Ember.get(this, 'onClick')(activeIndex);
        return false;
      }
    }
  });
});