define("@outdoorsyco/outdoorsy-addon/components/line-behind/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QUXRkDXh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"data-test-line-behind\",\"\"],[10,\"class\",\"line-behind\"],[11,\"style\",[22,\"style\"]],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"style\",[22,\"spanStyle\"]],[8],[14,1],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/line-behind/template.hbs"
    }
  });

  _exports.default = _default;
});