define("@outdoorsyco/outdoorsy-addon/components/review-stars/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/review-stars/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'span',
    classNames: ['reviews-stars'],
    score: 0,
    count: 0,
    size: 'size-24',
    // local variables
    max: 5,
    hasReviews: Ember.computed('count', function () {
      return this.get('count') > 0;
    }),
    stars: Ember.computed('score', function () {
      let stars = [];
      let max = this.max;
      let score = this.get('score');

      for (let i = 1; i <= max; i++) {
        if (i <= score) {
          stars.push('star-filled');
        } else if (Math.floor(score) - i + 1 === 0 && score % 1 >= 0.5) {
          stars.push('star-empty-half');
        } else {
          stars.push('star-empty');
        }
      }

      return stars;
    })
  });

  _exports.default = _default;
});