define("@outdoorsyco/ember-switchback/components/layout/modal/default/action-bar/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @documenter esdoc */

  /**
   * Shows a bar for placing the actions of the modal.
   *
   * ```handlebars
   * <ActionBar>
   *  <Button {{on "click" this.save}}>
   *    Save Changes
   *  </Button>
   * </ActionBar>
   * ```
   * @public
   */
  class ModalActionBar extends _component.default {}

  _exports.default = ModalActionBar;
});