define("@outdoorsyco/ember-shared-data/adapters/premium-service", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PremiumService extends _application.default {
    pathForType() {
      return 'bookings/:bookingId/services';
    }

    buildURL(type, id, snapshot, requestType, query) {
      let url = super.buildURL(type, id, snapshot, requestType);

      if (snapshot) {
        url = url.replace(':bookingId', snapshot.belongsTo('booking', {
          id: true
        }));
      } else if (query && query.booking_id) {
        url = url.replace(':bookingId', query.booking_id);
        delete query.booking_id;
      } // we're using the service_id for update/destroy, not sure if that's a great idea or not


      if (id) {
        let re = new RegExp(`/${id}$`);
        url = url.replace(re, `/${snapshot.attr('serviceId')}`);
      }

      return url;
    }

  }

  _exports.default = PremiumService;
});