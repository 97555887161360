define("@outdoorsyco/ember-shared-data/components/country-select/component", ["exports", "@outdoorsyco/ember-shared-data/components/country-select/template", "@outdoorsyco/ember-shared-data/utils/countries", "@outdoorsyco/ember-shared-data/utils/states-provinces"], function (_exports, _template, _countries, _statesProvinces) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    label: false,
    id: 'country-select',
    country: '',
    value: '',
    showErrors: false,
    error: '',
    supported: false,
    dropdownClass: null,
    renderInPlace: false,
    disabled: false,
    placeholder: Ember.computed('intl.locale', function () {
      return this.get('intl').t('form-country');
    }),
    countries: Ember.computed('country', function () {
      let objs = this.get('supported') ? this.get('supportedCountries') : _countries.countries;
      return objs.sort((a, b) => {
        if (a.display < b.display) {
          return -1;
        }

        if (a.display > b.display) {
          return 1;
        }

        return 0;
      });
    }),
    supportedCountries: Ember.computed(function () {
      return (0, _statesProvinces.countriesForSelect)().map(c => {
        c.display = c.name;
        return c;
      });
    }),
    actions: {
      setCountry(country) {
        this.sendAction('onChange', country.value); //eslint-disable-line ember/closure-actions
      }

    }
  });

  _exports.default = _default;
});