define("@outdoorsyco/outdoorsy-addon/services/conversations", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const pollingTimeout = 900000; // 15 minutes

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    messagesCount: null,

    decrementMessageCount() {
      let messagesCount = this.get('messagesCount');

      if (messagesCount > 0) {
        this.set('messagesCount', Number(messagesCount - 1));
      }
    },

    loadMessages: (0, _emberConcurrency.task)(function* () {
      let requestData = {
        owner_id: this.get('session.userId'),
        order: 'updated',
        limit: 1,
        offset: 0,
        has_messages: true,
        new: true
      };
      let conversations = yield this.get('store').query('conversation', requestData);
      this.set('messagesCount', conversations.get('meta.total'));

      if (Ember.testing) {
        return;
      }

      yield (0, _emberConcurrency.timeout)(pollingTimeout);
      this.get('loadMessages').perform();
    })
  });

  _exports.default = _default;
});