define("@outdoorsyco/ember-shared-data/helpers/cents-to-dollars", ["exports", "@outdoorsyco/ember-shared-data/utils/money"], function (_exports, _money) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.centsToDollars = centsToDollars;
  _exports.default = void 0;

  function centsToDollars([cents]) {
    return (0, _money.centsToDollars)(cents);
  }

  var _default = Ember.Helper.helper(centsToDollars);

  _exports.default = _default;
});