define("@outdoorsyco/ember-switchback/components/fieldset/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Fieldset groups together multiple fields into one block of inputs with
   * a single label. This block will only show one validation error at a time.
   *
   * ```handlebars
   * <Fieldset as |g|>
   *  <g.Row>
   *    <TextInput
   *      @value={{this.number}}
   *      @onChange={{fn (mut this.number)}}
   *      @group={{g}}
   *      @label="Card number"
   *      placeholder="1234 1234 1234 1234" />
   *  </g.Row>
   *  <g.Row>
   *    <TextInput
   *      @value={{this.expiry}}
   *      @onChange={{fn (mut this.expiry)}}
   *      @group={{g}}
   *      @label="Expiry"
   *      placeholder="MM / YY" />
   *    <TextInput
   *      @value={{this.vcc}}
   *      @onChange={{fn (mut this.vcc)}}
   *      @group={{g}}
   *      @label="CVV"
   *      placeholder="CVV" />
   *  </g.Row>
   *  <g.Row>
   *    <Select
   *      @value={{this.country}}
   *      @group={{g}}
   *      @options={{this.options}}
   *      @placeholder="Country" as |option|>
   *      {{option}}
   *    </Select>
   *  </g.Row>
   *  </Fieldset>
   * ```
   * @public
   */
  let Fieldset = (_dec = Ember._tracked, (_class = class Fieldset extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "inputRegistry", _descriptor, this);
    }

    /**
     * Adds a child input component to the registry.
     *
     * @param {component} child - A child input component to register
     */
    registerInput(child) {
      Ember.run.next(() => {
        this.inputRegistry.pushObject(child);
      });
    }
    /**
       Removes a child input component from the registry
      @param {component} child A child input component to unregister
    */


    unregisterInput(child) {
      this.inputRegistry.removeObject(child);
    }

    get validationError() {
      if (!this.inputRegistry.length) {
        return null;
      }

      let input = this.inputRegistry.findBy('isErrored', true);

      if (input) {
        return input.validationErrors[0];
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "inputRegistry", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return Ember.A();
    }
  })), _class));
  _exports.default = Fieldset;
});