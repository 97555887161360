define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/explore-menu/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "nav-container": "_nav-container_fsssuf",
    "sections": "_sections_fsssuf"
  };
  _exports.default = _default;
});