define("@outdoorsyco/ember-shared-data/components/impersonation-banner/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "white": "hsl(255, 100%, 100%)",
    "orange500": "hsl(33, 99%, 57%)",
    "orange700": "hsl(31, 92%, 44%)",
    "red500": "hsl(358, 80%, 58%)",
    "impersonation-banner": "_impersonation-banner_1jlp80",
    "container": "_container_1jlp80",
    "link": "_link_1jlp80"
  };
  _exports.default = _default;
});