define("@outdoorsyco/ember-shared-data/components/bundle/features/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RaWfxQIY",
    "block": "{\"symbols\":[\"feature\",\"item\",\"@currency\",\"@bundle\"],\"statements\":[[4,\"each\",[[23,4,[\"bundleFeatures\"]]],null,{\"statements\":[[4,\"each\",[[23,1,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"  text-medium margin-bottom-0.75 \"],[8],[0,\"\\n      \"],[1,[23,2,[\"name\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"category\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"  text-normal text-gray700 \"],[8],[0,\"\\n          \"],[1,[23,1,[\"category\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[23,4,[\"deductibleInDollars\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"  text-medium margin-all-0 \"],[8],[0,\"\\n    \"],[1,[28,\"format-number\",[[23,4,[\"deductibleInDollars\"]]],[[\"style\",\"currency\",\"minimumFractionDigits\",\"maximumFractionDigits\"],[\"currency\",[23,3,[]],0,0]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"span\",true],[10,\"class\",\"  text-gray700 margin-top-1 \"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"global.term.deductible-lowercase\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/bundle/features/template.hbs"
    }
  });

  _exports.default = _default;
});