define("@outdoorsyco/form-components/components/time-select/component", ["exports", "@outdoorsyco/form-components/components/time-select/template", "moment"], function (_exports, _template, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const minTime = 0;
  const maxTime = 86399;
  const timeInterval = 1800; // 30 minutes

  var _default = Ember.Component.extend({
    layout: _template.default,
    value: null,
    minTime,
    maxTime,
    timeInterval,
    options: null,
    error: null,
    disabled: false,
    showErrors: false,
    allowCreate: false,
    searchEnabled: false,
    renderInPlace: false,
    placeholder: null,
    allowClear: false,
    locale: 'en-us',
    timeOptions: Ember.computed('minTime', 'maxTime', function () {
      let min = this.get('minTime') || minTime;
      let max = this.get('maxTime') || maxTime;
      let interval = this.get('timeInterval') || timeInterval;
      let options = [];
      let m = (0, _moment.default)().locale(this.locale);

      for (let i = min; i <= max; i = i + interval) {
        m.set({
          'hour': i / 60 / 60,
          'minute': i / 60 % 60
        });
        options.push({
          value: i,
          display: m.format('LT')
        });
      }

      return options;
    }),
    actions: {
      onChange(time) {
        /* When cleared time is null */
        let value = time ? time.value : this.get('minTime');

        if (this.onChange) {
          this.onChange(value);
        }
      }

    }
  });

  _exports.default = _default;
});