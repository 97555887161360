define("@outdoorsyco/ember-shared-data/components/modal/trip-insurance/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ModalTripInsuranceComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ModalTripInsuranceComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "apiRequest", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _defineProperty(this, "userHasNoAddress", !Ember.get(this, 'args.user.hasAddress'));

      _initializerDefineProperty(this, "onSubmit", _descriptor3, this);
    }

    get integration() {
      return this.args.integrations.findBy('category', 'trip-insurance');
    }
    /**
     * Adds trip-insurance to the booking provided and close the modal if successful
     *
     * @param {object} modal - The object yielded by <Modal>
     */


    /**
     * Closes modal and rolls back changes if the user decides to cancel
     * this action.
     */
    onClose() {
      let user = this.args.user.content || this.args.user;

      if (user.hasDirtyAttributes) {
        user.rollbackAttributes();
      }

      this.args.onClose(...arguments);
    }

    updateAddress(key, value) {
      let objectKey;

      switch (key) {
        case 'street1':
          objectKey = 'street';
          break;

        case 'street2':
          objectKey = 'street1';
          break;

        default:
          objectKey = key;
          break;
      }

      Ember.set(this.args.user, objectKey, value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (modal) {
        let user = this.args.user.content || this.args.user;
        /**
         * Booking already has trip insurance added
         */

        if (this.args.booking.tripInsuranceService) {
          yield modal.close();
          return;
        }

        try {
          if (user.hasDirtyAttributes) {
            yield user.save();
          }

          let url = `bookings/${this.args.booking.id}/services/${this.integration.id}`;
          yield this.apiRequest.post(url);
          yield this.args.booking.reload();
        } catch (error) {
          let errorMessage = this.intl.t(`global.error.add-failed`, {
            type: 'trip_insurance'
          });
          let errorObject = new Error(errorMessage);
          errorObject.name = 'FormSubmit';
          throw errorObject;
        }

        yield modal.close();
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAddress", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateAddress"), _class.prototype)), _class));
  _exports.default = ModalTripInsuranceComponent;
});