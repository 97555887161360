define("@outdoorsyco/ember-shared-data/serializers/conversation", ["exports", "ember-data", "moment", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _moment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class ConversationSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        bookings: {
          deserialize: 'records',
          serialize: false
        },
        messages: {
          deserialize: 'records',
          serialize: false
        }
      });
    }

    keyForRelationship(key) {
      return key === 'bookings' || key === 'messages' ? key : `${key}_id`;
    }

    normalize(typeClass, hash) {
      let bookings = Ember.makeArray(hash.bookings);
      bookings = bookings.sort(function (a, b) {
        return (0, _moment.default)(a, 'YYYY-MM-DD').isAfter(b);
      });
      const mostRecent = bookings[0];

      if (mostRecent) {
        hash.next_trip_from = mostRecent.from;
        hash.next_trip_to = mostRecent.to;
      }

      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = ConversationSerializer;
});