define("@outdoorsyco/ember-shared-data/components/rentals-line-extended/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "md": "768px",
    "request-details": "_request-details_lwjizw",
    "request-info": "_request-info_lwjizw",
    "grid-container": "_grid-container_lwjizw",
    "item-a": "_item-a_lwjizw",
    "item-b": "_item-b_lwjizw",
    "outdoorsy-rental": "_outdoorsy-rental_lwjizw",
    "grid-item": "_grid-item_lwjizw",
    "request-btn-wrapper": "_request-btn-wrapper_lwjizw",
    "request-btn": "_request-btn_lwjizw",
    "info-count-characters": "_info-count-characters_lwjizw"
  };
  _exports.default = _default;
});