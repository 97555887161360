define("@outdoorsyco/ember-shared-data/components/prompt/upload/handoff-photos/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "UploadHandoffPhotos": "_UploadHandoffPhotos_1mgtha",
    "file-uploaded": "_file-uploaded_1mgtha"
  };
  _exports.default = _default;
});