define("@outdoorsyco/outdoorsy-addon/components/svg-icon/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/svg-icon/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    // Passed-in properties:
    path: null
  });

  _exports.default = _default;
});