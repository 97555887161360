define("@outdoorsyco/ember-nydus/serializers/nydus-rental", ["exports", "@outdoorsyco/ember-nydus/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize() {
      let data = this._super(...arguments);

      data.id = data.rental_id;
      delete data.rental_id;
      return data;
    }

  });

  _exports.default = _default;
});