define("@outdoorsyco/ember-shared-data/components/form/authenticate/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sizer": "_sizer_qehvr6",
    "link": "_link_qehvr6",
    "terms": "_terms_qehvr6"
  };
  _exports.default = _default;
});