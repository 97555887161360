define("@outdoorsyco/outdoorsy-addon/services/metadata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      this.set('config', Ember.getOwner(this).resolveRegistration('config:environment'));
      this.set('fastboot', Ember.getOwner(this).lookup('service:fastboot'));
    },

    urlHost: Ember.computed(function () {
      if (Ember.testing) {
        // eslint-disable-line
        return '';
      }

      let protocol = this.get('config.environment') !== 'development' ? 'https:' : false;

      if (this.get('fastboot.isFastBoot')) {
        protocol = protocol || this.get('fastboot.request.protocol');
        return `${protocol}//${this.get('fastboot.request.host')}`;
      }

      protocol = protocol || window.location.protocol;
      return `${protocol}//${window.location.host}`;
    })
  });

  _exports.default = _default;
});