define("@outdoorsyco/ember-shared-data/components/map/static/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const RENTAL_TYPE_TO_PIN = {
    'camper-van': 'campervan',
    a: 'class_a',
    b: 'class_b',
    c: 'class_c',
    'fifth-wheel': 'fifth-wheel',
    'folding-trailer': 'folding-trailer',
    other: 'other',
    'toy-hauler': 'toy-hauler',
    trailer: 'trailer',
    'truck-camper': 'truck-camper',
    'utility-trailer': 'utility-trailer'
  };
  let MapStaticComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class = class MapStaticComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "environment", _descriptor, this);

      _initializerDefineProperty(this, "width", _descriptor2, this);

      _initializerDefineProperty(this, "height", _descriptor3, this);
    }

    get markerUrl() {
      if (this.args.markerUrl) {
        return this.args.markerUrl;
      }

      let name = RENTAL_TYPE_TO_PIN[this.args.rentalType] || 'class-c';
      return `https://d1o5877uy6tsnd.cloudfront.net/img/location-map-pins/map-pin-${name}-v2.png`;
    }

    get src() {
      let style = this.environment.config.mapbox.static.style || 'mapbox/outdoors-v11';
      let token = this.environment.config.mapbox.static.token;
      let marker = encodeURIComponent(this.markerUrl);
      let lng = this.args.lng;
      let lat = this.args.lat;
      let zoom = this.args.zoom || 7;

      if (Ember.isEmpty(lat) || Ember.isEmpty(lng)) {
        return '';
      }

      return `https://api.mapbox.com/styles/v1/${style}/static/url-${marker}(${lng},${lat})/${lng},${lat},${zoom},0/${this.width}x${this.height}@2x?access_token=${token}&logo=false`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "width", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.args.width || 400;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "height", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.args.height || 400;
    }
  })), _class));
  _exports.default = MapStaticComponent;
});