define("@outdoorsyco/outdoorsy-addon/services/cancellation-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),

    policyDescription(policy) {
      switch (policy) {
        case 'custom':
          return this.intl.t('comp-cancel-policy-custom-default');

        case 'flexible':
          return this.intl.t('comp-cancel-policy-flexible');

        case 'moderate':
          return this.intl.t('comp-cancel-policy-moderate');

        case 'strict':
          return this.intl.t('comp-cancel-policy-strict');
      }
    },

    policyLink() {
      return 'https://www.outdoorsy.com/help/what-are-the-cancellation-fees-and-policies';
    }

  });

  _exports.default = _default;
});