define("@outdoorsyco/ember-shared-data/instance-initializers/session-events", ["exports", "@outdoorsyco/ember-shared-data/utils/remove-auth-params"], function (_exports, _removeAuthParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(instance) {
    const session = instance.lookup('service:session');
    const environment = instance.lookup('service:environment');
    session.on('invalidationSucceeded', function () {
      const routeAfterInvalidation = this.routeAfterInvalidation;
      this.set('routeAfterInvalidation', null);
      this.set('data.actorId', null);

      if (this.userReAuth && this.userId) {
        this.set('userReAuth', false);
        return;
      }

      if (!environment.isTest) {
        let params = (0, _removeAuthParams.default)(window.location.search) || '';

        if (params !== '') {
          params = `?${params}`;
        }

        window.location.href = routeAfterInvalidation || window.location.href.replace(window.location.search, params);
        return;
      } else {
        if (this.endOfTest) return;

        try {
          // simulate window replace by transitioning within Ember app
          instance.lookup('route:application').transitionTo(routeAfterInvalidation || '/');
        } catch (error) {
          // In tests, the invalidation may have occured before a 'visit'
          const beforeRouter = error.message === `Cannot read property 'transitionTo' of undefined`;

          if (error.name !== 'TransitionAborted' && !beforeRouter) {
            throw error;
          }
        }

        return;
      }
    });
    /**
     * Sends a message to the universal nav to load the user data
     */

    session.on('authenticationSucceeded', function () {
      let fastboot = instance.lookup('service:fastboot');

      if (fastboot && fastboot.isFastBoot) {
        return;
      }

      let event = new CustomEvent('session', {
        cancelable: true,
        bubbles: true,
        detail: {
          name: 'load-user'
        }
      });
      document.dispatchEvent(event);
    });
  }

  var _default = {
    initialize,
    name: 'session-events',
    after: 'ember-simple-auth'
  };
  _exports.default = _default;
});