define("@outdoorsyco/ember-switchback/components/modal/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-animated/transitions/fade", "@outdoorsyco/ember-switchback/transitions/scale-and-fade", "@outdoorsyco/ember-switchback/utils/key-codes"], function (_exports, _component, _emberConcurrencyDecorators, _fade, _scaleAndFade, _keyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * A modal dialog.
   *
   * ```handlebars
   * <Modal
   *  @onClose={{action "continue"}}
   *  {{style padding="all-1.5"}} as |m|>
   *
   *  <Heading id={{m.labelledbyId}}>
   *    Sample modal
   *  </Heading>
   *  <P>
   *    Content of the modal
   *  </P>
   *  <Button {{on "click" m.close}}>
   *    Close Modal
   *  </Button>
   *
   * </Modal>
   * ```
   * @public
   */
  let hasDOM = typeof document !== 'undefined';
  let Modal = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class Modal extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "renderModal", _descriptor, this);

      _defineProperty(this, "fadeTransition", _fade.default);

      _defineProperty(this, "scaleAndFadeTransition", _scaleAndFade.default);

      _defineProperty(this, "previousFocusElement", void 0);

      _defineProperty(this, "windowId", `${Ember.guidFor(this)}--window`);

      _defineProperty(this, "labelledbyId", `${Ember.guidFor(this)}--labelledby`);

      _initializerDefineProperty(this, "hide", _descriptor2, this);
    }

    get windowElement() {
      return document.getElementById(this.windowId);
    }

    get animationDuration() {
      if (!hasDOM) {
        return 0;
      }

      let {
        environment
      } = Ember.getOwner(this).resolveRegistration('config:environment');
      /**
       * If the window is not yet in the DOM and we aren't trying to hide it then
       * we can assume it was not rendered in fastboot.
       */

      let wasRenderedInFastboot = Boolean(document.querySelector('[role=dialog]')) && !this.hide.isRunning;
      return environment === 'test' || wasRenderedInFastboot ? 0 : 200;
    }

    get minWidth() {
      return parseInt(this.args.minWidth || 20);
    }

    get closeOnEsc() {
      (false && !(this.args.closeOnEsc === undefined || typeof this.args.closeOnEsc === 'boolean') && Ember.assert('closeOnEsc must be a boolean value', this.args.closeOnEsc === undefined || typeof this.args.closeOnEsc === 'boolean'));
      return this.args.closeOnEsc !== undefined ? this.args.closeOnEsc : true;
    }

    onDidInsert() {
      this.show();
    }

    onEsc() {
      if (this.closeOnEsc) {
        this.hide.perform({
          cancelled: true
        });
      }
    }

    onKeyDown(event) {
      if (event.which === _keyCodes.ESC) {
        this.onEsc();
      }
    }
    /**
     * Shows the modal and manages the focus state.
     */


    show() {
      this.renderModal = true;

      if (!hasDOM) {
        return;
      }

      this.previousFocusElement = document.activeElement; // Prevents the page from scrolling behind the modal

      document.body.style.overflow = 'hidden';
      Ember.run.next(this, () => {
        if (this.windowElement) {
          this.windowElement.focus();
        }
      });
    }
    /**
     * Hides the modal and ensures that focus is restored
     * to where it was before the modal was shown.
     */


    willDestroy() {
      super.willDestroy(...arguments);

      if (!hasDOM) {
        return;
      }

      (false && Ember.warn('You must close <Modal> using the yielded `close` method. You most likely used an {{if}} statement to remove the modal.', this.hide.performCount > 0, {
        id: 1
      })); // Only make page scrollable if no other modals are visible

      if (document.querySelectorAll('#switchback-modals').length === 1) {
        document.body.style.overflow = '';
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "renderModal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return !hasDOM;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEsc", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onEsc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hide", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (options = {
        cancelled: false
      }) {
        this.renderModal = false;

        if (this.previousFocusElement) {
          this.previousFocusElement.focus();
        }
        /**
         * We delay until after the animation had time to finish before the
         * onClose is called and the Modal is removed from the dom.
         */


        yield new Promise(resolve => setTimeout(resolve, this.animationDuration));
        this.args.onClose(options);
      };
    }
  })), _class));
  _exports.default = Modal;
});