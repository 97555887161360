define('ember-mobile-pane/components/mobile-pane/simple-indicator', ['exports', 'ember-mobile-pane/templates/components/mobile-pane/simple-indicator'], function (exports, _simpleIndicator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _simpleIndicator.default,

    classNames: ['scroller__simple-indicator'],

    // public
    warpEnabled: false,

    // protected
    navItems: null,
    currentOffset: 0,

    onClick: function onClick() {},


    indicatorStyle: Ember.computed('currentOffset', function () {
      var offset = -1 * Ember.get(this, 'currentOffset') * Ember.get(this, 'navItems.length');
      var style = 'transform: translateX(' + offset + '%)';

      if (Ember.get(this, 'warpEnabled')) {
        // warp effect
        var fraction = offset % 100 / 100;
        var scale = 2 * fraction - 2 * Math.pow(fraction, 2);
        var scaleY = 1 - scale / 1.5;
        var scaleX = 1 + 1.5 * scale;
        style += ' scale(' + scaleX + ', ' + scaleY + ')';
      }
      style += ';';

      return Ember.String.htmlSafe(style);
    }),

    actions: {
      onClick: function onClick(navItem) {
        Ember.get(this, 'onClick')(navItem);
      }
    }
  });
});