define("@outdoorsyco/ember-shared-data/components/affirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cdRz3isJ",
    "block": "{\"symbols\":[\"&attrs\",\"@price\"],\"statements\":[[7,\"p\",false],[12,\"class\",[29,[\"affirm-as-low-as \",[28,\"local-class\",[\"affirm\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/affirm/styles\"]]]]]],[12,\"role\",\"button\"],[12,\"data-page-type\",\"product\"],[12,\"data-amount\",[23,0,[\"priceInCents\"]]],[13,1],[3,\"did-insert\",[[23,0,[\"affirm\",\"loadAffirm\"]]]],[3,\"did-update\",[[23,0,[\"refreshAffirm\"]],[23,2,[]]]],[3,\"on\",[\"click\",[23,0,[\"trackAffirmInteraction\"]]]],[8],[0,\"\\n  \"],[5,\"loading\",[],[[\"@size\"],[\"small\"]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/affirm/template.hbs"
    }
  });

  _exports.default = _default;
});