define("@outdoorsyco/ember-shared-data/mixins/nextable", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    next: null,

    nextableRoute(action) {
      let search = _emberWindowMock.default.location.search.substring(1); // remove user id - we don't want the user login to be stored


      search = search.replace(/&{0,1}user_id=[\w]+/gi, ''); // remove token

      search = search.replace(/[&\?]{1}token=[^&]*/gi, ''); // eslint-disable-line no-useless-escape

      if (action) {
        // remove current action if we have one
        search = search.replace(/&{0,1}action=[\w]+/gi, '');

        if (search.length > 1) {
          search += '&';
        }

        search += `action=${encodeURIComponent(action)}`;
      }

      let url = _emberWindowMock.default.location.pathname;

      if (search) {
        url += `?${search}`;
      }

      return btoa(url);
    },

    nextRoute() {
      if (this.get('next')) {
        return atob(this.get('next'));
      }
    },

    actions: {
      headToNext() {
        const next = this.nextRoute();

        if (next) {
          return this.transitionToRoute(next);
        }
      }

    }
  });

  _exports.default = _default;
});