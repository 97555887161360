define("@outdoorsyco/ember-shared-data/utils/async", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.waitForProperty = waitForProperty;

  function waitForProperty(obj, prop, {
    interval = 100,
    timeout = 2000
  } = {}) {
    return new Promise((resolve, reject) => {
      let elapsedTime = 0;

      let checkValue = function checkValue() {
        let value = Ember.get(obj, prop);

        if (value) {
          return resolve(value);
        }

        elapsedTime += interval;

        if (elapsedTime < timeout) {
          Ember.run.later(checkValue, interval);
        } else {
          return reject();
        }
      };

      checkValue();
    });
  }
});