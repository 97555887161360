define("@outdoorsyco/ember-switchback/components/list/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @documenter esdoc */

  /**
   * Displays a list of items.
   *
   * ```handlebars
   * <List as |l|>
   *  <l.Item>Item 1</l.Item>
   *  <l.Item>Item 2</l.Item>
   * </List>
   * ```
   * @public
   */
  class List extends _component.default {
    get computedElement() {
      (false && !(!this.args.tagName || this.args.tagName === 'ul' || this.args.tagName === 'ol') && Ember.assert('<List> only supports tagName values of ul or ol.', !this.args.tagName || this.args.tagName === 'ul' || this.args.tagName === 'ol'));
      return this.args.tagName || 'ul';
    }

  }

  _exports.default = List;
});