define("@outdoorsyco/ember-shared-data/services/lippert-rentals-service", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LippertRentalsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrencyDecorators.lastValue)('fetchRentals'), (_class = class LippertRentalsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "optimizely", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _defineProperty(this, "initialServiceYear", 2016);

      _initializerDefineProperty(this, "rentals", _descriptor4, this);

      _initializerDefineProperty(this, "fetchRentals", _descriptor5, this);
    }

    get lippertRepairZipCodes() {
      return this.optimizely.getFeatureVariable('lippert_repair', 'eligible_area', this.session.userId);
    }

    get hasRentals() {
      return Boolean(this.rentals.length);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "optimizely", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "rentals", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fetchRentals", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        var _this$lippertRepairZi;

        if (this.hasRentals) {
          return this.rentals;
        }

        let rentals = [];
        let params = {
          owner_id: this.session.currentUser.get('id'),
          status: 'all',
          limit: 0,
          hidden: true,
          full: true
        };
        const queryParams = Object.assign({}, params);
        const rentalsResponse = yield this.store.query('rental', queryParams);
        const zipCodes = (_this$lippertRepairZi = this.lippertRepairZipCodes) === null || _this$lippertRepairZi === void 0 ? void 0 : _this$lippertRepairZi.zip_codes;

        if ((zipCodes === null || zipCodes === void 0 ? void 0 : zipCodes.length) > 0) {
          rentalsResponse.forEach(rental => {
            var _rental$location;

            if (!rental.hasCompletedLyrv) {
              return;
            }

            zipCodes.includes(parseInt((_rental$location = rental.location) === null || _rental$location === void 0 ? void 0 : _rental$location.zip)) && rental.vehicleYear >= this.initialServiceYear ? rental.set('serviceAccepted', true) : rental.set('serviceAccepted', false);
            rentals.push(rental);
          });
        }

        return rentals;
      };
    }
  })), _class));
  _exports.default = LippertRentalsService;
});