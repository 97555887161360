define("@outdoorsyco/ember-shared-data/services/file-upload", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FileUploadService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class FileUploadService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "apiRequest", _descriptor, this);

      _initializerDefineProperty(this, "environment", _descriptor2, this);

      _initializerDefineProperty(this, "getUploadParamsTask", _descriptor3, this);

      _initializerDefineProperty(this, "uploadTask", _descriptor4, this);

      _initializerDefineProperty(this, "uploadMultipartTask", _descriptor5, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "getUploadParamsTask", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (path) {
        let url = 'uploads/sign';
        let folder = `${this.environment.config.APP.cloudinaryRoot}/${path}`;
        let transformation = 't_upload_scaling';
        let response = yield this.apiRequest.request(url, {
          method: 'GET',
          data: {
            folder,
            transformation
          }
        });
        let options = {
          method: 'POST',
          data: {
            api_key: this.environment.config.APP.cloudinaryKey,
            timestamp: response.timestamp,
            signature: response.signature,
            folder,
            transformation
          }
        };
        return {
          url: 'https://api.cloudinary.com/v1_1/outdoorsy/image/upload',
          options
        };
      };
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uploadTask", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (file, path) {
        let {
          url,
          options
        } = yield this.getUploadParamsTask.perform(path); // push file into options somewhere

        let data = yield this.apiRequest.request(url, options);
        return data;
      };
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uploadMultipartTask", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (file, path) {
        let {
          url,
          options
        } = yield this.getUploadParamsTask.perform(path);
        let match = file.match(/data:(.*);/);

        if (match) {
          options.data['Content-Type'] = match[1];
        }

        options.headers = {
          Accept: 'application/json,text/javascript'
        };
        options.data.file = file;
        let formData = new FormData();

        for (const [key, value] of Object.entries(options.data)) {
          formData.append(key, value);
        }

        options.data = formData;
        let data = yield this.apiRequest.request(url, options);
        return data;
      };
    }
  })), _class));
  _exports.default = FileUploadService;
});