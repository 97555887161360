define("@outdoorsyco/ember-shared-data/components/gps-deprecation-warning/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gps-warning-wrapper": "_gps-warning-wrapper_ni77c0",
    "gps-warning-heading": "_gps-warning-heading_ni77c0",
    "gps-warning-content": "_gps-warning-content_ni77c0",
    "gps-warning-content-main": "_gps-warning-content-main_ni77c0",
    "gps-warnign-footer": "_gps-warnign-footer_ni77c0"
  };
  _exports.default = _default;
});