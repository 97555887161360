define("@outdoorsyco/ember-shared-data/models/handoff", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  let HandoffModel = (_dec = attr('string'), _dec2 = attr('string'), _dec3 = attr('number'), _dec4 = attr('number'), _dec5 = attr('boolean'), _dec6 = attr('boolean'), _dec7 = attr('boolean'), _dec8 = attr('boolean'), _dec9 = attr('boolean'), _dec10 = attr('boolean'), _dec11 = attr('boolean'), _dec12 = attr('boolean'), _dec13 = attr('boolean'), _dec14 = attr('boolean'), _dec15 = attr('boolean'), _dec16 = attr('boolean'), _dec17 = attr('boolean'), _dec18 = attr('boolean'), _dec19 = attr('boolean'), _dec20 = attr('boolean'), _dec21 = attr('boolean'), _dec22 = attr('boolean'), _dec23 = attr('boolean'), _dec24 = attr('boolean'), _dec25 = belongsTo('booking'), _dec26 = attr('', {
    defaultValue() {
      return [];
    }

  }), _dec27 = attr('', {
    defaultValue() {
      return [];
    }

  }), _dec28 = attr('', {
    defaultValue() {
      return [];
    }

  }), _dec29 = attr('', {
    defaultValue() {
      return [];
    }

  }), _dec30 = attr('', {
    defaultValue() {
      return [];
    }

  }), _dec31 = attr('', {
    defaultValue() {
      return [];
    }

  }), _dec32 = attr('', {
    defaultValue() {
      return [];
    }

  }), (_class = class HandoffModel extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "assetType", _descriptor, this);

      _initializerDefineProperty(this, "assetId", _descriptor2, this);

      _initializerDefineProperty(this, "suggestedPhotoCount", _descriptor3, this);

      _initializerDefineProperty(this, "requiredPhotoCount", _descriptor4, this);

      _initializerDefineProperty(this, "ownerPresentOnDeparture", _descriptor5, this);

      _initializerDefineProperty(this, "ownerReportingVehicleDamage", _descriptor6, this);

      _initializerDefineProperty(this, "ownerAcknowledgedRentalAgreement", _descriptor7, this);

      _initializerDefineProperty(this, "ownerAcknowledgedDepartureForm", _descriptor8, this);

      _initializerDefineProperty(this, "renterAcknowledgedRentalAgreement", _descriptor9, this);

      _initializerDefineProperty(this, "renterAcknowledgedDepartureForm", _descriptor10, this);

      _initializerDefineProperty(this, "ownerDepartureEligible", _descriptor11, this);

      _initializerDefineProperty(this, "ownerReturnEligible", _descriptor12, this);

      _initializerDefineProperty(this, "renterDepartureEligible", _descriptor13, this);

      _initializerDefineProperty(this, "renterReturnEligible", _descriptor14, this);

      _initializerDefineProperty(this, "ownerDepartureStarted", _descriptor15, this);

      _initializerDefineProperty(this, "ownerReturnStarted", _descriptor16, this);

      _initializerDefineProperty(this, "renterDepartureStarted", _descriptor17, this);

      _initializerDefineProperty(this, "renterReturnStarted", _descriptor18, this);

      _initializerDefineProperty(this, "ownerDepartureComplete", _descriptor19, this);

      _initializerDefineProperty(this, "ownerReturnComplete", _descriptor20, this);

      _initializerDefineProperty(this, "renterDepartureComplete", _descriptor21, this);

      _initializerDefineProperty(this, "renterReturnComplete", _descriptor22, this);

      _initializerDefineProperty(this, "departureComplete", _descriptor23, this);

      _initializerDefineProperty(this, "returnComplete", _descriptor24, this);

      _initializerDefineProperty(this, "booking", _descriptor25, this);

      _initializerDefineProperty(this, "departureErrors", _descriptor26, this);

      _initializerDefineProperty(this, "departureFiles", _descriptor27, this);

      _initializerDefineProperty(this, "returnErrors", _descriptor28, this);

      _initializerDefineProperty(this, "returnFiles", _descriptor29, this);

      _initializerDefineProperty(this, "damageFiles", _descriptor30, this);

      _initializerDefineProperty(this, "pooFiles", _descriptor31, this);

      _initializerDefineProperty(this, "signatures", _descriptor32, this);
    }

    get hasOwnerDepartureSignature() {
      return Boolean(this.signatures.findBy('type', 'booking_handoff_owner_departure_signature'));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "assetType", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "assetId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "suggestedPhotoCount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "requiredPhotoCount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ownerPresentOnDeparture", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "ownerReportingVehicleDamage", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "ownerAcknowledgedRentalAgreement", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "ownerAcknowledgedDepartureForm", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "renterAcknowledgedRentalAgreement", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "renterAcknowledgedDepartureForm", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "ownerDepartureEligible", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "ownerReturnEligible", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "renterDepartureEligible", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "renterReturnEligible", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "ownerDepartureStarted", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "ownerReturnStarted", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "renterDepartureStarted", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "renterReturnStarted", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "ownerDepartureComplete", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "ownerReturnComplete", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "renterDepartureComplete", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "renterReturnComplete", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "departureComplete", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "returnComplete", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "booking", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "departureErrors", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "departureFiles", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "returnErrors", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "returnFiles", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "damageFiles", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "pooFiles", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "signatures", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = HandoffModel;
});