define("@outdoorsyco/outdoorsy-addon/components/banner/share/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CX/PDdQP",
    "block": "{\"symbols\":[\"@onClose\",\"&attrs\",\"@onShare\"],\"statements\":[[5,\"frame\",[[12,\"class\",[29,[[28,\"local-class\",[\"Share\"],[[\"from\"],[\"@outdoorsyco/outdoorsy-addon/components/banner/share/styles\"]]]]]],[12,\"data-test-banner-share\",\"\"],[13,2]],[[],[]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"p\",[[12,\"class\",\"  text-pre-heading200 text-green900 margin-bottom-0.25 \"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"banner-share-title\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"p\",[[12,\"class\",\"  text-green800 margin-bottom-0.75 \"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"banner-share-description\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"button\",[[12,\"onclick\",[28,\"action\",[[23,0,[]],[23,3,[]]],null]]],[[\"@variant\",\"@size\",\"@href\"],[\"red-primary\",\"small\",\"/referral\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"banner-share-cta\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[5,\"close\",[[12,\"onclick\",[28,\"action\",[[23,0,[]],[23,1,[]]],null]]],[[\"@position\"],[\"top-right\"]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/banner/share/template.hbs"
    }
  });

  _exports.default = _default;
});