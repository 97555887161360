define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/header-nav/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/outdoorsy/header-nav/template", "ember-window-mock", "@outdoorsyco/outdoorsy-addon/utils/currencies", "@outdoorsyco/ember-shared-data/utils/domain-languages"], function (_exports, _template, _emberWindowMock, _currencies, _domainLanguages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'nav',
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    classNames: ['navbar', 'navbar-default', 'navbar-static-top'],
    classNameBindings: ['mobileNavOpen:open', 'isFastBoot:fastboot'],
    domain: '',
    environment: '',
    mobileNavOpen: false,

    init() {
      this._super(...arguments);

      this.config = Ember.getOwner(this).resolveRegistration('config:environment'); // dashboard does not have fastboot so conditionally bring it in

      this.set('fastboot', Ember.getOwner(this).lookup('service:fastboot'));

      let host = this.get('fastboot.request.host') || _emberWindowMock.default.location.host;

      let protocol = this.config.environment === 'development' ? 'http:' : 'https:';
      this.set('domain', `${protocol}//${host}`);
      this.countriesMenu = this._includedCountries();
      this.currenciesArray = _currencies.currenciesArray;
      let locale = this.get('intl._locale') && this.get('intl._locale')[0] || 'en';

      if (locale && locale === 'en') {
        locale = 'en-us';
      }

      this.set('currentCountry', locale);
    },

    isFastBoot: Ember.computed(function () {
      let fastboot = Ember.getOwner(this).lookup('service:fastboot') || {};
      return fastboot.isFastBoot;
    }),
    homepageURL: Ember.computed(function () {
      if (this.get('fastboot.isFastBoot')) {
        return '/';
      }

      return `${_emberWindowMock.default.location.protocol}//${_emberWindowMock.default.location.host}/`;
    }),
    currentCurrency: Ember.computed.readOnly('session.currentCurrency'),
    currentCountryObject: Ember.computed('countriesMenu', 'currentCountry', function () {
      return this.get('countriesMenu').findBy('langs.firstObject', this.get('currentCountry'));
    }),
    currentCurrencyObject: Ember.computed('currenciesArray', 'currentCurrency', function () {
      let currentCurrency = this.get('currentCurrency');

      if (currentCurrency) {
        return this.get('currenciesArray').findBy('currency', this.get('currentCurrency'));
      }

      return this.get('currenciesArray').findBy('currency', 'USD');
    }),

    didRender() {
      if (!this.get('mobileNavOpen') && Array.from(document.body.classList).includes('mobile-fixed-layout')) {
        document.body.classList.remove('mobile-fixed-layout');
      }
    },

    actions: {
      login() {
        if (this.get('media.isMobile')) {
          this.send('toggleMobileNav');
        }

        this.get('login')('login');
      },

      register() {
        if (this.get('media.isMobile')) {
          this.send('toggleMobileNav');
        }

        this.get('signup')('register');
      },

      logout() {
        if (this.get('media.isMobile')) {
          this.send('toggleMobileNav');
        }

        this.get('logout')();
      },

      locationSearch(location) {
        this.get('locationSearch')(location);
      },

      locationFound(lat, lng, location, place, ref, bounds) {
        this.get('locationFound')(lat, lng, location, place, ref, bounds);
      },

      searchWithQuery(...args) {
        this.get('searchWithQuery')(args);
      },

      toggleMobileNav() {
        if (!this.get('fastboot.isFastBoot')) {
          if (!this.get('mobileNavOpen')) {
            document.body.classList.add('mobile-fixed-layout');
          } else {
            document.body.classList.remove('mobile-fixed-layout');
          }
        }

        this.get('toggleMobileNav')(this.get('mobileNavOpen'));
      }

    },

    _includedCountries() {
      let languages = _domainLanguages.domainLanguagesArray;

      if (this.get('features').isEnabled('internationalHomepage')) {
        languages = languages.concat(_domainLanguages.pendingDomainLanguages);
      }

      return languages.filter(country => {
        if (Ember.isPresent(country.host)) {
          return country;
        }
      });
    }

  });

  _exports.default = _default;
});