define("@outdoorsyco/outdoorsy-addon/components/location-input/component", ["exports", "fetch", "@outdoorsyco/outdoorsy-addon/components/location-input/template", "ember-concurrency"], function (_exports, _fetch, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * location-input leverages the google places api using ember-google-maps
   */
  var _default = Ember.Component.extend({
    autocomplete: 'off'
    /* default autocomplete of the input to off until it's needed by google places api */
    ,
    countries: null,
    inputId: null,
    layout: _template.default,
    ref: null
    /* used exclusively in marketplace dashboard for places */
    ,
    tagName: '',
    types: '(regions)',
    intl: Ember.inject.service(),
    geocoder: Ember.inject.service(),
    googleMapsApi: Ember.inject.service(),
    google: Ember.computed.reads('googleMapsApi.google'),

    init() {
      this._super(...arguments);

      this.placeholder = this.placeholder || this.intl.t('ui-enter-a-location');
      this.prevCountries = [];
      Ember.set(this, 'inputId', `${Ember.guidFor(this)}--inputId`);
    },

    didInsertElement() {
      this._super(...arguments);

      this._addEvents();

      this._setupPlacesApiAndAutocomplete();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this._setupPlacesApiAndAutocomplete();
    },

    willDestroyElement() {
      if (Ember.isPresent(this.autocomplete)) {
        let evt = Ember.get(this, 'google.maps.event');

        if (evt) {
          evt.clearInstanceListeners(this.autocomplete);

          this._clearAutoCompleteElements();
        }
      }

      this._super(...arguments);
    },

    /**
     * @method _addEvents
     * @private
     * set up event listeners for the input and google pac menu
     */
    _addEvents() {
      let el = document.querySelector(`#${this.inputId}`);
      el.addEventListener('input', event => {
        Ember.tryInvoke(this, 'onQueryChanged', [event.target.value]);
      });
      el.addEventListener('submit', e => e.preventDefault());
    },

    /**
     * @method _setupPlacesApiAndAutocomplete
     * @private
     * do some setup work for the google places and autocomplete api
     */
    _setupPlacesApiAndAutocomplete() {
      let shouldUpdate = false;

      if (!Ember.isEmpty(this.countries)) {
        shouldUpdate = JSON.stringify(this.countries) !== JSON.stringify(this.prevCountries);
        Ember.set(this, 'prevCountries', this.countries);
      }

      if (this.mapautocomplete && !shouldUpdate) {
        return;
      }

      this._setupAutocomplete.perform();
    },

    /**
     * @method _setupAutocomplete
     * @private
     * intialize an Autocomplete object
     * attach places_changed event listener for Google's event fired on
     * selection of a new place in the pac (drop down) menu
     * calls Outdoorsy's geoip api to get place details
     * calls onPlaceDetailsSelected and passes it the parsed location data
     */
    _setupAutocomplete: (0, _emberConcurrency.task)(function* () {
      yield this.google;
      let el = document.querySelector(`#${this.inputId}`);
      let ref = this.ref;
      let types = this.types || '';
      let countries = this.countries || null;
      let places = Ember.get(this, 'google.maps.places');

      if (!el) {
        return;
      }

      let autocomplete = new places.Autocomplete(el, {
        types: Ember.makeArray(types),
        // limit the fields, so we don't query the Google places API
        // (instead use our own - see this._getPlaceDetails)
        // https://developers.google.com/maps/documentation/javascript/reference/places-widget#AutocompleteOptions.placeIdOnly
        fields: ['place_id', 'name', 'types']
      });

      if (countries && countries.length) {
        autocomplete.setComponentRestrictions({
          country: countries
        });
      }

      Ember.set(this, 'mapautocomplete', autocomplete);
      Ember.set(this, 'autocomplete', 'on');
      /**
       * place_changed fires both when a user clicks on a location in the pac menu
       * AND if they select a pac menu location and hit enter
       */

      autocomplete.addListener('place_changed', async () => {
        let place = autocomplete.getPlace(); // send the place name back up to the caller right away,
        // in case something goes wrong or the user submits before we get the details back

        Ember.tryInvoke(this, 'onQueryChanged', [place.name]);

        this._onPlaceChanged.perform(place, ref);
      });
    }),
    _onPlaceChanged: (0, _emberConcurrency.task)(function* (place, ref) {
      try {
        const placeDetails = yield this._getPlaceDetails.perform(place);

        if (!placeDetails.address_components || !placeDetails.geometry) {
          let placeName = placeDetails.formatted_address || placeDetails.name;

          this._getBounds(placeName);

          return;
        }

        let bounds = this.geocoder.getBounds(placeDetails);
        let location = this.geocoder.geocodeParsePlace(placeDetails);
        let {
          lat,
          lng
        } = location;
        this.onPlaceDetailsSelected({
          lat,
          lng,
          location,
          placeDetails,
          ref,
          bounds
        });
      } catch (error) {
        this.logger.error(error);
      }
    }),

    /**
     * @method _getPlaceDetails
     * @private
     * @param {*} place
     * Uses Outdoorsy's geoip service to fetch details for a place
     */
    _getPlaceDetails: (0, _emberConcurrency.task)(function* (place) {
      // if no place id, just return back what we have (probably just { name: ... })
      if (!place.place_id) {
        return place;
      }

      const response = yield (0, _fetch.default)(`https://geoip.outdoorsy.co/places/${place.place_id}`);
      const {
        result
      } = yield response.json();
      return result;
    }),

    /**
     * @method _getBounds
     * @private
     * @param {*} placeName
     * takes a plain place name and parses out coordinates for bounds
     */
    _getBounds(placeName) {
      this.geocoder.boundingBox(placeName).then(locationData => {
        let bounds = {
          neLat: locationData.northeast.lat,
          neLng: locationData.northeast.long,
          swLat: locationData.southwest.lat,
          swLng: locationData.southwest.long
        };
        let locationObj = locationData.location || {};
        let lng = locationObj.long;
        let {
          location,
          lat
        } = locationObj;
        const placeDetails = this.mapautocomplete.getPlace();
        let ref = this.ref;
        this.onPlaceDetailsSelected({
          lat,
          lng,
          location,
          placeDetails,
          ref,
          bounds
        });
      }).catch(() => {
        Ember.tryInvoke(this, 'onQueryChanged', [placeName]);
      });
    },

    /**
     * @method _clearAutoCompleteElements
     * @private
     * clean up the drop down location menu that is provided by Google's
     * api known as a pac-container
     */
    _clearAutoCompleteElements() {
      // clean up after google :(
      let pacContainers = document.querySelectorAll('.pac-container');

      for (let i = 0; pacContainers.length > i; i++) {
        pacContainers[i].parentNode.removeChild(pacContainers[i]);
      }
    }

  });

  _exports.default = _default;
});