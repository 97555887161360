define("ember-stripe-elements/services/stripev3", ["exports", "ember-stripe-elements/utils/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // As listed at https://stripe.com/docs/stripe-js/reference#the-stripe-object
  const STRIPE_FUNCTIONS = ['elements', 'createToken', 'createSource', 'createPaymentMethod', 'retrieveSource', 'paymentRequest', 'redirectToCheckout', 'retrievePaymentIntent', 'handleCardPayment', 'handleCardAction', 'confirmPaymentIntent', 'handleCardSetup', 'retrieveSetupIntent', 'confirmSetupIntent'];

  var _default = Ember.Service.extend({
    config: null,
    didConfigure: false,
    didLoad: false,
    lazyLoad: Ember.computed.readOnly('config.lazyLoad'),
    mock: Ember.computed.readOnly('config.mock'),
    publishableKey: null,

    init() {
      this._super(...arguments);

      this.set('publishableKey', this.get('config.publishableKey'));
      let lazyLoad = this.get('lazyLoad');

      if (!lazyLoad) {
        this.configure();
      }
    },

    load(publishableKey = null) {
      if (publishableKey) {
        this.set('publishableKey', publishableKey);
      }

      let lazyLoad = this.get('lazyLoad');
      let mock = this.get('mock');
      let shouldLoad = lazyLoad && !mock;
      let doLoad = shouldLoad ? (0, _loadScript.default)("https://js.stripe.com/v3/") : Ember.RSVP.resolve();
      return doLoad.then(() => {
        this.configure();
        this.set('didLoad', true);
      });
    },

    configure() {
      let didConfigure = this.get('didConfigure');

      if (!didConfigure) {
        let publishableKey = this.get('publishableKey');

        if (!publishableKey) {
          throw new Ember.Error("stripev3: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js");
        }

        let stripe = new Stripe(publishableKey);
        let functions = Ember.getProperties(stripe, STRIPE_FUNCTIONS);
        Ember.setProperties(this, functions);
        this.set('didConfigure', true);
      }
    }

  });

  _exports.default = _default;
});