define("@outdoorsyco/ember-shared-data/models/integration", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  let Integration = (_dec = Ember.inject.service, _dec2 = attr('string'), _dec3 = attr('string'), _dec4 = attr('string'), _dec5 = attr('string'), _dec6 = attr('string'), _dec7 = hasMany('tax', {
    async: true
  }), _dec8 = hasMany('localized-content', {
    async: true
  }), _dec9 = attr('number', {
    defaultValue: 1950
  }), _dec10 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec11 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec12 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec13 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec14 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec15 = attr('centsToDollars'), _dec16 = attr('string'), _dec17 = attr('string'), _dec18 = attr('number'), _dec19 = attr('string'), _dec20 = attr('boolean'), _dec21 = attr('centsToDollars'), _dec22 = attr('string'), _dec23 = attr('number'), _dec24 = attr('string'), _dec25 = attr('string'), _dec26 = attr('boolean'), _dec27 = attr('boolean', {
    default: false
  }), _dec28 = attr('centsToDollars'), _dec29 = attr('centsToDollars'), _dec30 = attr('boolean'), _dec31 = attr('boolean', {
    defaultValue: false
  }), _dec32 = attr('centsToDollars', {
    defaultValue: 0
  }), _dec33 = attr('boolean', {
    defaultValue: false
  }), _dec34 = attr('boolean'), _dec35 = attr('boolean'), _dec36 = attr('boolean'), _dec37 = attr({
    defaultValue() {
      return {};
    }

  }), _dec38 = attr('boolean'), _dec39 = attr('date'), _dec40 = attr('date'), _dec41 = Ember.computed.not('qualified'), _dec42 = Ember.computed('dealerPrice', 'userPremium'), _dec43 = Ember.computed('ownerPrice', 'userPremium'), _dec44 = Ember.computed('dealerPriceType'), _dec45 = Ember.computed('ownerPriceType'), _dec46 = Ember.computed('intl.locale', 'name', 'description', 'slug'), _dec47 = Ember.computed('intl.locale', 'description', 'slug'), (_class = class Integration extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "slug", _descriptor2, this);

      _initializerDefineProperty(this, "name", _descriptor3, this);

      _initializerDefineProperty(this, "description", _descriptor4, this);

      _initializerDefineProperty(this, "category", _descriptor5, this);

      _initializerDefineProperty(this, "chargeTo", _descriptor6, this);

      _initializerDefineProperty(this, "taxRates", _descriptor7, this);

      _initializerDefineProperty(this, "localizedContent", _descriptor8, this);

      _initializerDefineProperty(this, "minimumVehicleYear", _descriptor9, this);

      _initializerDefineProperty(this, "vehicleTypes", _descriptor10, this);

      _initializerDefineProperty(this, "countries", _descriptor11, this);

      _initializerDefineProperty(this, "regions", _descriptor12, this);

      _initializerDefineProperty(this, "renterRegionExclusions", _descriptor13, this);

      _initializerDefineProperty(this, "requiredFields", _descriptor14, this);

      _initializerDefineProperty(this, "ownerPrice", _descriptor15, this);

      _initializerDefineProperty(this, "ownerChargeType", _descriptor16, this);

      _initializerDefineProperty(this, "ownerPriceType", _descriptor17, this);

      _initializerDefineProperty(this, "ownerPercent", _descriptor18, this);

      _initializerDefineProperty(this, "ownerPercentType", _descriptor19, this);

      _initializerDefineProperty(this, "allowOwnerPremium", _descriptor20, this);

      _initializerDefineProperty(this, "dealerPrice", _descriptor21, this);

      _initializerDefineProperty(this, "dealerPriceType", _descriptor22, this);

      _initializerDefineProperty(this, "dealerPercent", _descriptor23, this);

      _initializerDefineProperty(this, "dealerPercentType", _descriptor24, this);

      _initializerDefineProperty(this, "dealerChargeType", _descriptor25, this);

      _initializerDefineProperty(this, "allowDealerPremium", _descriptor26, this);

      _initializerDefineProperty(this, "allowDealerToRequire", _descriptor27, this);

      _initializerDefineProperty(this, "total", _descriptor28, this);

      _initializerDefineProperty(this, "taxAmount", _descriptor29, this);

      _initializerDefineProperty(this, "dealer", _descriptor30, this);

      _initializerDefineProperty(this, "userDisabled", _descriptor31, this);

      _initializerDefineProperty(this, "userPremium", _descriptor32, this);

      _initializerDefineProperty(this, "userRequired", _descriptor33, this);

      _initializerDefineProperty(this, "isTaxable", _descriptor34, this);

      _initializerDefineProperty(this, "active", _descriptor35, this);

      _initializerDefineProperty(this, "qualified", _descriptor36, this);

      _initializerDefineProperty(this, "missingFields", _descriptor37, this);

      _initializerDefineProperty(this, "dealerQualifies", _descriptor38, this);

      _initializerDefineProperty(this, "created", _descriptor39, this);

      _initializerDefineProperty(this, "updated", _descriptor40, this);

      _initializerDefineProperty(this, "notQualified", _descriptor41, this);
    }

    get renterPriceType() {
      return this.dealer ? this.dealerPriceType : this.ownerPriceType;
    }

    get renterPercent() {
      return this.dealer ? this.dealerPercent : this.ownerPercent;
    }

    get renterPercentType() {
      return this.dealer ? this.dealerPercentType : this.ownerPercentType;
    }

    get renterChargeType() {
      return this.dealer ? this.dealerChargeType : this.ownerChargeType;
    }

    get totalDealerPrice() {
      return this.dealerPrice + Number(this.userPremium);
    }

    get totalOwnerPrice() {
      return this.ownerPrice + Number(this.userPremium);
    }

    get dailyDealerPrice() {
      return this.dealerPriceType === 'daily';
    }

    get dailyOwnerPrice() {
      return this.get('ownerPriceType') === 'daily';
    }

    get display() {
      let intl = this.intl;
      let name, description, enabledText, disabledText;

      switch (this.slug) {
        case 'esign_fee':
          name = intl.t('integration-esign-name');
          description = intl.t('integration-esign-description');
          enabledText = intl.t('integration-esign-on');
          disabledText = intl.t('integration-esign-off');
      }

      return {
        name: name || this.name,
        description: description || this.description,
        enabledText: enabledText || intl.t('integration-default-enabled'),
        disabledText: disabledText || intl.t('integration-default-disabled')
      };
    }

    get displayDescription() {
      let intl = this.intl;

      switch (this.slug) {
        case 'esign_fee':
          return intl.t('integration-esign-description');
      }

      return this.description;
    }

    rollbackTaxRates() {
      if (this.taxRates) {
        for (var i = 0; i < this.taxRates.length; i++) {
          let lc = this.taxRates.objectAt(i);
          lc.rollback();
        }
      }
    }

    rollbackLocalizedContent() {
      if (this.localizedContent) {
        for (var i = 0; i < this.localizedContent.length; i++) {
          let lc = this.localizedContent.objectAt(i);
          lc.rollback();
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "chargeTo", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "taxRates", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "localizedContent", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "minimumVehicleYear", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "vehicleTypes", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "countries", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "regions", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "renterRegionExclusions", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "requiredFields", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "ownerPrice", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "ownerChargeType", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "ownerPriceType", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "ownerPercent", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "ownerPercentType", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "allowOwnerPremium", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "dealerPrice", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "dealerPriceType", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "dealerPercent", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "dealerPercentType", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "dealerChargeType", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "allowDealerPremium", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "allowDealerToRequire", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "total", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "taxAmount", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "dealer", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "userDisabled", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "userPremium", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "userRequired", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "isTaxable", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "active", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "qualified", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "missingFields", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "dealerQualifies", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "created", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "updated", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "notQualified", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "totalDealerPrice", [_dec42], Object.getOwnPropertyDescriptor(_class.prototype, "totalDealerPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalOwnerPrice", [_dec43], Object.getOwnPropertyDescriptor(_class.prototype, "totalOwnerPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dailyDealerPrice", [_dec44], Object.getOwnPropertyDescriptor(_class.prototype, "dailyDealerPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dailyOwnerPrice", [_dec45], Object.getOwnPropertyDescriptor(_class.prototype, "dailyOwnerPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "display", [_dec46], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayDescription", [_dec47], Object.getOwnPropertyDescriptor(_class.prototype, "displayDescription"), _class.prototype)), _class));
  _exports.default = Integration;
});