define("@outdoorsyco/ember-shared-data/adapters/line-item", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LineItem extends _application.default {
    pathForType() {
      return ':parentType/:parentTypeId/items';
    }

    buildURL(type, id, snapshot) {
      let url = super.buildURL(type, id, snapshot);

      if (snapshot && snapshot.belongsTo('booking', {
        id: true
      })) {
        return url.replace(':parentType/:parentTypeId', `bookings/${snapshot.belongsTo('booking', {
          id: true
        })}`);
      }

      if (snapshot && snapshot.belongsTo('consignedPayout', {
        id: true
      })) {
        return url.replace(':parentType/:parentTypeId/items', `consigned-payouts/${snapshot.belongsTo('consignedPayout', {
          id: true
        })}/consigned-payout-items`);
      }

      return url.replace(':parentType/:parentTypeId/', '');
    }

  }

  _exports.default = LineItem;
});