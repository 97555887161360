define("ember-google-maps/components/g-map/info-window", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/info-window", "ember-google-maps/utils/options-and-events", "ember-google-maps/utils/helpers"], function (_exports, _mapComponent, _infoWindow, _optionsAndEvents, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.InfoWindowAPI = InfoWindowAPI;
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function InfoWindowAPI(source) {
    let mapComponentAPI = (0, _mapComponent.MapComponentAPI)(source);
    return (0, _mapComponent.combine)(mapComponentAPI, {
      actions: {
        open: () => source.open(),
        close: () => source.close()
      }
    });
  }
  /**
   * A wrapper for the google.maps.InfoWindow class.
   *
   * @class InfoWindow
   * @namespace GMap
   * @module ember-google-maps/components/g-map/info-window
   * @extends GMap.MapComponent
   */


  var _default = _mapComponent.default.extend({
    layout: _infoWindow.default,
    _type: 'infoWindow',
    isOpen: false,
    _cachedIsOpen: false,
    position: Ember.computed('lat', 'lng', _helpers.position),
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([..._optionsAndEvents.ignoredOptions, 'isOpen', 'target', 'content']),

    _createOptions(options) {
      let newOptions = {
        content: undefined
      };

      if (!Ember.get(this, 'target')) {
        newOptions.position = Ember.get(this, 'position');
      }

      if (Ember.get(this, 'isOpen')) {
        newOptions.content = this._getContent();
      }

      return _objectSpread(_objectSpread({}, options), newOptions);
    },

    init() {
      this._super(...arguments);

      this.publicAPI = InfoWindowAPI(this);
    },

    _addComponent(options) {
      return Ember.RSVP.resolve(Ember.set(this, 'mapComponent', new google.maps.InfoWindow(options)));
    },

    _didAddComponent() {
      this._openOrClose();

      this._super(...arguments);
    },

    _updateComponent(mapComponent, options) {
      mapComponent.setOptions(options);

      this._openOrClose();
    },

    _openOrClose() {
      let isOpen = Ember.get(this, 'isOpen');
      let isOpenChanged = this._cachedIsOpen !== isOpen;

      if (isOpenChanged && isOpen) {
        this.open();
      } else if (isOpenChanged && !isOpen) {
        this.close();
      }

      Ember.set(this, '_cachedIsOpen', isOpen);
    },

    _getContent() {
      if (this.content) {
        return this.content;
      }

      let content = document.createElement('div');
      Ember.set(this, '_targetPane', content);
      Ember.set(this, 'content', content);
      return content;
    },

    open() {
      if (this.mapComponent) {
        google.maps.event.addListenerOnce(this.mapComponent, 'closeclick', () => {
          Ember.set(this, 'isOpen', false);
        });
        this.mapComponent.open(Ember.get(this, 'map'), Ember.get(this, 'target'));
      }
    },

    close() {
      if (this.mapComponent) {
        this.mapComponent.close();
      }
    }

  });

  _exports.default = _default;
});