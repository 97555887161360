define("@outdoorsyco/outdoorsy-addon/helpers/asset-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    assetMap: Ember.inject.service(),

    compute([fileUrl]) {
      if (Ember.isEmpty(fileUrl)) {
        return;
      }

      return this.assetMap.resolve(fileUrl);
    }

  });

  _exports.default = _default;
});