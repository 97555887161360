define("@outdoorsyco/ember-switchback/components/input-error/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Shows an error message for an input
   *
   * ```handlebars
   * TODO
   * ```
   * @public
   */
  class InputError extends _component.default {}

  _exports.default = InputError;
});