define("@outdoorsyco/ember-shared-data/components/modals/collect-fees-services-v2/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cf-modal-layout": "_cf-modal-layout_1u2t8p",
    "cf-notice": "_cf-notice_1u2t8p",
    "cf-section-container": "_cf-section-container_1u2t8p",
    "cf-section-desc": "_cf-section-desc_1u2t8p",
    "cf-checkbox-row": "_cf-checkbox-row_1u2t8p",
    "cf-drawer-container": "_cf-drawer-container_1u2t8p",
    "cf-penalty-container": "_cf-penalty-container_1u2t8p",
    "cf-checkbox": "_cf-checkbox_1u2t8p",
    "cf-checkbox-desc": "_cf-checkbox-desc_1u2t8p",
    "cf-input-holder": "_cf-input-holder_1u2t8p",
    "mb-12": "_mb-12_1u2t8p",
    "mb-16": "_mb-16_1u2t8p",
    "cf-action-bar": "_cf-action-bar_1u2t8p"
  };
  _exports.default = _default;
});