define("@outdoorsyco/ember-shared-data/components/driving-check-modal/component", ["exports", "@outdoorsyco/ember-shared-data/components/driving-check-modal/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,

    init() {
      this._super(...arguments);

      let passedVars = this.get('attrs.data.value') || {};
      this.firstName = passedVars.firstName || '';
      this.lastName = passedVars.lastName || '';
      this.email = passedVars.email || '';
      this.booking = passedVars.booking || null;
      this.user = passedVars.user || null;
      this.driverCheckOnly = passedVars.driverCheckOnly || false;
    },

    actions: {
      cancelIdentityCheck() {
        this.modal.close();
      },

      identityCheckComplete() {
        if (this.user && typeof this.user.then === 'function') {
          this.get('user').then(user => user.reload());
        } else if (this.user) {
          this.get('user').reload();
        }

        this.modal.close();
      }

    }
  });

  _exports.default = _default;
});