define('@outdoorsyco/ember-initials/services/ember-initials-store', ['exports', '@outdoorsyco/ember-initials/utils/generators/svg'], function (exports, _svg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    cache: null,

    generator: Ember.computed(function () {
      return new _svg.default();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('cache', {});
    },
    removeAll: function removeAll() {
      var _this = this;

      Object.keys(this.get('cache')).forEach(function (key) {
        return _this.get('generator').revoke(key);
      });
      this.set('cache', {});
    },
    initialsFor: function initialsFor(properties) {
      var key = properties.backgroundColor + '-' + properties.initials + '-' + properties.initialsColor;
      return this.get('cache')[key] || this._create(key, properties);
    },
    _create: function _create(key, properties) {
      return this.get('cache')[key] = this.get('generator').generate(properties);
    }
  });
});