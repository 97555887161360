define("@outdoorsyco/ember-shared-data/components/location-address-form/component", ["exports", "@outdoorsyco/ember-shared-data/components/location-address-form/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['address-form'],
    classNameBindings: ['loading:disabled'],
    intl: Ember.inject.service(),
    street: null,
    city: null,
    state: null,
    zip: null,
    lat: null,
    lng: null,
    loading: false,
    geocodeScope: 'address',
    allowFocusOutSearch: false,
    placeholder: Ember.computed('intl.locale', function () {
      return this.get('intl').t('comp-location-address-form-type-to-search-your-location');
    }),
    searchValue: Ember.computed('street', 'city', 'state', function () {
      let {
        street,
        city,
        state
      } = this.getProperties(['street', 'city', 'state']);
      return [street, city, state].filter(item => !Ember.isBlank(item)).join(', ');
    }),
    actions: {
      foundLocation(lat, lng, location, place) {
        let placeNumber = place.address_components.filter(a => a.types.includes('street_number'));
        let placeStreet = place.address_components.filter(a => a.types.includes('route'));
        let placePostal = place.address_components.filter(a => a.types.includes('postal_code'));
        let number = placeNumber.length ? placeNumber[0].long_name : null;
        let street = placeStreet.length ? placeStreet[0].short_name : null;
        let fullStreet = [number, street].filter(item => !Ember.isBlank(item)).join(' ');
        this.sendAction('addressChange', {
          street1: fullStreet,
          // fallback compatibility with some models still using street1
          street: fullStreet,
          state: location.state,
          city: location.city,
          lat,
          lng,
          zip: placePostal.length ? placePostal[0].long_name : null
        });
      }

    }
  });

  _exports.default = _default;
});