define("@outdoorsyco/ember-switchback/components/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WwRGc0ry",
    "block": "{\"symbols\":[\"@placement\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"open\",\"isOpen\",\"Container\"],[[23,0,[\"open\"]],[23,0,[\"isOpen\"]],[28,\"if\",[[28,\"eq\",[[23,0,[\"switchback\",\"layout\"]],\"desktop\"],null],[28,\"component\",[\"popup\"],[[\"placement\",\"show\",\"relativeTo\",\"onDismiss\"],[[28,\"or\",[[23,1,[]],\"bottom-end\"],null],[23,0,[\"isOpen\"]],[23,0,[\"targetElement\"]],[23,0,[\"close\"]]]]],[28,\"component\",[\"slideup\"],[[\"show\",\"onDismiss\"],[[23,0,[\"isOpen\"]],[23,0,[\"close\"]]]]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});