define("@outdoorsyco/ember-shared-data/models/quote-request", ["exports", "ember-data", "ember-data-storefront/mixins/loadable-model"], function (_exports, _emberData, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  /**
   * A request for quotes sent by a renter. Also known as a bid or an
   * instamatch request.
   */
  let QuoteRequest = (_dec = belongsTo('user', {
    inverse: null
  }), _dec2 = belongsTo('rental', {
    inverse: null
  }), _dec3 = attr(), _dec4 = attr('simpleDate'), _dec5 = attr('simpleDate'), _dec6 = attr('number'), _dec7 = attr('centsToDollars'), _dec8 = attr('centsToDollars'), _dec9 = attr('string'), _dec10 = attr('string'), _dec11 = attr(), _dec12 = attr(), _dec13 = attr('string'), _dec14 = attr(), _dec15 = attr(), _dec16 = attr('boolean'), _dec17 = attr('boolean'), _dec18 = attr('boolean'), _dec19 = attr('string'), _dec20 = attr('string'), _dec21 = attr('centsToDollars'), _dec22 = attr('number', {
    defaultValue: 0.25
  }), _dec23 = attr('string'), _dec24 = attr('string'), _dec25 = Ember.computed('bidMatches.[]'), _dec26 = Ember.computed('quality'), (_class = class QuoteRequest extends Model.extend(_loadableModel.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "owner", _descriptor, this);

      _initializerDefineProperty(this, "rental", _descriptor2, this);

      _initializerDefineProperty(this, "bidMatches", _descriptor3, this);

      _initializerDefineProperty(this, "from", _descriptor4, this);

      _initializerDefineProperty(this, "to", _descriptor5, this);

      _initializerDefineProperty(this, "travelers", _descriptor6, this);

      _initializerDefineProperty(this, "maxDayPrice", _descriptor7, this);

      _initializerDefineProperty(this, "minDayPrice", _descriptor8, this);

      _initializerDefineProperty(this, "type", _descriptor9, this);

      _initializerDefineProperty(this, "comments", _descriptor10, this);

      _initializerDefineProperty(this, "pickupLat", _descriptor11, this);

      _initializerDefineProperty(this, "pickupLng", _descriptor12, this);

      _initializerDefineProperty(this, "destination", _descriptor13, this);

      _initializerDefineProperty(this, "destLat", _descriptor14, this);

      _initializerDefineProperty(this, "destLng", _descriptor15, this);

      _initializerDefineProperty(this, "petFriendly", _descriptor16, this);

      _initializerDefineProperty(this, "sent", _descriptor17, this);

      _initializerDefineProperty(this, "open", _descriptor18, this);

      _initializerDefineProperty(this, "year", _descriptor19, this);

      _initializerDefineProperty(this, "make", _descriptor20, this);

      _initializerDefineProperty(this, "pricePerDay", _descriptor21, this);

      _initializerDefineProperty(this, "quality", _descriptor22, this);

      _initializerDefineProperty(this, "name", _descriptor23, this);

      _initializerDefineProperty(this, "email", _descriptor24, this);
    }

    get bookingId() {
      if (Ember.isArray(this.get('bidMatches'))) {
        return this.get('bidMatches.firstObject.booking_id');
      }

      return null;
    }

    get qualityPercent() {
      let percentage = parseInt(this.get('quality') * 100, 10);
      return percentage;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rental", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "bidMatches", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "from", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "to", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "travelers", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "maxDayPrice", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "minDayPrice", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "comments", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "pickupLat", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "pickupLng", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "destination", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "destLat", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "destLng", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "petFriendly", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "sent", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "open", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "year", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "make", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "pricePerDay", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "quality", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "bookingId", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "bookingId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "qualityPercent", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "qualityPercent"), _class.prototype)), _class));
  _exports.default = QuoteRequest;
});