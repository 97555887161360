define("@outdoorsyco/outdoorsy-addon/components/additional-fee-breakdown/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/additional-fee-breakdown/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    session: Ember.inject.service(),
    classNames: ['additional-fee-breakdown'],
    booking: null,
    hints: true,
    currentCurrency: Ember.computed('session.currentCurrency', 'booking.{isQuote,localeBaseCurrency}', function () {
      if (this.get('booking.isQuote')) {
        return this.get('session.currentCurrency') || this.get('booking.localeBaseCurrency') || 'USD';
      }

      return this.get('booking.localeBaseCurrency') || 'USD';
    }),
    isServiceFee: Ember.computed('booking.renter', function () {
      let user = this.get('booking.renter');

      if (!user) {
        return true;
      }

      return user.get('outdoorsyUser');
    }),
    lineItems: Ember.computed('booking.{lineItems.[],lines.[],isQuote}', function () {
      if (this.get('booking.isQuote')) {
        return this.get('booking.lines') || [];
      }

      return this.get('booking.lineItems') || [];
    }),
    filteredLineItems: Ember.computed('lineItems.@each.{rentalAmount,coachnetItem,isMileageItem,isGeneratorItem,total}', function () {
      return this.get('lineItems').rejectBy('isVariableOrNote', true).reduce((arr, item) => {
        item = this.get('booking.isQuote') ? Ember.Object.create(item) : item;
        let mileGenItem = item.get('isMileageItem') || item.get('isGeneratorItem');
        let mileGenItemHasEmptyTotal = mileGenItem && item.get('total') === 0;

        if (!item.get('rentalAmount') && !item.get('coachnetItem') && !mileGenItemHasEmptyTotal) {
          arr.push(item);
        }

        return arr;
      }, []);
    }),
    generatorUsageItem: Ember.computed('rental.generatorUsageItem.included', function () {
      return this.get('rental.generatorUsageItem');
    }),
    mileageUsageItem: Ember.computed('rental.mileageUsageItem.included', function () {
      return this.get('rental.mileageUsageItem');
    }),
    deferredItems: Ember.computed('booking.{deferred.[],deferredItems.[],isQuote}', function () {
      if (this.get('booking.isQuote')) {
        return this.get('booking.deferred') || [];
      }

      return this.get('booking.deferredItems') || [];
    }),
    coachNetLineItem: Ember.computed('lineItems.[]', function () {
      return this.get('lineItems').findBy('coachnetItem', true);
    }),
    serviceFee: Ember.computed('booking.originalServiceFee', function () {
      return this.get('booking.originalServiceFee');
    })
  });

  _exports.default = _default;
});