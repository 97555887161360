define("@outdoorsyco/ember-shared-data/components/user/address/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "UserAddress": "_UserAddress_1tv3ob"
  };
  _exports.default = _default;
});