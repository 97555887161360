define("@outdoorsyco/outdoorsy-addon/components/location-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ycEv2JJ",
    "block": "{\"symbols\":[\"t\",\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"  \"],[14,3,[[28,\"hash\",null,[[\"inputId\",\"data-test-location-input\",\"place\"],[[23,0,[\"inputId\"]],true,[23,0,[\"place\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"text-input\",[[12,\"data-test-location-input\",\"true\"],[12,\"placeholder\",[22,\"placeholder\"]],[13,2]],[[\"@id\",\"@value\",\"@onChange\"],[[23,0,[\"inputId\"]],[23,0,[\"value\"]],[28,\"fn\",[[28,\"mut\",[[23,0,[\"value\"]]],null]],null]]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"Icon\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"gps-tracking-default\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/location-input/template.hbs"
    }
  });

  _exports.default = _default;
});