define("ember-mobile-pane/templates/components/mobile-pane/pervious", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rZ15uqo2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"mobile-pane__previous \",[28,\"if\",[[24,[\"isDisabled\"]],\"mobile-pane__previous-disabled\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\",[24,[\"activeIndex\"]]],null]],[10,\"role\",\"button\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mobile-pane/templates/components/mobile-pane/pervious.hbs"
    }
  });

  _exports.default = _default;
});