define("@outdoorsyco/ember-switchback/components/select-time/label/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Renders the default label for SelectTime by converting
   * seconds into a human readable label.
   *
   * @private
   */
  class SelectTime extends _component.default {
    get label() {
      let date = new Date(null);
      date.setHours(0, 0, 0, 0);
      date.setSeconds(this.args.seconds);
      return new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric'
      }).format(date);
    }

  }

  _exports.default = SelectTime;
});