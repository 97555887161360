define("@outdoorsyco/form-components/components/select-field/component", ["exports", "@outdoorsyco/form-components/components/select-field/template", "@outdoorsyco/form-components/components/animated-select-field/template"], function (_exports, _template, _template2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    options: null,
    value: null,
    error: null,
    disabled: false,
    showErrors: false,
    allowCreate: false,
    searchEnabled: true,
    searchField: 'name',
    placeholder: null,
    dropdownClass: null,
    allowClear: false,
    // internal
    formElementId: null,
    hasError: Ember.computed.and('showErrors', 'error.message'),

    init() {
      this._super(...arguments);

      this.set('formElementId', `id-${this.get('elementId')}`);

      if (this.get('new')) {
        this.set('layout', _template2.default);
      } else {
        this.set('layout', _template.default);
      }
    },

    actions: {
      onChange(value) {
        this.sendAction('onChange', value);
      },

      onCreate(value) {
        this.sendAction('onChange', value);
      },

      onInput(value) {
        this.sendAction('onInput', value);
      }

    }
  });

  _exports.default = _default;
});