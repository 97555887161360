define("@outdoorsyco/ember-shared-data/services/session", ["exports", "ember-data", "ember-concurrency", "@sentry/browser", "ember-simple-auth/services/session", "@outdoorsyco/ember-shared-data/utils/domain-languages"], function (_exports, _emberData, _emberConcurrency, _browser, _session, _domainLanguages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    PromiseObject
  } = _emberData.default;
  const COOKIEAGE = 365 * 24 * 60 * 60;

  var _default = _session.default.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    cookies: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    apiRequest: Ember.inject.service(),
    redirectAfterAuthentication: false,
    routeAfterInvalidation: null,

    init() {
      this._super(...arguments);

      this.config = Ember.getOwner(this).resolveRegistration('config:environment');
    },

    userId: Ember.computed.readOnly('data.authenticated.user_id'),
    hasAuthenticatedUserId: Ember.computed.and('isAuthenticated', 'userId'),

    async loadUser() {
      return await this.currentUserService.loadUser();
    },

    /**
     * DEPRECATED
     * A Promise Object that is an unresolved promise while the current user is fetching,
     * and the resolved user afterwards
     */
    currentUser: Ember.computed.readOnly('currentUserService.currentUser'),

    /**
     * A promise set by loadUser() that resolves when the current user is fetched
     * This allows you to manually wait before accessing the user,
     * if you are in a route that is not blocked by the promise:
     *
     * ```js
     * try {
     *   await this.session.userPromise;
     *   const userName = this.session.user.name;
     * } catch (error) {
     *   // If the user fetch had failed, will arrive here. The error was already handled,
     *   // so you just need to deal with the lack of user data
     * }
     * ```
     */
    userPromise: Ember.computed.readOnly('currentUserService.userPromise'),

    /**
     * the current authenticated user, as an Ember Data object
     * gets set when loadUser() resolves
     */
    user: Ember.computed.readOnly('currentUserService.user'),
    currentDistanceUnit: Ember.computed('currentUser.localeDistanceUnit', 'intl.locale', function () {
      let defaultUnit = 'miles';
      let userUnit = this.get('isAuthenticated') ? this.get('currentUser.localeDistanceUnit') : null;
      let locale = this.get('intl.locale') || ['en-us'];
      let domainUnit = (0, _domainLanguages.getDistanceUnitFromLocale)(locale[0]);
      return userUnit || domainUnit || defaultUnit;
    }),
    currentCurrency: Ember.computed('currentUser.localeBaseCurrency', 'userLocaleCurrency', 'intl.locale', function () {
      let defaultCurrency = 'USD';
      let userCurrency = this.get('isAuthenticated') ? this.get('currentUser.localeBaseCurrency') : null;
      let sessionCurrency = this.get('userLocaleCurrency.currency');
      let locale = this.get('intl.locale') || ['en-us'];
      let domainCurrency = (0, _domainLanguages.getCurrencyFromLocale)(locale[0]);
      return userCurrency || sessionCurrency || domainCurrency || defaultCurrency;
    }),
    currentCurrencyLocale: Ember.computed('currentUser.localeBaseCurrency', 'userLocaleCurrency', function () {
      let defaultCurrencyLocale = 'en-us';
      let userCurrencyLocale = this.get('isAuthenticated') ? this.get('currentUser.localeLanguage') : null;
      let sessionCurrencyLocale = this.get('userLocaleCurrency.locale');
      let domainCurrencyLocale = defaultCurrencyLocale;
      let host = window && window.location && window.location.host ? window.location.host : false;

      if (host) {
        let opts = _domainLanguages.domainLanguages[host] || {};
        let langs = opts.langs || [];
        domainCurrencyLocale = langs[0] || false;
      }

      return (sessionCurrencyLocale || userCurrencyLocale || domainCurrencyLocale || defaultCurrencyLocale).toLowerCase();
    }),
    refCode: Ember.computed('cookies.refCode', 'data.refCode', function () {
      let refCode = this.get('cookies').read('refCode');
      return refCode ? refCode : this.get('data.refCode');
    }),
    discountCode: Ember.computed('cookies.discountCode', 'data.discountCode', function () {
      const discountCode = this.get('cookies').read('discountCode');
      return discountCode ? discountCode : this.get('data.discountCode');
    }),
    userSource: Ember.computed('cookies.userSource', 'data.userSource', function () {
      let userSource = this.get('cookies').read('userSource');
      userSource = userSource || this.get('data.userSource');

      if (typeof userSource === 'string') {
        return JSON.parse(userSource);
      }

      return userSource;
    }),
    userLocaleCurrency: Ember.computed('cookies.userlocaleCurrency', 'data.userlocaleCurrency', function () {
      let userlocaleCurrency = this.get('cookies').read('userLocaleCurrency');
      userlocaleCurrency = userlocaleCurrency || this.get('data.userLocaleCurrency');

      if (typeof userlocaleCurrency === 'string') {
        try {
          return JSON.parse(userlocaleCurrency);
        } catch (_unused) {
          return {};
        }
      }

      return userlocaleCurrency || {};
    }),
    storedKey: Ember.computed('data.authenticated.token', function () {
      return this.get('data.authenticated.token');
    }),
    actorId: Ember.computed('data.actorId', function () {
      return this.get('data.actorId');
    }),
    authKey: Ember.computed('storedKey', function () {
      let key = this.get('storedKey');
      return key ? key.replace('guest:', '') : key;
    }),
    loggedIn: Ember.computed('isAuthenticated', 'userId', function () {
      return this.get('isAuthenticated') === true && this.get('userId') !== undefined;
    }),
    currentAccount: Ember.computed('user.ownedById', function () {
      return PromiseObject.create({
        promise: new Ember.RSVP.Promise((resolve, reject) => {
          const currentUserPromise = this.userPromise;

          if (!currentUserPromise) {
            // session.loadUser() was never called, so we won't have a user. resolve nothing
            return resolve(null);
          }

          currentUserPromise.then(user => {
            if (!user) {
              resolve(null);
            } else if (user.get('ownedById')) {
              resolve(this.get('store').find('user', user.get('ownedById')));
            } else {
              resolve(this.get('store').find('user', user.get('id')));
            }
          }).catch(error => {
            reject(error);
          });
        })
      });
    }),
    isGuest: Ember.computed('storedKey', 'user.guest', function () {
      return this.loggedIn && (Ember.get(this, 'user.guest') || this.storedKey.indexOf('guest:') === 0);
    }),
    isRealUser: Ember.computed('storedKey', 'loggedIn', 'isGuest', function () {
      return this.get('loggedIn') && !this.get('isGuest');
    }),

    userLoggedIn(userId, token, options) {
      let expirationTime;

      if (options) {
        if (options.session) {
          expirationTime = 'session-only';
          this.set('store.cookieExpirationTime', expirationTime);
        }

        if (options.actorId && options.actorId !== userId) {
          this.set('data.actorId', options.actorId);
        }
      }

      if (this.get('isAuthenticated') && (this.get('userId') !== userId || this.get('storedKey') !== token)) {
        /* We want guests to reload the page. */
        if (!this.get('isGuest')) {
          this.set('userReAuth', true);
        }

        this.invalidate();
      }

      return this.authenticate('authenticator:outdoorsy', null, null, null, userId, token, options);
    },

    setReferralCode(code) {
      this.set('data.refCode', code);
      this.get('cookies').write('refCode', code, {
        maxAge: COOKIEAGE
      });
    },

    setUserSource(source) {
      source = JSON.stringify(source);
      this.set('data.userSource', source);
      this.get('cookies').write('userSource', source, {
        maxAge: COOKIEAGE
      });
    },

    setDiscountCode(discountCode) {
      this.set('data.discountCode', discountCode);
      this.get('cookies').write('discountCode', discountCode, {
        maxAge: COOKIEAGE
      });
    },

    removeDiscountCode() {
      this.set('data.discountCode', undefined);
      this.get('cookies').clear('discountCode');
    },

    setUserLocaleCurrency(localeHash) {
      let locale = JSON.stringify(localeHash);
      this.set('data.userLocaleCurrency', locale);
      this.get('cookies').write('userLocaleCurrency', locale, {
        path: '/',
        maxAge: COOKIEAGE
      });
    },

    async logout() {
      if (this.get('isAuthenticated')) {
        // reset Sentry user context
        (0, _browser.configureScope)(scope => {
          scope.setUser(null);
        });
        return this.invalidate();
      }

      return Ember.RSVP.resolve();
    },

    updateLocaleCurrency(options) {
      this.setUserLocaleCurrency(options);
      return false;
    },

    updateCurrency(currency) {
      this.setUserLocaleCurrency({
        currency: currency
      });
      window.location.reload();
      window.scrollTo(0, 0);
      return false;
    },

    updateCountry(country) {
      this.get('_updateCountry').perform(country);
    },

    _updateCountry: (0, _emberConcurrency.task)(function* (country) {
      let promise = Ember.RSVP.resolve();

      if (this.userId) {
        let user = yield this.get('currentUser');
        let locale = country.langs[0] || 'en-US';
        user.set('localeLanguage', locale);
        yield user.save();
      }

      promise.then(() => {
        let environment = this.get('config.environment');
        let host = environment !== 'staging' ? country.host : country.stagingHost;
        let link = `${host.replace(/\/$/, '')}${window.location.pathname}${window.location.search}`;
        window.location.href = link;
      });
    })
  });

  _exports.default = _default;
});