define("@outdoorsyco/ember-shared-data/serializers/usage-based-item", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class UsageBasedItemSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        taxRates: {
          serialize: 'ids',
          deserialize: 'ids'
        },
        tiers: {
          deserialize: 'records',
          serialize: false
        }
      });
    }

    keyForRelationship(key, relationship) {
      // images are embedded and therefor are NOT sent with an ID
      if (key === 'tiers') {
        return key;
      }

      return super.keyForRelationship(key, relationship);
    } // Model -> what the API expects


    serialize(record, options) {
      let hash = super.serialize(record, options);
      let tax_rate_ids = hash.tax_rates || [];
      hash.tax_rate_ids = tax_rate_ids.map(tr => parseInt(tr));
      delete hash.tax_rates; // delete hash.tax_rate_id;

      return hash;
    } // API -> what the model expects


    normalize(typeClass, hash) {
      let tax_rate_ids = hash.tax_rate_ids || [];
      hash.tax_rates = tax_rate_ids;

      if (hash.tax_rate_id === 0) {
        delete hash.tax_rate_id;
      }

      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = UsageBasedItemSerializer;
});