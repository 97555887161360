define("@outdoorsyco/ember-shared-data/utils/scroll-into-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollIntoView;

  function scrollIntoView({
    element,
    container,
    offset = 200,
    timeout = 150
  }) {
    (false && !(element) && Ember.assert('You must pass an element in order to scroll it into place', element));
    Ember.run.later(() => {
      // scrolls smoothly to the element for evergreen browsers, jumps to element for IE and safari
      let offsetValue = getOffsetRelativeToContainer(element, container) - offset;
      let elContainer = container ? container : window;
      elContainer.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: offsetValue
      });
    }, timeout);
  }
  /**
   * Iterates up the element parent chain adding the relative offsets until reaching
   * the body (or the container in the case where the body is not the element to be scrolled)
   * @param {HTMLElement} element
   */


  function getOffsetRelativeToContainer(element, container) {
    let cumulativeOffset = 0;

    while (element && element.tagName !== 'BODY') {
      if (element && element === container) {
        return cumulativeOffset;
      }

      cumulativeOffset += element.offsetTop;
      element = element.offsetParent;
    }

    return cumulativeOffset;
  }
});