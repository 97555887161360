define("@outdoorsyco/ember-shared-data/models/image", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/helpers/image-formatter"], function (_exports, _emberData, _imageFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /* eslint no-useless-escape: "off" */
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  let ImageModel = (_dec = belongsTo('rental', {
    inverse: 'images'
  }), _dec2 = attr('string'), _dec3 = attr('number'), _dec4 = attr('boolean'), _dec5 = attr('string'), _dec6 = attr('string'), _dec7 = attr('boolean'), _dec8 = attr('string'), _dec9 = attr('string'), _dec10 = attr('boolean'), _dec11 = attr('boolean'), _dec12 = attr('string'), _dec13 = attr('boolean'), (_class = class ImageModel extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "rental", _descriptor, this);

      _initializerDefineProperty(this, "url", _descriptor2, this);

      _initializerDefineProperty(this, "position", _descriptor3, this);

      _initializerDefineProperty(this, "primary", _descriptor4, this);

      _initializerDefineProperty(this, "categoryName", _descriptor5, this);

      _initializerDefineProperty(this, "categorySlug", _descriptor6, this);

      _initializerDefineProperty(this, "skipEnhance", _descriptor7, this);

      _initializerDefineProperty(this, "title", _descriptor8, this);

      _initializerDefineProperty(this, "description", _descriptor9, this);

      _initializerDefineProperty(this, "video", _descriptor10, this);

      _initializerDefineProperty(this, "best", _descriptor11, this);

      _initializerDefineProperty(this, "status", _descriptor12, this);

      _initializerDefineProperty(this, "ignoreRejected", _descriptor13, this);
    }

    get width1500() {
      return this.generateUrl('width1500');
    }

    get width1000() {
      return this.generateUrl('width1000');
    }

    get width750() {
      return this.generateUrl('width750');
    }

    get width500() {
      return this.generateUrl('width500');
    }

    get square750() {
      return this.generateUrl('square750');
    }

    get square500() {
      return this.generateUrl('square500');
    }

    get square250() {
      return this.generateUrl('square250');
    }

    generateUrl(size) {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: size,
        skipEnhance: this.skipEnhance,
        category: this.categorySlug,
        skipWatermark: true
      });
    } // Legacy sizes


    get thumbnail() {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: 'thumbnail',
        skipEnhance: this.skipEnhance,
        category: this.categorySlug
      });
    }

    get tinyThumbnail() {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: 'tinyThumbnail',
        skipEnhance: this.skipEnhance,
        category: this.categorySlug
      });
    }

    get listing() {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: 'listing',
        skipEnhance: this.skipEnhance,
        category: this.categorySlug,
        skipWatermark: true
      });
    }

    get featured() {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: 'featured',
        skipEnhance: true,
        category: this.categorySlug,
        skipWatermark: true
      });
    }

    get social() {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: 'social',
        skipEnhance: true,
        cateogry: this.categorySlug
      });
    }

    get coverStyle() {
      return `background: url(${this.thumbnail}) no-repeat center center;background-size:cover;`;
    }

    get shadedBackground() {
      let u = this.url;
      return u.replace('/outdoorsy/image/upload', '/outdoorsy/image/upload/e_brightness:-22,c_scale,e_blur:1019,q_20,w_70');
    }

    get youtubeId() {
      if (this.video && this.url) {
        let reg = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        let match = this.url.match(reg);

        if (match && match[1]) {
          return match[1];
        }
      }

      return '';
    }

    get isRejected() {
      return this.status === 'rejected';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "rental", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "position", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "primary", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "categoryName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "categorySlug", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "skipEnhance", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "video", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "best", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "ignoreRejected", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ImageModel;
});