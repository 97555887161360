define("@outdoorsyco/ember-shared-data/components/prompt/add-passenger/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_1pqxot",
    "description": "_description_1pqxot",
    "icon-badge": "_icon-badge_1pqxot",
    "icon": "_icon_1pqxot"
  };
  _exports.default = _default;
});