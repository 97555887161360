define("calendar-picker/components/power-calendar-range", ["exports", "ember-power-calendar/components/power-calendar-range"], function (_exports, _powerCalendarRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _powerCalendarRange.default.extend({
    tagName: 'div',
    attributeBindings: ['style'],
    style: Ember.computed('calendarStyle', function () {
      let style = this.get('calendarStyle');

      if (!Ember.isBlank(style) && style) {
        return Ember.String.htmlSafe(style);
      }

      return null;
    }),

    init() {
      this._super(...arguments);

      this.exposeCalendar = this;
      this.calendarStyle = null;
    },

    didInsertElement() {
      this._super(...arguments);

      let onInsertElement = this.get('onInsertElement');

      if (typeof onInsertElement === 'function') {
        onInsertElement(this);
      }
    }

  });

  _exports.default = _default;
});