define("ember-svg-jar/inlined/mobile_phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#ade8c4\" d=\"M81.2 24H8.8c-1.6 0-3 1.4-2.9 3v85.8c0 1.6 1.3 3 2.9 3h72.3c1.6 0 3-1.3 3-2.9V27c.1-1.7-1.3-3-2.9-3z\"/><path fill=\"#28323e\" d=\"M71.2 0H7C3.2 0 0 3.1 0 7v114c0 3.8 3.1 6.9 6.9 7h64.3c3.9 0 7-3.1 7-7V7c0-3.8-3.1-7-7-7zM6 7c0-.6.4-1 1-1h64.3c.6 0 1 .4 1 1v11.1H6V7zm0 17h66.3v80H6V24zm65.2 98H7c-.6 0-1-.4-1-1v-11.1h66.3V121c-.1.6-.5 1-1.1 1z\"/><path fill=\"#28323e\" d=\"M57.6 57.4C54.1 50.3 46.9 45.8 39 45.7c-3.8 0-7.5 1-10.8 2.8C21.5 52.3 18 59 18.8 61.7c0 0 .7 2.4 4 2.3 1.2.1 2.2-.8 2.3-2 .4-4.5 4-9.6 9-11.9 3-1.3 6.4-1.2 9.2.3 2.8 1.3 5 3.5 6.2 6.3 1.2 2.5 1.9 5.3 1.8 8.2.1 3-.5 5.9-2 8.5-2 3.2-6.2 4.2-9.4 2.2-.9-.6-1.7-1.4-2.3-2.3-1.6-2.4-2.3-5.3-2.2-8.3-.1-3.1.6-6.1 2.2-8.8.6-1 1.5-1.8 2.6-2.4.5-.2.7-.8.5-1.3-.2-.4-.5-.6-.9-.6-8.3 0-12.3 7.1-12.3 13.1 0 13.5 9.5 17.3 16 17.3 9.1 0 16-8.1 16-17.4-.2-2.6-.8-5.1-1.9-7.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 84.2 128"
    }
  };
  _exports.default = _default;
});