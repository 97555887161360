define("@outdoorsyco/ember-shared-data/components/modals/insurance-description/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YBBJDgrA",
    "block": "{\"symbols\":[\"m\",\"l\",\"@onClose\",\"@description\",\"@showInsuranceDescriptionModal\"],\"statements\":[[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"  \"],[5,\"modal\",[],[[\"@onClose\",\"@maxWidth\"],[[23,3,[]],\"40\"]],{\"statements\":[[0,\"\\n\\n    \"],[5,\"layout/modal/default\",[],[[\"@modal\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"Heading\"]],[[12,\"class\",\"  margin-bottom-0.5 margin-right-2 \"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"term-insurance-details\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[5,\"p\",[[12,\"class\",[29,[[28,\"local-class\",[\"text-format\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/modals/insurance-description/styles\"]]],\"  margin-bottom-1.5 \"]]]],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[23,4,[]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,2,[\"Close\"]],[],[[],[]]],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/modals/insurance-description/template.hbs"
    }
  });

  _exports.default = _default;
});