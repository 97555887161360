define('ember-mobile-core/mixins/pan-recognizer', ['exports', 'ember-mobile-core/mixins/run-on-raf', 'ember-mobile-core/utils/parse-touch-data'], function (exports, _runOnRaf, _parseTouchData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_runOnRaf.default, {
    panManager: Ember.inject.service(),

    // public
    threshold: 10,
    axis: 'horizontal',
    useCapture: false,
    preventScroll: true,
    panArea: null,

    // private
    currentTouches: null,

    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'currentTouches', new Map());
    },


    // hooks
    didPanStart: function didPanStart() {},
    didPan: function didPan() {},
    didPanEnd: function didPanEnd() {},


    //public functions
    lockPan: function lockPan() {
      Ember.get(this, 'panManager').lock(Ember.get(this, 'elementId'));
    },
    unlockPan: function unlockPan() {
      Ember.get(this, 'panManager').unlock(Ember.get(this, 'elementId'));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // if an axis is set, limit scroll to a single axis
      var axis = Ember.get(this, 'axis');
      if (axis === 'horizontal') {
        this.element.style.touchAction = 'pan-y';
      } else if (axis === 'vertical') {
        this.element.style.touchAction = 'pan-x';
      }

      var options = {
        capture: Ember.get(this, 'useCapture'),
        passive: true
      };
      this.element.addEventListener('touchstart', Ember.get(this, 'didTouchStart').bind(this), options);
      this.element.addEventListener('touchmove', Ember.get(this, 'didTouchMove').bind(this), { capture: Ember.get(this, 'useCapture') });
      this.element.addEventListener('touchend', Ember.get(this, 'didTouchEnd').bind(this), options);
      this.element.addEventListener('touchcancel', Ember.get(this, 'didTouchEnd').bind(this), options);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var options = {
        capture: Ember.get(this, 'useCapture'),
        passive: true
      };
      this.element.removeEventListener('touchstart', Ember.get(this, 'didTouchStart').bind(this), options);
      this.element.removeEventListener('touchmove', Ember.get(this, 'didTouchMove').bind(this), { capture: Ember.get(this, 'useCapture') });
      this.element.removeEventListener('touchend', Ember.get(this, 'didTouchEnd').bind(this), options);
      this.element.removeEventListener('touchcancel', Ember.get(this, 'didTouchEnd').bind(this), options);
    },


    // events
    didTouchStart: function didTouchStart(e) {
      var currentTouches = Ember.get(this, 'currentTouches');

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = e.changedTouches[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var touch = _step.value;

          var touchData = (0, _parseTouchData.parseInitialTouchData)(touch, e);

          currentTouches.set(touch.identifier, touchData);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    didTouchMove: function didTouchMove(e) {
      var _this = this;

      var currentTouches = Ember.get(this, 'currentTouches');

      var _loop = function _loop(touch) {
        var previousTouchData = currentTouches.get(touch.identifier);
        var touchData = (0, _parseTouchData.default)(previousTouchData, touch, e);

        if (touchData.panStarted) {
          // prevent scroll if a pan is still busy
          if (Ember.get(_this, 'preventScroll')) {
            e.preventDefault();
          }

          // fire didPan hook only if there is a lock on the current element or there is no lock
          if (Ember.get(_this, 'panManager.panLocked') === Ember.get(_this, 'elementId') || !Ember.get(_this, 'panManager.panLocked')) {
            _this.runOnRaf(function () {
              return _this.didPan(touchData.data);
            });
          }
        } else if (!Ember.get(_this, 'panManager.panLocked')) {
          var axis = Ember.get(_this, 'axis');

          // only pan when pan wasn't denied and the threshold for the given axis is achieved
          if (!touchData.panDenied && (axis === 'horizontal' && Math.abs(touchData.data.current.distanceX) > Ember.get(_this, 'threshold') || axis === 'vertical' && Math.abs(touchData.data.current.distanceY) > Ember.get(_this, 'threshold'))) {
            // test if axis matches with data else deny the pan
            if (axis === 'horizontal' && (0, _parseTouchData.isHorizontal)(touchData) || axis === 'vertical' && (0, _parseTouchData.isVertical)(touchData)) {
              // prevent scroll if a pan is detected
              if (Ember.get(_this, 'preventScroll')) {
                e.preventDefault();
              }

              touchData.panStarted = true;

              // trigger panStart hook
              _this.didPanStart(touchData.data);
            } else {
              touchData.panDenied = true;
            }
          }
        }

        currentTouches.set(touch.identifier, touchData);
      };

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = e.changedTouches[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var touch = _step2.value;

          _loop(touch);
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    },
    didTouchEnd: function didTouchEnd(e) {
      var currentTouches = Ember.get(this, 'currentTouches');

      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = e.changedTouches[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var touch = _step3.value;

          var previousTouchData = currentTouches.get(touch.identifier);
          var _touchData = (0, _parseTouchData.default)(previousTouchData, touch, e);

          if (_touchData.panStarted && (Ember.get(this, 'panManager.panLocked') === Ember.get(this, 'elementId') || !Ember.get(this, 'panManager.panLocked'))) {
            this.didPanEnd(_touchData.data);
          }

          currentTouches.delete(touch.identifier);
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      this.unlockPan();
    }
  });
});