define("@outdoorsyco/ember-shared-data/components/modals/insurance-description/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "text-format": "_text-format_1ktvnm"
  };
  _exports.default = _default;
});