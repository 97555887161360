define("@outdoorsyco/ember-switchback/styles/shadow/shadow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "raisedGray100": "0 1px 1px 0 hsla(207, 6%, 23%, 0.1)",
    "raisedGray200": "0 4px 9px -2px  hsla(207, 6%, 23%, 0.1), 0 1px 2px -1px hsla(207, 6%, 23%, 0.06)",
    "raisedGray300": "0 5px 9px -1px  hsla(207, 6%, 23%, 0.1), 0 2px 4px -1px hsla(207, 6%, 23%, 0.06)",
    "raisedGray400": "0 10px 15px -3px hsla(207, 6%, 23%, 0.1), 0 4px 6px -2px hsla(207, 6%, 23%, 0.05)",
    "raisedGray500": "0 15px 25px -5px hsla(207, 6%, 23%, 0.1), 0 10px 10px -5px hsla(207, 6%, 23%, 0.04)",
    "raisedGray600": "0 24px 64px 0 hsla(0, 0%, 0%, 0.15)",
    "insetShadow100": "inset 0px 1px 2px hsla(0, 0, 0, 0.1)"
  };
  _exports.default = _default;
});