define("calendar-picker/mixins/od-rangepick", ["exports", "calendar-picker/utils/cp-utils", "moment"], function (_exports, _cpUtils, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    guid: null,
    start: null,
    end: null,
    rangeApplied: false,
    disabledRanges: [],
    specialRanges: [],
    useMomentInput: false,
    useMomentOutput: false,
    inputFormat: 'YYYY-MM-DD',
    outputFormat: 'YYYY-MM-DD',
    displayFormat: 'MMM D, YYYY',
    closeAfterApply: false,
    closeAfterRange: true,
    minDate: null,
    maxDate: (0, _moment.default)().add(1, 'year'),
    applyAction: null,
    applyAfterRange: true,
    disableNavBack: false,
    disableNavForth: false,
    showDaysAround: true,
    allowDisabledInRange: false,
    areMinRangeNights: false,
    numberOfMonths: 2,
    monthRange: null,
    daysComponent: 'od-range-days',
    calendarStyle: null,
    readonly: false,
    updateCenter: true,

    // opens the calendar to the first day of the selected range, regardless of current date
    init() {
      this._super(...arguments);

      if (typeof FastBoot !== 'undefined') {
        return;
      }

      this.calendars = [];
      this.monthRange = _moment.default.range(null, null);
      this.set('guid', Ember.guidFor(this));

      this._setCalendars();

      this._registerCalendar();
    },

    didReceiveAttrs(attrs) {
      this._super(...arguments);

      if (typeof FastBoot !== 'undefined') {
        return;
      }

      let _attrs = attrs || this.attrs;

      if (!_attrs) {
        return;
      }

      if (this.get('range') === null && (_attrs.start || _attrs.end)) {
        this._initSelection();

        this._checkNavegability();
      }
    },

    willDestroy() {
      this._super(...arguments);

      if (typeof FastBoot !== 'undefined') {
        return;
      }

      this._unregisterCalendar();
    },

    willRender() {
      this._super(...arguments);

      if (typeof FastBoot !== 'undefined') {
        Ember.run(this, 'destroy');
      }
    },

    range: Ember.computed('start', 'end', {
      get() {
        return {
          start: this.start ? this._formatDate(this.start) : null,
          end: this.end ? this._formatDate(this.end) : null
        };
      },

      set(key, value) {
        return value;
      }

    }),

    goToDate(date) {
      const newDate = (0, _moment.default)(date);
      const minDate = this.get('minDate');
      const maxDate = this.get('maxDate');

      if (minDate && newDate.isBefore(minDate) || maxDate && newDate.isAfter(maxDate)) {
        return false;
      }

      const monthsDiff = newDate.startOf('month').diff(this.monthRange.start.startOf('month'), 'months');
      this.actions.changeCenter.apply(this, [monthsDiff, 'months']);
    },

    actions: {
      updateCenter(newCenter, calendar) {
        calendar.set('center', newCenter.moment);
        return false;
      },

      onSelect(dropdown, date) {
        if (this.get('readonly')) {
          return;
        }

        let startDate = date.moment && date.moment.start ? date.moment.start : null;
        let endDate = date.moment && date.moment.end ? date.moment.end : null;
        let allowDisabledInRange = this.get('allowDisabledInRange');
        let disabledRanges = this.get('disabledRanges');
        let specialRanges = this.get('specialRanges');
        let applyAction = this.get('applyAction');

        if (startDate && endDate) {
          let minRange = this.get('minRange');
          let minRangeAndNights = _moment.default.range(date.moment.start, date.moment.end).diff('day') + (this.get('areMinRangeNights') ? 1 : 0);

          if (minRangeAndNights <= minRange) {
            // ToDO: replace alert with optional/customizable message
            alert(`Sorry, the minimum amount of days to select is ${minRange}`);
            this.set('range', null);
            return false;
          }
        } // Check and prevent selecting date ranges when there are disabled days in between


        if (!allowDisabledInRange && (Ember.isArray(disabledRanges) && !Ember.isEmpty(disabledRanges) || Ember.isArray(specialRanges) && !Ember.isEmpty(specialRanges) && endDate)) {
          let selectedRange = _moment.default.range(date.moment.start, date.moment.end);

          let disabledSpecialRanges = specialRanges ? specialRanges.filter(sro => sro.isDisabled && (0, _cpUtils.normalizeSRPSameDay)(sro).intersect(selectedRange)) : null;
          let disabledRangesBetween = disabledRanges ? disabledRanges.filter(disabledRange => disabledRange.intersect(selectedRange)) : null;

          if (disabledRangesBetween && disabledRangesBetween.length || disabledSpecialRanges && disabledSpecialRanges.length) {
            // ToDO: replace alert with optional/customizable message
            alert('Sorry, selecting dates with unavailable days in between is not allowed');
            this.set('range', null);
            return false;
          }
        }

        this.set('range', date.moment);
        this.set('isSelecting', false);

        if (this.get('didSelect')) {
          this.get('didSelect')(startDate, endDate);
        }

        if (applyAction) {
          let applyAfterRange = this.get('applyAfterRange');

          if (applyAfterRange && Ember.isEmpty(endDate)) {
            return false;
          }

          (false && !(typeof applyAction === 'function') && Ember.assert('Must pass a "applyAction" action', typeof applyAction === 'function'));

          if (!this.get('useMomentOutput')) {
            let outputFormat = this.get('outputFormat');
            startDate = startDate.format(outputFormat);
            endDate = endDate === null && !applyAfterRange ? null : endDate.format(outputFormat);
          }

          applyAction(startDate, endDate, dropdown);

          if (this.get('closeAfterApply') && this.get('isDropdown')) {
            if (this.get('closeAfterRange') && Ember.isEmpty(endDate)) {
              return;
            }

            Ember.run.next(this, () => {
              if (!(this.isDestroying || this.isDestroyed)) {
                dropdown.actions.close();
              }
            });
          }
        }
      },

      reset() {
        let clearRange = {
          start: null,
          end: null
        };
        this.set('range', clearRange);
        this.setProperties(clearRange);
        return false;
      },

      changeCenter(amount, type) {
        let calendars = this.get('calendars');
        let startRangeCenter = calendars.get('firstObject.center');

        if (startRangeCenter && startRangeCenter.isValid()) {
          calendars.forEach(calendar => {
            calendar.set('center', calendar.get('center').clone().add(amount, type));
          });
          this.set('monthRange', _moment.default.range(calendars.get('firstObject.center'), calendars.get('lastObject.center')));

          this._checkNavegability();
        }
      }

    },

    _setCalendars(startDate) {
      let calendars = Ember.A();
      let numberOfMonths = this.get('numberOfMonths');
      let start = startDate || (0, _moment.default)(this.get('start'));
      let minDate = (0, _moment.default)(this.get('minDate'));
      let startMonth = null;

      if (start && start.isValid()) {
        startMonth = start;
      } else if (minDate && minDate.isValid()) {
        startMonth = minDate;
      } else {
        startMonth = (0, _moment.default)();
      }

      if (!startMonth) {
        return;
      }

      if (!this.get('updateCenter')) {
        let staticCenterStartDate = minDate.startOf('month');
        let staticCenterEndDate = minDate.clone().add(numberOfMonths - 1, 'month');
        let staticCenterRange = (0, _moment.default)().range(staticCenterStartDate, staticCenterEndDate);
        this.set('monthRange', staticCenterRange); // use month range of minDate and # of months

        for (let month of staticCenterRange.by('months')) {
          calendars.pushObject(Ember.Object.create({
            center: month,
            ref: null
          }));
        }
      } else {
        let startMonthDate = startMonth.startOf('month');
        let endMonthDate = startMonth.clone().add(numberOfMonths - 1, 'month');
        let monthRange = (0, _moment.default)().range(startMonthDate, endMonthDate);
        this.set('monthRange', monthRange);

        for (let month of monthRange.by('months')) {
          calendars.pushObject(Ember.Object.create({
            center: month,
            ref: null
          }));
        }
      }

      this.set('calendars', calendars);
    },

    _checkNavegability() {
      let minDate = this.get('minDate');
      let maxDate = this.get('maxDate');
      let monthRange = this.get('monthRange').clone();

      if (monthRange) {
        if (minDate) {
          this.set('disableNavBack', monthRange.start.isBefore(minDate));
        }

        if (maxDate) {
          this.set('disableNavForth', monthRange.end.endOf('month').isAfter(maxDate));
        }
      }
    },

    _initSelection() {
      let start = this.get('start');
      let end = this.get('end');
      let inputFormat = this.get('inputFormat');

      if (this.get('useMomentInput')) {
        start = start && start.isValid() ? start.format(inputFormat) : null;
        end = end && end.isValid() ? end.format(inputFormat) : null;
      } else {
        start = start ? (0, _moment.default)(start, inputFormat) : null;
        end = end ? (0, _moment.default)(end, inputFormat) : null;
      }

      if (start && end) {
        this.set('range', _moment.default.range(start, end));
      }

      this.setProperties({
        start,
        end
      });
    },

    _registerCalendar() {
      if (window) {
        window.__calendarPickers = window.__calendarPickers || {};
        window.__calendarPickers[this.get('guid')] = this;
      }

      const registerCalendar = this.get('registerCalendar');

      if (registerCalendar) {
        registerCalendar(this);
      }
    },

    _unregisterCalendar() {
      if (window) {
        delete window.__calendarPickers[this.get('guid')];
      }

      const unregisterCalendar = this.get('unregisterCalendar');

      if (unregisterCalendar) {
        unregisterCalendar(this);
      }
    },

    _formatDate(date) {
      if (typeof date.format === 'function') {
        return (0, _moment.default)(date.format());
      }

      return (0, _moment.default)(date);
    }

  });

  _exports.default = _default;
});