define("@outdoorsyco/outdoorsy-addon/components/tax-inclusive-help-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rXP92vF6",
    "block": "{\"symbols\":[\"@taxAmount\",\"@isInclusive\"],\"statements\":[[4,\"if\",[[28,\"and\",[[23,2,[]],[28,\"gt\",[[23,1,[]],0],null]],null]],null,{\"statements\":[[0,\"  \"],[5,\"help\",[],[[\"@size\"],[\"small\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"max-width-320\"],[[\"from\"],[\"@outdoorsyco/outdoorsy-addon/components/tax-inclusive-help-box/styles\"]]],\"  padding-all-1 \"]]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"comp-tax-inclusive-help-box-amount\"],[[\"amount\"],[[28,\"money-formatter\",[[23,1,[]],2],[[\"format\"],[[23,0,[\"currentCurrency\"]]]]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/tax-inclusive-help-box/template.hbs"
    }
  });

  _exports.default = _default;
});