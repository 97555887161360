define("@outdoorsyco/ember-shared-data/utils/date-of-birth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.driverYears = driverYears;
  _exports.days = days;
  _exports.months = void 0;
  const months = [{
    display: '01 January',
    value: 1
  }, {
    display: '02 February',
    value: 2
  }, {
    display: '03 March',
    value: 3
  }, {
    display: '04 April',
    value: 4
  }, {
    display: '05 May',
    value: 5
  }, {
    display: '06 June',
    value: 6
  }, {
    display: '07 July',
    value: 7
  }, {
    display: '08 August',
    value: 8
  }, {
    display: '09 September',
    value: 9
  }, {
    display: '10 October',
    value: 10
  }, {
    display: '11 November',
    value: 11
  }, {
    display: '12 December',
    value: 12
  }];
  _exports.months = months;

  function driverYears() {
    let years = [];
    let firstYear = new Date().getFullYear() - 17;

    for (let i = firstYear; i >= 1925; i--) {
      years.push(i);
    }

    return years;
  }

  function days() {
    let count = 31;
    return Array(count).fill().map((v, i) => {
      let counter = i + 1;

      if (counter <= 9) {
        return `0${counter}`;
      }

      return `${counter}`;
    });
  }
});