define("@outdoorsyco/ember-shared-data/validator/strict-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.strictName = strictName;
  _exports.default = void 0;

  function strictName(_value) {
    if (Ember.isEmpty(_value)) {
      return true;
    }

    return /^ *[a-zA-ZÀ-ÖØ-öø-ɏ]+((\.|-|'| )[a-zA-ZÀ-ÖØ-öø-ɏ]*)* *$/.test(_value);
  }

  var _default = {
    param: 'strictName',
    error: {
      key: 'form-strict-name-validation',
      message: 'Name contains illegal characters'
    },
    validate: strictName
  };
  _exports.default = _default;
});