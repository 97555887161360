define("@outdoorsyco/ember-shared-data/utils/states-provinces", ["exports", "@outdoorsyco/ember-shared-data/utils/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countryFromState = countryFromState;
  _exports.stateLongFromShort = stateLongFromShort;
  _exports.stateShortFromLong = stateShortFromLong;
  _exports.countryNameFromAbbreviation = countryNameFromAbbreviation;
  _exports.countriesForSelect = countriesForSelect;
  _exports.stripePayoutCountriesForSelect = stripePayoutCountriesForSelect;
  _exports.groupedStatesForSelect = groupedStatesForSelect;
  _exports._stateObjectFromValue = _stateObjectFromValue;
  _exports.statesProvinces = void 0;
  const statesProvinces = {
    US: {
      name: 'United States',
      regions: [{
        short: 'AL',
        name: 'Alabama'
      }, {
        short: 'AK',
        name: 'Alaska'
      }, {
        short: 'AZ',
        name: 'Arizona'
      }, {
        short: 'AR',
        name: 'Arkansas'
      }, {
        short: 'CA',
        name: 'California'
      }, {
        short: 'CO',
        name: 'Colorado'
      }, {
        short: 'CT',
        name: 'Connecticut'
      }, {
        short: 'DC',
        name: 'District of Columbia',
        alt: ['Washington DC', 'Washington D.C.']
      }, {
        short: 'DE',
        name: 'Delaware'
      }, {
        short: 'FL',
        name: 'Florida'
      }, {
        short: 'GA',
        name: 'Georgia'
      }, {
        short: 'HI',
        name: 'Hawaii'
      }, {
        short: 'ID',
        name: 'Idaho'
      }, {
        short: 'IL',
        name: 'Illinois'
      }, {
        short: 'IN',
        name: 'Indiana'
      }, {
        short: 'IA',
        name: 'Iowa'
      }, {
        short: 'KS',
        name: 'Kansas'
      }, {
        short: 'KY',
        name: 'Kentucky'
      }, {
        short: 'LA',
        name: 'Louisiana'
      }, {
        short: 'ME',
        name: 'Maine'
      }, {
        short: 'MD',
        name: 'Maryland'
      }, {
        short: 'MA',
        name: 'Massachusetts'
      }, {
        short: 'MI',
        name: 'Michigan'
      }, {
        short: 'MN',
        name: 'Minnesota'
      }, {
        short: 'MS',
        name: 'Mississippi'
      }, {
        short: 'MO',
        name: 'Missouri'
      }, {
        short: 'MT',
        name: 'Montana'
      }, {
        short: 'NE',
        name: 'Nebraska'
      }, {
        short: 'NV',
        name: 'Nevada'
      }, {
        short: 'NH',
        name: 'New Hampshire'
      }, {
        short: 'NJ',
        name: 'New Jersey'
      }, {
        short: 'NM',
        name: 'New Mexico'
      }, {
        short: 'NY',
        name: 'New York'
      }, {
        short: 'NC',
        name: 'North Carolina'
      }, {
        short: 'ND',
        name: 'North Dakota'
      }, {
        short: 'OH',
        name: 'Ohio'
      }, {
        short: 'OK',
        name: 'Oklahoma'
      }, {
        short: 'OR',
        name: 'Oregon'
      }, {
        short: 'PA',
        name: 'Pennsylvania'
      }, {
        short: 'RI',
        name: 'Rhode Island'
      }, {
        short: 'SC',
        name: 'South Carolina'
      }, {
        short: 'SD',
        name: 'South Dakota'
      }, {
        short: 'TN',
        name: 'Tennessee'
      }, {
        short: 'TX',
        name: 'Texas'
      }, {
        short: 'UT',
        name: 'Utah'
      }, {
        short: 'VT',
        name: 'Vermont'
      }, {
        short: 'VA',
        name: 'Virginia'
      }, {
        short: 'WA',
        name: 'Washington'
      }, {
        short: 'WV',
        name: 'West Virginia'
      }, {
        short: 'WI',
        name: 'Wisconsin'
      }, {
        short: 'WY',
        name: 'Wyoming'
      }, {
        short: 'AS',
        name: 'American Samoa'
      }, {
        short: 'GU',
        name: 'Guam'
      }, {
        short: 'MP',
        name: 'Northern Mariana Islands'
      }, {
        short: 'PR',
        name: 'Puerto Rico'
      }, {
        short: 'UM',
        name: 'United States Minor Outlying Islands'
      }, {
        short: 'VI',
        name: 'Virgin Islands'
      }]
    },
    CA: {
      name: 'Canada',
      regions: [{
        short: 'AB',
        name: 'Alberta'
      }, {
        short: 'BC',
        name: 'British Columbia'
      }, {
        short: 'MB',
        name: 'Manitoba'
      }, {
        short: 'NB',
        name: 'New Brunswick'
      }, {
        short: 'NL',
        name: 'Newfoundland and Labrador',
        alt: ['Newfoundland', 'Labrador']
      }, {
        short: 'NS',
        name: 'Nova Scotia'
      }, {
        short: 'NU',
        name: 'Nunavut'
      }, {
        short: 'NT',
        name: 'Northwest Territories'
      }, {
        short: 'ON',
        name: 'Ontario'
      }, {
        short: 'PE',
        name: 'Prince Edward Island'
      }, {
        short: 'QC',
        name: 'Quebec'
      }, {
        short: 'SK',
        name: 'Saskatchewan'
      }, {
        short: 'YT',
        name: 'Yukon'
      }]
    },
    AU: {
      name: 'Australia',
      regions: [{
        short: 'ACT',
        name: 'Australian Capital Territory'
      }, {
        short: 'NSW',
        name: 'New South Wales'
      }, {
        short: 'NT',
        name: 'Northern Territory'
      }, {
        short: 'QLD',
        name: 'Queensland'
      }, {
        short: 'SA',
        name: 'South Australia'
      }, {
        short: 'TAS',
        name: 'Tasmania'
      }, {
        short: 'VIC',
        name: 'Victoria'
      }, {
        short: 'WA',
        name: 'Western Australia'
      }]
    },
    NZ: {
      name: 'New Zealand',
      regions: [{
        short: 'Auckland',
        name: 'Auckland'
      }, {
        short: 'Bay of Plenty',
        name: 'Bay of Plenty'
      }, {
        short: 'Canterbury',
        name: 'Canterbury'
      }, {
        short: 'Gisborne',
        name: 'Gisborne'
      }, {
        short: "Hawke's Bay",
        name: "Hawke's Bay"
      }, {
        short: 'Marlborough',
        name: 'Marlborough'
      }, {
        short: 'Manawatu-Wanganui',
        name: 'Manawatu-Wanganui'
      }, {
        short: 'Nelson',
        name: 'Nelson'
      }, {
        short: 'Northland',
        name: 'Northland'
      }, {
        short: 'Otago',
        name: 'Otago'
      }, {
        short: 'Southland',
        name: 'Southland'
      }, {
        short: 'Tasman',
        name: 'Tasman'
      }, {
        short: 'Taranaki',
        name: 'Taranaki'
      }, {
        short: 'Waikato',
        name: 'Waikato'
      }, {
        short: 'Wellington',
        name: 'Wellington'
      }, {
        short: 'West Coast',
        name: 'West Coast'
      }, {
        short: 'Chatham Islands Territory',
        name: 'Chatham Islands Territory'
      }]
    },
    FR: {
      name: 'France',
      regions: [{
        short: 'FR-A',
        name: 'Alsace'
      }, {
        short: 'FR-B',
        name: 'Aquitaine'
      }, {
        short: 'FR-C',
        name: 'Auvergne'
      }, {
        short: 'FR-E',
        name: 'Brittany'
      }, {
        short: 'FR-D',
        name: 'Burgundy'
      }, {
        short: 'FR-F',
        name: 'Centre-Val de Loire'
      }, {
        short: 'FR-G',
        name: 'Champagne-Ardenne'
      }, {
        short: 'FR-H',
        name: 'Corsica'
      }, {
        short: 'FR-I',
        name: 'Franche-Comté'
      }, {
        short: 'FR-K',
        name: 'Languedoc-Roussillon'
      }, {
        short: 'FR-L',
        name: 'Limousin'
      }, {
        short: 'FR-M',
        name: 'Lorraine'
      }, {
        short: 'FR-P',
        name: 'Lower Normandy'
      }, {
        short: 'FR-N',
        name: 'Midi-Pyrénées'
      }, {
        short: 'FR-O',
        name: 'Nord-Pas-de-Calais'
      }, {
        short: 'FR-R',
        name: 'Pays de la Loire'
      }, {
        short: 'FR-S',
        name: 'Picardy'
      }, {
        short: 'FR-T',
        name: 'Poitou-Charentes'
      }, {
        short: 'FR-U',
        name: "Provence-Alpes-Côte d'Azur"
      }, {
        short: 'FR-V',
        name: 'Rhône-Alpes'
      }, {
        short: 'FR-Q',
        name: 'Upper Normandy'
      }, {
        short: 'FR-J',
        name: 'Île-de-France'
      }]
    },
    DE: {
      name: 'Germany',
      regions: []
    },
    IE: {
      name: 'Ireland',
      regions: [{
        short: 'IE-C',
        name: 'Connacht'
      }, {
        short: 'IE-L',
        name: 'Leinster'
      }, {
        short: 'IE-M',
        name: 'Munster'
      }, {
        short: 'IE-U',
        name: 'Ulster'
      }]
    },
    IT: {
      name: 'Italy',
      regions: [{
        short: 'IT-65',
        name: 'Abruzzo'
      }, {
        short: 'IT-77',
        name: 'Basilicata'
      }, {
        short: 'IT-78',
        name: 'Calabria'
      }, {
        short: 'IT-72',
        name: 'Campania'
      }, {
        short: 'IT-45',
        name: 'Emilia-Romagna'
      }, {
        short: 'IT-36',
        name: 'Friuli-Venezia Giulia'
      }, {
        short: 'IT-62',
        name: 'Lazio'
      }, {
        short: 'IT-42',
        name: 'Liguria'
      }, {
        short: 'IT-25',
        name: 'Lombardia'
      }, {
        short: 'IT-57',
        name: 'Marche'
      }, {
        short: 'IT-67',
        name: 'Molise'
      }, {
        short: 'IT-21',
        name: 'Piemonte'
      }, {
        short: 'IT-75',
        name: 'Puglia'
      }, {
        short: 'IT-88',
        name: 'Sardegna'
      }, {
        short: 'IT-82',
        name: 'Sicilia'
      }, {
        short: 'IT-52',
        name: 'Toscana'
      }, {
        short: 'IT-32',
        name: 'Trentino-Alto Adige'
      }, {
        short: 'IT-55',
        name: 'Umbria'
      }, {
        short: 'IT-23',
        name: "Valle d'Aosta"
      }, {
        short: 'IT-34',
        name: 'Veneto'
      }]
    },
    ES: {
      name: 'Spain',
      regions: [{
        short: 'ES-AN',
        name: 'Andalucía'
      }, {
        short: 'ES-AR',
        name: 'Aragón'
      }, {
        short: 'ES-AS',
        name: 'Asturias, Principado de'
      }, {
        short: 'ES-CN',
        name: 'Canarias'
      }, {
        short: 'ES-CB',
        name: 'Cantabria'
      }, {
        short: 'ES-CM',
        name: 'Castilla-La Mancha'
      }, {
        short: 'ES-CL',
        name: 'Castilla y León'
      }, {
        short: 'ES-CT',
        name: 'Catalunya (ca) [Cataluña]'
      }, {
        short: 'ES-EX',
        name: 'Extremadura'
      }, {
        short: 'ES-GA',
        name: 'Galicia (gl) [Galicia]'
      }, {
        short: 'ES-IB',
        name: 'Illes Balears (ca) [Islas Baleares]'
      }, {
        short: 'ES-RI',
        name: 'La Rioja'
      }, {
        short: 'ES-MD',
        name: 'Madrid, Comunidad de'
      }, {
        short: 'ES-MC',
        name: 'Murcia, Región de'
      }, {
        short: 'ES-NC',
        name: 'Navarra, Comunidad Foral de Nafarroako Foru Komunitatea (eu)'
      }, {
        short: 'ES-PV',
        name: 'País Vasco Euskal Herria (eu)'
      }, {
        short: 'ES-VC',
        name: 'Valenciana, Comunidad Valenciana, Comunitat (ca)'
      }, {
        short: 'ES-CE',
        name: 'Ceuta'
      }, {
        short: 'ES-ML',
        name: 'Melilla'
      }]
    },
    GB: {
      name: 'United Kingdom',
      regions: []
    },
    PL: {
      name: 'Poland',
      regions: []
    },
    PT: {
      name: 'Portugal',
      regions: []
    },
    NL: {
      name: 'Netherlands',
      regions: []
    },
    BE: {
      name: 'Belgium',
      regions: []
    },
    SE: {
      name: 'Sweden',
      regions: []
    },
    AT: {
      name: 'Austria',
      regions: []
    },
    DK: {
      name: 'Denmark',
      regions: []
    },
    FI: {
      name: 'Finland',
      regions: []
    },
    GR: {
      name: 'Greece',
      regions: []
    },
    HR: {
      name: 'Croatia',
      regions: []
    }
  };
  _exports.statesProvinces = statesProvinces;

  function countryFromState(state) {
    if (!state) {
      return null;
    }

    let lowerState = state.toLowerCase();
    let countries = Object.keys(statesProvinces);
    let foundCountry = null;
    countries.some(country => {
      let found = statesProvinces[country].regions.some(region => {
        return lowerState === region.name.toLowerCase() || lowerState === region.short.toLowerCase();
      });

      if (found) {
        foundCountry = country;
      }

      return foundCountry;
    });
    return foundCountry;
  }

  function stateLongFromShort(short, country) {
    if (!short) {
      return;
    }

    let trimmedShort = short.trim();

    let state = _stateObjectFromValue(trimmedShort, 'short', country);

    return state.name || short;
  }

  function stateShortFromLong(long, country) {
    if (!long) {
      return;
    }

    let trimmedLong = long.trim();

    let state = _stateObjectFromValue(trimmedLong, 'name', country);

    return state.short || long;
  }

  function countryNameFromAbbreviation(abbreviation) {
    if (!abbreviation) {
      return;
    }

    return Ember.get(statesProvinces, `${abbreviation}.name`);
  }

  function formatCountriesForSelect(keys) {
    return keys.reduce((arr, key) => {
      let country = statesProvinces[key];
      arr.push({
        name: country.name,
        value: key
      });
      return arr;
    }, []);
  }

  function countriesForSelect() {
    return formatCountriesForSelect(Object.keys(statesProvinces));
  }

  function stripePayoutCountriesForSelect() {
    return formatCountriesForSelect(_countries.stripePayoutCountryAbbreviations);
  }

  function groupedStatesForSelect(onlyCountries, stateAsObject = false) {
    let countries = [];

    if (!onlyCountries) {
      countries = Object.keys(statesProvinces);
    } else {
      countries = typeof onlyCountries === 'string' ? [onlyCountries] : onlyCountries;
    }

    let groups = [];
    countries.forEach(name => {
      let country = statesProvinces[name];

      if (country) {
        let regions = country.regions;
        let options = regions.reduce((arr, opt) => {
          let state = opt.name;

          if (stateAsObject) {
            state = opt;
          }

          arr.push(state);
          return arr;
        }, []);
        groups.push({
          groupName: country.name,
          options: options
        });
      }
    });
    return groups;
  }

  function _stateObjectFromValue(lookupValue, lookupKey, country) {
    if (Ember.isBlank(country)) {
      country = 'US';
    }

    country = country.toUpperCase();
    lookupValue = lookupValue.toLowerCase();
    let obj = statesProvinces[country] || {};
    let regions = obj.regions || [];

    if (!regions.length) {
      return {
        [lookupKey]: lookupValue
      };
    }

    return regions.find(region => region[lookupKey].toLowerCase() === lookupValue) || {};
  }
});