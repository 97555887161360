define("ember-svg-jar/inlined/current-color-times", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.267 18.733a.911.911 0 001.289 0L11 12.29l6.444 6.444a.911.911 0 001.29-1.289L12.288 11l6.444-6.444a.911.911 0 10-1.289-1.29L11 9.712 4.556 3.267a.911.911 0 10-1.29 1.289L9.712 11l-6.444 6.444a.911.911 0 000 1.29z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});