define("@outdoorsyco/ember-shared-data/components/select-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HkGG6AV5",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"select\",false],[12,\"class\",[29,[[22,\"selectClass\"]]]],[12,\"disabled\",[22,\"disabled\"]],[3,\"action\",[[23,0,[]],\"change\"],[[\"on\"],[\"change\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"placeholder\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\",true],[10,\"value\",\"\"],[11,\"selected\",[28,\"is-equal\",[null,[24,[\"value\"]]],null]],[8],[0,\"\\n      \"],[1,[22,\"placeholder\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[24,[\"options\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[4,\"if\",[[23,1,[\"display\"]]],null,{\"statements\":[[0,\"      \"],[7,\"option\",true],[11,\"value\",[29,[[23,1,[\"value\"]]]]],[11,\"selected\",[28,\"is-equal\",[[23,1,[\"value\"]],[24,[\"value\"]]],null]],[8],[0,\"\\n        \"],[1,[23,1,[\"display\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"option\",true],[11,\"value\",[29,[[23,1,[]]]]],[11,\"selected\",[28,\"is-equal\",[[23,1,[]],[24,[\"value\"]]],null]],[8],[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/select-tag/template.hbs"
    }
  });

  _exports.default = _default;
});