define("@outdoorsyco/ember-shared-data/serializers/driver", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Driver extends _application.default {
    normalize(typeClass, hash) {
      if (!hash.id) {
        hash.id = `${hash.first_name} ${hash.last_name}`;
      }

      if (!hash.checks) {
        hash.checks = [];
      }

      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = Driver;
});