define("@outdoorsyco/ember-shared-data/serializers/handoff", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HandoffSerializer extends _application.default {
    serialize() {
      let payload = super.serialize(...arguments);
      payload.booking_id = `${payload.booking_id}`;
      return payload;
    }

  }

  _exports.default = HandoffSerializer;
});