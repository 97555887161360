define("@outdoorsyco/ember-shared-data/components/upload-file-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nEW5eQ1M",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"form\",true],[11,\"action\",[22,\"filePath\"]],[10,\"enctype\",\"multipart/form-data\"],[11,\"method\",[22,\"method\"]],[10,\"onsubmit\",\"return false;\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mtx\"],[8],[0,\"\\n    \"],[5,\"button\",[],[[\"@variant\",\"@disabled\"],[\"default\",[22,\"isProcessing\"]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"isProcessing\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"component-upload-file-form-processing\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,\"buttonText\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/upload-file-form/template.hbs"
    }
  });

  _exports.default = _default;
});