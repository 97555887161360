define("@outdoorsyco/ember-shared-data/serializers/file", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class File extends _application.default {
    normalize(typeClass, data) {
      if (Array.isArray(data) && data.length === 1) {
        data = data[0];
      }

      return super.normalize(typeClass, data);
    }

  }

  _exports.default = File;
});