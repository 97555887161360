define("ember-stripe-elements/utils/stripe-mock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let StripeMock = function StripeMock(publishableKey) {
    this.publishableKey = publishableKey;
  };

  StripeMock.prototype.elements = function () {
    return {
      create: function create() {
        return {
          mount: function mount() {},
          on: function on() {},
          unmount: function unmount() {}
        };
      }
    };
  };

  StripeMock.prototype.createToken = function () {};

  StripeMock.prototype.createSource = function () {};

  StripeMock.prototype.createPaymentMethod = function () {};

  StripeMock.prototype.retrieveSource = function () {};

  StripeMock.prototype.paymentRequest = function () {};

  StripeMock.prototype.redirectToCheckout = function () {};

  StripeMock.prototype.retrievePaymentIntent = function () {};

  StripeMock.prototype.handleCardPayment = function () {};

  StripeMock.prototype.handleCardAction = function () {};

  StripeMock.prototype.confirmPaymentIntent = function () {};

  StripeMock.prototype.handleCardSetup = function () {};

  StripeMock.prototype.retrieveSetupIntent = function () {};

  StripeMock.prototype.confirmSetupIntent = function () {};

  var _default = StripeMock;
  _exports.default = _default;
});