define("@outdoorsyco/outdoorsy-addon/components/od-range-slider/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/od-range-slider/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['od-range-slider'],
    showLabel: false,
    min: 0,
    max: 100,
    connect: true,
    start: 0,
    labelSuffix: '',
    placeholder: '',
    maxSuffix: '+',

    init() {
      this._super(...arguments);

      if (!this.labelSuffix) {
        if (this.get('session.currentDistanceUnit') === 'kilometers') {
          this.labelSuffix = this.get('intl').t('kilometer', {
            count: 2
          });
        } else {
          this.labelSuffix = this.get('intl').t('mile', {
            count: 2
          });
        }
      }
    },

    actions: {
      didUpdate(value) {
        if (this.get('onUpdate')) {
          this.get('onUpdate')(value);
        }
      },

      didChange(value) {
        if (this.get('onChange')) {
          this.get('onChange')(value);
        }
      }

    }
  });

  _exports.default = _default;
});