define("@outdoorsyco/outdoorsy-addon/models/page", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    display: attr('string'),
    contentLocation: attr('string'),
    title: attr('string'),
    seoTitle: attr('string'),
    seoDescription: attr('string'),
    seoAudience: attr('string'),
    seoSpeciality: attr('string'),
    socialDescription: attr('string'),
    socialTitle: attr('string'),
    socialImage: attr('string'),
    filters: attr(),
    language: attr('string'),
    breadcrumbs: attr(),
    sections: attr(),
    srpPage: attr('string'),
    cityPage: attr('string'),
    description: attr('string'),
    srpDescription: attr('string'),
    pageType: attr('string'),
    lat: attr('string'),
    lng: attr('string'),
    city: attr('string'),
    state: attr('string'),
    county: attr('string'),
    shortState: attr('string'),
    contentBlocks: attr()
  });

  _exports.default = _default;
});