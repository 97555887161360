define("@outdoorsyco/outdoorsy-addon/transitions/delayed-fade", ["exports", "ember-animated/motions/opacity", "ember-animated/motions/move"], function (_exports, _opacity, _move) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Transition used by when showing/hiding content that shifts placement of other content and it is
   * helpful to delay the start of the fadeIn / fadeOut until partway through the movement.
   * There is also a slight shift to the left in position during animation.
   * Created originally for lyrv generator radio and text input interactions.
   */
  function* _default({
    removedSprites,
    insertedSprites,
    duration
  }) {
    for (let sprite of removedSprites) {
      yield Promise.all([sprite.endTranslatedBy(8, 0), (0, _move.default)(sprite, {
        duration: duration * (1 / 4)
      }), (0, _opacity.fadeOut)(sprite, {
        duration: duration * (1 / 4)
      })]);
    }

    for (let sprite of insertedSprites) {
      yield Promise.all([sprite.startTranslatedBy(8, 0), (0, _move.default)(sprite, {
        duration: duration * (3 / 4)
      }), (0, _opacity.fadeIn)(sprite, {
        duration: duration * (3 / 4)
      })]);
    }
  }
});