define("@outdoorsyco/ember-switchback/components/text-input/icon/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray400": "hsl(212, 14%, 80%)",
    "Icon": "_Icon_dvghdl"
  };
  _exports.default = _default;
});