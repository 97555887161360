define("@outdoorsyco/ember-switchback/components/switchback/prompts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7/liTHHY",
    "block": "{\"symbols\":[\"p\",\"m\"],\"statements\":[[4,\"each\",[[23,0,[\"promptService\",\"registry\"]]],null,{\"statements\":[[0,\"  \"],[5,\"modal\",[],[[\"@minWidth\",\"@maxWidth\",\"@onClose\"],[[23,1,[\"options\",\"minWidth\"]],[23,1,[\"options\",\"maxWidth\"]],[28,\"action\",[[23,0,[]],[23,0,[\"onClose\"]],[23,1,[]]],null]]],{\"statements\":[[0,\"\\n\\n    \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"modal\",\"data\",\"accept\",\"cancel\"],[[23,2,[]],[23,1,[\"data\"]],[28,\"perform\",[[23,0,[\"accept\"]],[23,1,[]],[23,2,[]]],null],[28,\"perform\",[[23,0,[\"cancel\"]],[23,1,[]],[23,2,[]]],null]]]],false],[0,\"\\n\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/switchback/prompts/template.hbs"
    }
  });

  _exports.default = _default;
});