define("@outdoorsyco/ember-switchback/components/heading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o4dKRgT5",
    "block": "{\"symbols\":[\"Tag\",\"@id\",\"@color\",\"@maxWidth\",\"&attrs\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"-element\",[[23,0,[\"computedElement\"]]],null]],[[\"tagName\"],[[23,0,[\"computedElement\"]]]]]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[[12,\"id\",[23,2,[]]],[12,\"class\",[28,\"local-class\",[\"Heading\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/heading/styles\"]]]],[12,\"data-color\",[23,3,[]]],[12,\"data-max-width\",[23,4,[]]],[13,5]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,6],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/heading/template.hbs"
    }
  });

  _exports.default = _default;
});