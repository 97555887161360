define("@outdoorsyco/ember-shared-data/helpers/first-letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.firstLetter = firstLetter;
  _exports.default = void 0;

  /**
   * @param {word} string
   * @return {letter} The first letter
   */
  function firstLetter(word) {
    return String(word).charAt(0).toUpperCase();
  }

  var _default = Ember.Helper.helper(firstLetter);

  _exports.default = _default;
});