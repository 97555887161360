define("@outdoorsyco/ember-switchback/components/layout/modal/default/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @documenter esdoc */

  /**
   * Provides contextual components from which to build a modal.
   *
   * ```handlebars
   * <Layout::Modal::Default as |l|>
   *  <l.Heading>
   *    Modal Title
   *  </l.Heading>
   *  <l.ActionBar>
   *    <Button {{on "click" this.save}}>
   *      Save Changes
   *    </Button>
   *  </l.ActionBar>
   *  <l.Close />
   * </Layout>
   * ```
   * @public
   */
  class LayoutModalDefault extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (false && !(args.modal) && Ember.assert('You must pass in the modal object to <Layout::Modal::Default>', args.modal));
    }

  }

  _exports.default = LayoutModalDefault;
});