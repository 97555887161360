define("@outdoorsyco/ember-shared-data/utils/dayjs", ["exports", "dayjs", "dayjs/plugin/isBetween"], function (_exports, _dayjs, _isBetween) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = dayjsAutoImport;

  function dayjsAutoImport() {
    _dayjs.default.extend(_isBetween.default);

    return true;
  }
});