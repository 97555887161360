define("@outdoorsyco/ember-shared-data/services/currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurrencyService = (_dec = Ember.inject.service, (_class = class CurrencyService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "apiRequest", _descriptor, this);

      _defineProperty(this, "cache", []);
    }

    async convert(from, to, amount) {
      if (!from || !to) {
        throw 'Missing from/to currency';
      }

      if (this.cache[from] && this.cache[from][to] && this.cache[from][to][amount] !== undefined) {
        return this.cache[from][to][amount];
      }

      let r = await this.apiRequest.request('convert-currency', {
        data: {
          from,
          to,
          amount
        }
      });
      this.cache[from] = this.cache[from] || [];
      this.cache[from][to] = this.cache[from][to] || [];
      return this.cache[from][to][amount] = r === null || r === void 0 ? void 0 : r.amount;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CurrencyService;
});