define("@outdoorsyco/ember-switchback/components/loading/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @documenter esdoc */

  /**
   * Shows a animated loading indicator
   *
   * ```handlebars
   *  <Loading />
   * ```
   *
   * @public
   */
  class Loading extends _component.default {}

  _exports.default = Loading;
});