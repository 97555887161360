define("ember-mobile-pane/templates/components/mobile-pane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VXdexFPi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"activeIndex\",\"simple-indicator\",\"previous\",\"next\",\"scroller\"],[[24,[\"activeIndex\"]],[28,\"component\",[\"mobile-pane/simple-indicator\"],[[\"navItems\",\"currentOffset\",\"onClick\"],[[24,[\"navItems\"]],[24,[\"currentOffset\"]],[28,\"action\",[[23,0,[]],\"changePane\"],null]]]],[28,\"component\",[\"mobile-pane/pervious\"],[[\"paneCount\",\"activeIndex\",\"onClick\"],[[24,[\"paneCount\"]],[24,[\"activeIndex\"]],[28,\"action\",[[23,0,[]],\"onPrevious\"],null]]]],[28,\"component\",[\"mobile-pane/next\"],[[\"paneCount\",\"activeIndex\",\"onClick\"],[[24,[\"paneCount\"]],[24,[\"activeIndex\"]],[28,\"action\",[[23,0,[]],\"onNext\"],null]]]],[28,\"component\",[\"mobile-pane/scroller\"],[[\"activeIndex\",\"lazyRendering\",\"keepRendered\",\"transitionDuration\",\"triggerVelocity\",\"activePane\",\"paneContainerElement\",\"paneCount\",\"currentOffset\",\"visiblePanes\",\"onDragStart\",\"onDragMove\",\"onDragEnd\"],[[24,[\"activeIndex\"]],[24,[\"_lazyRendering\"]],[24,[\"keepRendered\"]],[24,[\"transitionDuration\"]],[24,[\"triggerVelocity\"]],[24,[\"activePane\"]],[24,[\"paneContainerElement\"]],[24,[\"paneCount\"]],[24,[\"currentOffset\"]],[24,[\"visiblePanes\"]],[28,\"action\",[[23,0,[]],\"onDragStart\"],null],[28,\"action\",[[23,0,[]],\"onDragMove\"],null],[28,\"action\",[[23,0,[]],\"onDragEnd\"],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mobile-pane/templates/components/mobile-pane.hbs"
    }
  });

  _exports.default = _default;
});