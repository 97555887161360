define("@outdoorsyco/ember-shared-data/components/input/date/component", ["exports", "@glimmer/component", "@outdoorsyco/ember-shared-data/utils/generate-options"], function (_exports, _component, _generateOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KEY_TO_INDEX = {
    month: 1,
    day: 2,
    year: 0
  };
  /**
   * Naive date selector using two selects and an input. It
   * outputs a value formatted as YYYY-MM-DD.
   */

  class InputDateComponent extends _component.default {
    get monthOptions() {
      return (0, _generateOptions.generateOptions)(12);
    }

    get dayOptions() {
      return (0, _generateOptions.generateOptions)(31);
    }

    get yearOptions() {
      let currentYear = new Date().getFullYear();
      let minAge = 18;
      let maxAge = 80;
      return (0, _generateOptions.generateOptions)(maxAge - minAge, currentYear - maxAge);
    }

    get value() {
      return this.args.value || 'YYYY-MM-DD';
    }

    get year() {
      return this.getValue('year');
    }

    set year(value) {
      this.setValue(value, 'year');
    }

    get month() {
      return this.getValue('month');
    }

    set month(value) {
      this.setValue(value, 'month');
    }

    get day() {
      return this.getValue('day');
    }

    set day(value) {
      this.setValue(value, 'day');
    }

    setValue(value, key) {
      let index = KEY_TO_INDEX[key];
      let date = this.value.split('-');
      let newValue = value.toString();

      if (newValue.length === 1) {
        newValue = `0${newValue}`;
      }

      date[index] = newValue;
      this.args.onChange(date.join('-'));
    }

    getValue(key) {
      let index = KEY_TO_INDEX[key];
      let value = parseInt(this.value.split('-')[index]);

      if (isNaN(value)) {
        return null;
      }

      return value;
    }

  }

  _exports.default = InputDateComponent;
});