define("@outdoorsyco/ember-switchback/components/rating/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Rating": "_Rating_1jp9g1",
    "stars": "_stars_1jp9g1",
    "fill": "_fill_1jp9g1"
  };
  _exports.default = _default;
});