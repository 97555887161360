define("@outdoorsyco/ember-shared-data/mixins/status-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    apiRequest: Ember.inject.service(),

    changeStatus(bookingId, status, message, additional) {
      if (status) {
        let data = Ember.typeOf(additional) === 'object' ? additional : {};
        data.status = status;
        data.details = message;
        return this.apiRequest.request(`bookings/${bookingId}/status`, {
          data: JSON.stringify(data),
          method: 'PATCH'
        });
      }
    }

  });

  _exports.default = _default;
});