define("@outdoorsyco/ember-switchback/components/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pM6j3P03",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"form\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Form\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/form/styles\"]]]]]],[12,\"role\",\"form\"],[12,\"onsubmit\",[28,\"perform\",[[23,0,[\"onSubmit\"]]],null]],[12,\"novalidate\",\"\"],[13,1],[8],[0,\"\\n\\n\"],[4,\"animated-container\",null,null,{\"statements\":[[4,\"animated-if\",[[23,0,[\"onSubmitError\"]]],[[\"use\",\"duration\"],[[24,[\"fadeTransition\"]],300]],{\"statements\":[[0,\"      \"],[5,\"frame\",[[12,\"class\",\"  margin-bottom-1.5 padding-y-1 padding-right-1 padding-left-3.5 \"],[12,\"data-form-error\",\"\"],[12,\"data-test-form-error\",\"\"]],[[\"@highlight\"],[\"left-red\"]],{\"statements\":[[0,\"\\n        \"],[7,\"svg\",true],[11,\"class\",[29,[\"has-fill \",[28,\"local-class\",[\"icon\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/form/styles\"]]]]]],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"viewBox\",\"0 0 18 18\"],[8],[0,\"\\n          \"],[7,\"path\",true],[10,\"fill\",\"hsl(358, 80%, 58%)\"],[10,\"d\",\"M9 0a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm0 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7z\"],[8],[9],[0,\"\\n          \"],[7,\"path\",true],[10,\"fill\",\"hsl(358, 80%, 58%)\"],[10,\"d\",\"M11.4 5L9 7.4 6.6 5 5 6.6 7.4 9 5 11.4 6.6 13 9 10.6l2.4 2.4 1.6-1.6L10.6 9 13 6.6 11.4 5z\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[1,[23,0,[\"onSubmitError\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"actions\",\"isLoading\",\"registerInput\",\"unregisterInput\"],[[28,\"hash\",null,[[\"onSubmit\"],[[28,\"perform\",[[23,0,[\"onSubmit\"]]],null]]]],[23,0,[\"onSubmit\",\"isRunning\"]],[28,\"action\",[[23,0,[]],[23,0,[\"registerInput\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"unregisterInput\"]]],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/form/template.hbs"
    }
  });

  _exports.default = _default;
});