define('ember-mobile-pane/components/mobile-pane/nav', ['exports', 'ember-mobile-pane/templates/components/mobile-pane/nav', 'ember-mobile-pane/mixins/component-parent', 'ember-mobile-pane/components/mobile-pane/nav/item', 'ember-mobile-core/tween'], function (exports, _nav, _componentParent, _item, _tween) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentParent.default, {
    layout: _nav.default,

    tagName: 'nav',

    classNames: ['mobile-pane__nav'],

    // public
    navScrollOffset: 75,
    transitionDuration: 0,

    // protected
    activeIndex: 0,
    activePane: null,
    navItems: null,
    navOffset: 0,
    transitionsEnabled: true,

    // private
    indicator: null,
    initialRender: true,
    runningAnimation: null,

    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),

    /**
     * Fired when a nav item is clicked
     */
    onItemClick: function onItemClick() {},


    // lifecycle
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.set(this, 'indicator', document.getElementById(Ember.get(this, 'elementId') + '-nav__indicator'));

      this._updateStyle();
    },


    childNavItems: Ember.computed.filter('children', function (view) {
      return view instanceof _item.default;
    }),

    updateStyle: Ember.observer('navOffset', 'activeIndex', 'childNavItems.@each.elementId', 'elementId', function () {
      Ember.run.once(this, this._updateStyle);
    }),

    _updateStyle: function _updateStyle() {

      if (this.get('fastboot.isFastboot')) {
        return;
      }

      var activeIndex = Ember.get(this, 'activeIndex');
      var childNavItems = Ember.get(this, 'childNavItems');
      var element = Ember.get(this, 'element');
      var navOffset = Ember.get(this, 'navOffset');
      var navScrollOffset = Ember.get(this, 'navScrollOffset');

      var e1Index = Math.floor(navOffset);
      var e2Index = Math.ceil(navOffset);

      if (Ember.get(this, 'runningAnimation')) {
        Ember.get(this, 'runningAnimation').stop();
        Ember.set(this, 'runningAnimation', null);
      }

      if (childNavItems.length && e1Index < childNavItems.length && e2Index < childNavItems.length) {
        // the first element is always present
        var e1Dims = Ember.get(childNavItems.objectAt(e1Index), 'element').getBoundingClientRect();
        var e2Dims = Ember.get(childNavItems.objectAt(e2Index), 'element').getBoundingClientRect();
        var navDims = element.getBoundingClientRect();
        var navLeft = navDims.left;
        var navScrollLeft = element.scrollLeft;

        var targetLeft = e1Dims.left;
        var targetWidth = e1Dims.width;

        if (e1Index !== e2Index) {
          var relativeOffset = navOffset - e1Index;

          // map relativeOffset to correct ranges
          targetLeft = relativeOffset * (e2Dims.left - e1Dims.left) + e1Dims.left;
          targetWidth = (1 - relativeOffset) * (e1Dims.width - e2Dims.width) + e2Dims.width;
        }

        // correct for nav scroll and offset to viewport
        var scrollLeftTarget = targetLeft - navLeft;
        var indicatorLeftTarget = scrollLeftTarget + navScrollLeft;

        // make scroll follow pan and click
        var targetIsElement1 = navOffset - activeIndex < 0;
        var targetElementIndex = targetIsElement1 ? e1Index : e2Index;

        if (targetElementIndex === activeIndex) {
          // pan ended or a menu change happened (i.e. by click)

          this._finishTransition(navDims, e1Dims, e2Dims, navScrollLeft, navScrollOffset, indicatorLeftTarget, targetWidth, targetIsElement1);
        } else {
          // a pan is happening

          this._followPan(scrollLeftTarget, navScrollOffset, indicatorLeftTarget, targetWidth);
        }
      }
    },
    _finishTransition: function _finishTransition(navDims, e1Dims, e2Dims, navScrollLeft, navScrollOffset, indicatorLeftTarget, indicatorWidthTarget, targetIsElement1) {
      var _this = this;

      var indicatorDims = Ember.get(this, 'indicator').getBoundingClientRect();
      var navScrollWidth = Ember.get(this, 'element').scrollWidth;

      // change scroll based on target position
      var indicatorLeft = indicatorDims.left + navScrollLeft - navDims.left;
      var indicatorWidth = indicatorDims.width;

      var scrollLeftMax = navScrollWidth - navDims.width;
      var targetElementLeft = targetIsElement1 ? e1Dims.left : e2Dims.left;
      var scrollLeftTarget = Math.max(Math.min(navScrollLeft + targetElementLeft - navScrollOffset - navDims.left, scrollLeftMax), 0);

      var scrollDiff = scrollLeftTarget - navScrollLeft;
      var indicatorLeftDiff = indicatorLeftTarget - indicatorLeft;
      var indicatorWidthDiff = indicatorWidthTarget - indicatorWidth;

      if (scrollDiff !== 0 || indicatorLeftDiff !== 0 || indicatorWidthDiff !== 0) {
        if (Ember.get(this, 'initialRender')) {
          this._applyStyle(navScrollLeft + scrollDiff, indicatorLeft + indicatorLeftDiff, indicatorWidth + indicatorWidthDiff);
          Ember.set(this, 'initialRender', false);
        } else {
          var anim = new _tween.default(function (progress) {
            _this._applyStyle(navScrollLeft + scrollDiff * progress, indicatorLeft + indicatorLeftDiff * progress, indicatorWidth + indicatorWidthDiff * progress);
          }, { duration: Ember.get(this, 'transitionDuration') });
          Ember.set(this, 'runningAnimation', anim);
          anim.start();
        }
      }
    },
    _followPan: function _followPan(scrollLeftTarget, navScrollOffset, indicatorLeftTarget, indicatorWidthTarget) {
      // change scroll based on indicator position
      if (scrollLeftTarget > 50) {
        Ember.get(this, 'element').scrollLeft += scrollLeftTarget - navScrollOffset;
      } else {
        Ember.get(this, 'element').scrollLeft -= navScrollOffset - scrollLeftTarget;
      }
      Ember.get(this, 'indicator').style.transform = 'translateX(' + indicatorLeftTarget + 'px) scaleX(' + indicatorWidthTarget + ')';
    },
    _applyStyle: function _applyStyle(scrollLeft, indicatorLeft, indicatorWidth) {
      Ember.get(this, 'element').scrollLeft = scrollLeft;
      Ember.get(this, 'indicator').style.transform = 'translateX(' + indicatorLeft + 'px) scaleX(' + indicatorWidth + ')';
    }
  });
});