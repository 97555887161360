define("@outdoorsyco/ember-shared-data/components/modals/update-user-info/component", ["exports", "@outdoorsyco/ember-shared-data/components/modals/update-user-info/template", "ember-cp-validations"], function (_exports, _template, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'user.firstName': (0, _emberCpValidations.validator)('presence', true),
    'user.lastName': (0, _emberCpValidations.validator)('presence', true),
    'user.phone': (0, _emberCpValidations.validator)('presence', true),
    'user.state': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.requireState')
    })
  });

  var _default = Ember.Component.extend(Validations, {
    layout: _template.default,
    apiRequest: Ember.inject.service(),
    showErrors: false,
    user: null,
    integration: null,
    missingFields: null,

    init() {
      this._super(...arguments);

      let passedVars = this.get('attrs.data.value') || {};
      this.missingFields = passedVars.missingFields;
      this.user = passedVars.user;
      this.integration = passedVars.integration;
    },

    requiredFields: Ember.computed('missingFields', function () {
      let fields = this.missingFields || [];

      if (this.get('integration')) {
        fields = this.get('integration.missingFields');
      }

      if (Ember.isArray(fields)) {
        return fields;
      }

      let required = [];

      if (typeof fields === 'object') {
        if (fields.renter) {
          required = fields.renter.map(f => {
            return f.replace('profile.', '');
          });
        }
      }

      return required;
    }),
    requireState: Ember.computed('requiredFields.[]', function () {
      return this.get('requiredFields').some(f => {
        return f.indexOf('address.state') !== -1;
      });
    }),
    requireAddress: Ember.computed('requiredFields.[]', function () {
      return this.get('requiredFields').some(f => {
        return f.indexOf('address.') !== -1;
      });
    }),

    async save() {
      let u = await this.user;

      try {
        await u.save();
        let log = Ember.get(this, 'integration.lastServiceLog');

        if (log && log.failed) {
          await this.retryServiceRequest(log);
        }

        if (this.get('on-save')) {
          this.get('on-save')();
        }

        this.modal.close();
      } catch (e) {
        console.log(e);
      }
    },

    async retryServiceRequest(log) {
      try {
        await this.apiRequest.post(`integrations/request_log/${log.id}/retry`);
      } catch (e) {
        console.log(e);
      }
    },

    actions: {
      saveAddress(address) {
        this.get('user').then(renter => {
          renter.setProperties({
            street: address.street,
            street1: address.street1,
            city: address.city,
            state: address.state,
            zip: address.zip,
            county: address.county,
            country: address.country
          });
        });
      },

      saveAndContinue() {
        this.validate().then(({
          validations
        }) => {
          this.set('showErrors', true);

          if (validations.get('isValid')) {
            this.save();
          }
        });
      }

    }
  });

  _exports.default = _default;
});