define('ember-mobile-pane/components/mobile-pane/infinite-scroller/child', ['exports', 'ember-mobile-pane/templates/components/mobile-pane/infinite-scroller/child'], function (exports, _child) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _child.default,

    classNames: ['mobile-pane__child'],
    attributeBindings: ['style'],

    // protected
    setAsDocumentScroll: false,
    scroll: 0,
    offsetTop: 0,

    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'setAsDocumentScroll')) {
        var current = document.scrollingElement || document.documentElement;
        current.scrollTop = Ember.get(this, 'scroll');
      } else {
        this.element.querySelector('.mobile-pane__child-transformable').style.transform = 'translateY(-' + Ember.get(this, 'scroll') + 'px)';
      }
    },


    style: Ember.computed('offsetTop', function () {
      return Ember.get(this, 'offsetTop') ? Ember.String.htmlSafe('transform: translateY(' + Ember.get(this, 'offsetTop') + 'px);') : null;
    })
  });
});