define("ember-mapbox-gl/components/mapbox-gl-marker", ["exports", "ember-mapbox-gl/templates/components/mapbox-gl-marker", "ember-mapbox-gl/-private/mapbox-loader"], function (_exports, _mapboxGlMarker, _mapboxLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A utility that brokers HTTP requests...
   *
   * @class MapboxGlMarker
   */
  var _default = Ember.Component.extend({
    layout: _mapboxGlMarker.default,
    map: null,
    initOptions: null,
    lngLat: null,

    init() {
      this._super(...arguments);

      this.marker = null;
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, this._setup);
    },

    didUpdateAttrs() {
      this._super(...arguments);

      if (this.marker !== null) {
        const lngLat = Ember.get(this, 'lngLat');
        (false && !(lngLat) && Ember.assert('mapbox-gl-marker requires lngLat, maybe you passed latLng?', lngLat));
        this.marker.setLngLat(lngLat);
      }
    },

    willDestroy() {
      this._super(...arguments);

      if (this.marker !== null) {
        this.marker.remove();
      }
    },

    _setup() {
      const {
        lngLat,
        initOptions
      } = Ember.getProperties(this, 'lngLat', 'initOptions');
      (false && !(lngLat) && Ember.assert('mapbox-gl-marker requires lngLat, maybe you passed latLng?', lngLat));
      const options = Ember.assign({}, Ember.get(Ember.getOwner(this).resolveRegistration('config:environment'), 'mapbox-gl.marker'), initOptions);
      const marker = new _mapboxLoader.default.Mapbox.Marker(this.element, options).setLngLat(lngLat).addTo(this.map);
      Ember.set(this, 'marker', marker);
    }

  });

  _exports.default = _default;
});