define("@outdoorsyco/ember-shared-data/services/experiments", ["exports", "ember-experiments/services/experiments"], function (_exports, _experiments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExperimentsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class ExperimentsService extends _experiments.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "apiRequest", _descriptor2, this);

      _initializerDefineProperty(this, "environment", _descriptor3, this);

      _defineProperty(this, "fetchedExperiments", []);
    }

    get logger() {
      return Ember.getOwner(this).lookup('logger:outdoorsy');
    }
    /*
     * There are currently two types of experiments, persistent and volatile.
     *
     * A persistent experiment will use the variant value of from the
     * `ember-experiments` cookie if it exists. The only way to reset the variant
     * is to have the user delete their cookie, or rename the experiment. The
     * basic setup of a persistent experiment looks like the following:
     *
     * this.experiments.setup({ expName: 'test-experiment' });
     *
     * A volatile experiment will ignore the variant in the cookie, and will
     * always make an initial request to the experiments back-end. Once the
     * experiment has been setup in the app instance, additional calls to setup
     * the experiment will use the value from the cookie. The basic setup for a
     * volatile experiment looks like the following:
     *
     * this.experiments.setup({
     *   expName: 'test-experiment',
     *   options: { volatile: true }
     * });
     */


    async setup({
      expName,
      options = {}
    }) {
      // if we don't have an experiment name, we don't know what to setup!
      if (!expName) {
        throw 'Missing experiment name';
      } // check if this experiment is already setup


      if (options.volatile && this.fetchedExperiments.includes(expName) && this.alreadyDefined(expName) || !options.volatile && this.alreadyDefined(expName)) {
        return this.getVariation(expName);
      }

      let variation = undefined;
      this.fetchedExperiments.push(expName); // when environment === test use the inTesting variation

      if (this.isTesting && options.inTesting) {
        variation = options.inTesting;
      }

      if (!variation || this.isDestroyed || this.isDestroying) {
        return;
      }

      this.enable(expName, variation);
      return variation;
    }

    enable() {
      super.enable(...arguments);
      Ember.sendEvent(this, 'variantUpdated', arguments);
    }
    /**
     * if `useDID` is true return it
     * otherwise:
     * use the `userId` if it exists
     * if no `userId`, use the `did`
     * @param {boolean} useDID
     *
     * @return {Object}
     * @property {string} id
     * @property {string} type
     */


    _expUserId(useDID) {
      const userId = this.session.ownedById || this.session.userId;

      if (useDID || !userId) {
        return {
          id: this._getDID(),
          type: 'did'
        };
      }

      return {
        id: userId.toString(),
        type: 'uid'
      };
    }

    _getDID() {
      const getIdentifier = window && Ember.get(window, 'Outdoorsy.metrics.getIdentifier');
      const {
        did
      } = getIdentifier() || {};

      if (!did) {
        throw new Error('No device id found. Aborting experiments request');
      }

      return did;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ExperimentsService;
});