define("@outdoorsyco/outdoorsy-addon/components/language-link-select/component", ["exports", "jquery", "@outdoorsyco/outdoorsy-addon/components/language-link-select/template", "@outdoorsyco/ember-shared-data/utils/domain-languages"], function (_exports, _jquery, _template, _domainLanguages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['language-link-dropdown'],
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    supportsSVGJar: true,
    includeLangsWithDomain: true,
    //public
    selection: null,
    //private
    isOpen: false,

    init() {
      this._super(...arguments);

      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      let protocol = config.environment === 'development' ? 'http:' : 'https:';
      this.set('protocol', protocol); // dashboard does not have fastboot so conditionally bring it in

      this.set('fastboot', Ember.getOwner(this).lookup('service:fastboot'));

      let countries = _domainLanguages.domainLanguagesArray.concat(_domainLanguages.pendingDomainLanguages);

      if (this.includeLangsWithDomain) {
        countries = countries.reduce((arr, c) => {
          if (c.host) {
            arr.push(c);
          }

          return arr;
        }, []);
      }

      this.countriesMenu = countries;
    },

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(document).on('click.languageSelect', Ember.run.bind(this, this._clickHandler));
    },

    willDestroyElement() {
      (0, _jquery.default)(window).off('click.languageSelect', Ember.run.bind(this, this._clickHandler));

      this._super(...arguments);
    },

    selectedObject: Ember.computed('selection', function () {
      let selected = this.get('countriesMenu').findBy('langs.firstObject', this.get('selection'));

      if (!selected) {
        selected = this.get('countriesMenu').get('firstObject');
      }

      return selected;
    }),
    actions: {
      openDropdown() {
        this.set('isOpen', true);
      },

      updateCountry(object) {
        if (this.get('onChange')) {
          this.get('onChange')(object);
        }

        this.set('selection', object.country);
        this.set('isOpen', false);
        return false;
      }

    },

    _clickHandler(e) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      if (this.get('isOpen') && (0, _jquery.default)(e.target).closest('.language-link-dropdown').length > 0) {
        return;
      }

      this.set('isOpen', false);
    }

  });

  _exports.default = _default;
});