define("ember-power-select/components/power-select-multiple/trigger", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const isIndexAccesible = target => {
    return typeof target.objectAt === 'function';
  };

  const ua = window && window.navigator ? window.navigator.userAgent : '';
  const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  let Trigger = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class Trigger extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "inputFont", void 0);

      _defineProperty(this, "_lastIsOpen", this.args.select.isOpen);

      _initializerDefineProperty(this, "textMeasurer", _descriptor, this);
    }

    // Properties
    get triggerMultipleInputStyle() {
      Ember.run.scheduleOnce('actions', null, this.args.select.actions.reposition);

      if (!this.args.select.selected || Ember.get(this.args.select.selected, 'length') === 0) {
        return Ember.String.htmlSafe('width: 100%;');
      } else {
        let textWidth = 0;

        if (this.inputFont) {
          textWidth = this.textMeasurer.width(this.args.select.searchText, this.inputFont);
        }

        return Ember.String.htmlSafe(`width: ${textWidth + 25}px`);
      }
    }

    get maybePlaceholder() {
      if (isIE) {
        return undefined;
      }

      return !this.args.select.selected || Ember.get(this.args.select.selected, 'length') === 0 ? this.args.placeholder || '' : '';
    } // Actions


    openChanged(_el, [isOpen]) {
      if (isOpen === false && this._lastIsOpen === true) {
        Ember.run.scheduleOnce('actions', null, this.args.select.actions.search, '');
      }

      this._lastIsOpen = isOpen;
    }

    storeInputStyles(input) {
      let {
        fontStyle,
        fontVariant,
        fontWeight,
        fontSize,
        lineHeight,
        fontFamily
      } = window.getComputedStyle(input);
      this.inputFont = `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize}/${lineHeight} ${fontFamily}`;
    }

    chooseOption(e) {
      if (e.target === null) return;
      let selectedIndex = e.target.getAttribute('data-selected-index');

      if (selectedIndex) {
        let numericIndex = parseInt(selectedIndex, 10);
        e.stopPropagation();
        e.preventDefault();
        let object = this.selectedObject(this.args.select.selected, numericIndex);
        this.args.select.actions.choose(object);
      }
    }

    handleInput(e) {
      if (this.args.onInput && this.args.onInput(e) === false) {
        return;
      }

      this.args.select.actions.open(e);
    }

    handleKeydown(e) {
      if (e.target === null) return;

      if (this.args.onKeydown && this.args.onKeydown(e) === false) {
        e.stopPropagation();
        return false;
      }

      if (e.keyCode === 8) {
        e.stopPropagation();

        if (Ember.isBlank(e.target.value)) {
          let lastSelection = this.args.select.selected[this.args.select.selected.length - 1];

          if (lastSelection) {
            this.args.select.actions.select(this.args.buildSelection(lastSelection, this.args.select), e);

            if (typeof lastSelection === 'string') {
              this.args.select.actions.search(lastSelection);
            } else {
              (false && !(this.args.searchField) && Ember.assert('`<PowerSelectMultiple>` requires a `@searchField` when the options are not strings to remove options using backspace', this.args.searchField));
              this.args.select.actions.search(Ember.get(lastSelection, this.args.searchField));
            }

            this.args.select.actions.open(e);
          }
        }
      } else if (e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32) {
        // Keys 0-9, a-z or SPACE
        e.stopPropagation();
      }
    }

    selectedObject(list, index) {
      if (isIndexAccesible(list)) {
        return list.objectAt(index);
      } else {
        return Ember.get(list, index);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "textMeasurer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openChanged", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "openChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "storeInputStyles", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "storeInputStyles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseOption", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "chooseOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInput", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeydown", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeydown"), _class.prototype)), _class));
  _exports.default = Trigger;
});