define("@outdoorsyco/ember-shared-data/services/count-down", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    //public
    secondsOffset: 600,
    autoRestart: true,
    //private
    timer: null,
    display: null,
    timeout: 1000,

    //TODO allow passing options
    //write tests
    start() {
      this._super(...arguments);

      let ENV = Ember.getOwner(this).resolveRegistration('config:environment');

      if (ENV.environment === 'test') {
        this._setupForTests();
      }

      this._startCountDown();
    },

    stop() {
      this.set('timer', null);
      this.set('display', null);
      this.get('countDown').cancelAll();
    },

    countDown: (0, _emberConcurrency.task)(function* () {
      while (this.timer > 0) {
        yield (0, _emberConcurrency.timeout)(this.timeout);
        this.timer -= 1;

        this._updateDisplay();
      }

      if (this.autoRestart) {
        this._startCountDown();
      }

      if (this.get('countDownCompleted')) {
        this.get('countDownCompleted')();
      }
    }).restartable(),

    _startCountDown() {
      this.timer = this.get('secondsOffset');

      this._updateDisplay();

      this.get('countDown').perform();
    },

    _updateDisplay() {
      if (!(this.isDestroying || this.isDestroyed)) {
        let minutes = Math.floor(this.timer / 60);
        let seconds = `0${this.timer - minutes * 60}`.slice(-2);
        this.set('display', `${minutes}:${seconds}`);
      }
    },

    _setupForTests() {
      this.secondsOffset = 1;
      this.timeout = 1;
      this.autoRestart = this.testAutoRestart || false;
    }

  });

  _exports.default = _default;
});