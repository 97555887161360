define("@outdoorsyco/ember-shared-data/utils/array/min", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = min;

  function min(values, valueof) {
    let min;

    if (valueof === undefined) {
      for (const value of values) {
        if (value !== null && (min > value || min === undefined && value >= value)) {
          min = value;
        }
      }
    } else {
      let index = -1;

      for (let value of values) {
        if ((value = valueof(value, ++index, values)) !== null && (min > value || min === undefined && value >= value)) {
          min = value;
        }
      }
    }

    return min;
  }
});