define("@outdoorsyco/ember-switchback/components/menu-button/options/component", ["exports", "@glimmer/component", "@outdoorsyco/ember-switchback/utils/key-codes", "@outdoorsyco/ember-switchback/utils/focus"], function (_exports, _component, _keyCodes, _focus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MenuButtonOptions = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class MenuButtonOptions extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "switchback", _descriptor, this);
    }

    get optionsElement() {
      return document.getElementById(this.args.menuId);
    }

    onKeyDown(event) {
      switch (event.which) {
        case _keyCodes.DOWN_ARROW:
          event.preventDefault();
          (0, _focus.focusNextElement)({
            scope: this.optionsElement
          });
          break;

        case _keyCodes.UP_ARROW:
          event.preventDefault();
          (0, _focus.focusPreviousElement)({
            scope: this.optionsElement
          });
          break;

        case _keyCodes.HOME:
          event.preventDefault();
          (0, _focus.focusFirstElement)({
            scope: this.optionsElement
          });
          break;

        case _keyCodes.END:
          event.preventDefault();
          (0, _focus.focusLastElement)({
            scope: this.optionsElement
          });
          break;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "switchback", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype)), _class));
  _exports.default = MenuButtonOptions;
});