define("@outdoorsyco/ember-switchback/validators/required", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.required = required;
  _exports.default = void 0;

  function required(value, required) {
    const validatorIsDisabled = !required;
    const validValue = required && !Ember.isEmpty(value) && value;
    return validatorIsDisabled || validValue;
  }

  var _default = {
    param: 'required',
    error: {
      key: 'error.validator.required',
      message: 'Please fill this out.'
    },
    validate: required
  };
  _exports.default = _default;
});