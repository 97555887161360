define("@outdoorsyco/ember-shared-data/components/invite-driver/component", ["exports", "@outdoorsyco/ember-shared-data/components/invite-driver/template", "ember-concurrency", "ember-cp-validations"], function (_exports, _template, _emberConcurrency, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true),
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    layout: _template.default,
    intl: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    name: null,
    email: null,
    showErrors: false,

    async submit() {
      await this.validate();

      if (this.validations.isValid) {
        this.get('sendInvitation').perform();
      } else {
        this.set('showErrors', true);
      }
    },

    sendInvitation: (0, _emberConcurrency.task)(function* () {
      try {
        const bookingId = this.get('attrs.data.value.bookingId');
        const asDriver = this.get('attrs.data.value.driver');
        const source = this.get('attrs.data.value.location');
        const url = `bookings/${bookingId}/drivers/invite`;
        const data = {
          name: this.name,
          email: this.email,
          driver: asDriver,
          source: source
        };
        yield this.apiRequest.post(url, {
          data
        });
        this.flashMessages.success(this.intl.t('modal-invite-driver-request-sent'));
      } catch (response) {
        let message = this.intl.t('modal-invite-driver-request-error');

        if (Ember.isPresent(response.payload) && Ember.isPresent(response.payload.error)) {
          message = response.payload.error;
        }

        this.flashMessages.danger(message);
      } finally {
        this.accept();
      }
    })
  });

  _exports.default = _default;
});