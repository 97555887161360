define("@outdoorsyco/ember-shared-data/components/booking/review/photo-uploader/upload-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray400": "hsl(212, 14%, 80%)",
    "upload-button": "_upload-button_1291dz",
    "icon": "_icon_1291dz",
    "bad": "_bad_1291dz",
    "button": "_button_1291dz"
  };
  _exports.default = _default;
});