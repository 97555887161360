define("@outdoorsyco/ember-switchback/styles/background/shadow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "raisedShadow100": "0 1px 1px 0 hsla(0, 0%, 0%, 0.1), 0 1px 1px 0 hsla(0, 0%, 0%, 0.06)",
    "raisedShadow200": "0 1px 3px 0 hsla(0, 0%, 0%, 0.1), 0 1px 2px 0 hsla(0, 0%, 0%, 0.06)",
    "raisedShadow300": "0 4px 6px -1px hsla(0, 0%, 0%, 0.1), 0 2px 4px -1px hsla(0, 0%, 0%, 0.06)",
    "raisedShadow400": "0 10px 15px -3px hsla(0, 0%, 0%, 0.1), 0 4px 6px -2px hsla(0, 0%, 0%, 0.05)",
    "raisedShadow500": "0 20px 25px -5px hsla(0, 0%, 0%, 0.1), 0 10px 10px -5px hsla(0, 0%, 0%, 0.04)",
    "raisedShadow600": "0 24px 64px 0 hsla(0, 0%, 0%, 0.15)"
  };
  _exports.default = _default;
});