define("@outdoorsyco/ember-shared-data/components/password-strength/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "20M4BEdU",
    "block": "{\"symbols\":[\"@strength\",\"&attrs\"],\"statements\":[[7,\"div\",false],[13,2],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"PasswordStrength\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/password-strength/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"bars\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"Bar Strength\",[23,0,[\"numberOfBars\"]]],null]],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/password-strength/styles\"]]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"numberOfBars\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"  text-gray900 text-font100 padding-top-0.5 padding-bottom-1 \"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"gte\",[[23,1,[]],3],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"comp-password-strength-strong\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"comp-password-strength-weak\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/password-strength/template.hbs"
    }
  });

  _exports.default = _default;
});