define("@outdoorsyco/ember-shared-data/validator/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.password = password;
  _exports.default = void 0;

  function password(value, password) {
    const validatorIsDisabled = !password;
    const noValueToValidate = Ember.isEmpty(value);
    const validValue = value.length >= 8 && value.match(/[0-9]/);
    return validatorIsDisabled || noValueToValidate || validValue;
  }

  var _default = {
    param: 'password',
    error: {
      key: 'error.validator.password',
      message: 'This must be at least 8 characters long and include a number.'
    },
    validate: password
  };
  _exports.default = _default;
});