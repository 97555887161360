define('ember-mobile-pane/components/mobile-pane/infinite-scroller', ['exports', 'ember-mobile-pane/templates/components/mobile-pane/infinite-scroller'], function (exports, _infiniteScroller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _infiniteScroller.default,

    classNames: ['mobile-pane__infinite-scroller'],

    router: Ember.inject.service(),
    memory: Ember.inject.service('memory-scroll'),

    //public
    previousModel: null,
    currentModel: null,
    nextModel: null,

    //private
    prevChildScroll: 0,
    currentChildScroll: 0,
    nextChildScroll: 0,
    childOffsetTop: 0,

    onDragStart: function onDragStart() {},
    onDragMove: function onDragMove(dx) {},
    onDragEnd: function onDragEnd(activeIndex) {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      //TODO: purge scroll states if we came from a higher level route
      this._setupScroller();
    },


    updateActiveIndex: Ember.observer('models.@each.id', function () {
      var _this = this;

      Ember.run.once(function () {
        // we received new models, update activeIndex to the currentModel index
        _this._setupScroller();
      });
    }),

    _setupScroller: function _setupScroller() {
      this.restoreScroll();

      var activeIndex = Ember.get(this, 'previousModel') ? 1 : 0;
      Ember.get(this, 'onDragEnd')(activeIndex);
    },


    models: Ember.computed('previousModel', 'currentModel', 'nextModel', function () {
      return Ember.A([Ember.get(this, 'previousModel'), Ember.get(this, 'currentModel'), Ember.get(this, 'nextModel')].filter(Boolean));
    }),

    actions: {
      onDragStart: function onDragStart() {
        // write scroll offset for prev/next children
        Ember.set(this, 'childOffsetTop', document.scrollingElement.scrollTop || document.documentElement.scrollTop);

        Ember.get(this, 'onDragStart').apply(undefined, arguments);
      },
      onDragMove: function onDragMove() {
        Ember.get(this, 'onDragMove').apply(undefined, arguments);
      },
      onDragEnd: function onDragEnd(targetIndex) {
        // transition to previous or next model
        var targetModel = Ember.get(this, 'models').objectAt(targetIndex);
        if (targetModel !== Ember.get(this, 'currentModel')) {
          // store the scroll position of currentModel
          this.storeScroll();

          // transition to the targetModel on the current route
          Ember.get(this, 'router').transitionTo(Ember.get(this, 'router.currentRouteName'), targetModel);
        }

        Ember.get(this, 'onDragEnd').apply(undefined, arguments);
      }
    },

    storeScroll: function storeScroll() {
      var key = this._buildMemoryKey(this.get('currentModel.id'));
      this.get('memory')[key] = document.scrollingElement.scrollTop || document.documentElement.scrollTop;
    },
    restoreScroll: function restoreScroll() {
      var prevKey = this._buildMemoryKey(this.get('previousModel.id'));
      var currentKey = this._buildMemoryKey(this.get('currentModel.id'));
      var nextKey = this._buildMemoryKey(this.get('nextModel.id'));

      Ember.set(this, 'prevChildScroll', this.get('memory')[prevKey] || 0);
      Ember.set(this, 'currentChildScroll', this.get('memory')[currentKey] || 0);
      Ember.set(this, 'nextChildScroll', this.get('memory')[nextKey] || 0);
    },


    // utils
    _buildMemoryKey: function _buildMemoryKey(id) {
      return 'mobile-pane/' + this.get('router.currentRouteName') + '.' + id;
    }
  });
});