define("calendar-picker/components/od-ddpicker", ["exports", "calendar-picker/mixins/od-rangepick", "calendar-picker/templates/components/od-ddpicker", "moment"], function (_exports, _odRangepick, _odDdpicker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_odRangepick.default, {
    tagName: 'div',
    layout: _odDdpicker.default,
    format: 'MMMM YYYY',
    media: Ember.inject.service(),
    classNames: ['od-ddpicker'],
    attributeBindings: ['data-odcp-guid'],
    matchTriggerWidth: false,
    startPlaceholder: null,
    endPlaceholder: null,
    isDropdown: true,
    selectDatesText: "Pick dates",
    selectStartText: "Pick start",
    selectEndText: "Pick end",
    'data-odcp-guid': Ember.computed.oneWay('guid'),
    mobileDevice: Ember.computed.or('media.isMobile', 'media.isTablet'),
    triggerElement: null,
    selectedDates: Ember.computed('start', 'end', function () {
      let fromDate = this.get('start');
      let toDate = this.get('end');
      let {
        selectDatesText,
        selectStartText,
        selectEndText
      } = this.getProperties(['selectDatesText', 'selectStartText', 'selectEndText']);
      fromDate = fromDate && (0, _moment.default)(fromDate).isValid() ? (0, _moment.default)(fromDate).format('MMM D, YYYY') : null;
      toDate = toDate && (0, _moment.default)(toDate).isValid() ? (0, _moment.default)(toDate).format('MMM D, YYYY') : null;
      return !fromDate && !toDate ? selectDatesText : `${fromDate || selectStartText}  →  ${toDate || selectEndText}`;
    }),
    actions: {
      onOpen(publicApi, e) {
        if (typeof FastBoot === 'undefined' && this.get('mobileDevice') && e) {
          // iOS fix for blinking cursor on top of modal
          document.body.classList.add('od-dropdown-no-overflow');
          (false && !(e) && Ember.assert('Expects an event or element for mobile open to work', e));
          let element = e.target ? e.target : e;
          this.set('triggerElement', element);
          element.disabled = true;
        }

        if (this.get('willOpen')) {
          this.get('willOpen')();
        }

        return true;
      },

      onClose() {
        if (typeof FastBoot === 'undefined' && this.get('mobileDevice') && this.get('triggerElement')) {
          document.body.classList.remove('od-dropdown-no-overflow'); // iOS fix for blinking cursor on top of modal

          let triggerElement = this.get('triggerElement');

          if (triggerElement) {
            triggerElement.disabled = false;
          }
        }

        if (this.get('didClose')) {
          this.get('didClose')();
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});