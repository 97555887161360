define("@outdoorsyco/ember-shared-data/components/booking/handoff/photo-uploader/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sm": "576px",
    "uploader": "_uploader_bbd1ly",
    "dropzone": "_dropzone_bbd1ly",
    "uploaded-images": "_uploaded-images_bbd1ly",
    "image": "_image_bbd1ly",
    "image-overlay": "_image-overlay_bbd1ly",
    "image-delete": "_image-delete_bbd1ly"
  };
  _exports.default = _default;
});