define("@outdoorsyco/ember-shared-data/controllers/modals/refund-security-deposit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    intl: Ember.inject.service(),
    refundAmount: null,
    refundSelected: 'part',
    refundOptions: Ember.computed('booking.{securityDepositStatus,holdSecurityDeposit}', 'intl.locale', function () {
      let options = [];
      let intl = this.get('intl');
      let status = this.get('model.booking.securityDepositStatus');

      if (status === 'paid' || status === 'hold') {
        if (!this.get('model.booking.holdSecurityDeposit')) {
          options.push({
            display: intl.t('modal-refund-security-deposit-option-hold'),
            value: 'hold'
          });
        }

        options.push({
          display: intl.t('modal-refund-security-deposit-option-claim'),
          value: 'claim'
        });
        options.push({
          display: intl.t('modal-refund-security-deposit-option-all'),
          value: 'all'
        });
      }

      options.push({
        display: intl.t('modal-refund-security-deposit-option-part'),
        value: 'part'
      });
      return options;
    }),
    buttonText: Ember.computed('refundSelected', 'refundAmount', 'intl.locale', function () {
      let intl = this.get('intl');

      switch (this.get('refundSelected')) {
        case 'part':
          return intl.t('modal-refund-security-deposit-button-part');

        case 'claim':
          return intl.t('modal-refund-security-deposit-button-claim');

        case 'all':
          return intl.t('modal-refund-security-deposit-button-all');

        case 'hold':
          return intl.t('modal-refund-security-deposit-button-hold');
      }
    }),
    modalMessage: Ember.computed('refundSelected', 'intl.locale', 'model.booking.renter.firstName', function () {
      let intl = this.get('intl');

      switch (this.get('refundSelected')) {
        case 'hold':
          return intl.t('modal-refund-security-deposit-must-add-message-prompt', {
            firstName: this.get('model.booking.renter.firstName')
          });

        default:
          return intl.t('modal-refund-security-deposit-add-message-prompt', {
            firstName: this.get('model.booking.renter.firstName')
          });
      }
    }),
    observeModel: Ember.observer('model.remainingAmount', 'model.booking.holdSecurityDeposit', function () {
      return this.setProperties({
        messageBody: '',
        refundAmount: this.get('model.remainingAmount'),
        refundSelected: 'part'
      });
    }),
    actions: {
      processRefund() {
        let amount, data, message;
        let intl = this.get('intl');
        this.setProperties({
          error: null,
          loading: true
        });
        let selected = this.get('refundSelected');

        if (selected === 'part') {
          amount = Number(this.get('refundAmount'));

          if (!amount || Boolean(isNaN(amount))) {
            this.setProperties({
              error: intl.t('modal-refund-security-deposit-error-part')
            });
            return;
          }

          data = {
            amount: amount * 100,
            message: this.get('messageBody')
          };
        } else if (selected === 'claim') {
          amount = 0;
          data = {
            amount,
            message: this.get('messageBody')
          };
        } else if (selected === 'all') {
          amount = Number(this.model.get('amount'));

          if (!amount || Boolean(isNaN(amount))) {
            this.setProperties({
              error: intl.t('modal-refund-security-deposit-error-all')
            });
            return;
          }

          data = {
            amount: amount * 100,
            message: this.get('messageBody')
          };
        } else if (selected === 'hold') {
          message = this.get('messageBody');

          if (!message) {
            this.setProperties({
              error: intl.t('modal-refund-security-deposit-error-hold-message')
            });
            return;
          }

          data = {
            message: this.get('messageBody'),
            hold: true
          };
        } else {
          this.setProperties({
            error: intl.t('modal-refund-security-deposit-error-hold')
          });
          return;
        }

        let bookingID = this.get('model.booking.id');
        let id = this.get('model.id');
        let url = `bookings/${bookingID}/transactions/${id}/refund`;
        data = JSON.stringify(data);
        return this.apiRequest.post(url, {
          data: data
        }).then(() => {
          return this.get('model.booking').then(booking => {
            return booking.reload().then(() => {
              booking.incrementProperty('newCharge');
              this.set('loading', false);
              this.send('closeModal');
            });
          });
        }).catch(err => {
          let error = intl.t('modal-refund-security-deposit-error-processing');

          if (err && err.payload && err.payload.error) {
            error = err.payload.error;
          }

          return this.setProperties({
            error,
            loading: false
          });
        });
      }

    }
  });

  _exports.default = _default;
});