define("@outdoorsyco/outdoorsy-addon/components/accepted-payment-methods/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/accepted-payment-methods/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    description: true
  });

  _exports.default = _default;
});