define("@outdoorsyco/ember-switchback/components/radioset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mzHLVihl",
    "block": "{\"symbols\":[\"@maxWidth\",\"&attrs\",\"@onChange\",\"@disabled\",\"@value\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Radioset\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/radioset/styles\"]]]]]],[12,\"id\",[23,0,[\"fieldsetId\"]]],[12,\"role\",\"radiogroup\"],[12,\"data-max-width\",[23,1,[]]],[13,2],[3,\"on\",[\"keydown\",[23,0,[\"onKeyDown\"]]]],[3,\"on\",[\"focusin\",[23,0,[\"onFocusin\"]]]],[3,\"on\",[\"focusout\",[23,0,[\"onFocusout\"]]]],[3,\"did-insert\",[[23,0,[\"onDidInsert\"]]]],[8],[0,\"\\n\"],[0,\"  \"],[14,6,[[28,\"hash\",null,[[\"Option\"],[[28,\"component\",[\"radioset/option\"],[[\"radiosetValue\",\"radiosetDisabled\",\"registerOption\",\"unregisterOption\",\"onChange\",\"errored\",\"maxWidth\"],[[23,5,[]],[23,4,[]],[28,\"action\",[[23,0,[]],[23,0,[\"registerOption\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"unregisterOption\"]]],null],[23,3,[]],[23,0,[\"isErrored\"]],\"34\"]]]]]]]],[0,\"\\n\\n  \"],[5,\"input-error\",[[12,\"id\",[23,0,[\"errorId\"]]]],[[\"@showWhen\",\"@align\"],[[23,0,[\"showValidationErrors\"]],\"left\"]],{\"statements\":[[0,\"\\n    \"],[1,[23,0,[\"validationErrors\",\"firstObject\",\"message\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/radioset/template.hbs"
    }
  });

  _exports.default = _default;
});