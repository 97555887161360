define("@outdoorsyco/ember-switchback/utils/focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTabableElements = getTabableElements;
  _exports.focusNextElement = focusNextElement;
  _exports.focusPreviousElement = focusPreviousElement;
  _exports.focusFirstElement = focusFirstElement;
  _exports.focusLastElement = focusLastElement;

  /**
   * Gets all html elements that are capable of receiving focus inside
   * another html element.
   *
   * @param {HTMLElement} element
   * @return {array} elements - Array of DOM elements
   */
  function getTabableElements(element) {
    const selectors = ['*:not([tabindex="-1"])[tabindex]', 'input:not([disabled])', 'textarea:not([disabled])', 'button:not([disabled])'];
    return Array.from(element.querySelectorAll(selectors.join(',')));
  }
  /**
   * Sets focus to the next element in the DOM which is allowed
   * to receive focus.
   *
   * @param {HTMLElement} [params.scope=document.body] - And element in which the focus must stay
   */


  function focusNextElement({
    scope = document.body
  }) {
    let tabables = getTabableElements(scope);
    let activeElementPosition = tabables.indexOf(document.activeElement);
    /**
     * Handles the very last element being active, or there being no
     * active element yet.
     */

    if (activeElementPosition + 1 === tabables.length) {
      let element = tabables[0];

      if (element) {
        element.focus();
        return;
      }
    }

    tabables[activeElementPosition + 1].focus();
  }
  /**
   * Sets focus to the previous element in the DOM which is allowed
   * to receive focus.
   *
   * @param {HTMLElement} [params.scope=document.body] - And element in which the focus must stay
   */


  function focusPreviousElement({
    scope = document.body
  }) {
    let tabables = getTabableElements(scope);
    let activeElementPosition = tabables.indexOf(document.activeElement);
    /**
     * Handles the very first element being active, or there being no
     * active element yet.
     */

    if (activeElementPosition < 1) {
      let element = tabables.pop();

      if (element) {
        element.focus();
        return;
      }
    }

    tabables[activeElementPosition - 1].focus();
  }
  /**
   * Sets focus to the first element in the DOM which is allowed
   * to receive focus.
   *
   * @param {HTMLElement} [params.scope=document.body] - And element in which the focus must stay
   */


  function focusFirstElement({
    scope = document.body
  }) {
    getTabableElements(scope)[0].focus();
  }
  /**
   * Sets focus to the last element in the DOM which is allowed
   * to receive focus.
   *
   * @param {HTMLElement} [params.scope=document.body] - And element in which the focus must stay
   */


  function focusLastElement({
    scope = document.body
  }) {
    getTabableElements(scope).pop().focus();
  }
});