define("@outdoorsyco/ember-shared-data/components/provider/booking/service/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   *  Provides all the logic for different premium services offered on a booking
   *
   *  @argument {object} booking - The booking object
   */
  let ProviderBookingService = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('loadAvailableServices.lastSuccessful.value'), (_class = class ProviderBookingService extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "loadAvailableServices", _descriptor2, this);

      _initializerDefineProperty(this, "availableServices", _descriptor3, this);

      (false && !(this.args.booking) && Ember.assert('You must provide a booking object to <Provider::Booking::Service>', this.args.booking));
      this.loadAvailableServices.perform();
    }

    get isApprovedOrImminent() {
      let status = this.args.booking.status;
      return status === 'approved' || status === 'imminent';
    }

    get hasOutdoorsySupport() {
      /* show support info for outdoorsy users, not dealer-owned users */
      return Ember.get(this, 'args.booking.renter.outdoorsyUser');
    }

    get supportsRoadsideAssistance() {
      let booking = this.args.booking;
      /* Already has roadside assistance on booking */

      if (booking.roadsideService) {
        return true;
      }
      /* Not approved or imminent */


      if (!this.isApprovedOrImminent) {
        return false;
      }

      if (this.availableServices && this.availableServices.findBy('slug', 'custom-roadside-assistance')) {
        return true;
      }

      let roadsideReady = Ember.get(this, 'args.booking.rental.coachnetReady');

      if (this.availableServices && roadsideReady) {
        return this.availableServices.findBy('category', 'roadside');
      }

      return roadsideReady;
    }

    get supportsTripInsurance() {
      let booking = this.args.booking;
      /* Already has trip insurance on booking */

      if (booking.tripInsuranceService) {
        return true;
      }
      /* Not approved or imminent */


      if (!this.isApprovedOrImminent) {
        return false;
      }

      if (this.availableServices) {
        return this.availableServices.findBy('category', 'trip-insurance');
      }

      return false;
    }

    get supportsDamageProtection() {
      let booking = this.args.booking;
      /* Already has damage protection on booking */

      if (booking.damageProtectionService) {
        return true;
      }
      /* Not approved or imminent */


      if (!this.isApprovedOrImminent) {
        return false;
      }

      if (this.availableServices) {
        return this.availableServices.findBy('category', 'damage-protection');
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loadAvailableServices", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        return yield this.store.query('integration', {
          booking_id: this.args.booking.id
        });
      };
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "availableServices", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ProviderBookingService;
});