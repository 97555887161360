define("@outdoorsyco/ember-shared-data/components/form/signup/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "@outdoorsyco/ember-shared-data/validator/password-strength", "@outdoorsyco/ember-shared-data/validator/name", "@outdoorsyco/ember-shared-data/validator/basic-name"], function (_exports, _component, _emberConcurrencyDecorators, _passwordStrength, _name, _basicName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormSignupComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, (_class = class FormSignupComponent extends _component.default {
    // Will end up being set to the zxcvbn password strength library via dynamic import below
    constructor(owner, values) {
      super(...arguments);

      _initializerDefineProperty(this, "experiments", _descriptor, this);

      _initializerDefineProperty(this, "apiError", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "login", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "environment", _descriptor6, this);

      _initializerDefineProperty(this, "name", _descriptor7, this);

      _initializerDefineProperty(this, "email", _descriptor8, this);

      _initializerDefineProperty(this, "phone", _descriptor9, this);

      _initializerDefineProperty(this, "password", _descriptor10, this);

      _defineProperty(this, "gRecaptcha", null);

      _initializerDefineProperty(this, "passwordStrengthLibrary", _descriptor11, this);

      _defineProperty(this, "passwordStrengthValidator", _passwordStrength.default);

      _defineProperty(this, "nameStrengthValidator", _name.default);

      _defineProperty(this, "basicNameValidator", _basicName.default);

      _initializerDefineProperty(this, "onSubmit", _descriptor12, this);

      _initializerDefineProperty(this, "onFinalSubmit", _descriptor13, this);

      _initializerDefineProperty(this, "loadPasswordStrength", _descriptor14, this);

      this.name = Ember.get(values, 'preset.name') || '';
      this.email = Ember.get(values, 'preset.email') || '';
      this.phone = Ember.get(values, 'preset.phone') || '';
      this.disabled = Ember.get(values, 'preset.disable' || false);
    }

    get passwordStrength() {
      const {
        password,
        name,
        email,
        passwordStrengthLibrary
      } = this;

      if (!password || !passwordStrengthLibrary) {
        return null;
      }

      return passwordStrengthLibrary(password, name.split(' ').concat(email)).score;
    }

    onCaptchaResolved(token) {
      this.onFinalSubmit.perform(null, token);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "experiments", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "apiError", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "login", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "password", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "passwordStrengthLibrary", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onCaptchaResolved", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onCaptchaResolved"), _class.prototype), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (event) {
        if (!this.session.loggedIn && !this.environment.isTest) {
          if (this.gRecaptcha) {
            yield window.grecaptcha.execute(this.gRecaptcha.get('widgetId'));
            return;
          }
        }

        yield this.onFinalSubmit.perform(event);
      };
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "onFinalSubmit", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (event, token) {
        let pieces = this.name.split(' ');
        let firstName = this.name;
        let lastName = '';

        if (pieces.length >= 2) {
          firstName = pieces.slice(0, -1).join(' ').trim();
          lastName = pieces.slice(-1).join(' ').trim();
        }

        let data = {
          first_name: firstName,
          last_name: lastName,
          phone: this.phone,
          email: this.email.toLowerCase(),
          password: this.password,
          recaptcha_response: token || ''
        };

        try {
          yield this.login.registerUser(data);

          if (this.args.redirectAfterAuthentication) {
            this.session.set('redirectAfterAuthentication', true);
          }

          this.args.onSuccess();
        } catch (error) {
          this.gRecaptcha && this.gRecaptcha.resetReCaptcha();
          Ember.getOwner(this).lookup('logger:outdoorsy').debug('Failed to create account', {
            error
          });

          if (event) {
            this.apiError.throwFormSubmitError(error, 'form-signup-error');
          } else {
            this.apiError.danger(error, 'form-signup-error');
          }
        }
      };
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "loadPasswordStrength", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        let zxcvbn = yield emberAutoImportDynamic("zxcvbn");
        this.passwordStrengthLibrary = zxcvbn.default;
      };
    }
  })), _class));
  _exports.default = FormSignupComponent;
});