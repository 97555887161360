define("@outdoorsyco/ember-shared-data/adapters/state-fee", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StateFee extends _application.default {
    pathForType() {
      return 'state-fees';
    }

  }

  _exports.default = StateFee;
});