define("@outdoorsyco/outdoorsy-addon/components/language-link-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pK1oe/xB",
    "block": "{\"symbols\":[\"country\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"language-selection\"],[12,\"role\",\"button\"],[12,\"data-test-language-link-select\",\"\"],[3,\"action\",[[23,0,[]],\"openDropdown\"]],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"text\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"font-bold\"],[8],[1,[24,[\"selectedObject\",\"name\"]],false],[9],[0,\" (\"],[1,[24,[\"selectedObject\",\"languageName\"]],false],[0,\")\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"svg-jar\",[\"arrow-down\"],[[\"class\"],[\"white size-20\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"isOpen\"]],[24,[\"fastboot\",\"isFastBoot\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"language-dropdown-options\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"countriesMenu\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[\"langs\",\"firstObject\"]],[24,[\"selection\"]]],null],\"selected\",\"not-selected\"],null]],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"href\",[23,1,[\"host\"]]],[12,\"data-test-language-select\",[23,1,[\"country\"]]],[3,\"action\",[[23,0,[]],\"updateCountry\",[23,1,[]]]],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"font-bold\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\" (\"],[1,[23,1,[\"languageName\"]],false],[0,\")\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/language-link-select/template.hbs"
    }
  });

  _exports.default = _default;
});