define("@outdoorsyco/ember-shared-data/adapters/passenger", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PassengerAdapter extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", 'v0');
    }

    pathForType() {
      return 'bookings/:booking_id/passengers';
    }

    buildURL(type, id, snapshot, requestType) {
      let url = super.buildURL(type, id, snapshot, requestType);
      url = url.replace(':booking_id', snapshot.record.belongsTo('booking').id());
      return url;
    }

  }

  _exports.default = PassengerAdapter;
});