define("@outdoorsyco/ember-shared-data/models/search/rental", ["exports", "ember-data", "ember-concurrency-decorators", "dayjs", "@outdoorsyco/ember-shared-data/models/rental"], function (_exports, _emberData, _emberConcurrencyDecorators, _dayjs, _rental) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  let SearchRental = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = belongsTo('usageBasedItem', {
    async: false,
    inverse: null
  }), _dec4 = belongsTo('usageBasedItem', {
    async: false,
    inverse: null
  }), _dec5 = belongsTo('usageBasedItem', {
    async: false,
    inverse: null
  }), _dec6 = belongsTo('search/breadcrumb', {
    async: false,
    inverse: null
  }), _dec7 = hasMany('image', {
    async: false,
    inverse: null
  }), _dec8 = belongsTo('image', {
    async: false
  }), _dec9 = attr(), _dec10 = attr('centsToDollars'), _dec11 = attr('centsToDollars'), _dec12 = attr('centsToDollars'), _dec13 = attr('centsToDollars'), _dec14 = attr('centsToDollars'), _dec15 = attr('centsToDollars'), _dec16 = attr('centsToDollars'), _dec17 = Ember.computed.or('activePricePerDay', 'pricePerDay'), _dec18 = attr('string', {
    defaultValue: 'USD'
  }), _dec19 = attr('string'), _dec20 = attr('number'), _dec21 = attr('number'), _dec22 = attr('string'), _dec23 = attr('string'), _dec24 = attr('boolean'), _dec25 = attr(), _dec26 = attr(), _dec27 = attr(), _dec28 = Ember.computed.or('activeOptions.instant_book', 'instantBook'), _dec29 = Ember.computed.or('activeOptions.cancel_policy', 'cancelPolicy'), _dec30 = Ember.computed.or('activeOptions.minimum_days', 'minimumDays'), _dec31 = Ember.computed.alias('activeOptions.use_tax_inclusive_pricing'), _dec32 = Ember.computed.alias('activeOptions.use_day_pricing'), _dec33 = Ember.computed.and('location.lat', 'location.lng', 'name', 'images.length', 'pricePerDay', 'type'), _dec34 = attr('boolean'), _dec35 = attr(), _dec36 = attr('boolean'), _dec37 = Ember.computed('published', 'hidden'), _dec38 = (0, _emberConcurrencyDecorators.lastValue)('fetchQuote'), _dec39 = Ember._tracked, _dec40 = Ember._action, (_class = class SearchRental extends _rental.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "mileageUsageItem", _descriptor3, this);

      _initializerDefineProperty(this, "generatorUsageItem", _descriptor4, this);

      _initializerDefineProperty(this, "rentalPriceUsageItem", _descriptor5, this);

      _initializerDefineProperty(this, "breadcrumbs", _descriptor6, this);

      _initializerDefineProperty(this, "images", _descriptor7, this);

      _initializerDefineProperty(this, "primaryImage", _descriptor8, this);

      _initializerDefineProperty(this, "tags", _descriptor9, this);

      _initializerDefineProperty(this, "pricePerDay", _descriptor10, this);

      _initializerDefineProperty(this, "pricePerWeek", _descriptor11, this);

      _initializerDefineProperty(this, "pricePerMonth", _descriptor12, this);

      _initializerDefineProperty(this, "activePricePerDay", _descriptor13, this);

      _initializerDefineProperty(this, "activePricePerWeek", _descriptor14, this);

      _initializerDefineProperty(this, "activePricePerMonth", _descriptor15, this);

      _initializerDefineProperty(this, "activePriceDayTax", _descriptor16, this);

      _initializerDefineProperty(this, "activeOrNormalPricePerDay", _descriptor17, this);

      _initializerDefineProperty(this, "presentmentCurrency", _descriptor18, this);

      _initializerDefineProperty(this, "settlementCurrency", _descriptor19, this);

      _initializerDefineProperty(this, "deliveryRadius", _descriptor20, this);

      _initializerDefineProperty(this, "weeklyViewCount", _descriptor21, this);

      _initializerDefineProperty(this, "cancelText", _descriptor22, this);

      _initializerDefineProperty(this, "houseRules", _descriptor23, this);

      _initializerDefineProperty(this, "hidden", _descriptor24, this);

      _initializerDefineProperty(this, "features", _descriptor25, this);

      _initializerDefineProperty(this, "activeOptions", _descriptor26, this);

      _initializerDefineProperty(this, "seoContent", _descriptor27, this);

      _initializerDefineProperty(this, "activeInstantBook", _descriptor28, this);

      _initializerDefineProperty(this, "activeCancelPolicy", _descriptor29, this);

      _initializerDefineProperty(this, "activeMinimumDays", _descriptor30, this);

      _initializerDefineProperty(this, "activeUseTaxInclusivePricing", _descriptor31, this);

      _initializerDefineProperty(this, "activeUseDayPricing", _descriptor32, this);

      _initializerDefineProperty(this, "readyToPublish", _descriptor33, this);

      _initializerDefineProperty(this, "unavailable", _descriptor34, this);

      _initializerDefineProperty(this, "lastPublished", _descriptor35, this);

      _initializerDefineProperty(this, "hasBeenPublished", _descriptor36, this);

      _initializerDefineProperty(this, "quote", _descriptor37, this);

      _initializerDefineProperty(this, "fetchQuoteError", _descriptor38, this);

      _initializerDefineProperty(this, "fetchQuote", _descriptor39, this);
    }

    get isBookable() {
      return this.published && !this.hidden;
    }
    /**
     * The last quote loadded for this rental
     */


    /**
     * Unloads the previously fetched quote
     */
    unloadPreviousQuote() {
      if (!this.quote) {
        return;
      }

      this.quote.lineItems.forEach(item => {
        item === null || item === void 0 ? void 0 : item.unloadRecord();
      });
      this.quote.premiumServices.forEach(service => {
        service === null || service === void 0 ? void 0 : service.unloadRecord();
      });
      this.quote.unloadRecord();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "mileageUsageItem", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "generatorUsageItem", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rentalPriceUsageItem", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "breadcrumbs", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "images", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "primaryImage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "pricePerDay", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "pricePerWeek", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "pricePerMonth", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "activePricePerDay", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "activePricePerWeek", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "activePricePerMonth", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "activePriceDayTax", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "activeOrNormalPricePerDay", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "presentmentCurrency", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "settlementCurrency", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "deliveryRadius", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "weeklyViewCount", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "cancelText", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "houseRules", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "hidden", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "activeOptions", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "seoContent", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "activeInstantBook", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "activeCancelPolicy", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "activeMinimumDays", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "activeUseTaxInclusivePricing", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "activeUseDayPricing", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "readyToPublish", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "unavailable", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "lastPublished", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "hasBeenPublished", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isBookable", [_dec37], Object.getOwnPropertyDescriptor(_class.prototype, "isBookable"), _class.prototype), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "quote", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "fetchQuoteError", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "fetchQuote", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (from, to, discountCode) {
        this.fetchQuoteError = null;

        if (!from || !to) {
          this.unloadPreviousQuote();
          return null;
        }

        let quote = this.store.createRecord('quote', {
          presentmentCurrency: this.session.currentCurrency,
          rental: this,
          from: (0, _dayjs.default)(from).format('YYYY-MM-DD'),
          to: (0, _dayjs.default)(to).format('YYYY-MM-DD'),
          discountCode: discountCode || this.session.discountCode
        });

        try {
          yield quote.save();
        } catch (error) {
          this.fetchQuoteError = error;
          this.unloadPreviousQuote();
          return;
        }

        this.unloadPreviousQuote();
        return quote;
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "unloadPreviousQuote", [_dec40], Object.getOwnPropertyDescriptor(_class.prototype, "unloadPreviousQuote"), _class.prototype)), _class));
  _exports.default = SearchRental;
});