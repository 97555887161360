define("@outdoorsyco/ember-shared-data/components/loading-button/component", ["exports", "@outdoorsyco/ember-shared-data/components/loading-button/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    className: 'button-inline',
    loading: false,
    disabled: null,
    buttonClass: 'btn btn-primary btn-block',
    eventLabel: null,
    buttonText: Ember.computed('intl.locale', function () {
      return this.get('intl').t('ui-submit');
    }),
    loadingText: Ember.computed('intl.locale', function () {
      return this.get('intl').t('ui-loading');
    }),
    actions: {
      submit() {
        if (this.get('save')) {
          this.get('save')(this.get('model'));
          return;
        }

        this.sendAction(); //eslint-disable-line ember/closure-actions
      }

    }
  });

  _exports.default = _default;
});