define("@outdoorsyco/ember-switchback/components/popup/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "white": "hsl(255, 100%, 100%)",
    "gray200": "hsl(204, 14%, 89%)",
    "gray300": "hsl(210, 14%, 84%)",
    "raisedGray400": "0 10px 15px -3px hsla(207, 6%, 23%, 0.1), 0 4px 6px -2px hsla(207, 6%, 23%, 0.05)",
    "Popup": "_Popup_ainkhd",
    "content": "_content_ainkhd",
    "arrow": "_arrow_ainkhd",
    "arrow-wrapper": "_arrow-wrapper_ainkhd",
    "left-show": "_left-show_ainkhd",
    "right-show": "_right-show_ainkhd",
    "top-show": "_top-show_ainkhd",
    "bottom-show": "_bottom-show_ainkhd",
    "left-hide": "_left-hide_ainkhd",
    "right-hide": "_right-hide_ainkhd",
    "top-hide": "_top-hide_ainkhd",
    "bottom-hide": "_bottom-hide_ainkhd"
  };
  _exports.default = _default;
});