define("@outdoorsyco/ember-shared-data/services/set-metadata", ["exports", "@outdoorsyco/ember-shared-data/utils/domain-languages"], function (_exports, _domainLanguages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    headData: Ember.inject.service(),
    experiments: Ember.inject.service(),
    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),
    urlHost: Ember.computed(function () {
      const ENV = Ember.getOwner(this).resolveRegistration('config:environment');

      if (ENV.environment === 'test') {
        return 'http://localhost:4203';
      }

      let protocol = this.hasHttps(ENV) ? 'https:' : false;

      if (this.get('fastboot.isFastBoot')) {
        protocol = protocol || this.get('fastboot.request.protocol');
        return `${protocol}//${this.get('fastboot.request.host')}`;
      }

      protocol = protocol || window.location.protocol;
      return `${protocol}//${window.location.host}`;
    }),

    setNextPrev(currentPage = 1, totalPages = 1) {
      if (totalPages <= 1) {
        return;
      }

      let currentRouteName = this.router.currentRouteName;
      let currentController = Ember.getOwner(this).lookup(`controller:${currentRouteName}`);
      let currentModel = currentController.get('model');

      let currentParams = this._extractChangedParams(currentController.get('queryParamsState')); // depends on ember-parachute


      let siteUrl = this.get('urlHost');
      let next, prev;

      if (totalPages > currentPage) {
        let nextParams = Object.assign({}, currentParams, {
          page: currentPage + 1
        });
        next = this._getUrl(currentRouteName, currentModel, nextParams);
        Ember.set(this, 'headData.next', [siteUrl, next].join(''));
      }

      if (currentPage > 1) {
        let prevParams = Object.assign({}, currentParams, {
          page: currentPage - 1
        });

        if (prevParams.page === 1) {
          delete prevParams.page;
        }

        prev = this._getUrl(currentRouteName, currentModel, prevParams);
        Ember.set(this, 'headData.prev', [siteUrl, prev].join(''));
      }
    },

    setHeadTags(data, currentPath = '') {
      const ENV = Ember.getOwner(this).resolveRegistration('config:environment');
      let host = this.get('urlHost').replace(/^\/|\/$/, '');
      currentPath = currentPath.replace(/^\/|\/$/, ''); // trim trailing/leading slashes

      let fallback = {
        title: 'Get Great Rates on Amazing RV Rentals',
        description: this.intl.t('head-default-description'),
        image: '',
        canonical: [host, currentPath].join('/')
      };
      data = Ember.assign({}, fallback, data);
      data.canonical = data.canonical.replace(/\/$/, '');
      data.title = `${data.title} | Outdoorsy`;

      if ((0, _domainLanguages.getCountryForDomain)(host) !== 'US') {
        data.title += ` ${this.get('intl').t('site-country')}`;
      }

      if (data.price) {
        Ember.set(this, 'headData.price', data.price);
        Ember.set(this, 'headData.currency', data.currency || 'USD');
      }

      Ember.set(this, 'headData.titleTag', Ember.String.htmlSafe(`<title>${data.title}</title>`));
      Ember.set(this, 'headData.title', data.title);
      Ember.set(this, 'headData.description', data.description);
      Ember.set(this, 'headData.image', data.image);
      Ember.set(this, 'headData.canonical', data.canonical);
      Ember.set(this, 'headData.facebookId', ENV.facebookId);
      Ember.set(this, 'headData.type', data.type);
      Ember.set(this, 'headData.noindex', data.noindex);
      Ember.set(this, 'headData.iOSapp', this._isEnabledIOSBanner(data.iOSapp));
      Ember.set(this, 'headData.prefetch', [ENV.APP.contentApi, ENV.APP.searchApi, ENV.APP.cloudinaryApi, ...ENV.APP.preconnectUrls]);
      let alternateLanguages = this.alternateLinkTagData(currentPath);
      Ember.set(this, 'headData.languages', alternateLanguages);
    },

    alternateLinkTagData(currentPath) {
      let path = (currentPath || '').split('?')[0];
      let langs = Object.keys(_domainLanguages.domainLanguages).reduce((arr, domain) => {
        let obj = _domainLanguages.domainLanguages[domain];
        obj.langs.forEach(lang => {
          let url = `https://${[domain, path].join('/')}`;
          url = url.replace(/\/$/, '');
          arr.push({
            url,
            hreflang: lang.toLowerCase()
          });
        });
        return arr;
      }, []);
      let enLang = langs.findBy('hreflang', 'en');

      if (enLang) {
        let defaultLang = Object.assign({}, enLang);
        defaultLang['hreflang'] = 'x-default';
        langs.push(defaultLang);
      }

      return langs;
    },

    _getUrl(currentRouteName, model, queryParams) {
      let router = this.get('router');

      if (model) {
        return router.urlFor(currentRouteName, model, {
          queryParams: queryParams
        });
      }

      return router.urlFor(currentRouteName, {
        queryParams: queryParams
      });
    },

    _extractChangedParams(paramState = {}) {
      let newParams = {};

      for (let key in paramState) {
        if (paramState.hasOwnProperty(key) && paramState[key].changed) {
          newParams[paramState[key].as] = paramState[key].serializedValue;
        }
      }

      return newParams;
    },

    _hasIOSapp: false,

    _isEnabledIOSBanner(iOSapp) {
      if (iOSapp) {
        this._hasIOSapp = true;
      }

      return this._hasIOSapp;
    },

    hasHttps(ENV) {
      if (ENV.deployTarget === 'development' && ENV.environment === 'production') {
        return false;
      }

      return ENV.environment !== 'development';
    }

  });

  _exports.default = _default;
});