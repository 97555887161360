define("@outdoorsyco/ember-shared-data/components/booking/handoff/photo-uploader/departure/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YSgrdkYb",
    "block": "{\"symbols\":[\"@booking\",\"&attrs\"],\"statements\":[[5,\"booking/handoff/photo-uploader\",[[13,2]],[[\"@booking\",\"@fileType\",\"@handoffFilesArray\"],[[23,1,[]],\"booking_handoff_departure\",\"departureFiles\"]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/booking/handoff/photo-uploader/departure/template.hbs"
    }
  });

  _exports.default = _default;
});