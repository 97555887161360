define("@outdoorsyco/ember-shared-data/serializers/payout", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class PayoutSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        lineItems: {
          deserialize: 'ids',
          serialize: 'ids'
        },
        bookings: {
          serialize: 'ids',
          deserialize: 'ids'
        }
      });
    }

    // the embedded records mixin has an issue where it doens't pass the hasMany
    // relationship identifier with the request. So we check for it explicitly
    keyForRelationship(key, relationship, method) {
      if (key === 'bookings' || key === 'line_items') {
        return key;
      }

      return super.keyForRelationship(key, relationship, method);
    } // API -> what the model expects


    normalize(typeClass, hash) {
      // we only want to do this if it's an array.  If not, just ignore it!
      if (Ember.isArray(hash.items)) {
        hash.line_items = hash.items;
      }

      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = PayoutSerializer;
});