define("@outdoorsyco/outdoorsy-addon/components/od-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/nN2aT5K",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"hide\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"alert alert-\",[22,\"type\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"allowClose\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"remove\"],[3,\"action\",[[23,0,[]],[28,\"toggle\",[\"hide\",[23,0,[]]],null]]],[8],[0,\"\\n        \"],[1,[28,\"svg-jar\",[\"close\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"media\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"svgIcon\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"media-left\"],[8],[0,\"\\n          \"],[1,[28,\"svg-jar\",[[24,[\"svgIcon\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\",true],[10,\"class\",\"media-body\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"          \"],[7,\"h5\",true],[10,\"class\",\"media-heading\"],[8],[0,\"\\n            \"],[1,[22,\"title\"],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[14,1],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/od-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});