define('ember-mobile-core/services/pan-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    panLocked: false,

    lock: function lock(elementId) {
      Ember.set(this, 'panLocked', elementId);
    },
    unlock: function unlock(elementId) {
      if (Ember.get(this, 'panLocked') === elementId) {
        Ember.set(this, 'panLocked', false);
      }
    }
  });
});