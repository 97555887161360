define("@outdoorsyco/ember-shared-data/services/push-notifications", ["exports", "ember-concurrency", "@outdoorsyco/ember-shared-data/utils/load-script"], function (_exports, _emberConcurrency, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // NOTE ABOUT THIS SERVICE!!
  // this service loads the firebase SDK which requires a .js file to be at root.
  // that file lives in our web-static repo here: https://github.com/outdoorsy/web-static/blob/staging/source/firebase-messaging-sw.js
  // firebase documentation here: https://firebase.google.com/docs/cloud-messaging/js/client
  var _default = Ember.Service.extend({
    apiRequest: Ember.inject.service(),
    didLoadFirebaseSDK: false,
    messaging: Ember.computed('didLoadFirebaseSDK', function () {
      if (this.get('didLoadFirebaseSDK')) {
        return window.firebase.messaging();
      }
    }),

    setupPushNotifications(userId) {
      const appConfig = Ember.getOwner(this).resolveRegistration('config:environment');
      const env = appConfig.environment;
      const unsupportedEnvironments = ['test'
      /* TODO restore after push notifications work
      'development',
      'heroku'
      */
      ]; // if notifications aren't supported by browser or we don't have a user, bail!

      if (!userId || typeof Notification !== 'function' || unsupportedEnvironments.includes(env)) {
        return;
      } // if the user has already denied permission, bail


      if (Notification.permission === 'denied') {
        return;
      }

      return this.get('_setUp').perform(userId);
    },

    /**
     Overwrite this method in your app to
     consume push notification in your app
     ```
      this.pushNotifications.onMessageHandler = (payload) => {
        this._notification(payload);
      };
     ```
     * @param { Object } payload
     */
    onMessageHandler(payload) {
      Ember.debug(`firebase push notification payload ${payload}`);
    },

    _setUp: (0, _emberConcurrency.task)(function* (userId) {
      if (!this.get('didLoadFirebaseSDK')) {
        yield this.get('_loadFireBaseSDK').perform(); // after loading, set the message handler

        if (this.get('isSupported')) {
          this.get('messaging').onMessage(this.onMessageHandler);
        }
      }

      if (!this.get('isSupported')) {
        return;
      } // if we've already gotten permission at some point just init the firebase SDK and return the current token


      if (Notification.permission === 'granted') {
        try {
          let token = yield this.get('messaging').getToken();
          Ember.debug('firebase web push token', token);
          return token;
        } catch (e) {
          console.log(e);
          return;
        }
      }

      try {
        yield this.get('messaging').requestPermission();
      } catch (err) {
        return console.log('notifications permission not granted: ', err);
      }

      let token = yield this.get('messaging').getToken();
      return yield this.get('_saveToken').perform(token, userId);
    }),
    _loadFireBaseSDK: (0, _emberConcurrency.task)(function* () {
      const loaded = this.get('didLoadFirebaseSDK');
      const appConfig = Ember.getOwner(this).resolveRegistration('config:environment');
      const config = appConfig.firebaseSDKConfig;

      if (!loaded) {
        let loadFirebaseApp = (0, _loadScript.default)({
          src: 'https://www.gstatic.com/firebasejs/5.7.0/firebase-app.js'
        });
        let loadFirebaseMessaging = (0, _loadScript.default)({
          src: 'https://www.gstatic.com/firebasejs/5.7.0/firebase-messaging.js'
        });

        try {
          yield Promise.all([loadFirebaseApp, loadFirebaseMessaging]);
        } catch (err) {
          console.log('error loading firebase SDK: ', err);
          return;
        }
      }

      if (window.firebase.apps.length > 0) {
        return;
      }

      window.firebase.initializeApp(config);
      this.set('didLoadFirebaseSDK', true);
      this.set('isSupported', window.firebase.messaging.isSupported());
    }),
    _saveToken: (0, _emberConcurrency.task)(function* (token, userId) {
      let data = {
        user_id: userId,
        token,
        device: 'browser'
      };
      yield this.apiRequest.post(`notifications/tokens`, {
        data: JSON.stringify(data)
      });
      return token;
    })
  });

  _exports.default = _default;
});