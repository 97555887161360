define("@outdoorsyco/ember-switchback/components/counter/component", ["exports", "@outdoorsyco/ember-switchback/components/form-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * An counter
   *
   * ```handlebars
   * <Counter @value={{this.value}} @min="2" @max="10" />
   * ```
   * @public
   */
  let Counter = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class Counter extends _component.default {
    /**
     * Tracks if the input has focus so we can highlight both the
     * add and subtract buttons to indicate they are active..
     */
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "hasFocus", _descriptor, this);

      (false && !(!this.args.step || typeof this.args.step === 'number') && Ember.assert('The @step passed to Counter must be a number', !this.args.step || typeof this.args.step === 'number'));
    }

    get step() {
      return this.args.step || 1;
    }

    get displayValue() {
      return this.args.displayValue ? this.args.displayValue : this.args.value || 0;
    }

    get addIsDisabled() {
      if (Ember.isEmpty(this.args.max)) {
        return this.args.disabled;
      }

      let currentValue = parseInt(this.args.value) || 0;
      let max = parseInt(this.args.max);
      return this.args.disabled || currentValue >= max;
    }

    get subtractIsDisabled() {
      if (Ember.isEmpty(this.args.min)) {
        return this.args.disabled;
      }

      let currentValue = parseInt(this.args.value) || 0;
      let min = parseInt(this.args.min);
      return this.args.disabled || currentValue <= min;
    }
    /**
     * Subtracts the step from the value if it doesn't fall below the min.
     */


    subtract() {
      if (this.args.onChange) {
        let currentValue = parseInt(this.args.value) || 0;
        let newValue = currentValue - this.step;
        let setValue = Math.max(newValue, this.args.min || newValue);

        if (this.args.max && this.args.max < setValue) {
          setValue = parseInt(this.args.max);
        }

        this.args.onChange(setValue);
      }
    }
    /**
     * Adds the step to the value if it doesn't exceed the max.
     */


    add() {
      if (this.args.onChange) {
        let currentValue = parseInt(this.args.value) || 0;
        let newValue = currentValue + this.step;
        let setValue = Math.min(newValue, this.args.max || newValue);

        if (this.args.min && this.args.min > setValue) {
          setValue = parseInt(this.args.min);
        }

        this.args.onChange(setValue);
      }
    }
    /**
     * Sets the value to the one specified
     */


    set(value) {
      if (value && this.args.onChange) {
        this.args.onChange(value);
      }
    }

    onKeyDown(event) {
      if (this.args.disabled) {
        return;
      }

      switch (event.which) {
        case 38:
          /* UP ARROW */
          event.preventDefault();
          return this.add();

        case 40:
          /* DOWN ARROW */
          event.preventDefault();
          return this.subtract();

        case 39:
          /* RIGHT ARROW */
          event.preventDefault();
          return this.add();

        case 37:
          /* LEFT ARROW */
          event.preventDefault();
          return this.subtract();

        case 36:
          /* HOME */
          event.preventDefault(); // eslint-disable-next-line ember/classic-decorator-no-classic-methods

          return this.set(this.args.min);

        case 35:
          /* END */
          event.preventDefault(); // eslint-disable-next-line ember/classic-decorator-no-classic-methods

          return this.set(this.args.max);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hasFocus", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "subtract", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "subtract"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype)), _class));
  _exports.default = Counter;
});