define("@outdoorsyco/ember-shared-data/components/edit-discount-code/component", ["exports", "@outdoorsyco/ember-shared-data/components/edit-discount-code/template", "ember-cp-validations", "@outdoorsyco/ember-shared-data/utils/vehicle-types", "@outdoorsyco/ember-shared-data/utils/countries"], function (_exports, _template, _emberCpValidations, _vehicleTypes, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'model.code': (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    'model.amount': (0, _emberCpValidations.validator)('presence', {
      presence: true
    })
  });

  var _default = Ember.Component.extend(Validations, {
    layout: _template.default,
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    environment: Ember.inject.service(),
    applyToField: null,
    showErrors: false,
    country: '',

    init() {
      var _this$model, _this$model$rentalCat;

      this._super(...arguments);

      this.targetCategories = this.get('rentalCategories') || ['rv'];
      this.rentalCategoryOptions = [{
        name: 'RVs',
        value: 'rv'
      }, {
        name: 'Stays',
        value: 'stay'
      }, {
        name: 'Campground',
        value: 'campground'
      }];
      this.vehicleTypeOptions = (0, _vehicleTypes.default)();
      this.rentalCategoryOptions = this.rentalCategoryOptions.filter(opt => this.targetCategories.includes(opt.value));
      this.rentalCategoryData = this.targetCategories.map(rentalCategory => {
        return {
          value: rentalCategory,
          label: this._getRentalCategoryLabel(rentalCategory),
          options: this._getRentalCategoryOptions(rentalCategory)
        };
      });
      this.vehicleTypes = [];
      (_this$model = this.model) === null || _this$model === void 0 ? void 0 : (_this$model$rentalCat = _this$model.rentalCategory) === null || _this$model$rentalCat === void 0 ? void 0 : _this$model$rentalCat.forEach(rc => {
        var _this$model$vehicleTy;

        const vehicleTypes = ((_this$model$vehicleTy = this.model.vehicleType) === null || _this$model$vehicleTy === void 0 ? void 0 : _this$model$vehicleTy.map(vt => `${rc}-${vt}`)) || [];
        this.vehicleTypes = this.vehicleTypes.concat(vehicleTypes);
      });
    },

    shouldShowStateAndCountry: function shouldShowStateAndCountry() {
      return Ember.get(this, 'session.currentUser.admin') === true || ['US', 'CA'].includes(Ember.get(this, 'session.currentUser.country'));
    },

    _getRentalCategoryLabel(rentalCategory) {
      switch (rentalCategory) {
        case 'rv':
          return this.intl.t('comp-edit-discount-code-rv-type');

        case 'stay':
          return this.intl.t('comp-edit-discount-code-stay-type');

        case 'campground':
          return this.intl.t('comp-edit-discount-code-campground-type');
      }
    },

    _getRentalCategoryOptions(rentalCategory) {
      return this.vehicleTypeOptions.get(rentalCategory);
    },

    _getVehicleTypes(rentalCategories) {
      let options = [];
      rentalCategories.forEach(rentalCategory => {
        const opts = this._getRentalCategoryOptions(rentalCategory).map(o => o.value);

        options = options.concat(opts);
      });
      const vehicleTypes = this.get('vehicleTypes').filter(vehicleType => options.includes(vehicleType));
      return vehicleTypes;
    },

    _getRentalCategories(rentalCategory) {
      let rentalCategories = this.get('model.rentalCategory') || [];

      if (!rentalCategories.includes(rentalCategory)) {
        rentalCategories = rentalCategories.concat(rentalCategory);
      }

      return rentalCategories;
    },

    supportedCountries: _countries.countries.filter(el => ['US', 'CA'].includes(el.value)),
    actions: {
      setCountry(country) {
        this.set(`model.tempCountry`, country);
        this.set('model.vehicleState', '');
      },

      setVehicleType(args, value) {
        // We need some custom logic when the user iretact with vehicleType
        const rentalCategories = this._getRentalCategories(args.value);

        this.set(`model.rentalCategory`, rentalCategories);
        this.set('vehicleTypes', value);
      },

      setRentalCategory(value) {
        // We need some custom logic when the user iretact with rentalCategory
        const options = Ember.copy(this.get('targetCategories') || [], true); // Fix the order. seems like we can not keep original order. multiselect add to the end

        if (value.length === options.length) {
          value = options;
        } // We need to update remove all vehicleType not related with newly selected value


        const vehicleTypes = this._getVehicleTypes(value);

        this.set(`vehicleTypes`, vehicleTypes);
        this.set(`model.rentalCategory`, value);
      },

      setParam(param, value) {
        this._setParam(param, value);
      },

      clearVehicleState() {
        this.set('model.vehicleState', '');
        this.set('model.tempCountry', '');
      },

      cancel() {
        if (!this.get('model.isNew') && this.get('model.hasDirtyAttributes')) {
          this.model.rollbackAttributes();
        }

        this.sendAction('closeModal'); //eslint-disable-line ember/closure-actions
      },

      saveChanges() {
        this.set('error', '');
        this.set('showErrors', false);
        this.validate().then(({
          validations
        }) => {
          if (validations.isInvalid) {
            this.set('showErrors', true);
            return;
          } // validation percentage is 1-100


          let amount = this.model.get('amount'); // we want to add safeguards to make sure portal users are not able to accidentally/maliciously create extreme discounts
          // we do want 1 user with an exception to allow for more controlled flexibility to bypass these maximums

          let actorID = Ember.get(this, 'session.currentUser.id');

          if (this.get('isPortal') && this.get('isProduction') && this.model.get('applyToField') === 'subtotal' && !this.userAllowedToCreateExtremeDiscountCode(actorID)) {
            if (this.model.get('isPercentage') && (amount < 0 || amount > 10)) {
              this.set('error', 'Percentage must be a value between 1 - 10');
              return;
            } else if (this.model.get('isDollarAmount') && (amount < 0 || amount > 100)) {
              this.set('error', 'Amount must be a value between 1 - 100');
              return;
            }
          } else {
            if (this.model.get('isPercentage') && (amount < 0 || amount > 100)) {
              this.set('error', 'Percentage must be a value between 1 - 100');
              return;
            } else if (this.model.get('isDollarAmount') && amount < 0) {
              this.set('error', 'Amount must be a value over zero');
              return;
            }
          }
          /* admin-portal sets this to service_fee/subtotal, but defaults to owner_payout. */


          if (this.get('applyToField')) {
            this.model.set('applyToField', this.get('applyToField'));
          } // normalize vehicleTypes data


          const regex = new RegExp(`^(${this.targetCategories.join('|')})-`, 'i');
          const vehicleTypes = this.get('vehicleTypes').map(vt => vt.replace(regex, ''));
          let unique = [...new Set(vehicleTypes)];
          this.set(`model.vehicleType`, unique);
          return this.model.save().then(() => {
            this.sendAction('closeModal'); //eslint-disable-line ember/closure-actions
          }).catch(res => {
            let msg = 'An error ocurred while trying to save';

            if (res && res.errors && res.errors.length) {
              msg = res.errors[0].title;
            }

            this.set('error', msg);
          });
        });
      }

    },

    _setParam(param, value) {
      // select field with complex object
      if (typeof value === 'object' && value.value !== undefined) {
        this.set(`model.${param}`, value.value);
      } else {
        this.set(`model.${param}`, value);
      }
    },

    userAllowedToCreateExtremeDiscountCode(userID) {
      const allowedList = ['3201730', '3976884', '3583695', '3703951', '3682555']; //Bryan Rucklos, Willy Volk, Neil Robinson, Richard Dyer, Samuel Fortier-Galarneau

      userID = userID || '';

      if (allowedList.includes(userID.toString())) {
        return true;
      }

      return false;
    }

  });

  _exports.default = _default;
});