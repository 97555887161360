define("@outdoorsyco/ember-shared-data/helpers/belongs-to-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.belongsToId = belongsToId;
  _exports.default = void 0;

  // This function wraps Ember data belongsTo id behavior so we can get to id's in templates, without triggering requests
  // Inputs
  // params[0] - the model to look at the relationship of
  // params[1] - the relationship to look for
  function belongsToId(params) {
    if (Ember.isBlank(params) || params.length < 2) {
      return null;
    } // Unwrap proxy object


    let model = params[0].content || params[0];
    let relationshipName = params[1];

    if (Ember.isBlank(model) || Ember.isBlank(relationshipName)) {
      return null;
    }

    return model.belongsTo(relationshipName).id();
  }

  var _default = Ember.Helper.helper(belongsToId);

  _exports.default = _default;
});