define("@outdoorsyco/ember-shared-data/components/input/phone-number/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray100": "hsl(220, 20%, 97%)",
    "input": "_input_6d6hbx",
    "inline-input": "_inline-input_6d6hbx",
    "edit": "_edit_6d6hbx",
    "cancel": "_cancel_6d6hbx",
    "phone-verification-field": "_phone-verification-field_6d6hbx",
    "verified-message": "_verified-message_6d6hbx",
    "country-field": "_country-field_6d6hbx",
    "cancel-container": "_cancel-container_6d6hbx"
  };
  _exports.default = _default;
});