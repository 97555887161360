define("@outdoorsyco/outdoorsy-addon/components/line-behind/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/line-behind/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    justifyContent: 'center',
    textBackground: '#FFF',
    padding: '0 16px',
    style: Ember.computed('justifyContent', function () {
      return Ember.String.htmlSafe(`justify-content: ${this.get('justifyContent')}`);
    }),
    spanStyle: Ember.computed('padding', function () {
      let textBackground = this.get('textBackground');
      let padding = this.get('padding');
      return Ember.String.htmlSafe(`padding: ${padding}; background: ${textBackground};`);
    })
  });

  _exports.default = _default;
});