define("@outdoorsyco/ember-switchback/components/badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MZoBlRkj",
    "block": "{\"symbols\":[\"@variant\",\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Badge\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/badge/styles\"]]]]]],[12,\"data-variant\",[28,\"or\",[[23,1,[]],\"brand\"],null]],[13,2],[8],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/badge/template.hbs"
    }
  });

  _exports.default = _default;
});