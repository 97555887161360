define("@outdoorsyco/ember-shared-data/models/rule", ["exports", "ember-data", "moment", "ember-data-storefront/mixins/loadable-model"], function (_exports, _emberData, _moment, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  let Rule = (_dec = Ember.inject.service, _dec2 = attr('number'), _dec3 = hasMany('rental'), _dec4 = attr('number'), _dec5 = attr('string', {
    defaultValue: 'DATERANGE'
  }), _dec6 = attr('boolean'), _dec7 = attr('boolean'), _dec8 = attr('simpleDate', {
    defaultValue: ''
  }), _dec9 = attr('simpleDate', {
    defaultValue: ''
  }), _dec10 = attr('simpleDate', {
    defaultValue: ''
  }), _dec11 = attr('simpleDate', {
    defaultValue: ''
  }), _dec12 = attr('string', {
    defaultValue: ''
  }), _dec13 = attr('number', {
    defaultValue: null
  }), _dec14 = attr({
    defaultValue() {
      return ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    }

  }), _dec15 = attr({
    defaultValue() {
      return [];
    }

  }), _dec16 = attr({
    defaultValue() {
      return {};
    }

  }), _dec17 = attr('string', {
    defaultValue: 'decrease'
  }), _dec18 = attr('string', {
    defaultValue: 'PERCENTAGE'
  }), _dec19 = attr('number'), _dec20 = Ember.computed('ruleType'), _dec21 = Ember.computed('ruleType'), _dec22 = Ember.computed('searchDatesStart', 'searchDatesEnd'), _dec23 = Ember.computed('bookingDatesStart', 'bookingDatesEnd'), _dec24 = Ember.computed.equal('ruleType', 'DATERANGE'), _dec25 = Ember.computed.equal('ruleType', 'UTILIZATION'), _dec26 = Ember.computed.equal('ruleType', 'LENGTH_OF_BOOKING'), _dec27 = Ember.computed.equal('ruleType', 'DAY_OF_TRIP'), _dec28 = Ember.computed.equal('ruleType', 'MINIMUM_BOOKING_DAYS'), _dec29 = Ember.computed.equal('ruleType', 'SPECIFIC_DAY'), _dec30 = Ember.computed.equal('priceModifierType', 'FLAT'), _dec31 = Ember.computed.equal('priceModifierType', 'PERCENTAGE'), _dec32 = Ember.computed.equal('priceModifierType', 'SET'), _dec33 = Ember.computed.equal('priceModifierChange', 'increase'), _dec34 = Ember.computed.equal('priceModifierChange', 'decrease'), _dec35 = Ember.computed.or('isUtilization', 'isLengthOfBooking', 'isDayOfTrip'), _dec36 = Ember.computed.or('isDatesRanges', 'isSpecificDay'), _dec37 = Ember.computed.and('hasDayRuleTypes', 'isTypePercentage'), (_class = class Rule extends Model.extend(_loadableModel.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "ownerId", _descriptor2, this);

      _initializerDefineProperty(this, "rentals", _descriptor3, this);

      _initializerDefineProperty(this, "rentalsApplied", _descriptor4, this);

      _initializerDefineProperty(this, "ruleType", _descriptor5, this);

      _initializerDefineProperty(this, "repeats", _descriptor6, this);

      _initializerDefineProperty(this, "active", _descriptor7, this);

      _initializerDefineProperty(this, "bookingDatesStart", _descriptor8, this);

      _initializerDefineProperty(this, "bookingDatesEnd", _descriptor9, this);

      _initializerDefineProperty(this, "searchDatesStart", _descriptor10, this);

      _initializerDefineProperty(this, "searchDatesEnd", _descriptor11, this);

      _initializerDefineProperty(this, "description", _descriptor12, this);

      _initializerDefineProperty(this, "minimumBookingDays", _descriptor13, this);

      _initializerDefineProperty(this, "applicableDays", _descriptor14, this);

      _initializerDefineProperty(this, "tiers", _descriptor15, this);

      _initializerDefineProperty(this, "ruleSpecificData", _descriptor16, this);

      _initializerDefineProperty(this, "priceModifierChange", _descriptor17, this);

      _initializerDefineProperty(this, "priceModifierType", _descriptor18, this);

      _initializerDefineProperty(this, "priceModifierValue", _descriptor19, this);

      _initializerDefineProperty(this, "isDatesRanges", _descriptor20, this);

      _initializerDefineProperty(this, "isUtilization", _descriptor21, this);

      _initializerDefineProperty(this, "isLengthOfBooking", _descriptor22, this);

      _initializerDefineProperty(this, "isDayOfTrip", _descriptor23, this);

      _initializerDefineProperty(this, "isMinimumBookingDays", _descriptor24, this);

      _initializerDefineProperty(this, "isSpecificDay", _descriptor25, this);

      _initializerDefineProperty(this, "isTypeFlat", _descriptor26, this);

      _initializerDefineProperty(this, "isTypePercentage", _descriptor27, this);

      _initializerDefineProperty(this, "isTypeSet", _descriptor28, this);

      _initializerDefineProperty(this, "isChangeIncrease", _descriptor29, this);

      _initializerDefineProperty(this, "isChangeDecrease", _descriptor30, this);

      _initializerDefineProperty(this, "hasTierRuleTypes", _descriptor31, this);

      _initializerDefineProperty(this, "hasDayRuleTypes", _descriptor32, this);

      _initializerDefineProperty(this, "hasPercentageTypeAndIsDay", _descriptor33, this);
    }

    get priceChange() {
      let values = [];

      if (this.get('isDatesRanges') || this.get('isSpecificDay')) {
        let pmv = this.get('priceModifierValue');
        let pmt = this.get('priceModifierType');
        let pmc = this.get('priceModifierChange');
        values.push(this._getPriceChange(pmt, pmc, pmv));
      } else if (this.get('isLengthOfBooking') || this.get('isUtilization') || this.get('isDayOfTrip')) {
        let tiers = this.get('tiers') || [];
        tiers.forEach(tier => {
          let pmc = tier.price_modifier_change;
          let pmt = tier.price_modifier_type;
          let pmv = tier.price_modifier_value;
          values.push(this._getPriceChange(pmt, pmc, pmv));
        });
      } else {
        values.push('--');
      }

      return values.join(', ');
    }

    get category() {
      let category = this.get('ruleType');
      let intl = this.get('intl');

      if (this.get('isDatesRanges')) {
        category = intl.t('model-rule-date-range');
      } else if (this.get('isUtilization')) {
        category = intl.t('model-rule-utilization');
      } else if (this.get('isLengthOfBooking') || this.get('isDayOfTrip') || this.get('isSpecificDay')) {
        category = intl.t('model-rule-trip-discount');
      } else if (this.get('isMinimumBookingDays')) {
        category = intl.t('model-rule-minimum-days');
      }

      return category;
    }

    get discountDate() {
      let start = this.get('searchDatesStart');
      let end = this.get('searchDatesEnd');

      if (start && end) {
        let startDate = (0, _moment.default)(start).format('L');
        let endDate = (0, _moment.default)(end).format('L');
        return `${startDate}-${endDate}`;
      }

      return '';
    }

    get effectiveDate() {
      let start = this.get('bookingDatesStart');
      let end = this.get('bookingDatesEnd');

      if (start && end) {
        let startDate = (0, _moment.default)(start).format('L');
        let endDate = (0, _moment.default)(end).format('L');
        return `${startDate} - ${endDate}`;
      }

      return '';
    }

    _getPriceChange(type, change, value) {
      if (type !== 'SET') {
        value = type === 'PERCENTAGE' ? `${value}%` : `$${value}`;
        value = change === 'decrease' ? `-${value}` : `+${value}`;
      }

      return value;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ownerId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rentals", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "rentalsApplied", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ruleType", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "repeats", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "active", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "bookingDatesStart", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "bookingDatesEnd", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "searchDatesStart", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "searchDatesEnd", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "minimumBookingDays", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "applicableDays", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "tiers", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "ruleSpecificData", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "priceModifierChange", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "priceModifierType", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "priceModifierValue", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "priceChange", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "priceChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "category", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "category"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discountDate", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "discountDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "effectiveDate", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "effectiveDate"), _class.prototype), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "isDatesRanges", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "isUtilization", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "isLengthOfBooking", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "isDayOfTrip", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "isMinimumBookingDays", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "isSpecificDay", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "isTypeFlat", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "isTypePercentage", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "isTypeSet", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "isChangeIncrease", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "isChangeDecrease", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "hasTierRuleTypes", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "hasDayRuleTypes", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "hasPercentageTypeAndIsDay", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Rule;
});