define("@outdoorsyco/ember-switchback/components/accordion/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "font700": "normal normal 400 normal 28px/1.3 -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;",
    "font200": "normal normal 400 normal 16px/1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;",
    "Accordion": "_Accordion_1ot0ll",
    "title": "_title_1ot0ll",
    "subhead": "_subhead_1ot0ll",
    "toggle": "_toggle_1ot0ll",
    "svg": "_svg_1ot0ll",
    "body": "_body_1ot0ll"
  };
  _exports.default = _default;
});