define("ember-svg-jar/inlined/apple-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.3 5.2c.9 0 2.2-.7 2.9-1.6.7-.8 1.1-2 1.1-3.1V.1c-1.1 0-2.4.7-3.2 1.7-.7.7-1.2 1.9-1.2 3v.4h.4zM8.9 22.4c1.3 0 2-.9 3.6-.9 1.7 0 2.1.9 3.6.9s2.4-1.4 3.4-2.7c1-1.6 1.5-3.1 1.5-3.2-.1 0-2.9-1.2-2.9-4.5 0-2.9 2.2-4.1 2.3-4.2-1.5-2.2-3.7-2.2-4.3-2.2-1.6 0-3 1-3.8 1-.9 0-2.1-.9-3.6-.9-2.7 0-5.4 2.2-5.4 6.6 0 2.7 1 5.5 2.2 7.4 1.1 1.4 2.1 2.7 3.4 2.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});