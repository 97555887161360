define("@outdoorsyco/ember-shared-data/services/geocoder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Google Place objects have functions; plain POJOs just have props
  function getOrCall(expression) {
    return typeof expression === 'function' ? expression.call() : expression;
  }

  let GeocoderService = (_dec = Ember.inject.service, (_class = class GeocoderService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "googleMapsApi", _descriptor, this);
    }

    /**
     *
     * @param {object} start - Starting point in distance calculation
     * @param {number} start.lng
     * @param {number} start.lat
     * @param {object} destination - Ending point in distance calculation
     * @param {number} destination.lng
     * @param {number} destination.lat
     * @param {object} options
     * @param {object} options.unit - Unit to get the distance in
     */
    async calculateDistance(start, destination, {
      unit = 'kilometer'
    }) {
      let google = await this.googleMapsApi.google;
      let distanceMatrix = new google.maps.DistanceMatrixService();
      let startLatLng = new google.maps.LatLng(start.lat, start.lng);
      let destinationLatLng = new google.maps.LatLng(destination.lat, destination.lng);
      let unitSystem = unit === 'miles' || unit === 'mile' ? google.maps.UnitSystem.IMPERIAL : google.maps.UnitSystem.METRIC;
      return new Ember.RSVP.Promise(function (resolve) {
        let callback = function callback(data) {
          /**
           * TODO Convert distance to unit provided. Else we can send
           * back values in ft. and m
           */
          resolve({
            distance: Ember.get(data, 'rows.firstObject.elements.firstObject.distance'),
            unit: unit
          });
        };

        distanceMatrix.getDistanceMatrix({
          origins: [startLatLng],
          destinations: [destinationLatLng],
          travelMode: 'DRIVING',
          unitSystem: unitSystem
        }, callback);
      });
    }
    /**
     * Takes a Google Maps place id and and returns a Google Maps location object.
     *
     * @param {string} placeId - Google Maps place id
     */


    async geocodePlaceId(placeId) {
      let google = await this.googleMapsApi.google;
      let geocoder = new google.maps.Geocoder();
      return new Ember.RSVP.Promise(function (resolve) {
        let callback = function callback(data) {
          resolve(data[0]);
        };

        geocoder.geocode({
          placeId
        }, callback);
      });
    }
    /**
     * Deprecated. Don't use. Use getDistanceMatrix instead.
     */


    async getDrivingDistance(lat1, lng1, lat2, lng2) {
      let google = await this.googleMapsApi.google;
      let start = new google.maps.LatLng(lat1, lng1);
      let destination = new google.maps.LatLng(lat2, lng2);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return new google.maps.DistanceMatrixService().getDistanceMatrix({
          origins: [start],
          destinations: [destination],
          travelMode: 'DRIVING',
          unitSystem: google.maps.UnitSystem.IMPERIAL
        }, function (data, status) {
          if (status !== google.maps.GeocoderStatus.OK) {
            reject();
            return;
          } // console.log('data', data);


          let firstResult = null;
          let row = Ember.isPresent(data['rows']) ? data['rows'][0] : {};
          let element = Ember.isPresent(row['elements']) ? row['elements'][0] : false;

          if (element) {
            firstResult = element['distance'];
          }

          if (!firstResult) {
            reject('unable to determine distance');
            return;
          }

          firstResult.valueMiles = firstResult.value * 0.000621371;
          firstResult.valueKilometers = firstResult.value * 0.001;
          return resolve(firstResult);
        });
      });
    }

    getDistance(lat1, lon1, lat2, lon2) {
      const R = 6371;

      let deg2rad = deg => deg * (Math.PI / 180);

      let dLon = deg2rad(lon2 - lon1);
      let dLat = deg2rad(lat2 - lat1);
      let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let d = R * c * 0.62; //distance in miles

      return d;
    }

    async geocode(query, onlyFirstObject) {
      let google = await this.googleMapsApi.google;
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (query) {
          return new google.maps.Geocoder().geocode({
            address: query
          }, function (data, status) {
            if (status !== google.maps.GeocoderStatus.OK) {
              return reject();
            }

            let res;

            if (onlyFirstObject && data) {
              res = data[0];
            } else {
              let items = [];
              data.forEach(item => {
                let geo = item.geometry.location;
                return items.push({
                  lat: getOrCall(geo.lat),
                  lon: getOrCall(geo.lng)
                });
              });
              res = items;
            }

            return resolve(res);
          });
        }

        return reject();
      });
    }

    async geoLocation(query) {
      let google = await this.googleMapsApi.google;
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (query) {
          return new google.maps.Geocoder().geocode({
            address: query
          }, function (data, status) {
            if (status !== google.maps.GeocoderStatus.OK) {
              return reject();
            }

            let loc = {};
            let obj = data && data[0] ? data[0] : {};

            if (obj.geometry && obj.geometry.location) {
              let objLocation = obj.geometry.location;
              loc.lat = objLocation.lat();
              loc.lng = objLocation.lng();
            }

            if (obj !== null) {
              obj.address_components.filter(m => {
                if (m.types.indexOf('locality') !== -1) {
                  return loc.city = {
                    long: m.long_name,
                    short: m.short_name
                  };
                } else if (m.types.indexOf('administrative_area_level_1') !== -1) {
                  return loc.state = {
                    long: m.long_name,
                    short: m.short_name
                  };
                } else if (m.types.indexOf('administrative_area_level_2') !== -1) {
                  return loc.county = {
                    long: m.long_name,
                    short: m.short_name
                  };
                } else if (m.types.indexOf('country') !== -1) {
                  return loc.country = {
                    long: m.long_name,
                    short: m.short_name
                  };
                }
              });
            }

            return resolve(loc);
          });
        }

        return reject();
      });
    }

    geocodeParsePlace(place) {
      if (!place) {
        return {};
      }

      let placeNumber = place.address_components.filter(a => a.types.includes('street_number'));
      let placeStreet = place.address_components.filter(a => a.types.includes('route'));
      let placePostal = place.address_components.filter(a => a.types.includes('postal_code'));
      let number = placeNumber.length ? placeNumber[0].long_name : null;
      let street = placeStreet.length ? placeStreet[0].short_name : null;
      let fullStreet = [number, street].filter(item => !Ember.isBlank(item)).join(' ');
      let geo = place.geometry.location;
      let loc = {
        place_id: place.place_id,
        // carry through
        street1: fullStreet,
        // fallback compatibility with some models still using street1
        street: fullStreet,
        lat: getOrCall(geo.lat),
        lng: getOrCall(geo.lng),
        zip: placePostal.length ? placePostal[0].long_name : null
      };
      place.address_components.forEach(segment => {
        if (segment.types.indexOf('locality') !== -1) {
          loc.city = segment.long_name;
          loc.short_city = segment.short_name;
        } else if (segment.types.indexOf('postal_town') !== -1 && !loc.city) {
          loc.city = segment.long_name;
          loc.short_city = segment.short_name;
        } else if (segment.types.indexOf('administrative_area_level_1') !== -1) {
          loc.state = segment.long_name;
          loc.short_state = segment.short_name;
        } else if (segment.types.indexOf('administrative_area_level_2') !== -1) {
          loc.county = segment.long_name;
          loc.short_county = segment.short_name;
        } else if (segment.types.indexOf('country') !== -1) {
          loc.country = segment.long_name;
          loc.short_country = segment.short_name;
        }
      });
      return loc;
    }

    async boundingBox(query) {
      let google = await this.googleMapsApi.google;
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (query) {
          return new google.maps.Geocoder().geocode({
            address: query
          }, (data, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
              reject();
              return;
            }

            let obj = data && data[0] ? data[0] : {};
            let geometry = obj.geometry || {};
            let viewport = geometry.viewport;
            let northeast = Ember.isPresent(viewport) ? viewport.getNorthEast() : undefined;
            let southwest = Ember.isPresent(viewport) ? viewport.getSouthWest() : undefined;

            if (!viewport || !northeast || !southwest) {
              return reject();
            }

            let locations = [];

            if (obj !== null) {
              obj.address_components.filter(m => {
                if (m.types.indexOf('locality') !== -1) {
                  return locations.push(m.long_name);
                } else if (m.types.indexOf('administrative_area_level_1') !== -1) {
                  return locations.unshift(m.long_name);
                }
              });
            }

            let response = {
              northeast: {
                lat: northeast.lat(),
                long: northeast.lng()
              },
              southwest: {
                lat: southwest.lat(),
                long: southwest.lng()
              },
              location: {
                lat: data[0] !== null ? data[0].geometry.location.lat() : undefined,
                lng: data[0] !== null ? data[0].geometry.location.lng() : undefined,
                long: data[0] !== null ? data[0].geometry.location.lng() : undefined,
                merged: locations.join('/').replace(/\s+/gi, '-'),
                crumbs: locations,
                location: this.geocodeParsePlace(obj)
              },
              place: obj
            };
            return resolve(response);
          });
        }

        return reject('the query passed in to boundingBox was empty');
      });
    }

    getBounds(place) {
      let geometry = place.geometry || {};
      let viewport = geometry.viewport;
      let northEast = Ember.isPresent(viewport) ? viewport.northeast || viewport.getNorthEast() : undefined;
      let southWest = Ember.isPresent(viewport) ? viewport.southwest || viewport.getSouthWest() : undefined;
      return {
        neLat: getOrCall(northEast.lat),
        neLng: getOrCall(northEast.lng),
        swLat: getOrCall(southWest.lat),
        swLng: getOrCall(southWest.lng)
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "googleMapsApi", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeocoderService;
});