define("@outdoorsyco/ember-shared-data/adapters/user", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class User extends _application.default {
    // Employees get deleted via a different endpoint.
    // There is no support for deleting anything but Employees
    // So....here we are
    urlForDeleteRecord(id, modelName, snapshot) {
      let url = super.urlForDeleteRecord(...arguments);

      if (snapshot && snapshot.record && !snapshot.record.get('employee')) {
        url = url.replace(/users\/\d+$/, `users/${id}/archive`);
      } else {
        url = url.replace(/users\/\d+$/, `users/dealers/${id}`);
      }

      return url;
    }

  }

  _exports.default = User;
});