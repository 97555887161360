define("ember-svg-jar/inlined/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M12 11.727A2.109 2.109 0 019.894 9.62 2.11 2.11 0 0112 7.512a2.11 2.11 0 012.107 2.108A2.11 2.11 0 0112 11.727m0-5.715A3.611 3.611 0 008.394 9.62 3.61 3.61 0 0012 13.227a3.611 3.611 0 003.607-3.607A3.611 3.611 0 0012 6.012m0 14.905C9.68 18.444 5.131 12.915 5.131 9.62c0-4.462 3.54-6.87 6.87-6.87s6.868 2.408 6.868 6.87c0 3.295-4.548 8.824-6.869 11.297M12 1.25c-4.114 0-8.369 3.13-8.369 8.37 0 4.918 7.515 12.584 7.836 12.908a.75.75 0 001.066 0c.321-.324 7.836-7.99 7.836-12.908 0-5.24-4.255-8.37-8.369-8.37\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});