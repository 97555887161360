define("ember-cli-flash/utils/object-without", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectWithout;

  function objectWithout(originalObj = {}, keysToRemove = []) {
    let newObj = {};

    for (let key in originalObj) {
      if (keysToRemove.indexOf(key) === -1) {
        newObj[key] = originalObj[key];
      }
    }

    return newObj;
  }
});