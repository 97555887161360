define("@glimmer/component/-private/component-manager", ["exports", "@glimmer/component/-private/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GlimmerComponentManager {
    static create(attrs) {
      let owner = Ember.getOwner(attrs);
      return new this(owner);
    }

    constructor(owner) {
      this.capabilities = void 0;
      Ember.setOwner(this, owner);

      if (true) {
        this.capabilities = Ember._componentManagerCapabilities('3.13', {
          destructor: true,
          asyncLifecycleCallbacks: true,
          updateHook: false
        });
      } else {
        this.capabilities = Ember._componentManagerCapabilities('3.4', {
          destructor: true,
          asyncLifecycleCallbacks: true
        });
      }
    }

    createComponent(Klass, args) {
      if (false
      /* DEBUG */
      ) {
        _component.ARGS_SET.add(args.named);
      }

      return new Klass(Ember.getOwner(this), args.named);
    }

    destroyComponent(component) {
      if (component.isDestroying) {
        return;
      }

      let meta = Ember.meta(component);
      meta.setSourceDestroying();
      component[_component.DESTROYING] = true;
      Ember.run.schedule('actions', component, component.willDestroy);
      Ember.run.schedule('destroy', this, this.scheduledDestroyComponent, component, meta);
    }

    scheduledDestroyComponent(component, meta) {
      if (component.isDestroyed) {
        return;
      }

      Ember.destroy(component);
      meta.setSourceDestroyed();
      component[_component.DESTROYED] = true;
    }

    didCreateComponent() {}

    didUpdateComponent() {}

    getContext(component) {
      return component;
    }

  } // In Ember 3.12 and earlier, the updateComponent hook was mandatory.
  // As of Ember 3.13, the `args` object is stable and each property of the
  // object participates in the autotrack stack on its own. This means we do not
  // need to set the `args` property on the component instance to invalidate
  // tracked getters that rely on `args`, and therefore don't require the `updateComponent`
  // hook at all.


  _exports.default = GlimmerComponentManager;

  if (!true) {
    GlimmerComponentManager.prototype.updateComponent = function updateComponent(component, args) {
      let argSnapshot = args.named;

      if (false
      /* DEBUG */
      ) {
        argSnapshot = Object.freeze(argSnapshot);
      }

      Ember.set(component, 'args', argSnapshot);
    };
  }
});