define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/explore-menu/component", ["exports", "ember-window-mock", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _emberWindowMock, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExploreMenu = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class ExploreMenu extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "metrics", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "metadata", _descriptor6, this);

      _defineProperty(this, "domain", null);

      _defineProperty(this, "config", null);

      _defineProperty(this, "currentCountry", '');

      _defineProperty(this, "filters", {});

      _defineProperty(this, "renterResources", renterResources);

      _defineProperty(this, "fastboot", null);

      _initializerDefineProperty(this, "onPlaceDetailsSelected", _descriptor7, this);
    }

    get threeDots() {
      return Ember.String.htmlSafe(threeDots);
    }

    get sections() {
      let section = contentSections;
      const intlLocale = this.intl.locale;

      if (intlLocale.includes('en-ca') || intlLocale.includes('fr-ca')) {
        section = canadianContentSection;
      }

      return section;
    }

    get neverIdleLink() {
      let locale = this.intl.locale[0];
      let domain = this.domain;

      if (locale !== 'en-us') {
        return `${domain}/neveridle`;
      }

      return `${domain}/blog`;
    }

    search(event) {
      event.preventDefault();
      this.metrics.trackEvent({
        event: 'desktop-header-search',
        query: this.filters
      });

      if (Ember.getOwner(this).lookup(`route:rv-search`)) {
        this.router.transitionTo('rv-search', {
          queryParams: this.filters
        });
      } else {
        _emberWindowMock.default.location.href = `/rv-search?address=${this.filters.address}`;
      }
    }

    updateCountry(country) {
      this.session.updateCountry(country);
    }

    _updateFilters(newParams = {}) {
      if (!this.isDestroyed && !this.isDestroying) {
        Ember.set(this, 'filters', Ember.assign({}, this.filters, newParams));
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "metadata", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onPlaceDetailsSelected", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* ({
        placeDetails
      }) {
        yield this._updateFilters({
          address: placeDetails.formatted_address
        });
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "search", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateCountry", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateCountry"), _class.prototype)), _class));
  _exports.default = ExploreMenu;
  const threeDots = `<svg width="20" height="4" viewBox="0 0 20 4" version="1" xmlns="http://www.w3.org/2000/svg">
  <g fill="#4A4A4A" fill-rule="evenodd">
    <circle cx="2" cy="2" r="2"/>
    <circle cx="10" cy="2" r="2"/>
    <circle cx="18" cy="2" r="2"/>
  </g>
</svg>`;
  const contentSections = [{
    display: 'Los Angeles, CA',
    path: 'rv-rental/california/los-angeles'
  }, {
    display: 'Las Vegas, NV',
    path: 'rv-rental/nevada/las-vegas'
  }, {
    display: 'San Francisco, CA',
    path: 'rv-rental/california/san-francisco'
  }, {
    display: 'Denver, CO',
    path: 'rv-rental/colorado/denver'
  }, {
    display: 'Seattle, WA',
    path: 'rv-rental/washington/seattle'
  }, {
    display: 'Austin, TX',
    path: 'rv-rental/texas/austin'
  }, {
    display: 'New York, NY',
    path: 'rv-rental/new-york/new-york'
  }, {
    display: 'Houston, TX',
    path: 'rv-rental/texas/houston'
  }, {
    display: 'Phoenix, AZ',
    path: 'rv-rental/arizona/phoenix'
  }, {
    display: 'San Diego, CA',
    path: 'rv-rental/california/san-diego'
  }, {
    display: 'Chicago, IL',
    path: 'rv-rental/illinois/chicago'
  }, {
    display: 'Boston, MA',
    path: 'rv-rental/massachusetts/boston'
  }, {
    display: 'Miami, FL',
    path: 'rv-rental/florida/miami'
  }, {
    display: 'Portland, OR',
    path: 'rv-rental/oregon/portland'
  }, {
    display: 'San Antonio, TX',
    path: 'rv-rental/texas/san-antonio'
  }, {
    display: 'Salt Lake City, UT',
    path: 'rv-rental/utah/salt-lake-city'
  }, {
    display: 'Maui, HI',
    path: 'rv-rental/hawaii/maui'
  }, {
    display: 'Honolulu, HI',
    path: 'rv-rental/hawaii/honolulu'
  }, {
    display: 'Orlando, FL',
    path: 'rv-rental/florida/orlando'
  }, {
    display: 'Kauai, HI',
    path: 'rv-rental/hawaii/kauai'
  }, {
    display: 'Dallas, TX',
    path: 'rv-rental/texas/dallas'
  }, {
    display: 'Atlanta, GA',
    path: 'rv-rental/georgia/atlanta'
  }, {
    display: 'Minneapolis, MN',
    path: 'rv-rental/minnesota/minneapolis'
  }, {
    display: 'Oklahoma City, OK',
    path: 'rv-rental/oklahoma/oklahoma-city'
  }, {
    display: 'Columbus, OH',
    path: 'rv-rental/ohio/columbus'
  }, {
    display: 'Raleigh, NC',
    path: 'rv-rental/north-carolina/raleigh'
  }, {
    display: 'Kailua-Kona, HI',
    path: 'rv-rental/hawaii/kailua-kona'
  }, {
    display: 'Nashville, TN',
    path: 'rv-rental/tennessee/nashville'
  }];
  const canadianContentSection = [{
    display: 'Winnipeg, MB',
    path: 'rv-rental/canada/manitoba/winnipeg'
  }, {
    display: 'Calgary, AB',
    path: 'rv-rental/canada/alberta/calgary'
  }, {
    display: 'Saskatoon, SK',
    path: 'rv-rental/canada/saskatchewan/saskatoon'
  }, {
    display: 'Vancouver, BC',
    path: 'rv-rental/canada/british-columbia/vancouver'
  }, {
    display: 'Nanaimo, BC',
    path: 'rv-rental/canada/british-columbia/nanaimo'
  }, {
    display: 'London, ON',
    path: 'rv-rental/canada/ontario/london'
  }, {
    display: 'Regina, SK',
    path: 'rv-rental/canada/saskatchewan/regina'
  }, {
    display: 'Edmonton, AB',
    path: 'rv-rental/canada/alberta/edmonton'
  }, {
    display: 'Hamilton, ON',
    path: 'rv-rental/canada/ontario/hamilton'
  }, {
    display: 'Victoria, BC',
    path: 'rv-rental/canada/british-columbia/victoria'
  }, {
    display: 'Ottawa, ON',
    path: 'rv-rental/canada/ontario/ottawa'
  }, {
    display: 'Kelowna, BC',
    path: 'rv-rental/canada/british-columbia/kelowna'
  }, {
    display: 'Montreal, QC',
    path: 'rv-rental/canada/quebec/montreal'
  }, {
    display: 'Red Deer, AB',
    path: 'rv-rental/canada/alberta/red-deer'
  }, {
    display: 'Barrie, ON',
    path: 'rv-rental/canada/ontario/barrie'
  }, {
    display: 'Kamloops, BC',
    path: 'rv-rental/canada/british-columbia/kamloops'
  }, {
    display: 'Toronto, ON',
    path: 'rv-rental/canada/ontario/toronto'
  }, {
    display: 'Windsor, ON',
    path: 'rv-rental/canada/ontario/windsor'
  }, {
    display: 'Banff, AB',
    path: 'rv-rental/canada/alberta/banff'
  }, {
    display: 'Niagara Falls, ON',
    path: 'rv-rental/canada/ontario/niagara-falls'
  }, {
    display: 'Prince George, BC',
    path: 'rv-rental/canada/british-columbia/prince-george'
  }, {
    display: 'Campbell River, BC',
    path: 'rv-rental/canada/british-columbia/campbell-river'
  }, {
    display: 'Sault Ste. Marie, ON',
    path: 'rv-rental/canada/ontario/sault-ste-marie'
  }, {
    display: 'Mississauga, ON',
    path: 'rv-rental/canada/ontario/mississauga'
  }, {
    display: 'Kitchener, ON',
    path: 'rv-rental/canada/ontario/kitchener'
  }, {
    display: 'Chilliwack, BC',
    path: 'rv-rental/canada/british-columbia/chilliwack'
  }, {
    display: 'Penticton, BC',
    path: 'rv-rental/canada/british-columbia/penticton'
  }, {
    display: 'Sarnia, ON',
    path: 'rv-rental/canada/ontario/sarnia'
  }];
  const renterResources = [{
    path: 'https://www.outdoorsy.com/guide/parks',
    display: 'Rent an RV near state or national park'
  }, {
    path: 'https://www.outdoorsy.com/blog',
    display: 'RV tips and tricks'
  }, {
    path: '/rv-search',
    display: 'RV rentals near me'
  }];
});