define("@outdoorsyco/ember-shared-data/components/input/phone-number/component", ["exports", "@outdoorsyco/ember-switchback/components/form-input/component", "ember-concurrency-decorators", "ember-animated/motions/opacity", "ember-animated/motions/move"], function (_exports, _component, _emberConcurrencyDecorators, _opacity, _move) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InputPhoneNumberComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._action, _dec18 = Ember._action, (_class = class InputPhoneNumberComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "apiRequest", _descriptor3, this);

      _initializerDefineProperty(this, "metrics", _descriptor4, this);

      _initializerDefineProperty(this, "apiError", _descriptor5, this);

      _initializerDefineProperty(this, "user", _descriptor6, this);

      _initializerDefineProperty(this, "fourDigits", _descriptor7, this);

      _initializerDefineProperty(this, "editMode", _descriptor8, this);

      _initializerDefineProperty(this, "isVerified", _descriptor9, this);

      _initializerDefineProperty(this, "isValidated", _descriptor10, this);

      _initializerDefineProperty(this, "shouldValidateNumber", _descriptor11, this);

      _defineProperty(this, "isVerifyingNumber", false);

      _initializerDefineProperty(this, "verifyPhoneErrorMessage", _descriptor12, this);

      _initializerDefineProperty(this, "hasValidationError", _descriptor13, this);

      _initializerDefineProperty(this, "hasVerificationError", _descriptor14, this);

      _initializerDefineProperty(this, "isPhoneVerificationSent", _descriptor15, this);

      _initializerDefineProperty(this, "state", _descriptor16, this);

      _defineProperty(this, "id", Ember.guidFor(this));

      _initializerDefineProperty(this, "onInsert", _descriptor17, this);

      _initializerDefineProperty(this, "editSave", _descriptor18, this);

      _initializerDefineProperty(this, "validatePhoneNumber", _descriptor19, this);

      _initializerDefineProperty(this, "sendVerificationCode", _descriptor20, this);

      _initializerDefineProperty(this, "finalizePhoneVerification", _descriptor21, this);

      _initializerDefineProperty(this, "resendVerificationCode", _descriptor22, this);

      _initializerDefineProperty(this, "onFocusOut", _descriptor23, this);

      _defineProperty(this, "isValidPhoneNumberValidator", {
        param: 'phoneNumber',
        error: {
          key: 'error.validator.phone',
          message: 'This must be a valid phone number'
        },
        validate: function validate(phoneNumber, {
          shouldValidateNumber,
          isValidated
        }) {
          const phoneLength = // eslint-disable-next-line no-useless-escape
          !Ember.isEmpty(phoneNumber) || phoneNumber.replace(/[\(\)\+\-\s]+/g, '').length >= 7;
          return phoneLength && shouldValidateNumber ? isValidated : true;
        }
      });

      _defineProperty(this, "isVerifiedValidator", {
        param: 'phoneNumber',
        error: {
          key: 'input-phone-numbers-verification-error',
          message: 'We were unable to verify your phone number. Please try again'
        },
        validate: function validate(value, {
          isVerifyingNumber,
          isVerified
        }) {
          return isVerifyingNumber ? true : isVerified;
        }
      });
    }

    /**
     * Show user send validation button.
     */
    get shouldShowValidateAction() {
      var _this$user, _this$user2;

      const isStateVerified = this.state === 'is_verified';
      const isStateVerificationSent = this.state === 'is_verification_sent';
      const isStateEditing = this.state === 'is_editing';
      const hasPhoneNumber = (_this$user = this.user) === null || _this$user === void 0 ? void 0 : _this$user.phone;
      const isPhoneVerified = (_this$user2 = this.user) === null || _this$user2 === void 0 ? void 0 : _this$user2.isPhoneVerified;

      if (isStateVerified || isStateVerificationSent || isStateEditing) {
        return false;
      }

      return !isPhoneVerified && Boolean(hasPhoneNumber);
    }

    /**
     * We invoke the 'onChange' argument that should mutate
     * the phone number value on the invoking data owner's side.
     *
     * @param {String} value
     */
    onChange(value) {
      this.verifyPhoneErrorMessage = null;
      Ember.tryInvoke(this.args, 'onChange', [value]);
    }
    /**
     * Applies a rollback on user attributes and
     * sets `editMode` on false. Also triggers the
     * states to go back to initial state
     * (as default on first render).
     */


    cancelEdit() {
      this.user.rollbackAttributes();

      this._updateEditMode(false);
    }
    /**
     * Sets the initial states by checking the
     * current user phone and verification status.
     *
     * @private
     */


    _setInitialState() {
      if (!this.user.phone) {
        this._updateEditMode(true);
      } else if (!this.user.phoneVerified) {
        this._updateValidated(true);

        return;
      } else if (this.user.phoneVerified) {
        this._updateVerified(this.user.phoneVerified);

        return;
      } else {
        this._updateValidated(true);

        this._updateVerified(this.user.phoneVerified);
      }
    }
    /**
     * Updates the current state of the component,
     * the allowed values for the `state` param are:
     *
     * "is_editing"
     * "is_verified"
     * "is_verification_sent"
     *
     * @param {String} state
     * @private
     */


    _updateState(state) {
      this.state = state;
    }
    /**
     * Updates edit mode state, invokes 'onEditModeChange' if provided.
     * @param {Boolean} status
     * @private
     */


    _updateEditMode(status) {
      this.editMode = status;

      if (status) {
        this._updateState('is_editing');
      } else {
        this._setInitialState();
      }

      Ember.tryInvoke(this.args, 'onEditModeChange', [status]);
    }
    /**
     * Updates phone validation related states,
     * invokes 'onValidationChange' if provided.
     * @param {Boolean} status
     * @private
     */


    _updateValidated(status) {
      if (status) {
        this.hasValidationError = false;
        this.shouldValidateNumber = false;
      }

      this.isValidated = status;
      Ember.tryInvoke(this.args, 'onValidationChange', [status]);
    }
    /**
     * Updates phone verification related states,
     * invokes 'onVerificationChange' if provided.
     *
     * @param {Boolean} status
     * @private
     */


    _updateVerified(status, preventSendingVerification) {
      if (status) {
        this._updateState('is_verified');

        this._updatePhoneVerificationSent(false);

        this.hasVerificationError = false;
      } else {
        if (!this.editMode) {
          if (this.isPhoneVerificationSent) {
            this._updatePhoneVerificationSent(true);
          } else if (!preventSendingVerification) {
            this.sendVerificationCode.perform();
          }
        } else {
          this._updateState('is_editing');
        }
      }

      this.isVerifyingNumber = false;
      this.isVerified = status;
      Ember.tryInvoke(this.args, 'onVerificationChange', [status]);
    }
    /**
     * Updates phone isPhoneVerificationSent flag and
     * invokes 'onPhoneVerificationSent' if provided.
     *
     * @param {Boolean} status
     * @private
     */


    _updatePhoneVerificationSent(status) {
      this.isPhoneVerificationSent = status;

      if (status) {
        this._updateState('is_verification_sent');
      }

      Ember.tryInvoke(this.args, 'onPhoneVerificationSent', [status]);
    }
    /**
     * Custom Switchback validation that checks context variables in order
     * to determine if the phone number is valid or not.
     *
     * @todo Replace this with an improved async
     * and server-assisted version of this validator.
     */


    /**
     * Originally called "delayed-fade" on @outdoorsyco/outdoorsy-addon,
     * but with a few tweaks on the sprite move/fade durations
     */
    *transition({
      removedSprites,
      insertedSprites,
      duration
    }) {
      for (let sprite of removedSprites) {
        yield Promise.all([sprite.endTranslatedBy(8, 0), (0, _move.default)(sprite, {
          duration: duration * 0.2
        }), (0, _opacity.fadeOut)(sprite, {
          duration: duration * 0.2
        })]);
      }

      for (let sprite of insertedSprites) {
        yield Promise.all([sprite.startTranslatedBy(8, 0), (0, _move.default)(sprite, {
          duration: duration * 0.3
        }), (0, _opacity.fadeIn)(sprite, {
          duration: duration * 0.3
        })]);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "apiError", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fourDigits", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "editMode", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isVerified", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isValidated", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "shouldValidateNumber", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "verifyPhoneErrorMessage", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "hasValidationError", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "hasVerificationError", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isPhoneVerificationSent", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "onInsert", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        yield this.session.userPromise;
        this.user = this.session.user;

        this._setInitialState();
      };
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "editSave", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        let editMode = !this.editMode;

        if (!editMode) {
          try {
            yield this.validatePhoneNumber.perform();
            yield this.user.reload();

            this._updateEditMode(false);

            if (this.user.phoneVerified) {
              this.isVerified = true;
            } else {
              yield this.sendVerificationCode.perform();
            }
          } catch (error) {
            this._updateEditMode(true);

            let errorObject = new Error(this.intl.t('error.validator.phone'));
            errorObject.name = 'FormSubmit';
            throw errorObject;
          }
        } else {
          this._updateEditMode(true);

          this.shouldValidateNumber = true;
        }
      };
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "validatePhoneNumber", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        const user = this.user;

        try {
          yield user.save();

          this._updateValidated(true);
        } catch (error) {
          this.hasValidationError = true;
          this.shouldValidateNumber = true;

          this._updateValidated(false);

          this._updateVerified(false);

          this.metrics.trackEvent({
            event: 'User : Phone Validation : Failed'
          });
          let errorObject = new Error(this.intl.t('error.validator.phone'));
          errorObject.name = 'FormSubmit';
          throw errorObject;
        }
      };
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "sendVerificationCode", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        try {
          this.verifyPhoneErrorMessage = null;
          yield this.apiRequest.post('verify/phone').catch(error => {
            this.hasValidationError = true;

            this._updateVerified(false, true);

            let errorFormated = this.apiError.getMessageAndCode(error, 'generic-error');

            if (errorFormated.message) {
              this.verifyPhoneErrorMessage = errorFormated.message;
            }

            throw error;
          });
          this.fourDigits = '';

          this._updatePhoneVerificationSent(true);

          this._updateState('is_verification_sent');
        } catch (error) {
          this.hasValidationError = true;

          this._updateVerified(false, true);

          let errorObject = new Error(this.intl.t('input-phone-numbers-verification-error'));
          errorObject.name = 'FormSubmit';
          throw errorObject;
        }
      };
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "finalizePhoneVerification", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (code) {
        try {
          const url = `verify/phone/finalize?code=${code}`;
          this.isVerifyingNumber = true;
          yield this.apiRequest.post(url);

          this._updateVerified(true);

          yield this.user.reload();
          this.metrics.trackEvent({
            event: 'User : Phone Verification : Succeed'
          });
        } catch (error) {
          this._updateVerified(false);

          this.metrics.trackEvent({
            event: 'User : Phone Verification : Failed'
          });
          this.hasVerificationError = true;
          let errorObject = new Error(this.intl.t('input-phone-numbers-verification-error'));
          errorObject.name = 'FormSubmit';
          throw errorObject;
        } finally {
          if (typeof this.args.onFinalizeVerification === 'function') {
            this.args.onFinalizeVerification(this.isVerified);
          }
        }
      };
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "resendVerificationCode", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        this.fourDigits = '';
        this.hasVerificationError = false;

        this._updateVerified(false, true);

        yield this.sendVerificationCode.perform();
      };
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "onFocusOut", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        try {
          // eslint-disable-next-line no-useless-escape
          if (!Ember.isEmpty(this.user.phone) || this.user.phone.replace(/[\(\)\+\-\s]+/g, '').length >= 7) {
            yield this.editSave.perform();
          }
        } catch (error) {
          if (error.name !== 'FormSubmit') {
            throw error;
          }
        }

        return yield this.focusOut.perform();
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelEdit", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEdit"), _class.prototype)), _class));
  _exports.default = InputPhoneNumberComponent;
});