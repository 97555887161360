define("@outdoorsyco/outdoorsy-addon/components/lazy-loader/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/lazy-loader/template", "ember-in-viewport"], function (_exports, _template, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    layout: _template.default,
    isLoaded: false,

    didInsertElement() {
      Ember.setProperties(this, {
        viewportTolerance: {
          top: 100,
          bottom: 400,
          left: 100,
          right: 100
        }
      });

      this._super(...arguments);
    },

    didEnterViewport() {
      this._super(...arguments);

      this.set('isLoaded', true);
    }

  });

  _exports.default = _default;
});