define('ember-mobile-pane/components/mobile-pane/nav/item', ['exports', 'ember-mobile-pane/templates/components/mobile-pane/nav/item', 'ember-mobile-pane/mixins/component-child'], function (exports, _item, _componentChild) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentChild.default, {
    layout: _item.default,
    tagName: 'li',
    classNames: ['nav__item'],

    onClick: function onClick() {},


    isActive: Ember.computed('navItem.elementId', 'activePane.elementId', function () {
      return Ember.get(this, 'navItem.elementId') === Ember.get(this, 'activePane.elementId');
    }),

    actions: {
      clickItem: function clickItem() {
        Ember.get(this, 'onClick')(Ember.get(this, 'navItem'));
      }
    }
  });
});