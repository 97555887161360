define("ember-data-storefront/-private/record-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class RecordQuery {
    constructor(store, type, id, params = {}) {
      this.store = store;
      this.type = type;
      this.id = id;
      this.params = params; // if we have no params, we can use the model from
      // the store if it exists, nice lil shortcut here.

      this.value = Object.keys(this.params).length === 0 ? this.store.peekRecord(type, id) : null;
    }

    run() {
      // if we're running a query in storefront we always want
      // a blocking promise, so we force reload true.
      let options = _objectSpread(_objectSpread({}, {
        reload: true
      }), this.params);

      return this.store.findRecord(this.type, this.id, options).then(record => {
        this.value = record;
        return record;
      });
    }

  }

  _exports.default = RecordQuery;
});