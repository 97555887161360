define("@outdoorsyco/ember-shared-data/serializers/bundle", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class BundleSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        rules: {
          deserialize: 'records',
          serialize: false
        },
        features: {
          deserialize: 'records',
          serialize: false
        }
      });
    }

    // not sure why, but relationships get normalized as `name_ids`
    // and we need to make it aware of those.
    keyForRelationship(key, relationship, method) {
      if (method !== 'serialize' && (key === 'features' || key === 'rules')) {
        return `${key}_ids`;
      }

      return super.keyForRelationship(key, relationship, method);
    } // Model -> what the API expects


    serialize(record, options) {
      let data = super.serialize(record, options);
      let tax_rate_ids = data.tax_rates || [];
      data.tax_rate_ids = tax_rate_ids.map(tr => parseInt(tr));
      delete data.tax_rates;
      return data;
    } // API -> what the Model expects


    normalize(typeClass, hash) {
      hash.links = hash.links || {};
      hash.links.localizedContent = `/v0/localized-content?object_type=bundles&object_id=${hash.id}`;
      let tax_rate_ids = hash.tax_rate_ids || [];
      hash.tax_rates = tax_rate_ids;
      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = BundleSerializer;
});