define("@outdoorsyco/ember-switchback/components/modal/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "raisedShadow600": "0 24px 64px 0 hsla(0, 0%, 0%, 0.15)",
    "Modal": "_Modal_ouz4mo",
    "overlay": "_overlay_ouz4mo",
    "window": "_window_ouz4mo"
  };
  _exports.default = _default;
});