define("@outdoorsyco/ember-shared-data/components/input/location/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Google Place objects have functions; Mirage responses just have props
  function getOrCall(expression) {
    return typeof expression === 'function' ? expression.call() : expression;
  }

  let InputLocationComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = (0, _emberConcurrencyDecorators.task)({
    restartable: true
  }), _dec11 = Ember._action, (_class = class InputLocationComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "googleMapsApi", _descriptor, this);

      _initializerDefineProperty(this, "geocoder", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _defineProperty(this, "autocompleteOptions", {
        types: this.args.types || ['address'],
        // limit the fields, so we don't query the Google places API because it gets more
        // pricy the more fields it returns
        // https://developers.google.com/maps/documentation/javascript/reference/places-widget#AutocompleteOptions.fields
        fields: ['place_id', 'name', 'types', 'geometry', 'address_component']
      });

      _initializerDefineProperty(this, "destinationDistance", _descriptor4, this);

      _initializerDefineProperty(this, "distanceUnit", _descriptor5, this);

      _initializerDefineProperty(this, "calculateDestinationDistance", _descriptor6, this);
    }

    get canClear() {
      return this.args.allowClear && (this.displayString !== '' || Ember.isPresent(this.value));
    }

    get displayString() {
      if (this.args.displayType === 'price') {
        if (typeof this.args.price !== 'number') {
          return '';
        }

        return this.intl.formatNumber(this.args.price || 0, {
          style: 'currency',
          currency: this.args.currency,
          currencyDisplay: 'symbol'
        });
      }

      return this.destinationDistanceString;
    }

    get destinationDistanceString() {
      if (this.destinationDistance && this.distanceUnit) {
        return this.intl.t('global.unit.formatted-fixed-short', {
          amount: this.destinationDistance,
          unit: this.distanceUnit
        });
      }

      return '';
    }

    async setupAutocomplete(input) {
      let google = await this.googleMapsApi.google;
      let places = google.maps.places;
      let autocomplete = new places.Autocomplete(input, this.autocompleteOptions);
      autocomplete.addListener('place_changed', async () => {
        let place = autocomplete.getPlace();
        Ember.tryInvoke(this.args, 'onSelect', [place]);
        this.updateDeliveryAddress(place);
      });
    }
    /**
     * The address input value isn't stored anywhere to this uses the pickup
     * address place_id to find the formatted address and set it to the
     * destination field so the input can be used to edit an existing address.
     */


    async setInitialDestinationAndDistance() {
      let placeId = this.args.pickupPlaceId;

      if (!placeId || !this.args.start) {
        return;
      }

      let location = await this.geocoder.geocodePlaceId(placeId);
      this.deliveryAddress = (location || {}).formatted_address;
      let longitude = Ember.get(location, 'geometry.location.lng');
      let latitude = Ember.get(location, 'geometry.location.lat');

      if (!longitude || !latitude) {
        return;
      }

      let distance = await this.calculateDestinationDistance.perform({
        lng: getOrCall(longitude),
        lat: getOrCall(latitude)
      });
      this.destinationDistance = distance.distance;
      this.distanceUnit = distance.unit;
      Ember.tryInvoke(this.args, 'checkDistance', [distance]);
    }
    /**
     * clear the delivery address
     */


    async clearAddress() {
      this.destinationDistance = 0;
      this.distanceUnit = null;
      Ember.tryInvoke(this.args, 'onDeliveryUpdate', [{}]);
    }
    /**
     * Updates the booking's delivery address. Doesn't run if start is not provided.
     *
     * @param {object} location - The location object provided by google places
     */


    async updateDeliveryAddress(location) {
      if (!this.args.start) {
        return;
      }

      let longitude = Ember.get(location, 'geometry.location.lng');
      let latitude = Ember.get(location, 'geometry.location.lat');

      if (!longitude || !latitude) {
        return;
      }

      let distance = await this.calculateDestinationDistance.perform({
        lng: getOrCall(longitude),
        lat: getOrCall(latitude)
      });
      Ember.tryInvoke(this.args, 'checkDistance', [distance]);
      this.destinationDistance = distance.distance;
      this.distanceUnit = distance.unit;
      Ember.tryInvoke(this.args, 'onDeliveryUpdate', [{
        location,
        distance
      }]);
    }
    /**
     * Calculates the distances between the delivery address and a
     * destination
     *
     * @param {object} [destination] - Destination to use when calculating distance
     * @param {number} [destination.lng]
     * @param {number} [destination.lat]
     */


    /**
     * On keydown event, we ignore the enter key from submitting the form.
     */
    onKeyDown(e) {
      switch (e.key) {
        case 'Enter':
          if (!this.args.submitOnEnter) {
            e.preventDefault();
            e.stopPropagation();
          }

          return;

        default:
          break;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "googleMapsApi", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "geocoder", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "destinationDistance", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "distanceUnit", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupAutocomplete", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setupAutocomplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setInitialDestinationAndDistance", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setInitialDestinationAndDistance"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearAddress", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "clearAddress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDeliveryAddress", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updateDeliveryAddress"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "calculateDestinationDistance", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (destination) {
        let start = {
          lng: Ember.get(this.args.start, 'lng'),
          lat: Ember.get(this.args.start, 'lat')
        };
        let unit = this.args.unit;
        let distance = yield this.geocoder.calculateDistance(start, destination, {
          unit
        });
        let distanceAsText = Ember.get(distance, 'distance.text') || '';
        /**
         * Remove all non-number characters except . first so parsInt extras the correct number
         */

        let distanceAsFloat = parseFloat(distanceAsText.replace(/[^\d.]+/g, ''));
        return {
          distance: distanceAsFloat,
          unit: unit
        };
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype)), _class));
  _exports.default = InputLocationComponent;
});