define("@outdoorsyco/ember-shared-data/components/login-form/component", ["exports", "@outdoorsyco/ember-shared-data/components/login-form/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    redirectAfterAuthentication: false,
    loading: false,
    error: null,
    actions: {
      emailClicked() {
        Ember.tryInvoke(this, 'emailClicked');
      },

      passwordClicked() {
        Ember.tryInvoke(this, 'passwordClicked');
      },

      async authenticate() {
        if (this.redirectAfterAuthentication) {
          this.session.set('redirectAfterAuthentication', true);
        }

        this.setProperties({
          loading: true,
          error: null
        });
        Ember.tryInvoke(this, 'loginClicked');
        let {
          email,
          password,
          dealer
        } = this.getProperties('email', 'password', 'dealer');

        try {
          await this.session.authenticate('authenticator:outdoorsy', email, password, dealer);
          let sessionData = this.session.data.authenticated;
          this.sendAction('loginSuccess', sessionData.user_id, sessionData.token); //eslint-disable-line ember/closure-actions
        } catch (error) {
          let errorMessage = this.intl.t('comp-login-form-error-message');
          this.set('errorMessage', error || errorMessage);
        }

        this.set('loading', false);
      }

    }
  });

  _exports.default = _default;
});