define("@outdoorsyco/ember-shared-data/components/password-strength/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "red500": "hsl(358, 80%, 58%)",
    "orange800": "hsl(30, 92%, 33%)",
    "orange700": "hsl(31, 92%, 44%)",
    "green800": "hsl(172, 50%, 28%)",
    "PasswordStrength": "_PasswordStrength_jeg77l",
    "Bar": "_Bar_jeg77l",
    "Strength1": "_Strength1_jeg77l",
    "Strength2": "_Strength2_jeg77l",
    "Strength3": "_Strength3_jeg77l",
    "Strength4": "_Strength4_jeg77l",
    "Strength5": "_Strength5_jeg77l"
  };
  _exports.default = _default;
});