define("@outdoorsyco/outdoorsy-addon/components/alert-bar/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/alert-bar/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    flashMessage: false,
    dismissable: true,
    dismissed: false,
    style: 'info',
    tagName: '',
    actions: {
      dismiss() {
        if (this.get('flashMessage')) {
          return;
        }

        this.set('dismissed', true);
      },

      handleClick() {
        const onClickPassedIn = this.get('onClick');

        if (onClickPassedIn) {
          onClickPassedIn();
        }
      }

    }
  });

  _exports.default = _default;
});