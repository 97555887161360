define("@outdoorsyco/outdoorsy-addon/utils/is-testing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isTesting;

  function isTesting(context) {
    let ENV = Ember.getOwner(context).resolveRegistration('config:environment');
    return ENV.environment === 'test';
  }
});