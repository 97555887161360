define("@outdoorsyco/ember-shared-data/components/input/location/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GHuCPZbP",
    "block": "{\"symbols\":[\"t\",\"@id\",\"@value\",\"@onChange\",\"@requirements\",\"@errorMessages\",\"@form\",\"&attrs\"],\"statements\":[[5,\"text-input\",[[12,\"class\",[29,[[28,\"local-class\",[\"clearable\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/input/location/styles\"]]]]]],[12,\"autocomplete\",\"off\"],[13,8],[3,\"on\",[\"keydown\",[23,0,[\"onKeyDown\"]]]],[3,\"did-insert\",[[23,0,[\"setupAutocomplete\"]]]],[3,\"did-insert\",[[23,0,[\"setInitialDestinationAndDistance\"]]]]],[[\"@id\",\"@value\",\"@onChange\",\"@requirements\",\"@errorMessages\",\"@unit\",\"@form\"],[[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,0,[\"displayString\"]],[23,7,[]]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"Icon\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"location\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"canClear\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",\"  text-pre-link text-font100 \"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"clearAddress\"]]]],[8],[1,[28,\"t\",[\"term-remove\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/input/location/template.hbs"
    }
  });

  _exports.default = _default;
});