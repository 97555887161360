define("calendar-picker/components/od-day-button", ["exports", "calendar-picker/templates/components/od-day-button"], function (_exports, _odDayButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _odDayButton.default,
    tagName: ''
  });

  _exports.default = _default;
});