define("@outdoorsyco/ember-shared-data/services/optimizely", ["exports", "ember-experiments/services/experiments", "@optimizely/optimizely-sdk"], function (_exports, _experiments, _optimizelySdk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OptimizelyService = (_dec = Ember.inject.service, (_class = class OptimizelyService extends _experiments.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "environment", _descriptor, this);

      _defineProperty(this, "optimizelyClientInstance", null);
    }

    // isLoggerEnabled if this is true then we will get logs in the console
    setup(isLoggerEnabled) {
      if (!this.optimizelyClientInstance) {
        this.optimizelyClientInstance = _optimizelySdk.default.createInstance({
          logger: isLoggerEnabled ? undefined : {
            log() {}

          },
          sdkKey: this.environment.config.optimizelyKey,
          eventDispatcher: this.environment.isTest ? {
            dispatchEvent: () => {}
          } : undefined
        });
      }
    }

    isFeatureEnabled(expName, userId, attributes) {
      return this.optimizelyClientInstance.isFeatureEnabled(expName, userId.toString(), attributes);
    }

    trackEvent(eventName, userId, attributes) {
      this.optimizelyClientInstance.track(eventName, userId, attributes);
    }

    setForcedVariation(expName, userId, variation) {
      return this.optimizelyClientInstance.setForcedVariation(expName, userId, variation);
    }

    getFeatureVariable(expName, variableKey, userId, attributes) {
      return this.optimizelyClientInstance.getFeatureVariable(expName, variableKey, userId, attributes);
    }

    getAllFeatureVariables(expName, userId, attributes) {
      return this.optimizelyClientInstance.getAllFeatureVariables(expName, userId.toString(), attributes);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OptimizelyService;
});