define("@outdoorsyco/ember-shared-data/components/country-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UUuFIvxV",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"select-field\",null,[[\"new\",\"disabled\",\"label\",\"formElementId\",\"options\",\"placeholder\",\"value\",\"searchField\",\"onChange\",\"showErrors\",\"error\",\"dropdownClass\",\"renderInPlace\"],[[24,[\"new\"]],[24,[\"disabled\"]],[24,[\"label\"]],[24,[\"id\"]],[24,[\"countries\"]],[24,[\"placeholder\"]],[28,\"find-by\",[\"value\",[24,[\"value\"]],[24,[\"countries\"]]],null],\"display\",[28,\"action\",[[23,0,[]],\"setCountry\"],null],[24,[\"showErrors\"]],[24,[\"error\"]],[24,[\"dropdownClass\"]],[24,[\"renderInPlace\"]]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"display\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/country-select/template.hbs"
    }
  });

  _exports.default = _default;
});