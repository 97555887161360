define("@outdoorsyco/ember-shared-data/modifiers/model-did-update", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class RouteWillChangeModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "model", null);

      _defineProperty(this, "callback", null);
    }

    addListener() {
      let [model, callback] = this.args.positional;
      this.model = model;

      this.callback = () => callback(model);

      this.model.on('didUpdate', this.callback);
    }

    removeListener() {
      if (this.model && this.callback) {
        this.model.off('didUpdate', this.callback);
      }

      this.model = null;
      this.callback = null;
    }

    didReceiveArguments() {
      this.removeListener();
      this.addListener();
    }

    willRemove() {
      this.removeListener();
    }

  }

  _exports.default = RouteWillChangeModifier;
});