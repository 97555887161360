define("@outdoorsyco/ember-shared-data/adapters/search/-search-api", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;

  var _default = JSONAPIAdapter.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    locale: Ember.computed(function () {
      return this.get('intl.locale.firstObject');
    }),
    host: Ember.computed(function () {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (this.fastboot && this.fastboot.isFastBoot && config.APP.internalSearchApi && !config.isDev && !config.isCI) {
        return config.APP.internalSearchApi;
      }

      return config.APP.searchApi;
    }),
    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),
    headers: Ember.computed(function () {
      let h = {
        'X-App-ID': 'outdoorsycom'
      };

      if (this.get('fastboot.isFastBoot')) {
        h['X-App-Session'] = 'fastboot';
        h['X-Device-ID'] = 'fastboot';
      }

      if (this.locale) {
        const locale = this.locale === 'en' ? 'en-us' : this.locale;
        h['Client-Locale'] = locale;
      }

      if (window.Outdoorsy && window.Outdoorsy.metrics && window.Outdoorsy.metrics.getIdentifier) {
        let {
          sid,
          did
        } = window.Outdoorsy.metrics.getIdentifier();
        h['X-App-Session'] = sid;
        h['X-Device-ID'] = did;
      }

      let accessToken = this.get('session.data.authenticated.token');

      if (Ember.isPresent(accessToken)) {
        h['Authorization'] = `Bearer ${accessToken.replace('guest:', '')}`;
      }

      return h;
    }).volatile()
  });

  _exports.default = _default;
});