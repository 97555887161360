define("@outdoorsyco/ember-shared-data/models/booking-bundle", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  /**
   * booking-bundle is an instance of a bundle or "protection plan" that is available for or
   * has been applied to a booking
   */

  let BookingBundle = (_dec = attr('string'), _dec2 = attr('string'), _dec3 = Ember.computed('description'), _dec4 = attr('string'), _dec5 = attr('string'), _dec6 = attr('number'), _dec7 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec8 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec9 = attr('number'), _dec10 = attr('string'), _dec11 = belongsTo('user', {
    async: true
  }), _dec12 = attr('string'), _dec13 = attr('number'), _dec14 = attr('number'), _dec15 = attr('boolean'), _dec16 = attr('boolean'), _dec17 = Ember.computed('totalPrice'), _dec18 = Ember.computed('pricePerDay'), _dec19 = Ember.computed('totalPrice'), (_class = class BookingBundle extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "description", _descriptor2, this);

      _initializerDefineProperty(this, "subtext", _descriptor3, this);

      _initializerDefineProperty(this, "note", _descriptor4, this);

      _initializerDefineProperty(this, "tier", _descriptor5, this);

      _initializerDefineProperty(this, "bundleFeatures", _descriptor6, this);

      _initializerDefineProperty(this, "offerIndividualServiceTypes", _descriptor7, this);

      _initializerDefineProperty(this, "deductible", _descriptor8, this);

      _initializerDefineProperty(this, "insuranceProtection", _descriptor9, this);

      _initializerDefineProperty(this, "owner", _descriptor10, this);

      _initializerDefineProperty(this, "propertyDamage", _descriptor11, this);

      _initializerDefineProperty(this, "pricePerDay", _descriptor12, this);

      _initializerDefineProperty(this, "totalPrice", _descriptor13, this);

      _initializerDefineProperty(this, "defaultSelection", _descriptor14, this);

      _initializerDefineProperty(this, "delivery", _descriptor15, this);
    }

    get hasDescription() {
      return this.description && this.description.trim() !== '';
    }
    /**
     * Subtext of the package. This field gets localized by the server.
     *
     * @type {string}
     */


    /**
     * These values are used by the admin portal for the pricing interface.
     * They are commented out as they are unused by any app using this addon.
     *
     * dealerOwnedRenter: attr('boolean'),
     * supportedTypes: attr(),
     */
    get deductibleInDollars() {
      return this.deductible / 100;
    }

    get pricePerDayInDollars() {
      return this.pricePerDay / 100;
    }

    get totalPriceInDollars() {
      return this.totalPrice / 100;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hasDescription", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hasDescription"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "subtext", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "note", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tier", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "bundleFeatures", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "offerIndividualServiceTypes", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "deductible", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "insuranceProtection", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "propertyDamage", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "pricePerDay", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "totalPrice", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "defaultSelection", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "delivery", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deductibleInDollars", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "deductibleInDollars"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pricePerDayInDollars", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "pricePerDayInDollars"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalPriceInDollars", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "totalPriceInDollars"), _class.prototype)), _class));
  _exports.default = BookingBundle;
});