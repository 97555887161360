define("@outdoorsyco/form-components/components/animated-textarea-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IqduYCKh",
    "block": "{\"symbols\":[\"@label\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"animated-textarea-field \",[28,\"if\",[[24,[\"hasError\"]],\"error-state has-error\"],null]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"field-control-input\"],[8],[0,\"\\n    \"],[1,[28,\"textarea\",null,[[\"row\",\"autoresize\",\"id\",\"type\",\"value\",\"placeholder\",\"name\",\"class\"],[[24,[\"row\"]],[24,[\"autoresize\"]],[24,[\"formElementId\"]],[24,[\"type\"]],[24,[\"value\"]],[24,[\"placeholder\"]],[24,[\"name\"]],[28,\"concat\",[[28,\"if\",[[24,[\"value\"]],\" has-value\"],null],[28,\"if\",[[24,[\"label\"]],\" has-label\"],null]],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[7,\"label\",true],[11,\"for\",[22,\"formElementId\"]],[10,\"class\",\"field-label\"],[8],[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"hasError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"field-help \",[28,\"if\",[[24,[\"hasError\"]],\"error-state\"],null]]]],[8],[1,[28,\"svg-jar\",[\"warning\"],null],false],[7,\"span\",true],[8],[1,[24,[\"error\",\"message\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"tip\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"field-help \"],[8],[7,\"span\",true],[8],[1,[22,\"tip\"],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/form-components/components/animated-textarea-field/template.hbs"
    }
  });

  _exports.default = _default;
});