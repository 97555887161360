define("@outdoorsyco/ember-shared-data/components/item-tax-rates-list/component", ["exports", "@outdoorsyco/ember-shared-data/components/item-tax-rates-list/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    item: null,
    intl: Ember.inject.service(),
    editable: true,
    queryParams: null,
    actions: {
      addItemNewTaxRate() {
        let newItem = {};
        let rates = this.get('taxRates') || [];

        if (!rates.length) {
          this.set('taxRates', rates);
        }

        rates.pushObject(newItem);
        this.set('currentTaxRate', newItem);
      },

      editItemTaxRate(taxRate) {
        this.set('currentTaxRate', taxRate);
      },

      removeItemTaxRate(taxRate) {
        let intl = this.get('intl');

        if (window.confirm(intl.t('comp-list-tax-rates-confirm-remove'))) {
          let rates = this.get('taxRates') || [];
          rates.removeObject(taxRate);
          this.set('taxRates', rates);
          this.get('item').save();
        }
      }

    }
  });

  _exports.default = _default;
});