define("@outdoorsyco/ember-switchback/components/menu-button/options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Hl5qoiy",
    "block": "{\"symbols\":[\"w\",\"option\",\"&default\",\"@Container\",\"@menuId\",\"&attrs\",\"@options\"],\"statements\":[[6,[23,4,[]],[[12,\"class\",[29,[[28,\"local-class\",[\"options\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/menu-button/options/styles\"]]]]]],[12,\"role\",\"menu\"],[13,6],[3,\"on\",[\"keydown\",[23,0,[\"onKeyDown\"]]]]],[[\"@id\"],[[23,5,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,7,[]]],null,{\"statements\":[[0,\"    \"],[14,3,[[23,2,[]],[23,1,[\"close\"]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"switchback\",\"layout\"]],\"mobile\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"  padding-x-1 margin-top-1 \"],[8],[0,\"\\n      \"],[5,\"button\",[[3,\"on\",[\"click\",[23,1,[\"close\"]]]]],[[\"@width\"],[\"full\"]],{\"statements\":[[0,\"\\n        \"],[1,[23,0,[\"switchback\",\"labelForCancel\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/menu-button/options/template.hbs"
    }
  });

  _exports.default = _default;
});