define("@outdoorsyco/form-components/components/checkbox-field/component", ["exports", "@outdoorsyco/form-components/components/checkbox-field/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    label: '',
    value: false,
    error: null,
    showErrors: false,
    hasError: Ember.computed.and('showErrors', 'error.message'),
    uniqueId: Ember.computed(function () {
      return `${Ember.guidFor(this)}-checkbox`;
    }),
    actions: {
      toggleValue() {
        this.toggleProperty('value');
      }

    }
  });

  _exports.default = _default;
});