define("@outdoorsyco/outdoorsy-addon/components/review-stars/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5j/OLmDu",
    "block": "{\"symbols\":[\"star\"],\"statements\":[[4,\"if\",[[24,[\"hasReviews\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"title\",[28,\"t\",[\"comp-review-stars-number-of-stars\"],[[\"score\",\"max\",\"count\"],[[24,[\"score\"]],[24,[\"max\"]],[24,[\"count\"]]]]]],[10,\"data-test-review-stars-display\",\"\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"stars\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"svg-jar\",[[23,1,[]]],[[\"class\"],[[28,\"concat\",[[23,1,[]],\"-icon \",[24,[\"size\"]],\" review-star\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"no-reviews\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"comp-review-stars-no-reviews\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/review-stars/template.hbs"
    }
  });

  _exports.default = _default;
});