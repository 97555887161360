define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/mobile-menu/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/mobile-menu/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'ul',
    classNames: ['mobile-menu', 'nav', 'navbar-nav'],
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    conversations: Ember.inject.service(),
    messagesCount: Ember.computed.alias('conversations.messagesCount'),
    domain: '',
    config: null,
    loggedInMenu: Ember.computed('session.user.owner', function () {
      return [{
        title: this.session.isAuthenticated && this.session.user.owner ? 'Owner dashboard' : 'Dashboard',
        nested: [{
          href: 'dashboard/bookings',
          title: 'Requests & Bookings'
        }, {
          href: 'dashboard/trips',
          title: 'My trips'
        }, {
          href: 'dashboard/calendar',
          title: 'Calendar'
        }, {
          href: 'dashboard/bookings/new',
          title: 'Send a quote'
        }, {
          href: 'dashboard/instamatch',
          title: 'INSTAmatch'
        }, {
          href: 'dashboard/rentals',
          title: 'Manage Listings'
        }, {
          href: 'dashboard/documents',
          title: 'Documents'
        }]
      }, {
        href: 'referral',
        title: 'Account Credit',
        app: 'credit'
      }, {
        href: 'rv-search',
        title: 'Rent an RV'
      }, {
        href: 'dashboard/favorites',
        title: 'My Favorites'
      }, {
        title: 'Account Settings',
        nested: [{
          href: 'dashboard/profile',
          title: 'Edit Profile'
        }, {
          href: 'dashboard/account/password',
          title: 'Change Password'
        }, {
          href: 'dashboard/account/payout',
          title: 'Payout Preferences'
        }]
      }];
    }),

    click(el) {
      let routerService = this.get('router');

      if (!routerService || !el.target.attributes.href) {
        return;
      } // close when the user clicks on the current route


      let targetUrl = el.target.attributes.href.nodeValue;
      let rootURL = routerService.get('rootURL');
      let routeName = routerService.get('currentURL');

      if (targetUrl === `${rootURL}${routeName}`) {
        this.send('close');
      }
    },

    init() {
      this._super(...arguments);

      this.config = Ember.getOwner(this).resolveRegistration('config:environment'); // dashboard does not have fastboot so conditionally bring it in

      this.set('fastboot', Ember.getOwner(this).lookup('service:fastboot'));
      let host = this.get('fastboot.request.host') || window.location.host;
      let protocol = this.config.environment === 'development' ? 'http://' : 'https://';
      this.set('domain', `${protocol}${host}`);
      this.loggedInMenu = this.loggedOutMenu = [{
        href: 'rv-search',
        title: 'Rent an RV'
      }];
    },

    actions: {
      login() {
        this.get('login')('login');
      },

      register() {
        this.get('register')('register');
      },

      logout() {
        this.get('logout')();
      },

      close() {
        this.get('close')();
        return true;
      },

      back() {
        this.set('openNestedMenu', null);
      },

      revealNestedMenu(element) {
        this.set('openNestedMenu', element);
      }

    }
  });

  _exports.default = _default;
});