define("ember-animated/templates/components/animated-each", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S+fZJQEw",
    "block": "{\"symbols\":[\"child\",\"&inverse\",\"&default\"],\"statements\":[[4,\"each\",[[23,0,[\"renderedChildren\"]]],[[\"key\"],[\"id\"]],{\"statements\":[[0,\"  \"],[5,\"ea-list-element\",[],[[\"@child\",\"@elementToChild\"],[[23,1,[]],[23,0,[\"_elementToChild\"]]]],{\"statements\":[[14,3,[[23,1,[\"value\"]],[23,1,[\"index\"]]]]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[14,2]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-each.hbs"
    }
  });

  _exports.default = _default;
});