define('ember-mobile-core/tween', ['exports', 'ember-mobile-core/easings'], function (exports, _easings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Tween = function () {
    function Tween() {
      var callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      _classCallCheck(this, Tween);

      this.running = false;

      this.callback = callback;
      this.duration = opts.duration || 250;
      this.ease = _easings.default[opts.ease] || _easings.default['easeOutCubic'];
    }

    _createClass(Tween, [{
      key: 'start',
      value: function start() {
        var _this = this;

        this.promise = null;
        this.running = true;

        return new Promise(function (resolve, reject) {
          _this.promise = { resolve: resolve, reject: reject };
          window.requestAnimationFrame(_this._step.bind(_this));
        });
      }
    }, {
      key: 'stop',
      value: function stop() {
        this.running = false;
      }
    }, {
      key: '_step',
      value: function _step(timestamp) {
        if (this.running) {
          if (!this.startTS) {
            this.startTS = timestamp;
          }

          this.progress = (timestamp - this.startTS) / this.duration;
          var currentValue = this.ease(this.progress);

          if (this.progress < 1) {
            this.callback(currentValue);
            window.requestAnimationFrame(this._step.bind(this));
          } else {
            this.callback(1);
            this.promise.resolve();
          }
        }
      }
    }]);

    return Tween;
  }();

  exports.default = Tween;
});