define("@outdoorsyco/form-components/components/input-field/component", ["exports", "@outdoorsyco/form-components/components/input-field/template", "@outdoorsyco/form-components/components/animated-input-field/template"], function (_exports, _template, _template2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disabled: false,
    showErrors: false,
    autocomplete: null,
    // internal variables
    formElementId: null,
    type: 'text',
    hasError: Ember.computed.and('showErrors', 'error.message'),

    init() {
      this._super(...arguments);

      this.set('formElementId', `id-${this.get('elementId')}`);

      if (this.get('new')) {
        this.set('layout', _template2.default);
      } else {
        this.set('layout', _template.default);
      }
    },

    actions: {
      changed() {
        if (this.get('onChange')) {
          this.get('onChange')(this.get('value'));
        }
      }

    }
  });

  _exports.default = _default;
});