define("@outdoorsyco/outdoorsy-addon/serializers/page", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;

  var _default = JSONAPISerializer.extend({
    keyForAttribute(key) {
      return Ember.String.underscore(key);
    },

    keyForRelationship(key) {
      return Ember.String.underscore(key);
    }

  });

  _exports.default = _default;
});