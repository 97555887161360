define("@outdoorsyco/ember-shared-data/components/spreedly/credit-card-form/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray100": "hsl(220, 20%, 97%)",
    "gray200": "hsl(204, 14%, 89%)",
    "gray600": "hsl(210, 10%, 58%)",
    "gray800": "hsl(208, 8%, 36%)",
    "gray900": "hsl(207, 6%, 23%)",
    "red400": "hsl(358, 86%, 64%)",
    "raisedShadow100": "0 1px 1px 0 hsla(0, 0%, 0%, 0.1), 0 1px 1px 0 hsla(0, 0%, 0%, 0.06)",
    "focusedShadow100": "0px 0px 0px 2px color-mod(hsl(172, 50%, 28%) a(20%))",
    "erroredShadow100": "0px 0px 0px 2px color-mod(hsl(358, 80%, 58%) a(20%))",
    "SpreedlyCreditCardFormFakeInput": "_SpreedlyCreditCardFormFakeInput_mvkwwm"
  };
  _exports.default = _default;
});