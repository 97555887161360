define("@outdoorsyco/ember-shared-data/models/search/image", ["exports", "@outdoorsyco/ember-shared-data/models/image"], function (_exports, _image) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SearchImage extends _image.default {}

  _exports.default = SearchImage;
});