define("@outdoorsyco/outdoorsy-addon/components/card/user/contact/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserContactComponent extends _component.default {
    constructor() {
      super(...arguments);
      (false && !(this.args.user) && Ember.assert('You must provide a user argument to Card::User', this.args.user));
    }

    get phoneNumber() {
      const user = this.args.user;
      const {
        isOwner,
        canSeeContract
      } = this.args;
      const businessPhone = Ember.get(user, 'businessPhone');
      const phone = Ember.get(user, 'phone');

      if (isOwner) {
        if (!Ember.isEmpty(businessPhone)) {
          return businessPhone;
        }

        return canSeeContract ? phone : null;
      }

      return !Ember.isEmpty(phone) ? phone : null;
    }

    get messageName() {
      const user = this.args.user;
      const isOwner = this.args.isOwner;
      const businessName = Ember.get(user, 'businessName');
      const name = Ember.get(user, 'firstName');
      return isOwner ? !Ember.isEmpty(businessName) ? businessName : name : name;
    }

  }

  _exports.default = UserContactComponent;
});