define('ember-mobile-pane/components/mobile-pane/next', ['exports', 'ember-mobile-pane/templates/components/mobile-pane/next'], function (exports, _next) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _next.default,

    tagName: '',

    // public

    // protected
    paneCount: 0,
    activeIndex: 0,

    onClick: function onClick() {},


    isDisabled: Ember.computed('activeIndex', 'paneCount', function () {
      return Ember.get(this, 'activeIndex') >= Math.floor(Ember.get(this, 'paneCount') - 1);
    }),

    actions: {
      onClick: function onClick(activeIndex, evt) {
        evt.stopPropagation();
        if (Ember.get(this, 'isDisabled')) {
          return;
        }

        Ember.get(this, 'onClick')(activeIndex);
        return false;
      }
    }
  });
});