define("@outdoorsyco/ember-shared-data/serializers/bundle-rule", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BundleRuleSerializer extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        bundle: {
          key: 'bundle_id'
        }
      });
    }

    normalize(typeClass, hash) {
      hash.rule = hash.rule ? hash.rule : {};
      hash.vehicle_types = hash.rule.vehicle_types;

      if (hash.type === 'pricing') {
        hash.rate = hash.rule.rate;
        hash.charge = hash.rule.charge;
        hash.deductible = hash.rule.deductible;
        hash.min_length = hash.rule.min_length;
        hash.max_length = hash.rule.max_length;
        hash.min_value = hash.rule.min_value;
        hash.max_value = hash.rule.max_value;
      } else if (hash.type === 'eligibility') {
        hash.regions = hash.rule.regions;
        hash.exclude_vehicle_types = hash.rule.exclude_types;
      }

      return super.normalize(typeClass, hash);
    }

    serialize(record, options) {
      options = options ? options : {}; // handle the case where options is undefined

      options.includeId = true;
      let hash = super.serialize(record, options);

      if (hash.vehicle_types.length) {
        if (hash.vehicle_types[0].indexOf(',') > 0) {
          hash.vehicle_types = hash.vehicle_types[0].split(',');
        }
      }

      if (hash.exclude_vehicle_types.length) {
        if (hash.exclude_vehicle_types[0].indexOf(',') > 0) {
          hash.exclude_vehicle_types = hash.exclude_vehicle_types[0].split(',');
        }
      }

      if (hash.regions.length) {
        if (hash.regions[0].indexOf(',') > 0) {
          hash.regions = hash.regions[0].split(',');
        }
      }

      if (hash.type === 'pricing') {
        hash.rule = {
          rate: parseInt(hash.rate),
          charge: parseInt(hash.charge),
          deductible: parseInt(hash.deductible),
          min_length: hash.min_length,
          max_length: hash.max_length,
          min_value: parseInt(hash.min_value),
          max_value: parseInt(hash.max_value),
          vehicle_types: hash.vehicle_types
        };
      } else if (hash.type === 'eligibility') {
        hash.rule = {
          vehicle_types: hash.vehicle_types,
          exclude_types: hash.exclude_vehicle_types,
          regions: hash.regions
        };
      }

      delete hash.rate;
      delete hash.charge;
      delete hash.deductible;
      delete hash.min_length;
      delete hash.max_length;
      delete hash.min_value;
      delete hash.max_value;
      delete hash.vehicle_types;
      delete hash.exclude_types;
      delete hash.regions;
      return hash;
    }

  }

  _exports.default = BundleRuleSerializer;
});