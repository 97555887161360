define("@outdoorsyco/outdoorsy-addon/components/od-spinner/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/od-spinner/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    size: 'small',
    style: 'primary',
    color: 'secondary',
    componentClass: Ember.computed('color', 'size', 'style', function () {
      return `od-spinner od-spinner--color-${this.get('color')} od-spinner--style-${this.get('style')} od-spinner--size-${this.get('size')}`;
    })
  });

  _exports.default = _default;
});