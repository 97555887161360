define("@outdoorsyco/ember-shared-data/services/outdoorsy-intl", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OutdoorsyIntlService = (_dec = Ember.inject.service, (_class = class OutdoorsyIntlService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "assetMapOverride", void 0);
    }

    get assetMap() {
      return this.assetMapOverride || Ember.getOwner(this).lookup('service:asset-map');
    }

    set assetMap(value) {
      this.assetMapOverride = value;
    }
    /**
     * We are not injecting this service directly because we don't want to
     * force every app that uses this addon to install power-calendar.
     */


    get calendarService() {
      return Ember.getOwner(this).lookup('service:power-calendar');
    }
    /**
     * Asynchronously loads the translation file for the given locale
     * and sets the locale via ember-intl
     */


    async setLocale(locale) {
      // Try to fetch the translations file, unless we already have it
      if (!this.intl.locales.includes(locale)) {
        await this._tryFetchingTranslations(locale);
      } //setup calendar locale


      let calendarLocale = locale.split('-')[0];

      if (this.calendarService) {
        this.calendarService.set('locale', calendarLocale);
      }

      return this.intl.setLocale(locale);
    }

    async _tryFetchingTranslations(locale) {
      try {
        const path = this.assetMap.resolve(`translations/${locale}.json`);
        const translations = await (0, _fetch.default)(path);
        const translationsJSON = await translations.json();
        this.intl.addTranslations(locale, translationsJSON);
      } catch (error) {
        console.error(`Error fetching ${locale} translation:`);
        console.error(error);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OutdoorsyIntlService;
});