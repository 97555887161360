define("ember-mobile-pane/templates/components/mobile-pane/scroller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bovr96O0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"mobile-pane__scroller\"],[11,\"style\",[22,\"style\"]],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"pane\"],[[28,\"component\",[\"mobile-pane/pane\"],[[\"lazyRendering\",\"keepRendered\",\"activePane\",\"paneCount\",\"visiblePanes\"],[[24,[\"lazyRendering\"]],[24,[\"keepRendered\"]],[24,[\"activePane\"]],[24,[\"paneCount\"]],[24,[\"visiblePanes\"]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mobile-pane/templates/components/mobile-pane/scroller.hbs"
    }
  });

  _exports.default = _default;
});