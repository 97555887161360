define("@outdoorsyco/outdoorsy-addon/helpers/background-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.backgroundImage = backgroundImage;
  _exports.default = void 0;

  function backgroundImage(params, {
    url
  }) {
    if (!url) {
      return null;
    } // TODO: sanitize the url first


    return Ember.String.htmlSafe(`background-image: url(${url})`);
  }

  var _default = Ember.Helper.helper(backgroundImage);

  _exports.default = _default;
});