define("@outdoorsyco/outdoorsy-addon/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    mobile: '(max-width: 767px)',
    tablet: '(min-width: 768px) and (max-width: 1100px)',
    desktop: '(min-width: 1101px)',
    // Matches names and sizing from switchback
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)'
  };
  _exports.default = _default;
});