define("@outdoorsyco/ember-shared-data/components/input/country/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const IMPORTANT_COUNTRIES = ['US', 'CA', 'GB', 'AU', 'NZ'];
  let InputCountryComponent = (_dec = Ember.inject.service, _dec2 = (0, _emberConcurrencyDecorators.lastValue)('getCountryOptions'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class InputCountryComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "apiRequest", _descriptor, this);

      _initializerDefineProperty(this, "countryOptions", _descriptor2, this);

      _initializerDefineProperty(this, "getCountryOptions", _descriptor3, this);
    }

    get importantCountries() {
      const countryOptions = this.countryOptions;
      return countryOptions.length ? IMPORTANT_COUNTRIES.map(importantCountryCode => {
        // make sure to use the same object references, so ember-power-select doesn't duplicate
        return countryOptions.find(country => country.code === importantCountryCode);
      }).concat({
        code: '',
        name: '',
        disabled: true
      }) : [];
    }

    get expandedOptions() {
      return [...this.importantCountries, ...this.countryOptions];
    }

    get filteredExpandedOptions() {
      return this.expandedOptions && this.expandedOptions.filter(country => {
        const supports = this.args.filters && this.args.filters.supports;

        if (!supports || !country.supports) {
          return true;
        }

        return country.supports.includes(supports);
      });
    }

    search(term) {
      // Only include countries from countryOptions, not expandedOptions
      // Otherwise, countries included in importantCountries will be duplicated in results
      return this.countryOptions.filter(country => {
        return country.name.toLowerCase().includes(term.toLowerCase());
      });
    }

    onChange(value) {
      this.args.onChange(value.code);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "countryOptions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "getCountryOptions", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        let options = yield this.apiRequest.request(`static/countries`);
        return options.sort((a, b) => {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype)), _class));
  _exports.default = InputCountryComponent;
});