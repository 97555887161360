define("@outdoorsyco/ember-shared-data/models/driver", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  let Driver = (_dec = attr('string'), _dec2 = attr('string'), _dec3 = attr('string'), _dec4 = attr('simpleDate'), _dec5 = attr('boolean'), _dec6 = attr('number'), _dec7 = attr('boolean'), _dec8 = attr('string'), _dec9 = attr('boolean'), _dec10 = attr('string'), _dec11 = attr('number'), _dec12 = attr('string'), _dec13 = attr('boolean'), _dec14 = attr('boolean'), _dec15 = attr('string'), _dec16 = attr('string'), _dec17 = attr('string'), _dec18 = attr('', {
    defaultValue() {
      return [];
    }

  }), _dec19 = Ember.computed('firstName', 'lastName'), _dec20 = Ember.computed('checks.[]'), _dec21 = Ember.computed('checks.[]'), _dec22 = Ember.computed('checks.[]'), _dec23 = Ember.computed('checks.[]'), _dec24 = Ember.computed('checks.[]'), _dec25 = Ember.computed('checks.[]'), (_class = class Driver extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "firstName", _descriptor, this);

      _initializerDefineProperty(this, "lastName", _descriptor2, this);

      _initializerDefineProperty(this, "email", _descriptor3, this);

      _initializerDefineProperty(this, "birthdate", _descriptor4, this);

      _initializerDefineProperty(this, "verified", _descriptor5, this);

      _initializerDefineProperty(this, "ownedById", _descriptor6, this);

      _initializerDefineProperty(this, "hasDrivingRecordCheck", _descriptor7, this);

      _initializerDefineProperty(this, "driverVerificationStatus", _descriptor8, this);

      _initializerDefineProperty(this, "ageVerified", _descriptor9, this);

      _initializerDefineProperty(this, "validationStatus", _descriptor10, this);

      _initializerDefineProperty(this, "verifiedChecksCount", _descriptor11, this);

      _initializerDefineProperty(this, "inviteStatus", _descriptor12, this);

      _initializerDefineProperty(this, "legacyDriver", _descriptor13, this);

      _initializerDefineProperty(this, "namedPassenger", _descriptor14, this);

      _initializerDefineProperty(this, "token", _descriptor15, this);

      _initializerDefineProperty(this, "role", _descriptor16, this);

      _initializerDefineProperty(this, "country", _descriptor17, this);

      _initializerDefineProperty(this, "checks", _descriptor18, this);
    }

    get fullName() {
      return `${this.firstName} ${this.lastName}`;
    }

    get hasRiskCheck() {
      return this.hasTintCheck() || this.hasRoamlyRiskCheck();
    }

    get hasTintCheck() {
      let checks = this.get('checks') || [];
      return checks.filterBy('method', 'tint');
    }

    get hasRoamlyRiskCheck() {
      let checks = this.get('checks') || [];
      return checks.filterBy('method', 'roamly_risk');
    }

    get hasEvidentCheck() {
      let checks = this.get('checks') || [];
      return checks.filterBy('method', 'evident');
    }

    get hasPersonaCheck() {
      let checks = this.get('checks') || [];
      return checks.filterBy('method', 'persona');
    }

    get canSwitchIDVerify() {
      let check = Ember.get(this, 'hasEvidentCheck.firstObject');

      if (check && check.status !== 'passed') {
        return true;
      }

      return false;
    }

    get otherIdVerify() {
      if (Ember.get(this, 'hasEvidentCheck.length')) {
        return 'persona';
      }

      if (Ember.get(this, 'hasPersonaCheck.length')) {
        return 'evident';
      }

      return null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "firstName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "birthdate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "verified", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "ownedById", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasDrivingRecordCheck", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "driverVerificationStatus", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "ageVerified", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "validationStatus", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "verifiedChecksCount", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "inviteStatus", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "legacyDriver", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "namedPassenger", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "token", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "role", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "checks", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fullName", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "fullName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasTintCheck", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "hasTintCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasRoamlyRiskCheck", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "hasRoamlyRiskCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasEvidentCheck", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "hasEvidentCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasPersonaCheck", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "hasPersonaCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canSwitchIDVerify", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "canSwitchIDVerify"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "otherIdVerify", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "otherIdVerify"), _class.prototype)), _class));
  _exports.default = Driver;
});