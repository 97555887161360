define("@outdoorsyco/ember-switchback/components/text/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays a piece of inline text.
   *
   * ```handlebars
   * <Text>Inline text</Text>
   * ```
   * @public
   */
  class Text extends _component.default {}

  _exports.default = Text;
});