define("@outdoorsyco/ember-shared-data/services/current-user", ["exports", "ember-data", "ember-concurrency-decorators", "@outdoorsyco/ember-shared-data/utils/is-error-status", "@sentry/browser"], function (_exports, _emberData, _emberConcurrencyDecorators, _isErrorStatus, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    PromiseObject
  } = _emberData.default;
  /**
   * The new way of handling the fetching and getting of the session's current user.
   * This will probably end up being merged into the session service,
   * but for now, we're keeping the session service separate, while we refactor its logic
   */

  let CurrentUserService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = class CurrentUserService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "environment", _descriptor3, this);

      _initializerDefineProperty(this, "user", _descriptor4, this);

      _initializerDefineProperty(this, "userPromise", _descriptor5, this);

      _initializerDefineProperty(this, "getUser", _descriptor6, this);
    }

    get logger() {
      return Ember.getOwner(this).lookup('logger:outdoorsy');
    }

    get currentUser() {
      // TODO: change to assert when we think all uses of session.currentUser are removed
      (false && Ember.warn('Calling session.currentUser is deprecated. ' + 'Instead, call session.user after session.userPromise has resolved. ' + '(session.user is a resolved user object, not a promise)', false, {
        id: 'outdoorsy.session-current-user'
      }));

      if (!this.userPromise) {
        const message = 'tried to get session.currentUser before loadUser was called';
        this.logger.error(new Error(message));
        return this.loadUser();
      }

      return this.userPromise;
    }

    async loadUser() {
      // support deprecated access of current user as PromiseObject via session.currentUser
      // TODO: once we no longer do that anywhere, change this to `return this.userPromise = this.getUser.perform()`
      this.userPromise = PromiseObject.create({
        promise: new Ember.RSVP.Promise((resolve, reject) => {
          this.getUser.perform().then(user => {
            resolve(user);
          }).catch(error => {
            reject(error);
          });
        })
      });
      return this.userPromise;
    }

    setSentryUser(user) {
      if (Ember.get(window, 'Outdoorsy.metrics.getIdentifier')) {
        let {
          sid,
          did
        } = window.Outdoorsy.metrics.getIdentifier();
        (0, _browser.configureScope)(scope => {
          scope.setUser({
            session_id: sid,
            device_id: did,
            email: user.email,
            id: user.id
          });
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userPromise", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "getUser", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        // Don't try and load the user durning SSR (Server side rendering)
        if (this.environment.isFastBoot) {
          return;
        } // https://github.com/simplabs/ember-simple-auth/blob/master/guides/managing-current-user.md#loading-the-user-with-its-id


        try {
          const userId = Ember.get(this, 'session.data.authenticated.user_id');

          if (!Ember.isEmpty(userId)) {
            // reload: true, because we need to wait for the new response from server
            const user = yield this.store.findRecord('user', userId, {
              reload: true
            });
            this.user = user;
            this.setSentryUser(user);
            return user;
          } else {
            return Ember.RSVP.resolve();
          }
        } catch (error) {
          if ((0, _isErrorStatus.isForbiddenError)(error)) {
            this.session.invalidate();
          } else {
            this.logger.error(error);
          }

          throw error;
        }
      };
    }
  })), _class));
  _exports.default = CurrentUserService;
});