define("@outdoorsyco/ember-shared-data/validator/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.phone = phone;
  _exports.default = void 0;

  function phone(value, phone) {
    const validatorIsDisabled = !phone;
    const noValueToValidate = Ember.isEmpty(value);
    const phoneRegex = /^ *(\+?\d{1,3}( |-)*)?(\(\d{1,3}\)( |-)*)?((\d+( |-)+)*\d+)( *x(\d+))? *$/;
    const validValue = phoneRegex.test(value);
    return validatorIsDisabled || noValueToValidate || validValue;
  }

  var _default = {
    param: 'phone',
    error: {
      key: 'error.validator.phone',
      message: 'This must be a valid phone number'
    },
    validate: phone
  };
  _exports.default = _default;
});