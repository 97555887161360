define("@outdoorsyco/ember-shared-data/components/upload-file/component", ["exports", "ember-concurrency", "@outdoorsyco/ember-shared-data/components/upload-file/template"], function (_exports, _emberConcurrency, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['file-uploader'],
    fileUpload: Ember.inject.service(),
    uploadPath: '',
    acceptType: '',
    // like image/*
    dragDropText: 'Drag and drop photos here or',
    buttonText: 'Click here to upload',

    init() {
      this._super(...arguments);

      if (!this.displayFiles) {
        this.set('displayFiles', []); // [ { type: 'img', url: url } ]
      }
    },

    appConfig() {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    },

    uploadFile: (0, _emberConcurrency.task)(function* (file, path) {
      try {
        let {
          url,
          options
        } = yield this.fileUpload.getUploadParamsTask.perform(path);
        let data = yield file.upload(url, options);

        if (this.fileUploaded) {
          this.fileUploaded(data);
        }
      } catch (e) {
        console.error(e);
      }
    }).maxConcurrency(3).enqueue(),
    actions: {
      uploadFile(file) {
        let path = this.get('uploadPath');
        this.get('uploadFile').perform(file, path);
      },

      removeFile(file) {
        if (this.get('removeFile')) {
          this.get('removeFile')(file);
        }
      }

    }
  });

  _exports.default = _default;
});