define("@outdoorsyco/ember-switchback/styles/shadow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "red500": "hsl(358, 80%, 58%)",
    "green800": "hsl(172, 50%, 28%)",
    "insetShadow100": "inset 0px 1px 2px hsla(0, 0, 0, 0.1)",
    "raisedShadow100": "0 1px 1px 0 hsla(0, 0%, 0%, 0.1), 0 1px 1px 0 hsla(0, 0%, 0%, 0.06)",
    "raisedShadow200": "0 1px 3px 0 hsla(0, 0%, 0%, 0.1), 0 1px 2px 0 hsla(0, 0%, 0%, 0.06)",
    "raisedShadow300": "0 4px 6px -1px hsla(0, 0%, 0%, 0.1), 0 2px 4px -1px hsla(0, 0%, 0%, 0.06)",
    "raisedShadow400": "0 10px 15px -3px hsla(0, 0%, 0%, 0.1), 0 4px 6px -2px hsla(0, 0%, 0%, 0.05)",
    "raisedShadow500": "0 20px 25px -5px hsla(0, 0%, 0%, 0.1), 0 10px 10px -5px hsla(0, 0%, 0%, 0.04)",
    "raisedShadow600": "0 24px 64px 0 hsla(0, 0%, 0%, 0.15)",
    "raisedGray100": "0 1px 1px 0 hsla(207, 6%, 23%, 0.1)",
    "raisedGray200": "0 4px 9px -2px  hsla(207, 6%, 23%, 0.1), 0 1px 2px -1px hsla(207, 6%, 23%, 0.06)",
    "raisedGray300": "0 5px 9px -1px  hsla(207, 6%, 23%, 0.1), 0 2px 4px -1px hsla(207, 6%, 23%, 0.06)",
    "raisedGray400": "0 10px 15px -3px hsla(207, 6%, 23%, 0.1), 0 4px 6px -2px hsla(207, 6%, 23%, 0.05)",
    "raisedGray500": "0 15px 25px -5px hsla(207, 6%, 23%, 0.1), 0 10px 10px -5px hsla(207, 6%, 23%, 0.04)",
    "raisedGray600": "0 24px 64px 0 hsla(0, 0%, 0%, 0.15)",
    "negativeGray500": "0 -15px 25px -5px hsla(207, 6%, 23%, 0.1), 0 -10px 10px -5px hsla(207, 6%, 23%, 0.04)",
    "focusedShadow100": "0px 0px 0px 2px color-mod(hsl(172, 50%, 28%) a(20%))",
    "erroredShadow100": "0px 0px 0px 2px color-mod(hsl(358, 80%, 58%) a(20%))"
  };
  _exports.default = _default;
});