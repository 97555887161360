define("@outdoorsyco/ember-nydus/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    // we underscore all the things
    keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    }

  });

  _exports.default = _default;
});