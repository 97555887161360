define("@outdoorsyco/ember-switchback/components/popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GA6Irx4g",
    "block": "{\"symbols\":[\"@strategy\",\"@placement\",\"@relativeTo\",\"@id\",\"&attrs\",\"@trapFocus\",\"@enableArrow\",\"&default\",\"@show\"],\"statements\":[[4,\"if\",[[23,9,[]]],null,{\"statements\":[[4,\"in-element\",[[23,0,[\"popupElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Popup\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/popup/styles\"]]]]]],[3,\"tether-to\",[[23,3,[]]],[[\"placement\",\"strategy\",\"modifiers\"],[[28,\"or\",[[23,2,[]],\"auto\"],null],[28,\"or\",[[23,1,[]],\"absolute\"],null],[23,0,[\"modifiers\"]]]]],[8],[0,\"\\n      \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"content\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/popup/styles\"]]]]]],[12,\"id\",[23,4,[]]],[12,\"data-animate\",[28,\"if\",[[23,0,[\"dismissTask\",\"isRunning\"]],\"out\",\"in\"],null]],[13,5],[3,\"on\",[\"keydown\",[23,0,[\"onKeyDown\"]]]],[3,\"trap-focus\",null,[[\"disabled\"],[[28,\"eq\",[[23,6,[]],false],null]]]],[3,\"on-click-outside\",[[28,\"perform\",[[23,0,[\"dismissTask\"]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[23,7,[]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"arrow-wrapper\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/popup/styles\"]]]]]],[11,\"id\",[23,0,[\"arrowId\"]]],[8],[0,\"\\n            \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"arrow\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/popup/styles\"]]]]]],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[14,8,[[28,\"hash\",null,[[\"close\"],[[23,0,[\"dismiss\"]]]]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/popup/template.hbs"
    }
  });

  _exports.default = _default;
});