define("@outdoorsyco/ember-shared-data/components/add-driver/component", ["exports", "@outdoorsyco/ember-shared-data/components/add-driver/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    user: null,
    showDialog: false,
    actions: {
      identityCheckComplete() {
        let user = this.get('user');

        if (user && user.reload) {
          user.reload();
          this.send('close');
        } else {
          user.then(u => {
            u.reload();
            this.send('close');
          });
        }
      },

      close() {
        if (this.get('close')) {
          this.get('close')();
        }
      }

    }
  });

  _exports.default = _default;
});