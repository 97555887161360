define("@outdoorsyco/ember-nydus/models/nydus-booking", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    user: belongsTo('nydus-user', {
      async: false
    }),
    vehicle: belongsTo('nydus-rental', {
      async: false
    }),
    integrationId: attr('string'),
    provider: attr('string'),
    total: attr('centsToDollars'),
    cancelled: attr('boolean', {
      defaultValue: false
    }),
    status: attr('string'),
    range: attr()
  });

  _exports.default = _default;
});