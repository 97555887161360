define("@outdoorsyco/ember-shared-data/helpers/div", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.div = div;
  _exports.default = void 0;

  /**
   * Divides two or more numbers. Copied from ember-math-helpers
   *
   * ```hbs
   * {{div a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to divide
   * @return {number} The result of dividing all the passed numbers
   */
  function div(numbers) {
    return numbers.reduce((a, b) => Number(a) / Number(b));
  }

  var _default = Ember.Helper.helper(div);

  _exports.default = _default;
});