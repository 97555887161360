define("@outdoorsyco/form-components/components/textarea-field/component", ["exports", "@outdoorsyco/form-components/components/textarea-field/template", "@outdoorsyco/form-components/components/animated-textarea-field/template"], function (_exports, _template, _template2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showErrors: false,
    autoresize: true,
    //internal variables
    formElementId: null,
    type: 'text',
    hasError: Ember.computed.and('showErrors', 'error.message'),

    init() {
      this._super(...arguments);

      this.set('formElementId', `id-${this.get('elementId')}`);

      if (this.get('new')) {
        this.set('layout', _template2.default);
      } else {
        this.set('layout', _template.default);
      }
    }

  });

  _exports.default = _default;
});