define("@outdoorsyco/ember-switchback/components/help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EvubQdMx",
    "block": "{\"symbols\":[\"@size\",\"&attrs\",\"@placement\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Help\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/help/styles\"]]]]]],[12,\"id\",[23,0,[\"triggerId\"]]],[12,\"role\",\"button\"],[12,\"tabindex\",\"0\"],[12,\"data-size\",[28,\"or\",[[23,1,[]],\"medium\"],null]],[12,\"aria-haspopup\",\"dialog\"],[12,\"aria-expanded\",[28,\"to-string\",[[23,0,[\"show\"]]],null]],[12,\"aria-controls\",[23,0,[\"popupId\"]]],[12,\"aria-label\",\"?\"],[13,2],[3,\"on\",[\"click\",[28,\"fn\",[[28,\"mut\",[[23,0,[\"show\"]]],null],true],null]]],[3,\"on\",[\"keydown\",[23,0,[\"onKeyDown\"]]]],[8],[0,\"\\n\\n  \"],[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"viewBox\",\"0 0 140 140\"],[8],[0,\"\\n    \"],[7,\"g\",true],[10,\"fill\",\"none\"],[10,\"stroke\",\"currentColor\"],[10,\"stroke-width\",\"12\"],[8],[0,\"\\n      \"],[7,\"path\",true],[10,\"stroke-linecap\",\"round\"],[10,\"stroke-linejoin\",\"round\"],[10,\"d\",\"M52.5 52.5a17.5 17.5 0 1123.33333333 16.5025A8.75 8.75 0 0070 77.25666667V83.125M70 100.625a2.1875 2.1875 0 102.1875 2.1875A2.1875 2.1875 0 0070 100.625h0\"],[8],[9],[0,\"\\n      \"],[7,\"path\",true],[10,\"stroke-miterlimit\",\"10\"],[10,\"d\",\"M4.375 70a65.625 65.625 0 10131.25 0 65.625 65.625 0 10-131.25 0z\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[5,\"popup\",[[12,\"role\",\"dialog\"],[12,\"aria-live\",\"polite\"]],[[\"@id\",\"@show\",\"@relativeTo\",\"@placement\",\"@enableArrow\",\"@trapFocus\",\"@onDismiss\"],[[23,0,[\"popupId\"]],[23,0,[\"show\"]],[28,\"concat\",[\"#\",[23,0,[\"triggerId\"]]],null],[28,\"or\",[[23,3,[]],\"top\"],null],true,false,[28,\"fn\",[[28,\"mut\",[[23,0,[\"show\"]]],null],false],null]]],{\"statements\":[[0,\"\\n  \"],[14,4],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/help/template.hbs"
    }
  });

  _exports.default = _default;
});