define("@outdoorsyco/outdoorsy-addon/components/od-avatar/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/od-avatar/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    user: null,
    size: 'md',
    name: null,
    isLoaded: true,
    image: Ember.computed('user.avatarImage', 'isLoaded', function () {
      if (!this.get('isLoaded')) {
        return;
      }

      let image = this.get('user.avatarImage');

      if (!image) {
        return image;
      }

      return image.replace('http://', 'https://');
    }),
    displayName: Ember.computed('user', 'user.name', function () {
      if (Ember.isPresent(this.get('name'))) {
        return this.get('name');
      }

      return this.get('user.name');
    }),
    _avatar: Ember.computed('size', function () {
      return this._setSize(this.get('size'));
    }),

    _setSize(size) {
      let ret;

      switch (size) {
        case 'lg':
          ret = {
            size: 175,
            klass: 'avatar-lg'
          };
          break;

        case 'md':
          ret = {
            size: 120,
            klass: 'avatar-md'
          };
          break;

        case 'sm':
          ret = {
            size: 75,
            klass: 'avatar-sm'
          };
          break;

        case 's':
          ret = {
            size: 56,
            klass: 'avatar-s'
          };
          break;

        case 'xs':
          ret = {
            size: 50,
            klass: 'avatar-xs'
          };
          break;

        case 'menu':
          ret = {
            size: 32,
            klass: 'avatar-menu'
          };
          break;
      }

      return ret;
    }

  });

  _exports.default = _default;
});