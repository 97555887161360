define("@outdoorsyco/outdoorsy-addon/components/cookie-provider/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/cookie-provider/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COOKIEAGE = 365 * 24 * 60 * 60;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    cookies: Ember.inject.service(),
    displayCount: 0,
    cookieName: null,
    maxDisplayCount: 3,
    lastDismissed: null,
    shouldDisplay: false,
    timeoutSeconds: 604800,

    init() {
      this._super(...arguments);

      this.get('_setupCookie').perform();
    },

    getCookie() {
      const rawCookie = this.get('cookies').read(this.get('cookieName'));

      try {
        const parsedCookie = JSON.parse(rawCookie);
        return parsedCookie;
      } catch (e) {
        return null;
      }
    },

    _setupCookie: (0, _emberConcurrency.task)(function* () {
      const cookie = this.getCookie();

      if (!cookie) {
        yield this.get('_writeInitialCookie').perform();
        this.set('shouldDisplay', true);
        return;
      }

      if (!cookie.lastDismissed) {
        this.set('shouldDisplay', true);
        return;
      }

      const lastDismissedDate = new Date(cookie.lastDismissed);
      this.set('shouldDisplay', new Date() - lastDismissedDate > this.get('timeoutSeconds') * 1000 && cookie.displayCount < this.get('maxDisplayCount'));
    }),
    _writeCookie: (0, _emberConcurrency.task)(function* (value) {
      yield this.get('cookies').write(this.get('cookieName'), JSON.stringify(value), {
        maxAge: COOKIEAGE,
        raw: true
      });
    }),
    _writeInitialCookie: (0, _emberConcurrency.task)(function* () {
      yield this.get('_writeCookie').perform({
        lastDismissed: this.get('lastDismissed'),
        displayCount: this.get('displayCount')
      });
    }),
    dismissTask: (0, _emberConcurrency.task)(function* () {
      const cookie = this.getCookie();

      if (!cookie) {
        yield this.get('_writeInitialCookie').perform();
      } else {
        yield this.get('_writeCookie').perform({
          lastDismissed: new Date(),
          displayCount: cookie.displayCount + 1
        });
      }

      this.set('shouldDisplay', false);
    })
  });

  _exports.default = _default;
});