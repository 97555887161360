define("@outdoorsyco/ember-shared-data/services/create-booking", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    environment: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    isRunning: Ember.computed.or('_startBooking.isRunning', '_saveBooking.isRunning'),

    startBooking({
      rentalId,
      from,
      to,
      discountCode,
      status = 'negotiating',
      renterInfo,
      details,
      quoteId
    }) {
      return this.get('_startBooking').perform({
        rentalId,
        from,
        to,
        discountCode,
        status,
        renterInfo,
        details,
        quoteId
      });
    },

    _startBooking: (0, _emberConcurrency.task)(function* ({
      rentalId,
      from,
      to,
      discountCode,
      status,
      renterInfo,
      details,
      quoteId
    }) {
      let rental = yield this.get('store').findRecord('rental', rentalId);
      let booking = this.get('store').createRecord('booking', {
        to,
        from,
        rental,
        status,
        details,
        quoteId,
        discountCode: discountCode || this.get('session.discountCode')
      });

      if (renterInfo) {
        booking.setProperties({
          newRenterFirstName: renterInfo.newRenterFirstName,
          newRenterLastName: renterInfo.newRenterLastName,
          newRenterEmail: renterInfo.newRenterEmail,
          newRenterPhone: renterInfo.newRenterPhone,
          newRenterCountry: renterInfo.newRenterCountry,
          newRenterLocale: renterInfo.newRenterLocale,
          newRenterBirthdate: renterInfo.newRenterBirthdate,
          newRenterSignupSource: renterInfo.newRenterSignupSource,
          recaptchaResponse: renterInfo.recaptchaResponse || ''
        });
      }

      booking = yield this.get('_saveBooking').perform(booking);

      if (booking.get('anonToken')) {
        yield this.get('session').userLoggedIn(booking.get('anonUserId'), booking.get('anonToken'), {
          session: true
        });
      }

      return booking;
    }),
    _saveBooking: (0, _emberConcurrency.task)(function* (booking) {
      return yield booking.save();
    })
  });

  _exports.default = _default;
});