define("@outdoorsyco/outdoorsy-addon/components/currency-select/component", ["exports", "jquery", "@outdoorsyco/outdoorsy-addon/components/currency-select/template", "@outdoorsyco/ember-shared-data/utils/currencies"], function (_exports, _jquery, _template, _currencies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    selection: null,
    classNames: ['currency-select'],
    isOpen: false,

    init() {
      this._super(...arguments);

      this.currenciesArray = _currencies.currenciesArray;
      this.selectedCurrency = this.get('selection') || 'USD';
    },

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(document).on('click.currencySelect', Ember.run.bind(this, this._clickHandler));
    },

    willDestroyElement() {
      (0, _jquery.default)(window).off('click.currencySelect', Ember.run.bind(this, this._clickHandler));

      this._super(...arguments);
    },

    selectedCurrencyObject: Ember.computed('currenciesArray', 'selectedCurrency', function () {
      return this.get('currenciesArray').findBy('currency', this.get('selectedCurrency'));
    }),
    actions: {
      openDropdown() {
        this.set('isOpen', true);
      },

      updateCurrency(currency) {
        if (this.get('onChange')) {
          this.get('onChange')(currency);
        }

        this.set('selection', currency);
        this.set('isOpen', false);
        return false;
      }

    },

    _clickHandler(e) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      if (this.get('isOpen') && (0, _jquery.default)(e.target).closest('.currency-select').length > 0) {
        return;
      }

      this.set('isOpen', false);
    }

  });

  _exports.default = _default;
});