define("@outdoorsyco/ember-switchback/services/switchback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SwitchbackService extends Ember.Service {
    /**
     * This must match the `sm` size of the breakpoints because the css
     * styles may be coordinated with it.
     *
     * TODO Import this value from the defined breakpoints and make it watch screen size
     */
    get layout() {
      return window.matchMedia('(max-width: 576px)').matches ? 'mobile' : 'desktop';
    }

    get intl() {
      return Ember.getOwner(this).lookup('service:intl');
    }
    /**
     * TODO Figure out a better way to configure translations and text
     */


    get labelForCancel() {
      if (this.intl) {
        return this.intl.t('switchback.global.cancel');
      }

      return 'Cancel';
    }

    get labelForToggleOpen() {
      if (this.intl) {
        return this.intl.t('switchback.global.toggle-open');
      }

      return 'Open';
    }

    get labelForToggleClose() {
      if (this.intl) {
        return this.intl.t('switchback.global.toggle-close');
      }

      return 'Close';
    }

  }

  _exports.default = SwitchbackService;
});