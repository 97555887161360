define("@outdoorsyco/outdoorsy-addon/components/booking/-breakdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    experiments: Ember.inject.service(),
    hasInsurance: false,
    booking: null,
    rental: null,
    hasFullQuote: true,
    discountCodesEnabled: false,
    discountCode: Ember.computed.oneWay('booking.discountCode'),

    didRender() {
      if (!Ember.testing) {
        Ember.run.debounce(this, this._refreshAffirm, 150);
      }
    },

    ownerBaseCurrency: Ember.computed('rental.owner.localeBaseCurrency', function () {
      return this.get('rental.owner.localeBaseCurrency') || 'USD';
    }),
    currentCurrency: Ember.computed('session.currentCurrency', 'booking.{isQuote,localeBaseCurrency}', function () {
      if (this.get('booking.isQuote')) {
        return Ember.get(this, 'booking.presentmentCurrency');
      }

      return this.get('booking.localeBaseCurrency') || 'USD';
    }),
    hasMinimumDayRestriction: Ember.computed('booking.{requestedDuration,duration}', 'rental.activeMinimumDays', function () {
      let requestDuration = this.get('booking.requestedDuration');
      let bookingDuration = this.get('booking.duration');

      if (requestDuration && requestDuration < bookingDuration) {
        return true;
      }

      let duration = requestDuration || bookingDuration;
      return duration < this.get('rental.activeMinimumDays');
    }),
    lineItems: Ember.computed('booking.{lineItems.[],lines.[],isQuote}', function () {
      if (this.get('booking.isQuote')) {
        return this.get('booking.lines') || [];
      }

      return this.get('booking.lineItems') || [];
    }),
    reservationDeposit: Ember.computed('booking.{reserveAmountAfterCredits,reservationDeposit}', function () {
      return this.get('booking.isQuote') ? this.get('booking.reservationDeposit') : this.get('booking.reserveAmountAfterCredits');
    }),
    rentalAmountLineItem: Ember.computed('lineItems.[]', function () {
      return this.get('lineItems').findBy('rentalAmount', true);
    }),
    estimatedMileageFee: Ember.computed('booking.mileageEstimatedFee', 'booking.lineItems.@each.{isMileageItem,total}', function () {
      let estimatedTotal = this.get('booking.mileageEstimatedFee') || 0;

      if (estimatedTotal === 0) {
        return estimatedTotal;
      }

      let items = this.get('booking.lineItems') || [];
      let mileageItem = items.findBy('isMileageItem', true);

      if (!mileageItem || mileageItem && mileageItem.get('total') === 0) {
        return estimatedTotal;
      }

      return 0;
    }),
    estimatedGeneratorFee: Ember.computed('booking.generatorEstimatedFee', 'booking.lineItems.@each.{isGeneratorItem,total}', function () {
      let estimatedTotal = this.get('booking.generatorEstimatedFee') || 0;

      if (estimatedTotal === 0) {
        return estimatedTotal;
      }

      let items = this.get('booking.lineItems') || [];
      let generatorItem = items.findBy('isGeneratorItem', true);

      if (!generatorItem || generatorItem && generatorItem.get('total') === 0) {
        return estimatedTotal;
      }

      return 0;
    }),
    generatorUsageItem: Ember.computed('rental.generatorUsageItem.included', function () {
      return this.get('rental.generatorUsageItem');
    }),
    mileageUsageItem: Ember.computed('rental.mileageUsageItem.included', function () {
      return this.get('rental.mileageUsageItem');
    }),
    deferredItems: Ember.computed('booking.{deferred.[],deferredItems.[],isQuote}', function () {
      if (this.get('booking.isQuote')) {
        return this.get('booking.deferred') || [];
      }

      return this.get('booking.deferredItems') || [];
    }),
    serviceFee: Ember.computed('booking.originalServiceFee', function () {
      return this.get('booking.originalServiceFee');
    }),
    renterCredits: Ember.computed('booking.{credits,renterCredits}', function () {
      return this.get('booking.isQuote') ? this.get('booking.credits') : this.get('booking.renterCredits');
    }),
    totalAdditionalFee: Ember.computed('serviceFee', 'deferredItems.[]', 'lineItems.@each.{rentalAmount,coachnetItem,isMileageItem,isGeneratorItem,total}', 'premiumServices.[]', function () {
      let totalAdditionalFee = 0;
      this.get('lineItems').forEach(item => {
        item = this.get('booking.isQuote') ? Ember.Object.create(item) : item;
        let excludedItem = item.get('isMileageItem') || item.get('isGeneratorItem') || item.get('rentalAmount');

        if (!excludedItem) {
          totalAdditionalFee += item.get('total') || 0;
        }
      });
      this.get('booking.premiumServices').forEach(item => {
        item = this.get('booking.isQuote') ? Ember.Object.create(item) : item;
        let renterItem = item.get('chargeTo') === 'renter';

        if (this.get('booking.isQuote') || renterItem) {
          totalAdditionalFee += item.get('total') || 0;
        }
      });
      this.get('deferredItems').forEach(item => {
        item = this.get('booking.isQuote') ? Ember.Object.create(item) : item;
        totalAdditionalFee += item.get('total') || 0;
      });
      totalAdditionalFee += this.get('serviceFee') || 0;
      totalAdditionalFee += this.get('booking.tax') || 0;
      return totalAdditionalFee;
    }),
    totalAsCents: Ember.computed('booking.total', function () {
      return (this.get('booking.total') || 0) * 100;
    }),

    /**
     * @TODO: Don't use observers if possible (ember/no-observers)
     */
    // eslint-disable-next-line ember/no-observers
    watchLoading: Ember.observer('totalAsCents', 'isLoading', function () {
      if (this.get('isLoading') === false) {
        this._refreshAffirm();
      }
    }),

    _refreshAffirm() {
      if (this.get('supportsAffirmPayment') && window.affirm) {
        Ember.run.later(this, () => {
          window.affirm.ui.refresh();
        });
      }
    },

    currentUser: Ember.computed('session.isAuthenticated', function () {
      if (this.session.hasAuthenticatedUserId) {
        return this.get('session.currentUser');
      }

      return {};
    }),
    supportsAffirmPayment: Ember.computed('currentUser.state', function () {
      let enabled = this.get('experiments').isEnabled('affirmPayment', 'enabled');
      let state = this.get('currentUser.state');
      return enabled && this.get('ownerBaseCurrency') === 'USD' && this.get('rental.owner.dealer') === false && ['IA', 'WV'].indexOf(state) === -1;
    }),
    showAffirmMessageTotal: Ember.computed('hideAffirmMessaging', 'booking.isNegotiating', function () {
      if (this.get('hideAffirmMessaging')) {
        return false;
      }

      return this.get('supportsAffirmPayment') && this.get('booking.isNegotiating');
    }),
    actions: {
      applyDiscountCode() {
        if (this.get('applyDiscountCode')) {
          this.get('applyDiscountCode')(this.get('discountCode'));
        }
      }

    }
  });

  _exports.default = _default;
});