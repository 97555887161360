define("@outdoorsyco/ember-shared-data/serializers/search/user", ["exports", "@outdoorsyco/ember-shared-data/serializers/search/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend();

  _exports.default = _default;
});