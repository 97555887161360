define("@outdoorsyco/ember-shared-data/helpers/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServiceHelper extends Ember.Helper {
    compute([serviceName]) {
      const service = Ember.getOwner(this).lookup(`service:${serviceName}`);
      (false && !(service) && Ember.assert(`The service '${serviceName}' does not exist`, service));
      return service;
    }

  }

  _exports.default = ServiceHelper;
});