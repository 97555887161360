define("@outdoorsyco/ember-shared-data/initializers/outdoorsy-logger", ["exports", "@sentry/browser", "@datadog/browser-rum"], function (_exports, _browser, _browserRum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // https://docs.sentry.io/enriching-error-data/context/?platform=browsernpm
  function setScope(scope, {
    level,
    tags,
    extra
  }) {
    if (level) {
      scope.setLevel(level);
    }

    if (tags) {
      Object.keys(tags).forEach(key => {
        scope.setTag(key, tags[key]);
      });
    }

    if (extra) {
      Object.keys(extra).forEach(key => {
        scope.setExtra(key, extra[key]);
      });
    }
  }

  function initialize(application) {
    const config = application.resolveRegistration('config:environment');
    const sentryEnabled = Ember.get(config, 'sentry.enabled');
    const datadogEnabled = Ember.get(config, 'datadogBrowserRum.enabled');
    Ember.debug(`outdoorsy logger sending to sentry: ${Boolean(sentryEnabled)}`);
    let Logger = Ember.Object.extend({
      error(error, {
        level = 'error',
        tags,
        extra
      } = {}) {
        (false && !(error instanceof Error) && Ember.assert('Must pass an error object into `logger.error`.', error instanceof Error));

        if (datadogEnabled) {
          _browserRum.datadogRum.addError(error, {
            tags: tags,
            extra: extra
          });
        }

        if (sentryEnabled) {
          (0, _browser.withScope)(scope => {
            setScope(scope, {
              level,
              tags,
              extra
            });
            (0, _browser.captureException)(error);
          });
        } else {
          console.error(...arguments);
        }
      },

      warn(message) {
        if (datadogEnabled) {
          _browserRum.datadogRum.addAction(message, {
            logType: 'warn',
            message: message
          });
        }

        if (sentryEnabled) {
          (0, _browser.captureMessage)(message, 'warning');
        } else {
          console.warn(message);
        }
      },

      info(message) {
        if (datadogEnabled) {
          _browserRum.datadogRum.addAction(message, {
            logType: 'info',
            message: message
          });
        }

        if (sentryEnabled) {
          (0, _browser.captureMessage)(message, 'info');
        } else {
          console.info(message);
        }
      },

      debug(message, extra) {
        if (datadogEnabled) {
          _browserRum.datadogRum.addAction(message, {
            logType: 'debug',
            extra: extra
          });
        }

        if (sentryEnabled) {
          (0, _browser.withScope)(scope => {
            setScope(scope, {
              extra
            });
            (0, _browser.captureMessage)(message, 'debug');
          });
        } else {
          console.log(...arguments);
        }
      }

    });
    application.register('logger:outdoorsy', Logger); // Give all controllers, routes, and non-glimmer components access to Logger as this.logger

    application.inject('component', 'logger', 'logger:outdoorsy');
    application.inject('controller', 'logger', 'logger:outdoorsy');
    application.inject('route', 'logger', 'logger:outdoorsy');
  }

  var _default = {
    name: 'logger',
    initialize: initialize
  };
  _exports.default = _default;
});