define("@outdoorsyco/outdoorsy-addon/components/card/user/contact/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "UserContact": "_UserContact_192rrr",
    "avatar": "_avatar_192rrr",
    "content": "_content_192rrr"
  };
  _exports.default = _default;
});