define("@outdoorsyco/ember-shared-data/adapters/search/locality", ["exports", "@outdoorsyco/ember-shared-data/adapters/search/-search-api"], function (_exports, _searchApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _searchApi.default.extend({
    pathForType() {
      return 'localities';
    }

  });

  _exports.default = _default;
});