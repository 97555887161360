define("@outdoorsyco/ember-switchback/helpers/to-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toString = toString;
  _exports.default = void 0;

  function toString([value]) {
    if (typeof value !== 'undefined' && value !== null && value.toString) {
      return value.toString();
    }
  }

  var _default = Ember.Helper.helper(toString);

  _exports.default = _default;
});