define("ember-svg-jar/inlined/plus-gray400", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 5v14m-7-7h14\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "stroke": "hsl(212, 14%, 80%)",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});