define("@outdoorsyco/ember-shared-data/helpers/display-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.displayTime = displayTime;
  _exports.default = void 0;

  function displayTime([value]) {
    let hours = Math.floor(value / 60 / 60) % 24;
    let minutes = Math.floor(value / 60 % 60);
    return (0, _moment.default)({
      hours,
      minutes
    }).format('LT');
  }

  var _default = Ember.Helper.helper(displayTime);

  _exports.default = _default;
});