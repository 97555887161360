define("@outdoorsyco/ember-shared-data/components/prompt/authenticate/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray200": "hsl(204, 14%, 89%)",
    "gray100": "hsl(220, 20%, 97%)",
    "raisedShadow100": "0 1px 1px 0 hsla(0, 0%, 0%, 0.1), 0 1px 1px 0 hsla(0, 0%, 0%, 0.06)",
    "green800": "hsl(172, 50%, 28%)",
    "sizer": "_sizer_djewq8",
    "social-button": "_social-button_djewq8",
    "social-button--facebook": "_social-button--facebook_djewq8",
    "social-button--google": "_social-button--google_djewq8",
    "social-button--primary": "_social-button--primary_djewq8",
    "link": "_link_djewq8",
    "terms": "_terms_djewq8"
  };
  _exports.default = _default;
});