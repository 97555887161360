define("@outdoorsyco/ember-shared-data/models/special-hour", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/utils/format-time"], function (_exports, _emberData, _formatTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  let SpecialHour = (_dec = attr('string', {
    defaultValue: ''
  }), _dec2 = attr('simpleDate'), _dec3 = attr('simpleDate'), _dec4 = belongsTo('location'), _dec5 = belongsTo('user'), _dec6 = attr('boolean', {
    defaultValue: false
  }), _dec7 = attr('number'), _dec8 = attr('boolean', {
    defaultValue: true
  }), _dec9 = attr('number'), _dec10 = attr('number'), _dec11 = attr('boolean', {
    defaultValue: true
  }), _dec12 = attr('number'), _dec13 = attr('number'), _dec14 = Ember.computed('pickupStartTime', 'pickupEndTime'), _dec15 = Ember.computed('dropoffStartTime', 'dropoffEndTime'), (_class = class SpecialHour extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "startDate", _descriptor2, this);

      _initializerDefineProperty(this, "endDate", _descriptor3, this);

      _initializerDefineProperty(this, "location", _descriptor4, this);

      _initializerDefineProperty(this, "owner", _descriptor5, this);

      _initializerDefineProperty(this, "repeats", _descriptor6, this);

      _initializerDefineProperty(this, "createdFromId", _descriptor7, this);

      _initializerDefineProperty(this, "pickupUnavailable", _descriptor8, this);

      _initializerDefineProperty(this, "pickupStartTime", _descriptor9, this);

      _initializerDefineProperty(this, "pickupEndTime", _descriptor10, this);

      _initializerDefineProperty(this, "dropoffUnavailable", _descriptor11, this);

      _initializerDefineProperty(this, "dropoffStartTime", _descriptor12, this);

      _initializerDefineProperty(this, "dropoffEndTime", _descriptor13, this);
    }

    get pickupTime() {
      let start = this.get('pickupStartTime');
      let end = this.get('pickupEndTime');
      return this._timeString(start, end);
    }

    get dropoffTime() {
      let start = this.get('dropoffStartTime');
      let end = this.get('dropoffEndTime');
      return this._timeString(start, end);
    }

    _timeString(start, end) {
      let time = [];

      if (Ember.isPresent(start)) {
        time.push((0, _formatTime.hoursMinutesStringFromSeconds)(start, Ember.get(this, 'owner.localeLanguage')));
      }

      if (Ember.isPresent(end)) {
        time.push((0, _formatTime.hoursMinutesStringFromSeconds)(end, Ember.get(this, 'owner.localeLanguage')));
      }

      return time.join(' - ');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "repeats", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "createdFromId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "pickupUnavailable", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "pickupStartTime", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "pickupEndTime", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "dropoffUnavailable", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "dropoffStartTime", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "dropoffEndTime", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "pickupTime", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "pickupTime"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dropoffTime", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "dropoffTime"), _class.prototype)), _class));
  _exports.default = SpecialHour;
});