define("@outdoorsyco/outdoorsy-addon/components/usage-item/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/usage-item/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    session: Ember.inject.service(),
    classNames: ['price-extra'],
    currency: false,
    intl: Ember.inject.service(),
    useRows: false,
    currentCurrency: Ember.computed('session.currentCurrency', 'currency', function () {
      return this.get('currency') || this.get('session.currentCurrency') || 'USD';
    }),
    listAsRow: Ember.computed('usageItem.sortedTiers.[]', 'useRows', function () {
      if (this.get('usageItem.sortedTiers.length') === 1) {
        return false;
      }

      return this.get('useRows');
    }),
    pluralUnit: Ember.computed('usageItem.unit', function () {
      let unit = this.get('usageItem.unit');

      switch (unit) {
        case 'hours':
        case 'hour':
          unit = this.get('intl').t('hour', {
            count: 2
          });
          break;

        case 'miles':
        case 'mile':
          unit = this.get('intl').t('mile', {
            count: 2
          });
          break;

        case 'kilometers':
        case 'kilometer':
          unit = this.get('intl').t('kilometer', {
            count: 2
          });
          break;

        default:
          break;
      }

      return unit;
    }),
    singularUnit: Ember.computed('usageItem.unit', function () {
      let unit = this.get('usageItem.unit');

      switch (unit) {
        case 'hour':
        case 'hours':
          unit = this.get('intl').t('hour', {
            count: 1
          });
          break;

        case 'mile':
        case 'miles':
          unit = this.get('intl').t('mile', {
            count: 1
          });
          break;

        case 'kilometer':
        case 'kilometers':
          unit = this.get('intl').t('kilometer', {
            count: 1
          });
          break;

        default:
          break;
      }

      return unit;
    }),
    additionalType: Ember.computed('type', function () {
      switch (this.get('type')) {
        case 'delivery':
          return this.get('intl').t('mileage');

        case 'mileage':
          return this.get('intl').t('mileage');

        default:
          return this.get('intl').t('usage');
      }
    }),
    tiersLength: Ember.computed('usageItem.sortedTiers.[]', function () {
      return this.get('usageItem.sortedTiers.length') - 1;
    })
  });

  _exports.default = _default;
});