define("@outdoorsyco/ember-shared-data/components/reports/deposits/batch/detail-table/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "BatchDetailTable": "_BatchDetailTable_tq9wd5"
  };
  _exports.default = _default;
});