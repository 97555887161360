define("@outdoorsyco/ember-shared-data/utils/array/chunk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = chunk;

  function chunk(arr, chunkSize) {
    chunkSize = Math.max(parseInt(chunkSize, 10), 0);

    if (!arr.length || chunkSize < 1) {
      return [];
    }

    let index = 0;
    let resultIndex = -1;
    let result = new Array(Math.ceil(length / chunkSize));

    while (index < arr.length) {
      result[++resultIndex] = arr.slice(index, index += chunkSize);
    }

    return result;
  }
});