define("@outdoorsyco/ember-shared-data/components/form/server-form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormServerFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, (_class = class FormServerFormComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "prompt", _descriptor2, this);

      _initializerDefineProperty(this, "apiRequest", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "metrics", _descriptor5, this);

      _initializerDefineProperty(this, "responseFormData", _descriptor6, this);

      _initializerDefineProperty(this, "loadForm", _descriptor7, this);

      _initializerDefineProperty(this, "onSubmit", _descriptor8, this);

      _initializerDefineProperty(this, "onCancel", _descriptor9, this);
    }

    get logger() {
      return Ember.getOwner(this).lookup('logger:outdoorsy');
    }

    get formData() {
      var _this$loadForm$lastSu;

      return this.responseFormData || ((_this$loadForm$lastSu = this.loadForm.lastSuccessful) === null || _this$loadForm$lastSu === void 0 ? void 0 : _this$loadForm$lastSu.value) || this.args.formData;
    }

    get setupFormData() {
      let collection = this.collectFormData(this.formData.content);
      collection = this.expandDottedKeys(collection);

      if (Ember.isArray(this.formData.carryover_values)) {
        this.formData.carryover_values.forEach(line => {
          this._setMap(collection, line.form_key, line.value);
        });
      }

      return collection;
    }

    expandDottedKeys(map) {
      let dest = {};
      Object.keys(map).forEach(k => {
        this._setMap(dest, k, map[k]);
      });
      return dest;
    }

    collectFormData(elements) {
      let map = {};
      elements.forEach(entry => {
        if (entry.content) {
          let merge = this.collectFormData(entry.content);
          map = Object.assign(map, merge);
          return map;
        }

        if (entry.form_key) {
          if (entry.type === 'array') {
            map[entry.form_key] = entry.list;
          } else if (Object.prototype.hasOwnProperty.call(entry, 'value')) {
            map[entry.form_key] = entry.value;
          } else if (Object.prototype.hasOwnProperty.call(entry, 'values')) {
            map[entry.form_key] = entry.values;
          }
        }

        if (entry.type === 'conditionalToggle') {
          if (typeof entry.ifYes === 'object') {
            let merge = this.collectObjectData(entry.ifYes);
            map = Object.assign(map, merge);
          }
        }
      });
      return map;
    }

    collectObjectData(object) {
      let map = {};
      Object.keys(object).forEach(k => {
        let entry = object[k];
        map[k] = '';

        if (entry.type === 'array') {
          map[k] = entry.list || [];
        } else if (entry.value) {
          map[k] = entry.value;
        }
      });
      return map;
    } // helper function to expand a dotted notation key into it's nested object format


    _setMap(object, key, value) {
      let pieces = key.split('.');
      let current = object;

      for (let i = 0; i < pieces.length; i++) {
        let segment = pieces[i];

        if (i === pieces.length - 1) {
          current[segment] = value;
        } else if (current[segment] === undefined) {
          current[segment] = {};
        }

        current = current[segment];
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "prompt", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "responseFormData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loadForm", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        if (!this.args.formName) {
          return this.args.formData.bui;
        }

        try {
          let jsonData = yield this.apiRequest.request(`forms/${this.args.formName}`);
          return jsonData.bui;
        } catch (error) {
          let msg = error.payload.error || this.intl.t('comp-driving-check-error-processing-request');

          this._responsePrompt.perform(msg);
        }
      };
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        try {
          let data = this.setupFormData; // if onSubmit hook is defined, the caller is handling the submission so give them the data.

          if (typeof this.args.onSubmit === 'function') {
            this.args.onSubmit(data);
            return;
          }

          let response = {};
          const url = Ember.get(this, 'formData.action.url');
          const actionType = Ember.get(this, 'formData.action.type');

          if (actionType === 'request' && !url) {
            (false && !(false) && Ember.assert(`'url' property not found in bui form data action hash`));
            return;
          }

          if (url) {
            const method = Ember.get(this, 'formData.action.method');
            response = yield this.apiRequest.request(url, {
              method,
              data
            });

            if (this.formData.event_name) {
              this.metrics.trackEvent({
                event: this.formData.event_name
              });
            }

            if (response.bui) {
              this.responseFormData = response.bui;
              return;
            }
          }

          Ember.tryInvoke(this.args, 'onSuccess', [response, {
            message: this.formData.success_msg
          }]);
        } catch (error) {
          Ember.tryInvoke(this.args, 'onError', [error]);
          let humanReadableErrorMessage = Ember.get(error, 'payload.bui.description');

          if (!humanReadableErrorMessage) {
            this.logger.error(error);
            humanReadableErrorMessage = this.formData.error_msg || this.intl.t('generic-error');
          }

          const humanReadableError = new Error(humanReadableErrorMessage);
          humanReadableError.name = 'FormSubmit';
          throw humanReadableError;
        }
      };
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onCancel", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        yield this.args.onCancel();
      };
    }
  })), _class));
  _exports.default = FormServerFormComponent;
});