define("@outdoorsyco/ember-shared-data/serializers/payment-gateway", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PaymentGatewaySerializer extends _application.default {
    normalize(typeClass, payload) {
      payload.name = payload.bank_name;
      delete payload.bank_name;
      return super.normalize(typeClass, payload);
    }

  }

  _exports.default = PaymentGatewaySerializer;
});