define("@outdoorsyco/ember-shared-data/serializers/search/rental", ["exports", "@outdoorsyco/ember-shared-data/serializers/search/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SearchRentalSerializer extends _jsonApi.default {
    normalizeResponse(store, primaryModelClass, payload) {
      if (payload && payload.data && payload.data && payload.data.attributes) {
        payload.data.attributes = this._processRentalObject(payload.data.attributes);
      } //@TODO - these shoudl be changed in the search-api - but then a deploy needs to be coordinated cause it will break the frontend


      if (payload && payload.data && Ember.isArray(payload.data)) {
        payload.data.forEach(item => {
          item.type = 'search/rentals';
          item.relationships = item.relationships || {};
          let attrs = item.attributes || {};
          let locId = attrs.current_location_id;

          if (locId) {
            item.relationships.current_location = {
              data: {
                id: locId,
                type: 'location'
              }
            };
          }
        });
      } else if (payload && payload.data && payload.data.attributes) {
        payload.data.type = 'search/rentals';
        let rels = payload.data.relationships || {};
        let locId = payload.data.attributes.current_location_id;

        if (locId) {
          payload.data.relationships.current_location = {
            data: {
              id: payload.data.attributes.current_location_id,
              type: 'location'
            }
          };
        }

        Object.keys(rels).forEach(key => {
          let item = rels[key];

          if (item && item.data && item.data.type === 'breadcrumbs') {
            let k = item.data.type;
            payload.data.relationships[k].data.type = `search/${k}`;
          }
        });
      }

      if (payload && payload.included && Ember.isArray(payload.included)) {
        payload.included.forEach(item => {
          if (item.type === 'breadcrumbs') {
            item.type = 'search/breadcrumbs';
          }
        });
      } // end hacky stuff that should be done in search api


      let normalized = super.normalizeResponse(...arguments);

      if (normalized.meta && typeof normalized.meta === 'object') {
        normalized.meta = this._camelizeKeys(normalized.meta);
      }

      return normalized;
    }

    normalize(typeClass, hash) {
      hash.attributes = this._processRentalObject(hash.attributes);
      return super.normalize(typeClass, hash);
    }

    _camelizeKeys(obj) {
      let ret = {};
      Object.keys(obj).forEach(prop => ret[Ember.String.camelize(prop)] = obj[prop]);
      return ret;
    }

    _processRentalObject(attributes) {
      if (attributes.reviews_num) {
        attributes.score_count = attributes.reviews_num;
        delete attributes.reviews_num;
      }

      if (attributes.group_reviews_num) {
        attributes.group_score_count = attributes.group_reviews_num;
        delete attributes.group_reviews_num;
      }

      if (Ember.isPresent(attributes.active_options) && Ember.isPresent(attributes.active_options.price_per_day) && Ember.isPresent(attributes.active_options.price_per_week) && Ember.isPresent(attributes.active_options.price_per_month)) {
        attributes.active_price_per_day = attributes.active_options.price_per_day;
        attributes.active_price_per_week = attributes.active_options.price_per_week;
        attributes.active_price_per_month = attributes.active_options.price_per_month;
        attributes.active_price_day_tax = attributes.active_options.tax;
        delete attributes.active_options.price_per_day;
        delete attributes.active_options.price_per_week;
        delete attributes.active_options.price_per_month;
        delete attributes.active_options.tax;
      }

      if (attributes.generator_usage_item_id) {
        delete attributes.generator_usage_item_id;
      }

      if (Ember.isPresent(attributes.locale)) {
        attributes.locale_base_currency = attributes.locale.base_currency;
        attributes.locale_distance_unit = attributes.locale.distance_unit;
        attributes.locale_length_unit = attributes.locale.length_unit;
        attributes.locale_liquid_unit = attributes.locale.liquid_unit;
        attributes.locale_weight_unit = attributes.locale.weight_unit;
        delete attributes.locale; // don't overwrite if already exists
      } else if (attributes.locale_base_currency === undefined) {
        attributes.locale_base_currency = 'USD';
        attributes.locale_distance_unit = 'miles';
        attributes.locale_length_unit = 'feet';
        attributes.locale_liquid_unit = 'gallons';
        attributes.locale_weight_unit = 'lbs';
      }

      return attributes;
    }

  }

  _exports.default = SearchRentalSerializer;
});