define("@outdoorsyco/ember-switchback/components/menu-button/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "htEQP1X+",
    "block": "{\"symbols\":[\"@variant\",\"@size\",\"@disabled\",\"@isOpen\",\"@menuId\",\"&attrs\",\"@open\",\"&default\"],\"statements\":[[5,\"button\",[[12,\"aria-haspopup\",\"menu\"],[12,\"aria-expanded\",[28,\"to-string\",[[23,4,[]]],null]],[12,\"aria-controls\",[23,5,[]]],[13,6],[3,\"on\",[\"click\",[23,7,[]]]]],[[\"@variant\",\"@size\",\"@disabled\"],[[23,1,[]],[23,2,[]],[23,3,[]]]],{\"statements\":[[0,\"\\n\\n  \"],[14,8],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/menu-button/button/template.hbs"
    }
  });

  _exports.default = _default;
});