define("ember-google-maps/templates/components/g-map/canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VFXUA9Wk",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"id\",[22,\"id\"]],[12,\"class\",[22,\"computedClasses\"]],[12,\"style\",[22,\"style\"]],[13,1],[8],[14,2],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/canvas.hbs"
    }
  });

  _exports.default = _default;
});