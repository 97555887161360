define("ember-google-maps/components/g-map/control", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/control", "ember-google-maps/utils/options-and-events"], function (_exports, _mapComponent, _control, _optionsAndEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Control
   * @namespace GMap
   * @module ember-google-maps/components/g-map/control
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    layout: _control.default,
    _type: 'control',
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([..._optionsAndEvents.ignoredOptions, 'index', 'class']),

    _addComponent() {
      let _elementDestination = Ember.set(this, '_elementDestination', document.createElement('div'));

      let {
        map,
        class: classNames,
        index
      } = Ember.getProperties(this, ['map', 'class', 'index']);

      if (classNames) {
        _elementDestination.classList.add(classNames);
      }

      if (Number.isInteger(index)) {
        _elementDestination.index = index;
      }

      let controlPosition = google.maps.ControlPosition[Ember.get(this, 'position')];
      map.controls[controlPosition].push(_elementDestination);
      return Ember.RSVP.resolve(Ember.set(this, 'mapComponent', _elementDestination));
    },

    _updateComponent() {}

  });

  _exports.default = _default;
});