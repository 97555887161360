define("@outdoorsyco/ember-switchback/components/frame/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @documenter esdoc */

  /**
   * Wraps content in a frame.
   *
   * ```handlebars
   * <Frame>
   *   content
   * </Frame>
   * ```
   * @public
   */
  class Frame extends _component.default {
    get tagName() {
      return this.args.tagName || 'div';
    }

  }

  _exports.default = Frame;
});