define("@outdoorsyco/ember-switchback/components/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-animated/transitions/fade"], function (_exports, _component, _emberConcurrencyDecorators, _fade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * A html form that validates the values of all the inputs inside
   * of it, and if the validations passes, submits the content.
   *
   * ```handlebars
   * <Form @onSubmit={{perform this.onSubmit}} as |f|>
   *  <TextInput
   *    @value={{this.name}}
   *    @onChange={{fn (mut this.name)}}
   *    @form={{f}}
   *    @requirements={{hash required=true}} />
   *  <Button
   *    @variant="brand-primary"
   *    @loading={{f.isLoading}}
   *    @type="submit">
   *    Save Changes
   *  </Button>
   * </Form>
   * ```
   * @public
   */
  let Form = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = (0, _emberConcurrencyDecorators.task)(), (_class = class Form extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "fadeTransition", _fade.default);

      _initializerDefineProperty(this, "onSubmitError", _descriptor, this);

      _initializerDefineProperty(this, "inputRegistry", _descriptor2, this);

      _initializerDefineProperty(this, "onSubmit", _descriptor3, this);
    }

    /**
     * Adds a child input component to the registry.
     *
     * @param {component} child - A child input component to register
     */
    registerInput(child) {
      this.inputRegistry = [...this.inputRegistry, child];
    }
    /**
       Removes a child input component from the registry
      @param {component} child A child input component to unregister
    */


    unregisterInput(child) {
      this.inputRegistry = Ember.A([...this.inputRegistry]).removeObject(child);
    }
    /**
     * Action that is called when the form is submitted. If none of the child components have any errors then it calls the onSubmit action.
     *
     * @param {event} event The event that triggered the form submission
     */


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "onSubmitError", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "inputRegistry", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (event) {
        if (event && event.preventDefault) {
          event.preventDefault();
        }

        let invalidInput = Ember.A(this.inputRegistry).findBy('isInvalid', true);

        if (invalidInput) {
          yield this.inputRegistry.setEach('isTouched', true);
          invalidInput.focus();
          return;
        }

        this.onSubmitError = null;

        try {
          yield this.args.onSubmit(...arguments);
          /**
           * This resets the form so it can be reused without error
           * messages immediately showing up when all the fields are
           * reset to empty.
           */

          yield this.inputRegistry.setEach('isTouched', false);
        } catch (error) {
          if ((error === null || error === void 0 ? void 0 : error.name) !== 'FormSubmit') {
            throw error;
          }

          this.onSubmitError = Ember.get(error, 'message');
          Ember.run.next(this, function () {
            let errorElement = document.querySelector('[data-form-error]');

            if (errorElement) {
              errorElement.scrollIntoView({
                behavior: 'smooth'
              });
            }
          });
        }
      };
    }
  })), _class));
  _exports.default = Form;
});