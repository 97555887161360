define("@outdoorsyco/ember-shared-data/components/modals/terms-and-conditions/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "md": "768px",
    "tos-modal": "_tos-modal_1dvj7b",
    "heading-wrapper": "_heading-wrapper_1dvj7b",
    "tos-content": "_tos-content_1dvj7b",
    "tos-content-text-wrapper": "_tos-content-text-wrapper_1dvj7b",
    "btn-wrapper": "_btn-wrapper_1dvj7b"
  };
  _exports.default = _default;
});