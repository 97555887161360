define("@outdoorsyco/ember-shared-data/components/input/address/component", ["exports", "@glimmer/component", "@outdoorsyco/ember-shared-data/utils/address-fields", "@outdoorsyco/ember-shared-data/validator/address"], function (_exports, _component, _addressFields, _address) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormAddressComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class FormAddressComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "addressFormat", _descriptor, this);

      _defineProperty(this, "previousCountryCode", null);

      _defineProperty(this, "addressFieldValidator", _address.default);

      (false && !(typeof this.args.countryCode === 'string') && Ember.assert('You must provide a country code to <Input::Address>', typeof this.args.countryCode === 'string'));
      this.countryCodeDidChange();
    }

    get regionWithZip() {
      let hasCountry = this.args.countryCode && (this.args.countryCode === 'US' || this.args.countryCode === 'CA');
      let hasState = this.addressFormat && this.addressFormat.fields.some(f => f.name === 'administrativeArea');
      let hasZip = this.addressFormat && this.addressFormat.fields.some(f => f.name === 'postalCode');
      return hasCountry && hasState && hasZip;
    }

    updateAdministrativeArea(option) {
      this.args.onChange('state', option);
    }

    countryCodeDidChange() {
      if (this.args.countryCode !== this.previousCountryCode) {
        this.addressFormat = (0, _addressFields.getAddressFormatForCountry)(this.args.countryCode || 'US');
        this.previousCountryCode = this.args.countryCode;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "addressFormat", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateAdministrativeArea", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateAdministrativeArea"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countryCodeDidChange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "countryCodeDidChange"), _class.prototype)), _class));
  _exports.default = FormAddressComponent;
});