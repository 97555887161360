define("@outdoorsyco/ember-shared-data/components/bank-form/component", ["exports", "dayjs", "moment", "@outdoorsyco/ember-shared-data/components/bank-form/template", "ember-concurrency", "ember-cp-validations", "@outdoorsyco/ember-shared-data/utils/address-fields", "@outdoorsyco/ember-shared-data/utils/states-provinces", "@outdoorsyco/ember-shared-data/utils/date-of-birth"], function (_exports, _dayjs, _moment, _template, _emberConcurrency, _emberCpValidations, _addressFields, _statesProvinces, _dateOfBirth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    institutionNumber: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isCanadaAccount'),
      description: Ember.computed('model.institutionNumber,intl', function () {
        return this.get('model.intl').t('form-institution-number');
      })
    }),
    transitionNumber: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isCanadaAccount'),
      description: Ember.computed('model.transitionNumber,intl', function () {
        return this.get('model.intl').t('form-transition-number');
      })
    }),
    bsbRouting: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isAustraliaAccount'),
      description: Ember.computed('model.bsbRouting,intl', function () {
        return this.get('model.intl').t('form-bsb-number');
      })
    }),
    sortCode: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isUKAccount'),
      description: Ember.computed('model.sortCode,intl', function () {
        return this.get('model.intl').t('form-sort-code');
      })
    }),
    routingNumber: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isUSAccount'),
      description: Ember.computed('model.routingNumber,intl', function () {
        return this.get('model.intl').t('form-routing-number');
      })
    }),
    personalId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.usesPersonalId'),
      description: Ember.computed('model.personalId,intl', function () {
        return this.get('model.intl').t('form-sin');
      })
    }),
    ssnLastFour: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isUSAccount'),
      description: Ember.computed('model.ssnLastFour,intl', function () {
        return this.get('model.intl').t('form-ssn-last-four');
      })
    }),
    accountNumber: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: Ember.computed('model.accountNumber,intl', function () {
        return this.get('model.intl').t('form-account-number');
      })
    }),
    'bank.vendor.business.tax_id': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isAccountTypeCompany')
    }),
    'bank.vendor.business.name': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isAccountTypeCompany')
    }),
    dobMonth: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please provide your date of birth month.'
    }),
    dobDay: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please provide your date of birth day.'
    }),
    dobYear: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please provide your date of birth year.'
    })
  });

  var _default = Ember.Component.extend(Validations, {
    layout: _template.default,
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    cardTokens: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    classNames: ['add-bank-form'],
    user: null,
    statusMessage: null,
    loading: false,
    routingNumber: null,
    accountNumber: null,
    personalId: null,
    supportPhone: null,
    ssnLastFour: null,
    dobMonth: null,
    dobDay: null,
    dobYear: null,
    error: false,
    hasAdditionalOwners: false,
    isAccountTypeCompany: Ember.computed.equal('bank.vendor.type', 'company'),
    isCanadaAccount: Ember.computed.equal('country', 'CA'),
    isAustraliaAccount: Ember.computed.equal('country', 'AU'),
    isUKAccount: Ember.computed.equal('country', 'GB'),
    usesIban: Ember.computed('country', function () {
      // https://stripe.com/docs/connect/payouts#formats
      const ibanCountries = ['DE', 'ES', 'FR', 'IT', 'BE', 'SE', 'PT', 'NL', 'IE', 'FI', 'DK', 'AT', 'GR', 'NO', 'PL', 'CH'];
      return ibanCountries.indexOf(this.get('country')) !== -1;
    }),
    usesPersonalId: Ember.computed('country', function () {
      const personIdCountries = ['CA'];
      return personIdCountries.indexOf(this.get('country')) !== -1;
    }),
    usesPersonalIdOrSsn: Ember.computed.or('usesPersonalId', 'isUSAccount'),
    isUSAccount: Ember.computed.equal('country', 'US'),
    country: Ember.computed.alias('bank.vendor.address.country'),
    defaultCountry: null,
    supportedCountries: Ember.computed(function () {
      return (0, _statesProvinces.stripePayoutCountriesForSelect)().map(c => {
        c.display = c.name;
        return c;
      });
    }),
    orderedFieldsForCountry: Ember.computed('bank.vendor.address.country', function () {
      let fields = (0, _addressFields.getSortedFieldsForCountry)(this.get('bank.vendor.address.country')); // if a country hasn't been added to the addres-fields list yet use default

      return Ember.isEmpty(fields) ? (0, _addressFields.getSortedFieldsForCountry)('default') : fields;
    }),
    showInputFieldForAdministrativeArea: Ember.computed('bank.vendor.address.country', function () {
      return (0, _addressFields.showInputFieldForAdministrativeArea)(this.get('bank.vendor.address.country'));
    }),
    needsPersonalAddress: Ember.computed('bank.vendor.address.country', 'isAccountTypeCompany', function () {
      let isBusiness = this.get('isAccountTypeCompany');
      let country = this.get('bank.vendor.address.country') || '';
      let excludedCountriesForPersonalAddress = ['US', 'CA', 'AU'];
      let countryNeedsPersonalAddress = excludedCountriesForPersonalAddress.indexOf(country) === -1;
      return isBusiness && countryNeedsPersonalAddress;
    }),
    // avoid-leaking-state-in-ember-objects
    accountTypeOptions: Ember.computed('intl.locales', function () {
      let intl = this.get('intl');
      return [{
        display: intl.t('comp-add-bank-individual'),
        value: 'individual'
      }, {
        display: intl.t('comp-add-bank-business'),
        value: 'company'
      }];
    }),
    bank: null,
    months: Ember.computed('intl.locale', function () {
      return _dateOfBirth.months.map(month => {
        // we can't switch to dayjs here until we get intl working for dayjs
        // getting a mutation error when trying to set month.display... so make a copy
        let monthCopy = Object.assign({}, month);

        _moment.default.locale(this.intl.locale);

        let monthName = (0, _moment.default)().month(month.value - 1).format('MMMM');
        monthCopy.display = `${month.value} ${monthName}`;
        return monthCopy;
      });
    }),

    init() {
      this._super(...arguments);

      let bankInfo = this.bank ? Ember.getProperties(this.bank, 'id', 'street', 'streetEtc', 'city', 'state', 'country', 'zip', 'supportPhone', 'nickname', 'accountType') : {};
      const accountType = bankInfo.accountType === 'corporation' ? 'company' : 'individual';
      let bank = {
        id: bankInfo.id || null,
        vendor: {
          nickname: bankInfo.nickname || '',
          first_name: this.get('user.firstName'),
          last_name: this.get('user.lastName'),
          email: this.get('user.email'),
          type: accountType,
          birth_date: '',
          no_additional_owners: false,
          address: {
            line_one: bankInfo.street || '',
            line_two: bankInfo.streetEtc || '',
            city: bankInfo.city || '',
            state: bankInfo.state || '',
            country: bankInfo.country || 'US',
            zip: bankInfo.zip || ''
          },
          personal_address: {
            line_one: bankInfo.street || '',
            line_two: bankInfo.streetEtc || '',
            city: bankInfo.city || '',
            state: bankInfo.state || '',
            country: bankInfo.country || 'US',
            zip: bankInfo.zip || ''
          },
          business: {
            name: '',
            tax_id: ''
          }
        },
        token: ''
      };

      if (this.defaultCountry) {
        bank.vendor.address.country = this.defaultCountry;
      }

      this.set('supportPhone', bankInfo.supportPhone);
      this.years = (0, _dateOfBirth.driverYears)().map(y => y.toString());
      this.days = (0, _dateOfBirth.days)();
      this.set('bank', bank);
      this.get('cardTokens').resetServerProvidedToken();
    },

    didInsertElement() {
      this._super(...arguments);

      if (false
      /* DEBUG */
      ) {
        Ember.Test.registerWaiter(this, this._waiter);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (false
      /* DEBUG */
      ) {
        Ember.Test.unregisterWaiter(this, this._waiter);
      }
    },

    _waiter() {
      return this._saveBank.isIdle;
    },

    actions: {
      setMonth(obj) {
        this.set('dobMonth', obj.value);
      },

      setDateOfBirth(type, value) {
        this.set(type, value);
      },

      setAdditionalOwners(value) {
        this.set('hasAdditionalOwners', value);
      },

      async connectBank() {
        this.flashMessages.clearMessages();
        const {
          validations
        } = await this.validate();

        if (!validations.isValid) {
          let errorMsg = validations.message || this.intl.t('comp-add-bank-fields-error-message');
          this.flashMessages.danger(errorMsg);
          return;
        }

        return this._saveBank.perform();
      }

    },

    getCurrency() {
      switch (this.get('country')) {
        case 'US':
          return 'usd';

        case 'CA':
          return 'cad';

        case 'AU':
          return 'aud';

        case 'NZ':
          return 'nzd';

        case 'GB':
          return 'gbp';

        case 'PL':
          return 'pln';

        case 'AT':
        case 'BE':
        case 'DE':
        case 'FR':
        case 'IE':
        case 'IT':
        case 'ES':
        case 'GR':
        case 'PT':
        case 'NL':
        case 'SE':
          return 'eur';
      }
    },

    _saveBank: (0, _emberConcurrency.task)(function* () {
      let bankToken, piiToken;

      try {
        if (typeof Stripe === 'undefined') {
          yield this.cardTokens.setupStripe();
        }

        bankToken = yield this.get('_bankToken').perform();
        piiToken = yield this.get('_piiToken').perform();
      } catch (error) {
        // bit of rigmarole here cause the card-tokens service passes through some randomness
        const errorMessage = Ember.get(error, 'name') === 'StripeError' && Ember.get(error, 'message') || Ember.get(error, 'message') || typeof error === 'string' && error;
        const defaultMessage = this.intl.t('comp-add-bank-api-error-message');
        const message = errorMessage || defaultMessage;
        this.get('flashMessages').danger(message);
        let newError = error instanceof Error ? error : new Error(errorMessage || 'create or update stripe bank error');
        this.logger.error(newError);
        return;
      }

      let data = this.get('bank');
      data.vendor.birth_date = (0, _dayjs.default)(`${this.dobYear}-${this.dobMonth}-${this.dobDay}`, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ssZ');
      data.token = bankToken;

      if (piiToken) {
        data.vendor.personal_id = piiToken;
      }

      if (this.get('ssnLastFour')) {
        data.vendor.ssn_last_four = this.get('ssnLastFour');
      }

      if (this.get('supportPhone')) {
        data.vendor.support_phone = this.get('supportPhone');
      }

      if (this.get('needsPersonalAddress')) {
        data.vendor.no_additional_owners = !this.get('hasAdditionalOwners');
        data.vendor.personal_address.country = data.vendor.address.country;
      } else {
        delete data.vendor.no_additional_owners;
        delete data.vendor.personal_address;
      }

      yield this.onSubmit(data);
    }),
    _bankToken: (0, _emberConcurrency.task)(function* () {
      let routingNumber = this.get('routingNumber');

      if (this.get('isCanadaAccount')) {
        routingNumber = `${this.get('transitionNumber')}-${this.get('institutionNumber')}`;
      }

      if (this.get('isAustraliaAccount')) {
        routingNumber = this.get('bsbRouting');
      }

      if (this.get('isUKAccount')) {
        routingNumber = this.get('sortCode');
      }

      let data = {
        country: this.get('bank.vendor.address.country'),
        account_number: this.get('accountNumber'),
        currency: this.getCurrency()
      };

      if (!this.get('usesIban')) {
        data.routing_number = routingNumber;
      }

      let token = yield this.get('cardTokens').generateBankToken(data);
      return token.id;
    }).restartable(),
    _piiToken: (0, _emberConcurrency.task)(function* () {
      if (Ember.isBlank(this.get('personalId'))) {
        return;
      }

      let data = {
        country: this.get('bank.vendor.address.country'),
        personal_id_number: this.get('personalId')
      };
      let token = yield this.get('cardTokens').generatePiiToken(data);
      return token.id;
    }).restartable()
  });

  _exports.default = _default;
});