define("@outdoorsyco/ember-shared-data/components/driving-check/component", ["exports", "moment", "@outdoorsyco/ember-shared-data/components/driving-check/template", "ember-concurrency", "ember-cp-validations"], function (_exports, _moment, _template, _emberConcurrency, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    lastName: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    middleName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.hasMiddleName')
    }),
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.requiresEmail')
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      disabled: Ember.computed.not('model.requiresEmail')
    })],
    gender: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.showDriversLicenseFields')
    }),
    nationality: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    phone: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    license: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.licenseCheck')
    }),
    license_issuer: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.licenseCheck')
    }),
    authorization: (0, _emberCpValidations.validator)(function () {
      return this.get('model.authorization') ? true : this.get('model.intl').t('comp-driving-check-auth-perform-check');
    })
  });

  var _default = Ember.Component.extend(Validations, {
    layout: _template.default,
    user: null,
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    booking: null,
    genderOptions: Ember.computed('intl.locale', function () {
      let intl = this.get('intl');
      return [{
        display: intl.t('common-male'),
        value: 'male'
      }, {
        display: intl.t('common-female'),
        value: 'female'
      }];
    }),
    yesNoOptions: Ember.computed('intl.locale', function () {
      let intl = this.get('intl');
      return [{
        display: intl.t('common-yes'),
        value: true
      }, {
        display: intl.t('common-no'),
        value: false
      }];
    }),
    selectedGender: Ember.computed('genderOptions.@each.value', 'gender', function () {
      return this.genderOptions.findBy('value', this.gender);
    }),
    buttonDisabled: Ember.computed('submissionComplete', function () {
      return this.get('submissionComplete');
    }),
    locationFormIsValid: false,
    showErrors: false,
    geolocate: true,
    // Pre selected values so no need of validation if no change
    // When user iteract and change the selection we validate it and record the result in birthdaySelectorIsValid
    birthdayDay: null,
    birthdayMonth: null,
    birthdayYear: null,
    birthdaySelectorIsValid: false,
    driverCheckOnly: false,
    odRiskAssessmentOnly: false,
    submissionComplete: false,
    felonies: false,
    tickets: false,
    dui: false,
    // values
    firstName: null,
    middleName: null,
    lastName: null,
    gender: null,
    phone: null,
    license: null,
    license_issuer: null,
    nationality: 'US',
    authorization: null,
    email: null,
    street: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    presetUserValues: false,

    init() {
      this._super(...arguments);

      if (this.presetUserValues && this.user) {
        let u = this.user;
        this.gender = u.get('gender') || this.get('session.currentUser.gender');
        this.phone = u.get('phone') || this.get('session.currentUser.phone');
        this.firstName = u.get('firstName');
        this.lastName = u.get('lastName');
        this.email = u.get('email');
        this.street = u.get('street');
        this.city = u.get('city');
        this.state = u.get('state');
        this.zip = u.get('zip');
        this.country = u.get('country');
        let dob = u.get('birthdate');

        if (dob && dob.isValid()) {
          this.birthdayMonth = dob.month() + 1;
          this.birthdayDay = dob.date();
          this.birthdayYear = dob.year();
          this.birthdaySelectorIsValid = true;
        }
      }
    },

    showDriversLicenseFields: Ember.computed('booking.requireDrivers', 'driverCheckOnly', function () {
      return this.get('booking.requireDrivers') || this.get('driverCheckOnly');
    }),
    needsOdRiskAssesmentCheck: Ember.computed('booking.insuranceCoverage', function () {
      return this.get('booking.insuranceCoverage') === 'normal' && !this.get('driverCheckOnly');
    }),
    needsDriverCheck: Ember.computed('showDriversLicenseFields', function () {
      return this.get('showDriversLicenseFields') && !this.get('odRiskAssessmentOnly');
    }),
    licenseCheck: Ember.computed('nationality', 'showDriversLicenseFields', function () {
      let allowedCountries = ['US', 'CA'].includes(this.get('nationality'));
      return allowedCountries && this.get('showDriversLicenseFields');
    }),
    drivingCheckRequiresMoreQuestions: Ember.computed('license_issuer', function () {
      return ['IN', 'NH', 'PA', 'VI', 'WA', 'WY'].includes(this.get('license_issuer'));
    }),
    requiresAddress: Ember.computed('nationality', 'showDriversLicenseFields', function () {
      if (this.get('showDriversLicenseFields')) {
        return true;
      }

      return ['US'].includes(this.get('nationality'));
    }),
    requiresEmail: Ember.computed('booking.insuranceCoverage', function () {
      return this.get('booking.insuranceCoverage') === 'normal';
    }),

    addDriverToBooking(data) {
      const bookingId = this.get('booking.id');
      let userData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        street: data.address_street,
        street_etc: '',
        birthdate: data.dob,
        city: data.address_city,
        state: data.address_state,
        zip: data.address_zip,
        county: data.county,
        country: data.country
      };
      return this.apiRequest.post(`bookings/${bookingId}/drivers/new`, {
        data: userData
      });
    },

    saveUser(data) {
      if (!this.get('session.currentUser.id')) {
        return Ember.RSVP.resolve();
      }

      return this.get('session.currentUser').then(user => {
        user.setProperties({
          firstName: data.first_name,
          lastName: data.last_name,
          phone: data.phone,
          birthdate: data.dob,
          street: data.address_street,
          street1: data.address_street1,
          city: data.address_city,
          state: data.address_state,
          zip: data.address_zip,
          county: data.county,
          country: data.country
        });
        return user.save();
      });
    },

    updateBirthdate(userId, dob) {
      if (!userId || !dob) {
        return Ember.RSVP.resolve();
      }

      return this.apiRequest.patch(`users/${userId}`, {
        data: {
          birthdate: dob
        }
      });
    },

    doDriverCheck(data) {
      return this.apiRequest.request('verify/identity', {
        data: JSON.stringify(data),
        method: 'POST'
      });
    },

    _processIdentityVerification: (0, _emberConcurrency.task)(function* (data) {
      try {
        let needsTintCheck = this.get('needsOdRiskAssesmentCheck');
        let needsDriverCheck = this.get('needsDriverCheck');

        if (needsTintCheck) {
          if (this.get('session.currentAccount.dealer')) {
            if (this.user && this.user.get('id') === this.get('booking.renterId') && this.user.get('ownedById') !== 0) {
              yield this.updateBirthdate(this.user.get('id'), data.dob);
            }

            yield this.addDriverToBooking(data);
          } else {
            yield this.saveUser(data);

            if (this.outdoorsyDriverCheck) {
              yield this.outdoorsyDriverCheck();
            }
          }
        }

        let drivers = [];
        let hasDriversAssocation = this.get('booking.drivers') && typeof this.get('booking.drivers').reload === 'function';

        if (hasDriversAssocation) {
          drivers = yield this.get('booking.drivers');
        }

        let driver = (drivers || []).findBy('email', this.get('session.currentUser.email')) || {};

        if (needsDriverCheck && (!driver || driver && !driver.hasDrivingRecordCheck)) {
          yield this.doDriverCheck(data);
        }

        if (hasDriversAssocation) {
          this.get('booking.drivers').reload();
        }

        this.sendAction('identityCheckComplete'); //eslint-disable-line ember/closure-actions

        this.set('submissionComplete', true);
      } catch (response) {
        let message = this.get('intl').t('comp-driving-check-error-processing-request');

        if (Ember.isPresent(response) && Ember.isPresent(response.errors)) {
          let err = response.errors[0] || {};
          let detail = err.detail || {};
          message = detail.error || message;
        } else if (response && response.payload && response.payload.error) {
          message = response.payload.error;

          if (response.payload.errors && response.payload.errors.length) {
            for (let i = 0; i < response.payload.errors.length; i++) {
              let err = response.payload.errors[i];
              let [field, value] = err.field.split(':');
              message += `<br>${field.capitalize()}: ${value}`;
            }
          }
        }

        this.set('error', Ember.String.htmlSafe(message));

        if (this.handleError) {
          this.handleError(message);
        }
      }

      this.setProperties({
        processingIdentity: false
      });
    }),
    actions: {
      cancelIdentityCheck() {
        this.sendAction('cancelIdentityCheck'); //eslint-disable-line ember/closure-actions
      },

      setLocation(location) {
        this.setProperties({
          street: location.street,
          city: location.city,
          state: location.state,
          zip: location.zip
        });
      },

      onBirthDayChange(params, value) {
        switch (params) {
          case 'day':
            Ember.set(this, 'birthdayDay', value);
            break;

          case 'month':
            Ember.set(this, 'birthdayMonth', value);
            break;

          case 'year':
            Ember.set(this, 'birthdayYear', value);
            break;
        }
      },

      setGender({
        value
      }) {
        this.set('gender', value);
      },

      onLocationFormValidation(validations) {
        const {
          isValid
        } = validations; // will be set when form inserts and updates

        this.set('locationFormIsValid', isValid);
      },

      onBirthdaySelectorValidation(validations) {
        const {
          isValid
        } = validations; // will be set when form inserts and updates

        this.set('birthdaySelectorIsValid', isValid);
      },

      processIdentityVerification() {
        Ember.set(this, 'showErrors', true);

        if (!this.locationFormIsValid && this.requiresAddress) {
          this._scrollToErrors();

          return;
        }

        if (!this.birthdaySelectorIsValid) {
          this._scrollToErrors();

          return;
        }

        this.validate().then(({
          validations
        }) => {
          if (validations.isInvalid) {
            this._scrollToErrors();

            return;
          }

          this.set('processingIdentity', true);
          this.set('error', null);
          let birthday = (0, _moment.default)(`${this.get('birthdayMonth')}/${this.get('birthdayDay')}/${this.get('birthdayYear')}`, 'MM/DD/YYYY');
          let data = {
            first_name: this.get('firstName'),
            last_name: this.get('lastName'),
            gender: this.get('gender'),
            dob: birthday.format('YYYY-MM-DD'),
            phone: this.get('phone'),
            license_number: this.get('license'),
            license_state: this.get('license_issuer'),
            country: this.get('nationality'),
            email: this.get('email'),
            address_street: this.get('street'),
            address_city: this.get('city'),
            address_state: this.get('state'),
            address_zip: this.get('zip')
          };

          if (this.get('middleName')) {
            data['middle_name'] = this.get('middleName');
          } else {
            data['no_middle_name'] = true;
          }

          if (this.get('user') || this.get('booking.renterId')) {
            data['renter_id'] = Number(this.get('user.id') || this.get('booking.renterId'));
          }

          if (this.get('drivingCheckRequiresMoreQuestions')) {
            data.dui = this.get('dui');
            data.tickets = this.get('tickets');
            data.felonies = this.get('felonies');
          }

          if (this.get('booking')) {
            data.booking_id = parseInt(this.get('booking.id'));
          }

          this.get('_processIdentityVerification').perform(data);
        });
      }

    },

    _scrollToErrors() {
      Ember.run.later(this, () => {
        const input = document.querySelector('.has-error');
        return input && input.scrollIntoView();
      });
    }

  });

  _exports.default = _default;
});