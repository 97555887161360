define("@outdoorsyco/ember-switchback/components/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5HMGAQVg",
    "block": "{\"symbols\":[\"Tag\",\"@variant\",\"@maxWidth\",\"&attrs\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"-element\",[[23,0,[\"computedElement\"]]],null]],[[\"tagName\"],[[23,0,[\"computedElement\"]]]]]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[[12,\"class\",[29,[[28,\"local-class\",[\"List\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/list/styles\"]]]]]],[12,\"data-variant\",[28,\"or\",[[23,2,[]],\"default\"],null]],[12,\"data-max-width\",[23,3,[]]],[13,4]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,5,[[28,\"hash\",null,[[\"Item\"],[[28,\"component\",[\"text\"],[[\"tagName\"],[\"li\"]]]]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/list/template.hbs"
    }
  });

  _exports.default = _default;
});