define("@outdoorsyco/ember-switchback/components/fieldset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0iV/owEw",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"fieldset\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Fieldset\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/fieldset/styles\"]]]]]],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[14,2,[[28,\"hash\",null,[[\"group\",\"registerInput\",\"unregisterInput\",\"Row\"],[[23,0,[]],[28,\"action\",[[23,0,[]],[23,0,[\"registerInput\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"unregisterInput\"]]],null],[28,\"component\",[\"fieldset/row\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"input-error\",[],[[\"@showWhen\"],[[23,0,[\"validationError\"]]]],{\"statements\":[[0,\"\\n\"],[0,\"    \"],[1,[23,0,[\"validationError\",\"message\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/fieldset/template.hbs"
    }
  });

  _exports.default = _default;
});