define("@outdoorsyco/ember-shared-data/components/survey-show-multiple-choice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sDku+YAy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"question\",\"style\"]],\"single-choice\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"select-tag\",null,[[\"value\",\"content\",\"placeholder\"],[[24,[\"question\",\"value\"]],[24,[\"choices\"]],[28,\"t\",[\"ui-select\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"multiselect-checkboxes\",null,[[\"class\",\"options\",\"selection\",\"updateSelectionValue\"],[\"list-unstyled\",[24,[\"choices\"]],[24,[\"question\",\"value\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/survey-show-multiple-choice/template.hbs"
    }
  });

  _exports.default = _default;
});