define("@outdoorsyco/ember-shared-data/serializers/proposal", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Proposal extends _application.default {
    normalize(typeClass, data) {
      let total = data.details.total;
      data.details.total = total !== 0 ? Number(data.details.total) / 100 : 0;
      return super.normalize(typeClass, data);
    }

  }

  _exports.default = Proposal;
});