define("@outdoorsyco/ember-switchback/styles/color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "white": "hsl(255, 100%, 100%)",
    "gray100": "hsl(220, 20%, 97%)",
    "gray200": "hsl(204, 14%, 89%)",
    "gray300": "hsl(210, 14%, 84%)",
    "gray400": "hsl(212, 14%, 80%)",
    "gray500": "hsl(208, 12%, 72%)",
    "gray600": "hsl(210, 10%, 58%)",
    "gray700": "hsl(210, 10%, 42%)",
    "gray800": "hsl(208, 8%, 36%)",
    "gray900": "hsl(207, 6%, 23%)",
    "red100": "hsl(356, 100%, 97%)",
    "red200": "hsl(358, 93%, 86%)",
    "red300": "hsl(358, 90%, 73%)",
    "red400": "hsl(358, 86%, 64%)",
    "red500": "hsl(358, 80%, 58%)",
    "red600": "hsl(358, 71%, 48%)",
    "red700": "hsl(358, 78%, 42%)",
    "red800": "hsl(358, 88%, 32%)",
    "red900": "hsl(358, 92%, 20%)",
    "orange100": "hsl(28, 100%, 97%)",
    "orange200": "hsl(30, 94%, 88%)",
    "orange300": "hsl(31, 94%, 74%)",
    "orange400": "hsl(33, 94%, 66%)",
    "orange500": "hsl(33, 99%, 57%)",
    "orange600": "hsl(33, 90%, 52%)",
    "orange700": "hsl(31, 92%, 44%)",
    "orange800": "hsl(30, 92%, 33%)",
    "orange900": "hsl(28, 93%, 18%)",
    "green100": "hsl(154, 70%, 94%)",
    "green300": "hsl(143, 56%, 79%)",
    "green400": "hsl(149, 50%, 64%)",
    "green800": "hsl(172, 50%, 28%)",
    "green900": "hsl(172, 51%, 22%)",
    "blue100": "hsl(210, 88%, 97%)",
    "blue200": "hsl(210, 86%, 88%)",
    "blue300": "hsl(210, 82%, 72%)",
    "blue400": "hsl(210, 80%, 62%)",
    "blue500": "hsl(210, 76%, 54%)",
    "blue600": "hsl(210, 72%, 48%)",
    "blue700": "hsl(210, 78%, 40%)",
    "blue800": "hsl(210, 84%, 30%)",
    "blue900": "hsl(210, 87%, 18%)"
  };
  _exports.default = _default;
});