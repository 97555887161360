define("@outdoorsyco/ember-switchback/motions/scale", ["exports", "ember-animated", "ember-animated/easings/cosine"], function (_exports, _emberAnimated, _cosine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scale;
  _exports.Scale = void 0;

  // Copied from https://github.com/ember-animation/ember-animated/issues/38
  function scale(sprite, opts) {
    return new Scale(sprite, opts).run();
  }

  class Scale extends _emberAnimated.Motion {
    constructor(sprite, opts) {
      super(sprite, opts);
      this.prior = null;
      this.tween = null;
    }

    interrupted(motions) {
      this.prior = motions.find(m => m instanceof this.constructor);
    }

    *animate() {
      let {
        sprite,
        duration,
        opts
      } = this;
      let to = opts.to != null ? opts.to : sprite.finalComputedStyle != null ? parseFloat(sprite.finalComputedStyle.opacity) : 1;
      let from;

      if (this.prior) {
        from = this.prior.tween.currentValue;
        duration = Math.abs(from - to) * duration; // set proportional duration
      } else {
        from = opts.from != null ? opts.from : sprite.initialComputedStyle != null ? parseFloat(sprite.initialComputedStyle.opacity) : 0;
      }

      this.tween = new _emberAnimated.Tween(from, to, duration, _cosine.easeInAndOut);

      while (!this.tween.done) {
        sprite.applyStyles({
          transform: `scale(${this.tween.currentValue})`
        });
        yield (0, _emberAnimated.rAF)();
      }
    }

  }

  _exports.Scale = Scale;
});