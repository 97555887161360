define("@outdoorsyco/ember-shared-data/models/ugc-review", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  const Validations = (0, _emberCpValidations.buildValidations)({
    content: (0, _emberCpValidations.validator)('length', {
      min: 60,
      max: 200,
      message: 'Please write a description (60 char. min).'
    }),

    /*  rating: validator('number', {
      gte: 0.5,
      message: "Please give the place a 1-5 star rating"
    }),*/
    subCategory: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please choose a category.'
    })
  });
  let UgcReview = (_dec = attr('string', {
    defaultValue: ''
  }), _dec2 = belongsTo('ugc-place', {
    async: true
  }), _dec3 = belongsTo('ugc-category', {
    async: true
  }), _dec4 = belongsTo('ugc-sub-category', {
    async: true
  }), _dec5 = hasMany('ugc-tag', {
    async: false
  }), _dec6 = belongsTo('user', {
    async: true
  }), _dec7 = attr('number'), _dec8 = attr('date'), _dec9 = attr('date'), _dec10 = Ember.computed('tags.[]'), _dec11 = Ember.computed.alias('category.id'), (_class = class UgcReview extends Model.extend(Validations) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "content", _descriptor, this);

      _initializerDefineProperty(this, "place", _descriptor2, this);

      _initializerDefineProperty(this, "category", _descriptor3, this);

      _initializerDefineProperty(this, "subCategory", _descriptor4, this);

      _initializerDefineProperty(this, "tags", _descriptor5, this);

      _initializerDefineProperty(this, "owner", _descriptor6, this);

      _initializerDefineProperty(this, "reviewId", _descriptor7, this);

      _initializerDefineProperty(this, "created", _descriptor8, this);

      _initializerDefineProperty(this, "updated", _descriptor9, this);

      _initializerDefineProperty(this, "categoryId", _descriptor10, this);
    }

    get tagsList() {
      return this.get('tags').map(tag => tag.get('name')).join(', ');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "content", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "place", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "subCategory", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "reviewId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "created", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "updated", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "tagsList", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "tagsList"), _class.prototype), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "categoryId", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UgcReview;
});