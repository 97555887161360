define("@outdoorsyco/ember-shared-data/utils/password-requirements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validPassword = validPassword;

  // Deprecated. Use the passwordStrength validator with <PasswordStrength/> component.
  function validPassword(password) {
    password = password || ''; // at least 8 characters, with at least 1 number

    return password.length >= 8 && password.match(/[0-9]/);
  }
});