define("@outdoorsyco/ember-switchback/components/radioset/option/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "brand800": "hsl(172, 50%, 28%)",
    "gray100": "hsl(220, 20%, 97%)",
    "gray300": "hsl(210, 14%, 84%)",
    "gray400": "hsl(212, 14%, 80%)",
    "gray500": "hsl(208, 12%, 72%)",
    "gray600": "hsl(210, 10%, 58%)",
    "raisedShadow100": "0 1px 1px 0 hsla(0, 0%, 0%, 0.1), 0 1px 1px 0 hsla(0, 0%, 0%, 0.06)",
    "raisedShadow200": "0 1px 3px 0 hsla(0, 0%, 0%, 0.1), 0 1px 2px 0 hsla(0, 0%, 0%, 0.06)",
    "focusedShadow100": "0px 0px 0px 2px color-mod(hsl(172, 50%, 28%) a(20%))",
    "erroredShadow100": "0px 0px 0px 2px color-mod(hsl(358, 80%, 58%) a(20%))",
    "red400": "hsl(358, 86%, 64%)",
    "Option": "_Option_7yxax0",
    "radio": "_radio_7yxax0",
    "label": "_label_7yxax0"
  };
  _exports.default = _default;
});