define("@outdoorsyco/ember-shared-data/utils/is-error-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isForbiddenError = isForbiddenError;

  /**
   * isForbiddenError
   *
   * @param {Object}	error - Ajax error
   *
   * @return {boolean}
   *
   */
  function isForbiddenError(error) {
    let status = Ember.get(error, 'errors.firstObject.status');
    return parseInt(status) >= 400;
  }
});