define("@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/mobile-menu/country-menu/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/outdoorsy/header/mobile-menu/country-menu/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'li',
    'data-test-country-select': 'true',
    session: Ember.inject.service(),
    actions: {
      back() {
        this.get('back')();
      },

      revealNestedMenu(element) {
        this.get('revealNestedMenu')(element);
      },

      updateCountry(object) {
        this.get('session').updateCountry(object);
      }

    }
  });

  _exports.default = _default;
});