define("@outdoorsyco/outdoorsy-addon/components/card/user/contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7VVPjQgC",
    "block": "{\"symbols\":[\"@conversationId\",\"@user\",\"@isOwner\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"UserContact\"],[[\"from\"],[\"@outdoorsyco/outdoorsy-addon/components/card/user/contact/styles\"]]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"avatar\"],[[\"from\"],[\"@outdoorsyco/outdoorsy-addon/components/card/user/contact/styles\"]]]]]],[10,\"data-test-avatar\",\"\"],[8],[0,\"\\n    \"],[1,[28,\"od-avatar\",null,[[\"user\"],[[23,2,[]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"content\"],[[\"from\"],[\"@outdoorsyco/outdoorsy-addon/components/card/user/contact/styles\"]]],\"  margin-left-1 \"]]],[8],[0,\"\\n    \"],[5,\"text\",[[12,\"class\",\"  text-font200 text-medium \"],[12,\"data-test-user-name\",\"\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"if\",[[28,\"and\",[[23,3,[]],[23,2,[\"businessName\"]]],null],[23,2,[\"businessName\"]],[23,2,[\"name\"]]],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"text\",[[12,\"class\",\"  text-gray700 text-thin \"],[12,\"data-test-phone-number\",\"\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"if\",[[23,0,[\"phoneNumber\"]],[28,\"phone-number\",[[23,0,[\"phoneNumber\"]]],null],[28,\"t\",[\"term-no-phone\"],null]],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",true],[10,\"class\",\"  text-thin text-green800 \"],[11,\"href\",[28,\"href-to\",[\"dashboard.messages.show\",[23,1,[]]],null]],[10,\"data-test-message-user\",\"\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"ui-message-user\"],[[\"userName\"],[[23,0,[\"messageName\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/card/user/contact/template.hbs"
    }
  });

  _exports.default = _default;
});