define("@outdoorsyco/ember-shared-data/utils/address-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showInputFieldForAdministrativeArea = showInputFieldForAdministrativeArea;
  _exports.getSortedFieldsForCountry = getSortedFieldsForCountry;
  _exports.getAddressFormatForCountry = getAddressFormatForCountry;
  _exports.addressFieldsByCountry = _exports.googlePlaceFields = void 0;
  const googlePlaceFields = ['address_component', 'alt_id', 'formatted_address', 'geometry', 'id', 'name', 'place_id', 'type', 'utc_offset', 'vicinity'];
  _exports.googlePlaceFields = googlePlaceFields;
  const addressFieldsByCountry = {
    default: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      localityName: {
        sort: 2
      },
      postalCode: {
        sort: 4,
        variant: 'postal-code'
      }
    },
    AT: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    AU: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      localityName: {
        sort: 2
      },
      administrativeArea: {
        sort: 3,
        type: 'select',
        variant: 'state'
      },
      postalCode: {
        sort: 4,
        variant: 'postal-code'
      }
    },
    BE: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    BR: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      dependentLocalityName: {
        sort: 2
      },
      localityName: {
        sort: 3
      },
      administrativeArea: {
        sort: 4,
        type: 'select',
        variant: 'state'
      },
      postalCode: {
        sort: 5,
        variant: 'postal-code'
      }
    },
    BG: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    CA: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      localityName: {
        sort: 2
      },
      administrativeArea: {
        sort: 3,
        type: 'select',
        variant: 'province'
      },
      postalCode: {
        sort: 4,
        variant: 'postal-code'
      }
    },
    CN: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      dependentLocalityName: {
        sort: 2
      },
      localityName: {
        sort: 3
      },
      administrativeArea: {
        sort: 4,
        type: 'select',
        variant: 'province'
      },
      postalCode: {
        sort: 5,
        variant: 'postal-code'
      }
    },
    CZ: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    DE: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      localityName: {
        sort: 2
      },
      postalCode: {
        sort: 3,
        variant: 'postal-code'
      }
    },
    DK: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    FI: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    FR: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      localityName: {
        sort: 2
      },
      postalCode: {
        sort: 3,
        variant: 'postal-code'
      }
    },
    GB: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      localityName: {
        sort: 2
      },
      postalCode: {
        sort: 4,
        variant: 'postal-code'
      }
    },
    GR: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    HU: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    IE: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      localityName: {
        sort: 2
      },
      administrativeArea: {
        sort: 3,
        type: 'text',
        variant: 'province'
      },
      postalCode: {
        sort: 4,
        variant: 'postal-code'
      }
    },
    IT: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      localityName: {
        sort: 2
      },
      postalCode: {
        sort: 3,
        variant: 'postal-code'
      }
    },
    JP: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      },
      administrativeArea: {
        sort: 4,
        type: 'select',
        variant: 'prefecture'
      }
    },
    KR: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      dependentLocalityName: {
        sort: 2
      },
      localityName: {
        sort: 3
      },
      administrativeArea: {
        sort: 4,
        type: 'select',
        variant: 'province'
      },
      postalCode: {
        sort: 5,
        variant: 'postal-code'
      }
    },
    MX: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      dependentLocalityName: {
        sort: 2
      },
      localityName: {
        sort: 3
      },
      administrativeArea: {
        sort: 4,
        type: 'select',
        variant: 'state'
      },
      postalCode: {
        sort: 5,
        variant: 'postal-code'
      }
    },
    MY: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      dependentLocalityName: {
        sort: 2
      },
      localityName: {
        sort: 3
      },
      administrativeArea: {
        sort: 4,
        type: 'select',
        variant: 'state'
      },
      postalCode: {
        sort: 5,
        variant: 'postal-code'
      }
    },
    NL: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    NO: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    NZ: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      localityName: {
        sort: 2
      },
      postalCode: {
        sort: 3,
        variant: 'postal-code'
      }
    },
    PL: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    PT: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    HR: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    RS: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    SI: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    RO: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    RU: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      localityName: {
        sort: 2
      },
      administrativeArea: {
        sort: 3,
        type: 'select',
        variant: 'province'
      },
      postalCode: {
        sort: 4,
        variant: 'postal-code'
      }
    },
    ES: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    SE: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    CH: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 2,
        variant: 'postal-code'
      },
      localityName: {
        sort: 3
      }
    },
    TR: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      postalCode: {
        sort: 3,
        variant: 'postal-code'
      },
      administrativeArea: {
        sort: 4,
        type: 'select',
        variant: 'province'
      },
      localityName: {
        sort: 5
      }
    },
    US: {
      thoroughfare: {
        sort: 0
      },
      primise: {
        sort: 1
      },
      localityName: {
        sort: 2
      },
      administrativeArea: {
        sort: 3,
        type: 'select',
        variant: 'state'
      },
      postalCode: {
        sort: 4,
        variant: 'zip'
      }
    }
  };
  _exports.addressFieldsByCountry = addressFieldsByCountry;

  function showInputFieldForAdministrativeArea(country) {
    if (!country) {
      return;
    }

    const countryFields = addressFieldsByCountry[country];
    return countryFields.hasOwnProperty('administrativeArea') && countryFields.administrativeArea.type === 'text';
  }

  function getSortedFieldsForCountry(country) {
    if (!country) {
      return;
    } // allow return of empty array for countries not yet on the list


    const countryFields = addressFieldsByCountry[country] || {};
    return Object.keys(countryFields).sort((a, b) => countryFields[a].sort - countryFields[b].sort);
  }

  function getAddressFormatForCountry(countryCode) {
    let fields = addressFieldsByCountry[countryCode] || addressFieldsByCountry['default'];
    let format = {
      countryCode: countryCode,
      fields: []
    };

    for (let [key, value] of Object.entries(fields)) {
      let field = {
        name: key,
        sort: value.sort
      };

      if (key === 'administrativeArea') {
        format['administrativeAreaType'] = value.variant;
      }

      if (key === 'postalCode') {
        format['postalCodeType'] = value.variant;
      }

      format.fields.push(field);
    }

    format.fields = format.fields.sort((a, b) => {
      return a.sort - b.sort;
    });
    return format;
  }
});