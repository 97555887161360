define("@outdoorsyco/ember-shared-data/models/review-image", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/helpers/image-formatter"], function (_exports, _emberData, _imageFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  let ReviewImage = (_dec = belongsTo('review'), _dec2 = attr('string'), _dec3 = attr('string'), _dec4 = attr('string'), _dec5 = attr('number'), _dec6 = attr('number'), _dec7 = attr('boolean'), _dec8 = attr('string'), _dec9 = attr('string'), (_class = class ReviewImage extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "review", _descriptor, this);

      _initializerDefineProperty(this, "url", _descriptor2, this);

      _initializerDefineProperty(this, "created", _descriptor3, this);

      _initializerDefineProperty(this, "updated", _descriptor4, this);

      _initializerDefineProperty(this, "lat", _descriptor5, this);

      _initializerDefineProperty(this, "lng", _descriptor6, this);

      _initializerDefineProperty(this, "reviewed", _descriptor7, this);

      _initializerDefineProperty(this, "reviewedBy", _descriptor8, this);

      _initializerDefineProperty(this, "status", _descriptor9, this);
    }

    get width1500() {
      return this.generateUrl('width1500');
    }

    get width1000() {
      return this.generateUrl('width1000');
    }

    get width750() {
      return this.generateUrl('width750');
    }

    get width500() {
      return this.generateUrl('width500');
    }

    get square250() {
      return this.generateUrl('square250');
    }

    get square125() {
      return this.generateUrl('square125');
    }

    generateUrl(size) {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: size,
        skipWatermark: true
      });
    } // Legacy sizes


    get thumbnail() {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: 'thumbnail'
      });
    }

    get tinyThumbnail() {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: 'tinyThumbnail'
      });
    }

    get listing() {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: 'listing',
        skipWatermark: true
      });
    }

    get featured() {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: 'featured'
      });
    }

    get social() {
      return (0, _imageFormatter.imageFormatter)(this.url, {
        size: 'social'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "review", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "created", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "updated", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "lat", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "lng", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "reviewed", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "reviewedBy", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ReviewImage;
});