define("@outdoorsyco/outdoorsy-addon/components/banner/share/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "green100": "hsl(154, 70%, 94%)",
    "green300": "hsl(143, 56%, 79%)",
    "Share": "_Share_h2o4gr"
  };
  _exports.default = _default;
});