define("@outdoorsyco/ember-shared-data/components/user/address/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aYIgyqG/",
    "block": "{\"symbols\":[\"@renter\",\"@form\",\"&attrs\"],\"statements\":[[7,\"div\",false],[13,3],[8],[0,\"\\n  \"],[5,\"heading\",[[12,\"class\",\"  margin-bottom-0.25 \"]],[[\"@level\"],[1]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"address-billing\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"field\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"label\",[[12,\"for\",\"country\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"form-country\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"input/country\",[[12,\"class\",\"  margin-bottom-1 \"],[12,\"id\",\"country\"]],[[\"@value\",\"@onChange\",\"@form\",\"@requirements\"],[[23,1,[\"country\"]],[28,\"fn\",[[28,\"mut\",[[23,1,[\"country\"]]],null]],null],[23,2,[]],[28,\"hash\",null,[[\"required\"],[true]]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"country\"]]],null,{\"statements\":[[0,\"    \"],[5,\"field\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"label\",[[12,\"for\",\"address\"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"form-address\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[5,\"input/address\",[],[[\"@inputId\",\"@value\",\"@countryCode\",\"@onChange\",\"@form\",\"@required\"],[\"address\",[28,\"hash\",null,[[\"street1\",\"street2\",\"city\",\"state\",\"zip\"],[[23,1,[\"street\"]],[23,1,[\"street1\"]],[23,1,[\"city\"]],[23,1,[\"state\"]],[23,1,[\"zip\"]]]]],[23,1,[\"country\"]],[28,\"action\",[[23,0,[]],[23,0,[\"updateAddress\"]]],null],[23,2,[]],true]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/user/address/template.hbs"
    }
  });

  _exports.default = _default;
});