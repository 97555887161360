define("@outdoorsyco/ember-shared-data/components/payouts-processor/component", ["exports", "@glimmer/component", "@outdoorsyco/ember-shared-data/utils/states-provinces", "ember-concurrency-decorators", "@outdoorsyco/ember-shared-data/utils/payouts-processors/plaid"], function (_exports, _component, _statesProvinces, _emberConcurrencyDecorators, _plaid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Tracking event names
  const VIEWED_STEP = 'Owner : Payouts : Viewed step';
  const COMPLETED_STEP = 'Owner : Payouts : Completed step';
  const PLAID_EVENT = 'Owner: Payouts: Plaid event';
  const PLAID_CONTINUE_EVENT = 'Owner: Payouts: Plaid authorize event';
  let PayoutsProcessorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class PayoutsProcessorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "apiRequest", _descriptor2, this);

      _initializerDefineProperty(this, "metrics", _descriptor3, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "experiments", _descriptor6, this);

      _initializerDefineProperty(this, "router", _descriptor7, this);

      _initializerDefineProperty(this, "country", _descriptor8, this);

      _initializerDefineProperty(this, "currentStep", _descriptor9, this);

      _initializerDefineProperty(this, "noAuthAccounts", _descriptor10, this);

      _initializerDefineProperty(this, "accountType", _descriptor11, this);

      _initializerDefineProperty(this, "getAuthorizer", _descriptor12, this);

      _initializerDefineProperty(this, "launchProcessor", _descriptor13, this);

      _initializerDefineProperty(this, "completeAuthorization", _descriptor14, this);

      _initializerDefineProperty(this, "continueAuthorization", _descriptor15, this);

      _initializerDefineProperty(this, "authorizationFundForm", _descriptor16, this);

      _initializerDefineProperty(this, "deleteAuthorization", _descriptor17, this);

      (false && !(['outdoorsy', 'wheelbase'].includes(this.args.appName)) && Ember.assert(`<PayoutsProcessor/> needs @appName set to 'outdoorsy' or 'wheelbase'`, ['outdoorsy', 'wheelbase'].includes(this.args.appName)));

      if (this.args.authorizationId) {
        this.currentStep = 3;
        this.continueAuthorization.perform(this.args.authorizationId, this.args.authorizationStatus);
      }
    }

    get logger() {
      return Ember.getOwner(this).lookup('logger:outdoorsy');
    }

    get progressPercentage() {
      return this.currentStep * 25;
    }

    get supportedCountries() {
      return (0, _statesProvinces.stripePayoutCountriesForSelect)().map(c => {
        c.display = c.name;
        return c;
      });
    }

    get accountTypeOptions() {
      return Ember.A([{
        display: 'Individual',
        value: 'individual'
      }, {
        display: 'Sole Proprietor',
        value: 'sole_proprietor'
      }, {
        display: 'Corporation',
        value: 'corporation'
      }]);
    }

    get isEditing() {
      return Boolean(this.args.payoutProcessor);
    }

    changeCountry(newCountry) {
      this.country = newCountry;
      Ember.tryInvoke(this.args, 'onChangeCountry', [newCountry]);
    }

    changeAccountType(newType) {
      this.accountType = newType.value;
      Ember.tryInvoke(this.args, 'onChangeAccountType', [newType.value]);
    }

    fundStepsCompleted(newBank, {
      message
    }) {
      Ember.set(this, 'fundSteps', null);
      Ember.tryInvoke(this.args, 'onSuccess', [newBank, {
        message
      }]);
    }

    fundStepsError(error) {
      // Specific handler for NO_AUTH_ACCOUNTS from plaid.
      // It's clunky and should likely have a backend solve.
      let errorLabel = Ember.get(error, 'payload.bui.label');

      if (errorLabel === 'No eligible accounts') {
        Ember.set(this, 'fundSteps', null);
        this.noAuthAccounts = true;
        let buiAuthValue = Ember.A(Ember.get(this, 'fundSteps.bui.carryover_values') || []).findBy('form_key', 'authorization_id');
        let authId = this.args.authorizationId || (buiAuthValue === null || buiAuthValue === void 0 ? void 0 : buiAuthValue.value);

        if (typeof authId === 'string') {
          this.deleteAuthorization.perform(authId);
        }
      }
    }

    fundStepsCanceled() {
      Ember.set(this, 'fundSteps', null);
    }

    _handleFormError(error) {
      this.logger.error(error);
      let errorObject = new Error(this.intl.t('generic-error'));
      errorObject.name = 'FormSubmit';
      throw errorObject;
    }

    _trackEvent(event) {
      if (this.args.appName === 'outdoorsy') {
        this.metrics.trackEvent(event);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "experiments", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'US';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "currentStep", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 1;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "noAuthAccounts", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "accountType", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'individual';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "getAuthorizer", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        this._trackEvent({
          event: VIEWED_STEP,
          stepName: 'get-authorizer'
        });

        let country = this.country; // we've got an existing bank, use the country

        if (this.args.payoutProcessor && this.args.payoutProcessor.country !== country) {
          country = this.args.payoutProcessor.country;
        }

        let authorizer;
        let useStripeConnect = Ember.get(this.session, 'currentUser.useStripeConnect'); // backend has this list and it's available in the Input::Country component
        // but not being sent back here.
        // let plaidSupported = ['US', 'CA', 'GB', 'FR', 'IE', 'NL', 'ES'];
        // if (plaidSupported.indexOf(country) === -1) {

        if (country !== 'US' || useStripeConnect) {
          return this.args.legacyConnectBank(this.args.payoutProcessor);
        }

        try {
          authorizer = yield this.apiRequest.request('users/authorizations/begin', {
            method: 'GET',
            data: {
              country
            }
          });
        } catch (error) {
          this._handleFormError(error);
        }

        this.authorizer = authorizer;

        this._trackEvent({
          event: COMPLETED_STEP,
          stepName: 'get-authorizer'
        });

        this.currentStep = 2;
      };
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "launchProcessor", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        this._trackEvent({
          event: VIEWED_STEP,
          stepName: 'launch-processor'
        });

        const {
          authorizer
        } = this;
        let userId = Ember.get(this.session, 'currentUser.id');

        try {
          const {
            public_token,
            metadata
          } = yield (0, _plaid.default)(authorizer, {
            userId,
            appName: this.args.appName,
            onLoad: this.args.onLoad || (() => {}),
            onEvent: ({
              eventName,
              metadata
            }) => {
              this._trackEvent({
                event: PLAID_EVENT,
                plaidEventName: eventName,
                plaidEventMetadata: metadata
              });
            },
            logger: this.logger
          });
          yield this.completeAuthorization.perform({
            public_token,
            metadata
          });
        } catch (error) {
          if (error && error instanceof Error) {
            // the processor threw an error
            this._handleFormError(error);
          } // if no error, user may have just exited before completing


          return;
        }

        this._trackEvent({
          event: COMPLETED_STEP,
          stepName: 'launch-processor'
        });
      };
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "completeAuthorization", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* ({
        public_token,
        metadata
      }) {
        var _metadata$account, _metadata$account2;

        this._trackEvent({
          event: VIEWED_STEP,
          stepName: 'complete-authorization'
        });

        let data = {
          token: public_token,
          country: this.country,
          account_id: metadata.account_id,
          account_name: (_metadata$account = metadata.account) === null || _metadata$account === void 0 ? void 0 : _metadata$account.name,
          account_mask: (_metadata$account2 = metadata.account) === null || _metadata$account2 === void 0 ? void 0 : _metadata$account2.mask,
          business_type: this.accountType
        };

        try {
          let endpoint = this.args.payoutProcessor ? `users/authorizations?update_bank_id=${this.args.payoutProcessor.id}` : 'users/authorizations';
          let authForm = yield this.apiRequest.request(endpoint, {
            method: 'POST',
            data
          });
          Ember.set(this, 'fundSteps', authForm);
          this.currentStep = 3;

          this._trackEvent({
            event: COMPLETED_STEP,
            stepName: 'complete-authorization'
          });

          return authForm;
        } catch (error) {
          this._handleFormError(error);
        }

        return {};
      };
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "continueAuthorization", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (authorizationId, authorizationStatus) {
        try {
          if (authorizationStatus !== 'active') {
            let auth = yield this.apiRequest.request(`users/authorizations/${authorizationId}/continue`);
            let userId = Ember.get(this.session, 'currentUser.id');
            yield (0, _plaid.default)(auth, {
              userId,
              depositLinkToken: auth.continue_token,
              // continues the deposit test
              appName: this.args.appName,
              onLoad: this.args.onLoad || (() => {}),
              onEvent: ({
                eventName,
                metadata
              }) => {
                this._trackEvent({
                  event: PLAID_CONTINUE_EVENT,
                  plaidEventName: eventName,
                  plaidEventMetadata: metadata
                }); // error_code can be item-no-error which is a valid pending state


                if (eventName === 'EXIT' && (metadata.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS' || metadata.error_code === null && metadata.exit_status === 'requires_credentials')) {
                  this.deleteAuthorization.perform(authorizationId);
                  this.router.transitionTo('dashboard.account.payout');
                }
              },
              logger: this.logger
            }).catch(() => {// if we catch an error, move on to see what the BUI says
            });
          }

          yield this.authorizationFundForm.perform(authorizationId);
        } catch (e) {
          this.logger.error(e);
          this.flashMessages.danger(this.intl.t('comp-review-form-an-error-occurred'));
        }
      };
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "authorizationFundForm", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (authorizationId) {
        try {
          let resp = yield this.apiRequest.request(`users/authorizations/${authorizationId}/fund-form`, {
            method: 'POST',
            data: {
              authorization_id: authorizationId
            }
          });
          Ember.set(this, 'fundSteps', resp);
        } catch (e) {
          this.logger.error(e);
          this.flashMessages.danger(this.intl.t('comp-review-form-an-error-occurred'));
        }
      };
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "deleteAuthorization", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (authorizationId) {
        try {
          yield this.apiRequest.delete(`users/authorizations/${authorizationId}`);
        } catch (e) {
          this.logger.error(e);
          this.flashMessages.danger(this.intl.t('comp-review-form-an-error-occurred'));
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeCountry", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "changeCountry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeAccountType", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "changeAccountType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fundStepsCompleted", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "fundStepsCompleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fundStepsError", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "fundStepsError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fundStepsCanceled", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "fundStepsCanceled"), _class.prototype)), _class));
  _exports.default = PayoutsProcessorComponent;
});