define("@outdoorsyco/ember-switchback/components/toggle/component", ["exports", "@outdoorsyco/ember-switchback/components/form-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SPACEBAR_CODE = 32;
  /**
   * A native-style interface toggle.
   *
   * It can be used as part of a form or it can trigger an update
   * on its own when passed an onChange action
   *
   * ```handlebars
   * <Toggle @value={{this.value}}>
   *   This label will appear on the right side of the toggle
   * </Toggle>
   *
   * <Toggle
   *   @value={{this.value}}
   *   @onChange={{perform this.saveTask}}>
   *   This label will appear on the right side of the toggle
   * </Toggle>
   *
   * <Toggle
   *   @value={{this.value}}
   *   @onChange={{perform this.saveTask}}
   *   @reverseLayout={{true}}
   *   @class="custom-class">
   *   This label will appear on the left side of the toggle
   * </Toggle>
   * ```
   * @public
   */

  class Toggle extends _component.default {
    /**
     * Converts the provided value to an appropriate string to assign to the
     * aria-checked attribute.
     */
    get value() {
      let type = typeof this.args.value;

      if (type === 'boolean') {
        return this.args.value.toString();
      }

      return 'false';
    }
    /**
     * Updates the value of the toggle
     */


    toggleSelect() {
      if (this.args.disabled) {
        return;
      } // This ensures a falsy undefined value goes to true on click


      let value = typeof this.args.value === 'boolean' ? !this.args.value : true; // only call the update function if no onChange action is provided

      if (this.args.onChange) {
        this.args.onChange(value);
      }
    }
    /**
     * Handles key press events. Space changes value.
     *
     * @param {event} event - The key press event
     */


    onKeyDown(event) {
      if (event.keyCode === SPACEBAR_CODE) {
        event.preventDefault();
        this.toggleSelect();
      }
    }

  }

  _exports.default = Toggle;
});