define("@outdoorsyco/ember-switchback/modifiers/trap-focus", ["exports", "ember-modifier", "@outdoorsyco/ember-switchback/utils/key-codes", "@outdoorsyco/ember-switchback/utils/focus"], function (_exports, _emberModifier, _keyCodes, _focus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let hasDOM = typeof document !== 'undefined';
  /**
   * This modifiers ensures that the focus cannot leave the element it
   * is applied to when the user presses tab. It also ensures that focus
   * wraps around at the start and end of the element.
   *
   * @param {HTMLElement} element - The element to constrain focus to
   */

  var _default = (0, _emberModifier.modifier)((element, args, {
    disabled = false
  }) => {
    if (!hasDOM || disabled) {
      return;
    }

    setElementFocus(element);

    let keydown = event => {
      if (event.which === _keyCodes.TAB) {
        event.preventDefault();

        if (event.shiftKey) {
          (0, _focus.focusPreviousElement)({
            scope: element
          });
        } else {
          (0, _focus.focusNextElement)({
            scope: element
          });
        }
      }
    };

    element.addEventListener('keydown', keydown);
    return () => {
      element.removeEventListener('keydown', keydown);
    };
  });
  /**
   * Sets the focus to an element and ensures the window does not scroll
   * while doing so.
   *
   * @param {HTMLElement} element - The element to focus
   */


  _exports.default = _default;

  let setElementFocus = function setElementFocus(element) {
    element.setAttribute('tabindex', '0');
    let x = window.scrollX,
        y = window.scrollY;
    element.focus();
    window.scrollTo(x, y);
  };
});