define("@outdoorsyco/ember-switchback/components/menu-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gHuxOvrH",
    "block": "{\"symbols\":[\"dd\",\"@options\",\"&default\"],\"statements\":[[5,\"dropdown\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[14,3,[[28,\"hash\",null,[[\"open\",\"Button\",\"Options\",\"Option\"],[[23,1,[\"open\"]],[28,\"component\",[\"menu-button/button\"],[[\"open\",\"isOpen\",\"menuId\"],[[23,1,[\"open\"]],[23,1,[\"isOpen\"]],[23,0,[\"menuId\"]]]]],[28,\"component\",[\"menu-button/options\"],[[\"options\",\"menuId\",\"Container\"],[[23,2,[]],[23,0,[\"menuId\"]],[23,1,[\"Container\"]]]]],[28,\"component\",[\"menu-button/option\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/menu-button/template.hbs"
    }
  });

  _exports.default = _default;
});