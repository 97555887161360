define("@outdoorsyco/ember-shared-data/components/form/insurance-claim/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "@outdoorsyco/ember-shared-data/utils/address-fields", "@outdoorsyco/ember-shared-data/utils/domain-languages"], function (_exports, _component, _emberConcurrencyDecorators, _addressFields, _domainLanguages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormsInsuranceClaimComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember.computed.alias('args.booking'), _dec19 = Ember.computed.alias('args.booking.owner.dealer'), _dec20 = Ember.computed('booking.passengers.[]'), _dec21 = Ember.computed('typeOfLoss'), _dec22 = Ember.computed('typeOfLoss'), _dec23 = Ember.computed('address.{country,street,city,state}'), _dec24 = (0, _emberConcurrencyDecorators.task)({
    drop: true,
    maxConcurrency: 3,
    keepLatest: true
  }), _dec25 = (0, _emberConcurrencyDecorators.task)({
    drop: true,
    maxConcurrency: 3,
    keepLatest: true
  }), _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, _dec30 = Ember._action, _dec31 = Ember._action, _dec32 = Ember._action, _dec33 = Ember._action, (_class = class FormsInsuranceClaimComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "prompt", _descriptor2, this);

      _initializerDefineProperty(this, "apiRequest", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "metrics", _descriptor5, this);

      _initializerDefineProperty(this, "geocoder", _descriptor6, this);

      _initializerDefineProperty(this, "showMap", _descriptor7, this);

      _initializerDefineProperty(this, "triedGeolocation", _descriptor8, this);

      _initializerDefineProperty(this, "validAddress", _descriptor9, this);

      _initializerDefineProperty(this, "exteriorDamageGlass", _descriptor10, this);

      _initializerDefineProperty(this, "exteriorDamagePanels", _descriptor11, this);

      _initializerDefineProperty(this, "exteriorDamageWheels", _descriptor12, this);

      _initializerDefineProperty(this, "interiorOptions", _descriptor13, this);

      _initializerDefineProperty(this, "glassOptions", _descriptor14, this);

      _initializerDefineProperty(this, "panelsSeverity", _descriptor15, this);

      _initializerDefineProperty(this, "panelsOptions", _descriptor16, this);

      _initializerDefineProperty(this, "wheelsOptions", _descriptor17, this);

      _initializerDefineProperty(this, "booking", _descriptor18, this);

      _initializerDefineProperty(this, "isWb", _descriptor19, this);

      _initializerDefineProperty(this, "geocodeAddress", _descriptor20, this);

      _initializerDefineProperty(this, "updateAddress", _descriptor21, this);

      _initializerDefineProperty(this, "onSubmit", _descriptor22, this);

      _initializerDefineProperty(this, "_responsePrompt", _descriptor23, this);

      Ember.set(this, 'typeOfLoss', '');
      Ember.set(this, 'injuredContacts', Ember.A([]));
      Ember.set(this, 'witnesses', Ember.A([]));
      Ember.set(this, 'thirdParties', Ember.A([]));
      Ember.set(this, 'maxDate', new Date());
      Ember.set(this, 'address', {
        country: 'US'
      });
    }

    get damageInteriorOptions() {
      return Ember.A([{
        name: this.intl.t('form-insurance-claim-option-interior'),
        value: 'interior'
      }, {
        name: this.intl.t('form-insurance-claim-option-exterior'),
        value: 'exterior'
      }, {
        name: this.intl.t('form-insurance-claim-option-ie-damage'),
        value: 'both'
      }]);
    }

    get typeOfLossOptions() {
      return Ember.A([{
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-motor-vehicle'),
        value: 'auto_claim_collision_with_motor_vehicle'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-fixed-object'),
        value: 'auto_claim_collision_with_fixed_object'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-pedestrian'),
        value: 'auto_claim_collision_with_pedestrian'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-bicycle'),
        value: 'auto_claim_collision_with_bicycle'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-train-or-bus'),
        value: 'auto_claim_collision_with_bus_or_train'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-other-object'),
        value: 'auto_claim_collision_with_other'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-fire'),
        value: 'auto_claim_fire'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-theft'),
        value: 'auto_claim_theft_of_vehicle'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-theft-of-parts'),
        value: 'auto_claim_theft_of_parts'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-falling-object'),
        value: 'auto_claim_falling_object'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-glass-breakage'),
        value: 'auto_claim_glass'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-hail'),
        value: 'auto_claim_hail'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-flood'),
        value: 'auto_claim_flood'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-storm'),
        value: 'auto_claim_storm'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-mechanical-failure'),
        value: 'auto_claim_tow_or_breakdown'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-animal'),
        value: 'auto_claim_animal'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-rollover'),
        value: 'auto_claim_rollover'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-vandalism'),
        value: 'auto_claim_vandalism'
      }]);
    }

    get typeOfIncidentLocations() {
      return Ember.A([{
        name: this.intl.t('form-insurance-claim-incident-location-highway'),
        value: 'highway'
      }, {
        name: this.intl.t('form-insurance-claim-incident-location-stop-sign'),
        value: 'stop_sign'
      }, {
        name: this.intl.t('form-insurance-claim-incident-location-parking-lot'),
        value: 'parking_lot'
      }, {
        name: this.intl.t('form-insurance-claim-incident-location-repairer'),
        value: 'repairer'
      }, {
        name: this.intl.t('form-insurance-claim-incident-location-other'),
        value: 'other'
      }]);
    }

    get causeOfIncidentOptions() {
      return Ember.A([{
        name: this.intl.t('form-insurance-claim-incident-cause-animal'),
        value: 'animal'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-bicycle'),
        value: 'collision_bicycle'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-fixed-object'),
        value: 'collision_fixed_object'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-motor-vehicle'),
        value: 'collision_motor_vehicle'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-other-object'),
        value: 'collision_other_object'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-pedestrian'),
        value: 'collision_pedestrian'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-collision-with-train-or-bus'),
        value: 'collision_other_vehicle'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-rear-end-collision'),
        value: 'rear_end_collision'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-damage-while-loading-or-unloading'),
        value: 'damage_loading'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-falling-object'),
        value: 'falling_object'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-fire'),
        value: 'fire'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-glass-breakage'),
        value: 'glass'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-hail'),
        value: 'hail'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-mechanical-failure'),
        value: 'mechanical_failure'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-reckless-driving'),
        value: 'reckless_driving'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-rollover'),
        value: 'rollover'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-storm'),
        value: 'storm'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-theft'),
        value: 'theft'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-tire-blowout'),
        value: 'tire_blowout'
      }, {
        name: this.intl.t('form-insurance-claim-incident-cause-vandalism'),
        value: 'vandalism'
      }]);
    }

    get typeOfTheftOptions() {
      return Ember.A([{
        name: this.intl.t('form-insurance-claim-theft-location-home'),
        value: 'home'
      }, {
        name: this.intl.t('form-insurance-claim-theft-location-work'),
        value: 'work'
      }, {
        name: this.intl.t('form-insurance-claim-theft-location-other'),
        value: 'other'
      }]);
    }

    get yesNoUnsureOptions() {
      return Ember.A([{
        name: this.intl.t('common-yes'),
        value: 'true'
      }, {
        name: this.intl.t('common-no'),
        value: 'false'
      }, {
        name: this.intl.t('form-insurance-claim-option-unsure'),
        value: 'unsure'
      }]);
    }

    get yesNoOptions() {
      return Ember.A([{
        name: this.intl.t('common-yes'),
        value: true
      }, {
        name: this.intl.t('common-no'),
        value: false
      }]);
    }

    get yesNoOptionsStr() {
      return Ember.A([{
        name: this.intl.t('common-yes'),
        value: 'true'
      }, {
        name: this.intl.t('common-no'),
        value: 'false'
      }]);
    }

    get deductibleInsuranceAmount() {
      if (this.booking && this.booking.insuranceBundle && this.booking.insuranceBundle.deductible !== undefined) {
        return this.booking.insuranceBundle.deductible * 0.01;
      }

      return null;
    }

    get otherOption() {
      return Ember.A([{
        name: this.intl.t('form-insurance-claim-theft-location-other'),
        value: 'other'
      }]);
    }

    get passengers() {
      let bookingPassengers = this.booking.passengers.models || this.booking.passengers;
      return this.collectDrivers(bookingPassengers).pushObjects(this.otherOption);
    }

    collectDrivers(passengers) {
      return passengers.map(p => {
        let fullName = p.firstName + ' ' + p.lastName;
        return {
          name: fullName,
          email: p.email
        };
      });
    }

    get collisionLossType() {
      return this.typeOfLoss.indexOf('auto_claim_collision') === 0;
    }

    get theftLossType() {
      return this.typeOfLoss.indexOf('auto_claim_theft') === 0;
    }

    get geolocationReady() {
      const {
        country,
        street,
        city,
        state
      } = this.address;
      const addressFormat = (0, _addressFields.getAddressFormatForCountry)(country || 'US');
      let hasState = addressFormat && addressFormat.fields.any(f => f.name === 'administrativeArea');

      if (hasState) {
        return !Ember.isEmpty(street) && !Ember.isEmpty(city) && !Ember.isEmpty(state);
      }

      return !Ember.isEmpty(street) && !Ember.isEmpty(city);
    }

    setupCountry() {
      if (!this.address.country) {
        this.address.country = (0, _domainLanguages.getCountryForDomain)();
      }
    }

    get displayGeolocationError() {
      if (this.triedGeolocation) {
        return !this.validAddress;
      }

      return false;
    }

    damageTypeLocation() {
      let damageDetail = [];

      if (this.exteriorDamageGlass) {
        for (let i = 0; i < this.glassOptions.length; i++) {
          if (this.glassOptions[i].value) {
            damageDetail.push({
              type: 'glass',
              areaOfDamage: this.glassOptions[i].option
            });
          }
        }
      }

      if (this.exteriorDamagePanels) {
        for (let i = 0; i < this.panelsOptions.length; i++) {
          if (this.panelsOptions[i].value) {
            damageDetail.push({
              type: 'panel',
              areaOfDamage: this.panelsOptions[i].option,
              severity: this.panelsOptions[i].severity
            });
          }
        }
      }

      if (this.exteriorDamageWheels) {
        for (let i = 0; i < this.wheelsOptions.length; i++) {
          if (this.wheelsOptions[i].value) {
            damageDetail.push({
              type: 'wheel',
              areaOfDamage: this.wheelsOptions[i].option
            });
          }
        }
      }

      if (this.damageInterior === 'interior' || this.damageInterior === 'both') {
        for (let i = 0; i < this.interiorOptions.length; i++) {
          if (this.interiorOptions[i].value) {
            damageDetail.push({
              type: 'interior',
              areaOfDamage: this.interiorOptions[i].option
            });
          }
        }
      }

      return damageDetail;
    }

    get setupClaim() {
      return {
        booking_id: parseInt(this.booking.id),
        processor: 'snapsheet',
        damage_type: this.damageInterior,
        damage_type_location: this.damageTypeLocation(),
        type_of_loss: this.typeOfLoss,
        incident_location_type: this.incidentLocationType,
        cause_of_incident: this.causeOfIncident,
        theft_location_type: this.theftLocationType,
        claim_party: 'owner',
        prior_damage: this.priorDamage,
        party_provided_keys: this.partyProvidedKeys,
        other_driver_name: this.otherDriverName,
        where_damage_happen_location: this.address,
        damage_description: this.damageDescription,
        damage_estimate: this.damageEstimate,
        facts_of_loss: this.factsOfLoss,
        any_injuries: this.anyInjuries,
        injured_contacts: this.injuredContacts,
        hail_damage_during_booking: this.hailDamageBooking,
        is_damage_mechanical: this.damageMechanical,
        is_awning_damage: this.awingDamage === 'true' && this.damageInterior !== 'interior',
        is_vehicle_drivable: this.isVehicleDrivable === 'true',
        is_vehicle_abandoned: this.wasVehicleAbandoned === 'true',
        has_tire_blowout: this.tireBlowout === 'true',
        date_of_loss: this.damageDate,
        damage_over_deductible: this.damageDeductible,
        police_notified: this.policeAgencyOpt,
        police_agency: this.policeAgency,
        police_report_number: this.policeReportNumber,
        witnesses_present: this.witnessesPresent,
        witnesses: this.witnesses,
        third_party_involved: this.thirdPartyInvolved,
        third_parties: this.thirdParties,
        additional_information: this.additionalInformation,
        occured_during_pickup_delivery: this.pickupOrDelivery === 'true',
        delivery_driver_name: this.deliveryDriverName,
        claim_contact_email: this.claimContactEmail,
        claim_phone_number: this.claimPhoneNumber
      };
    }

    _createPerson() {
      return {
        name: '',
        phone: '',
        email: '',
        description: ''
      };
    }

    setOptionValue(attrName, option) {
      Ember.set(this, attrName, option.value);
    }

    toggleList(prop, list, value) {
      Ember.set(this, prop, value);

      if (value) {
        let arr = Ember.A([this._createPerson()]);
        Ember.set(this, list, arr);
      } else {
        Ember.set(this, list, Ember.A([]));
      }
    }

    addPerson(listName) {
      if (Ember.isArray(this[listName])) {
        Ember.set(this, listName, [...this[listName], this._createPerson()]);
      }
    }

    removePerson(boolProp, list, index) {
      if (Ember.isArray(this[list])) {
        this[list].splice(index, 1);
        Ember.set(this, list, [...this[list]]);

        if (boolProp !== '' && this[list].length === 0) {
          Ember.set(this, boolProp, false);
        }
      }
    }

    setExteriorDamageOpt(option, val) {
      Ember.set(this, option, !val);
    }

    async uploadHandoffPhotos(handoffStage) {
      await this.prompt.show('prompt/upload/handoff-photos', {
        claim: true,
        booking: this.booking,
        handoffStage
      }, {
        minWidth: 36
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "prompt", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "geocoder", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showMap", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "triedGeolocation", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "validAddress", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "exteriorDamageGlass", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "exteriorDamagePanels", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "exteriorDamageWheels", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "interiorOptions", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return Ember.A([{
        name: this.intl.t('form-insurance-claim-option-front-seat'),
        value: false,
        option: 'front_left_seat'
      }, {
        name: this.intl.t('form-insurance-claim-option-rear-seat'),
        value: false,
        option: 'rear_seat'
      }, {
        name: this.intl.t('form-insurance-claim-option-dashboard'),
        value: false,
        option: 'dashboard'
      }, {
        name: this.intl.t('form-insurance-claim-option-floor'),
        value: false,
        option: 'floor'
      }]);
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "glassOptions", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return Ember.A([{
        name: this.intl.t('form-insurance-claim-option-glass-front'),
        value: false,
        option: 'front'
      }, {
        name: this.intl.t('form-insurance-claim-option-glass-front-left'),
        value: false,
        option: 'front_left'
      }, {
        name: this.intl.t('form-insurance-claim-option-glass-front-right'),
        value: false,
        option: 'front_right'
      }, {
        name: this.intl.t('form-insurance-claim-option-glass-rear'),
        value: false,
        option: 'rear'
      }, {
        name: this.intl.t('form-insurance-claim-option-glass-rear-left'),
        value: false,
        option: 'rear_left'
      }, {
        name: this.intl.t('form-insurance-claim-option-glass-rear-right'),
        value: false,
        option: 'rear_right'
      }]);
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "panelsSeverity", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return Ember.A([{
        name: this.intl.t('form-insurance-claim-option-panels-severity-light'),
        value: 'light'
      }, {
        name: this.intl.t('form-insurance-claim-option-panels-severity-medium'),
        value: 'medium'
      }, {
        name: this.intl.t('form-insurance-claim-option-panels-severity-severe'),
        value: 'severe'
      }]);
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "panelsOptions", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return Ember.A([{
        name: this.intl.t('form-insurance-claim-option-panels-right-front-door'),
        value: false,
        option: 'right_front_door',
        severity: 'light'
      }, {
        name: this.intl.t('form-insurance-claim-option-panels-front'),
        value: false,
        option: 'front',
        severity: 'light'
      }, {
        name: this.intl.t('form-insurance-claim-option-panels-rear'),
        value: false,
        option: 'rear',
        severity: 'light'
      }, {
        name: this.intl.t('form-insurance-claim-option-panels-right-front-fender'),
        value: false,
        option: 'right_front_fender',
        severity: 'light'
      }, {
        name: this.intl.t('form-insurance-claim-option-panels-left-front-fender'),
        value: false,
        option: 'left_front_fender',
        severity: 'light'
      }, {
        name: this.intl.t('form-insurance-claim-option-panels-right-rear-quarter'),
        value: false,
        option: 'right_rear_quarter_panel',
        severity: 'light'
      }, {
        name: this.intl.t('form-insurance-claim-option-panels-left-rear-quarter'),
        value: false,
        option: 'left_rear_quarter_panel',
        severity: 'light'
      }, {
        name: this.intl.t('form-insurance-claim-option-panels-roof'),
        value: false,
        option: 'roof',
        severity: 'light'
      }, {
        name: this.intl.t('form-insurance-claim-option-panels-undercarriage'),
        value: false,
        option: 'undercarriage',
        severity: 'light'
      }]);
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "wheelsOptions", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return Ember.A([{
        name: this.intl.t('form-insurance-claim-option-wheels-front-left'),
        value: false,
        option: 'front_left'
      }, {
        name: this.intl.t('form-insurance-claim-option-wheels-front-right'),
        value: false,
        option: 'front_right'
      }, {
        name: this.intl.t('form-insurance-claim-option-wheels-rear-left'),
        value: false,
        option: 'rear_left'
      }, {
        name: this.intl.t('form-insurance-claim-option-wheels-rear-right'),
        value: false,
        option: 'rear_right'
      }, {
        name: this.intl.t('form-insurance-claim-option-wheels-inner-left'),
        value: false,
        option: 'rear_left'
      }, {
        name: this.intl.t('form-insurance-claim-option-wheels-inner-right'),
        value: false,
        option: 'rear_right'
      }]);
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "booking", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "isWb", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "passengers", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "passengers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "collisionLossType", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "collisionLossType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "theftLossType", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "theftLossType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "geolocationReady", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "geolocationReady"), _class.prototype), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "geocodeAddress", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        if (this.geolocationReady) {
          const {
            street,
            street_etc,
            city,
            state,
            zip,
            country
          } = this.address;
          const query = `${street} ${street_etc || ''} ${city}, ${state} ${zip || ''} ${country || 'US'}`;
          this.triedGeolocation = true;

          try {
            const location = yield this.geocoder.geocode(query);
            const lat = Number(location[0].lat);
            const lng = Number(location[0].lon);
            Ember.set(this.address, 'lat', lat);
            Ember.set(this.address, 'lng', lng);
            this.showMap = true;
            this.validAddress = true;
          } catch (error) {
            this.showMap = false;
            this.validAddress = false;
            Ember.set(this.address, 'lat', null);
            Ember.set(this.address, 'lng', null);
          }
        }
      };
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "updateAddress", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (key, value) {
        if (key === 'street1') {
          key = 'street';
        } else if (key === 'street2') {
          key = 'street_etc';
        }

        Ember.set(this.address, key, value);

        if (this.geolocationReady) {
          yield this.geocodeAddress.perform();
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupCountry", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "setupCountry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "damageTypeLocation", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "damageTypeLocation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setOptionValue", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "setOptionValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleList", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "toggleList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addPerson", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "addPerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removePerson", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "removePerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setExteriorDamageOpt", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "setExteriorDamageOpt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadHandoffPhotos", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "uploadHandoffPhotos"), _class.prototype), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        try {
          let req = this.apiRequest; //if we submit this from the portal we want to use admin request instead of regular

          if (this.args.appRequest && this.args.appRequest.post) {
            req = this.args.appRequest;
          }

          yield req.post('claims', {
            data: this.setupClaim
          });
          this.metrics.trackEvent({
            event: 'Owner Insurance Claim'
          });

          this._responsePrompt.perform();
        } catch (error) {
          console.error(error);
          let msg = this.intl.t('comp-driving-check-error-processing-request');

          if (error.payload && error.payload.error) {
            msg = error.payload.error;
          }

          this._responsePrompt.perform(msg);
        }
      };
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "_responsePrompt", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (error) {
        let title = error ? this.intl.t('form-insurance-claim-issue-sending') : this.intl.t('form-insurance-claim-sent');
        yield this.prompt.inform({
          title: title,
          description: error ? error : this.intl.t('form-insurance-claim-sent-thanks', {
            htmlSafe: true
          }),
          close: {
            label: 'Close',
            variant: 'brand-primary'
          }
        }, {
          minWidth: 26,
          maxWidth: 30
        }); // let them re-attempt the submission

        if (error) {
          return;
        }

        if (typeof this.args.onSuccess === 'function') {
          this.args.onSuccess();
        }
      };
    }
  })), _class));
  _exports.default = FormsInsuranceClaimComponent;
});