define("@outdoorsyco/ember-shared-data/adapters/integration", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Integration extends _application.default {
    buildURL(type, id, snapshot, requestType, query) {
      let url = super.buildURL(type, id, snapshot, requestType);
      /* When looking for services for a booking, switch to booking route
         so we load services supported by the booking */

      if (requestType === 'query') {
        let host = this.get('host');
        let namespace = this.get('namespace');

        if (query.booking_id) {
          url = `${host}/${namespace}/bookings/${query.booking_id}/services`;
          delete query.booking_id;
        } else if (query.rental_id) {
          url = `${host}/${namespace}/rentals/${query.rental_id}/services`;
          delete query.rental_id;
        }
      }

      return url;
    }

  }

  _exports.default = Integration;
});