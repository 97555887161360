define("ember-data-storefront/instance-initializers/inject-storefront", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.inject('route', 'storefront', 'service:storefront');
    appInstance.inject('controller', 'storefront', 'service:storefront');
  }

  var _default = {
    name: 'inject-storefront',
    after: 'mixin-storefront',
    initialize
  };
  _exports.default = _default;
});