define("@outdoorsyco/ember-switchback/components/slideup/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gray200": "hsl(204, 14%, 89%)",
    "white": "hsl(255, 100%, 100%)",
    "negativeGray500": "0 -15px 25px -5px hsla(207, 6%, 23%, 0.1), 0 -10px 10px -5px hsla(207, 6%, 23%, 0.04)",
    "Slideup": "_Slideup_j4e289",
    "content": "_content_j4e289",
    "overlay": "_overlay_j4e289"
  };
  _exports.default = _default;
});