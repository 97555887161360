define("@outdoorsyco/ember-shared-data/components/booking/review/photo-uploader/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sm": "576px",
    "uploader": "_uploader_1vbr6s",
    "dropzone": "_dropzone_1vbr6s",
    "uploaded-images": "_uploaded-images_1vbr6s",
    "image": "_image_1vbr6s",
    "image-overlay": "_image-overlay_1vbr6s",
    "image-delete": "_image-delete_1vbr6s"
  };
  _exports.default = _default;
});