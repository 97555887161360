define("ember-animated/templates/components/animated-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Edo6Czkq",
    "block": "{\"symbols\":[\"currentPredicate\",\"&inverse\",\"&default\"],\"statements\":[[4,\"animated-value\",[[23,0,[\"predicate\"]]],[[\"key\",\"rules\",\"use\",\"duration\",\"group\",\"initialInsertion\",\"finalRemoval\"],[[23,0,[\"key\"]],[23,0,[\"rules\"]],[23,0,[\"use\"]],[23,0,[\"duration\"]],[23,0,[\"realGroup\"]],[23,0,[\"initialInsertion\"]],[23,0,[\"finalRemoval\"]]]],{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-if.hbs"
    }
  });

  _exports.default = _default;
});