define("@outdoorsyco/ember-shared-data/components/avatar/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AvatarComponent extends _component.default {
    get initials() {
      return this.nameInitials || this.emailInitials;
    }

    get emailInitials() {
      return (Ember.get(this, 'args.user.email') || '').charAt(0).toUpperCase();
    }

    get nameInitials() {
      var _this$args$user, _this$args$user2;

      let name = Ember.get(this, 'args.user.name') || '';
      let firstInitial = (((_this$args$user = this.args.user) === null || _this$args$user === void 0 ? void 0 : _this$args$user.first_name) || name.split(' ')[0] || '').slice(0, 1);
      let secondInitial = (((_this$args$user2 = this.args.user) === null || _this$args$user2 === void 0 ? void 0 : _this$args$user2.last_name) || name.split(' ')[1] || '').slice(0, 1);
      return `${firstInitial}${secondInitial}`.toUpperCase();
    }

  }

  _exports.default = AvatarComponent;
});