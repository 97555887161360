define("@outdoorsyco/ember-shared-data/models/bundle", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  /**
   * A bundle (or "protection plan") defines a set of features and/or price for insurance.
   * with the ability to define services that are included in the bundle (or "package" or "protection plan")
   */

  let Bundle = (_dec = attr('boolean'), _dec2 = attr('string'), _dec3 = attr('date'), _dec4 = attr('boolean'), _dec5 = attr('string'), _dec6 = hasMany('bundle-feature', {
    async: false
  }), _dec7 = attr('string'), _dec8 = attr('string'), _dec9 = attr('string'), _dec10 = belongsTo('user', {
    async: true
  }), _dec11 = attr('number'), _dec12 = attr('number'), _dec13 = attr('number'), _dec14 = hasMany('bundle-rule', {
    async: false
  }), _dec15 = attr('number'), _dec16 = attr('date'), _dec17 = hasMany('localized-content', {
    async: true
  }), _dec18 = hasMany('tax'), (_class = class Bundle extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "active", _descriptor, this);

      _initializerDefineProperty(this, "country", _descriptor2, this);

      _initializerDefineProperty(this, "created", _descriptor3, this);

      _initializerDefineProperty(this, "dealerOwnedRenter", _descriptor4, this);

      _initializerDefineProperty(this, "description", _descriptor5, this);

      _initializerDefineProperty(this, "features", _descriptor6, this);

      _initializerDefineProperty(this, "internalDescription", _descriptor7, this);

      _initializerDefineProperty(this, "lineItemName", _descriptor8, this);

      _initializerDefineProperty(this, "name", _descriptor9, this);

      _initializerDefineProperty(this, "owner", _descriptor10, this);

      _initializerDefineProperty(this, "percentUptakeDrivable", _descriptor11, this);

      _initializerDefineProperty(this, "percentUptakeTowable", _descriptor12, this);

      _initializerDefineProperty(this, "rentalId", _descriptor13, this);

      _initializerDefineProperty(this, "rules", _descriptor14, this);

      _initializerDefineProperty(this, "tier", _descriptor15, this);

      _initializerDefineProperty(this, "updated", _descriptor16, this);

      _initializerDefineProperty(this, "localizedContent", _descriptor17, this);

      _initializerDefineProperty(this, "taxRates", _descriptor18, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "active", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "created", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dealerOwnedRenter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "internalDescription", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "lineItemName", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "percentUptakeDrivable", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "percentUptakeTowable", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "rentalId", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "rules", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "tier", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "updated", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "localizedContent", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "taxRates", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Bundle;
});