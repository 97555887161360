define("ember-power-select/components/power-select/before-options", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BeforeOptions = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class BeforeOptions extends _component.default {
    clearSearch() {
      Ember.run.scheduleOnce('actions', this.args.select.actions, 'search', '');
    }

    handleKeydown(e) {
      if (this.args.onKeydown(e) === false) {
        return false;
      }

      if (e.keyCode === 13) {
        this.args.select.actions.close(e);
      }
    }

    focusInput(el) {
      Ember.run.later(() => {
        if (this.args.autofocus !== false) {
          el.focus();
        }
      }, 0);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "clearSearch", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "clearSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeydown", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "focusInput"), _class.prototype)), _class));
  _exports.default = BeforeOptions;
});