define("@outdoorsyco/ember-shared-data/components/location-form/component", ["exports", "@outdoorsyco/ember-shared-data/components/location-form/template", "ember-concurrency", "ember-cp-validations", "@outdoorsyco/ember-shared-data/utils/address-fields", "@outdoorsyco/ember-shared-data/utils/states-provinces"], function (_exports, _template, _emberConcurrency, _emberCpValidations, _addressFields, _statesProvinces) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    street: (0, _emberCpValidations.validator)(function () {
      let {
        street,
        lat,
        lng
      } = this.get('model').getProperties(['street', 'lat', 'lng']);
      let validCoords = this.get('model.geolocate') ? lat && lng : true;

      if (street && validCoords) {
        return true;
      }

      let message = Ember.getOwner(this).lookup('service:intl').t('comp-location-validation-street');
      return message;
    }),
    city: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'comp-location-validation-city'
    }),
    state: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'comp-location-validation-state',
      disabled: Ember.computed('model.country', function () {
        return !(0, _addressFields.getSortedFieldsForCountry)(this.get('model.country') || 'US').includes('administrativeArea');
      })
    }),
    zip: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'comp-location-validation-zip'
    }),
    country: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'comp-location-validation-country'
    })
  });

  var _default = Ember.Component.extend(Validations, {
    layout: _template.default,
    classNames: ['address-form'],
    classNameBindings: ['loading:disabled'],
    geocoder: Ember.inject.service(),
    street: null,
    street1: null,
    city: null,
    state: null,
    zip: null,
    lat: null,
    lng: null,
    country: null,
    showErrors: true,
    loading: false,
    geocodeScope: 'address',
    countryOptions: (0, _statesProvinces.countriesForSelect)(),
    showAllFields: false,
    geolocate: true,
    // includes lat/lng and geo locate lookup
    countrySelect: true,
    showCountryField: true,
    restrictSearch: true,
    renderInPlace: false,

    didInsertElement() {
      // setup validations to give caller access to form validations
      this.validate().then(({
        validations
      }) => {
        if (validations.get('isInvalid') && this.get('validationsChange')) {
          this.get('validationsChange')(validations);
        }
      });
    },

    locationProperties() {
      let props = ['street', 'street1', 'city', 'state', 'zip', 'country'];

      if (this.get('geolocate')) {
        props = props.concat(['lat', 'lng']);
      }

      return this.getProperties(props);
    },

    locationAddress() {
      let {
        street,
        city,
        state,
        zip,
        country
      } = this.locationProperties();
      return [street, city, state, zip, (0, _statesProvinces.countryNameFromAbbreviation)(country)].filter(item => !Ember.isBlank(item)).join(', ');
    },

    restrictedSearchCountries: Ember.computed('country', 'restrictSearch', function () {
      let shouldRestrict = this.get('restrictSearch') && this.get('country');
      return shouldRestrict ? [this.get('country')] : [];
    }),
    showInputFieldForAdministrativeArea: Ember.computed('country', function () {
      return (0, _addressFields.showInputFieldForAdministrativeArea)(this.get('country'));
    }),
    orderedFieldsForCountry: Ember.computed('country', function () {
      let fields = (0, _addressFields.getSortedFieldsForCountry)(this.get('country') || 'US'); // if a country hasn't been added to the addres-fields list yet use default

      return Ember.isEmpty(fields) ? (0, _addressFields.getSortedFieldsForCountry)('default') : fields;
    }),

    updateLocation(attrs, fromGeoCode = false) {
      if (attrs.country && !fromGeoCode) {
        // dump country-dependent fields on country change
        this.setProperties(Ember.assign(attrs, {
          city: '',
          state: '',
          zip: ''
        }));
      } else {
        this.setProperties(attrs);
      }

      if (this.get('geolocate') && !fromGeoCode) {
        const address = this.locationAddress();
        this.get('_geocodeAddress').perform(address);
      }

      this.validate().then(({
        validations
      }) => {
        // pass validations to caller to give access to form validations
        if (this.get('validationsChange')) {
          this.get('validationsChange')(validations);
        }

        this.sendAction('addressChange', this.locationProperties()); //eslint-disable-line ember/closure-actions
      });
    },

    _geocodeAddress: (0, _emberConcurrency.task)(function* (address) {
      const data = yield this.get('geocoder').geoLocation(address);
      this.updateLocation({
        lat: data.lat,
        lng: data.lng
      }); // I think , true should be passed here.
    }).drop(),
    _findLocation: (0, _emberConcurrency.task)(function* () {
      let address = yield this.locationAddress();
      return yield this.get('geocoder').geoLocation(address).then(data => {
        let loc = {};
        Object.keys(data).forEach(key => {
          let value = data[key];

          if (typeof value === 'object' && value.short) {
            value = value.short;
          }

          loc[key] = value;
        });
        let updateProps = {
          lat: loc.lat,
          lng: loc.lng
        };
        let props = this.locationProperties(); // update only keys that havent been defined
        // like if a user entered a zip of 46220-103
        // for now, dont rewrite the value to 46220

        Object.keys(props).forEach(key => {
          if (!props[key]) {
            updateProps[key] = loc[key];
          }
        });
        this.setProperties(updateProps);
        this.sendAction('addressChange', this.locationProperties()); //eslint-disable-line ember/closure-actions
      });
    }),
    actions: {
      updateStreet(event) {
        let value = event.target.value;

        if (this.get('street') !== value) {
          let attrs = {
            street: value
          };
          this.updateLocation(attrs);
        }
      },

      updateCountry(country) {
        if (typeof country === 'object') {
          country = country.value;
        }

        this.updateLocation({
          country
        });
      },

      updateAddress(field, val) {
        let attrs = {};
        attrs[field] = val;
        this.updateLocation(attrs);
      },

      updateState(val) {
        this.updateLocation({
          state: val.short
        });
      },

      foundLocation(lat, lng, location, place) {
        let attrs = this.get('geocoder').geocodeParsePlace(place);

        if (attrs['street1'] === attrs['street']) {
          attrs['street1'] = '';
        }

        if (this.get('country') === attrs['short_country']) {
          // dont trigger country update if it hasn't changed
          delete attrs['country'];
        } else {
          attrs['country'] = attrs['short_country'];
        }

        attrs['state'] = attrs['short_state'];
        this.updateLocation(attrs, true);
      }

    }
  });

  _exports.default = _default;
});