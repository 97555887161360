define("@outdoorsyco/ember-switchback/validators/minlength", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.minlength = minlength;
  _exports.default = void 0;

  function minlength(value, minlength) {
    const validatorIsDisabled = !minlength;
    const noValueToValidate = Ember.isEmpty(value);
    const validValue = `${value}`.length >= parseInt(minlength);
    return validatorIsDisabled || noValueToValidate || validValue;
  }

  var _default = {
    param: 'minlength',
    error: {
      key: 'error.validator.minlength',
      message: 'Too short. This must have at least %@ characters.'
    },
    validate: minlength
  };
  _exports.default = _default;
});