define("@outdoorsyco/ember-shared-data/serializers/image", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ImageSerializer extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        categoryName: {
          serialize: false
        }
      });
    }

    serialize(record, options) {
      let data = super.serialize(record, options);
      data.category = {
        slug: data.category_slug
      };
      delete data.category_slug;
      return data;
    }

    normalize(typeClass, hash) {
      if (hash.category) {
        hash.category_slug = hash.category.slug;
        hash.category_name = hash.category.name;
      }

      delete hash.category;
      return super.normalize(typeClass, hash);
    }

    pushPayload(store, payload) {
      let documentHash = {
        data: [],
        included: []
      };

      for (let prop in payload) {
        let modelName = this.modelNameFromPayloadKey(prop);

        if (!store._hasModelFor(modelName)) {
          (false && Ember.warn('You must pass a model', false));
          continue;
        }

        let type = store.modelFor(modelName);
        let typeSerializer = store.serializerFor(type.modelName);
        Ember.makeArray(payload[prop]).forEach(hash => {
          let {
            data,
            included
          } = typeSerializer.normalize(type, hash, prop);
          documentHash.data.push(data);

          if (included) {
            documentHash.included.push(...included);
          }
        });
      }

      store.push(documentHash);
    }

  }

  _exports.default = ImageSerializer;
});