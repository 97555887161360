define("@outdoorsyco/ember-shared-data/services/api-error", ["exports", "@outdoorsyco/ember-shared-data/utils/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // we either have this code littered everywhere we handle an error
  // or we're just posting up a generic error which does not help our clients,
  // or customer support, or engineering once it gets escalated all the way up to
  // us.  let's be kind to everyone and show meaningful error messages.
  let ApiErrorService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ApiErrorService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);
    }

    /**
     * Returns a string with the message returned from the api
     *
     * @param {object} response - Exception thrown during api response
     * @param {string} fallbackMessage - Default message to display, can be a translation key or raw message.
     */
    getMessage(response, fallbackMessage) {
      (false && !(response) && Ember.assert(`You must pass an error response`, response));
      let obj = this.getMessageAndCode(response, fallbackMessage);
      return obj.message;
    }
    /**
     * Returns an object with message and code returned from the api
     *
     * @param {object} response - Exception thrown during api response
     * @param {string} fallbackMessage - Default message to display, can be a translation key or raw message.
     */


    getMessageAndCode(response, fallbackMessage) {
      (false && !(response) && Ember.assert(`You must pass an error response`, response));
      const error = (0, _errors.errorFromResponse)(response);
      let message = error.message;

      if (!error.message && !fallbackMessage.includes(' ')) {
        message = this.intl.t(fallbackMessage);
      }

      return {
        message,
        code: error.code || ''
      };
    }
    /**
     * Creates and throws a new Error object with the name FormSubmit
     *
     * @param {object} response - Exception thrown during api response
     * @param {string} fallbackMessage - Default message to display, can be a translation key or raw message.
     */


    throwFormSubmitError(response, fallbackMessage) {
      let {
        message
      } = this.getMessageAndCode(response, fallbackMessage);
      throw new _errors.FormSubmitError(message);
    }
    /**
     * Uses flash message service to display the api response as danger
     *
     * @param {object} response - Exception thrown during api response
     * @param {string} fallbackMessage - Default message to display, can be a translation key or raw message.
     */


    danger(response, fallbackMessage) {
      let msg = this.getMessage(response, fallbackMessage);
      this.flashMessages.danger(msg);
    }
    /**
     * Uses flash message service to display the api response as warning
     *
     * @param {object} response - Exception thrown during api response
     * @param {string} fallbackMessage - Default message to display, can be a translation key or raw message.
     */


    warning(response, fallbackMessage) {
      let msg = this.getMessage(response, fallbackMessage);
      this.flashMessages.warning(msg);
    }

    isErrorCardDeclined(response) {
      let hasErrors = response && response.errors && response.errors.length;

      if (hasErrors && response.errors[0].code === 'CardDeclined') {
        return true;
      }

      if (response && response.payload && response.payload.code === 'CardDeclined') {
        return true;
      }

      return false;
    }

    isErrorAuthenticationRequired(response) {
      let hasErrors = response && response.errors && response.errors.length;

      if (hasErrors && response.errors[0].code === 'AuthenticationRequired') {
        return true;
      }

      if (response && response.payload && response.payload.code === 'AuthenticationRequired') {
        return true;
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApiErrorService;
});