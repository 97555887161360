define("@outdoorsyco/ember-switchback/components/frame/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "white": "hsl(255, 100%, 100%)",
    "gray200": "hsl(204, 14%, 89%)",
    "red500": "hsl(358, 80%, 58%)",
    "green800": "hsl(172, 50%, 28%)",
    "orange500": "hsl(33, 99%, 57%)",
    "blue500": "hsl(210, 76%, 54%)",
    "raisedGray200": "0 4px 9px -2px  hsla(207, 6%, 23%, 0.1), 0 1px 2px -1px hsla(207, 6%, 23%, 0.06)",
    "raisedGray300": "0 5px 9px -1px  hsla(207, 6%, 23%, 0.1), 0 2px 4px -1px hsla(207, 6%, 23%, 0.06)",
    "raisedGray600": "0 24px 64px 0 hsla(0, 0%, 0%, 0.15)",
    "brand100": "hsl(154, 70%, 94%)",
    "brand800": "hsl(172, 50%, 28%)",
    "Frame": "_Frame_s5x0qu"
  };
  _exports.default = _default;
});