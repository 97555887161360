define("@outdoorsyco/ember-nydus/models/nydus-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    userId: attr('number'),
    provider: attr('string'),
    providerKey: attr('string'),
    providerSecret: attr('string'),
    providerEndpoint: attr('string')
  });

  _exports.default = _default;
});