define("@outdoorsyco/ember-switchback/components/heading/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "font400": "normal normal 400 normal 20px/1.4 -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;",
    "Heading": "_Heading_1xbxyz"
  };
  _exports.default = _default;
});