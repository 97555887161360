define("ember-svg-jar/inlined/current-color-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.7 5.632l-11 11.282c-.2.205-.4.308-.7.308-.3 0-.5-.103-.7-.308l-3-3.077a1.01 1.01 0 010-1.435c.4-.41 1-.41 1.4 0L7 14.76 17.3 4.196c.4-.41 1-.41 1.4 0 .4.41.4 1.026 0 1.436z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});