define("@outdoorsyco/outdoorsy-addon/components/tax-inclusive-help-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "max-width-320": "_max-width-320_1542yv"
  };
  _exports.default = _default;
});