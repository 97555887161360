define("ember-composable-helpers/helpers/includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.includes = includes;
  _exports.default = void 0;

  function includes(needleOrNeedles, haystack) {
    if (!Ember.isArray(haystack)) {
      return false;
    }

    let needles = Ember.isArray(needleOrNeedles) ? needleOrNeedles : [needleOrNeedles];
    let haystackAsEmberArray = Ember.A(haystack);
    return needles.every(needle => {
      return haystackAsEmberArray.includes(needle);
    });
  }

  var _default = Ember.Helper.helper(function ([needle, haystack]) {
    return includes(needle, haystack);
  });

  _exports.default = _default;
});