define("@outdoorsyco/ember-shared-data/models/usage-based-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  let UsageBasedItem = (_dec = belongsTo('user'), _dec2 = belongsTo('tax'), _dec3 = hasMany('tax'), _dec4 = hasMany('usageBasedItemTier', {
    async: false
  }), _dec5 = attr('string'), _dec6 = attr('boolean'), _dec7 = attr('number'), _dec8 = attr('string'), _dec9 = attr('string'), _dec10 = attr('boolean'), _dec11 = attr('string'), _dec12 = attr('momentDate'), _dec13 = attr('momentDate'), _dec14 = Ember.computed.sort('tiers', 'sortOrder'), _dec15 = attr(), _dec16 = Ember.computed.alias('sortedTiers.lastObject.maximum'), _dec17 = Ember.computed('unit'), (_class = class UsageBasedItem extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "owner", _descriptor, this);

      _initializerDefineProperty(this, "taxRate", _descriptor2, this);

      _initializerDefineProperty(this, "taxRates", _descriptor3, this);

      _initializerDefineProperty(this, "tiers", _descriptor4, this);

      _initializerDefineProperty(this, "name", _descriptor5, this);

      _initializerDefineProperty(this, "unlimited", _descriptor6, this);

      _initializerDefineProperty(this, "included", _descriptor7, this);

      _initializerDefineProperty(this, "includedPeriod", _descriptor8, this);

      _initializerDefineProperty(this, "unit", _descriptor9, this);

      _initializerDefineProperty(this, "singleTier", _descriptor10, this);

      _initializerDefineProperty(this, "feeType", _descriptor11, this);

      _initializerDefineProperty(this, "created", _descriptor12, this);

      _initializerDefineProperty(this, "updated", _descriptor13, this);

      _defineProperty(this, "sortOrder", ['minimum']);

      _initializerDefineProperty(this, "sortedTiers", _descriptor14, this);

      _initializerDefineProperty(this, "formatted", _descriptor15, this);

      _initializerDefineProperty(this, "highestTierMaximum", _descriptor16, this);
    }

    /**
     * The minimum fee you can pay if you use this usage item.
     */
    get minimumFee() {
      let tier = this.tiers.find(tier => {
        return tier.minimumFee > 0;
      });

      if (tier) {
        return tier.minimumFee;
      }

      return 0;
    }
    /**
     * If the current model has a single tier of pricing.
     */


    get isSingleTier() {
      return this.tiers.length === 1;
    }

    get shortUnit() {
      let unit = Ember.get(this, 'unit');

      if (unit === 'miles' || unit === 'mile') {
        return 'mi.';
      } else if (unit === 'kilometers' || unit === 'kilometer') {
        return 'km.';
      } else if (unit === 'hours' || unit === 'hour') {
        return 'hrs.';
      }

      return unit;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "taxRate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "taxRates", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tiers", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "unlimited", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "included", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "includedPeriod", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "unit", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "singleTier", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "feeType", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "created", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "updated", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "sortedTiers", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "formatted", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "highestTierMaximum", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "shortUnit", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "shortUnit"), _class.prototype)), _class));
  _exports.default = UsageBasedItem;
});