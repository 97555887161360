define("@outdoorsyco/ember-switchback/styles/max-width", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sm": "576px",
    "md": "768px",
    "lg": "1024px"
  };
  _exports.default = _default;
});