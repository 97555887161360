define("@outdoorsyco/ember-switchback/components/heading/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** @documenter esdoc */

  /**
   * A typographic heading
   *
   * ```handlebars
   *  <Heading @level="2">Heading</Heading>
   * ```
   *
   * @public
   */
  class Heading extends _component.default {
    /**
     * Adjusts the tag we use based on the level provided. Defaults to
     * an H2 heading.
     */
    get computedElement() {
      const level = Ember.isEmpty(this.args.level) ? 2 : this.args.level;
      (false && !(level > 0 && level < 7) && Ember.assert('Level must be a number between 1 and 6', level > 0 && level < 7));
      return `h${level}`;
    }

  }

  _exports.default = Heading;
});