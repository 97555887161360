define("@outdoorsyco/ember-shared-data/components/reports/deposits/batch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WC/O5Lmq",
    "block": "{\"symbols\":[\"&default\",\"&attrs\",\"@page\",\"@dateGT\",\"@dateLT\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"DepositsBatch\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/reports/deposits/batch/styles\"]]]]]],[13,2],[3,\"did-update\",[[23,0,[\"filtersChanged\"]],[23,5,[]],[23,4,[]],[23,3,[]]]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"Table\"],[[28,\"component\",[\"reports/deposits/batch/table\"],[[\"batchEvents\"],[[23,0,[\"batchEvents\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"reports/deposits/batch/table\",[],[[\"@batchEvents\"],[[23,0,[\"batchEvents\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/reports/deposits/batch/template.hbs"
    }
  });

  _exports.default = _default;
});