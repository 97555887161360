define("@outdoorsyco/outdoorsy-addon/components/od-range-slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QMNBmfNw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showLabel\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"od-range-slider-header mbxs\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"placeholder\"]],[28,\"eq\",[[24,[\"start\"]],0],null]],null]],null,{\"statements\":[[0,\"        \"],[1,[22,\"placeholder\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"or\",[[24,[\"start\"]],0],null],false],[1,[28,\"if\",[[28,\"and\",[[24,[\"maxSuffix\"]],[28,\"eq\",[[24,[\"start\"]],[24,[\"max\"]]],null]],null],[24,[\"maxSuffix\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[28,\"and\",[[24,[\"placeholder\"]],[28,\"eq\",[[24,[\"start\"]],0],null]],null]],null]],null,{\"statements\":[[0,\"      \"],[1,[22,\"labelSuffix\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"range-slider\",null,[[\"min\",\"max\",\"animate\",\"connect\",\"start\",\"on-update\",\"on-change\"],[[24,[\"min\"]],[24,[\"max\"]],false,[24,[\"connect\"]],[24,[\"start\"]],[28,\"action\",[[23,0,[]],\"didUpdate\"],null],[28,\"action\",[[23,0,[]],\"didChange\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/od-range-slider/template.hbs"
    }
  });

  _exports.default = _default;
});