define("@outdoorsyco/ember-shared-data/helpers/image-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.imageFormatter = imageFormatter;
  _exports.default = void 0;

  function imageFormatter(url, {
    size = 'featured',
    skipEnhance = false,
    skipWatermark = false,
    category
  }) {
    if (url && url.constructor === Array) {
      if (typeof url[0] === 'string') {
        url = url[0].toString();
      } else {
        url = undefined;
      }
    }

    if (Ember.isBlank(url)) {
      return url;
    }

    if (url.indexOf('res.cloudinary.com') !== -1) {
      return cloudinaryFormat(url, size, skipEnhance, skipWatermark, category);
    } else if (url.indexOf('.com/insecure/') !== -1) {
      return imageProxyFormat(url, size);
    }

    return url;
  }

  function imageProxyFormat(url, size) {
    let base = [];

    switch (size) {
      case 'width1500':
      case 'width1000':
      case 'width750':
      case 'width500':
        base.push('preset:' + size);
        break;

      case 'square750':
      case 'square500':
      case 'square250':
      case 'square125':
      case 'square48':
      case 'avatar':
        base.push('preset:' + size);
        break;

      case 'tinyThumbnail':
        base.push('height:50', 'width:50');
        break;

      case 'listing':
        base.push('height:300', 'width:450');
        break;

      case 'featured':
        base.push('height:600', 'width:900');
        break;

      case 'social':
        base.push('height:630', 'width:1200');
        break;

      case 'masthead':
        base.push('width:2000', 'rt:fit');
        break;

      case 'masthead-back':
        base.push('height:80', 'rt:fit');
        break;

      case 'upload':
        base.push('width:400', 'rt:fit');
        break;
    }

    let enhancements = base.join('/');
    url = url.replace('/insecure', `/insecure/${enhancements}`);
    return url;
  }

  function cloudinaryFormat(url, size, skipEnhance, skipWatermark, category) {
    let base = ['a_exif', 'q_auto', 'f_auto', 'w_auto'];
    let watermarkCode;

    if (!skipEnhance) {
      base.push('e_improve');
    }

    switch (size) {
      case 'width1500':
        base.push('w_1500');
        break;

      case 'width1000':
        base.push('w_1000');
        break;

      case 'width750':
        base.push('w_750');
        break;

      case 'width500':
        base.push('w_500');
        break;

      case 'square750':
        base.push('w_750', 'h_750');
        break;

      case 'square500':
        base.push('w_500', 'h_500');
        break;

      case 'square250':
        base.push('w_250', 'h_250');
        break;

      case 'square125':
        base.push('w_125', 'h_125');
        break;

      case 'square48':
        base.push('w_48', 'h_48');
        break;

      case 'tinyThumbnail':
        base.unshift('t_odw');
        base.push('h_50', 'w_50');
        break;

      case 'thumbnail':
        base.push('h_300', 'w_300');
        break;

      case 'listing':
        base.push('h_300', 'w_450');

        if (!skipWatermark) {
          watermarkCode = 'c_scale,e_overlay,g_south_east,l_outdoorsy-2020,w_100,x_10,y_10';
        }

        break;

      case 'featured':
        base.push('h_600', 'w_900');

        if (!skipWatermark) {
          watermarkCode = 'c_scale,e_overlay,g_south_east,l_outdoorsy-2020,w_150,x_10,y_10';
        }

        break;

      case 'social':
        base.push('h_630', 'w_1200');

        if (!skipWatermark) {
          watermarkCode = 'c_scale,e_overlay,g_south_east,l_outdoorsy-2020,w_150,x_10,y_10';
        }

        break;

      case 'masthead':
        base.push('a_exif,q_auto,f_auto,w_2000,c_limit');
        break;

      case 'masthead-back':
        base.push('q_50,c_fill,e_blur:500,h_80');
        break;

      case 'upload':
        base.push('a_exif,q_auto,f_auto,w_400,c_limit');
        break;
    }

    if (category === 'floorplan') {
      base.push('c_pad');
    } else {
      base.push('c_fill');
    }

    let enhancements = base.join(',');

    if (watermarkCode) {
      enhancements = `${enhancements}/${watermarkCode}`;
    }

    if (size !== 'tinyThumbnail') {
      enhancements = `${enhancements}/t_odw`;
    }

    url = url.replace('/outdoorsy/image/upload', `/outdoorsy/image/upload/${enhancements}`);
    return url;
  }

  var _default = Ember.Helper.extend({
    compute(params, hash) {
      let url = params[0];
      return imageFormatter(url, hash);
    }

  });

  _exports.default = _default;
});