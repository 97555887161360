define("@outdoorsyco/ember-shared-data/serializers/price", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;

  class Price extends JSONAPISerializer {
    keyForAttribute(key) {
      return Ember.String.underscore(key);
    }

    keyForRelationship(key) {
      return Ember.String.underscore(key);
    }

    normalize(typeClass, hash) {
      hash.attributes.id = hash.id;
      return super.normalize(typeClass, hash);
    }

    serialize
    /* snapshot, options */
    () {
      let json = super.serialize(
      /* snapshot, options */
      ...arguments);

      if (json.data.relationships) {
        if (json.data.relationships.rental) {
          json.data.attributes.rental_id = json.data.relationships.rental.data.id;
        }

        delete json.data.relationships;
      }

      if (Ember.isBlank(json.data.attributes.price)) {
        json.data.attributes.price = 0;
      }

      return json;
    }

  }

  _exports.default = Price;
});