define('ember-mobile-pane/components/mobile-pane/pane', ['exports', 'ember-mobile-pane/templates/components/mobile-pane/pane', 'ember-mobile-pane/mixins/component-child'], function (exports, _pane, _componentChild) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentChild.default, {
    layout: _pane.default,

    classNames: ['mobile-pane__pane'],
    classNameBindings: ['isActive:active'],

    // public
    title: null,

    // protected
    activePane: null,
    lazyRendering: true,
    keepRendered: false,
    paneCount: 0,
    visiblePanes: null,

    // private
    didRender: false,

    isActive: Ember.computed('activePane', function () {
      return this === Ember.get(this, 'activePane');
    }),

    renderContent: Ember.computed('lazyRendering', 'keepRendered', 'didRender', 'visiblePanes.@each.{elementId}', 'elementId', function () {
      var _this = this;

      if (Ember.get(this, 'lazyRendering') && !(Ember.get(this, 'keepRendered') && Ember.get(this, 'didRender'))) {
        var willRender = !!Ember.get(this, 'visiblePanes').find(function (item) {
          return Ember.get(item, 'elementId') === Ember.get(_this, 'elementId');
        });

        if (willRender) {
          Ember.set(this, 'didRender', true);
        }

        return willRender;
      } else {
        return true;
      }
    })
  });
});