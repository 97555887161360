define("@outdoorsyco/ember-shared-data/utils/generate-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateOptions = generateOptions;

  /**
   * Generates an array of numbers to be used as options in a select.
   *
   * @param {number} count - How many numeric options to generate
   * @param {number} start - The starting point from which to generate the numbers
   * @param {number} order - An ascending or descending order of the array numbers
   */
  function generateOptions(count, start = 1, order = 'asc') {
    let options = [];

    switch (order) {
      case 'asc':
        for (let i = start; i < count + start; i++) {
          options.push(i.toString());
        }

        break;

      case 'desc':
        for (let i = count + start - 1; i >= start; i--) {
          options.push(i.toString());
        }

        break;
    }

    return options;
  }
});