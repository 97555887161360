define("@outdoorsyco/ember-shared-data/serializers/message", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Message extends _application.default {
    keyForRelationship(key) {
      // images are embedded and therefor are NOT sent with an ID
      if (key === 'files') {
        return key;
      }

      return `${key}_id`;
    } // Model -> what the API expects


    serialize(record, options) {
      let data = super.serialize(record, options);
      data.ref_booking_id = data.booking_id;
      delete data.booking_id;
      delete data.sortable;
      delete data.timestamp;
      return data;
    } // API -> what the model expects


    normalize(typeClass, hash) {
      hash.sortable = hash.id;

      if (hash.ref_booking_id) {
        hash.booking_id = hash.ref_booking_id;
      }

      if (hash.ref_rental_id) {
        hash.rental_id = hash.ref_rental_id;
      }

      delete hash.ref_booking_id;
      delete hash.ref_rental_id;
      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = Message;
});