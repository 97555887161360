define("@outdoorsyco/outdoorsy-addon/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q28zAuJJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"animate\"]]],null,{\"statements\":[[0,\"  \"],[7,\"style\",true],[8],[0,\"\\n    \"],[1,[22,\"animationStyle\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"progress-bar-container \"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"progress-bar \",[28,\"if\",[[28,\"eq\",[[24,[\"value\"]],100],null],\"complete\"],null],\" progress-bar-\",[22,\"uniqueId\"]]]],[11,\"style\",[22,\"progressStyle\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});