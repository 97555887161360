define("ember-svg-jar/inlined/star_green", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"a\" d=\"M15.308 5.941c-.066-.266-.265-.399-.531-.465l-4.252-.6-1.927-3.86c-.2-.466-.997-.466-1.196 0L5.475 4.81l-4.185.666c-.266 0-.466.2-.598.465-.067.267 0 .533.199.666l3.056 2.995-.73 4.26a.634.634 0 00.265.666c.2.133.465.2.73.066L8 12.597l3.787 1.997h.332c.133 0 .266-.066.399-.133.2-.133.332-.4.266-.666l-.731-4.26 3.056-2.995c.2-.133.266-.399.2-.599zM10.857 8.87c-.133.2-.2.4-.2.6l.532 3.26-2.923-1.53a.495.495 0 00-.598 0l-2.924 1.53.598-3.26c0-.2-.066-.4-.199-.6L2.751 6.607l3.256-.466c.2 0 .398-.2.531-.333L8 2.813l1.462 2.929c.066.2.265.333.531.333l3.256.465-2.392 2.33z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><use fill=\"#000\" fill-rule=\"nonzero\" xlink:href=\"#a\"/><g fill=\"#246b61\" mask=\"url(#b)\"><path d=\"M0 0h16v16H0z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});