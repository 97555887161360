define("@outdoorsyco/ember-shared-data/helpers/filter-by-any", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterByAny = filterByAny;
  _exports.default = void 0;

  function filterByAny([byPath, values, array]) {
    return array.filter(item => {
      return values.includes(Ember.get(item, byPath));
    });
  }

  var _default = Ember.Helper.helper(filterByAny);

  _exports.default = _default;
});