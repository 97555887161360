define("@outdoorsyco/ember-shared-data/utils/array/portion", ["exports", "@outdoorsyco/ember-shared-data/utils/array/chunk"], function (_exports, _chunk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = portion;

  function portion(arr, numberOfArrays) {
    return (0, _chunk.default)(arr, Math.ceil(arr.length / numberOfArrays));
  }
});