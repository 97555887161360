define("@outdoorsyco/ember-shared-data/utils/domain-languages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.availableLanguages = availableLanguages;
  _exports.getCountryFromLocale = getCountryFromLocale;
  _exports.getLocaleFromCountry = getLocaleFromCountry;
  _exports.getCountryForDomain = getCountryForDomain;
  _exports.getDomainForCountry = getDomainForCountry;
  _exports.getCurrencyFromLocale = getCurrencyFromLocale;
  _exports.getDistanceUnitFromLocale = getDistanceUnitFromLocale;
  _exports.getCustomerServiceNumberFromHost = getCustomerServiceNumberFromHost;
  _exports.getLanguageFromLocale = getLanguageFromLocale;
  _exports.pendingDomainLanguages = _exports.domainLanguagesArray = _exports.domainLanguages = void 0;
  const domainLanguages = {
    'www.outdoorsy.com': {
      langs: ['en-us'],
      country: 'US',
      currency: 'USD',
      distanceUnit: 'miles'
    },
    'ca.outdoorsy.com': {
      langs: ['en-ca'],
      country: 'CA',
      currency: 'CAD',
      distanceUnit: 'kilometers'
    },
    'ca-fr.outdoorsy.com': {
      langs: ['fr-ca'],
      country: 'CA',
      currency: 'CAD',
      distanceUnit: 'kilometers'
    },
    'www.outdoorsy.com.au': {
      langs: ['en-au'],
      country: 'AU',
      currency: 'AUD',
      distanceUnit: 'kilometers'
    },
    'nz.outdoorsy.com': {
      langs: ['en-nz'],
      country: 'NZ',
      currency: 'NZD',
      distanceUnit: 'kilometers'
    },
    'www.outdoorsy.co.uk': {
      langs: ['en-GB'],
      country: 'GB',
      currency: 'GBP',
      distanceUnit: 'miles'
    },
    'www.outdoorsy.it': {
      langs: ['it-it'],
      country: 'IT',
      currency: 'EUR',
      distanceUnit: 'kilometers'
    },
    'www.outdoorsy.fr': {
      langs: ['fr-FR'],
      country: 'FR',
      currency: 'EUR',
      distanceUnit: 'kilometers'
    },
    'es.outdoorsy.com': {
      langs: ['es-ES'],
      country: 'ES',
      currency: 'EUR',
      distanceUnit: 'kilometers'
    },
    'www.outdoorsy.de': {
      langs: ['de-de'],
      country: 'DE',
      currency: 'EUR',
      distanceUnit: 'kilometers'
    }
  };
  /*
  Customer Service Number
  US 1 877-723-7232
  AU +61 (1800) 319447  in Australia 1800 319 447
  NZ +64 (80) 0437216 in NZ 0800 437 216
  UK +44 (800) 3688399 in UK 0 800 368 8399
  DE +49 (800) 0006334
  IT +39 (800) 582645
  FR +33 (805) 371672
  */

  _exports.domainLanguages = domainLanguages;
  const domainLanguagesArray = [{
    host: 'https://www.outdoorsy.com',
    stagingHost: 'https://www.staging.outdoorsy.com',
    langs: ['en-us'],
    country: 'US',
    languageName: 'English',
    name: 'USA',
    currency: 'USD',
    distanceUnit: 'miles',
    customerServicePhone: '1 877-723-7232'
  }, {
    host: 'https://ca.outdoorsy.com',
    stagingHost: 'https://ca.staging.outdoorsy.com',
    langs: ['en-ca'],
    country: 'CA',
    languageName: 'English',
    name: 'Canada',
    currency: 'CAD',
    distanceUnit: 'kilometers',
    customerServicePhone: '1 877-723-7232'
  }, {
    host: 'https://ca-fr.outdoorsy.com',
    stagingHost: 'https://ca-fr.staging.outdoorsy.com',
    langs: ['fr-ca'],
    country: 'CA',
    languageName: 'Français',
    name: 'Canada',
    currency: 'CAD',
    distanceUnit: 'kilometers',
    customerServicePhone: '1 877-723-7232'
  }, {
    host: 'https://www.outdoorsy.com.au',
    stagingHost: 'https://www.staging.outdoorsy.com.au',
    langs: ['en-au'],
    country: 'AU',
    languageName: 'English',
    name: 'Australia',
    currency: 'AUD',
    distanceUnit: 'kilometers',
    customerServicePhone: '1800 319 447'
  }, {
    host: 'https://nz.outdoorsy.com',
    stagingHost: 'https://nz.staging.outdoorsy.com',
    langs: ['en-nz'],
    country: 'NZ',
    languageName: 'English',
    name: 'New Zealand',
    currency: 'NZD',
    distanceUnit: 'kilometers',
    customerServicePhone: '0800 437 216'
  }, {
    host: 'https://www.outdoorsy.co.uk',
    stagingHost: 'https://odc-lab-uk.herokuapp.com',
    langs: ['en-gb'],
    country: 'GB',
    languageName: 'English',
    name: 'United Kingdom',
    currency: 'GBP',
    distanceUnit: 'miles',
    customerServicePhone: '0800 368 8399'
  }, {
    host: 'https://www.outdoorsy.de',
    stagingHost: 'https://odc-lab-germany.herokuapp.com',
    langs: ['de-de'],
    country: 'DE',
    languageName: 'Deutsch',
    name: 'Germany',
    currency: 'EUR',
    distanceUnit: 'kilometers',
    customerServicePhone: '0800 000 6334'
  }, {
    host: 'https://www.outdoorsy.fr',
    stagingHost: 'https://odc-lab-france.herokuapp.com',
    langs: ['fr-fr'],
    country: 'FR',
    languageName: 'Français',
    name: 'France',
    currency: 'EUR',
    distanceUnit: 'kilometers',
    customerServicePhone: '805 37 16 72'
  }, {
    host: 'https://www.outdoorsy.it',
    stagingHost: 'https://odc-lab-italy.herokuapp.com',
    langs: ['it-it'],
    country: 'IT',
    languageName: 'Italiano',
    name: 'Italy',
    currency: 'EUR',
    distanceUnit: 'kilometers',
    customerServicePhone: '+39 (800) 582645'
  }, {
    host: 'https://es.outdoorsy.com',
    stagingHost: 'https://odc-lab-spain.herokuapp.com',
    langs: ['es-es'],
    country: 'ES',
    languageName: 'Espanol',
    name: 'Spain',
    currency: 'EUR',
    distanceUnit: 'kilometers',
    customerServicePhone: '+34 (800) 0000000'
  }];
  _exports.domainLanguagesArray = domainLanguagesArray;
  const pendingDomainLanguages = [{
    langs: ['es-mx'],
    country: 'MX',
    languageName: 'Spanish',
    name: 'Mexico',
    currency: 'MXN',
    distanceUnit: 'kilometers'
  }, {
    langs: ['pt-pt'],
    country: 'PT',
    languageName: 'Portuguese',
    name: 'Portugal',
    currency: 'EUR',
    distanceUnit: 'kilometers'
  }, {
    langs: ['sv-se'],
    country: 'SE',
    languageName: 'Swedish',
    name: 'Sweden',
    currency: 'SEK',
    distanceUnit: 'kilometers'
  }, {
    langs: ['el-gr'],
    country: 'GR',
    languageName: 'Greek',
    name: 'Greece',
    currency: 'EUR',
    distanceUnit: 'kilometers'
  }, {
    langs: ['nl-nl'],
    country: 'NL',
    languageName: 'Dutch',
    name: 'Netherlands',
    currency: 'EUR',
    distanceUnit: 'kilometers'
  }, {
    langs: ['fi-fi'],
    country: 'FI',
    languageName: 'Finnish',
    name: 'Finland',
    currency: 'EUR',
    distanceUnit: 'kilometers'
  }];
  _exports.pendingDomainLanguages = pendingDomainLanguages;

  function getCountryBlockForHost(host) {
    return domainLanguagesArray.find(country => {
      if (country.host.replace(/https?:\/\//, '') === host || country.stagingHost.replace(/https?:\/\//, '') === host) {
        return country;
      }
    });
  }

  function availableLanguages() {
    return Object.keys(domainLanguages).reduce((arr, domain) => {
      arr = arr.concat(domainLanguages[domain].langs);
      return arr;
    }, []);
  }

  function getCountryFromLocale(locale) {
    let country = 'US';
    domainLanguagesArray.some(domain => {
      let hasLocale = domain.langs.includes(locale);

      if (hasLocale) {
        country = domain.country;
      }

      return hasLocale;
    });
    return country;
  }

  function getLocaleFromCountry(country) {
    let locale = 'en-us';
    Object.keys(domainLanguages).some(domain => {
      let info = domainLanguages[domain];
      let isCountry = info.country === country;

      if (isCountry) {
        locale = info.langs[0];
      }

      return isCountry;
    });
    return locale;
  }

  function getCountryForDomain(host) {
    if (!host) {
      host = window.location.host;
    }

    let country = 'US';
    let countryInfo = getCountryBlockForHost(host);

    if (countryInfo) {
      return countryInfo.country;
    }

    return country;
  }

  function getDomainForCountry(country) {
    let domain = 'www.outdoorsy.com';
    Object.keys(domainLanguages).some(d => {
      let info = domainLanguages[d];
      let isCountry = info.country === country;

      if (isCountry) {
        domain = d;
      }

      return isCountry;
    });
    return domain;
  }

  function getCurrencyFromLocale(locale) {
    let currency = 'USD';
    domainLanguagesArray.some(domain => {
      if (locale === domain.langs[0]) {
        currency = domain.currency;
      }
    });
    return currency;
  }

  function getDistanceUnitFromLocale(locale) {
    let unit = 'miles';
    domainLanguagesArray.some(domain => {
      if (locale === domain.langs[0]) {
        unit = domain.distanceUnit;
      }
    });
    return unit;
  }

  function getCustomerServiceNumberFromHost(host) {
    let customerServicePhone = domainLanguagesArray[0].customerServicePhone;
    let countryInfo = getCountryBlockForHost(host);

    if (countryInfo) {
      return countryInfo.customerServicePhone;
    }

    return customerServicePhone;
  }

  function getLanguageFromLocale(locale) {
    let language;
    locale = locale || 'en-us';
    domainLanguagesArray.concat(pendingDomainLanguages).some(domain => {
      if (locale === domain.langs[0]) {
        language = domain;
      }
    });

    if (!language) {
      return getLanguageFromLocale('en-us');
    }

    return language;
  }
});