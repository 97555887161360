define("@outdoorsyco/ember-shared-data/components/reports/deposits/batch/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kympAtpG",
    "block": "{\"symbols\":[\"event\",\"@adminRequest\",\"@batchEvents\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"table-responsive\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"table table-striped deposits\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"comp-reports-deposits-bank-name\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"comp-reports-deposits-bank-last-four\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"term-type\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"th\",true],[10,\"class\",\"amount\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"comp-reports-deposits-sum-payouts\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"th\",true],[10,\"class\",\"amount\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"comp-reports-deposits-sum-reversals\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"th\",true],[10,\"class\",\"amount\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"comp-reports-deposits-net\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"common-created\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"\\n           \\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"        \"],[5,\"reports/deposits/batch/event\",[],[[\"@event\",\"@adminRequest\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/reports/deposits/batch/table/template.hbs"
    }
  });

  _exports.default = _default;
});