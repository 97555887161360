define("calendar-picker/initializers/cpfb-initializer", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    if (typeof FastBoot === 'undefined') {
      window['moment-range'].extendMoment(_moment.default);
    } else {
      _moment.default.range = () => {};
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});