define("@outdoorsyco/ember-switchback/components/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "doWdCH1d",
    "block": "{\"symbols\":[\"Tag\",\"@color\",\"@maxWidth\",\"&attrs\",\"&default\",\"@tagName\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"-element\",[[28,\"or\",[[23,6,[]],[23,0,[\"tagName\"]],\"span\"],null]],null]],[[\"tagName\"],[[28,\"or\",[[23,6,[]],[23,0,[\"tagName\"]],\"span\"],null]]]]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[[12,\"class\",[29,[[28,\"local-class\",[\"Text\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/text/styles\"]]]]]],[12,\"data-color\",[23,2,[]]],[12,\"data-max-width\",[23,3,[]]],[13,4]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,5],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/text/template.hbs"
    }
  });

  _exports.default = _default;
});