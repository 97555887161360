define("@outdoorsyco/ember-shared-data/components/driver-missing-fields/component", ["exports", "moment", "@outdoorsyco/ember-shared-data/components/driver-missing-fields/template", "ember-concurrency", "ember-cp-validations"], function (_exports, _moment, _template, _emberConcurrency, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    dobMonth: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please provide your date of birth month.'
    }),
    dobDay: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please provide your date of birth day.'
    }),
    dobYear: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please provide your date of birth year.'
    })
  });

  var _default = Ember.Component.extend(Validations, {
    layout: _template.default,
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let passedVars = this.get('attrs.data.value') || {};
      this.driver = passedVars.driver || null;
      this.booking = passedVars.booking || null;
      this.coverageAlert = passedVars.coverageAlert || null;
      this.title = passedVars.title || null;
      this.description = passedVars.description || null;
      const locale = this.get('intl.locale.firstObject') || 'en-us';

      _moment.default.locale(locale);

      this.years = this.driverYears().map(y => y.toString());
      this.days = this.days();
    },

    driverYears() {
      let years = [];
      let firstYear = new Date().getFullYear() - 17;

      for (let i = firstYear; i >= 1925; i--) {
        years.push(i);
      }

      return years;
    },

    days() {
      let count = 31;
      return Array(count).fill().map((v, i) => {
        let counter = i + 1;

        if (counter <= 9) {
          return `0${counter}`;
        }

        return `${counter}`;
      });
    },

    months: Ember.computed('intl.locale', function () {
      return _moment.default.months().map((month, idx) => {
        let monthName = (0, _moment.default)().month(idx).format('MMMM');
        return `${idx + 1} ${monthName}`;
      });
    }),

    bodBirthday() {
      return (0, _moment.default)(`${this.get('dobMonth')}-${this.get('dobDay')}-${this.get('dobYear')}`, 'MMMM-DD-YYYY');
    },

    saveUser: (0, _emberConcurrency.task)(function* () {
      let driver = this.get('driver');

      if (!driver) {
        return;
      }

      let user = yield this.get('store').find('user', driver.id);
      user.set('birthdate', this.bodBirthday().format('YYYY-MM-DD'));
      yield user.save();
      let hasDriversAssocation = this.get('booking.drivers') && typeof this.get('booking.drivers').reload === 'function';

      if (hasDriversAssocation) {
        yield this.get('booking.drivers').reload();
      }

      this.accept();
    }),
    actions: {
      setDateOfBirth(type, value) {
        this.set(type, value);
      },

      saveBirthday() {
        this.validate().then(({
          validations
        }) => {
          this.set('showErrors', true);

          if (validations.get('isValid')) {
            this.get('saveUser').perform();
          }
        });
      }

    }
  });

  _exports.default = _default;
});