define("@outdoorsyco/ember-shared-data/components/form/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZKgUW6bk",
    "block": "{\"symbols\":[\"f\",\"g\",\"&attrs\",\"@buttonLabel\"],\"statements\":[[5,\"form\",[[13,3]],[[\"@onSubmit\"],[[28,\"perform\",[[23,0,[\"onSubmit\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"label\",[[12,\"for\",\"email\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"form-login-label\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"fieldset\",[[12,\"class\",\"  margin-bottom-1 \"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"Row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"text-input\",[[12,\"placeholder\",[28,\"t\",[\"form-signup-email\"],null]],[12,\"aria-label\",[28,\"t\",[\"form-signup-email\"],null]],[12,\"autocomplete\",\"email\"],[12,\"type\",\"email\"]],[[\"@id\",\"@form\",\"@group\",\"@value\",\"@onChange\",\"@requirements\"],[\"email\",[23,1,[]],[23,2,[]],[23,0,[\"email\"]],[28,\"fn\",[[28,\"mut\",[[23,0,[\"email\"]]],null]],null],[28,\"hash\",null,[[\"required\",\"email\"],[true,true]]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,2,[\"Row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"text-input\",[[12,\"placeholder\",[28,\"t\",[\"form-signup-password\"],null]],[12,\"aria-label\",[28,\"t\",[\"form-signup-password\"],null]],[12,\"autocomplete\",\"current-password\"],[12,\"type\",\"password\"]],[[\"@form\",\"@group\",\"@value\",\"@onChange\",\"@requirements\"],[[23,1,[]],[23,2,[]],[23,0,[\"password\"]],[28,\"fn\",[[28,\"mut\",[[23,0,[\"password\"]]],null]],null],[28,\"hash\",null,[[\"required\"],[true]]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\\n  \"],[5,\"button\",[],[[\"@variant\",\"@width\",\"@loading\",\"@type\"],[\"brand-primary\",\"full\",[23,0,[\"onSubmit\",\"isRunning\"]],\"submit\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"or\",[[23,4,[]],[28,\"t\",[\"ui-log-in\"],null]],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/form/login/template.hbs"
    }
  });

  _exports.default = _default;
});