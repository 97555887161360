define("@outdoorsyco/ember-switchback/components/menu-button/option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p+wD8OuZ",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"option\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/menu-button/option/styles\"]]]]]],[12,\"role\",\"menuitem\"],[12,\"tabindex\",\"0\"],[13,1],[3,\"on\",[\"mouseenter\",[23,0,[\"focus\"]]]],[3,\"on\",[\"mouseleave\",[23,0,[\"blur\"]]]],[3,\"on\",[\"keydown\",[23,0,[\"onKeyDown\"]]]],[8],[0,\"\\n\\n  \"],[14,2],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/menu-button/option/template.hbs"
    }
  });

  _exports.default = _default;
});