define("@outdoorsyco/form-components/utils/time-to-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = timeToString;

  function timeToString(value) {
    if (Ember.isBlank(value)) {
      return;
    }

    let hours = Math.floor(value / 60 / 60);
    let ampm = 'am';

    if (hours >= 12) {
      if (hours !== 12) {
        hours = hours - 12;
      }

      ampm = 'pm';
    }

    if (hours === 0) {
      hours = 12;
    }

    let minutes = Math.floor(value / 60 % 60);

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    return `${hours}:${minutes} ${ampm}`;
  }
});