define("calendar-picker/components/od-range-days", ["exports", "moment", "calendar-picker/templates/components/od-range-days", "ember-power-calendar/components/power-calendar-range/days", "calendar-picker/utils/cp-utils"], function (_exports, _moment, _odRangeDays, _days, _cpUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _days.default.extend({
    tagName: 'div',
    layout: _odRangeDays.default,
    disabledDates: Ember.computed('disabledRanges.[]', function () {
      let disabledDates = [];
      let disabledRanges = this.get('disabledRanges');
      let inputFormat = this.get('inputFormat');

      if (disabledRanges) {
        // for each disabled range
        disabledRanges.forEach(dr => {
          disabledDates.push(dr.by('days').map(day => day.format(inputFormat)));
        });
      }

      return [].concat(...disabledDates);
    }),

    buildDay() {
      let day = this._super(...arguments);

      let inputFormat = this.get('inputFormat');
      let momentDay = (0, _moment.default)(day.id, inputFormat).utc().startOf('day');
      let specialRanges = this.get('specialRanges') || []; // Match a special range object

      let sro = specialRanges.find(sp => (0, _cpUtils.normalizeRange)(sp.range).contains(momentDay));

      if (sro) {
        day.name = sro.name;
        day.isSpecial = true;
        day.isDisabled = sro.isDisabled;
        day.class = `special-day ${sro.class}`;

        if (sro.canSameDayPickStart && momentDay.isSame(sro.range.start, 'day')) {
          day.class = `${day.class} same-day-start`;
          day.canSameDayPickStart = sro.canSameDayPickStart;
          day.isSameDay = true;
          day.isDisabled = false;
        }

        if (sro.canSameDayPickEnd && momentDay.isSame(sro.range.end, 'day')) {
          day.class = `${day.class} same-day-end`;
          day.canSameDayPickEnd = sro.canSameDayPickEnd;
          day.isSameDay = true;
          day.isDisabled = false;
        }
      }

      return day;
    }

  });

  _exports.default = _default;
});