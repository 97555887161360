define("@outdoorsyco/ember-shared-data/metrics-adapters/odc-metrics", ["exports", "ember-metrics/metrics-adapters/base", "ember-metrics/utils/can-use-dom", "@outdoorsyco/odc-metrics"], function (_exports, _base, _canUseDom, _odcMetrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    toStringExtension() {
      return 'odc-metrics';
    },

    init() {
      const config = this.get('config');

      if (!config) {
        return;
      }

      const {
        appId,
        debug
      } = config;

      if (_canUseDom.default) {
        Ember.set(window, 'Outdoorsy', window.Outdoorsy || {});
        Ember.set(window, 'Outdoorsy.appId', appId);
        Ember.set(window, 'Outdoorsy.debug', debug);
        Ember.set(window, 'Outdoorsy.metrics', _odcMetrics.default);
      }
    },

    identify(options = {}) {
      const {
        distinctId,
        is_admin: isAdmin
      } = options;

      if (distinctId && _canUseDom.default) {
        let data = {
          type: 'alias',
          uid: distinctId
        };

        if (isAdmin) {
          data.is_admin = isAdmin;
        }

        window.Outdoorsy.metrics.push(data);
      }
    },

    trackEvent(options = {}) {
      if (_canUseDom.default) {
        options.type = 'event';
        window.Outdoorsy.metrics.push(options);
      }
    },

    trackPage() {
      if (_canUseDom.default) {
        window.Outdoorsy.metrics.push({
          type: 'page'
        });
      }
    },

    trackSession(options = {}) {
      if (_canUseDom.default) {
        options.type = 'session';
        window.Outdoorsy.metrics.push(options);
      }
    },

    willDestroy() {
      if (_canUseDom.default) {
        let element = document.querySelector('script[src*="odc-metrics"]');

        if (element) {
          element.remove();
        }

        delete window.Outdoorsy;
      }
    }

  });

  _exports.default = _default;
});