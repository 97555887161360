define("@outdoorsyco/ember-switchback/components/switchback/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uY5n3sy8",
    "block": "{\"symbols\":[],\"statements\":[[5,\"switchback/prompts\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"div\",false],[12,\"id\",\"switchback-select\"],[3,\"did-insert\",[[28,\"action\",[[23,0,[]],[23,0,[\"onDidInsert\"]]],null]]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"switchback-modals\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"switchback-popups\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"switchback-slideups\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/switchback/template.hbs"
    }
  });

  _exports.default = _default;
});