define("@outdoorsyco/ember-shared-data/components/avatar/placeholder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ItzTUFGk",
    "block": "{\"symbols\":[\"@size\",\"@shape\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"avatar\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/avatar/styles\"]]],\"  display-flex background-blue900 border-gray200 \"]]],[12,\"data-size\",[28,\"or\",[[23,1,[]],\"medium\"],null]],[12,\"data-shape\",[28,\"or\",[[23,2,[]],\"round\"],null]],[13,3],[8],[0,\"\\n   \\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/avatar/placeholder/template.hbs"
    }
  });

  _exports.default = _default;
});