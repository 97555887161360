define("@outdoorsyco/ember-shared-data/components/password-strength/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PasswordStrengthComponent extends _component.default {
    // this.args.strength will be null, 1, 2, 3, or 4
    get numberOfBars() {
      const {
        strength
      } = this.args;

      if (strength === null) {
        return 0;
      }

      return (this.args.strength || 0) + 1;
    }

    get bars() {
      return new Array(this.numberOfBars);
    }

  }

  _exports.default = PasswordStrengthComponent;
});