define("@outdoorsyco/ember-shared-data/validator/name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.name = name;
  _exports.default = void 0;

  function name(value) {
    value = value.replace(/^\s+|\s+$/g, '');
    const valueEmpty = Ember.isEmpty(value);
    return !valueEmpty;
  }

  var _default = {
    param: 'nameStrength',
    error: {
      key: 'error.validator.name',
      message: 'Must provide a valid name.'
    },
    validate: name
  };
  _exports.default = _default;
});