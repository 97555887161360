define("@outdoorsyco/ember-switchback/components/textarea-input/footer/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TextareaInputFooter extends _component.default {}

  _exports.default = TextareaInputFooter;
});