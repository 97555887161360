define('ember-experiments/services/experiments', ['exports', 'ember-experiments/utils/is-testing', 'ember-experiments/utils/camelize-name'], function (exports, _isTesting, _camelizeName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;
  exports.default = Ember.Service.extend({
    cookieName: 'ember-experiments',
    cookieMaxAge: 31536000, // default 1 year
    currentExperiments: null,

    cookies: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.getExperiments();
      this.frozenExperiments = {};
      this.isTesting = (0, _isTesting.default)(this);
    },


    /**
     * Optionally set this to an ember concurrency task to handle server-side
     * fetching of experiments, to be looked up in your app code.
     */
    serverFetchTask: null,

    /**
     * Optionally set this to a promise to handle server-side fetching of experiments,
     * to be looked up in your app code
     */
    serverFetchPromise: null,

    /**
     * Define a test and pass in possible variations
     *
     * @param {String} expName The name for your experiment
     * @param {Object} [variations={}] The variations you want to test
     * @returns {Promise}
     */
    setup: function setup(expName) {
      var _this = this;

      var variations = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      return new Ember.RSVP.Promise(function (resolve, reject) {
        // if we don't have an experiment name, we don't know what to setup!
        if (!expName) {
          reject('Missing experiment name');
          return;
        }

        // check if this experiment is already setup
        if (_this.alreadyDefined(expName)) {
          resolve(_this.getVariation(expName));
          return;
        }

        expName = (0, _camelizeName.default)(expName);

        // normalize the variations with a default a/b test
        if (keys(variations).length === 0) {
          variations = {
            control: 50,
            newVariation: 50
          };
        }

        // select the variation we want to use
        var variation = _this._determineVariation(variations);

        // when environment === test us the inTesting variation
        if (_this.isTesting && options.inTesting) {
          variation = options.inTesting;
        }

        _this.enable(expName, variation);
        resolve(variation);
      });
    },


    /**
     * Force a specific experiment to a specific variant
     *
     * @param {String} expName
     * @param {String} variation
     * @param {Object} [options = {}]
     * @param {boolean} [options.freeze = false] - 'true' means experiment can't be overwritten later
     * @returns {Null}
     */
    enable: function enable(expName, variation) {
      var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
          _ref$freeze = _ref.freeze,
          freeze = _ref$freeze === undefined ? false : _ref$freeze;

      if (!expName || !variation) {
        return;
      }

      expName = (0, _camelizeName.default)(expName);

      if (typeof variation === 'string') {
        variation = (0, _camelizeName.default)(variation);
      }

      var experiments = this.getExperiments();

      // Don't reset if frozen
      if (this.frozenExperiments[expName]) {
        return;
      }

      experiments[expName] = variation;
      if (freeze) {
        this.frozenExperiments[expName] = variation;
      }
      this.setExperiments(experiments);
    },


    /**
     * Check if an experiment is currently set to a specified variant
     *
     * @param {String} experimentName
     * @param {String} variationName
     * @returns {Boolean}
     */
    isEnabled: function isEnabled(experimentName, variationName) {
      var experiments = this.getExperiments();
      return experiments[experimentName] === variationName;
    },


    /**
     * Returns the selected variant for a given experiment
     *
     * @param {String} [expName='']
     * @returns {String}
     */
    getVariation: function getVariation() {
      var expName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      expName = (0, _camelizeName.default)(expName);
      return this.getExperiments()[expName];
    },


    /**
     * Tells you if a test has already been setup
     *
     * @param {String} expName
     * @returns {Boolean}
     */
    alreadyDefined: function alreadyDefined(expName) {
      if (!expName) {
        return;
      }

      expName = (0, _camelizeName.default)(expName);
      return typeof this.getExperiments()[expName] !== 'undefined';
    },


    /**
     * Returns a Javascript Object with existing experiments as keys
     * and their selected variants as values
     *
     * @returns {Object}
     */
    getExperiments: function getExperiments() {
      var experiments = this.get('currentExperiments');

      if (experiments) {
        return experiments;
      }

      experiments = this.get('cookies').read(this.cookieName);
      if (!experiments) {
        return this.set('currentExperiments', {});
      }

      try {
        experiments = JSON.parse(decodeURI(experiments));
      } catch (error) {
        experiments = {};
      }

      return this.set('currentExperiments', experiments);
    },


    /**
     * Allows you to force all experiments to provided values
     *
     * @param {Object} [experiments={}]
     */
    setExperiments: function setExperiments() {
      var experiments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this.set('currentExperiments', experiments);
      experiments = encodeURI(JSON.stringify(experiments));

      this.get('cookies').write(this.cookieName, experiments, {
        maxAge: this.cookieMaxAge,
        path: '/'
      });
    },


    /**
     * Clears all experiments
     */
    clearExperiments: function clearExperiments() {
      this.setExperiments();
    },


    /**
     * Internal - Takes a list of variations and weights and determins which variation this specific user gets
     *
     *
     * @param {Object} [variations={}]
     * @returns {String}
     */
    _determineVariation: function _determineVariation() {
      var variations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var variationChoice = Math.floor(Math.random() * 101);
      var sortedVariations = this._sortedVariations(variations);
      var result = sortedVariations.find(function (variation) {
        return variation[1] >= variationChoice;
      });

      if (!result) {
        // this can only really happen if the passed in variant weights don't add up to 100...
        // what were they thinking?!?
        // oh well, select a random variant for the folks
        result = sortedVariations[Math.floor(Math.random() * sortedVariations.length)];
      }

      return result[0];
    },


    /**
     * Internal - Takes a variations object and returns a sorted variations array
     *
     * @param {Object} [variations={}]
     * @returns {Array}
     */
    _sortedVariations: function _sortedVariations() {
      var variations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var sortedVariations = [];
      var currentMax = 0;
      keys(variations).sort().forEach(function (key) {
        var amount = variations[key] || 0;
        currentMax = currentMax + amount;
        sortedVariations.push([key, currentMax]);
      });
      return sortedVariations;
    }
  });
});