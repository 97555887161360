define("@outdoorsyco/ember-shared-data/adapters/proposal", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Proposal extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", 'v0');
    }

    pathForType() {
      return 'bookings/:bookingId/proposals';
    }

    buildURL(modelName, id, snapshot) {
      let url = super.buildURL(modelName, id, snapshot);
      const bookingId = snapshot.belongsTo('booking').id;
      return url.replace(':bookingId', bookingId);
    }

  }

  _exports.default = Proposal;
});