define("@outdoorsyco/outdoorsy-addon/components/truncated-item/component", ["exports", "jquery", "@outdoorsyco/outdoorsy-addon/components/truncated-item/template"], function (_exports, _jquery, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    show: false,
    content: null,
    length: 50,
    scrollIntoPlace: false,
    toggleButton: Ember.computed('content', function () {
      if (this.get('content')) {
        return this.get('content').length > this.get('length');
      }
    }),
    actions: {
      showHide: function showHide() {
        this.toggleProperty('show');

        if (!this.get('show') && this.get('scrollIntoPlace')) {
          this._scrollIntoPlace();
        }

        return false;
      }
    },

    _scrollIntoPlace() {
      (0, _jquery.default)('html, body').animate({
        scrollTop: (0, _jquery.default)(`#${this.get('elementId')}`).offset().top - 40
      }, 10);
    }

  });

  _exports.default = _default;
});