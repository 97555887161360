define("@outdoorsyco/ember-shared-data/components/reset-password/component", ["exports", "@outdoorsyco/ember-shared-data/components/reset-password/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    apiRequest: Ember.inject.service(),
    intl: Ember.inject.service(),
    loading: false,
    error: null,
    actions: {
      passwordReset() {
        let intl = this.get('intl');

        if (!this.get('email')) {
          this.set('error', intl.t('comp-reset-password-validation-enter-valid-email'));
          return;
        }

        this.setProperties({
          error: null,
          loading: true
        });
        this.apiRequest.post('auth/creds/forgot', {
          data: {
            email: this.get('email')
          }
        }).then(() => {
          this.setProperties({
            loading: false,
            status: intl.t('comp-reset-password-emailed-reset-link')
          });
          return this.sendAction('resetSuccess'); //eslint-disable-line ember/closure-actions
        }).catch(response => {
          let error = intl.t('comp-reset-password-error-processing');

          if (response && response.responseJSON) {
            error = response.responseJSON.error;
          }

          return this.setProperties({
            loading: false,
            error
          });
        });
      }

    }
  });

  _exports.default = _default;
});