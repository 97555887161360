define("@outdoorsyco/ember-shared-data/models/review", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  let Review = (_dec = belongsTo('user', {
    async: false
  }), _dec2 = belongsTo('user'), _dec3 = belongsTo('user'), _dec4 = belongsTo('booking'), _dec5 = belongsTo('rental'), _dec6 = hasMany('tag', {
    async: false
  }), _dec7 = hasMany('review-image', {
    async: false
  }), _dec8 = attr('', {
    defaultValue() {
      return [];
    }

  }), _dec9 = attr('string'), _dec10 = attr('boolean'), _dec11 = attr('number', {
    defaultValue: 0
  }), _dec12 = attr('number', {
    defaultValue: 0
  }), _dec13 = attr('number', {
    defaultValue: 0
  }), _dec14 = attr('number', {
    defaultValue: 0
  }), _dec15 = attr('number', {
    defaultValue: 0
  }), _dec16 = attr('number', {
    defaultValue: 0
  }), _dec17 = attr('boolean', {
    defaultValue: true
  }), _dec18 = attr('boolean', {
    defaultValue: false
  }), _dec19 = attr(), _dec20 = attr('boolean'), _dec21 = attr('date'), _dec22 = attr('string'), _dec23 = attr('string'), (_class = class Review extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "reviewer", _descriptor, this);

      _initializerDefineProperty(this, "owner", _descriptor2, this);

      _initializerDefineProperty(this, "user", _descriptor3, this);

      _initializerDefineProperty(this, "booking", _descriptor4, this);

      _initializerDefineProperty(this, "rental", _descriptor5, this);

      _initializerDefineProperty(this, "rentalTags", _descriptor6, this);

      _initializerDefineProperty(this, "reviewImages", _descriptor7, this);

      _initializerDefineProperty(this, "images", _descriptor8, this);

      _initializerDefineProperty(this, "text", _descriptor9, this);

      _initializerDefineProperty(this, "published", _descriptor10, this);

      _initializerDefineProperty(this, "score", _descriptor11, this);

      _initializerDefineProperty(this, "communicationScore", _descriptor12, this);

      _initializerDefineProperty(this, "cleanlinessScore", _descriptor13, this);

      _initializerDefineProperty(this, "mechanicalScore", _descriptor14, this);

      _initializerDefineProperty(this, "valueScore", _descriptor15, this);

      _initializerDefineProperty(this, "listingScore", _descriptor16, this);

      _initializerDefineProperty(this, "userRecommend", _descriptor17, this);

      _initializerDefineProperty(this, "excludeFromRating", _descriptor18, this);

      _initializerDefineProperty(this, "ownerResponse", _descriptor19, this);

      _initializerDefineProperty(this, "ownerCanRespond", _descriptor20, this);

      _initializerDefineProperty(this, "timestamp", _descriptor21, this);

      _initializerDefineProperty(this, "name", _descriptor22, this);

      _initializerDefineProperty(this, "source_page", _descriptor23, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "reviewer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "booking", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rental", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "rentalTags", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "reviewImages", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "images", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "text", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "published", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "score", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "communicationScore", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "cleanlinessScore", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "mechanicalScore", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "valueScore", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "listingScore", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "userRecommend", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "excludeFromRating", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "ownerResponse", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "ownerCanRespond", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "timestamp", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "source_page", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Review;
});