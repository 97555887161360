define("ember-power-select/components/power-select-multiple", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PowerSelectMultiple = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class PowerSelectMultiple extends _component.default {
    get computedTabIndex() {
      if (this.args.triggerComponent === undefined && this.args.searchEnabled) {
        return '-1';
      } else {
        return this.args.tabindex || '0';
      }
    } // Actions


    handleOpen(select, e) {
      if (this.args.onOpen && this.args.onOpen(select, e) === false) {
        return false;
      }

      this.focusInput(select);
    }

    handleFocus(select, e) {
      if (this.args.onFocus) {
        this.args.onFocus(select, e);
      }

      this.focusInput(select);
    }

    handleKeydown(select, e) {
      if (this.args.onKeydown && this.args.onKeydown(select, e) === false) {
        e.stopPropagation();
        return false;
      }

      if (e.keyCode === 13 && select.isOpen) {
        e.stopPropagation();

        if (select.highlighted !== undefined) {
          if (!select.selected || select.selected.indexOf(select.highlighted) === -1) {
            select.actions.choose(select.highlighted, e);
            return false;
          } else {
            select.actions.close(e);
            return false;
          }
        } else {
          select.actions.close(e);
          return false;
        }
      }
    }

    defaultBuildSelection(option, select) {
      let newSelection = (select.selected || []).slice(0);
      let idx = -1;

      for (let i = 0; i < newSelection.length; i++) {
        if (Ember.isEqual(newSelection[i], option)) {
          idx = i;
          break;
        }
      }

      if (idx > -1) {
        newSelection.splice(idx, 1);
      } else {
        newSelection.push(option);
      }

      return newSelection;
    }

    focusInput(select) {
      if (select) {
        let input = document.querySelector(`#ember-power-select-trigger-multiple-input-${select.uniqueId}`);

        if (input) {
          input.focus();
        }
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleOpen", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFocus", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeydown", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeydown"), _class.prototype)), _class)); // import { layout, tagName } from "@ember-decorators/component";
  // import Component from '@ember/component';
  // import { computed, action } from '@ember/object';
  // import { isEqual } from '@ember/utils';
  // import templateLayout from '../templates/components/power-select-multiple';
  // import fallbackIfUndefined from '../utils/computed-fallback-if-undefined';
  // export default @tagName('') @layout(templateLayout) class PowerSelectMultiple extends Component {
  //   @fallbackIfUndefined('power-select-multiple/trigger') triggerComponent
  //   @fallbackIfUndefined(null) beforeOptionsComponent
  //   // CPs
  //   @computed('triggerClass')
  //   get concatenatedTriggerClass() {
  //     let classes = ['ember-power-select-multiple-trigger'];
  //     if (this.triggerClass) {
  //       classes.push(this.triggerClass);
  //     }
  //     return classes.join(' ');
  //   }
  //   @computed
  //   get selected() {
  //     return [];
  //   }
  //   set selected(v) {
  //     if (v === null || v === undefined) {
  //       return [];
  //     }
  //     return v;
  //   }
  //   @computed('tabindex', 'searchEnabled', 'triggerComponent')
  //   get computedTabIndex() {
  //     if (this.triggerComponent === 'power-select-multiple/trigger' && this.args.searchEnabled) {
  //       return '-1';
  //     } else {
  //       return this.args.tabindex || '0';
  //     }
  //   }
  //   // Actions
  //   @action
  //   handleOpen(select, e) {
  //     if (this.onOpen && this.onOpen(select, e) === false) {
  //       return false;
  //     }
  //     this.focusInput(select);
  //   }
  //   @action
  //   handleFocus(select, e) {
  //     if (this.onFocus) {
  //       this.onFocus(select, e);
  //     }
  //     this.focusInput(select);
  //   }
  //   @action
  //   handleKeydown(select, e) {
  //     if (this.onKeydown && this.onKeydown(select, e) === false) {
  //       e.stopPropagation();
  //       return false;
  //     }
  //     if (e.keyCode === 13 && select.isOpen) {
  //       e.stopPropagation();
  //       if (select.highlighted !== undefined) {
  //         if (!select.selected || select.selected.indexOf(select.highlighted) === -1) {
  //           select.actions.choose(select.highlighted, e);
  //           return false;
  //         } else {
  //           select.actions.close(e);
  //           return false;
  //         }
  //       } else {
  //         select.actions.close(e);
  //         return false;
  //       }
  //     }
  //   }
  //   // Methods
  //   @fallbackIfUndefined(function(option, select) {
  //     let newSelection = (select.selected || []).slice(0);
  //     let idx = -1;
  //     for (let i = 0; i < newSelection.length; i++) {
  //       if (isEqual(newSelection[i], option)) {
  //         idx = i;
  //         break;
  //       }
  //     }
  //     if (idx > -1) {
  //       newSelection.splice(idx, 1);
  //     } else {
  //       newSelection.push(option);
  //     }
  //     return newSelection;
  //   })
  //   buildSelection
  //   focusInput(select) {
  //     if (select) {
  //       let input = document.querySelector(`#ember-power-select-trigger-multiple-input-${select.uniqueId}`);
  //       if (input) {
  //         input.focus();
  //       }
  //     }
  //   }
  // }

  _exports.default = PowerSelectMultiple;
});