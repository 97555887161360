define("@outdoorsyco/ember-switchback/services/prompt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PromptService = (_dec = Ember._tracked, (_class = class PromptService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "registry", _descriptor, this);
    }

    /**
     * Shows a prompt to the user and returns a promise. The promise can get resolved or rejected depending on how the user close the modal. It is up to the prompt component to choose what causes a sucessful resolve or a rejection.
     * @param {string} component - The name of the component to render inside the modal
     * @param {any} data - This will be set as the model argument on the prompt component
     * @param {object} options - Any options that should be applied to the modal
     * @property {object} options.maxWidth
     * @property {object} options.minWidth
     * @property {object} options.padding
     **/
    show(component, data, options) {
      let accept, cancel;
      let promise = new Ember.RSVP.Promise(resolve => {
        accept = (...response) => {
          this.removeFromRegistry(prompt);
          resolve({
            status: 'resolved',
            response: response
          });
        };

        cancel = (...response) => {
          this.removeFromRegistry(prompt);
          resolve({
            status: 'rejected',
            response: response
          });
        };
      });
      this.addToRegistry({
        component,
        data,
        options,
        accept,
        cancel
      });
      return promise;
    }
    /**
     * Shows a window asking a polar question.
     * @param {object} data - The content for the prompt
     * @property {string} data.title - Heading you want to show.
     * @property {string} data.[description] - Description to show below the heading.
     * @property {object} data.accept - Describes the accept button
     * @property {string} data.accept.label - Label of the accept button
     * @property {string} data.accept.[variant=default] - Variant of the accept button
     * @property {object} data.cancel - Describes the cancel button
     * @property {string} data.cancel.label - Label of the cancel button
     * @property {string} data.cancel.[variant=default] - Variant of the cancel button
     * @param {object} options - Any options that should be applied to the modal
     * @property {object} options.maxWidth
     * @property {object} options.minWidth
     * @property {object} options.padding
     */


    question(data, options) {
      return this.show('layout/modal/question', data, options);
    }
    /**
     * Shows a window informing the user of information they need to know.
     * @param {object} data - The content for the prompt
     * @property {string} data.title - Heading you want to show.
     * @property {string} data.[description] - Description to show below the heading.
     * @property {object} data.close - Describes the close button
     * @property {string} data.close.label - Label of the close button
     * @property {string} data.close.[variant=default] - Variant of the close button
     * @param {object} options - Any options that should be applied to the modal
     * @property {object} options.maxWidth
     * @property {object} options.minWidth
     * @property {object} options.padding
     */


    inform(data, options) {
      return this.show('layout/modal/inform', data, options);
    }
    /**
     * Adds a prompt to the registry
     * @param {object} prompt - The prompt to add
     * @private
     */


    removeFromRegistry(prompt) {
      let newRegistry = [...this.registry];
      newRegistry.splice(newRegistry.indexOf(prompt), 1);
      this.registry = newRegistry;
    }
    /**
     * Removes a prompt to the registry
     * @param {object} prompt - The prompt to remove
     * @private
     */


    addToRegistry(prompt) {
      let newRegistry = [...this.registry, prompt];
      this.registry = newRegistry;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "registry", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return Ember.A();
    }
  })), _class));
  _exports.default = PromptService;
});