define('ember-experiments/utils/camelize-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = camelizeName;
  function camelizeName(experimentName, variationName) {
    return Ember.String.camelize([experimentName, variationName].join('_'));
  }
});