define("ember-mobile-pane/templates/components/mobile-pane/infinite-scroller/child", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LBF4z7PL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"mobile-pane__child-transformable\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mobile-pane/templates/components/mobile-pane/infinite-scroller/child.hbs"
    }
  });

  _exports.default = _default;
});