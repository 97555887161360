define("@outdoorsyco/ember-switchback/components/radioset/option/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Adds an option to Radioset.
   *
   * @public
   */
  let RadiosetOption = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class RadiosetOption extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "id", `${Ember.guidFor(this)}--radio`);

      _initializerDefineProperty(this, "tabindex", _descriptor, this);
    }

    onDidInsert() {
      if (this.args.registerOption) {
        this.args.registerOption(this);
      }
    }

    willDestroy() {
      super.willDestroy(...arguments);

      if (this.args.unregisterOption) {
        this.args.unregisterOption(this);
      }
    }

    get isSelected() {
      return this.args.value === this.args.radiosetValue;
    }
    /**
     * Updates the value of the Radioset if this option or the
     * parent Radioset is not disabled.
     */


    select() {
      if (this.args.disabled || this.args.radiosetDisabled) {
        return;
      }

      if (this.args.onChange) {
        this.args.onChange(this.args.value);
      }
    }
    /**
     * Handles key presses so space selects this option.
     *
     * @param {event} event - The key press event
     */


    onKeyPress(event) {
      if (event.target !== document.getElementById(this.id)) {
        return;
      }
      /* Space */


      if (event.keyCode === 32) {
        event.preventDefault();
        this.select();
      }
    }
    /**
     * Sets focus to this radio. This does not manage the tabindex
     * and should only be called by the parent Radioset component.
     */


    focus() {
      document.getElementById(this.id).focus();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tabindex", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '-1';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyPress", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyPress"), _class.prototype)), _class));
  _exports.default = RadiosetOption;
});