define("@outdoorsyco/ember-shared-data/components/impersonation-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ozPC23ke",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"impersonated\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"set-body-class\",[\"impersonation-banner-body\"],null],false],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"impersonation-banner\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/impersonation-banner/styles\"]]]]]],[11,\"data-environment\",[23,0,[\"environmentName\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"container\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/impersonation-banner/styles\"]]]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"strong\",true],[10,\"class\",\"  text-semi-bold \"],[8],[0,\"\\n          Logged in as:\\n        \"],[9],[0,\"\\n        \"],[1,[24,[\"session\",\"user\",\"name\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"  text-capitalize \"],[8],[0,\"\\n        \"],[7,\"strong\",true],[10,\"class\",\"  text-semi-bold \"],[8],[0,\"\\n          Environment:\\n        \"],[9],[0,\"\\n        \"],[1,[22,\"environmentName\"],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"a\",true],[11,\"class\",[29,[[28,\"local-class\",[\"link\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/impersonation-banner/styles\"]]]]]],[10,\"href\",\"?incontext=true\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"common-edit-translations\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"link\"],[[\"from\"],[\"@outdoorsyco/ember-shared-data/components/impersonation-banner/styles\"]]]]]],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"logout\"]]]],[8],[0,\"\\n        Exit\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/impersonation-banner/template.hbs"
    }
  });

  _exports.default = _default;
});