define("@outdoorsyco/ember-switchback/components/modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UP89MyLx",
    "block": "{\"symbols\":[\"@maxWidth\",\"&attrs\",\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"switchback-modals\"]],{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"Modal\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/modal/styles\"]]]]]],[3,\"did-insert\",[[23,0,[\"onDidInsert\"]]]],[8],[0,\"\\n\"],[4,\"animated-if\",[[23,0,[\"renderModal\"]]],[[\"use\",\"duration\"],[[23,0,[\"fadeTransition\"]],[23,0,[\"animationDuration\"]]]],{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"overlay\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/modal/styles\"]]]]]],[12,\"data-test-overlay\",\"\"],[12,\"data-enabled\",[28,\"to-string\",[[23,0,[\"closeOnEsc\"]]],null]],[3,\"on\",[\"click\",[23,0,[\"onEsc\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"animated-if\",[[23,0,[\"renderModal\"]]],[[\"use\",\"duration\"],[[23,0,[\"scaleAndFadeTransition\"]],[23,0,[\"animationDuration\"]]]],{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"window\"],[[\"from\"],[\"@outdoorsyco/ember-switchback/components/modal/styles\"]]]]]],[12,\"data-test-modal\",\"\"],[12,\"id\",[23,0,[\"windowId\"]]],[12,\"role\",\"dialog\"],[12,\"aria-modal\",\"true\"],[12,\"aria-labelledby\",[23,0,[\"labelledbyId\"]]],[12,\"data-max-width\",[28,\"or\",[[23,1,[]],\"35\"],null]],[12,\"tabindex\",\"0\"],[13,2],[3,\"on\",[\"keydown\",[23,0,[\"onKeyDown\"]]]],[3,\"trap-focus\"],[3,\"bind-attribute-to-min-width\",null,[[\"attribute\",\"minWidth\"],[\"data-fullscreen\",[23,0,[\"minWidth\"]]]]],[8],[0,\"\\n\\n        \"],[14,3,[[28,\"hash\",null,[[\"close\",\"labelledbyId\"],[[28,\"perform\",[[23,0,[\"hide\"]]],null],[23,0,[\"labelledbyId\"]]]]]]],[0,\"\\n\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-switchback/components/modal/template.hbs"
    }
  });

  _exports.default = _default;
});