define("@outdoorsyco/outdoorsy-addon/utils/location-segment-path", ["exports", "@outdoorsyco/outdoorsy-addon/utils/clean-params", "@outdoorsyco/ember-shared-data/utils/states-provinces"], function (_exports, _cleanParams, _statesProvinces) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = locationSegmentPath;

  function locationSegmentPath(data) {
    data = data || {};
    let loc = [];
    let shortState = (0, _statesProvinces.stateShortFromLong)(data.state, data.country);

    if (data.city) {
      loc.push(data.city);
      loc.push(shortState);
    } else if (data.county) {
      loc.push(data.county);
      loc.push(shortState);
    } else {
      let stateLong = (0, _statesProvinces.stateLongFromShort)(data.state, data.country);
      loc.push(stateLong);
    }

    if (Ember.isPresent(data.country)) {
      let country = (0, _cleanParams.default)(data.country) || '';

      if (country.toLowerCase() !== 'us') {
        loc.push(data.country);
      }
    }

    let filteredLoc = loc.filter(value => Ember.isPresent(value));
    let destination = filteredLoc.map(d => (0, _cleanParams.default)(d)).join('_');
    return destination;
  }
});