define("ember-mobile-pane/templates/components/mobile-pane/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e4HiJx9e",
    "block": "{\"symbols\":[\"navItem\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"nav__scroller\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"navItems\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"mobile-pane/nav/item\",null,[[\"navItem\",\"activePane\",\"onClick\"],[[23,1,[]],[24,[\"activePane\"]],[24,[\"onItemClick\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"nav__indicator\"],[11,\"id\",[29,[[28,\"if\",[[23,0,[\"elementId\"]],[23,0,[\"elementId\"]]],null],\"-nav__indicator\"]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mobile-pane/templates/components/mobile-pane/nav.hbs"
    }
  });

  _exports.default = _default;
});