define("@outdoorsyco/ember-shared-data/models/file", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/helpers/printer-url"], function (_exports, _emberData, _printerUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  let File = (_dec = belongsTo('user'), _dec2 = belongsTo('rental'), _dec3 = belongsTo('booking', {
    inverse: 'files'
  }), _dec4 = belongsTo('user', {
    async: true,
    inverse: null
  }), _dec5 = attr('string'), _dec6 = attr('string'), _dec7 = attr('string'), _dec8 = attr('string'), _dec9 = attr('string'), _dec10 = attr('boolean'), _dec11 = attr('boolean'), _dec12 = attr('boolean'), _dec13 = attr('boolean'), _dec14 = attr('boolean'), _dec15 = attr('boolean'), _dec16 = attr('boolean'), _dec17 = attr(), _dec18 = attr('momentDate'), _dec19 = attr('momentDate'), _dec20 = Ember.computed.equal('type', 'booking_receipt'), _dec21 = Ember.computed('isSignableReceipt', 'url', 'booking.{id,bookingToken}'), (_class = class File extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "user", _descriptor, this);

      _initializerDefineProperty(this, "rental", _descriptor2, this);

      _initializerDefineProperty(this, "booking", _descriptor3, this);

      _initializerDefineProperty(this, "actor", _descriptor4, this);

      _initializerDefineProperty(this, "name", _descriptor5, this);

      _initializerDefineProperty(this, "url", _descriptor6, this);

      _initializerDefineProperty(this, "format", _descriptor7, this);

      _initializerDefineProperty(this, "type", _descriptor8, this);

      _initializerDefineProperty(this, "publicId", _descriptor9, this);

      _initializerDefineProperty(this, "shared", _descriptor10, this);

      _initializerDefineProperty(this, "autoInclude", _descriptor11, this);

      _initializerDefineProperty(this, "includeEsign", _descriptor12, this);

      _initializerDefineProperty(this, "signingComplete", _descriptor13, this);

      _initializerDefineProperty(this, "signingCanceled", _descriptor14, this);

      _initializerDefineProperty(this, "outOfDate", _descriptor15, this);

      _initializerDefineProperty(this, "signable", _descriptor16, this);

      _initializerDefineProperty(this, "signers", _descriptor17, this);

      _initializerDefineProperty(this, "created", _descriptor18, this);

      _initializerDefineProperty(this, "updated", _descriptor19, this);

      _initializerDefineProperty(this, "isSignableReceipt", _descriptor20, this);
    }

    get receiptUrl() {
      let url = this.get('url');

      if (!url) {
        url = (0, _printerUrl.printerUrlHelper)(this, ['bookings'], {
          booking: this.get('booking')
        });

        if (this.get('isSignableReceipt')) {
          url += `&esign=true&text_tags=false`;
        }
      }

      return url;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "user", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rental", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "booking", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "actor", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "format", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "publicId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "shared", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "autoInclude", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "includeEsign", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "signingComplete", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "signingCanceled", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "outOfDate", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "signable", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "signers", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "created", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "updated", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "isSignableReceipt", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "receiptUrl", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "receiptUrl"), _class.prototype)), _class));
  _exports.default = File;
});