define("@outdoorsyco/outdoorsy-addon/components/gdpr-alert/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/gdpr-alert/template", "fetch", "ember-concurrency"], function (_exports, _template, _fetch, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const cookieName = 'gdpr-notice-outdoorsy';

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    cookies: Ember.inject.service(),
    isShowingGDPR: false,

    init() {
      this._super(...arguments);

      this.get('_shouldCookieSet').perform();
    },

    actions: {
      close() {
        this._writeCookie({
          hasGDPR: true,
          isDismissed: true
        });

        this.set('isShowingGDPR', false);
      }

    },
    _shouldCookieSet: (0, _emberConcurrency.task)(function* () {
      let cookie = yield this._getCookie();

      if (!cookie) {
        this.get('_checkGDPRCountry').perform();
        return;
      }

      if (cookie.hasGDPR && cookie.isDismissed === false) {
        this.get('_checkGDPRCountry').perform();
      }
    }),
    _checkGDPRCountry: (0, _emberConcurrency.task)(function* () {
      var _response$headers;

      let url = `https://geoip.outdoorsy.co/encode`;
      let response = yield (0, _fetch.default)(url, {
        dataType: 'text'
      });
      let country = (_response$headers = response.headers) === null || _response$headers === void 0 ? void 0 : _response$headers.map['geo-ip-country'];

      this._shouldShowGDPRAlert(country);
    }),

    _shouldShowGDPRAlert(country) {
      if (euCountries.indexOf(country) >= 0) {
        this.set('isShowingGDPR', true);

        this._writeCookie({
          hasGDPR: true
        });

        return;
      }

      this._writeCookie({
        hasGDPR: false
      });
    },

    _writeCookie({
      hasGDPR = false,
      isDismissed = false
    }) {
      let valueObj = {
        hasGDPR,
        isDismissed
      };
      this.get('cookies').write(cookieName, JSON.stringify(valueObj), {
        path: '/',
        maxAge: 2147483647,
        raw: true
      });
    },

    _getCookie() {
      const rawCookie = this.get('cookies').read(cookieName);

      try {
        const parsedCookie = JSON.parse(rawCookie);
        return parsedCookie;
      } catch (e) {
        return null;
      }
    }

  });

  _exports.default = _default;
  const euCountries = ['BE', // Belgium
  'BG', // Bulgaria
  'CZ', // Czech Republic
  'DK', // Denmark
  'DE', // Germany
  'EE', // Estonia
  'IE', // Ireland
  'EL', // Greece
  'ES', // Spain
  'FR', // France
  'HR', // Croatia
  'IT', // Italy
  'CY', // Cyprus
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'HU', // Hungary
  'MT', // Malta
  'NL', // Netherlands
  'AT', // Austria
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SI', // Slovenia
  'SK', // Slovakia
  'FI', // Finland
  'SE', // Sweden
  'GB', // Great Britain
  'UK' // United Kingdom
  ];
});