define("@outdoorsyco/ember-shared-data/serializers/cancellation-details", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class CancellationDetailsSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        charges: {
          deserialize: 'records',
          serialize: false
        }
      });
    }

    keyForRelationship(key, relationship) {
      /**
       * For some reason the embedded relationships ars coming
       * in as undefined.
       */
      if (!relationship) {
        return Ember.String.underscore(key);
      }

      return super.keyForRelationship(...arguments);
    }

  }

  _exports.default = CancellationDetailsSerializer;
});