define("@outdoorsyco/ember-shared-data/helpers/object-has-key", ["exports", "@outdoorsyco/ember-shared-data/utils/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function objectHasKeyHelper([key, obj]) {
    return (0, _object.objectHasKey)(obj, key);
  });

  _exports.default = _default;
});