define("@outdoorsyco/ember-shared-data/models/premium-service", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  let PremiumService = (_dec = belongsTo('booking', {
    async: true
  }), _dec2 = belongsTo('booking-bundle'), _dec3 = belongsTo('service-request-log', {
    async: false
  }), _dec4 = attr('string'), _dec5 = attr('string'), _dec6 = attr('string'), _dec7 = attr('string'), _dec8 = attr(''), _dec9 = attr('string'), _dec10 = attr(), _dec11 = attr('boolean', {
    defaultValue: false
  }), _dec12 = attr('boolean'), _dec13 = attr('', {
    defaultValue() {
      return {};
    }

  }), _dec14 = attr('centsToDollars'), _dec15 = attr('string'), _dec16 = attr('centsToDollars'), _dec17 = attr('centsToDollars'), _dec18 = attr('centsToDollars'), _dec19 = attr('centsToDollars'), _dec20 = attr('boolean'), _dec21 = attr('number'), _dec22 = attr('string'), _dec23 = attr('number'), _dec24 = attr('simpleDate'), _dec25 = attr('number'), _dec26 = attr('string'), _dec27 = Ember.computed.alias('booking.owner.dealer'), _dec28 = Ember.computed.not('qualified'), _dec29 = Ember.computed, _dec30 = Ember.computed('premiumAmount', 'price'), _dec31 = Ember.computed('premiumTotal', 'total'), _dec32 = Ember.computed, _dec33 = Ember.computed, _dec34 = Ember.computed, (_class = class PremiumService extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "booking", _descriptor, this);

      _initializerDefineProperty(this, "bundle", _descriptor2, this);

      _initializerDefineProperty(this, "lastServiceLog", _descriptor3, this);

      _initializerDefineProperty(this, "name", _descriptor4, this);

      _initializerDefineProperty(this, "slug", _descriptor5, this);

      _initializerDefineProperty(this, "category", _descriptor6, this);

      _initializerDefineProperty(this, "description", _descriptor7, this);

      _initializerDefineProperty(this, "taxRates", _descriptor8, this);

      _initializerDefineProperty(this, "externalReferenceId", _descriptor9, this);

      _initializerDefineProperty(this, "missingFields", _descriptor10, this);

      _initializerDefineProperty(this, "required", _descriptor11, this);

      _initializerDefineProperty(this, "qualified", _descriptor12, this);

      _initializerDefineProperty(this, "serviceData", _descriptor13, this);

      _initializerDefineProperty(this, "price", _descriptor14, this);

      _initializerDefineProperty(this, "priceType", _descriptor15, this);

      _initializerDefineProperty(this, "taxAmount", _descriptor16, this);

      _initializerDefineProperty(this, "total", _descriptor17, this);

      _initializerDefineProperty(this, "premiumAmount", _descriptor18, this);

      _initializerDefineProperty(this, "premiumTotal", _descriptor19, this);

      _initializerDefineProperty(this, "daily", _descriptor20, this);

      _initializerDefineProperty(this, "percent", _descriptor21, this);

      _initializerDefineProperty(this, "percentType", _descriptor22, this);

      _initializerDefineProperty(this, "serviceId", _descriptor23, this);

      _initializerDefineProperty(this, "created", _descriptor24, this);

      _initializerDefineProperty(this, "quantity", _descriptor25, this);

      _initializerDefineProperty(this, "chargeTo", _descriptor26, this);

      _initializerDefineProperty(this, "isDealer", _descriptor27, this);

      _initializerDefineProperty(this, "notQualified", _descriptor28, this);
    }

    get bundleId() {
      return this.belongsTo('bundle').id();
    }

    get priceWithPremium() {
      return this.get('premiumAmount') + this.get('price');
    }

    get totalWithoutPremium() {
      return this.get('total') - this.get('premiumTotal');
    }

    get renterPriceType() {
      return this.get('daily') ? 'daily' : 'flat';
    }

    get suppportPhone() {
      return this.get('serviceData.phone');
    }

    get durationType() {
      return this.get('serviceData.duration_type');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "booking", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bundle", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lastServiceLog", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "taxRates", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "externalReferenceId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "missingFields", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "required", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "qualified", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "serviceData", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "price", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "priceType", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "taxAmount", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "total", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "premiumAmount", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "premiumTotal", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "daily", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "percent", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "percentType", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "serviceId", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "created", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "quantity", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "chargeTo", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "isDealer", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "notQualified", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "bundleId", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "bundleId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "priceWithPremium", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "priceWithPremium"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalWithoutPremium", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "totalWithoutPremium"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renterPriceType", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "renterPriceType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "suppportPhone", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "suppportPhone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "durationType", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "durationType"), _class.prototype)), _class));
  _exports.default = PremiumService;
});