define("ember-multiselect-checkboxes/templates/components/multiselect-checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O1haN27E",
    "block": "{\"symbols\":[\"checkbox\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"checkboxes\"]]],null,{\"statements\":[[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"    \"],[14,3,[[23,1,[\"option\"]],[23,1,[\"isSelected\"]],[23,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[23,1,[\"isSelected\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n        \"],[1,[23,1,[\"label\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-multiselect-checkboxes/templates/components/multiselect-checkboxes.hbs"
    }
  });

  _exports.default = _default;
});