define("ember-google-maps/utils/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computedPromise = computedPromise;
  _exports.position = position;
  _exports.promisify = promisify;

  function position() {
    const {
      lat,
      lng
    } = Ember.getProperties(this, 'lat', 'lng');
    return lat && lng ? new google.maps.LatLng(lat, lng) : undefined;
  }

  let ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  function computedPromise(...args) {
    let func = args.pop();
    return Ember.computed(...args, function () {
      return ObjectPromiseProxy.create({
        promise: func.apply(this)
      });
    });
  }

  function promisify(maybePromise) {
    return maybePromise instanceof Ember.RSVP.Promise ? maybePromise : Ember.RSVP.resolve(maybePromise);
  }
});