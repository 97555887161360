define("@outdoorsyco/ember-switchback/validators/maxlength", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.maxlength = maxlength;
  _exports.default = void 0;

  function maxlength(value, maxlength) {
    const validatorIsDisabled = !maxlength;
    const noValueToValidate = Ember.isEmpty(value);
    const validValue = `${value}`.length <= parseInt(maxlength);
    return validatorIsDisabled || noValueToValidate || validValue;
  }

  var _default = {
    param: 'maxlength',
    error: {
      key: 'error.validator.maxlength',
      message: "Too long. This can't be more than %@ characters."
    },
    validate: maxlength
  };
  _exports.default = _default;
});