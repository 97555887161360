define("@outdoorsyco/ember-shared-data/services/card-tokens", ["exports", "ember-test-waiters"], function (_exports, _emberTestWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apiRequest: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    logger: Ember.computed(function () {
      return Ember.getOwner(this).lookup('logger:outdoorsy');
    }),
    // not all apps have stripev3 yet
    stripev3: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:stripev3');
    }),
    // this exists to allow it to be overridden by tests to mock stripe
    stripe: Ember.computed(function () {
      return window.Stripe;
    }).volatile(),

    appConfig() {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    },

    setupStripe(country) {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');

      try {
        const stripeConfig = config.stripe || {}; // try server provided publish key first
        // if it doesn't exist, try to pull key from country
        // if it doesn't exist, default to US pub key

        const key = stripeConfig.serverProvidedStripeKey || stripeConfig.countries[country] || stripeConfig.publishableKey;
        return this.stripev3.load(key);
      } catch (error) {
        this.logger.error(error);
      }
    },

    saveCards(userId, cards, paymentProcessor = {}) {
      paymentProcessor.name = paymentProcessor.name || 'finance';
      paymentProcessor.country = paymentProcessor.country || 'US';
      cards = Ember.makeArray(cards);
      let promises = [];
      cards.forEach(cardToken => {
        let promise = this.get('store').findRecord('user', userId).then(user => {
          let card = this.get('store').createRecord('card', {
            owner: user,
            stripeToken: cardToken,
            paymentProcessor
          });
          return card.save();
        });
        promises.push(promise);
      });
      return Ember.RSVP.all(promises);
    },

    generateBankTokenV2(info = {}) {
      this._setCountryPublishableKey(info.country);

      return new Ember.RSVP.Promise((resolve, reject) => {
        this.stripe.bankAccount.createToken(info, (status, response) => {
          if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        });
      });
    },

    generatePiiTokenV2(info = {}) {
      this._setCountryPublishableKey(info.country);

      return new Ember.RSVP.Promise((resolve, reject) => {
        this.stripe.piiData.createToken({
          personal_id_number: info.personal_id_number
        }, (status, response) => {
          if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        });
      });
    },

    async generateBankToken(info = {}) {
      if (this.stripe.version === 2) {
        return this.generateBankTokenV2(info);
      }

      const publishableKey = this._setCountryPublishableKey(info.country);

      const stripeInstance = new this.stripe(publishableKey);
      const {
        token,
        error
      } = await stripeInstance.createToken('bank_account', info);

      if (error) {
        const stripeError = new Error(error.message);
        stripeError.type = error.type;
        stripeError.name = 'StripeError';
        throw stripeError;
      }

      return token;
    },

    async generatePiiToken(info = {}) {
      if (this.stripe.version === 2) {
        return this.generatePiiTokenV2(info);
      }

      const publishableKey = this._setCountryPublishableKey(info.country);

      const stripeInstance = new this.stripe(publishableKey);
      const {
        token,
        error
      } = await stripeInstance.createToken('pii', {
        personal_id_number: info.personal_id_number
      });

      if (error) {
        const stripeError = new Error(error.message);
        stripeError.type = error.type;
        stripeError.name = 'StripeError';
        throw stripeError;
      }

      return token;
    },

    async generateTokenFromStripeElement(stripeElement, options) {
      const response = await this.stripev3.createToken(stripeElement, options);

      if (response.error) {
        const error = response.error;
        const errorObject = new Error(error.message);
        errorObject.code = error.code;
        errorObject.type = error.type;
        throw errorObject;
      }

      return response.token.id;
    },

    generateToken(cardInfo, options = {}) {
      let error = this._validateCardInfo(cardInfo);

      if (error) {
        return Ember.RSVP.reject(error);
      }

      this._setCountryPublishableKey(options.country);

      return (0, _emberTestWaiters.waitForPromise)(new Ember.RSVP.Promise((resolve, reject) => {
        this.stripe.card.createToken({
          number: cardInfo.number,
          cvc: cardInfo.cvc,
          exp_month: cardInfo.expMonth,
          exp_year: cardInfo.expYear,
          address_zip: cardInfo.zip,
          name: cardInfo.name,
          address_line1: cardInfo.street,
          address_line2: cardInfo.street_etc,
          address_city: cardInfo.city,
          address_state: cardInfo.state,
          address_country: cardInfo.country
        }, function (status, response) {
          if (response.error) {
            reject(response);
          } else {
            resolve(response.id);
          }
        });
      }));
    },

    generateTokens(cardInfo, tokenCount = 1, options = {}) {
      let promises = [];

      if (options.saveCard) {
        tokenCount += 1;
      }

      let error = this._validateCardInfo(cardInfo);

      if (error) {
        return Ember.RSVP.reject(error);
      }

      this._setCountryPublishableKey(options.paymentProcessor.country);

      for (let i = 0; i < tokenCount; i++) {
        promises.push(this._getToken(cardInfo));
      }

      return (0, _emberTestWaiters.waitForPromise)(Ember.RSVP.all(promises).then(tokens => {
        if (options.saveCard && options.saveCardUserId) {
          let saveToken = tokens.pop();
          this.saveCards(options.saveCardUserId, saveToken, options.paymentProcessor);
        }

        return tokens;
      }));
    },

    _setCountryPublishableKey(country = 'US') {
      let stripeConfig = this.appConfig().stripe;

      if (!stripeConfig) {
        return;
      } // try server provided publish key first
      // if it doesn't exist, try to pull key from country
      // if it doesn't exist, default to US pub key (try v2 'key', then v3 'publishableKey')


      let key = stripeConfig.serverProvidedStripeKey || stripeConfig.countries[country] || stripeConfig.key || stripeConfig.publishableKey; // stripe v3 does not support setting publishable key this way

      this.stripe.setPublishableKey && this.stripe.setPublishableKey(key);
      return key;
    },

    resetServerProvidedToken() {
      let stripeConfig = this.appConfig().stripe || {};
      stripeConfig.serverProvidedStripeKey = undefined;
    },

    _validateCardInfo(cardInfo) {
      let error = false;
      ['number', 'expMonth', 'expYear', 'cvc'].some(key => {
        if (Ember.isBlank(cardInfo[key])) {
          error = `Card ${key} is required.`;
        }

        return error;
      });
      return error;
    },

    _getToken(cardInfo) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.stripe.card.createToken({
          number: cardInfo.number,
          cvc: cardInfo.cvc,
          exp_month: cardInfo.expMonth,
          exp_year: cardInfo.expYear,
          address_zip: cardInfo.zip,
          name: cardInfo.name
        }, (status, response) => {
          if (response.error) {
            reject(response.error.message);
          } else {
            resolve(response.id);
          }
        });
      });
    }

  });

  _exports.default = _default;
});