define("@outdoorsyco/ember-shared-data/models/bundle-rule", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  let BundleRule = (_dec = belongsTo('bundle'), _dec2 = attr('string', {
    defaultValue: 'pricing'
  }), _dec3 = attr('', {
    defaultValue() {
      return {};
    }

  }), _dec4 = attr('centsToDollars'), _dec5 = attr('centsToDollars'), _dec6 = attr('centsToDollars'), _dec7 = attr('centsToDollars'), _dec8 = attr('centsToDollars'), _dec9 = attr('number'), _dec10 = attr('number'), _dec11 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec12 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec13 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec14 = attr('number', {
    defaultValue: 0
  }), _dec15 = attr('number'), _dec16 = attr('date'), _dec17 = attr('date'), _dec18 = Ember.computed('ownerId'), (_class = class BundleRule extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "bundle", _descriptor, this);

      _initializerDefineProperty(this, "type", _descriptor2, this);

      _initializerDefineProperty(this, "rule", _descriptor3, this);

      _initializerDefineProperty(this, "rate", _descriptor4, this);

      _initializerDefineProperty(this, "charge", _descriptor5, this);

      _initializerDefineProperty(this, "deductible", _descriptor6, this);

      _initializerDefineProperty(this, "minValue", _descriptor7, this);

      _initializerDefineProperty(this, "maxValue", _descriptor8, this);

      _initializerDefineProperty(this, "minLength", _descriptor9, this);

      _initializerDefineProperty(this, "maxLength", _descriptor10, this);

      _initializerDefineProperty(this, "vehicleTypes", _descriptor11, this);

      _initializerDefineProperty(this, "excludeVehicleTypes", _descriptor12, this);

      _initializerDefineProperty(this, "regions", _descriptor13, this);

      _initializerDefineProperty(this, "ownerId", _descriptor14, this);

      _initializerDefineProperty(this, "rentalId", _descriptor15, this);

      _initializerDefineProperty(this, "created", _descriptor16, this);

      _initializerDefineProperty(this, "updated", _descriptor17, this);
    }

    get isOwnerRule() {
      return typeof this.get('ownerId') === 'number' && this.get('ownerId') !== 0;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bundle", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rule", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "rate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "charge", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "deductible", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "minValue", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "maxValue", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "minLength", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "maxLength", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "vehicleTypes", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "excludeVehicleTypes", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "regions", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "ownerId", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "rentalId", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "created", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "updated", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isOwnerRule", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "isOwnerRule"), _class.prototype)), _class));
  _exports.default = BundleRule;
});