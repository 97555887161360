define("@outdoorsyco/outdoorsy-addon/components/truncated-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4O/O2Ncr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"content\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"truncate\",[[24,[\"content\"]],[24,[\"length\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[24,[\"toggleButton\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",\"btn btn-link btn-link-inherit\"],[12,\"data-test-truncate-item-action\",\"\"],[3,\"action\",[[23,0,[]],\"showHide\"]],[8],[0,\"\\n    \"],[1,[28,\"if\",[[24,[\"show\"]],[28,\"t\",[\"ui-read-less\"],null],[28,\"t\",[\"ui-read-more\"],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/outdoorsy-addon/components/truncated-item/template.hbs"
    }
  });

  _exports.default = _default;
});