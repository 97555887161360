define("@outdoorsyco/ember-shared-data/components/provider/verify-id/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k99Ud8JI",
    "block": "{\"symbols\":[\"&attrs\",\"@id\",\"&default\"],\"statements\":[[7,\"div\",false],[13,1],[3,\"did-insert\",[[28,\"fn\",[[28,\"perform\",[[23,0,[\"verifyId\",\"setupVerification\"]]],null],[23,2,[]]],null]]],[8],[0,\"\\n  \"],[14,3,[[28,\"hash\",null,[[\"isVerificationLoading\",\"isPersonaValidator\",\"verifyViaPersona\"],[[23,0,[\"verifyId\",\"isVerificationLoading\"]],[28,\"eq\",[[23,0,[\"verifyId\",\"idVerification\",\"validator\"]],\"persona\"],null],[23,0,[\"verifyViaPersona\"]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/provider/verify-id/template.hbs"
    }
  });

  _exports.default = _default;
});