define("@outdoorsyco/ember-shared-data/validator/min-deposit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.minDeposit = minDeposit;
  _exports.default = void 0;

  function minDeposit(value, minDeposit) {
    const noValueToValidate = Ember.isEmpty(value); //minDeposit = $1,500 or 500£ or 1,500€ ==> 1500 or 500

    minDeposit = minDeposit.replace(/[^\d]/g, '');
    const validValue = String(value).match(/[0-9]/) && value >= parseInt(minDeposit);
    return noValueToValidate || validValue;
  }

  var _default = {
    param: 'minDeposit',
    error: {
      key: 'error.validator.minDeposit',
      message: 'The minimum deposit is %@'
    },
    validate: minDeposit
  };
  _exports.default = _default;
});