define("@outdoorsyco/ember-shared-data/utils/payouts-processors/plaid", ["exports", "@outdoorsyco/ember-shared-data/utils/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /* global Plaid */
  async function _default(authorizer, {
    appName = 'outdoorsy',
    userLegalName = 'enabled',
    depositLinkToken = undefined,
    userId = undefined,
    onLoad: _onLoad = () => {},
    onEvent: _onEvent = () => {},
    logger = undefined
  }) {
    if (!window.Plaid) {
      await loadPlaid();
    }

    return new Ember.RSVP.Promise((resolve, reject) => {
      var handler = Plaid.create({
        clientName: appName,
        linkCustomizationName: appName,
        env: authorizer.environment,
        key: authorizer.authorizer_public_key,
        // enables or disables penny flow test
        userLegalName: userLegalName,
        // Launches into mico deposit authorization flow
        token: depositLinkToken,
        // Include auth to enable Instant Match or Automated Microdeposits
        // For Same Day Microdeposits, auth must be the the only product.
        product: ['auth'],
        // To enable auth flows beyond Instant Auth,
        // countryCodes must be set to US.
        // We need microdeposits, so we are setting US. We will
        // need to look at this again when we expand Plaid beyond the US.
        countryCodes: ['US'],
        // set accountSubtypes show only institutions with
        // these following account subtypes and return accounts
        // of the following account subtypes.
        // given that we are only interested in accounts that
        // can function as a deposit/withdrawal source for our
        // payouts, we restrict to checking and savings
        accountSubtypes: {
          depository: ['checking', 'savings']
        },
        // Optional – use webhooks to get transaction and error updates
        // webhook: 'https://request-bin-thunderstorm.herokuapp.com/1584t3o1',
        onLoad: function onLoad() {
          _onLoad(); // Optional, called when Link loads

        },
        onSuccess: function onSuccess(public_token, metadata) {
          // Send the public_token to your app server.
          // The metadata object contains info about the institution the
          // user selected and the account ID or IDs, if the
          // Select Account view is enabled.
          resolve({
            public_token,
            metadata
          });
        },
        onExit: function onExit(error, metadata) {
          reject(error); // The user exited the Link flow.

          if (error !== null && logger) {
            // The user encountered a Plaid API error prior to exiting.
            let expectedErrorCodes = ['INVALID_CREDENTIALS', 'INCORRECT_DEPOSIT_AMOUNTS'];

            if (!expectedErrorCodes.includes(error.error_code)) {
              logger.debug('plaid error', {
                error,
                metadata,
                userId
              });
            }
          } // metadata contains information about the institution
          // that the user selected and the most recent API request IDs.
          // Storing this information can be helpful for support.

        },
        onEvent: function onEvent(eventName, metadata) {
          _onEvent({
            eventName,
            metadata
          }); // Optionally capture Link flow events, streamed through
          // this callback as your users connect an Item to Plaid.
          // For example:
          // eventName = "TRANSITION_VIEW"
          // metadata  = {
          //   link_session_id: "123-abc",
          //   mfa_type:        "questions",
          //   timestamp:       "2017-09-14T14:42:19.350Z",
          //   view_name:       "MFA",
          // }

        }
      });
      handler.open();
    });
  }

  function loadPlaid() {
    return (0, _loadScript.default)({
      src: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
      async: false
    });
  }
});