define("@outdoorsyco/outdoorsy-addon/components/progress-bar/component", ["exports", "@outdoorsyco/outdoorsy-addon/components/progress-bar/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    value: 50,
    animate: false,
    progressStyle: Ember.computed('value', function () {
      let value = this.get('value');
      return Ember.String.htmlSafe(`width: ${value}%`);
    }),
    uniqueId: Ember.computed(function () {
      return Math.round(Math.random() * 1000000, 0);
    }),
    animationStyle: Ember.computed('value', function () {
      return Ember.String.htmlSafe(`
.progress-bar-${this.get('uniqueId')} {
  animation: animatedBackground-${this.get('uniqueId')} 2s linear;
}

@keyframes animatedBackground-${this.get('uniqueId')} {
  0% { width: 0; }
  100% { ${this.get('progressStyle')}; }
}`);
    })
  });

  _exports.default = _default;
});