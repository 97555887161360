define("@outdoorsyco/ember-shared-data/utils/errors", ["exports", "ember-fetch/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notOkError = notOkError;
  _exports.FormSubmitError = _exports.errorFromResponse = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class FetchError extends Error {
    constructor({
      message,
      name,
      payload,
      errors,
      text,
      status
    }) {
      super(message);
      this.name = name;
      this.payload = payload;
      this.errors = errors;
      this.text = text;
      this.status = status;
    }

    get responseJSON() {
      (false && !(false) && Ember.assert(`errorResponse.responseJSON is deprecated.  Use errorResponse.payload`));
      return this.payload;
    }

  }

  async function notOkError(response, {
    logger,
    isHead = false
  } = {}) {
    let message, name, payload, errors, text;

    if ((0, _errors.isBadRequestResponse)(response)) {
      // 400
      message = 'Request was formatted incorrectly.';
      name = 'BadRequestError';
    } else if ((0, _errors.isUnauthorizedResponse)(response)) {
      // 401
      message = 'Authorization failed.';
      name = 'UnauthorizedError';
    } else if ((0, _errors.isForbiddenResponse)(response)) {
      // 403
      message = 'Request was rejected because user is not permitted to perform this operation.';
      name = 'ForbiddenError';
    } else if ((0, _errors.isNotFoundResponse)(response)) {
      // 404
      message = 'Resource was not found.';
      name = 'NotFoundError';
    } else if ((0, _errors.isServerErrorResponse)(response)) {
      // 50x
      message = 'Request was rejected due to server error.';
      name = 'ServerError';
    } else {
      message = 'Unknown error';
    }

    try {
      const contentType = response.headers.get('content-type');
      const hasJSON = !isHead && contentType && contentType.includes('application/json');

      if (hasJSON) {
        payload = await response.json(); // if the response follows JSON API spec, will have { errors: [{ title: ... }] }

        if (Ember.get(payload, 'errors.firstObject.title')) {
          errors = payload.errors;
        }
      } else {
        text = await response.text();
      }
    } catch (error) {
      logger && logger.error(error);
    }

    const fetchError = new FetchError({
      message,
      name,
      payload,
      errors,
      text,
      status: response.status
    });
    return fetchError;
  }

  const errorFromResponse = response => {
    let message, code;

    if (response.errors && response.errors.length) {
      let err = response.errors[0];

      if (err.code === 'ENOTHTTP') {
        message = err.original_error;
      } else {
        message = `${err.title} ${err.detail}`;
      }

      code = err.code;
    } else if (response.payload && response.payload.original_error && response.payload.original_error.indexOf('{') === -1) {
      message = response.payload.original_error;
    } else if (response.payload && response.payload.error) {
      message = response.payload.error;
      code = response.payload.code;
    } else if (response.message) {
      message = response.message;
    } else if (response.original_error) {
      message = response.original_error;
    }

    return {
      message,
      code
    };
  };

  _exports.errorFromResponse = errorFromResponse;

  class FormSubmitError extends Error {
    constructor() {
      super(...arguments);

      _defineProperty(this, "name", 'FormSubmit');
    }

  }

  _exports.FormSubmitError = FormSubmitError;
});