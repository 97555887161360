define("@outdoorsyco/ember-shared-data/utils/countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.abbreviationFromCountry = abbreviationFromCountry;
  _exports.countryFromAbbreviation = countryFromAbbreviation;
  _exports.euCountries = _exports.stripePayoutCountryAbbreviations = _exports.countries = void 0;
  const countries = [{
    value: 'US',
    display: 'United States'
  }, {
    value: 'AL',
    display: 'Albania'
  }, {
    value: 'AN',
    display: 'Netherlands Antilles'
  }, {
    value: 'AR',
    display: 'Argentina'
  }, {
    value: 'AM',
    display: 'Armenia'
  }, {
    value: 'AU',
    display: 'Australia'
  }, {
    value: 'AT',
    display: 'Austria'
  }, {
    value: 'BE',
    display: 'Belgium'
  }, {
    value: 'BD',
    display: 'Bangladesh'
  }, {
    value: 'BG',
    display: 'Bulgaria'
  }, {
    value: 'BS',
    display: 'Bahamas'
  }, {
    value: 'BL',
    display: 'Saint Barthélemy'
  }, {
    value: 'BZ',
    display: 'Belize'
  }, {
    value: 'BM',
    display: 'Bermuda'
  }, {
    value: 'BR',
    display: 'Brazil'
  }, {
    value: 'BB',
    display: 'Barbados'
  }, {
    value: 'CA',
    display: 'Canada'
  }, {
    value: 'CH',
    display: 'Switzerland'
  }, {
    value: 'CL',
    display: 'Chile'
  }, {
    value: 'CN',
    display: 'China'
  }, {
    value: 'CO',
    display: 'Colombia'
  }, {
    value: 'CV',
    display: 'Cape Verde'
  }, {
    value: 'CR',
    display: 'Costa Rica'
  }, {
    value: 'CU',
    display: 'Cuba'
  }, {
    value: 'KY',
    display: 'Cayman Islands'
  }, {
    value: 'CY',
    display: 'Cyprus'
  }, {
    value: 'CZ',
    display: 'Czech Republic'
  }, {
    value: 'DE',
    display: 'Germany'
  }, {
    value: 'DM',
    display: 'Dominica'
  }, {
    value: 'DK',
    display: 'Denmark'
  }, {
    value: 'DO',
    display: 'Dominican Republic'
  }, {
    value: 'DZ',
    display: 'Algeria'
  }, {
    value: 'EC',
    display: 'Ecuador'
  }, {
    value: 'EG',
    display: 'Egypt'
  }, {
    value: 'ES',
    display: 'Spain'
  }, {
    value: 'FI',
    display: 'Finland'
  }, {
    value: 'FJ',
    display: 'Fiji'
  }, {
    value: 'FK',
    display: 'Falkland Islands (Malvinas)'
  }, {
    value: 'FR',
    display: 'France'
  }, {
    value: 'GB',
    display: 'United Kingdom'
  }, {
    value: 'GR',
    display: 'Greece'
  }, {
    value: 'GD',
    display: 'Grenada'
  }, {
    value: 'GL',
    display: 'Greenland'
  }, {
    value: 'GT',
    display: 'Guatemala'
  }, {
    value: 'GF',
    display: 'French Guiana'
  }, {
    value: 'GU',
    display: 'Guam'
  }, {
    value: 'HK',
    display: 'Hong Kong'
  }, {
    value: 'HR',
    display: 'Croatia'
  }, {
    value: 'HU',
    display: 'Hungary'
  }, {
    value: 'ID',
    display: 'Indonesia'
  }, {
    value: 'IN',
    display: 'India'
  }, {
    value: 'IO',
    display: 'British Indian Ocean Territory'
  }, {
    value: 'IE',
    display: 'Ireland'
  }, {
    value: 'IS',
    display: 'Iceland'
  }, {
    value: 'IL',
    display: 'Israel'
  }, {
    value: 'IT',
    display: 'Italy'
  }, {
    value: 'JM',
    display: 'Jamaica'
  }, {
    value: 'JP',
    display: 'Japan'
  }, {
    value: 'KH',
    display: 'Cambodia'
  }, {
    value: 'KN',
    display: 'Saint Kitts and Nevis'
  }, {
    value: 'KR',
    display: 'Korea, Republic of'
  }, {
    value: 'LC',
    display: 'Saint Lucia'
  }, {
    value: 'LV',
    display: 'Latvia'
  }, {
    value: 'LI',
    display: 'Liechtenstein'
  }, {
    value: 'LK',
    display: 'Sri Lanka'
  }, {
    value: 'LT',
    display: 'Lithuania'
  }, {
    value: 'LU',
    display: 'Luxembourg'
  }, {
    value: 'MF',
    display: 'Saint Martin (French part)'
  }, {
    value: 'MA',
    display: 'Morocco'
  }, {
    value: 'MC',
    display: 'Monaco'
  }, {
    value: 'MD',
    display: 'Moldova, Republic of'
  }, {
    value: 'MG',
    display: 'Madagascar'
  }, {
    value: 'MV',
    display: 'Maldives'
  }, {
    value: 'MX',
    display: 'Mexico'
  }, {
    value: 'MN',
    display: 'Mongolia'
  }, {
    value: 'MY',
    display: 'Malaysia'
  }, {
    value: 'NF',
    display: 'Norfolk Island'
  }, {
    value: 'NG',
    display: 'Nigeria'
  }, {
    value: 'NI',
    display: 'Nicaragua'
  }, {
    value: 'NL',
    display: 'Netherlands'
  }, {
    value: 'NO',
    display: 'Norway'
  }, {
    value: 'NP',
    display: 'Nepal'
  }, {
    value: 'NR',
    display: 'Nauru'
  }, {
    value: 'NZ',
    display: 'New Zealand'
  }, {
    value: 'PA',
    display: 'Panama'
  }, {
    value: 'PE',
    display: 'Peru'
  }, {
    value: 'PH',
    display: 'Philippines'
  }, {
    value: 'PW',
    display: 'Palau'
  }, {
    value: 'PG',
    display: 'Papua New Guinea'
  }, {
    value: 'PL',
    display: 'Poland'
  }, {
    value: 'PR',
    display: 'Puerto Rico'
  }, {
    value: 'KP',
    display: "Korea, Democratic People's Republic of"
  }, {
    value: 'PL',
    display: 'Poland'
  }, {
    value: 'PT',
    display: 'Portugal'
  }, {
    value: 'PY',
    display: 'Paraguay'
  }, {
    value: 'PS',
    display: 'Palestinian Territory, Occupied'
  }, {
    value: 'PF',
    display: 'French Polynesia'
  }, {
    value: 'QA',
    display: 'Qatar'
  }, {
    value: 'RO',
    display: 'Romania'
  }, {
    value: 'RU',
    display: 'Russian Federation'
  }, {
    value: 'SA',
    display: 'Saudi Arabia'
  }, {
    value: 'SG',
    display: 'Singapore'
  }, {
    value: 'SV',
    display: 'El Salvador'
  }, {
    value: 'SM',
    display: 'San Marino'
  }, {
    value: 'PM',
    display: 'Saint Pierre and Miquelon'
  }, {
    value: 'RS',
    display: 'Serbia'
  }, {
    value: 'SE',
    display: 'Sweden'
  }, {
    value: 'TH',
    display: 'Thailand'
  }, {
    value: 'TT',
    display: 'Trinidad and Tobago'
  }, {
    value: 'TR',
    display: 'Turkey'
  }, {
    value: 'TW',
    display: 'Taiwan, Province of China'
  }, {
    value: 'UA',
    display: 'Ukraine'
  }, {
    value: 'UM',
    display: 'United States Minor Outlying Islands'
  }, {
    value: 'VA',
    display: 'Holy See (Vatican City State)'
  }, {
    value: 'VC',
    display: 'Saint Vincent and the Grenadines'
  }, {
    value: 'VE',
    display: 'Venezuela, Bolivarian Republic of'
  }, {
    value: 'VG',
    display: 'Virgin Islands, British'
  }, {
    value: 'VI',
    display: 'Virgin Islands, U.S.'
  }, {
    value: 'VN',
    display: 'Viet Nam'
  }, {
    value: 'ZA',
    display: 'South Africa'
  }];
  _exports.countries = countries;
  const stripePayoutCountryAbbreviations = ['AT', // Austria
  'AU', 'BE', // Belgium
  'CA', 'DE', 'DK', // Denmark
  'ES', // Spain
  'FI', // Finland
  'FR', 'GB', 'GR', // Greece
  'IE', // Ireland
  'IT', 'NL', // Netherlands
  'NZ', 'PL', // Poland
  'PT', // Portugal
  'SE', // Sweden
  'US'];
  _exports.stripePayoutCountryAbbreviations = stripePayoutCountryAbbreviations;
  const euCountries = ['AU', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'NL', 'PL', 'RO', 'ES', 'SE', 'GB'];
  _exports.euCountries = euCountries;

  function abbreviationFromCountry(countryName) {
    if (!countryName) {
      return;
    }

    countryName = countryName.toLowerCase();
    const country = countries.find(c => c.display.toLowerCase() === countryName);
    return country ? country.value : countryName;
  }

  function countryFromAbbreviation(countryAbbr) {
    if (!countryAbbr) {
      return;
    }

    countryAbbr = countryAbbr.toLowerCase();
    const country = countries.find(c => c.value.toLowerCase() === countryAbbr);
    return country ? country.display : countryAbbr;
  }
});