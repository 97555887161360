define("@outdoorsyco/ember-shared-data/helpers/time-of-day-from-seconds", ["exports", "@outdoorsyco/ember-shared-data/utils/format-time"], function (_exports, _formatTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeOfDayFromSeconds = timeOfDayFromSeconds;
  _exports.default = void 0;

  function timeOfDayFromSeconds([time]
  /*, hash*/
  ) {
    let date = new Date();
    let hour = (0, _formatTime.secondsToHour)(time);
    let minute = (0, _formatTime.secondsToMinuteOfHour)(time);
    date.setSeconds(0);
    date.setHours(hour);
    date.setMinutes(minute);
    return date;
  }

  var _default = Ember.Helper.helper(timeOfDayFromSeconds);

  _exports.default = _default;
});