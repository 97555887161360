define("@outdoorsyco/ember-shared-data/helpers/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute([value, currency]) {
      return formatCurrency({
        value,
        currency
      }, this.intl);
    }

  });

  _exports.default = _default;

  function formatCurrency({
    value,
    currency
  }, intl) {
    if (Ember.isBlank(currency)) {
      currency = 'USD';
    }

    let valueStr = intl.formatNumber(value, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol'
    });
    let currencyStr = currency !== 'USD' ? `<span class='cur'>${currency}</span>` : '';
    return Ember.String.htmlSafe(`<span class="formatted-currency">${valueStr}${currencyStr}</span>`);
  }
});